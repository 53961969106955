<template>
    <template v-if="!data.clicked">
        <pp4-poly :path="fieldLayout.path" strokeColor="#ffdd00" fillColor="#387c2b55" :fillOpacity="0.9" :clickable="true"
            :zIndex="ZIndexes.JdWizardButton" :label="label" labelClass="jd-begin-button-label" @click="onClick" :showLabel="true" />
    </template>
    <template v-else>
        <jd-wizard :farm="farm" :field="field" :fieldLayout="fieldLayout" @cancel="cancelWizard" @done="wizardDone" />
    </template>
</template>
 
<script setup>
import { onMounted, reactive, computed, watch } from 'vue'

import { ZIndexes } from '@/maps/ZIndexes'

import { GeoUtil } from '@/geo/GeoUtil'

const data = reactive({
    clicked: false
})

const props = defineProps(['farm', 'field', 'fieldLayout'])
const emit = defineEmits(['wizard-started', 'wizard-cancelled', 'wizard-done'])

function onClick() {
    data.clicked = true
    emit('wizard-started')
}

function cancelWizard() {
    data.clicked = false
    emit('wizard-cancelled')
}

function wizardDone() {
    data.clicked = false
    emit('wizard-done')
}

watch(() => props?.fieldLayout?.id, () => {
    data.clicked = false
})

onMounted(() => {
})

const label = computed(() => {
    const fieldGeo = GeoUtil.LatLngs.toPolygon(props.fieldLayout.path)
    const areaInAcres = Math.round(GeoUtil.GeoJson.areaInAcres(fieldGeo))

    return `${props.field.name}<br>${areaInAcres} ac.<br><br><i><u>Click to Begin Wizard<u/></i>`
}, [props])

defineExpose({ ...props, ...data, label, onClick })
</script>
 
<style lang="css">
.jd-begin-button-label {
    cursor: pointer;
    font-size: large;
    color: rgb(255, 221, 0, 0.8);
    background: var(--jd-green);
    transform: translate(0%, 50%);
    padding: 1em;
    text-align: center;
    border: 2px solid #ffdd00;
    border-radius: var(--general-border-radius);
}
</style>
<template>
    <Teleport to="#jd-split-boundary-context-menu">
        <div class="teleport-wrapper">
            <toggle-button label="Snap" :active="data.snapActive" description="Snap points to field bounds"
                :disabled="snapDisabled" @click="toggleSnap()">
                <img :src="SnapIcon" draggable="false" />
            </toggle-button>
        </div>
    </Teleport>

    <split-path v-for="pathWithKey in existingLeveePathsWithKey" :key="pathWithKey.key" :path="pathWithKey.path"
        :clickable="false" :showInPreviewMode="false" />

    <template v-if="data.pathBeingAdded.length > 0">
        <split-path :path="data.pathBeingAdded" :snapCallback="data.snapCallback" selectionStyle="selected"
            :clickable="false" :showInPreviewMode="true" />
    </template>

    <split-path v-if="previewLinePath" selectionStyle="selected" :clickable="false" :showInPreviewMode="true"
        :path="previewLinePath" />
</template>
  
<script setup>
import { useStore } from 'vuex'
import { reactive, onMounted, computed, onBeforeUnmount, defineModel } from 'vue'

import Vars from '@/Vars'
import { BuildLatLngSnapCallback } from '@/geo/PipeSnapAlg'
import { GeoUtil } from '@/geo/GeoUtil'
import { LatLng } from '@/store/Dto'
import { useMaps } from '@/composables/useMaps'

import SplitPath from './SplitPath'

import SnapIcon from '@/assets/snap_icon.svg'

const model = defineModel()

const maps = useMaps()

const store = useStore()

const props = defineProps({
    fieldLayout: {
        type: Object,
        required: true
    },
})

const data = reactive({
    snapActive: true,
    pathBeingAdded: [],
    mouseOverMarkerMapObject: null,
    cursorLatLng: null
})

const snapDisabled = computed(() => false)

const existingLeveePathsWithKey = computed(() =>
    model.value.map((path, i) => {
        return { path, key: 'ExistingLeveePath:' + i }
    })
)

const previewLinePath = computed(() => {
    if (data.cursorLatLng == null) {
        return null
    }

    if (data.pathBeingAdded.length === 0) {
        return null
    }

    const lastPoint = data.pathBeingAdded[data.pathBeingAdded.length - 1]

    return [lastPoint, data.cursorLatLng]
})

const fieldGeo = computed(() => GeoUtil.LatLngs.toPolygon(props.fieldLayout.path))

const pathBeingAddedLengthIncludingPreviewInFeet = computed(() => {
    let ret = GeoUtil.LatLngs.length(data.pathBeingAdded, { units: 'feet' })

    if (previewLinePath) {
        ret += GeoUtil.LatLngs.length(previewLinePath, { units: 'feet' })
    }

    return ret
})

function toggleSnap() {
    data.snapActive = !data.snapActive
}

const FieldOffsetInMeters = 0

const shortSnapCallback = computed(() => BuildLatLngSnapCallback(
    store.state.preferences.levees.shortSnapThresholdInMeters,
    FieldOffsetInMeters,
    [fieldGeo.value],
    { EnablePointGravity: false, OutsideBoundsAlwaysSnaps: true }
))

const longSnapCallback = computed(() => {
    const otherPathLineStrings = model.value.map(GeoUtil.LatLngs.toLineString)

    return BuildLatLngSnapCallback(
        store.state.preferences.levees.longSnapThresholdInMeters,
        FieldOffsetInMeters,
        [fieldGeo.value, ...otherPathLineStrings],
        { EnablePointGravity: false, OutsideBoundsAlwaysSnaps: true }
    )
})

const snapCallback = computed(() => {
    const ignoreSnapUntilLeveeIsThisManyFeetLong =
        store.state.preferences.levees.ignoreSnapUntilLeveeIsThisManyFeetLong
    const useShortSnapThresholdIfLastSegmentDistanceLessThanMeters =
        store.state.preferences.levees.useShortSnapThresholdIfLastSegmentDistanceLessThanMeters

    return (ll) => {
        if (!data.snapActive) {
            return ll
        }

        if (data.pathBeingAdded.length === 0) {
            return longSnapCallback.value(ll)
        }

        const coord = GeoUtil.LatLngs.toCoord(ll)

        if (!GeoUtil.GeoJson.booleanPointInPolygon(coord, fieldGeo.value)) {
            return longSnapCallback.value(ll)
        }

        if (pathBeingAddedLengthIncludingPreviewInFeet < ignoreSnapUntilLeveeIsThisManyFeetLong) {
            return ll
        }

        const lastCoord = GeoUtil.LatLngs.toCoord(
            data.pathBeingAdded[data.pathBeingAdded.length - 1]
        )

        // allow snap back to first point to create pockets
        if (data.pathBeingAdded.length > 2) {
            const firstCoord = GeoUtil.LatLngs.toCoord(data.pathBeingAdded[0])
            const distanceToFirstInMeters = GeoUtil.Coords.distance(coord, firstCoord, {
                units: 'meters'
            })
            if (distanceToFirstInMeters < store.state.preferences.levees.longSnapThresholdInMeters) {
                return Object.assign({}, data.pathBeingAdded[0])
            }
        }

        const lastSegmentLengthInMeters = GeoUtil.Coords.distance(coord, lastCoord, {
            units: 'meters'
        })
        if (lastSegmentLengthInMeters < useShortSnapThresholdIfLastSegmentDistanceLessThanMeters) {
            return shortSnapCallback.value(ll)
        }

        return longSnapCallback.value(ll)
    }
})

function updateMouseOverMarkerMapObjectIfNecessary() {
    const removeIt = (!data.cursorLatLng)

    if (removeIt) {
        if (data.mouseOverMarkerMapObject) {
            maps.removeMapObject(data.mouseOverMarkerMapObject)
        }
        data.mouseOverMarkerMapObject = null
        return
    }

    if (data.mouseOverMarkerMapObject) {
        data.mouseOverMarkerMapObject.setPosition(data.cursorLatLng)
    } else {
        data.mouseOverMarkerMapObject = maps.interpretationFactory.buildBoundsMarker(data.cursorLatLng)
        data.mouseOverMarkerMapObject.getIcon().strokeColor = Vars.TentativeFieldStrokeColor

        data.mouseOverMarkerMapObject.getIcon().fillColor = Vars.TentativeFieldStrokeColor
        data.mouseOverMarkerMapObject.setOptions({
            clickable: false
        })
        data.mouseOverMarkerMapObject.setClickable(false)
    }
}

onMounted(() => {
    maps.map.setOptions({
        disableDoubleClickZoom: true
    })

    store.dispatch('mapObjects/setHelpKey', 'add_split_path_begin')

    maps.addMapListener(
        'mousemove',
        (e) => {
            const latLng = new LatLng(e.latLng.lat(), e.latLng.lng())
            const finalLatLng = snapCallback.value ? snapCallback.value(latLng) : latLng
            data.cursorLatLng = finalLatLng
            updateMouseOverMarkerMapObjectIfNecessary()
        }
    )

    maps.doDragHack()

    maps.addMapListener('mouseout', (e) => {
        data.cursorLatLng = null
        updateMouseOverMarkerMapObjectIfNecessary()
    })

    const addIfComplete = () => {
        if (data.pathBeingAdded.length < 2) {
            return
        }

        model.value.push(data.pathBeingAdded)
        data.pathBeingAdded = []

        store.dispatch('mapObjects/setHelpKey', 'add_split_path_end')
    }

    maps.addMapListener('click', (e) => {
        const latLng = new LatLng(e.latLng.lat(), e.latLng.lng())
        const finalLatLng = data.snapActive ? snapCallback.value(latLng, fieldGeo) : latLng
        const coord = [finalLatLng.lng, finalLatLng.lat]

        data.cursorLatLng = null

        data.pathBeingAdded = [...data.pathBeingAdded, finalLatLng]

        const pathComplete = data.pathBeingAdded.length > 1
        const clickOutsideFieldBounds = !GeoUtil.GeoJson.booleanPointInPolygon(coord, fieldGeo.value, {
            ignoreBoundary: false
        })
        const pointSnapped = latLng !== finalLatLng
        if (pathComplete && (clickOutsideFieldBounds || pointSnapped)) {
            addIfComplete()
        }

        const middleHelpKeyNeeded = data.pathBeingAdded.length === 1 && !pathComplete
        if (middleHelpKeyNeeded) {
            store.dispatch('mapObjects/setHelpKey', 'add_split_path_middle')
        }
    })

    maps.addMapListener('dblclick', (e) => {
        data.cursorLatLng = null

        addIfComplete()
    })
})

onBeforeUnmount(() => {
    if (data.mouseOverMarkerMapObject) {
        maps.removeMapObject(data.mouseOverMarkerMapObject)
    }
})

</script>

<style lang="css" scoped>
.teleport-wrapper {
    margin-bottom: var(--general-padding);
}
</style>
  
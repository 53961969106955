<template>
    <span class="searchBar ml-1"> 
        <input
            title="Search Location" label="Search Location"
            ref="getAddress"
            type="textbox"
            placeholder="Search"
            v-model="address"
            id="address"
            v-show="mapSearchAvailable" 
            v-on:keyup.enter="performSearch"/>
        <icon-button  
            class="label_margin_left"
            title="Search"
            @click="performSearch"
            faIcon="fa-search"/>
    </span>
</template>

<script>
import { LatLng } from '@/store/Dto'
import Pp4MapObject from '@/components/maps/Pp4MapObject'
export default {
    extends: Pp4MapObject,
    data: function() {
        return {
            geocoder: null,
            address: null
        }
    },
    props: {
        input: {
            type: String
        }
    },
    computed: {
        mapSearchAvailable: (vm) => vm.$store.state.mapObjects.availableMapActions.SearchLocation
    },
    methods: {
        mapReady() {
            this.geocoder = new this.api.Geocoder()
        },
        performSearch() {
            if(this.geocoder == null) {
                console.warn('Geocoder Not Ready')
                return
            }

            this.geocoder.geocode({address: this.address}, 
                (results, status) => {
                    if(status == 'OK'){
                        const latLng = new LatLng(
                            results[0].geometry.location.lat(),
                            results[0].geometry.location.lng()
                        )       
                        this.$store.dispatch('mapObjects/setRequestedPanToLatLng', latLng)
                    } 
                    else { 
                        this.$store.dispatch('warn', 'Search Failed: ' + status)
                    }
                })
        }
    } 
}


</script>

<style lang="css">
.searchBar {
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-direction: row;
    display: inline-flex;
    align-items: center;
    align-content: center;
    flex-grow: 1;
}

input {
    border: none;
    text-align: center;
    max-width: 30vw;
}
#submit{
  display: inline-block;
  background: var(--secondary);
  margin-left: var(--general-padding);
  border-radius: 4px;
  color: white;
  white-space: normal;
  cursor: pointer;
}
</style>

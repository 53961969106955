import Papa from 'papaparse';

function cleanupFloat(s) {
    const s2 = s.replace(/[^0-9-.]/g, '')
    const ret = Number.parseFloat(s2)

    if (Number.isNaN(ret)) {
        return null
    }

    if (!Number.isFinite(ret)) {
        return null
    }

    return ret
}

function isSimpleLatLngRow(row) {
    if ((!row) || (row.length < 3)) {
        return false
    }

    const lat = cleanupFloat(row[0])
    const lng = cleanupFloat(row[1])
    const elevation = cleanupFloat(row[2])

    return (lat != null) && (lng != null) && (elevation != null)
}

function getHeaderIndexes(row) {
    if (isSimpleLatLngRow(row)) {
        return { latIndex: 0, lngIndex: 1, elevationIndex: 2 }
    }

    const findColumnIndexMatching = (row, regex) => {
        for (let i in row) {
            if (row[i].toLowerCase().match(regex)) {
                return i
            }
        }

        return -1
    }

    const latIndex = findColumnIndexMatching(row, /lat/)
    const lngIndex = findColumnIndexMatching(row, /(lon)|(lng)/)
    const elevationIndex = findColumnIndexMatching(row, /ele/)

    return { latIndex, lngIndex, elevationIndex }
}

function headerIndexesValid(indexes) {
    const { latIndex, lngIndex, elevationIndex } = indexes
    return ((latIndex >= 0) && (lngIndex >= 0) && (elevationIndex >= 0))
}

function buildRowMapCallback(latIndex, lngIndex, elevationIndex) {
    const maxIndex = Math.max(latIndex, lngIndex, elevationIndex)

    return (row) => {
        if ((row.length - 1) < maxIndex) {
            return null
        }
        const lat = cleanupFloat(row[latIndex])
        const lng = cleanupFloat(row[lngIndex])
        const elevationInFeet = cleanupFloat(row[elevationIndex])

        //Skip any lines that do not have a valid lat
        if (lat <= -90 || lat >= 90) {
            return null
        }

        //Skip any lines that do not have a valid lng
        if (lng <= -180 || lng >= 180) {
            return null
        }

        if ((lat == null) || (lng == null) || (elevationInFeet == null)) {
            return null
        }

        return { lat, lng, elevationInFeet }
    }
}

export const DefaultOpts = {
    HeaderMustBeWithinFirstRows: 50,
    MaxRows: 36000
}
Object.freeze(DefaultOpts)

export async function IsPp3ElevationCsv(domFileOrString, overrides = {}) {
    if (domFileOrString?.name?.length) {
        const name = domFileOrString.name.toLowerCase()
        if (name.endsWith('.shp')) {
            return false;
        }
        if (name.endsWith('.shx')) {
            return false;
        }
    }

    const opts = Object.assign(Object.assign({}, DefaultOpts), overrides)

    const buildConfig = (res, _rej) => {
        let stepIndex = 0
        let resolved = false
        return {
            error: () => {
                if (!resolved) {
                    res(false)
                    resolved = true
                }
            },
            complete: () => {
                if (!resolved) {
                    res(false)
                    resolved = true
                }
            },
            step: (results, parser) => {
                stepIndex += 1

                if (stepIndex > opts.HeaderMustBeWithinFirstRows) {
                    res(false)
                    resolved = true
                    parser.abort()
                }

                const headerIndexes = getHeaderIndexes(results.data)
                if (headerIndexesValid(headerIndexes)) {
                    res(true)
                    resolved = true
                    parser.abort()
                }
            }
        }
    }

    return new Promise((res, rej) => {
        const config = buildConfig(res, rej)
        Papa.parse(domFileOrString, config)
    })
}

export async function Pp3ElevationCsvParser(domFileOrString, overrides = {}) {
    const opts = Object.assign(Object.assign({}, DefaultOpts), overrides)

    const simplify = (lles) => {
        const MaxPoints = 32
        if (lles < MaxPoints) {
            return lles
        }

        const KeepEvery = Math.floor((lles.length - MaxPoints) / MaxPoints) || 1

        return lles.filter((lle, i) => i % KeepEvery === 0)

        // const ls = GeoUtil.LatLngs.toLineString(lles)
        // GeoUtil.GeoJson.simplify(ls, {tolerance: 0.00001, mutate: true})
        // PipePathAl


        // const points = lles.map(lle => {
        //     const ret = GeoUtil.LatLngs.toGeoJsonPoint(lle)
        //     ret.properties = {elevationInFeet: lle.elevationInFeet}
        //     return ret
        // })

        // const fc = GeoUtil.GeoJson.featureCollection(points)

        // {

        //     // TODO: try mapbox/geojson-tidy

        //     console.log("! this many: " + fc.features.length)
        //     GeoUtil.GeoJson.clustersKmeans(fc, {mutate: true, numberOfClusters: MaxPoints})
        //     const maxKm = 60 / 1000
        //     // GeoUtil.GeoJson.clustersDbscan(fc, 80, {minPoints: 0, mutate: true, units: 'feet'})


        //     console.log("after: " + fc.features.length)
        //     console.dir(fc)

        //     const clusterSet = new Set()
        //     fc.features = fc.features.filter(f => {
        //         // if(! ('cluster' in f.properties)) {
        //         //     return false
        //         // }

        //         if(clusterSet.has(f.properties.cluster)) {
        //             return false  
        //         }

        //         clusterSet.add(f.properties.cluster)

        //         return true
        //     })

        //     // console.dir(fc)

        //     fc.features.forEach(f => {
        //         f.geometry.coordinates = f.properties.centroid
        //     })
        // }

        // return fc.features.map(f => {
        //     const ret = GeoUtil.GeoJson.toLatLngs(f)
        //     ret.elevationInFeet = f.properties.elevationInFeet
        //     return ret
        // })
    }

    const buildConfig = (res, rej) => {
        let stepIndex = 0
        let resolved = false
        let rowMapCallback = null
        let ret = []

        return {
            error: (e) => {
                if (!resolved) {
                    rej(e)
                    resolved = true
                }
            },
            complete: () => {
                if (!resolved) {
                    res(simplify(ret))
                    resolved = true
                }
            },
            step: (results, parser) => {
                stepIndex += 1

                if (!rowMapCallback) {
                    if (stepIndex >= opts.HeaderMustBeWithinFirstRows) {
                        res(simplify(ret))
                        resolved = true
                        parser.abort()
                    }

                    const headerIndexes = getHeaderIndexes(results.data)
                    if (headerIndexesValid(headerIndexes)) {
                        const { latIndex, lngIndex, elevationIndex } = headerIndexes
                        rowMapCallback = buildRowMapCallback(
                            latIndex, lngIndex, elevationIndex)
                    }
                    else {
                        return
                    }
                }

                if (stepIndex > opts.MaxRows) {
                    res(simplify(ret))
                    resolved = true
                    parser.abort()
                }

                const result = rowMapCallback(results.data)
                if (result != null) {
                    ret.push(result)
                }
            }
        }
    }

    return new Promise((res, rej) => {
        const config = buildConfig(res, rej)
        Papa.parse(domFileOrString, config)
    })
}


<template>
    <point-marker :location="data.location" v-bind="markerProps" :opacity="0.5" :isLastPoint="false">
        <point-marker-drag-preview :prev="props.ll0" :next="props.ll1" :lineStyle="props.lineStyle"/>
    </point-marker>
</template>

<script setup>
import { reactive, watch } from 'vue'

const props = defineProps({
    location: {
        type: Object,
        required: true
    },
    ll0: {
        type: Object,
        required: true
    },
    ll1: {
        type: Object,
        required: true
    },
    snapCallback: {
        type: Function,
        required: false
    },
    snapLevel: {
        type: Number,
        required: false,
        default: 0
    },
    lineStyle: {
        type: String,
        required: false,
        default: "pipe-segment",
        validator: (val) => (val === "pipe-segment")
            || (val === 'polyline')
    }
})

const markerProps = {
    clickable: false,
    draggable: true,
    snapCallback: props.snapCallback,
    snapLevel: props.snapLevel
}

const data = reactive({
    location: props.location,
    previewPaths: []
})

watch(() => props.location, () => {
    data.location = props.location
})
</script>

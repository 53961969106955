<template>
    <poly v-for="poly in wateredAreaPolysWithMetadata" :path="poly.path" :key="poly.key"
        :infoWindowContent="poly.infoWindowContent" :strokeColor="poly.strokeColor"
        :fillColor="poly.key === hoveredWateredAreaPolyWithMetadata?.key ? Vars.UnselectablePipePathFillColor : color"
        fillOpacity="1.0" strokeOpacity="1.0" :clickable="true"
        :showLabel="poly.key === hoveredWateredAreaPolyWithMetadata?.key" :label="poly.label" labelClass="paddy_label"
        @click="wateredAreaClicked" @mouseover="wateredAreaPolyMouseOver(poly)" @mouseout="wateredAreaPolyMouseOut"
        :zIndex="zIndex">
    </poly>
    <pp4-label v-if="!holeDesignErrors?.length" :latLng="centerLatLng" elementClass="watered_area_label"
        :labelText="computedLabel" />
    <hole-design-error-marker v-for="error in holeDesignErrors" :key="error.key" :location="error.location"
        :message="error.message" />
</template>

<script>
import Vars from '@/Vars'

import { FieldGeoFactory } from '@/design/FieldGeoFactory'
import { GeoUtil } from '@/geo/GeoUtil'
import { FieldMode } from '@/store/Dto'
import { HoleSide } from '@/design/DesignDto'
import { ZIndexes } from '@/maps/ZIndexes'

import Poly from '@/components/maps/Poly'
import Pp4Label from '@/components/maps/Label'
import * as WateringTimeAlg from '@/util/WateringTimeAlg'
import HoleDesignErrorMarker from '@/components/maps/irrigation_systems/HoleDesignErrorMarker'
import HoleDesignErrors from '@/components/maps/design/HoleDesignErrors'

export default {
    components: {
        Poly, HoleDesignErrorMarker, Pp4Label
    },
    data: function () {
        return {
            Vars, ZIndexes,
            errorsBuilder: new HoleDesignErrors(),
            hoveredWateredAreaPolyWithMetadata: null
        }
    },
    props: {
        field: {
            type: Object,
            required: true
        },
        fieldLayout: {
            type: Object,
            required: true
        },
        pipePath: {
            type: Object,
            required: true
        },
        flowRate: {
            type: Object,
            required: true
        }
    },
    mounted() {
    },
    methods: {
        wateredAreaPolyMouseOver(wateredAreaPolyWithMetadata) {
            if (this.fieldLayout.mode !== FieldMode.Levees) {
                return
            }

            this.hoveredWateredAreaPolyWithMetadata = wateredAreaPolyWithMetadata

        },
        wateredAreaPolyMouseOut() {
            this.hoveredWateredAreaPolyWithMetadata = null
        },
        wateredAreaClicked() {
            this.$store.dispatch('setSelectedField', this.field)
        }
    },
    computed: {
        designParams: (vm) => {
            return vm.pipePath.designParams
        },
        computedClass: (vm) => {
            return vm.fieldLayout.mode !== FieldMode.Levees ? 'paddy_label' : 'watered_area_label'
        },
        waterableAreaInAcres: (vm) => {
            let totalWaterableAcres = 0
            vm.wateredAreaPolys.forEach((geo) => {
                totalWaterableAcres += GeoUtil.GeoJson.area(geo)
            })
            return (0.0002471052 * totalWaterableAcres).toFixed(1)
        },
        computedLabel: (vm) => {
            if (!vm.flowRate) {
                return
            }
            let ret = '(' + Math.round(vm.waterableAreaInAcres) + ' ac.) Watered'
            ret += '<br>' + WateringTimeAlg.formattedWateringTimeAndFlowRate(parseFloat(vm.waterableAreaInAcres), vm.flowRate, vm.fieldLayout)

            return ret
        },
        holeDesign: (vm) => {
            const designParams = vm.designParams
            if (!designParams) {
                return null
            }

            if (!('holeDesign' in designParams)) {
                return null
            }

            return designParams.holeDesign
        },
        holeDesignErrors: (vm) => {
            if (!(vm.holeDesign && vm.holeDesign.error)) {
                return []
            }

            const error = vm.holeDesign.error
            const errors = error.code === 'multiple_errors' ?
                error.errors : [error]

            const errorLocation = (e) => {
                if ((e instanceof Object) && ('waterableArea' in e)) {
                    const wa = e.waterableArea
                    if ('pipeEnterGeoJsonPoint' in wa) {
                        return GeoUtil.GeoJson.toLatLngs(
                            wa.pipeEnterGeoJsonPoint)
                    }
                }

                return vm.centerLatLng
            }

            return errors.map((e, i) => {
                return {
                    key: (vm.fieldLayout.id + ':HoleDesignError:' + i),
                    location: errorLocation(e),
                    message: vm.errorsBuilder.buildErrorMessage(
                        vm.fieldLayout, vm.pipePath, e)
                }
            })
        },
        color: (vm) => {
            if (!vm.holeDesign) {
                return Vars.CancelRedColor
            }

            if (!vm.holeDesign.error) { // TODO: look at holeDesign prop instead?
                return Vars.SelectedPipePathFillColor
            }

            return Vars.WarningOrange
        },
        zIndex: (vm) => {
            return vm.fieldLayout.mode === FieldMode.Levees ?
                ZIndexes.LeveeWateredAreas : ZIndexes.FurrowWateredAreas
        },
        centerLatLng: (vm) => {
            const polyCenters = vm.wateredAreaPolys.map(GeoUtil.GeoJson.centerOfMass)

            const featureCollection = GeoUtil.GeoJson.featureCollection(polyCenters)

            if (featureCollection == null || featureCollection.features.length == 0) {
                return vm.pipePath.path[vm.pipePath.path.length - 1]
            }

            const centerGeo = GeoUtil.GeoJson.centerOfMass(featureCollection)

            return GeoUtil.GeoJson.toLatLngs(centerGeo)
        },
        wateredAreaPolys: (vm) => {
            const factory = new FieldGeoFactory(vm.$store.state.preferences)

            const debugPolys = []

            try {
                if (vm.fieldLayout.mode === FieldMode.Furrows) {
                    const ret = factory.buildFurrowPolys(
                        vm.fieldLayout, vm.pipePath, debugPolys)

                    //vm.$store.dispatch('mapObjects/debug/setGeoJsonPolys', JSON.parse(JSON.stringify(debugPolys)))
                    return ret
                }
                else if (vm.fieldLayout.mode === FieldMode.Levees) {
                    return factory.buildLeveePaddyGeosForPipePath(
                        vm.fieldLayout, vm.pipePath, debugPolys)
                }

                return []
            }
            catch (e) {
                console.warn(e)
                vm.$store.dispatch('warn', e)
            }

            return []
        },
        wateredAreaPolysWithMetadata: (vm) => {
            const allHoleSidesMatch = vm.wateredAreaPolys.reduce(
                (accum, geo) => {
                    accum.add(geo.properties.holeSide)
                    return accum
                }, new Set()).size === 1
            const strokeColor = vm.fieldLayout.mode === FieldMode.Levees ?
                Vars.SelectedFieldStrokeColor : vm.color

            return vm.wateredAreaPolys.map((geo, i) => {
                const areaInSquareMeters = GeoUtil.GeoJson.area(geo)
                const areaInAcres = (0.0002471052 * areaInSquareMeters).toFixed(1)

                let label = ''
                if (vm.fieldLayout.mode == FieldMode.Levees) {
                    const holeSide = allHoleSidesMatch ? '' :
                        (geo.properties.holeSide === HoleSide.Left) ?
                            ' Left' : ' Right'

                    label = 'Paddy ' + (geo.properties.paddyIndex + 1) + holeSide
                        + '<br>' + areaInAcres + ' ac.'
                }

                return {
                    strokeColor,
                    label,
                    path: GeoUtil.GeoJson.toLatLngs(geo),
                    key: "WateredArea: " + i,
                    areaInAcres,
                    infoWindowContent: geo.properties.infoWindowContent
                }
            })
        }
    }
}
</script>

<style lang="css">
.paddy_label {
    font-size: medium;
    font-weight: bold;
    color: white;
    transform: translate(0%, 50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 0.5em;
    border-radius: var(--softer-border-radius);
    text-align: center;
}

.watered_area_label {
    font-size: medium;
    font-weight: bold;
    color: var(--selected-pipe-path-fill-color);
    background-color: white;
    transform: translate(0%, 50%);
    padding: 0.5em;
    border-radius: var(--softer-border-radius);
    text-align: center;
}
</style>

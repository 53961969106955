<template>
    <div :class="classes" @click="dismiss">
        <div>{{ props.message }}</div>
    </div>
</template>

<script setup>
import { computed, reactive } from 'vue';
import { popModal } from "jenesius-vue-modal"

const props = defineProps({
    message: {
        type: String,
        required: true
    },
    jd: {
        type: Boolean,
        required: false
    },
    dismissAfterMs: {
        type: Number,
        required: false,
        default: 1517
    }
})

const data = reactive({
    dismissed: false
})

function dismiss() {
    if (data.dismissed) { 
        return 
    }

    data.dismissed = true

    popModal()
}

if (props.dismissAfterMs) {
    setTimeout(dismiss, props.dismissAfterMs)
}

const classes = computed(() => {
    return props.jd ? "toast jd" : "toast"
})
</script>

<style lang="css">
.toast {
    background-color: var(--bg1);
    padding: var(--general-padding);
    border: var(--general-padding) solid var(--bg1);
    min-width: 10%;
    min-height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: large
}

.toast.jd {
    background-color: var(--jd-green);
    border: var(--general-padding) solid var(--jd-yellow);
    color: var(--jd-yellow);
}
</style>
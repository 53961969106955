<template></template>

<script setup>
import { toRaw, watch, onUnmounted, reactive, inject } from 'vue'

import Vars from '@/Vars'

const props = defineProps({
   path: {
      type: Array,
      required: true
   },
   clickable: {
      type: Boolean,
      required: false,
      default: false
   },
   strokeColor: {
      type: String,
      required: false,
      default: Vars.SelectedFieldStrokeColor
   },
   strokeWeight: {
      required: false,
      default: 3
   },
   strokeOpacity: {
      type: Number,
      required: false,
      default: 1.0
   },
   icons: {
      type: Array,
      required: false,
      default: () => []
   },
   zIndex: {
      type: Number,
      required: false,
      default: 0
   },
   infoWindowContent: {
      type: String,
      required: false,
      default: null
   },
   onClick: {
      required: false
   }
})

const data = reactive({
   mapObject: null,
   clickListener: null
})

onUnmounted(() => {
   if (data.mapObject) {
      toRaw(data.mapObject).setMap(null)
      data.mapObject = null
   }
})

const { api, map } = inject('provider')

function updateOrCreatePolyline() {
   const options = {
      editable: false,
      ...props
   }

   if (!data.mapObject) {
      const api2 = toRaw(api)
      const polyline = new api2.Polyline(options)
      polyline.setMap(map)
      data.mapObject = polyline
   }
   else {
      toRaw(data.mapObject).setOptions(options)
   }

   data.clickListener?.remove()
   if(props.onClick) {
      data.clickListener = data.mapObject.addListener('click', props.onClick)
   }
}

watch(props, updateOrCreatePolyline)

updateOrCreatePolyline()
</script>
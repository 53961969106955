<template>
    <Teleport to="#jd-split-boundary-context-menu">
        <div class="teleport-wrapper">
            <toggle-button label="Snap" :active="data.snapActive" description="Snap points to field bounds"
                :disabled="snapDisabled" @click="toggleSnap()">
                <img :src="SnapIcon" draggable="false" />
            </toggle-button>

            <toggle-button label="Remove Points" :active="data.mode === GeometryEditMode.RemovePoints" 
                class="mt-1"
                description="Remove points"
                :disabled="false" @click="toggleRemovePointsMode()">
                <img :src="RemovePointsIcon" draggable="false" />
            </toggle-button>
        </div>
    </Teleport>

    <EditablePath v-for="(o, index) in workingPathsWithKey" :key="o.key" :editMode="data.mode"
        :snapCallback="o.snapCallback" v-model="o.path" @change="pathChanged(index, $event)"
        @remove="removePath(index)" />
</template>
  
<script setup>
import { useStore } from 'vuex'
import { onMounted, reactive, computed, toRaw } from "vue"
import * as uuid from 'uuid'

import { BuildLatLngSnapCallback } from '@/geo/PipeSnapAlg'
import { GeoUtil } from '@/geo/GeoUtil'
import EditablePath from './EditablePath'
import { GeometryEditMode } from '@/store/Dto'

import RemovePointsIcon from '@/assets/remove_line_points_icon.svg'
import SnapIcon from '@/assets/snap_icon.svg'

const store = useStore()

const props = defineProps({
    fieldLayout: {
        type: Object,
        required: true
    }
})

const model = defineModel()

const data = reactive({
    mode: GeometryEditMode.EditPoints,
    snapActive: true
})

const snapDisabled = computed(() => data.mode === GeometryEditMode.RemovePoints)

function toggleSnap() {
    data.snapActive = !data.snapActive
}

function toggleRemovePointsMode() {
    data.mode = (data.mode === GeometryEditMode.EditPoints) ?
        GeometryEditMode.RemovePoints : GeometryEditMode.EditPoints
}

const fieldGeo = computed(() => GeoUtil.LatLngs.toPolygon(props.fieldLayout.path))

const workingPathsWithKey = computed(() => {
    const pathLineStrings = model.value.map(GeoUtil.LatLngs.toLineString)
    const FieldOffsetInMeters = 0

    return model.value.map((path, index) => {
        // If we don't filter out our own path we will be snapping to ourself -- no bueno
        const otherPathLineStrings = pathLineStrings.slice()
        otherPathLineStrings.splice(index, 1)

        const snapCallback = BuildLatLngSnapCallback(
            store.state.preferences.fields.snapThresholdInMeters,
            FieldOffsetInMeters,
            [fieldGeo.value, ...otherPathLineStrings],
            { EnablePointGravity: false, OutsideBoundsAlwaysSnaps: false }
        )

        const snapCallbackWrapper = (ll) => {
            if(! data.snapActive) {
                return ll
            }

            return snapCallback(ll)
        }

        return {
            path, snapCallback: snapCallbackWrapper,
            key: `EditPath:${uuid.v1()}`
        }
    })
})

function pathChanged(index, newPath) {
    const tmp = toRaw(model.value).splice(index, 1, newPath)
    model.value = tmp
}

function removePath(index) {
    model.value.splice(index, 1)
}

onMounted(() => {
    store.dispatch("mapObjects/setHelpKey", "edit_paths")
})
</script>
  
<style lang="css" scoped>
.teleport-wrapper {
    margin-bottom: var(--general-padding);
}
</style>
  
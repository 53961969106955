// Our wrapper for analytics events

// Right now we will reference a presumed $gtag plugin (Google Analytics)
import { reactive } from 'vue'

import { GeoUtil } from '@/geo/GeoUtil'

function buildInstance(gtag) {
    return reactive({
        holeDesignCompleted(field, fieldLayout, pipePath, irrigationSystem, designParams) {
            const holeDesign = designParams.holeDesign
            if (!holeDesign) {
                return
            }

            gtag.event(
                'hole_design_completed',
                {
                    acres: holeDesign.acres
                }
            )
        },
        fieldAdded(field) {
            const layout = field.layouts.find((layout) => layout.id === field.activeLayoutId)
            const areaInSquareMeters = GeoUtil.LatLngs.area(layout.path)
            const acres = (0.0002471052 * areaInSquareMeters).toFixed(1)

            gtag.event(
                'field_added',
                {
                    acres
                }
            )
        },
        wizardStep(stepName) {
            if (!stepName) {
                return
            }

            gtag.event(
                'wizard_' + stepName, {}
            )
        },
        jdActivateBlockedBecauseNotDealer() {
            gtag.event('jd_activate_blocked_because_not_dealer', {})
        }
    })
}

export const AnalyticsPlugin = {
    install(app, { gtag }) {
        const instance = buildInstance(gtag)
        app.config.globalProperties.$analytics = instance
        app.provide('$analytics', instance)
    }
}

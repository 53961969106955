<template>
   <pp4-field v-if="fieldLayout" :field="field" :fieldLayout="fieldLayout" :clickable="false" selectionStyle="selected"
      :showLabel="false" :showFurrowDirection="false" />

   <template v-if="Number.isFinite(model)">
      <furrow-direction :fieldLayout="fieldLayout" selectionStyle="selected" :bearing="model" />
   </template>

   <pp4-polyline v-for="p of matchingFieldSegmentPaths" :key="p.key" :strokeColor="p.jd ? Vars.JdYellow : 'white'"
      :strokeWeight="5" :path="p.path" :zIndex="ZIndexes.FurrowArrows" />

   <template v-if="data.jdSnapActive">
      <pp4-polyline v-for="p of data.jdAbLines" :key="p.key" :strokeColor="Vars.JdYellow" :opacity="0.5" :strokeWeight="5"
         :strokeOpacity="0.5" :clickable="true" @click="p.click" :path="p" :zIndex="0" />
      <pp4-label v-for="p of data.jdAbLines" :latLng="p[1]" :labelText="p.name" :clickable="true"
         :zIndex="ZIndexes.FurrowArrows" @click="p.click" :key="`label:${p.key}`" :latLang="p[0]"
         elementClass="jd_guidance_label" />
   </template>

   <context-menu>
      <toggle-button description="Snap furrow direction to field boundary" label="Snap to Field" :active="data.snapActive"
         @click="toggleSnap"><img :src="SnapIcon" :draggable="false"></toggle-button>
      <toggle-button v-if="props.field.jd" description="Snap furrow direction to John Deere guidance lines"
         label="Snap to Guidance" :active="data.jdSnapActive" class="button_margin_top" :jd="true"
         @click="toggleSnapJd"><img :src="GuidanceLinesIcon" :draggable="false"></toggle-button>

      <template v-if="props.enableSave">
         <icon-button label="Save" description="Save" class="button_group_margin_top" :disabled="!saveActive"
            :useSaveIcon="true" @click="save" />
         <icon-button label="Cancel" description="Cancel" class="button_margin_top" :useCancelIcon="true"
            @click="cancel" />
      </template>
   </context-menu>

   <water-source v-for="irrigationSystem in farm.irrigationSystems" :key="irrigationSystem.id"
      :location="irrigationSystem.waterSourceLocation" :name="irrigationSystem.name" :showLabel="true" :clickable="false"
      selectionStyle="unselectable" />

   <loading v-if="data.saving" label="Saving..." />
   <slot />
</template>

<script setup>
import { reactive, inject, onMounted, computed, onUnmounted, watch } from 'vue'
import * as uuid from 'uuid'

import { useMaps } from '@/composables/useMaps'
import { FurrowSetAlg } from '@/geo/FurrowSetAlg'
import Pp4Polyline from '@/components/maps/Polyline'
import FurrowDirection from '@/components/maps/FurrowDirection'
import { GeoUtil } from '@/geo/GeoUtil'
import { ZIndexes } from '@/maps/ZIndexes'
import Vars from '@/Vars'

import SnapIcon from '@/assets/furrow_direction_snap_icon.svg'
import GuidanceLinesIcon from '@/assets/guidance_lines.svg'

function buildSnapBearings(path, keyPrefix, jd) {
   const ret = []

   const keysForDupeChecking = new Set()

   path.forEach((p, index) => {
      let p1 = p
      let p2 = (index != (path.length - 1)) ?
         path[index + 1] : path[0]

      const distance = GeoUtil.LatLngs.distance(p1, p2, { units: 'meters' })
      if (distance < 10) {
         return
      }

      const key = keyPrefix + JSON.stringify([p1, p2])
      if (keysForDupeChecking.has(key)) {
         return
      }

      keysForDupeChecking.add(key)

      ret.push({
         key, p1: p1, p2: p2,
         bearing: GeoUtil.bearing(p1, p2),
         bearingReversed: GeoUtil.bearing(p2, p1),
         jd
      })
   })

   return ret
}

function buildMatchedSnapBearingPaths(snapBearings, bearing) {
   const threshold = 0.01

   const matchedSnapBearings = snapBearings.value.filter((sb) => {
      return (Math.abs(sb.bearing - bearing) < threshold)
         || (Math.abs(sb.bearingReversed - bearing) < threshold)
   })

   return matchedSnapBearings.map((sb) => {
      return {
         path: [sb.p1, sb.p2],
         key: sb.key,
         jd: sb.jd
      }
   })
}

const { map } = inject("provider")

const maps = useMaps()

const props = defineProps({
   farm: {
      type: Object,
      required: false
   },
   field: {
      type: Object,
      required: false
   },
   fieldLayout: {
      type: Object,
      required: true,
      validator: function (value) { return value != null && value.id != null }
   },
   irrigationSystem: { // only required for saving, cannot validate w/Vue because their validation system cannot examine other values
      type: Object,
      required: false,
      default: null
   },
   enableSave: {
      type: Boolean,
      required: false,
      default: false
   }
})

const data = reactive({
   snapActive: props.field.jd ? false : true,
   jdSnapActive: props.field.jd ? true : false,
   saving: false,
   jdAbLines: [],
   unmounted: false
})

const fieldLayout = props.fieldLayout

const model = defineModel()

const startingValue = model.value

const saveActive = computed(() => {
   return startingValue !== model.value
})

const $emit = defineEmits(['done', 'cancel'])
const $store = inject('$store')
const $dialog = inject('$dialog')

const fieldGeo = computed(() => GeoUtil.LatLngs.toPolygon(props.fieldLayout.path))

const fieldCenterCoord = computed(() => {
   const fieldCenterGeo = GeoUtil.GeoJson.centerOfMass(fieldGeo.value)
   return GeoUtil.GeoJson.getCoord(fieldCenterGeo)
})

const snapBearings = computed(() => {
   const ret = []

   if (data.snapActive) {
      if (fieldLayout?.path?.length > 3) {
         ret.push(...buildSnapBearings(fieldLayout.path, 'sb:', false))
      }
   }

   if (data.jdSnapActive) {
      data.jdAbLines.forEach(p => {
         ret.push(...buildSnapBearings(p, 'jdsb:', true))
      })
   }

   return ret
})

const matchingFieldSegmentPaths = computed(() => {
   const bearing = model.value

   if (!Number.isFinite(bearing)) {
      return []
   }

   return buildMatchedSnapBearingPaths(
      snapBearings, bearing)
})

function toggleSnap() {
   data.snapActive = !data.snapActive
}
function toggleSnapJd() {
   data.jdSnapActive = !data.jdSnapActive
}

function getNearestSnapBearingIfActive(userBearing) {
   if (!(snapBearings.value.length)) {
      return userBearing
   }

   let closestBearingDifference = Number.MAX_VALUE
   let ret = userBearing

   snapBearings.value.forEach((snapBearing) => {
      let difference1 = GeoUtil.bearingDifference(userBearing, snapBearing.bearing)
      if (difference1 < closestBearingDifference) {
         closestBearingDifference = difference1
         ret = snapBearing.bearing
      }

      let difference2 = GeoUtil.bearingDifference(userBearing, snapBearing.bearingReversed)
      if (difference2 < closestBearingDifference) {
         closestBearingDifference = difference2
         ret = snapBearing.bearingReversed
      }
   })

   return ret
}

function setBearingFromPath(path) {
   const newVal = getNearestSnapBearingIfActive(GeoUtil.bearing(path[0], path[1]))
   model.value = (model.value === newVal) ?
      GeoUtil.bearingReversed(newVal) : newVal
}

async function importJdGuidanceLines() {
   const jd = props.field.jd

   if (!jd?.fieldId) {
      return
   }

   const allLines = await $store.getters['jd/getFieldGuidanceLines'](
      jd.organizationId, jd.fieldId
   )

   if(data.unmounted) {
      return
   }

   data.jdAbLines = allLines.filter(p => p.length === 2).map(l => {
      l.key = uuid.v1(),

         l.click = function (e) {
            setBearingFromPath(l)
         }

      return l
   })

   $dialog.toast({ message: "Guidance Lines Found: " + data.jdAbLines.length, jd: true })
}

function onMapClick(e) {
   let googleLatLng = e.latLng
   let mouseCoord = [googleLatLng.lng(), googleLatLng.lat()]

   model.value = getNearestSnapBearingIfActive(
      GeoUtil.Coords.bearing(fieldCenterCoord.value, mouseCoord))
}

async function save() {
   const furrowBearingPatch = {
      op: 'replace',
      path: '/furrowBearing',
      value: model.value
   }

   const waterSourceLocation = props.irrigationSystem ?
   props.irrigationSystem.waterSourceLocation : null

   const furrowSetDetails = new FurrowSetAlg().buildFurrowSetDetailsFromSplitPointLatLngs(
      props.fieldLayout.furrowSetDetails.splitPointLatLngs,
      model.value,
      props.fieldLayout.path,
      waterSourceLocation)
   const furrowSetDetailsPatch = {
      op: 'replace',
      path: '/furrowSetDetails',
      value: furrowSetDetails
   }

   const patches = [
      furrowBearingPatch, furrowSetDetailsPatch
   ]

   try {
      data.saving = true

      await $store.dispatch('patchFieldLayout', {
         field: props.field, fieldLayout: props.fieldLayout, patch: patches
      })

      $emit('done')
   }
   catch (e) {
      console.error(e)
      $store.dispatch('warn', 'Save Failed -- Please Retry in a Moment or Refresh Page')
   }
   finally {
      data.saving = false
   }
}

function cancel() {
   $emit('cancel')
}

watch(data, () => {
   if (data.jdSnapActive) {
      $store.dispatch('mapObjects/setHelpKeyAndParams', {
         key: 'set_furrow_direction_jd',
         params: {
            jd: true
         }
      })
   }
   else {
      $store.dispatch('mapObjects/setHelpKeyAndParams', {
         key: 'set_furrow_direction'
      })
   }

}, { immediate: true })

onMounted(() => {
   importJdGuidanceLines()

   map.setOptions({
      draggableCursor: 'default',
      disableDoubleClickZoom: true
   })

   maps.addMapListener('click', onMapClick)
})
onUnmounted(() => {
   data.unmounted = true
})
</script>

<style lang="css">
.jd_guidance_label {
   position: absolute;
   display: block;
   transform: translate(2em, -0.5em);
   text-align: center;
   overflow: hidden;
   white-space: nowrap;
   text-overflow: ellipsis;
   background: var(--jd-green);
   color: var(--jd-yellow);
   padding: 0.5em;
   font-weight: bold;
   max-width: 20em;
   cursor: pointer;
}
</style>

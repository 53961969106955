<template>
    <span 
        v-bind:title="description" 
        :class="computedIconButtonClasses">
            <span v-if="faIconValid" :style="computedFaStyle" :class="computedFaIconClasses"></span>
            <span v-if="computedImage" ><img class="icon_img" :src="image" alt="Icon Image"></span>
            <div :class="computedLabelClass" v-if="label && labelsAvailable" v-html="label"></div>
    </span>
</template>

<script>
export default {
    props: ['label', 'description', 'disabled', 'faIcon', 'faColorClass', 'faColor', 'faBackgroundColor', 'image', 'useSaveIcon', 'useCancelIcon', 'big'],
    data: function() {
        return {
            iconColorClasses: ""
        }
    },
    methods: {
        emitClick() {
            if(! this.disabled) {
                this.$emit('click')
            }
        }
    },
    computed: {
        computedIconButtonClasses: (vm) => {
            let ret = ""

            ret = vm.big ?  'icon_big' : 'icon'

            ret += vm.disabled ? " disabled" : " enabled"

            return ret
        },
        computedLabelClass: (vm) => vm.big ?
            'icon_label_big' : 'icon_label',
        computedImage: (vm) => {
            if(vm.image) {
                return true
            }
            return false
        },
        computedFaStyle: (vm) => {
            let ret = {}

            if(vm.faColor) {
                ret['color'] = vm.faColor
            }

            if(vm.faBackgroundColor) {
                ret['background-color'] = vm.faBackgroundColor
            }

            return ret
        },
        computedFaIconClasses: (vm) => {
            let ret = ""
            
            if(vm.faIcon || vm.useSaveIcon || vm.useCancelIcon) {
                ret += vm.big ? "fa fa-3x" : "fa fa-2x"
            }

            if(vm.useSaveIcon) {
                ret += " fa-check ok_green"
            }
            else if(vm.useCancelIcon) {
                ret += " fa-undo cancel_red"
            }
            else if(vm.faIcon) {
                ret += " " + vm.faIcon

                if(vm.faColorClass != null && vm.faColorClass.length > 0) {
                    ret += " " + vm.faColorClass
                }
            }

            return ret
        },
        faIconValid: (vm) => vm.computedFaIconClasses.length > 0,
        imageValid: (vm) => vm.computedImageClasses.length > 0,
        labelsAvailable: (vm) => vm.$store.state.preferences.ui.showButtonLabels
    },
    created: function() {
        if(! this.title) {
            this.title = this.$data.label
        }
    }
}
</script>

<style lang="css" scoped>
.icon_img {
    height: 2.5em;
    width: 2.5em;
    padding: 0px;
}

.fa {
    vertical-align: middle;
    text-align: center;
    padding: 0.25em;
    margin: 0px;
    align-self: center;
}

.disabled {
    color: var(--disabled-dark);
}
.enabled {
    color: black;
}

.icon {
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    font-size: medium;
    padding: 0px;
    cursor: pointer;
    width: 2.5em;
}

.icon_big {
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    background-color: white;
    font-size: medium;
    padding: 0px;
    cursor: pointer;
    width: 4.5em;
}

@media screen and (min-width: 100px) {
    .icon {
        font-size: small;
    }

    .icon_big {
        font-size: small;
    }
}

@media screen and (min-width: 800px) {
    .icon {
        font-size: initial;
    }

    .icon_big {
        font-size: initial;
    }
}

.ok_green {
    color: var(--ok-green);
}
.disabled > .ok_green  {
    color: grey;
}

.cancel_red {
    color: var(--cancel-red);
}
.disabled > .cancel_red  {
    color: grey;
}

.enabled:hover {
    background-color: var(--secondary-light);
}

.icon img {
    border: 4px solid transparent;
    width: 1.75em;
    height: 1.75em;
    max-width: 10vw;
    max-height: 10vw;
    padding: 2px;
    align-self: center;
}

.icon_big img {
    border: 4px solid transparent;
    width: 3.5em;
    height: 3.5em;
    max-width: 3.5em;
    max-height: 3.5em;
    padding: 2px;
    align-self: center;
}

.icon_label {
    display: block;
    font-size: xx-small;
    color: white;
    padding: 0px;
    margin: 0px;
    position: relative;
    left: 0px;
    right: 0px;
    min-height: 1em;
    overflow: hidden;
    text-overflow: initial;
    text-align: center;
    background-color: var(--secondary-dark);
}

.icon_label_big {
    display: block;
    font-size: medium;
    color: white;
    padding: 0px;
    margin: 0px;
    position: relative;
    left: 0px;
    right: 0px;
    min-height: 1em;
    overflow: hidden;
    text-overflow: initial;
    text-align: center;
    background-color: var(--primary);
    align-self: stretch;
    flex-grow: 1;
    justify-self: flex-start;
}

.disabled > .icon_label {
    color: black;
    background: var(--primary-light);
}

.disabled {
    background-color: var(--disabled);
    cursor: unset;
}
</style>

<template>
    <template>
        <pp4-polyline :icons="icons" v-for="(o, i) in model" :key="i" :strokeOpacity="0.5" :path="o"
            :zIndex="ZIndexes.FurrowSets" />

        <remove-marker v-for="o in removeMarkers" :key="o.key" :location="o.location" @click="removePath(o.index)" />
    </template>
</template>

<script setup>
import { GeoUtil } from '@/geo/GeoUtil'
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import * as uuid from 'uuid'
import { ZIndexes } from '@/maps/ZIndexes'

import Pp4Polyline from '@/components/maps/Polyline'

const store = useStore()

const props = defineProps({
})

const model = defineModel({ default: [] })

onMounted(() => {
    store.dispatch('mapObjects/setHelpKey', 'remove_paths')
})

const getBestLocationForRemoveMarker = (p1, p2, markerLocations) => {
    let p1BestDistance = Number.MAX_VALUE
    let p2BestDistance = Number.MAX_VALUE

    markerLocations.forEach((latLng) => {
        p1BestDistance = Math.min(p1BestDistance, GeoUtil.LatLngs.distance(latLng, p1))
        p2BestDistance = Math.min(p2BestDistance, GeoUtil.LatLngs.distance(latLng, p2))
    })

    return p1BestDistance >= p2BestDistance ? p1 : p2
}

const removeMarkers = computed(() => {
    const ret = []

    model.value.forEach((path, index) => {
        let firstPoint = path[0]
        let lastPoint = path[path.length - 1]

        let bestPoint = getBestLocationForRemoveMarker(firstPoint, lastPoint, ret)

        ret.push({
            location: bestPoint,
            key: uuid.v1(), index
        })
    })

    return ret
})

const icons = [
    {
        icon: {
            path: 'M 0, -1.5 0,0',
            strokeOpacity: 1.0,
            scale: 4
        },
        offset: '0',
        repeat: '20px'
    }
]

function removePath(index) {
    model.value.splice(index, 1)
}
</script>

<style lang="css"></style>

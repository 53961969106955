<script>
export default {
    render() {
        return null
    },
    mounted: function () {
        this.$auth0.logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
            async onRedirect(url) {
                window.location.replace(url)
            }
        })
    }
}
</script>

function ResetDebugStoreState(state) {
    if(! state.latLngsWithElevation) {
        state.latLngsWithElevation = []
    }
    
    state.geoJsonPolys = []
    state.selectedGeoJsonPoly = null
    
    return state
}

const BuildDebugStore = () => {
    return {
        namespaced: true,
        state: ResetDebugStoreState({}),
        getters:  {
        },
        mutations: {
            reset(state) {
                state = ResetDebugStoreState(state)
            },
            setElevationOverlay(state, val) {
                state.latLngsWithElevation = val
            },
            setGeoJsonPolys(state, val) {
                state.geoJsonPolys.splice(0, state.geoJsonPolys.length)

                if(val != null) {
                    state.geoJsonPolys.splice(0, 0, ...val)

                    if(val.length > 0) {
                        state.selectedGeoJsonPoly = val[0]
                    }
                }
                else {
                    state.selectedGeoJsonPoly = null
                }
            },
            selectNextGeoJsonPoly(state) {
                if(state.geoJsonPolys.length < 1) {
                    return
                }

                let index = 0
                if(state.selectedGeoJsonPoly != null) {
                    index = state.geoJsonPolys.indexOf(state.selectedGeoJsonPoly) + 1

                    if(index >= state.geoJsonPolys.length) {
                        index = 0
                    }
                }

                state.selectedGeoJsonPoly = state.geoJsonPolys[index]
            },
            selectPrevGeoJsonPoly(state) {
                if(state.geoJsonPolys.length < 1) {
                    return
                }

                let index = 0
                if(state.selectedGeoJsonPoly != null) {
                    index = state.geoJsonPolys.indexOf(state.selectedGeoJsonPoly) - 1

                    if(index < 0) {
                        index = state.geoJsonPolys.length - 1
                    }
                }
                
                state.selectedGeoJsonPoly = state.geoJsonPolys[index]
            },
            removeSelectedGeoJsonPoly(state) {
                let index = state.geoJsonPolys.indexOf(state.selectedGeoJsonPoly)

                let nextIndexToSelect = index
                if(index == (state.geoJsonPolys.length - 1)) {
                    nextIndexToSelect = 0
                }

                state.geoJsonPolys.splice(index, 1)

                state.selectedGeoJsonPoly = ((state.geoJsonPolys.length > 0) && (nextIndexToSelect >= 0)) ? 
                    state.geoJsonPolys[nextIndexToSelect] : null
            }
        },
        actions: {
            setElevationOverlay({commit}, latLngsWithElevation) {
                commit('setElevationOverlay', latLngsWithElevation)
            },
            setGeoJsonPolys({commit}, val) {
                commit('setGeoJsonPolys', val)
            },
            selectNextGeoJsonPoly({commit}) {
                commit('selectNextGeoJsonPoly')
            },
            selectPrevGeoJsonPoly({commit}) {
                commit('selectPrevGeoJsonPoly')
            },
            removeSelectedGeoJsonPoly({commit}) {
                commit('removeSelectedGeoJsonPoly')
            }
        }
    }
}

export {
    BuildDebugStore, ResetDebugStoreState
}

import * as VueRouter from 'vue-router'
import { authGuard } from "@auth0/auth0-vue";

import Login from '@/views/Login'
import Logout from '@/views/Logout'
import AddFarm from '@/views/AddFarm'
import AdminReportsDialog from '@/views/AdminReportsDialog'
import JohnDeere from '@/views/JohnDeere'
import FarmMapView from '@/views/FarmMapView'
import SelectFarm from '@/views/SelectFarm'
import ValidationNeeded from '@/views/ValidationNeeded'
import TransferFarms from '@/views/TransferFarms'

const routes = [
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/Logout',
    name: 'Logout',
    component: Logout
  },


  // Redirects & stuff to play nice with old bookmarks
  {
    path: '/',
    redirect: '/SelectFarm'
  },
  {
    path: '/Home',
    name: 'Home',
    redirect: '/SelectFarm'
  },
  {
    path: '/#/SelectFarm',
    redirect: '/SelectFarm'
  },
  {
    path: '/SelectFarm#',
    redirect: '/SelectFarm'
  },
  {
    path: '/#',
    redirect: '/SelectFarm'
  },


  {
    path: '/ValidationNeeded',
    name: 'ValidationNeeded',
    component: ValidationNeeded
  },
  {
    path: '/SelectFarm/:farmId?',
    name: 'SelectFarm',
    component: SelectFarm,
    props: true,
    //beforeEnter: authGuard
  },
  {
    path: '/TransferFarms',
    name: 'TransferFarms',
    component: TransferFarms,
    //beforeEnter: authGuard
  },
  {
    path: '/AddFarm',
    name: 'AddFarm',
    component: AddFarm,
    //beforeEnter: authGuard
  },
  {
    path: '/AdminReportsDialog',
    name: 'AdminReportsDialog',
    component: AdminReportsDialog,
    //beforeEnter: authGuard
  },
  {
    path: '/FarmMapView/:farmId/:fieldId?',
    name: 'FarmMapView',
    component: FarmMapView,
    props: true,
  },
  {
    path: '/JohnDeere/:selectedOrganizationId?',
    name: 'JohnDeere',
    component: JohnDeere,
    props: true,
  }
]

const history = VueRouter.createWebHistory(import.meta.env.BASE_URL)

export default VueRouter.createRouter({
  routes,
  history,
  scrollBehavior(to, _from, savedPosition) {
    if (("SelectFarm" === to.name) && to.params.farmId) {
      const el = document.getElementById(`farm-accordion-${to.params.farmId}`)
      if (el) {
        el.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
      }
    }

    return null
  }
})

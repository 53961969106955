<template>
    <label :for="id" class="radio_button_label">
        <input type="radio" :id="id" :name="name" v-model="model" :value="props.radioValue" @change="handleChange" />
        <pp4-icon v-bind="iconProps" :faColorClass="computedColorClass" />
    </label>
</template>

<script setup>
import { computed } from 'vue'

import Pp4Icon from '@/components/Pp4Icon'

const model = defineModel()

const props = defineProps({
    id: {
        required: true
    },
    name: {
        required: true
    },

    //icon related props
    label: {},
    description: {},
    disabled: {},
    faIcon: {},
    image: {},
    big: {},

    radioValue: null,

    faCheckedColorClass: {},
    faUncheckedColorClass: {}
})

const selected = computed(() => {
    return model.value === props.radioValue
})

const iconProps = computed(() => {
    return {
        label: props.label,
        description: props.description,
        disabled: props.disabled,
        faIcon: props.faIcon,
        image: props.image,
        big: props.big
    }
})


const computedColorClass = computed(() => {
    return selected.value ?
        'radio_button_selected' : 'radio_button_selectable'
})
</script>

<style lang="css" scoped>
.radio_button_label {
    font-size: initial;
}

input[type="radio"] {
    opacity: 0;
    display: none;
}

label {
    overflow: hidden;
    border-radius: 0px;
}
</style>

<template>
    <water-source v-for="irrigationSystem in farm.irrigationSystems"
        :key="'SelectableIrrigationSystem:' + irrigationSystem.id" @click="emitSelection(irrigationSystem)"
        :location="irrigationSystem.waterSourceLocation" :clickable="true" :showLabel="true" :name="irrigationSystem.name"
        selectionStyle="selectable" />
</template> 

<script>
import WaterSource from '@/components/maps/irrigation_systems/WaterSource'

export default {
    components: {
        WaterSource
    },
    props: {
        farm: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
        }
    },
    emits: ['changed'],
    methods: {
        emitSelection(irrigationSystem) {
            this.$emit('changed', irrigationSystem)
        }
    },
    mounted() {
        this.$store.dispatch('mapObjects/setHelpKey', 'select_water_sources')
    }
}
</script>

<style lang="css"></style>

<script>

import { GeoUtil } from '../../geo/GeoUtil'

import Pp4MapObject from './Pp4MapObject'

export default {
    extends: Pp4MapObject,
    computed: {
        boundsGeo: (vm) => vm.provider.boundsGeo,
        zoomLevel: (vm) => vm.provider.zoomLevel,
    },
    watch: {
        boundsGeo: 'updateClusters'
    },
    methods: {
        updateClusters() {
            this.clearMapObjects()

            if(this.zoomLevel >= this.$store.state.preferences.ui.filterIndividualFieldsBelowZoomLevel) {
                return
            }

            let selectedFarm = this.$store.state.selectedFarm
            if(selectedFarm == null) {
                return
            }

            if(selectedFarm.fields.length < this.$store.state.preferences.ui.fieldCountNeededToTriggerFiltering) {
                return
            }

            let fieldPoints = selectedFarm.fields.map((field) => {
                let ret = []
                field.layouts.forEach((layout) => ret.push(layout.path[0]))
                
                return ret
            })

            fieldPoints = fieldPoints.filter((geo) => {
                return GeoUtil.GeoJson.booleanContains(this.boundsGeo, geo)
            })
            
            if(fieldPoints.length < 1) {
                return
            }

            let fieldPointsFeatureCollection = GeoUtil.GeoJson.featureCollection(fieldPoints)
            let fieldPointsWithClustersInfo = GeoUtil.GeoJson.clustersKmeans(fieldPointsFeatureCollection, 
                {numberOfClusters: (Math.min(16, Math.round(Math.sqrt(fieldPoints.length)))), mutate: true})

            let clusters = new Map()
            fieldPointsWithClustersInfo.features.forEach((feature) => {
                let clusterId = feature.properties.cluster
                let centroid = feature.properties.centroid

                let clusterInfo = clusters.get(clusterId)
                if(! clusterInfo) {
                    clusters.set(clusterId, {
                        featureCollection: GeoUtil.GeoJson.featureCollection([feature]),
                        fieldCount: 1, latLng: GeoUtil.Coords.toLatLng(centroid)
                    })
                }
                else {
                    clusterInfo.fieldCount += 1
                    clusterInfo.featureCollection.features.push(feature)
                }
            }, {})

            for (const [clusterId, cluster] of clusters.entries()) {
                let bbox = GeoUtil.GeoJson.bbox(cluster.featureCollection)
                let bboxPolygon = GeoUtil.GeoJson.bboxPolygon(bbox)
                let latLngs = GeoUtil.GeoJson.toLatLngs(bboxPolygon)
                let bboxCentroid = GeoUtil.GeoJson.centerOfMass(bboxPolygon)
                let bboxCentroidLatLng = GeoUtil.GeoJson.toLatLngs(bboxCentroid)

                let clickCallback = () => {
                    let latLngBounds = this.googleMapUtils.bboxToLatLngBounds(bbox)
                    this.map.fitBounds(latLngBounds, 100)
                }

                let poly = this.interpretationFactory.buildPoly(latLngs, 
                    {strokeColor: 'yellow'})
                this.mapObjects.push(poly)
                this.api.event.addListener(poly, 'click', clickCallback)

                let overlay = this.interpretationFactory.buildLabelOverlay(
                    cluster.fieldCount.toString() + " Fields", 
                    bboxCentroidLatLng, {elementClass: this.$style['field_cluster_label'], 
                    targetPane: 'overlayMouseTarget'})
                overlay.element.onclick = clickCallback

                this.mapObjects.push(overlay)
            }
        },
        mapReady() {
        }
    },
    render() {
        return null
    }
}
</script>

<style lang="css" module>
.field_cluster_label {
    font-size: medium;
    color: rgba(255, 255, 0, 0.7);
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    padding: 0.5em;
    border-radius: var(--softer-border-radius);
    cursor: pointer;
}
</style>
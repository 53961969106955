<template>
    <template>
        <remove-points v-if="editMode == GeometryEditMode.RemovePoints" :points="path"
            @remove="pointRemoved"/>
        <pp4-label v-if="labelProps" v-bind="labelProps" :clickable="clickable" @click="onClick"/>
        <slot/>
    </template> 
</template>

<script>
import { toRaw } from 'vue'
import { GeoUtil } from '@/geo/GeoUtil'
import { GeometryEditMode } from '@/store/Dto'

import Vars from '@/Vars'
import RemovePoints from '@/components/maps/RemovePoints'
import Pp4MapObject from '@/components/maps/Pp4MapObject'

export default {
    extends: Pp4MapObject,
    components: {RemovePoints},
    props: {
        path: {
            type: Array,
            required: true
        },
        clickable: {
            type: Boolean,
            required: false,
            default: true
        },
        strokeColor: {
            type: String,
            required: false,
            default: Vars.SelectedFieldStrokeColor
        },
        fillColor: {
            type: String,
            required: false,
            default: 'black'
        },
        fillOpacity: {
            required: false,
            default: 0.5
        },
        strokeWeight: {
            required: false,
            default: 3
        },
        strokeOpacity: {
            required: false,
            default: 1.0
        },
        zIndex: {
            type: Number,
            required: false,
            default: 0
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        labelClass: {
            type: String,
            required: false,
            default: null
        },
        showLabel: {
            type: Boolean,
            required: false,
            default: true
        },
        infoWindowContent: {
            type: String,
            required: false,
            default: null
        },
        editMode: {
            type: Number,
            required: false,
            default: GeometryEditMode.None,
            validator: function(value) { return value == GeometryEditMode.None || value == GeometryEditMode.EditPoints || value == GeometryEditMode.RemovePoints }
        }
    },
    computed: {
        geo: (vm) => GeoUtil.LatLngs.toPolygon(vm.path),
        centerLatLng: (vm) => {
            const centerGeo = GeoUtil.GeoJson.centerOfMass(vm.geo)
            return GeoUtil.GeoJson.toLatLngs(centerGeo)
        },
        labelProps: vm => {
            if(! (vm.showLabel && vm.label?.length)) {
                return null
            }


            let ret = {
                zIndex: vm.zIndex + 1
            }

            if(vm.labelClass) {
                ret['elementClass'] = vm.labelClass
            }

            ret.labelText = vm.label
            ret.latLng = vm.centerLatLng
            ret.clickable = vm.clickable
            
            return ret
        }
    },
    watch: {
        path: 'updateOrCreatePoly',
        strokeColor: 'updateOrCreatePoly',
        strokeWeight: 'updateOrCreatePoly',
        strokeOpacity: 'updateOrCreatePoly',
        fillColor: 'updateOrCreatePoly',
        fillOpacity: 'updateOrCreatePoly',
        showLabel: 'updateOrCreatePoly',
        clickable: 'invokeMapReadyIfAvailable',
        infoWindowContent: 'invokeMapReadyIfAvailable',
        zIndex: 'invokeMapReadyIfAvailable',
        editMode: 'activateEditMode'
    },
    data: function() {
        return {
            GeometryEditMode,
            polyMapObject: null,
            infoWindowObject: null,
            removePointMarkers: []
        }
    },
    methods: {
        updateOrCreatePoly() {
            let options = {
                path: this.path,
                editable: false,
                clickable: this.clickable,
                strokeColor: this.strokeColor,
                strokeWeight: this.strokeWeight,
                strokeOpacity: this.strokeOpacity,
                fillColor: this.fillColor,
                fillOpacity: this.fillOpacity,
                zIndex: this.zIndex
            }

            if(this.polyMapObject == null) {
                this.polyMapObject = this.interpretationFactory.buildPoly(this.path, options)

                this.mapObjects.push(this.polyMapObject)
            }
            else {
                this.polyMapObject.setOptions(options)
            }

            this.updateEventListeners()

            this.activateEditMode()
        },
        onClick(e) {
            this.$emit('click', e)
        },
        updateEventListeners() {
            const events = Object.keys(this.$attrs)
            events.forEach((attrEvent) => {
                const event = attrEvent.slice(2).toLowerCase()

                this.api.event.clearListeners(this.polyMapObject, event)

                this.api.event.addListener(this.polyMapObject, event, (e) => {
                    this.$emit(event, e)
                })
            })
        },
        updateInfoWindow() {
            if(this.infoWindowContent == null || this.polyMapObject == null || this.infoWindowContent.length < 1) {

                if(this.infoWindowObject) {
                    this.removeMapObject(this.infoWindowObject)
                    this.infoWindowObject = null
                }

                return
            }

            let bounds = this.googleMapUtils.objectBounds(this.polyMapObject)

            let infoWindow = this.interpretationFactory.buildInfoWindow(this.infoWindowContent)
            infoWindow.setPosition(bounds.getCenter())

            this.api.event.addListener(this.polyMapObject, 'click', (e) => {
                infoWindow.open(this.map)
            })

            this.mapObjects.push(infoWindow)
        },
        activateEditMode() {
            toRaw(this.polyMapObject).setOptions({
                editable: false
            })

            if(this.polyMapObject == null) {
                return
            }

            if(this.editMode == GeometryEditMode.EditPoints) {
                this.activateEditPointsMode()
            }
            // else if(this.editMode == GeometryEditMode.RemovePoints) {
            //     lets handle this with <RemovePoints> element
            // }
            else {
                toRaw(this.polyMapObject).setOptions({path: this.path})
            }
        },
        activateEditPointsMode() {
            this.polyMapObject.setOptions({
                editable: true
            })

            let path = this.polyMapObject.getPath()
            this.api.event.addListener(path, 'set_at', () => this.emitPolyChanged())
            this.api.event.addListener(path, 'insert_at', () => this.emitPolyChanged())
            this.api.event.addListener(path, 'remove_at', () => this.emitPolyChanged()) //for the undo events
        },
        pointRemoved(newPath) {
            this.$emit("poly_changed", newPath)
        },
        emitPolyChanged() {
            let latLngs = this.googleMapUtils.buildPathFromGooglePoly(this.polyMapObject)
            this.$emit("poly_changed", latLngs)
        },
        mapReady() {
            this.updateOrCreatePoly()
            this.updateInfoWindow()
        }
    },
    // emits: ['poly_changed', 'click', 'mousemove', 'mouseover', 'mouseout'],
}
</script>

<template>
  <div>
    <span class="filter">        
        <input  class="filter_bar" type="text" @focus="searchFieldFocus" onblur="this.placeholder='Filter Users'" placeholder="Filter Users" v-bind:value="search" ref="searchField"
        @input='updateFilteredList($event.target.value)'>
        <a v-if="search.length > 0" @click="clearFilter()" class="button clear_button">Clear</a>

    </span>
    <span v-if="search.length > 0" class="search_length_label">
        <label v-text="`${this.filteredList.length}/${this.users.length} users`"></label>
    </span>

    <div class="widget_margin_top" v-for="(user) in filteredList.slice(0, 20)" :key="user._id">
        <div class="user_label button button_margin_top" @click="userClicked(user)">
            <label :title="user.email">{{user.email}} ({{ user?.userId?.split('|')[0] }})</label>
        </div>
    </div> 
  </div>
</template>

<script>
export default {
    props: {
        allowSelfSelect: {
            type: Boolean,
            default: true,
            required: false
        }
    },
    data: function() {
        return{
            search: '',
            disabled: 0,
            modifiedUserList: []
       }
    },

    computed: {
        filteredList() {
            return  this.modifiedUserList.filter(user => { 
                if(user.email){
                    return user.email.toLowerCase().includes(this.search.toLowerCase())
                }else if (user.Email){
                    user.email = user.Email
                    delete user.Email
                    return user.email.toLowerCase().includes(this.search.toLowerCase())
                }
            })
        },
        users: vm => vm.$store.state.users,
        puppetUser: vm => vm.$store.state.puppetUser
    },
    watch: {
        users: 'userListUpdated'
    },
    methods: {
        searchFieldFocus() {
            this.$refs.searchField.placeholder = ' '
        },
        userListUpdated() {
            this.modifiedUserList = this.users

            if(! this.allowSelfSelect) {
                this.modifiedUserList.forEach((user, i) => {
                    if(user.userId === this.$auth.user.sub){
                        this.modifiedUserList.splice(i, 1)
                    }
                })
            }
        },
        filteredLists(){
            return  this.modifiedUserList.filter(user => { 
                return user.email.toLowerCase().includes(this.search.toLowerCase())
            })
            },
        updateFilteredList(value){
            this.search = value
            this.filteredLists()
            },
        clearFilter(){
            this.search = ''
            },
        async userClicked(user) {
            this.$emit('userSelected', user)
            //emit user was clicked
            },
        cancel: function() {
            this.$emit('cancel')
            }
    },
    mounted: async function() {
        this.userListUpdated()

        await this.$store.dispatch('getUsersFromService')

        this.$refs.searchField.focus()
    }
}
</script>

<style lang="css" scoped>
input[type="text"]:disabled{
    background: #787878;
    border-color: black;
}
#user_list{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap
}
.user_label{
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    overflow: visible;
    float: left;
    line-height: 2em;
    vertical-align: middle;
}
.filter_bar{
  min-width: 3em;
  text-align: center;
  white-space: nowrap;
  border-radius: var(--softer-border-radius);
  text-decoration: none;
  display:inline-block;
  cursor:pointer;
  font-family: var(--font-default);
  padding: 0.45em 1.1em;
  font-weight: bold;
  vertical-align: middle;
}
.filter {
  text-align:center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
}
.search_length_label {
  min-width: 3em;
  text-align: center;
  white-space: nowrap;
  border-radius: var(--softer-border-radius);
  text-decoration: none;
  display:inline-block;
  cursor:pointer;
  font-family: var(--font-default);
  padding: 0.45em 1.1em;
  font-weight: bold;
  margin-left: 30%;
  vertical-align: middle;
}
.clear_button {
    margin-left: 1em;
}
</style>
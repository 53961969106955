<template>
  <div class="printable-page">
    <img class="delta_plastics_logo" src='/DPREV_bg0.svg' alt="Delta Plastics Logo">
    
  <div class="summary_and_image_div">
      <div class="summary_column">
        <table class="summary_table">

          <!-- special header for John Deere fields -->
          <template v-if="rolledUpHoleDesign.jd">
            <tr class="jd">
              <td>
                <b>Organization:</b>
              </td>
              <td>{{ rolledUpHoleDesign.jd.organization?.name || 'N/A' }}</td>
            </tr>
            <tr class="jd">
              <td>
                <b>Client:</b>
              </td>
              <td>{{ rolledUpHoleDesign.jd.client?.name || 'N/A' }}</td>
            </tr>
            <tr class="jd">
              <td>
                <b>Farm:</b>
              </td>
              <td>{{ rolledUpHoleDesign.jd.farm?.name || 'N/A' }}</td>
            </tr>
            <tr class="jd">
              <td>
                <b>Field:</b>
              </td>
              <td>{{ rolledUpHoleDesign.jd.field?.name || 'N/A' }}</td>
            </tr>
            <tr class="jd">
              <td>
                <b>Boundary:</b>
              </td>
              <td>{{ rolledUpHoleDesign?.jd?.boundary?.name || 'N/A' }}</td>
            </tr>
            
            <tr>
              <td>
                <b>PP4 Field Name:</b>
              </td>
              <td>{{ rolledUpHoleDesign.field.name || 'N/A' }}</td>
            </tr>
            <tr v-if="rolledUpHoleDesign.furrowSet">
              <td>
                <b>Set Name:</b>
              </td>
              <td>{{ rolledUpHoleDesign.furrowSet.name }}</td>
            </tr>
          </template>

          <!-- regular Pipe Planner header -->
          <template v-else>
            <tr>
              <td>
                <b>Farm:</b>
              </td>
              <td>{{ rolledUpHoleDesign.farmName }}</td>
            </tr>
            <tr>
              <td>
                <b>Field:</b>
              </td>
              <td>{{ rolledUpHoleDesign.field.name }}</td>
            </tr>
            <tr>
              <td>
                <b>Layout: </b>
              </td>
              <td>
                {{ rolledUpHoleDesign.fieldLayout.name }}
              </td>
            </tr>
            <tr v-if="rolledUpHoleDesign.furrowSet">
              <td>
                <b>Set Name:</b>
              </td>
              <td>{{ rolledUpHoleDesign.furrowSet.name }}</td>
            </tr>
          </template>


          <tr>
            <td>
              <b>Watered Area:</b>
            </td>
            <td>{{ Math.round(rolledUpHoleDesign.wateredAreaInAcres, 1) }} ac</td>
          </tr>
          <tr v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Furrows">
            <td>
              <b>Furrow Count:</b>
            </td>
            <td>{{ rolledUpHoleDesign.sidedWateredAreaCount }}</td>
          </tr>
          <tr v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Levees">
            <td>
              <b>Paddies:</b>
            </td>
            <td>{{ rolledUpHoleDesign.sidedWateredAreaCount }}</td>
          </tr>
          <tr v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Furrows">
            <td>
              <b>Hole Spacing:</b>
            </td>
            <td>{{ rolledUpHoleDesign.holeSpacing }}</td>
          </tr>
          <tr v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Furrows">
            <td>
              <b>Uniformity:</b>
            </td>
            <td>{{ rolledUpHoleDesign.uniformity }}</td>
          </tr>
          <tr>
            <td>
              <b>Min Head Pressure:</b>
            </td>
            <td>{{ rolledUpHoleDesign.minHeadPressureInFeet + " ft" }}</td>
          </tr>
          <tr>
            <td>
              <b>Max Head Pressure:</b>
            </td>
            <td>{{ rolledUpHoleDesign.maxHeadPressureInFeet + " ft" }}</td>
          </tr>
          <tr v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Furrows">
            <td>
              <template>
                <b>Flow Per Furrow:</b>
              </template>
            </td>
            <td>{{ rolledUpHoleDesign.flowPerAreaInGpm + " gpm" }}</td>
          </tr>
          <tr>
            <td>
              <b>Watering Time:</b>
            </td>
            <td>{{ rolledUpHoleDesign.formattedWateringTime }}</td>
          </tr>
          <tr>
            <td>
              <b>Flow Rate:</b>
            </td>
            <td>{{ rolledUpHoleDesign.flowRateInGpm + " gpm" }}
            </td>
          </tr>
          <tr>
            <td>
              <b>Pipe Length:</b>
            </td>
            <td>{{ rolledUpHoleDesign.pipeLengthInFeet + " ft" }}</td>
          </tr>
        </table>

        <div class="hole_design_export_buttons widget_margin_top">
          <a class="button mobile-hide" v-on:click="createPdf('print')">
            <i class="fa fa-print fa_button_icon"></i> Print
          </a>
          <a class="button widget_margin_left" v-on:click="createPdf('save')">
            <i class="fa fa-save fa_button_icon"></i> Download
          </a>
          <a class="button widget_margin_left mobile-hide" v-on:click="createPdf('preview')">
            <i class="fa fa-file fa_button_icon"></i> Preview
          </a>
        </div>
      </div>

      <div class="map_image_container">
        <img :src="rolledUpHoleDesign.pipeImage" :draggable="false" />
      </div>
    </div>

    <div class="centered_stuff">
      <template v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Levees">
        <div class="levee_elevation_form_wrapper_div">
          <levee-elevation-form ref="leveeElevationForm" :fieldLayout="rolledUpHoleDesign.fieldLayout" />
        </div>
      </template>
      <template v-else>
        <pressure-range-edit ref="minAndMaxPressureRanges" v-model="minMaxHeadOverrides" class="hole_size_fieldsets" />
        <available-hole-sizes-fieldsets class="hole_size_fieldsets" :pipePath="rolledUpHoleDesign.pipePath"
          v-model="availableHoleSizes" />
      </template>
    </div>

    <div class="centered_stuff">
      <a class="button mobile-hide" v-if="!this.holeDesignError" text="Log Irrigation Events"
        @click="irrigatePipePath(rolledUpHoleDesign.field, rolledUpHoleDesign.fieldLayout, rolledUpHoleDesign.pipePath)">
        <i class="fa fa-tint fa_button_icon"></i>Irrigation
      </a>
      <a class="button" @click="applyPatches">Apply Changes</a>
    </div>

    <div class="hole_design_table_wrapper_div">
      <div class="header_legend">
        <h4 class="widget_margin_top" style="color:#005838; margin-bottom:0px">Hole Design</h4>
        <legend v-if="rolledUpHoleDesign.fieldLayout.mode != FieldMode.Levees" style="color: var(--bg0); border: none">
          Hole size colors are
          based off Poly Piranha </legend>
      </div>
      <table v-if="this.holeDesignError === null && this.noHoleSizesSelected == false" class="pipe_segment_table">
        <thead>
          <tr class="mobile-hide">
            <th>Pipe Size</th>
            <th>Pipe Function</th>
            <th>Pipe Length (ft)</th>
            <th v-if="rolledUpHoleDesign.fieldLayout.mode != FieldMode.Levees">Hole Size</th>
            <th colspan="2" v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Levees">Gate</th>
            <th v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Furrows">
              Furrow Count
            </th>
            <th v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Levees">
              Paddy
            </th>
            <th v-if="rolledUpHoleDesign.hasBuildups">Build Up (ft)</th>
          </tr>
          <tr class="mobile-show">
            <th>Pipe Size</th>
            <th class="mobile-hide">Function</th>
            <th>Length (ft)</th>
            <th v-if="rolledUpHoleDesign.fieldLayout.mode != FieldMode.Levees">Hole Size</th>
            <th colspan="2" v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Levees">Gate</th>
            <th v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Furrows">
              Furrows
            </th>
            <th v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Levees">
              Paddy
            </th>
            <th v-if="rolledUpHoleDesign.hasBuildups" class="mobile-hide">
              Build Up (ft)
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="rolledUpBranch in rolledUpHoleDesign.rolledUpWateredBranches">
            <tr v-if="showBranchNames" :key="rolledUpBranch.id + ':BranchName'">
              <td class="branch_name" :colspan="branchColspan">{{ rolledUpBranch.name }}</td>
            </tr>

            <tr v-for="(wa, i) in rolledUpBranch.rolledUpWateredAreas"
              :key="rolledUpBranch.id + ':RolledUpWateredArea:' + i">
              <td>{{ wa.pipeSize }}</td>
              <td class="mobile-hide">{{ wa.pipeFunction }}</td>
              <td>{{ wa.pipeLength }}</td>

              <td v-if="wa.pipeFunction === 'Irrigation' && rolledUpHoleDesign.fieldLayout.mode === FieldMode.Levees" className="gate">
                <a @click="showGateConfigImage">{{ wa.gatesLeft }}</a>
              </td>

              <td v-if="wa.pipeFunction === 'Irrigation' && rolledUpHoleDesign.fieldLayout.mode === FieldMode.Levees" className="gate ml-1 mr-1">
                <a @click="showGateConfigImage">{{ wa.gatesRight }}</a>
              </td>

              <td v-if="rolledUpHoleDesign.fieldLayout.mode !== FieldMode.Levees">
                <i v-if="wa.holeSize.colorLeft" class="fa fa-circle" :style="holeSizeColor(wa.holeSize.colorLeft)"></i>

                {{ wa.holeSize.name }}

                <i v-if="wa.holeSize.colorRight" class="fa fa-circle" :style="holeSizeColor(wa.holeSize.colorRight)"></i>
              </td>
              <td v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Furrows">{{ wa.sidedWateredAreaCount }}</td>
              <td v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Levees">{{ wa.wateredAreaRange }}</td>
              <td v-if="rolledUpHoleDesign.hasBuildups" class="mobile-hide">{{ wa.buildupNeededInFeet != null ?
                wa.buildupNeededInFeet : "" }}</td>
            </tr>
          </template>
        </tbody>
      </table>

      <div v-show="moreGateConfigImageActive" class="gate_image_div">
        <img :src="GatePositionImage" :draggable="false">
      </div>

      <div v-if="rolledUpHoleDesign.wateringEvents"
        class="hole_design_table_wrapper_div">
        <h4 class="widget_margin_top" style="color:#005838; margin-bottom:0px">Irrigation Events</h4>
        <table class="pipe_segment_table">
          <thead>
            <tr class="mobile-hide">
              <th>Start</th>
              <th>Stop</th>
              <th>Duration (hrs)</th>
              <th>Water (gal)</th>
              <th>Moisture Method</th>
              <th>Measurement</th>
              <!-- <th>User</th> -->
            </tr>
            <tr class="mobile-show">
              <th>Start</th>
              <!-- <th class="mobile-hide">Stop</th> -->
              <th>Duration (hrs)</th>
              <th>Water (gal)</th>
              <!-- <th>Moisture Method</th> -->
              <!-- <th>Measurement</th> -->
              <!-- <th>User</th> -->
            </tr>
          </thead>
          <tbody>
              <tr v-for="event in rolledUpHoleDesign.wateringEvents" :key="event.id">
                <td>{{ wateringEventDateTimeString(event.start) }}</td>
                <td class="mobile-hide">{{ wateringEventDateTimeString(event.stop) }}</td>
                <td>{{ event.duration }}</td>
                <td>{{ event.gallons ? event.gallons.toFixed(0) : 0 }}</td>
                <td class="mobile-hide">{{ event.moistureMethod }}</td>
                <td class="mobile-hide">{{ event.measurement }}</td>
              </tr>
              <tr>
                <td><b>Total</b></td>
                <td class="mobile-hide"></td>
                <td><b>{{ totalWateringEvent.duration }}</b></td>
                <td><b>{{ totalWateringEvent.gallons.toFixed(0) }}</b></td>
                <td class="mobile-hide"></td>
                <td class="mobile-hide"><b>{{ totalWateringEvent.measurement }}</b></td>
                <td></td>
              </tr>
          </tbody>
        </table>
      </div>

      <div>
        <div class="hole_design_failed " v-if="this.noHoleSizesSelected == true && !this.holeDesignError">
          <h4 class="">No Hole sizes Selected</h4>

        </div>
        <a class="hole_design_failed widget_margin_top widget_margin_left mobile-show" v-if="this.holeDesignError"> Hole
          Design Failed </a>
      </div>

      <div>
        <div class="hole_size_fieldsets mobile-hide" v-if="this.holeDesignError">

          <a class="hole_design_failed widget_margin_top widget_margin_left"> Hole Design Failed </a> <br><br>
          <pre class="widget_margin_top" v-html="this.warning"></pre>
          <div class="hole_design_failed widget_margin_left" v-if="this.noHoleSizesSelected && this.holeDesignError">
            <a class="">No Hole sizes Selected</a>
          </div>
        </div>
      </div>
      <div class="more_details_container widget_margin_top">
        <h4 class="more_details_bar" :class="!moreDetailsActive ? 'more_details_bar_closed' : ''"
          @click="showMoreDetails">More Details
          <a v-show="!this.moreDetailsActive" class="icon" href="#"><i class="fa fa-angle-down"></i></a>
          <a v-show="this.moreDetailsActive" class="icon" href="#"><i class="fa fa-angle-up"></i></a>
        </h4>

        <div class="more_details_table_outer" v-if="this.moreDetailsActive">
          <table class="more_details_table">
            <thead>
              <tr class="more_details_table_headers">
                <th>Length(ft)</th>
                <th v-if="rolledUpHoleDesign.fieldLayout.mode != FieldMode.Levees">Hole Size</th>
                <th v-if="rolledUpHoleDesign.fieldLayout.mode === FieldMode.Levees">Gate</th>
                <th>Pipe Function</th>
                <th v-show="rolledUpHoleDesign.fieldLayout.mode == FieldMode.Furrows">Hole Spacing(ft)</th>
                <th v-show="rolledUpHoleDesign.fieldLayout.mode == FieldMode.Furrows">Furrow Spacing(ft)</th>
                <th v-show="rolledUpHoleDesign.fieldLayout.mode == FieldMode.Furrows">Furrow length(ft)</th>
                <th>Elevation(ft)</th>
                <th>Pressure(ft)</th>
                <th v-if="rolledUpHoleDesign.hasBuildups">Build Up (ft)</th>
                <th>Flow(gpm)</th>
                <th>Coverage(%)</th>
              </tr>
            </thead>
            <tbody>
              <template v-for="rolledUpBranch in rolledUpHoleDesign.rolledUpWateredBranches">
                <tr v-if="showBranchNames" :key="rolledUpBranch.id + ':BranchName'">
                  <td class="branch_name" :colspan="rolledUpHoleDesign.fieldLayout.mode == 1 ? 12 : 8"
                    @click.self="toggleBranchDetails(rolledUpBranch.id)">{{ rolledUpBranch.name }}
                    <a v-show="!showBranchDetails(rolledUpBranch.id)" class="branch_icon" href="#"><i
                        class="fa fa-angle-down"></i></a>
                    <a v-show="showBranchDetails(rolledUpBranch.id)" class="branch_icon" href="#"><i
                        class="fa fa-angle-up"></i></a>
                  </td>
                </tr>
                <tr v-for="(details, i) in rolledUpBranch.moreDetailsRows" :key="rolledUpBranch.id + ':WateredArea:' + i"
                  v-show="showBranchDetails(rolledUpBranch.id)">
                  <!--length -->
                  <td>{{ details.lengthInFeet }}</td>
                  <!--pipesize -->
                  <td>{{ (details.holeSize.name === null && i == 0) ? 'Colors based off of Poly Piranha' :
                    details.holeSize.name }}</td>
                  <!--pipe function -->
                  <td>{{ details.function }}</td>
                  <!--hole spacing -->
                  <td v-show="rolledUpHoleDesign.fieldLayout.mode == FieldMode.Furrows">{{ details.holeSpacingInFeet }}
                  </td>
                  <!-- furrow spacing -->
                  <td v-show="rolledUpHoleDesign.fieldLayout.mode == FieldMode.Furrows">{{ details.furrowSpacing }}</td>
                  <!-- furrow length -->
                  <td v-show="rolledUpHoleDesign.fieldLayout.mode == FieldMode.Furrows">{{ details.furrowLength }}</td>
                  <!-- elevation-->
                  <td>{{ details.elevationInFeet }}</td>
                  <!-- pressure -->
                  <td>{{ details.pressure }}</td>
                  <!-- build up -->
                  <td v-show="rolledUpHoleDesign.hasBuildups">{{ details.buildUp }}</td>
                  <td>{{ details.flowInGpm }}</td>
                  <td>{{ details.coverage }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRaw } from 'vue'

import HoleDesignPrinter from '@/print/HoleDesignPrinter'
import { FieldMode } from '@/store/Dto'
import AvailableHoleSizesFieldsets from '@/components/maps/irrigation_systems/AvailableHoleSizesFieldsets'
import LeveeElevationForm from '@/components/maps/irrigation_systems/LeveeElevationForm'
import PressureRangeEdit from '@/components/maps/irrigation_systems/PressureRangeEdit'
import WateringDialog from '@/components/WateringDialog'
import GatePositionImage from '@/assets/Blue_Gate_Position.jpg'
import * as FormatAlg from '@/util/FormatAlg'

export default {
  components: {
    AvailableHoleSizesFieldsets, LeveeElevationForm,
    PressureRangeEdit
  },
  props: ['rolledUpHoleDesign', 'farm', 'jdObjects'],
  computed: {
    showBranchNames: (vm) => vm.rolledUpHoleDesign.rolledUpWateredBranches.length > 1,
    branchColspan: (vm) => vm.showBranchNames ? 6 : 5,
    warning: (vm) => vm.$store.state.holeDesignWarningMessage,
    holeDesignError: (vm) => vm.rolledUpHoleDesign.pipePath.designParams.holeDesign.error,
    furrowSetIndex: (vm) => {
      const id = (element) => vm.rolledUpHoleDesign.furrowSet.id == element.id
      if (!id) {
        return null
      }
      return (vm.rolledUpHoleDesign.fieldLayout.furrowSetDetails.resultingFurrowSets.findIndex(id) + 1) //TODO: need to go over this to use the layout rather than the field
    },
    totalWateringEvent: vm => {
      let totalGallons = 0;
      let totalDuration = 0;
      let totalMeasurement = 0;
      vm.rolledUpHoleDesign.wateringEvents.forEach(wateringEvent => {
        totalGallons += wateringEvent.gallons ? wateringEvent.gallons : 0;
        totalDuration += wateringEvent.duration ? parseInt(wateringEvent.duration) : 0;
        totalMeasurement += wateringEvent.measurement ? parseFloat(wateringEvent.measurement) : 0;
      });
      return {
        duration: totalDuration
        , measurement: totalMeasurement
        , gallons: totalGallons
      };
    },
  },
  data: function () {
    return {
      FieldMode,
      ShowHoleSizes: false,
      showPressureLimits: false,
      minMaxHeadOverrides: { min: null, max: null },
      availableHoleSizes: [],
      errorMessage: null,
      noHoleSizesSelected: false,
      moreDetailsActive: false,
      moreGateConfigImageActive: false,
      branchIds: [],
      GatePositionImage
    }
  },
  methods: {
    wateringEventDateTimeString: function (d) {
      const dateValue = new Date(d);
      return FormatAlg.wateringTime(dateValue)
    },
    holeSizeColor(holeSizeColor) {
      if (holeSizeColor == '#0F010D' || holeSizeColor == 'blue') {
        return 'color: ' + holeSizeColor
      }
      return 'color:' + holeSizeColor
    },
    showMoreDetails() {
      this.moreDetailsActive = !this.moreDetailsActive
    },
    showGateConfigImage() {
      this.moreGateConfigImageActive = !this.moreGateConfigImageActive
    },
    toggleBranchDetails(wateredBranchId) {
      if (this.branchIds.includes(wateredBranchId)) {
        const index = this.branchIds.indexOf(wateredBranchId)
        this.branchIds.splice(index, 1)
      }
      else {
        this.branchIds.push(wateredBranchId)
      }
    },
    showBranchDetails(wateredBranchId) {
      if (!this.branchIds.includes(wateredBranchId)) {
        return true
      }
      return false
    },
    async irrigatePipePath(field, fieldLayout, pipePath) {
      await this.$dialog.push(WateringDialog, {
        farm: this.farm, field, fieldLayout, pipePath
      })
    },
    async applyPatches() {
      const patches = []

      if (this.rolledUpHoleDesign.fieldLayout.mode === FieldMode.Levees) {
        patches.push(... this.$refs.leveeElevationForm.buildPatches())

        await this.$store.dispatch('patchFieldLayout', {
          field: this.rolledUpHoleDesign.field,
          fieldLayout: this.rolledUpHoleDesign.fieldLayout,
          patch: patches
        })

        return
      }

      patches.push(... this.buildMinMaxHeadPatches())
      patches.push(... this.buildAvailableHoleSizessPatches())

      const farm = this.rolledUpHoleDesign.farm
      const irrigationSystem = this.rolledUpHoleDesign.irrigationSystem
      const pipePath = this.rolledUpHoleDesign.pipePath

      await this.$store.dispatch('patchIrrigationSystemPipePath',
        { farm, irrigationSystem, pipePath, patch: patches })
    },
    buildAvailableHoleSizessPatches() {
      if (this.availableHoleSizes == null) {
        return []
      }

      return [
        {
          op: 'replace',
          path: '/availableHoleSizes',
          value: toRaw(this.availableHoleSizes),
        }
      ]
    },
    buildMinMaxHeadPatches() {
      let minHeadInFeet = this.minMaxHeadOverrides.min
      let maxHeadInFeet = this.minMaxHeadOverrides.max
      if (Number.isNaN(minHeadInFeet)) {
        minHeadInFeet = null
      }
      if (Number.isNaN(maxHeadInFeet)) {
        maxHeadInFeet = null
      }

      return [
        {
          op: 'replace',
          path: '/minHeadInFeet',
          value: minHeadInFeet
        },
        {
          op: 'replace',
          path: '/maxHeadInFeet',
          value: maxHeadInFeet
        },
      ]
    },
    createPdf: async function (mode) {
      let holeDesignPrinter = new HoleDesignPrinter()
      holeDesignPrinter.CreatePDF(mode, [this.rolledUpHoleDesign])
    }
  },
  beforeMount() {
    this.minMaxHeadOverrides = {
      min: this.rolledUpHoleDesign.pipePath.minHeadInFeet,
      max: this.rolledUpHoleDesign.pipePath.maxHeadInFeet
    }

    const availableHoleSizes = this.rolledUpHoleDesign?.pipePath?.availableHoleSizes

    const availableHoleSizesValid = availableHoleSizes.length ? true : false

    this.availableHoleSizes = availableHoleSizesValid ?
      availableHoleSizes : []
  }
}
</script>

<style lang="css" scoped>
@media (max-width: 720px) {
  .mobile-hide {
    display: none !important;
  }

  .more_details_bar {
    text-align: center;
  }
}

@media (min-width: 840px) {
  .mobile-show {
    display: none;
  }

  .hole_size_fieldsets {
    flex-direction: row;
  }
}

@media (max-width: 1000px) {
  .more_details_table {
    padding: 5px;
    background-color: var(--primary-lighter);
  }

  .more_details_table thead tr {
    background-color: var(--secondary-light);
  }
}

.header_legend {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.levee_elevation_form_wrapper_div {
  background-color: var(--secondary);
  border-radius: var(--general-border-radius);
  padding: var(--general-padding);
}

.delta_plastics_logo {
  align-self: center;
}

.mobile-buttons {
  text-align: center;
  vertical-align: baseline;
}

.icon {
  position: relative;
  float: right;
}

.branch_icon {
  position: relative;
  float: right;
  font-weight: bold;
  color: black !important;
}

.printable-page {
  display: flex;
  flex-direction: column;
  background-color: var(--primary-lighter);
  border-radius: var(--general-border-radius);
  row-gap: var(--general-padding);
  column-gap: var(--general-padding);
  padding: var(--general-padding);
}

.hole_design_table_wrapper_div {
  display: flex;
  flex-direction: column;
}

.more_details_table_outer {
  overflow-x: scroll;
}

.more_details_table {
  table-layout: auto;
  border-spacing: 2px;
  margin: 5px auto 0px auto;
  font-size: small;
}

.more_details_table tr {
  background-color: var(--primary-lighter);
}

.more_details_table thead tr {
  background-color: var(--secondary-light);
}

.more_details_table tr:hover {
  background: var(--primary-light);
  color: var(--bg0);
}

.branch_name:hover {
  background: var(--primary-light);
  color: var(--bg0);
}

.more_details_table td {
  overflow-wrap: break-word;
}

.pipe_segment_table {
  background-color: var(--primary-lighter);
}

.pipe_segment_table tr {
  background-color: var(--primary-lighter);
}

.pipe_segment_table tr:nth-child(even) {
  background-color: var(--primary-lighter);
}

.pipe_segment_table tr:hover {
  background: var(--primary-light);
  color: var(--bg0);
}

.pipe_segment_table thead tr {
  background-color: var(--secondary-light);
}

.pipe_segment_table tr td a {
  cursor: pointer;
  color: var(--bg0);
}

.gate_image_div {
  & img {
    width: 100%;
  }
}

.summary_and_image_div {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  row-gap: var(--general-padding);
  column-gap: var(--general-padding);
}

.summary_column {
  max-width: 40vw;
}

.map_image_container {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: red;
  max-width: 40vw;
}

.map_image_container img {
  width: 100%;
  height: 100%;
}

.centered_stuff {
  display: flex;
  flex-basis: auto;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: var(--general-padding);
  column-gap: var(--general-padding);
}

.more_details_container {
  margin-bottom: 0px;
  vertical-align: center;
  border-radius: var(--general-border-radius);
  background: var(--bg1);
}

.more_details_bar {
  cursor: pointer;
  background-color: var(--secondary);
  color: var(--fg0);
  padding: var(--general-padding);
  margin: 0px;
  border-top-left-radius: var(--softer-border-radius);
  border-top-right-radius: var(--softer-border-radius);
}

.more_details_bar_closed {
  border-bottom-left-radius: var(--softer-border-radius);
  border-bottom-right-radius: var(--softer-border-radius);
}

.more_details_table_outer {
  overflow-x: auto;
  padding: var(--general-padding);
}

.more_details_table {
  table-layout: auto;
  border-spacing: 2px;
  margin: 5px auto 0px auto;
}

.more_details_table tr {
  background-color: var(--primary-lighter);
}

.more_details_table thead tr {
  background-color: var(--secondary-light);
}

.more_details_table td {
  overflow-wrap: break-word;
}

tr.jd {
  background-color: var(--jd-green);
  color: var(--jd-yellow);
}

tr.jd td {
  padding: 0.35em;
}

.gate {
  text-decoration: underline;
}

.gate:hover {
  background: var(--secondary);
}

.gate:hover a {
  color: var(--fg0);
}

.hole_size_fieldsets {
  margin-top: 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  background-color: var(--bg1);
  border-radius: var(--general-border-radius);
  padding: var(--general-padding);
  align-self: stretch;
}

.branch_name {
  font-weight: bold;
  text-align: left;
  background-color: var(--secondary-light);
}

.hole_design_failed {
  background-color: #ff0033;
  display: inline-block;
  padding: 10px;
  border-radius: var(--general-border-radius);
  font-weight: bold;
}

.hole_design_export_buttons {
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>

<template>
    <fullpageform class="irrigation-events" title="Irrigation Events" @cancel="cancel">
        <template v-slot:float-right>
            <div class="action_buttons">
                <a class="button" v-on:click="cancel()"><i class="fa fa-times fa_button_icon"></i></a>
            </div>
        </template>

        <div class="watering_components">
            <watering-component
              v-for="o in finalPipePathsToShowWithMetadata"
              :key="o.pipePath.id"
              v-bind="o"/>
        </div>
    </fullpageform>
</template>

<script>
import WateringComponent from "@/components/WateringComponent.vue";

export default {
  components: {
    WateringComponent
  },
  props: {
    farm: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: false,
      default: null
    },
    fieldLayout: {
      type: Object,
      required: false,
      default: null
    },
    pipePath: {
      type: Object,
      required: false,
      default: null
    }
  },
  computed: {
    finalPipePathsToShowWithMetadata: (vm) => {
      let pipePaths = []
      if(vm.pipePath) {
        pipePaths.push(vm.pipePath)
      }
      else {
        const allPipePaths = vm.farm.irrigationSystems.reduce(
          (accum, irrigationSystem) => {
            accum.push(... irrigationSystem.pipePaths)
            return accum
          }, [])

        if(vm.fieldLayout) {
          pipePaths.push(... allPipePaths.filter(
            (pp) => pp.fieldLayoutId === vm.fieldLayout.id))
        }
        else if(vm.field) {
          pipePaths.push(... allPipePaths.filter(
            (pp) => pp.fieldId === vm.field.id))
        }
      }

      const withMetadata = pipePaths.map((pp) => vm.buildPipePathWithMetadata(pp))

      return withMetadata.filter((o) => o != null)
    }
  },
  data: function () {
    return {};
  },
  methods: {
    cancel: function() {
        this.$dialog.pop()
    },
    buildPipePathWithMetadata(pipePath) {
      const farm = this.farm

      const field = farm.fields.find((f) => f.id === pipePath.fieldId)
      if(! field) {
          return null
      }

      const fieldLayout = field.layouts.find((l) => l.id === pipePath.fieldLayoutId)
      if(! fieldLayout) {
          return null
      }

      const furrowSet = pipePath.furrowSetId ?
          fieldLayout.furrowSetDetails.resultingFurrowSets.find((s) => s.id === pipePath.furrowSetId) : null

      const irrigationSystem = farm.irrigationSystems.find(
        (i) => i.pipePaths.some((pp) => pp.id === pipePath.id))
      if(! irrigationSystem) {
          return null
      }

      return {
          farm, field, fieldLayout, furrowSet, irrigationSystem, pipePath
      }
    }
  },
  mounted: async function () {
  }
};

</script>

<style lang="css" scoped>
.watering_components {
  display: flex;
  flex-direction: column;
  row-gap: calc(var(--general-padding) * 2);
  margin: 5px;
}
</style>

import { computed, watch, reactive } from 'vue'

import { DesignParamsFactory } from '@/design/DesignParamsFactory'
import { FieldMode } from '@/store/Dto'
import sha1 from 'sha1'
import { version as Pp4DesignVersion } from '@pp4/design'

function delay(ms) {
  return new Promise(res => setTimeout(res, ms))
}

function BuildHoleDesignParametersChecksum(
  pipePath,
  field,
  fieldLayout,
  irrigationSystem,
  options = {}
) {
  let furrowSet = null
  if ('furrowSetId' in pipePath && pipePath.furrowSetId != null) {
    furrowSet = fieldLayout.furrowSetDetails.resultingFurrowSets.find(
      (furrowSet) => furrowSet.id === pipePath.furrowSetId
    )
    if (furrowSet == null) {
      return null
    }
  }

  if (!irrigationSystem) {
    return null
  }

  const pp4VersionNums = Pp4DesignVersion.split('.')
  if (!Number.isInteger(options.pp4DesignVersionMajor)) {
    options.pp4DesignVersionMajor = parseInt(pp4VersionNums[0])
  }
  if (!Number.isInteger(options.pp4DesignVersionMinor)) {
    options.pp4DesignVersionMinor = parseInt(pp4VersionNums[1])
  }

  const flowRate = irrigationSystem.flowRates
    ? irrigationSystem.flowRates.find((fr) => fr.id === pipePath.flowRateId)
    : null
  const flowRateInGpm = flowRate != null ? flowRate.valueInGpm : 0

  if (flowRate == null) {
    console.warn('Flow Rate Not Found: ' + pipePath.flowRateId + ' for Pipe Path: ' + pipePath.id)
  }

  const checksumJson = {
    pp4DesignVersionMajor: options.pp4DesignVersionMajor,
    pp4DesignVersionMinor: options.pp4DesignVersionMinor,
    fieldPath: fieldLayout.path,
    fieldMode: fieldLayout.mode,
    pipePath: pipePath.path,
    flowRateId: pipePath.flowRateId,
    flowRateInGpm,
    waterBothSides: pipePath.waterBothSides
  }

  if (fieldLayout.mode === FieldMode.Furrows) {
    // Add this if the way we calculate furrow geography changes meaningfully
    //checksumJson.FurrowGeoVersion = 15

    checksumJson.furrowBearing = fieldLayout.furrowBearing
    checksumJson.alternatingFurrows = fieldLayout.alternatingFurrows
    checksumJson.furrowSpacingInInches = fieldLayout.furrowSpacingInInches
    checksumJson.furrowBearing = fieldLayout.furrowBearing
      ; (checksumJson.furrowSetSplitPointLatLngs = fieldLayout.furrowSetDetails.splitPointLatLngs),
        (checksumJson.availableHoleSizes = pipePath.availableHoleSizes)
    checksumJson.minHeadInFeet = pipePath.minHeadInFeet
    checksumJson.maxHeadInFeet = pipePath.maxHeadInFeet

    if (Object.hasOwn(pipePath, 'waterOtherSide')) {
      checksumJson.waterOtherSide = pipePath.waterOtherSide
    }

  } else if (fieldLayout.mode === FieldMode.Levees) {
    // Increment this if the way we calculate levee geography changes meaningfully
    checksumJson.LeveeGeoVersion = 16
    checksumJson.leveePaths = fieldLayout.leveePaths
    checksumJson.leveeHeightInInches = fieldLayout.leveeHeightInInches
    checksumJson.fallPerLeveeInInches = fieldLayout.fallPerLeveeInInches
  }

  return sha1(JSON.stringify(checksumJson))
}

export function useHoleDesignsProcessor(store, { analytics }) {
  const $analytics = analytics

  const data = reactive({
    processing: false
  })

  const pipePathsWithStatus = computed(() => {
    const rootState = store.state

    if (rootState.selectedFarm == null) {
      return []
    }

    const ret = []
    rootState.selectedFarm.irrigationSystems?.forEach((irrigationSystem) => {
      irrigationSystem.pipePaths.forEach((pipePath) => {
        if (pipePath.zombie) {
          return
        }

        const field = rootState.selectedFarm.fields.find((field) => field.id === pipePath.fieldId)
        if (!field) {
          console.warn('Field Not Found for Pipe Path: ' + pipePath.fieldId)
          return
        }

        const fieldLayout = field.layouts.find((layout) => pipePath.fieldLayoutId == layout.id)
        if (!fieldLayout) {
          console.warn('Field Layout Not Found for Pipe Path: ' + pipePath.fieldLayoutId)
          return
        }

        const checksum = BuildHoleDesignParametersChecksum(
          pipePath,
          field,
          fieldLayout,
          irrigationSystem
        )
        if (!checksum) {
          return
        }

        const processed =
          'designParams' in pipePath &&
          pipePath.designParams != null &&
          pipePath.designParams.checksum === checksum

        ret.push({ pipePath, checksum, processed, field, fieldLayout, Pp4DesignVersion })
      })
    })

    return ret
  })

  const unprocessedPipePathsWithStatus = computed(() => {
    return pipePathsWithStatus.value.filter((o) => !o.processed)
  })

  function publishAnalyticsEvent(field, fieldLayout, pipePath, irrigationSystem, designParams) {
    $analytics.holeDesignCompleted(field, fieldLayout, pipePath, irrigationSystem, designParams)
  }

  async function runDesign(farm, pipePath, field, fieldLayout, irrigationSystem, checksum) {
    const designParamsFactory = new DesignParamsFactory(store.state.preferences)

    if (fieldLayout.mode == FieldMode.Furrows) {
      const result = designParamsFactory.buildFurrowDesignParamsAndDebugPolys(
        fieldLayout,
        pipePath,
        irrigationSystem
      )

      if (result.designParams) {
        result.designParams.checksum = checksum
        //we are removing design steps for the time being. to conserve space in mongo
        // We may add them back in the future.
        delete result.designParams.designSteps

        Object.assign(result.designParams.holeDesign, {
          farmId: farm.id,
          fieldId: field.id,
          fieldLayoutId: fieldLayout.id,
          irrigationSystemId: irrigationSystem.id,
          pipePathId: pipePath.id,
          checksum
        })

        store.dispatch('putHoleDesign', result.designParams.holeDesign)

        publishAnalyticsEvent(field, fieldLayout, pipePath, irrigationSystem, result.designParams)
      } else {
        console.warn('Furrow Hole Design Failure')
      }

      // if(result.debugPolys != null) {
      //     await this.$store.dispatch('mapObjects/debug/setGeoJsonPolys', result.debugPolys)
      // }
    } else if (fieldLayout.mode == FieldMode.Levees) {
      const result = designParamsFactory.buildLeveeDesignParamsAndDebugPolys(
        fieldLayout,
        pipePath,
        irrigationSystem
      )

      if (result.designParams != null) {
        result.designParams.checksum = checksum
        //we are removing design steps for the time being. to conserve space in mongo
        // We may add them back in the future.
        delete result.designParams.designSteps

        Object.assign(result.designParams.holeDesign, {
          farmId: farm.id,
          fieldId: field.id,
          fieldLayoutId: fieldLayout.id,
          irrigationSystemId: irrigationSystem.id,
          pipePathId: pipePath.id,
          checksum,
          error: null
        })

        store.dispatch('putHoleDesign', result.designParams.holeDesign)

        publishAnalyticsEvent(field, fieldLayout, pipePath, irrigationSystem, result.designParams)
      } else {
        console.warn('Levee Hole Design Failure')
      }

      // if(result.debugPolys != null) {
      //     await this.$store.dispatch('mapObjects/debug/setGeoJsonPolys', result.debugPolys)
      // }
    }
  }

  function markPipePathAsInvalidDueToCriticalError(
    farm,
    field,
    fieldLayout,
    irrigationSystem,
    pipePath,
    checksum,
    e
  ) {
    console.warn('Critical Exception While Processing Hole Designs')
    console.warn(e)

    const holeDesign = {
      farmId: farm.id,
      fieldId: field.id,
      fieldLayoutId: fieldLayout.id,
      irrigationSystemId: irrigationSystem.id,
      pipePathId: pipePath.id,
      checksum,
      error: {
        code: 'critical_error',
        error: e,
        errorString: 'toString' in e ? e.toString() : null
      }
    }

    store.dispatch('putHoleDesign', holeDesign)
  }

  async function processSomePipePath() {
    const todo = unprocessedPipePathsWithStatus.value

    if (!todo.length) {
      return
    }

    const pipePath = todo[0].pipePath
    const checksum = todo[0].checksum
    const Pp4DesignVersion = todo[0].Pp4DesignVersion
    const farm = store.state.selectedFarm
    const field = store.state.selectedFarm.fields.find((field) => field.id === pipePath.fieldId)
    const fieldLayout = field.layouts.find((layout) => layout.id == field.activeLayoutId)
    const irrigationSystem = store.state.selectedFarm.irrigationSystems.find((irrigationSystem) => {
      return irrigationSystem.flowRates.some((flowRate) => flowRate.id === pipePath.flowRateId)
    })
    if (field == null || irrigationSystem == null || fieldLayout == null) {
      console.warn('Missing Object References for PipePath: ' + pipePath.id)
      return
    }

    const timerId = 'Watering Plan Process ' + pipePath.id
    try {
      data.processing = true

      console.time(timerId)

      console.info(
        'Running Plan w/Checksum: ' + checksum + ', pp4-lib Version: ' + Pp4DesignVersion
      )

      await runDesign(farm, pipePath, field, fieldLayout, irrigationSystem, checksum)
    } catch (e) {
      markPipePathAsInvalidDueToCriticalError(
        farm,
        field,
        fieldLayout,
        irrigationSystem,
        pipePath,
        checksum,
        e
      )
    } finally {
      data.processing = false
      console.timeEnd(timerId)
    }
  }

  watch([unprocessedPipePathsWithStatus], processSomePipePath)

  processSomePipePath()

  return data
}

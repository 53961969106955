
<template>
    <pp4-marker :location="data.location" v-bind="markerProps" @dragstart="drag" @drag="drag" @dragend="dragEnd">
        <slot v-if="data.dragging" />
    </pp4-marker>
</template>

<script setup>
import { computed, reactive, provide } from 'vue'

import { LatLng, ConnectorType } from '@/store/Dto'
import { ZIndexes } from '@/maps/ZIndexes'
import PipePathAlg from '@/design/PipePathAlg'

import SelectedOpenEndIcon from '@/assets/selected_open_end_icon.svg'
import SelectableOpenEndIcon from '@/assets/selectable_open_end_icon.svg'
import UnselectableOpenEndIcon from '@/assets/unselectable_open_end_icon.svg'
import SelectedClosedEndIcon from '@/assets/selected_closed_end_icon.svg'
import SelectableClosedEndIcon from '@/assets/selectable_closed_end_icon.svg'
import UnselectableClosedEndIcon from '@/assets/unselectable_closed_end_icon.svg'
import SelectedInlineTeeIcon from '@/assets/selected_inline_tee_icon.svg'
import SelectableInlineTeeIcon from '@/assets/selectable_inline_tee_icon.svg'
import UnselectableInlineTeeIcon from '@/assets/unselectable_inline_tee_icon.svg'
import SelectedSurgeValveIcon from '@/assets/selected_surge_valve_icon.svg'
import SelectableSurgeValveIcon from '@/assets/selectable_surge_valve_icon.svg'
import UnselectableSurgeValveIcon from '@/assets/unselectable_surge_valve_icon.svg'
import SelectedPointIcon from '@/assets/selected_point.svg'
import SelectablePointIcon from '@/assets/selectable_point.svg'
import UnselectablePointIcon from '@/assets/unselectable_point.svg'

function openEndIconForMode(selectionStyle) {
    switch (selectionStyle) {
        case 'selected':
            return SelectedOpenEndIcon
        case 'selectable':
            return SelectableOpenEndIcon
        default:
            return UnselectableOpenEndIcon
    }
}

function closedEndIconForMode(selectionStyle) {
    switch (selectionStyle) {
        case 'selected':
            return SelectedClosedEndIcon
        case 'selectable':
            return SelectableClosedEndIcon
        default:
            return UnselectableClosedEndIcon
    }
}

function inlineTeeIconForMode(selectionStyle) {
    switch (selectionStyle) {
        case 'selected':
            return SelectedInlineTeeIcon
        case 'selectable':
            return SelectableInlineTeeIcon
        default:
            return UnselectableInlineTeeIcon
    }
}

function surgeValveIconForMode(selectionStyle) {
    switch (selectionStyle) {
        case 'selected':
            return SelectedSurgeValveIcon
        case 'selectable':
            return SelectableSurgeValveIcon
        default:
            return UnselectableSurgeValveIcon
    }
}

function pointIconForMode(selectionStyle) {
    switch (selectionStyle) {
        case 'selected':
            return SelectedPointIcon
        case 'selectable':
            return SelectablePointIcon
        default:
            return UnselectablePointIcon
    }
}

const emit = defineEmits(['change'])

const props = defineProps({
    location: {
        type: Object,
        required: true,
    },
    snapCallback: {
        type: Function,
        required: false
    },
    snapLevel: {
        type: Number,
        required: false,
        default: 0
    },
    isLastPoint: {
        type: Boolean,
        required: true
    },
    opacity: {
        type: Number,
        required: false,
        default: 1
    },
    draggable: {
        type: Boolean,
        required: false,
        default: true
    },
    selectionStyle: {
        type: String,
        required: false,
        default: 'selected',
        validator: function (value) { return value == "selected" || value == "selectable" || value == "unselectable" }
    },
})

const data = reactive({
    dragging: false,
    location: props.location
})

provide('pointMarkerData', data)

const markerProps = computed(() => {
    const isJunction = PipePathAlg.isJunction(data.location)

    const ret = {
        clickable: false,
        draggable: props.draggable,
        zIndex: ZIndexes.PipePathEditMarkers +
            (isJunction ? 2 : 1),
        opacity: props.opacity,
        icon: {
            url: pointIconForMode(props.selectionStyle)
            // path: api.SymbolPath.CIRCLE,
            // fillColor: 'white',
            // fillOpacity: props.opacity,
            // strokeWeight: 0,
            // scale: 8
        }
    }

    if (!props.isLastPoint) {
        return ret
    }

    if (isJunction) {
        let url = null
        switch (data.location.type) {
            case 'inline_tee':
                url = inlineTeeIconForMode(props.selectionStyle)
                break
            case 'surge_valve':
                url = surgeValveIconForMode(props.selectionStyle)
                break
        }

        ret.icon = {
            // anchor: new api.Point(18, 18),
            // scale: 0.125,
            // fillOpacity: props.opacity,
            // fillColor: 'white',
            // strokeOpacity: props.opacity,
            // strokeWeight: 3,
            // scaledSize: new api.Size(36, 36),
            url
        }
    }
    else {
        const url = (data.location?.connectorType === ConnectorType.TiedOffEnd) ?
            closedEndIconForMode(props.selectionStyle) : openEndIconForMode(props.selectionStyle)

        ret.icon = {
            // anchor: new api.Point(18, 18),
            // scale: 0.125,
            // fillOpacity: props.opacity,
            // strokeOpacity: props.opacity,
            // strokeWeight: 3,
            // scaledSize: new api.Size(36, 36),
            url
        }
    }

    return ret
})

import throttle from 'lodash.throttle'

const drag = throttle(function drag(e) {
    data.dragging = true

    const newLatLng = new LatLng(e.latLng.lat(), e.latLng.lng())

    if (props.snapCallback) {
        const snappedLatLng = props.snapCallback(newLatLng, props.snapLevel)

        if (snappedLatLng) {
            if (Number.isFinite(snappedLatLng.elevationInFeet)) {
                snappedLatLng.interpolated = false
            }

            data.location = { ...data.location, ...snappedLatLng }

            return
        }
    }

    data.location = { ...data.location, ...newLatLng }
}, 0, {leading: true, trailing: true, maxWait: 50})

function dragEnd(e) {
    drag(e)

    emit('change', data.location)

    data.dragging = false
}
</script>

<template>
   <full-page-form :title="title" @cancel="cancel">
      <template v-slot:float-right>
         <div class="dialog_buttons">
            <a class="button widget_margin_left" @click="cancel">
               <i class="fa fa-times fa_button_icon"></i>
            </a>
         </div>
      </template>

      <div v-if="dropError">
         {{ dropError }}
      </div>
      <div class="drop-preview" v-else>
         The field you've dropped is connected to other fields on the same irrigation system(s).
         <br><br>
         All connected data must be {{ lcAction }}:
         <div class="field_tiles">
            <field-tile :field="field" :farm="allFieldRelatedObjects.farm" :interactive="false"
               v-for="(field, i) in allFieldRelatedObjects.connectedFields" :key="i" />
         </div>

         <div class="bottom_buttons">
            <button @click="doIt">{{ buttonLabel }}</button>
            <button class="cancel_button" @click="cancel">Cancel</button>
         </div>
      </div>
   </full-page-form>
</template>
 
<script>
import FullPageForm from './FullPageForm.vue'
import FieldTile from './FieldTile.vue'

export default {
   components: { FullPageForm, FieldTile },
   props: {
      allFieldRelatedObjects: {
         type: Object,
         required: true
      },
      targetFarm: {
         type: Object, required: true
      },
      dropEffect: { // "copy" or "move"
         type: String,
         required: true
      }
   },
   data: function () {
      return {

      }
   },
   computed: {
      buttonLabel: vm => {
         if (vm.dropEffect === "move") {
            return "Continue Move";
         }

         return "Continue Copy";
      },
      lcAction: vm => {
         return (vm.dropEffect === "move") ? "moved" : "copied";
      },
      title: vm => {
         const action = (vm.dropEffect === "move") ? "Move" : "Copy";

         return `${action} to ` + (vm.targetFarm?.name || 'N/A')
      },
      dropError: vm => {
         if (vm.allFieldRelatedObjects) {
            return null;
         }

         return "Invalid Data Dropped -- Expected Field Dragged from Pipe Planner";
      },
      fieldPreviewImages: vm => {
         const fields = vm.allFieldRelatedObjects.connectedFields;

         const layouts = fields.map(f => {
            if (!f?.layouts) {
               return [];
            }
            return f.layouts.find((l) => l.id == f.activeLayoutId)
         });

         return layouts.map(l => l ? vm.$staticImages.buildFieldUrl(l) : null);
      },
   },
   methods: {
      async doIt() {
         const fieldIds = this.allFieldRelatedObjects.connectedFields.map(f => f.id);

         const transaction = {
            to: this.targetFarm.id,
            from: this.allFieldRelatedObjects.farm.id,
            fieldIds
         }

         await this.$store.dispatch('postFarmTransaction', transaction)

         this.$dialog.pop();
      },
      cancel() {
         this.$dialog.pop();
      }
   },
   mounted() {
   }
}
</script>
 
<style  lang="css" scoped>
.dialog_buttons {
   float: right;
}

.drop-preview {
   max-width: 80vw;
   max-height: 80vh;
   overflow: auto;
   font-size: smaller;
   padding: var(--general-padding);
}

.bottom_buttons {
   display: flex;
   flex-direction: row;
   justify-content: center;
   margin-top: calc(var(--general-padding) * 2);
}

.field_tiles {
   display: flex;
   flex-direction: row;
   justify-content: center;
   flex-wrap: wrap;
   min-width: 50vw;
   padding: 20px;
   justify-content: space-evenly;
}

.cancel_button {
   margin-left: var(--general-padding);
}
</style>
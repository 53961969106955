<template>
    <fullpageform title="Pipe Settings" description="Set pipe specific items" submitLabel="Save" cancelLabel="Cancel"
        @submit="submit" @cancel="cancel">

        <div>
            <!-- FLOW RATE -->
            <fieldset>
                <legend>Flow Rate</legend>
                <flow-rate-select :class="flowRateInvalid ? 'invalid' : ''" v-model="flowRateId" :field="field"
                    :fieldLayout="fieldLayout" :showApplyFlowRateChanges="false" :irrigationSystems="[irrigationSystem]"
                    :lineStringForDistanceCalculation="fieldLineString" />
            </fieldset>

            <levee-elevation-form class="widget_margin_top" v-if="fieldLayout.mode === FieldMode.Levees"
                ref="leveeElevationForm" :field="field" :fieldLayout="fieldLayout" />

            <!-- POINTS -->
            <pipe-path-path-edit class="widget_margin_top" ref="pipePathPathEdit" :field="field" :fieldLayout="fieldLayout"
                :pipePath="pipePath" :flowRateId="flowRateId" :irrigationSystem="irrigationSystem" />
        </div>
    </fullpageform>
</template>

<script>
import { GeoUtil } from '@/geo/GeoUtil'
import { FieldMode } from '@/store/Dto'

import PipePathPathEdit from '@/components/maps/irrigation_systems/PipePathPathEdit'
import LeveeElevationForm from './LeveeElevationForm.vue'

export default {
    components: {
        PipePathPathEdit,
        LeveeElevationForm
    },
    props: {
        field: {
            type: Object,
            required: true
        },
        fieldLayout: {
            type: Object,
            required: true
        },
        irrigationSystem: {
            type: Object,
            required: true
        },
        pipePath: {
            type: Object,
            required: true
        }
    },
    data: function () {
        return {
            flowRateId: null,
            FieldMode
        }
    },
    computed: {
        fieldLineString: (vm) => GeoUtil.LatLngs.toLineString(vm.fieldLayout.path),
        flowRateInvalid: (vm) => vm.flowRateId == null
    },
    methods: {
        submit: async function () {
            // patch pipe
            {
                const patches = []

                const flowRatePatch = this.buildFlowRatePatch()
                if (flowRatePatch) {
                    patches.push(flowRatePatch)
                }

                patches.push(... this.buildPipePathPathPatches())

                await this.$store.dispatch('patchIrrigationSystemPipePath', { 
                    farm: this.farm,
                    irrigationSystem: this.irrigationSystem, pipePath: this.pipePath, patch: patches })
            }

            // for levee fields, patch elevation settings if they changed
            if (this.$refs.leveeElevationForm) {
                const patches = this.$refs.leveeElevationForm.buildPatches()

                if (patches.length > 0) {
                    await this.$store.dispatch('patchFieldLayout', {
                        field: this.field, fieldLayout: this.fieldLayout, patch: patches
                    })
                }
            }

            await this.$store.dispatch('returnToSelectedIrrigationSystemPipePath')

            this.$dialog.pop()
        },
        cancel: function () {
            this.$dialog.pop()
        },
        buildPipePathPathPatches() {
            return this.$refs.pipePathPathEdit.buildPatches()
        },
        buildFlowRatePatch() {
            return {
                op: ('flowRateId' in this.pipePath) ? 'replace' : 'add',
                path: '/flowRateId',
                value: this.flowRateId
            }
        }
    },
    beforeMount() {
        this.flowRateId = this.pipePath.flowRateId
        this.waterBothSides = this.pipePath.waterBothSides
    },
    mounted() {
    }
}
</script>

<style lang="css" scoped>
.fieldset-group {
    border: 2px solid var(--primary);
    padding: 10px;
}

.fieldset-group-set {
    background-color: var(--primary);
    font-size: small;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}
</style>


<template>
    <template>
        <slot
            :filteredFields="filteredFields"
        />
    </template>
</template>

<script>

import Pp4MapObject from '@/components/maps/Pp4MapObject'
import { GeoUtil } from '@/geo/GeoUtil'

export default {
    extends: Pp4MapObject,
    props: {
        fields: {
            type: Array,
            required: true
        }
    },
    data: function() {
        return {
        }
    },
    computed: {
        boundsGeo: (vm) => vm.provider.boundsGeo,
        fieldsWithBbox: (vm) => vm.fields.map((field) => {
            const layout = field.layouts.find((layout) => layout.id == field.activeLayoutId)
            let bbox = GeoUtil.GeoJson.bbox(GeoUtil.LatLngs.toPolygon(layout.path))
            let bboxGeo = GeoUtil.GeoJson.bboxPolygon(bbox)
            bboxGeo.bbox = bbox

            return {field, bboxGeo}
        }),
        filteredFieldsWithBbox: (vm) => {
            if(vm.boundsGeo == null) {
                return []
            }

            return vm.fieldsWithBbox.filter((fieldWithBbox) => {
                return GeoUtil.GeoJson.bboxIntersect(fieldWithBbox.bboxGeo, vm.boundsGeo)
            })
        },
        filteredFields: (vm) => {           
            return vm.fields

            // const fieldsFiltered = 
            //     vm.map.getZoom() < vm.$store.state.preferences.ui.filterIndividualFieldsBelowZoomLevel &&
            //     vm.filteredFieldsWithBbox.length > vm.$store.state.preferences.ui.fieldCountNeededToTriggerFiltering

            // if(fieldsFiltered) {
            //     return []
            // }

            // return vm.filteredFieldsWithBbox.map((ffwbb) => ffwbb.field)
        }
    },
    watch: {
    },
    methods: {
    }
}
</script>

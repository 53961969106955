<template>
    <span class="pipe_type_select">
        <select v-model.number="diameterInInches">
            <option :value="10">10</option>
            <option :value="12">12</option>
            <option :value="15">15</option>
            <option :value="18">18</option>
            <option :value="22">22</option>
            <option :value="4">4</option>
            <option :value="5">5</option>
            <option :value="6">6</option>
            <option :value="8">8</option>
        </select>

        <select v-model.number="thicknessInMils" class="thickness">
            <option :value="5">5</option>
            <option :value="7">7</option>
            <option :value="8">8</option>
            <option :value="9">9</option>
            <option :value="10">10</option>
            <option value="trans">Trans</option>
            <option :value="20">20</option>
        </select>
    </span>
</template>

<script setup>
import { PipeType } from '@pp4/design';
import { onMounted, watch } from 'vue';

const model = defineModel()

const diameterInInches = defineModel("diameterInInches", {
    local: true,
    default: 12
})
const thicknessInMils = defineModel("thicknessInMils", {
    local: true,
    default: 10
})

watch([diameterInInches, thicknessInMils], () => {
    model.value = new PipeType(diameterInInches.value, thicknessInMils.value)
})

watch([model], () => {
    if (model.value) {
        diameterInInches.value = model.value.diameterInInches
        thicknessInMils.value = model.value.thicknessInMils
    }
})

onMounted(() => {
    if (model.value) {
        diameterInInches.value = model.value.diameterInInches
        thicknessInMils.value = model.value.thicknessInMils
    }
})
</script>

<style lang="css" scoped>
.thickness {
    margin-left: 0.25em;
}
</style>

<template>
  <context-menu>
    <toggle-button label="Remove Points" description="Toggle Remove Points Mode" :active="removeModeActive"
      :disabled="removeModeDisabled" v-on:click="toggleEditOrRemoveMode()"><img :src="RemoveIcon"
        draggable="false"></toggle-button>

    <template v-if="showSaveAndCancelButtons">
      <icon-button label="Save" description="Save" class="button_group_margin_top" :disabled="!saveActive"
        :useSaveIcon="true" @click="save()" />
      <icon-button label="Cancel" description="Cancel" class="button_margin_top" :useCancelIcon="true"
        @click="cancel()" />
    </template>
  </context-menu>

  <pp4-label v-if="editedFarmLabel" :labelText="editedFarmLabel" :latLng="centerFieldLatLng"
    elementClass="selected_field_label" />
  <water-source v-for="irrigationSystem in irrigationSystems" :key="irrigationSystem.id"
    :location="irrigationSystem.waterSourceLocation" :clickable="false" :name="irrigationSystem.name"
    selectionStyle="unselectable" />

  <pp4-poly :path="modifiedLatLngs || fieldLayout.path" :showLeveePaths="true" :editMode="editMode"
    @poly_changed="boundsChanged" />

  <pp4-polyline v-for="leveePath in leveePaths" :path="leveePath.path" :key="leveePath.key"
    :clickable="false"/>

  <furrow-set v-for="furrowSet in furrowSetsToDisplay" selectionStyle="selected" :key="furrowSet.id"
    :furrowSet="furrowSet" :fieldLayout="fieldLayout" />
</template>

<script>

import { FurrowSetAlg } from '@/geo/FurrowSetAlg'
import { GeometryEditMode, FieldMode } from '@/store/Dto'
import { GeoUtil } from '@/geo/GeoUtil'

import RemoveIcon from '@/assets/remove_poly_points_icon.svg'

export default {
  props: {
    field: {
      type: Object,
      required: false
    },
    fieldLayout: {
      type: Object,
      required: true
    },
    showSaveAndCancelButtons: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data: function () {
    return {
      GeometryEditMode, RemoveIcon,
      editMode: GeometryEditMode.EditPoints,
      modifiedLatLngs: null,
      modifiedFurrowSets: null,
      editedFarmLabel: null,
      centerFieldLatLng: null
    }
  },
  computed: {
    saveActive: (vm) => vm.modifiedLatLngs != null,
    removeModeActive: (vm) => vm.editMode == GeometryEditMode.RemovePoints,
    removeModeDisabled: (vm) => {
      if (vm.modifiedLatLngs) {
        return vm.modifiedLatLngs.length <= 3
      }

      return vm.fieldLayout.path.length <= 3
    },
    irrigationSystems: (vm) => {
      return vm.$store.state.selectedFarm.irrigationSystems
    },
    originalFurrowSets: (vm) => {
      if (vm.fieldLayout.mode != FieldMode.Furrows || vm.fieldLayout.furrowSetDetails == null) {
        return null
      }

      return vm.fieldLayout.furrowSetDetails.resultingFurrowSets
    },
    furrowSetsToDisplay: (vm) => {
      if (vm.modifiedFurrowSets != null) {
        return vm.modifiedFurrowSets
      }

      return vm.originalFurrowSets
    },
    leveePaths: (vm) => {
      if (vm.fieldLayout.mode !== FieldMode.Levees) {
        return []
      }

      return vm.fieldLayout.leveePaths.map((path, index) => {
        return {
          path, key: (vm.fieldLayout.id + ':leveePath:' + index)
        }
      })
    }
  },
  mounted() {
  },
  emits: ['boundsChanged'],
  methods: {
    boundsChanged(latLngs) {
      {
        const fieldGeo = GeoUtil.LatLngs.toPolygon(latLngs)
        const fieldArea = GeoUtil.GeoJson.area(fieldGeo)
        const areaInAcres = 0.0002471052 * fieldArea
        const centerOfMass = GeoUtil.GeoJson.centerOfMass(fieldGeo)
        const centerLatLngs = GeoUtil.GeoJson.toLatLngs(centerOfMass)
        let computedLabel = null
        if (this.field) {
          computedLabel = this.field.name + '<br>' + areaInAcres.toFixed(1) + ' ac.'
        } else {
          computedLabel = areaInAcres.toFixed(1) + ' ac.'
        }

        this.modifiedLatLngs = latLngs
        this.editedFarmLabel = computedLabel
        this.centerFieldLatLng = centerLatLngs
      }

      if (this.originalFurrowSets) {
        let newFurrowSetDetails = new FurrowSetAlg().buildFurrowSetDetailsFromSplitPointLatLngsAndSplitPaths(
          this.fieldLayout.furrowSetDetails.splitPointLatLngs,
          this.fieldLayout.furrowSetDetails.splitPaths,
          this.fieldLayout.furrowBearing, latLngs)

        this.modifiedFurrowSets = newFurrowSetDetails.resultingFurrowSets
      }

      if (this.modifiedLatLngs.length === 3) {
        this.editMode = GeometryEditMode.EditPoints
      }

      this.$emit('boundsChanged', this.modifiedLatLngs)
    },
    async save() {
      const patches = []

      const latLngsPatch = { op: 'replace', path: `/path`, value: this.modifiedLatLngs }
      patches.push(latLngsPatch)

      if (this.modifiedFurrowSets && (this.modifiedFurrowSets.length > 0)) {
        const patch = {
          path: `/furrowSetDetails/resultingFurrowSets`,
          op: 'replace', value: this.modifiedFurrowSets
        }
        patches.push(patch)
      }

      await this.$store.dispatch('patchFieldLayout', {
        field: this.field, fieldLayout: this.fieldLayout, patch: patches
      })
      this.$store.dispatch('returnToSelectedFieldOrFurrowSet')
    },
    cancel() {
      this.$store.dispatch('returnToSelectedFieldOrFurrowSet')
    },
    toggleEditOrRemoveMode() {
      if (this.editMode === GeometryEditMode.RemovePoints) {
        this.editMode = GeometryEditMode.EditPoints
      }
      else {
        this.editMode = GeometryEditMode.RemovePoints
      }
    }
  }
}
</script>

<style lang="css"></style>

<template>

</template>

<script setup>
import { ref, watch, onUnmounted, toRaw } from 'vue'

import { useMaps, useGmpEvents, useEvents } from '@/composables/useMaps'

const props = defineProps({
    latLng: {
        type: Object,
        required: true,
        validator: function (value) {
            return !(value == null || Number.isNaN(value.lat) || Number.isNaN(value.lng))
        }
    },
    labelText: {
        type: String,
        required: true
    },
    elementClass: {
        type: String,
        required: true
    },
    targetPane: {
        type: String,
        required: false,
        default: 'overlayMouseTarget'
    },
    clickable: {
        type: Boolean,
        required: false,
        default: false
    },

        //events
    onClick: {
        required: false
    }
})

const maps = useMaps()

const labelMapObjectRef = ref(null)

onUnmounted(() => {
    const val = labelMapObjectRef.value
    if (val) {
        val.setMap(null)
        toRaw(val).setMap(null)
        labelMapObjectRef.value = null
    }
})

function mapReady() {
    const val = labelMapObjectRef.value

    const label = document.createElement('div')
    label.style.userSelect = 'none'
    label.classList.add(props.elementClass)
    label.innerHTML = props.labelText

    if (val) {
        val.content = label
        val.position = props.latLng

        return
    }

    const marker = new maps.api.AdvancedMarkerElement({
        position: props.latLng,
        content: label, map: toRaw(maps.map)
    })

    labelMapObjectRef.value = marker

    // this is so stupid, they have a new event format with no latlng property...
    const eventEnrich = function (e) {
      e.latLng = new maps.api.LatLng(props.latLng)
      return e
    }

    useGmpEvents(labelMapObjectRef.value, props, ['onClick'], eventEnrich)
}

watch(props, mapReady, {immediate: true})
</script>

import { reactive } from 'vue'
import { pushModal, popModal } from "jenesius-vue-modal"

import ConfirmDialog from '@/components/ConfirmDialog'
import Toast from '@/components/Toast'

const ReactiveInstance = reactive({
  dialogElements: [],
  activeDialogElement: null,
  showDialogElement(dialogElement) {
    this.activeDialogElement = dialogElement
  },
  async confirm(callback, options = {
    title: 'Confirm or Cancel?',
    confirmLabel: 'Confirm',
    cancelLabel: 'Cancel'
  }) {

    const props = {
      confirmCallback: () => {
        if (callback) {
          callback()
        }

        this.pop()
      },
      cancelCallback: () => {
        this.pop()
      },
      title: options.title,
      confirmLabel: options.confirmLabel,
      cancelLabel: options.cancelLabel
    }

    await this.push(ConfirmDialog, props)
  },
  async push(dialogElement, props) {
    return await pushModal(dialogElement, props)
  },
  async pop() {
    return await popModal()
  },
  async toast(props) {
    return await pushModal(Toast, props)
  }
})

export const Pp4DialogPlugin = {
  install(app, _options) {
    app.config.globalProperties.$dialog = ReactiveInstance
  }
}

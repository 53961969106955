import { LatLng } from '@/store/Dto'
import { GeoUtil } from '@/geo/GeoUtil'

class GoogleMapUtils {
    constructor(api, map, store, mapDiv) {
        this.api = api
        this.map = map
        this.store = store
        this.mapDiv = mapDiv
    }

    static DefaultMapOptions() {
        return {
            mapTypeId : 'hybrid',
            tilt: 0,
            heading: 0,
            isFractionalZoomEnabled: false,
            zoomControl: true,
            panControl: false,
            tiltControl: false,
            rotateControl: false,
            streetViewControl : false,
            fullscreenControl : false,
            mapTypeControl : false,
            draggableCursor: 'auto'
        }
    }

    showMap() {
        this.mapDiv.classList.remove('map-hidden')
    }

    hideMap() {
        this.mapDiv.classList.add('map-hidden')
    }

    mapIsShown() {
        return ! this.mapDiv.classList.contains('map-hidden')
    }

    defaultMapOptions() {
        return GoogleMapUtils.DefaultMapOptions()
    }

    screenCoordinatetoLatLng(x, y) {
        let proj = this.map.getProjection()
        let mapBounds = this.map.getBounds()

        let ne = proj.fromLatLngToPoint(mapBounds.getNorthEast())
        let sw = proj.fromLatLngToPoint(mapBounds.getSouthWest())
        let scale = Math.pow(2, this.map.getZoom())

        let worldPoint = new this.api.Point((x / scale) + sw.x, (y / scale) + ne.y)

        let googleLatLng = proj.fromPointToLatLng(worldPoint)

        return new LatLng(googleLatLng.lat(), googleLatLng.lng())
    }

    bboxToLatLngBounds(bbox) {
        return new this.api.LatLngBounds(
            new this.api.LatLng(bbox[1], bbox[0]),
            new this.api.LatLng(bbox[3], bbox[2])
        )
    }

    latLngBoundsToBbox(latLngBounds) {
        if(latLngBounds == null) {
            return null
        }

        let sw = latLngBounds.getSouthWest()
        let ne = latLngBounds.getNorthEast()

        return [sw.lng(), sw.lat(), ne.lng(), ne.lat()]
    }

    toGooglePath(latLngPath) {
        return latLngPath.map((ll) => {
            return new this.api.LatLng(ll.lat, ll.lng)
        })
    }

    toGoogleLatLng(latLng) {
        return new this.api.LatLng(latLng.lat, latLng.lng)
    }

    buildPathFromGooglePoly(poly) {
        let ret = []
        
        poly.getPath().forEach(function(p) {
            ret.push(new LatLng(p.lat(), p.lng()))
        })

        return ret
    }

    buildPathFromGooglePolyline(line) {
        let ret = []

        line.getPath().forEach(function(p) {
            ret.push(new LatLng(p.lat(), p.lng()))
        })

        return ret
    }

    objectBounds(o) {
        let ret = new this.api.LatLngBounds();

        if(o.getPaths instanceof Function) {
            o.getPaths().forEach(function(paths) {
                paths.forEach(function(p) {
                    ret.extend(p)
                })
            })
        }
        else if(o.getPath instanceof Function) {
            o.getPath().forEach(function(p) {
                ret.extend(p)
            })
        }

        return ret
    }
    
    polyBounds(fieldPoly) {
        let ret = new this.api.LatLngBounds();

        fieldPoly.getPaths().forEach(function(paths) {
            paths.forEach(function(p) {
                ret.extend(p)
            })
        })

        return ret
    }

    panToLocation(latLng) {
        this.map.panTo(latLng)
    }

    panToFieldPoly(fieldPoly) {
        let bounds = this.polyBounds(fieldPoly)
        
        this.map.panToBounds(bounds, 400)

        if(this.map.getZoom() < 14) {
            this.map.setZoom(14)
        }
    }

    fitPolys(polys) {
        if(polys.length <= 0) {
            return
        }

        let bounds = new this.api.LatLngBounds();
    
        polys.forEach((poly) => {
            bounds.union(this.polyBounds(poly))
        })
        
        this.map.fitBounds(bounds, 10)
    }

    centerFieldPoly(fieldPoly) {
        let latLngs = this.buildPathFromGooglePoly(fieldPoly)
        let fieldGeo = GeoUtil.LatLngs.toPolygon(latLngs)
        let centroid = GeoUtil.GeoJson.centroid(fieldGeo)

        let centroidLatLng = GeoUtil.GeoJson.toLatLngs(centroid)
        this.map.panTo(centroidLatLng)

        let centerFieldZoomLevel = this.store.state.preferences.ui.centerFieldZoomLevel
        if(this.map.getZoom() < centerFieldZoomLevel) {
            this.map.setZoom(centerFieldZoomLevel)
        }
    }

    centerAndFocusFieldPoly(fieldPoly) {
        let bounds = this.polyBounds(fieldPoly)
        
        this.map.fitBounds(bounds, 100)
    }
}

export {
    GoogleMapUtils
}

<template>
    <div>
        <template v-if="showFieldLayoutInProgress">
            <pp4-field :fieldLayout="fieldLayoutInProgress" :field="fieldInProgress" selection-style="selected"
                :clickable="false" :showFurrowDirection="showFurrowDirection" :showLeveePaths="true" />
        </template>

        <water-source v-if="selectedIrrigationSystem" :location="selectedIrrigationSystem.waterSourceLocation"
            :clickable="false" :showLabel="true" :name="selectedIrrigationSystem.name" selectionStyle="selected" />

        <template v-if="currentStep === Steps.SelectFieldType">
            <fullpageform title="Select Field Type" :enableHotkeys="true" @cancel="cancel" :opaqueBackground="true">

                <div class="big_button_select_div select_field_type">
                    <div class="field_name_div">
                        <div class="field_name_div_property">
                            <label for="field_name">Field Name:</label>
                            <input type="text" class="button_margin_left" v-model="fieldInProgress.name"
                                :disabled="addLayoutToField" ref="fieldName">
                        </div>
                        <div class="field_name_div_property">
                            <label for="addfieldwizard-layout_name">Layout Name:</label>
                            <input v-model="fieldLayoutInProgress.name" class="button_margin_left" type="text"
                                id="addfieldwizard-layout_name" :placeholder="fieldLayoutInProgress.name">
                        </div>
                        <div class="field_name_div_property">
                            <soil-type-select v-model="fieldLayoutInProgress.soilType" />
                        </div>
                    </div>
                    <div class="big_button_select_buttons_div widget_margin_top">
                        <icon-button label="Furrow" description="Add a Field With Furrows"
                            class="huge_icon_button big_button_select_button" :big="true" @click="furrowModeSelected()">
                            <img :src="FurrowIcon" :draggable="false">
                        </icon-button>

                        <icon-button label="Levee" description="Add a Field With Paddys and Raised Levees"
                            class="huge_icon_button big_button_select_button" :big="true" @click="leveeModeSelected()"
                            :draggable="false">
                            <img :src="LeveeIcon">
                        </icon-button>
                    </div>
                </div>

                <template v-slot:float-right>
                    <header-cancel-button @cancel="cancel" />
                </template>
            </fullpageform>
        </template>

        <template v-else-if="currentStep === Steps.SelectLeveePathSource">
            <fullpageform title="Select Levee Path Source" :opaqueBackground="true"
                description="Draw levee paths by hand or upload them from a shapefile of lines.">

                <div class="big_button_select_div">
                    <div class="big_button_select_buttons_div">
                        <icon-button label="Draw" description="Draw Field and Levee Paths By Hand"
                            class="huge_icon_button big_button_select_button" :big="true" faIcon="fa-pencil"
                            @click="leveeDrawModeSelected()" />

                        <icon-button label="Levee Upload" description="Upload Field and Levee Paths From A Shapefile"
                            class="huge_icon_button big_button_select_button" :big="true" faIcon="fa-upload"
                            @click="leveeUploadModeSelected()" />

                    </div>
                </div>

                <template v-slot:float-right>
                    <header-cancel-button label="Back" @cancel="returnToPreviousStep" />
                </template>
            </fullpageform>
        </template>

        <template v-else-if="currentStep === Steps.UploadLeveePaths">
            <fullpageform :opaqueBackground="true">
                <div class="file_drop_div" @drag.stop.prevent @dragstart.stop.prevent @dragend.stop.prevent
                    @drop="uploadShapeFileDropped" @dragenter="uploadDragenter" @dragleave="uploadDragleave">
                    <div>
                        Drop Shapefile (.shp) Here or <input type="file" name="shapefile_file" id="shapefile_file"
                            ref="shapefile_file" @change="uploadShapeFileChosen">
                    </div>
                    <div v-if="loadingFile == false">
                        <div>
                            Import process uses levee line paths to create paddies and boundaries. Polygons and points will
                            be ignored.
                        </div>
                        <div>
                            Pipe Planner will attempt to clean up the lines and remove kinks.
                        </div>
                    </div>
                    <div v-else>
                        <i class="fa fa-circle-o-notch fa-spin fa-5x fa-fw"></i>
                    </div>
                    <span class="fa fa-4x fa-caret-up levee_shapefile_drop_success_hint"
                        :class="dropSuccessHintClass"></span>
                </div>
            </fullpageform>
            <header-cancel-button label="Back" @cancel="returnToPreviousStep" />
        </template>

        <template v-else-if="currentStep === Steps.UploadFurrowPaths">
            <fullpageform :opaqueBackground="true">
                <div class="file_drop_div" @drag.stop.prevent @dragstart.stop.prevent @dragend.stop.prevent
                    @drop="uploadShapeFileDropped" @dragenter="uploadDragenter" @dragleave="uploadDragleave">
                    <div>
                        Drop Shapefile (.shp) Here or <input type="file" name="shapefile_file" id="shapefile_file"
                            ref="shapefile_file" @change="uploadShapeFileChosen">
                    </div>
                    <div v-if="loadingFile == false">
                        Import process uses Shapefiles to create field boundaries.
                    </div>
                    <div v-else-if="loadingFile == true">
                        <i class="fa fa-circle-o-notch fa-spin fa-5x fa-fw"></i>
                    </div>
                    <span class="fa fa-4x fa-caret-up levee_shapefile_drop_success_hint"
                        :class="dropSuccessHintClass"></span>
                </div>
            </fullpageform>
            <header-cancel-button label="Back" @cancel="returnToPreviousStep" />
        </template>

        <template v-else-if="currentStep === Steps.DrawFieldPath">
            <add-field-bounds @poly-ready="handleFieldPathAdded" />
            <map-cancel-button label="Back" @cancel="returnToPreviousStep" />
        </template>

        <template v-else-if="currentStep === Steps.EditFieldPath">
            <map-cancel-button label="Back" @cancel="returnToPreviousStep" />
            <edit-field-bounds :field="fieldInProgress" :fieldLayout="fieldLayoutInProgress"
                @boundsChanged="handleFieldLayoutBoundaryChanged" :showSaveAndCancelButtons="false" />

            <next-step v-if="fieldLayoutInProgress.mode === FieldMode.Levees">
                <icon-button label="Add Water Source" description="Add Water Source" :big="true" faIcon="fa-arrow-right"
                    @click="beginAddWaterSource" /> <br>
                <icon-button label="Select Water Source" description="Select Water Source" :big="true"
                    faIcon="fa-arrow-right" @click="beginSelectWaterSource" />
            </next-step>

            <next-step v-else-if="fieldLayoutInProgress.mode === FieldMode.Furrows">
                <icon-button label="Set Furrow Direction" description="Set Furrow Direction for This Field" :big="true"
                    faIcon="fa-arrow-right" @click="beginSetFurrowDirection" />
            </next-step>
        </template>

        <template v-else-if="currentStep === Steps.SelectFurrowPathSource">
            <fullpageform title="Field Boundary" :opaqueBackground="true"
                description="Draw by hand or upload from a shapefile.">

                <div class="big_button_select_div">
                    <div class="big_button_select_buttons_div">
                        <icon-button label="Draw" description="Draw field boundary by hand"
                            class="huge_icon_button big_button_select_button" :big="true" faIcon="fa-pencil"
                            @click="beginDrawFieldPath()" />

                        <icon-button label="Upload" description="Upload field boundary from a shapefile"
                            class="huge_icon_button big_button_select_button big_button_middle" :big="true"
                            faIcon="fa-upload" @click="furrowUploadModeSelected()" />

                    </div>
                </div>

                <template v-slot:float-right>
                    <header-cancel-button label="Back" @cancel="returnToPreviousStep" />
                </template>
            </fullpageform>
        </template>

        <template v-else-if="currentStep === Steps.SetFurrowDirection">
            <edit-furrow-direction :field="fieldInProgress" :fieldLayout="fieldLayoutInProgress"
                :irrigationSystem="selectedIrrigationSystem" @furrowDirectionChanged="handleFieldFurrowDirectionUpdated" />
            <!-- give it a copy to prevent ciruclar update -->

            <map-cancel-button label="Back" @cancel="returnToPreviousStep" />

            <next-step v-if="furrowDirectionSet">
                <icon-button label="Add Water Source" description="Add Water Source" :big="true" faIcon="fa-arrow-right"
                    @click="addOrSelectWaterSource('addWaterSource')">
                </icon-button>
                <br>
                <icon-button label="Select Water Source" description="Select Water Source" :big="true"
                    faIcon="fa-arrow-right" @click="addOrSelectWaterSource('selectWaterSource')" />
            </next-step>
        </template>

        <template v-else-if="currentStep === Steps.SelectWaterSource">
            <header-cancel-button label="Back" @cancel="returnToPreviousStep" />
            <next-step v-if="(fieldLayoutInProgress.mode === FieldMode.Levees) && (selectedIrrigationSystem != null)">
                <icon-button label="Finish" description="Finish Field" :useSaveIcon="true" :big="true" @click="fieldDone" />
            </next-step>

            <select-water-sources :farm="farm" @changed="handleIrrigationSystemSelected" />
        </template>

        <template v-else-if="currentStep === Steps.AddWaterSource">
            <add-irrigation-system @added="handleIrrigationSystemAdded" :farm="farm" :field="fieldInProgress"
                :fieldLayout="fieldLayoutInProgress" :fromSource="'Wizard'" :wasEdited="true" />

            <header-cancel-button label="Back" @cancel="returnToPreviousStep" />
        </template>

        <template v-else-if="currentStep === Steps.EditWaterSource">
            <irrigation-system-dialog :farm="farm" :field="fieldInProgress" :irrigationSystem="selectedIrrigationSystem"
                :fieldLayout="fieldLayoutInProgress" :showCancel="false" fromSource="WaterSourceSettings"
                @patched="irrigationSystemAddedOrSelected" />

            <header-cancel-button label="Back" @cancel="returnToPreviousStep" />
        </template>

        <template v-else-if="currentStep === Steps.CalculateSets">
            <edit-furrow-sets :farm="farm" :field="fieldInProgress" :fieldLayout="fieldLayoutInProgress"
                :flowRateInGpm="selectedIrrigationSystem.flowRates[0].valueInGpm" :showSaveAndCancelButtons="false"
                :fromWizard="true" @cancel="returnToPreviousStep" @change="handleFurrowSetsChanged"
                :startingMode="EditFurrowSetsMode.Calculate"
                :primaryFlowRateId="selectedIrrigationSystem.flowRates[0].id" />

            <next-step>
                <icon-button label="Finish" description="Finish Field" :useSaveIcon="true" :big="true" @click="fieldDone">
                </icon-button>

                <map-cancel-button label="Back" @cancel="returnToPreviousStep" />
            </next-step>
        </template>
    </div>
</template>

<script>
import AddFieldBounds from '@/components/maps/add_field/AddFieldBounds'
import EditFieldBounds from '@/components/maps/edit_field_bounds/EditFieldBounds'
import SelectWaterSources from '@/components/maps/add_field/SelectWaterSources'
import AddIrrigationSystem from '@/components/maps/irrigation_systems/AddIrrigationSystem'
import EditFurrowDirection from '@/components/maps/edit_furrow_direction/EditFurrowDirection'
import EditFurrowSets from '@/components/maps/edit_furrow_sets/EditFurrowSets'
import { EditFurrowSetsMode } from '@/components/maps/edit_furrow_sets/EditFurrowSets'
import Pp4Field from '@/components/maps/Pp4Field'
import WaterSource from '@/components/maps/irrigation_systems/WaterSource'

import { GeometryEditMode, Pp4Uuid, FieldMode, Field, FieldLayout, FurrowSetDetails } from '@/store/Dto'
import { FileSorter } from '@/drag_and_drop/FileSorter'
import { ShapefileParser } from '@/drag_and_drop/ShapefileParser'
import { GeoUtil } from '@/geo/GeoUtil'
import * as NamingAlg from '@/util/NamingAlg'

import RemoveIcon from '@/assets/remove_poly_points_icon.svg'
import FurrowIcon from '@/assets/furrow_icon.svg'
import LeveeIcon from '@/assets/levee_icon.svg'
import AddFurrowSetIcon from '@/assets/add_furrow_set_icon.svg'
import EditFurrowSetsIcon from '@/assets/edit_furrow_sets_icon.svg'
import SnapIcon from '@/assets/furrow_direction_snap_icon.svg'

const Steps = {
    SelectFieldType: 'select_field_type',
    SelectLeveePathSource: 'select_levee_path_source',
    SelectFurrowPathSource: 'select_furrow_path_source',
    UploadLeveePaths: 'upload_levee_paths',
    UploadFurrowPaths: 'upload_furrow_paths',
    DrawFieldPath: 'draw_field_path',
    EditFieldPath: 'edit_field_path',
    UploadFieldPath: 'upload_field_path',
    SetFurrowDirection: 'set_furrow_direction',
    SelectWaterSource: 'select_water_source',
    AddWaterSource: 'add_water_source',
    EditWaterSource: 'edit_water_source', // this will only be used for special back cases, but could probably be refactored to be used after both add/edit water source
    IrrigationDesign: 'irrigation_design',
    CalculateSets: 'calculate_sets'
}

export default {
    props: {
        farm: {
            type: Object,
            required: true,
            validator: function (value) { return value != null && (value.fields instanceof Array) }
        },
        parentField: {
            type: Object,
            required: false,
            validator: function (value) { return value != null && (value.layouts instanceof Array) }
        }
    },
    components: {
        AddFieldBounds, SelectWaterSources, EditFieldBounds,
        EditFurrowDirection, Pp4Field, WaterSource,
        EditFurrowSets, AddIrrigationSystem
    },
    data: function () {
        return {
            EditFurrowSetsMode,
            startingGeoJson: null,
            FieldMode, Steps, FurrowIcon, LeveeIcon,
            RemoveIcon, AddFurrowSetIcon, EditFurrowSetsIcon, SnapIcon,
            currentStep: null,
            previousSteps: [],
            fieldInProgress: null,
            fieldLayoutInProgress: null,
            fieldEditMode: GeometryEditMode.None,
            selectedIrrigationSystem: null,
            addedIrrigationSystem: null, // this will be the same value as 'selectedIrrigationSystem', but is used to control whether a new one is added
            furrowDirectionSet: false,
            furrowDirectionCleanedUpPath: null,
            dragoverCount: false, //needs to be a count because of HTML weirdness with dragenter/leave events
            showFurrowDirection: false,
            editFieldTypeDisabled: null,
            shapeFiles: null,
            csvFiles: null,
            elevationCsvResults: null,
            loadingFile: false
        }
    },
    computed: {
        showFieldLayoutInProgress: (vm) => {
            return vm.fieldInProgress
                && vm.fieldLayoutInProgress
                && (vm.fieldLayoutInProgress.path.length > 2)
                && vm.currentStep !== Steps.EditFieldPath
        },
        furrowDirectionSnapActive: (vm) => vm.$store.state.editFurrowDirection.snap,
        furrowDirectionCleanupFieldActive: (vm) => vm.$store.state.editFurrowDirection.cleanupField,
        addLayoutToField: (vm) => vm.$store.state.mapObjects.availableMapActions.AddFieldLayout,
        dragoverActive: (vm) => vm.dragoverCount > 0,
        dropSuccessHintClass: (vm) => vm.dragoverActive ? 'levee_shapefile_drop_success_hint_active' : '',
        removeFieldPointsActive: (vm) => vm.fieldEditMode === GeometryEditMode.RemovePoints,
        removeFieldPointsDisabled: (vm) => {
            return vm.fieldLayoutInProgress.path.length <= 3
        }
    },
    watch: {
        currentStep: 'currentStepChanged',
        droppedFiles(files) {
            this.loadingFile = (files && (files.length > 0))
        }
    },
    mounted() {
        this.startingGeoJson = this.$store.state.mapObjects.startingGeoJson

        this.reset()
    },
    methods: {
        updateFieldLayoutInProgressFromStartingGeoJson() {
            const geo = this.startingGeoJson;

            if (!geo) {
                return;
            }

            const sfp = new ShapefileParser(this.$store)

            const mode = this.fieldLayoutInProgress.mode;
            if (mode === FieldMode.Furrows) {

                const parsedLayout = sfp.parseFurrowFieldLayout(geo, "")
                Object.assign(this.fieldLayoutInProgress, {
                    path: parsedLayout.path,
                    leveePaths: parsedLayout.leveePaths,
                    fromShapeFile: true
                })
            }
            else if (mode === FieldMode.Levees) {

                const parsedLayout = sfp.parseLeveeFieldLayout(geo, "")
                Object.assign(this.fieldLayoutInProgress, {
                    path: parsedLayout.path,
                    leveePaths: parsedLayout.leveePaths,
                    fromShapeFile: true
                })
            }
        },
        reset() {
            this.fieldLayoutInProgress = new FieldLayout(Pp4Uuid(),
                FieldMode.Furrows, [])
            this.fieldLayoutInProgress.name = this.defaultFieldLayoutName()
            this.fieldLayoutInProgress.soilType = 'Unknown',
                this.fieldLayoutInProgress.furrowBearing = null

            const fieldName = this?.startingGeoJson?.properties?.name || this.defaultFieldName()

            if (this.startingGeoJson) {
                this.updateFieldLayoutInProgressFromStartingGeoJson()
            }

            if (this.parentField) {
                this.fieldInProgress = this.parentField

                const parentFieldLayout = this.parentField.layouts.find(
                    (l) => l.id === this.parentField.activeLayoutId)

                this.fieldLayoutInProgress.path = parentFieldLayout.path.slice()
            }
            else {
                this.fieldInProgress = new Field(Pp4Uuid(),
                    fieldName, this.fieldLayoutInProgress)
            }

            this.currentStep = Steps.SelectFieldType
            this.furrowDirectionSet = false
            this.furrowDirectionCleanedUpPath = null
            this.selectedIrrigationSystem = null
            this.dragoverCount = 0
            this.editFieldTypeDisabled = null

            this.$store.dispatch('mapObjects/setHelpKey', null)
        },
        async returnToPreviousStep() {
            const previousCurrentStep = this.currentStep
            this.previousSteps.shift()
            this.currentStep = this.previousSteps[0]

            if (previousCurrentStep === Steps.CalculateSets) {
                this.currentStep = Steps.EditWaterSource
                this.previousSteps.shift() // we'd want to go back to edit furrow direction in this case...
                return
            }

            if ((previousCurrentStep === Steps.AddWaterSource) || (this.currentStep === Steps.AddWaterSource)) {
                await this.removeAddedIrrigationSystem()
            }

            //cleanup
            switch (previousCurrentStep) {
                case Steps.CalculateSets:
                    this.fieldLayoutInProgress.furrowSetDetails = new FurrowSetDetails()
                    break
                case Steps.SelectWaterSource:
                    this.selectedIrrigationSystem = null
                    break
            }

            //move to next step
            switch (this.currentStep) {
                case Steps.SelectFieldType:
                    this.reset()
                    break;
                case Steps.SelectLeveePathSource || Steps.SelectFurrowPathSource:
                    if (this.elevationCsvResults != null) {
                        this.elevationCsvResults = null
                        this.$store.dispatch('mapObjects/debug/setElevationOverlay', null)
                    }
                    break;
                case Steps.DrawFieldPath:
                    this.beginDrawFieldPath()
                    break
                case Steps.EditFieldPath:
                    this.beginEditFieldPath()
                    break
                case Steps.UploadFurrowPaths:
                    this.shapeFiles = null
                    this.loadingFile = false
                    this.furrowUploadModeSelected()
                    break;
                case Steps.UploadLeveePaths:
                    this.shapeFiles = null
                    this.dragoverCount = 0
                    this.loadingFile = false
                    this.leveeUploadModeSelected()
                    break;
                case Steps.SetFurrowDirection:
                    this.beginSetFurrowDirection()
                    break;
                case Steps.SelectWaterSource:
                    this.selectedIrrigationSystem = null
                    this.beginSelectWaterSource()
                    break;
                case Steps.AddWaterSource:
                    this.beginAddWaterSource()
                    break;
            }
        },
        currentStepChanged(newVal, oldVal) {
            this.$analytics.wizardStep(newVal)

            if (oldVal == null) {
                return
            }
            else {
                if (this.previousSteps.length <= 0) {
                    this.previousSteps.unshift(oldVal)
                    this.previousSteps.unshift(newVal)
                }
                else {
                    if (this.previousSteps[0] != newVal) {
                        this.previousSteps.unshift(newVal)
                    }
                }
            }

        },
        async removeAddedIrrigationSystem() {
            if (!this.addedIrrigationSystem) {
                return
            }

            await this.$store.dispatch('removeIrrigationSystem',
                {
                    irrigationSystemId: this.addedIrrigationSystem.id,
                    farmId: this.farm.id
                }
            )

            this.selectedIrrigationSystem = null
            this.addedIrrigationSystem = null
        },
        furrowModeSelected() {
            this.fieldLayoutInProgress.mode = FieldMode.Furrows

            this.updateFieldLayoutInProgressFromStartingGeoJson()

            if (this.fieldLayoutInProgress.path.length === 0) {
                this.currentStep = Steps.SelectFurrowPathSource
            }
            else {
                this.currentStep = Steps.EditFieldPath
                this.dispatchAddLayoutOrFinishEditingBoundsHelpTip()
            }
        },
        furrowUploadModeSelected() {
            this.fieldLayoutInProgress.path = []
            this.currentStep = Steps.UploadFurrowPaths
        },
        furrowBoundsUploaded() {
            this.beginEditFieldPath()
        },
        leveeModeSelected() {
            this.fieldLayoutInProgress.mode = FieldMode.Levees
            this.fieldLayoutInProgress.leveePaths = []

            this.updateFieldLayoutInProgressFromStartingGeoJson()

            if (this.fieldLayoutInProgress.path.length === 0) {
                this.currentStep = Steps.SelectLeveePathSource
            }
            else {
                this.currentStep = Steps.EditFieldPath
                this.dispatchAddLayoutOrFinishEditingBoundsHelpTip()
            }
        },
        leveeUploadModeSelected() {
            this.fieldLayoutInProgress.path = []
            this.currentStep = Steps.UploadLeveePaths
        },
        leveeDrawModeSelected() {
            this.beginDrawFieldPath()
        },
        beginDrawFieldPath() {
            this.currentStep = Steps.DrawFieldPath
            this.fieldLayoutInProgress.path = []
            this.$store.dispatch('mapObjects/setHelpKey', 'tap_field_bounds_points')
            this.$store.dispatch('mapObjects/showSearchAndCenterLocation')
        },
        beginEditFieldPath() {
            this.currentStep = Steps.EditFieldPath

            if (FieldMode.Levees === this.fieldLayoutInProgress.mode) {
                this.$store.dispatch('mapObjects/setHelpKey', 'finish_editing_bounds_levees')
            }
            else {
                this.$store.dispatch('mapObjects/setHelpKey', 'finish_editing_bounds_furrows')
            }

            this.$store.dispatch('mapObjects/hideSearchAndCenterLocation')
        },
        beginSetFurrowDirection() {
            this.furrowDirectionSet = false
            this.furrowDirectionCleanedUpPath = null
            this.selectedIrrigationSystem = null

            this.currentStep = Steps.SetFurrowDirection

            if (this.fieldLayoutInProgress.furrowBearing) {
                this.furrowDirectionSet = true
            }

            this.$store.dispatch('mapObjects/setHelpKey', 'set_furrow_direction')
        },
        async beginSelectWaterSource() {
            this.currentStep = Steps.SelectWaterSource
            this.showFurrowDirection = false
        },
        async beginAddWaterSource() {
            this.currentStep = Steps.AddWaterSource
            this.showFurrowDirection = false
            await this.$store.dispatch('mapObjects/setHelpKey', 'add_irrigation_system')
        },
        dispatchAddLayoutOrFinishEditingBoundsHelpTip() {
            if (this.parentField?.layouts?.length) {
                this.$store.dispatch('mapObjects/setHelpKey', 'begin_add_layout_wiz')
            }
            else if (this.fieldLayoutInProgress.path.length) {
                if (FieldMode.Levees === this.fieldLayoutInProgress.mode) {
                    this.$store.dispatch('mapObjects/setHelpKey', 'finish_editing_bounds_levees')
                }
                else {
                    this.$store.dispatch('mapObjects/setHelpKey', 'finish_editing_bounds_furrows')
                }
            }
        },
        async fieldDone() {
            if (this.startingGeoJson) {
                this.$emit('starting-geojson-used', this.startingGeoJson)
            }

            if (!this.addLayoutToField) {
                await this.$store.dispatch('addField', this.fieldInProgress)

                this.$analytics.fieldAdded(this.fieldInProgress)
            }
            else {
                await this.$store.dispatch('addFieldLayout', this.fieldLayoutInProgress)
            }
        },
        handleFieldPathAdded(path) {
            this.fieldLayoutInProgress.path = path
            this.beginEditFieldPath()
        },
        handleFieldLayoutBoundaryChanged(path) {
            this.fieldLayoutInProgress.path = path
        },
        handleFieldFurrowDirectionUpdated(newFurrowBearing, cleanedUpPath) {
            this.fieldLayoutInProgress.furrowBearing = newFurrowBearing

            if (cleanedUpPath) {
                this.furrowDirectionCleanedUpPath = JSON.parse(JSON.stringify(cleanedUpPath))
            }
            else {
                this.furrowDirectionCleanedUpPath = null
            }

            this.furrowDirectionSet = true
        },
        addOrSelectWaterSource(addOrSelectWaterSource) {
            if (this.furrowDirectionCleanedUpPath) {
                this.fieldLayoutInProgress.path = this.furrowDirectionCleanedUpPath
                this.furrowDirectionCleanedUpPath = null
            }

            if (addOrSelectWaterSource == 'addWaterSource') {
                this.beginAddWaterSource()
            }
            else if (addOrSelectWaterSource == 'selectWaterSource') {
                this.beginSelectWaterSource()
            }
        },
        async irrigationSystemAddedOrSelected() {
            this.fieldLayoutInProgress.primaryFlowRateId = this.selectedIrrigationSystem.flowRates[0].id

            if (this.fieldLayoutInProgress.mode == FieldMode.Levees) {
                return
            }

            this.currentStep = Steps.CalculateSets
        },
        async handleIrrigationSystemAdded(irrigationSystem) {
            this.addedIrrigationSystem = irrigationSystem
            this.selectedIrrigationSystem = irrigationSystem

            await this.$store.dispatch('addIrrigationSystem', irrigationSystem)

            await this.irrigationSystemAddedOrSelected()

            if (this.fieldLayoutInProgress.mode == FieldMode.Levees) {
                this.fieldDone()
            }
        },
        handleIrrigationSystemSelected(irrigationSystem) {
            this.addedIrrigationSystem = null
            this.selectedIrrigationSystem = irrigationSystem

            this.irrigationSystemAddedOrSelected()
        },
        handleFurrowSetsChanged(modifiedFieldLayout) {
            Object.assign(this.fieldLayoutInProgress, modifiedFieldLayout)
        },
        processUploadedShapeFiles(files) {
            const fileSorter = new FileSorter(this.$store)

            const fieldLayoutLoaded = (fieldLayout) => {
                if (fieldLayout == null) {
                    this.loadingFile = false
                    return
                }

                Object.assign(this.fieldLayoutInProgress, fieldLayout, {
                    id: this.fieldLayoutInProgress.id,
                    fromShapeFile: true
                })

                const fieldLayoutGeo = GeoUtil.LatLngs.toPolygon(fieldLayout.path)
                const centerGeo = GeoUtil.GeoJson.centerOfMass(fieldLayoutGeo)
                const centerLatLng = GeoUtil.GeoJson.toLatLngs(centerGeo)

                this.$store.dispatch('mapObjects/setRequestedPanToLatLng', centerLatLng)

                this.beginEditFieldPath()
            }

            if (this.fieldLayoutInProgress.mode == FieldMode.Furrows) {
                fileSorter.readAsFurrowField(files, fieldLayoutLoaded)
            } else {
                fileSorter.readAsLeveeField(files, fieldLayoutLoaded)
            }
        },
        uploadShapeFileChosen() {
            const files = this.$refs.shapefile_file.files
            if (files.length < 1) {
                return
            }
            this.shapeFiles = files
            this.processUploadedShapeFiles(files)
        },
        uploadShapeFileDropped(e) {
            if (!(e.dataTransfer && e.dataTransfer.files)) {
                return
            }
            const files = e.dataTransfer.files
            if (files.length < 1) {
                return
            }
            this.shapeFiles = files
            this.processUploadedShapeFiles(files)
        },
        uploadDragenter(e) {
            this.dragoverCount += 1
        },
        uploadDragleave(e) {
            if (this.dragoverCount > 0) {
                this.dragoverCount -= 1
            }
        },
        defaultFieldName() {
            const farm = this.$store.state.selectedFarm
            if (farm == null) {
                return 'Untitled'
            }

            const existingNames = farm.fields.map((field) => field.name)
            const nameRegex = /^Field ([0-9]+)$/
            const namePrefix = 'Field '

            return NamingAlg.defaultName(existingNames,
                { nameRegex, namePrefix })
        },
        defaultFieldLayoutName() {
            let existingFieldLayoutNames = []
            if (this.parentField) {
                existingFieldLayoutNames = this.parentField.layouts.map(
                    (layout) => layout.name
                )
            }

            return NamingAlg.fieldLayoutName(existingFieldLayoutNames)
        },
        async cancel() {
            await this.$store.dispatch('returnToSelectedFieldOrFurrowSet')
        }
    }
}
</script>

<style lang="css" scoped>
.map_cancel_button_div {
    border-bottom-left-radius: 15px;
    padding: var(--menu-border-size);
    flex-flow: column;
    overflow: auto;
    background: var(--menu-background-color);
}

.big_button_select_div {
    align-items: center;
    display: flex;
    flex-direction: column;
}

.select_field_type {
    text-align: center;
}

.big_button_middle {
    margin-right: 5px;
}

.big_button_select_buttons_div {
    display: flex;
    justify-content: center;
    align-content: center;
}

.big_button_select_button {
    margin-right: 5px;
}

.big_button_select_button:last-child {
    margin-right: 0px;
}

.field_name_div {
    display: flex;
    flex-direction: column;
}

.field_name_div_property {
    margin: 0.3em;
}

@media screen and (min-width: 100px) {
    .huge_icon_button {
        zoom: 1.5;
    }
}

@media screen and (min-width: 800px) {
    .huge_icon_button {
        zoom: 2;
    }
}

.file_drop_div {
    background: var(--secondary-dark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em;
}


.file_drop_div div {
    margin-bottom: 1em;
}

.levee_shapefile_drop_success_hint {
    display: block;
    text-align: center;
    margin: 0px;
    padding: 0px;
    align-self: center;
    opacity: 0.2;
}

.levee_shapefile_drop_success_hint_active {
    opacity: 1.0;
}
</style>

import { BuildDebugStore, ResetDebugStoreState } from '@/store/DebugStore'
import { GeometryEditMode, FieldMode, LatLng } from '@/store/Dto'

const emptyMapMode = (state) => {
    if(state.debug) {
        state.debug = ResetDebugStoreState(state.debug)
    }

    state.helpKey = null
    state.helpParams = {}
    state.startingGeoJson = null
    state.requestedPanToLatLng = null
    state.selectableFields = []
    state.unselectableFields = []
    state.selectedFields = []
    state.selectedPipePaths = []
    state.selectedPipePathEndPointTraversalIndexes = null
    state.selectablePipePaths = []
    state.unselectablePipePaths = []
    state.selectedFurrowSets = []
    state.selectableFurrowSets = []
    state.unselectableFurrowSets = []
    state.selectedIrrigationSystems = []
    state.selectableIrrigationSystems = []
    state.unselectableIrrigationSystems = []
    state.availableMapActions = {
        Home: true,

        
        /* Farm Actions */
        ClearSelectedField: false,
        Center: false,
        SearchLocation: false,
        EnterAddFieldMode: false,
        // EnterAddFieldLayoutMode: false,
        AddFieldBounds: false,
        AddFieldLayout: false,
        EnterAddIrrigationSystemMode: false,

        EnterAddPipePathMode: false,
        AddPipePath: false,
        AddIrrigationSystem: false,
        MoveIrrigationSystem: false,
        EditFarm: true,
        EnterMeasureToolMode: false,
        AddMeasurementTool: false,


        /* Field Actions */
        EditFieldBoundsMode: GeometryEditMode.None,
        SaveFurrowDirection: false,
        EnterEditLeveesMode: false,
        SaveLevees: false,
        EnterEditFurrowSetsMode: false,
        SaveFurrowSets: false,

        
        /* Irrigation System Actions */
        EditPipePathMode: GeometryEditMode.None
    }
    state.editingFarm = false
    state.editingField = false
    state.editingIrrigationSystem = false
    state.editingPipePath = false
    state.inPuncherMode = false

    return state
}

const fieldSelectedMode = (state, farm, field, fieldLayout, furrowSet) => {
    if(farm == null) {
        throw new Error("Farm Can Not Be Null")
    }
    if(field == null) {
        throw new Error("Field Can Not Be Null")
    }
    if(fieldLayout == null) {
        throw new Error("Field Layout Can Not Be Null")
    }

    farmSelectedMode(state, farm)

    state.availableMapActions.ClearSelectedField = true
    state.availableMapActions.EnterAddPipePathMode = true
    state.availableMapActions.EnterAddIrrigationSystemMode = true
    state.availableMapActions.EnterEditLeveesMode = true
    state.availableMapActions.EnterEditFurrowSetsMode = true
    state.availableMapActions.EnterMeasureToolMode = true
    // state.availableMapActions.EnterAddFieldLayoutMode = true

    state.selectableFields = farm.fields.filter((f) => {
        return f.id != field.id
    })
    state.selectedFields = [field]

    state.selectedLayouts = [fieldLayout]

    if(furrowSet == null && fieldLayout.furrowSetDetails != null && fieldLayout.furrowSetDetails.resultingFurrowSets.length > 0) {
        furrowSet = fieldLayout.furrowSetDetails.resultingFurrowSets[0]
    }

    if((fieldLayout.mode == FieldMode.Furrows) && furrowSet) {
        state.selectedFurrowSets = [furrowSet]
        state.selectableFurrowSets = fieldLayout.furrowSetDetails.resultingFurrowSets.filter(
            (otherFurrowSet) => otherFurrowSet.id !== furrowSet.id)

        state.selectablePipePaths = farm.irrigationSystems.reduce((accum, irrigationSystem) => {
            return accum.concat(irrigationSystem.pipePaths.filter((pipePath) => {
                return pipePath.fieldId === field.id && pipePath.furrowSetId === furrowSet.id
            }))
        }, [])
        state.unselectablePipePaths = []
    }
    else {
        state.selectablePipePaths = farm.irrigationSystems.reduce((accum, irrigationSystem) => {
            return accum.concat(irrigationSystem.pipePaths.filter((pipePath) => {
                return (pipePath.fieldId === field.id) && (pipePath.fieldLayoutId === fieldLayout.id) && (! pipePath.furrowSetId)
            }))
        }, [])

        state.unselectablePipePaths = []
    }

    return state
}

const fieldSelectedAndBeingEditedMode = (state, farm, field, fieldLayout, furrowSet) => {
   
    fieldSelectedMode(state, farm, field, fieldLayout, furrowSet)

    state.selectableFields = []
    state.unselectableFields = farm.fields.filter((f) => {
        return f.id != field.id
    })

    state.unselectablePipePaths = []
    state.selectablePipePaths = []

    state.availableMapActions.Home = false
    
    state.availableMapActions.EditFarm = false
    state.availableMapActions.EnterAddFieldMode = false
    state.availableMapActions.ClearSelectedField = false
    state.availableMapActions.EnterAddPipePathMode = false
    state.availableMapActions.EnterAddIrrigationSystemMode = false
    state.availableMapActions.EnterEditLeveesMode = false
    state.availableMapActions.EnterEditFurrowSetsMode = true
    state.availableMapActions.EnterMeasureToolMode = false
    // state.availableMapActions.EnterAddFieldLayoutMode = false
    state.editingField = true

    if(furrowSet != null) {
        state.unselectableFurrowSets = state.selectableFurrowSets
        state.selectableFurrowSets = []
    }

    state.selectedIrrigationSystems = []
    state.selectableIrrigationSystems = []
    state.unselectableIrrigationSystems = []

    return state
}

const irrigationSystemSelectedMode = (state, farm, irrigationSystem) => {
    let ret = farmSelectedMode(state, farm)

    const fieldIds = new Set()
    const activeLayoutIds = new Set()
    const activeFurrowSetIds = new Set()

    state.selectedFields = []
    state.selectableFields = farm.fields
    state.unselectableFields = []

    farm.fields.forEach((field) => {
        fieldIds.add(field.id)
        activeLayoutIds.add(field.activeLayoutId)

        const layout = field.layouts.find((l) => l.id === field.activeLayoutId)
        if(! layout) {
            return
        }

        if(layout.mode !== FieldMode.Furrows) {
            return
        }

        layout.furrowSetDetails.resultingFurrowSets.forEach((furrowSet) => {
            activeFurrowSetIds.add(furrowSet.id)
        })
    })

    ret.availableMapActions.EnterAddPipePathMode = false
    state.availableMapActions.EnterMeasureToolMode = false

    ret.selectedIrrigationSystems = [irrigationSystem]
    ret.unselectableIrrigationSystems = []
    ret.selectableIrrigationSystems = ret.selectableIrrigationSystems.filter((is) => is != irrigationSystem)

    ret.selectablePipePaths = irrigationSystem.pipePaths.filter((pipePath) => {
        if(! fieldIds.has(pipePath.fieldId)) {
            return false
        }

        if(! activeLayoutIds.has(pipePath.fieldLayoutId)) {
            return false
        }

        if(pipePath.furrowSetId) {
            if(! activeFurrowSetIds.has(pipePath.furrowSetId)) {
                return false
            }
        }

        return true
    })
    ret.unselectablePipePaths = []

    return ret
}

const irrigationSystemSelectedAndBeingEditedMode = (state, farm, irrigationSystem) => {
    irrigationSystemSelectedMode(state, farm, irrigationSystem)

    state.selectedFields = []
    state.selectableFields = []
    state.unselectableFields = farm.fields

    state.editingIrrigationSystem = true
}

const pipePathSelectedMode = (state, farm, field, fieldLayout, pipePath) => {
    let matchingFurrowSet = null
    if(pipePath.furrowSetId) {
        matchingFurrowSet = fieldLayout.furrowSetDetails.resultingFurrowSets.find((otherFurrowSet) => {
            return otherFurrowSet.id == pipePath.furrowSetId
        })
    }

    let ret = fieldSelectedMode(state, farm, field, fieldLayout, matchingFurrowSet)

    ret.availableMapActions.EnterAddPipePathMode = false
    state.availableMapActions.EnterMeasureToolMode = false

    if(fieldLayout.mode == FieldMode.Furrows && matchingFurrowSet) {
        ret.selectedFurrowSets = [matchingFurrowSet]
        ret.selectableFurrowSets = fieldLayout.furrowSetDetails.resultingFurrowSets.filter(
            (otherFurrowSet) => otherFurrowSet != matchingFurrowSet)

        ret.selectedPipePaths = [pipePath]
        ret.selectablePipePaths = ret.selectablePipePaths.filter(
            (otherPipePath) => otherPipePath != pipePath)
    }
    else {
        ret.selectedPipePaths = [pipePath]
        ret.unselectablePipePaths = []
        ret.selectablePipePaths = ret.selectablePipePaths.filter((pp) => pp != pipePath)
    }

    return ret
}

const pipePathSelectedAndBeingEditedMode = (state, farm, field, fieldLayout, pipePath) => {
    let ret = pipePathSelectedMode(state, farm, field, fieldLayout, pipePath)
    
    ret.availableMapActions.Home = false
    
    ret.availableMapActions.EditFarm = false
    ret.availableMapActions.EnterAddFieldMode = false
    ret.availableMapActions.EnterAddPipePathMode = false
    state.availableMapActions.EnterAddIrrigationSystemMode = false
    state.availableMapActions.EnterMeasureToolMode = false

    ret.unselectableFields = ret.selectableFields
    ret.selectableFields = []

    let furrowSet = null
    if(pipePath.furrowSetId) {
        furrowSet = fieldLayout.furrowSetDetails.resultingFurrowSets.find((otherFurrowSet) => {
            return otherFurrowSet.id == pipePath.furrowSetId
        })        
    }

    if(fieldLayout.mode == FieldMode.Furrows && furrowSet) {
        ret.selectedFurrowSets = [furrowSet]
        ret.unselectableFurrowSets = ret.selectableFurrowSets
        ret.selectableFurrowSets = []
    }

    ret.selectedPipePaths = [pipePath]
    ret.unselectablePipePaths = ret.selectablePipePaths.filter(
        (pp) => pp.id !== pipePath.id)
    ret.selectablePipePaths = []

    ret.selectedIrrigationSystems = []
    ret.selectableIrrigationSystems = []
    ret.unselectableIrrigationSystems = farm.irrigationSystems

    ret.editingPipePath = true
}

const farmSelectedMode = (state, farm) => {
    if(farm == null) {
        throw new Error("Farm Can Not Be Null")
    }
    
    emptyMapMode(state)

    state.availableMapActions.EnterAddFieldMode = true
    // state.availableMapAction.EnterAddFieldLayoutMode = false

    state.selectableFields = state.selectableFields.concat(farm.fields)


    if(farm.irrigationSystems != null) {
        state.selectableIrrigationSystems = farm.irrigationSystems
        state.selectedIrrigationSystems = []
        state.unselectableIrrigationSystems = []
        state.unselectablePipePaths = farm.irrigationSystems.reduce((accum, irrigationSystem) => {
            return accum.concat(irrigationSystem.pipePaths)
        }, [])
    }

    return state
}
const farmSelectedAndBeingEditedMode = (state, farm) => {
    farmSelectedMode(state, farm)

    state.editingFarm = true

    state.availableMapActions.Home = false
    state.availableMapActions.EditFarm = false
    state.availableMapActions.EnterAddFieldMode = false
    // state.availableMapActions.EnterAddFieldLayoutMode = false
    state.availableMapActions.EnterAddPipePathMode = false
    state.availableMapActions.EnterAddIrrigationSystemMode = false
    state.availableMapActions.EnterMeasureToolMode = false

    state.selectedFields = []
    state.selectableFields = []
    state.unselectableFields = farm.fields

    state.unselectablePipePaths = state.selectablePipePaths
    state.unselectablePipePaths.push(... state.selectedPipePaths)
    state.selectedPipePaths = []
    state.selectablePipePaths = []
    
    state.selectedIrrigationSystems = []
    state.selectableIrrigationSystems = []
    state.unselectableIrrigationSystems = farm.irrigationSystems

    return state
}

const addFieldMode = (state, {farm, startingGeoJson}) => {
    farmSelectedAndBeingEditedMode(state, farm)

    state.availableMapActions.SearchLocation = startingGeoJson ? false : true,
    state.availableMapActions.Center = startingGeoJson ? false : true,
    state.availableMapActions.AddFieldBounds = true
    state.startingGeoJson = startingGeoJson;

    return state
}

const addFieldLayoutMode = (state, farm, field, fieldLayout, furrowSet) => {
    fieldSelectedAndBeingEditedMode(state, farm, field, fieldLayout, furrowSet)
    
    state.availableMapActions.Center = true,
    state.availableMapActions.AddFieldLayout = true
    state.unselectableIrrigationSystems = farm.irrigationSystems
    
    return state
}

const addPipePathMode = (state, farm, field, fieldLayout, furrowSet) => {
    fieldSelectedAndBeingEditedMode(state, farm, field, fieldLayout, furrowSet)

    state.availableMapActions.AddPipePath = true

    return state
}

const enterMeasurementToolMode = (state, farm) => {
    farmSelectedAndBeingEditedMode(state, farm)

    state.availableMapActions.AddMeasurementTool = true

    return state
}

const addWaterSourceMode = (state, farm, field, layout, furrowSet) => {
    fieldSelectedAndBeingEditedMode(state, farm, field, layout, furrowSet)

    state.availableMapActions.AddIrrigationSystem = true
    state.unselectableIrrigationSystems = farm.irrigationSystems
 
    return state
}

const moveIrrigationSystemMode = (state, farm, irrigationSystem) => {
    irrigationSystemSelectedAndBeingEditedMode(state, farm, irrigationSystem)

    state.availableMapActions.MoveIrrigationSystem = true

    state.selectableIrrigationSystems = []
    state.selectedIrrigationSystems = []

    return state
}

const BuildMapObjectsStore = () => {
    return {
        modules: {
            debug: BuildDebugStore()
        },
        namespaced: true,
        state: emptyMapMode({}),
        getters:  {
            selectedFields(state) {
                return state.selectedFields
            },
            selectableFields(state) {
                return state.selectableFields
            },
            unselectableFields(state) {
                return state.unselectableFields
            },
            selectedPipePaths(state) {
                return state.selectedPipePaths
            },
            selectablePipePaths(state) {
                return state.selectablePipePaths
            },
            unselectablePipePaths(state) {
                return state.unselectablePipePaths
            },
            selectedFurrowSets(state) {
                return state.selectedFurrowSets
            },
            selectableFurrowSets(state) {
                return state.selectableFurrowSets
            },
            unselectableFurrowSets(state) {
                return state.unselectableFurrowSets
            },
            availableMapActions(state) {
                return state.availableMapActions
            },
            selectedIrrigationSystems(state) {
                return state.selectedIrrigationSystems
            },
            selectableIrrigationSystems(state) {
                return state.selectableIrrigationSystems
            },
            unselectableIrrigationSystems(state) {
                return state.unselectableIrrigationSystems
            }
        },
        mutations: {
            setEmptyMapMode(state) {
                emptyMapMode(state)
            },
            setHelpKey(state, value) {
                state.helpKey = value
                state.helpParams = {}
            },
            setHelpKeyAndParams(state, {key, params}) {
                state.helpKey = key
                state.helpParams = params
            },
            setRequestedPanToLatLng(state, value) {
                state.requestedPanToLatLng = value
            },
            setFarmSelectedMode(state, farm) {
                farmSelectedMode(state, farm)

                if(! farm.fields.length) {
                    state.helpKey = 'add_field'
                }
                
                state.availableMapActions.SearchLocation = true,
                state.availableMapActions.Center = true
            },
            setFieldSelectedMode(state, {farm, field, fieldLayout}) {
                fieldSelectedMode(state, farm, field, fieldLayout)

                const hasFurrowSets = fieldLayout.mode == FieldMode.Furrows && fieldLayout.furrowSetDetails && fieldLayout.furrowSetDetails.resultingFurrowSets.length > 0

                if(hasFurrowSets) {
                    //this is redundant code from setFurrowSetSelectedMode mutation, not sure how to factor it out in Vue...
                    let furrowSet = fieldLayout.furrowSetDetails.resultingFurrowSets[0]
                    state.selectedFurrowSets = [furrowSet]
                    state.selectableFurrowSets = fieldLayout.furrowSetDetails.resultingFurrowSets.filter(
                        (otherFurrowSet) => otherFurrowSet != furrowSet)
                }
            },
            setRequestPanToLatLng(state){
                state.setrequestPanToLatLng = (LatLng, null)
            },
            setEditFurrowDirectionMode(state, {farm, field, fieldLayout}) {
                fieldSelectedAndBeingEditedMode(state, farm, field, fieldLayout)
                state.availableMapActions.SaveFurrowDirection = true
                state.helpKey = 'set_furrow_direction'
            },
            setEditFieldBoundsPointsMode(state, {farm, field, fieldLayout}) {
                fieldSelectedAndBeingEditedMode(state, farm, field, fieldLayout)
                state.availableMapActions.EditFieldBoundsMode = GeometryEditMode.EditPoints
            },
            setAddFieldMode(state, {farm, startingGeoJson}) {
                addFieldMode(state, {farm, startingGeoJson})
            },
            setAddFieldLayoutMode(state, {farm, field, fieldLayout, furrowSet}) {
                addFieldLayoutMode(state, farm, field, fieldLayout, furrowSet)
            },
            setAddWaterSourceMode(state, {farm, field, layout, furrowSet}) {
                addWaterSourceMode(state, farm, field, layout, furrowSet)
            },
            setMoveIrrigationSystemMode(state, {farm, irrigationSystem}) {
                moveIrrigationSystemMode(state, farm, irrigationSystem)
            },
            setAddPipePathMode(state, {farm, field, fieldLayout, furrowSet}) {
                addPipePathMode(state, farm, field, fieldLayout, furrowSet)
            },
            setEditLeveesMode(state, {farm, field, fieldLayout}) {
                fieldSelectedAndBeingEditedMode(state, farm, field, fieldLayout)
                state.unselectableIrrigationSystems = farm.irrigationSystems
                state.availableMapActions.SaveLevees = true
            },
            setPipePathSelectedMode(state, {farm, field, fieldLayout, pipePath}) {
                pipePathSelectedMode(state, farm, field, fieldLayout, pipePath)
            },
            setIrrigationSystemSelectedMode(state, {farm, irrigationSystem}) {
                irrigationSystemSelectedMode(state, farm, irrigationSystem)
            }, 
            setEditPipePathPointsMode(state, {farm, field, pipePath, fieldLayout, endPointTraversalIndexes}) {
                pipePathSelectedAndBeingEditedMode(state, farm, field, fieldLayout, pipePath)

                state.availableMapActions.EditPipePathMode = GeometryEditMode.EditPoints

                if(endPointTraversalIndexes) {
                    state.selectedPipePathEndPointTraversalIndexes = endPointTraversalIndexes
                }
            },
            setFurrowSetSelectedMode(state, {farm, field, fieldLayout, furrowSet}) {
                fieldSelectedMode(state, farm, field, fieldLayout, furrowSet)
            },
            setFieldLayoutSelectedMode(state, {farm, field, fieldLayout, furrowSet}) {
                fieldSelectedMode(state, farm, field, fieldLayout, furrowSet)
            },
            setEditFurrowSetsMode(state, {farm, field, fieldLayout}) {
                fieldSelectedAndBeingEditedMode(state, farm, field, fieldLayout)

                state.availableMapActions.EnterEditFurrowSetsMode = false
                state.availableMapActions.SaveFurrowSets = true
            },
            showSearchAndCenterLocation(state) {
                state.availableMapActions.SearchLocation = false
                state.availableMapActions.Center = false
            },
            hideSearchAndCenterLocation(state) {
                state.availableMapActions.SearchLocation = false
                state.availableMapActions.Center = false
            },
            enterMeasurementToolMode(state, farm) {
                enterMeasurementToolMode(state, farm)
            },
            enterPuncherMode(state, {farm, field, layout, pipePath}) {
                pipePathSelectedAndBeingEditedMode(state, farm, field, layout, pipePath)

                state.editingPipePath = true
                state.inPuncherMode = true
            }
        },
        actions: {
            setHelpKey({commit}, value) {
                commit('setHelpKey', value)
            },
            setHelpKeyAndParams({commit}, {key, params}) {
                commit('setHelpKeyAndParams', {key, params})
            },
            setRequestedPanToLatLng({commit}, value) {
                commit('setRequestedPanToLatLng', value)
            },
            showSearchAndCenterLocation({commit}) {
                commit('showSearchAndCenterLocation')
            },
            hideSearchAndCenterLocation({commit}) {
                commit('hideSearchAndCenterLocation')
            }
        }
    }
}

export {
    BuildMapObjectsStore,
    emptyMapMode,
    farmSelectedMode,
    fieldSelectedMode,
    irrigationSystemSelectedMode,
    fieldSelectedAndBeingEditedMode
}

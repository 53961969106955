// a fucking copy of vars.scss cuz the import mechanisms just keep changing

// const dark0 = "#405465"; // dark grey
//   const dark1 = "#77838f"; // less dark grey
//   const light0 = "white"; // white
//   const light1 = "#ecf0f3"; // less white

//   const accent0 = "#1d9ad6"; // Revolution blue
//   const accent1 = "#3aae49"; // Delta style green
//   // const accent2 = "#77838f"; // this just looks grey to me
//   const accent3 = "#ccff00"; // Xbox neon!
//   // const accent4 = "#8cb7c9"; // dirty light blue
//   // const accent5 = "#09e6a0"; // maybe a sea green?

//   const bg0 = "black";
//   const bg1 = dark0;
//   const bg2 = dark1;
//   const bg3 = "#dddddd";
//   const bg4 = "#323232"; // slate blue that goes nice w/crazy

//   const fg0 = light0;
//   const fg1 = light1; // this would be for things like small description notes

//   const revBlue = accent0;
//   const deltaGreen = accent1;
//   const crazy = accent3;
//   const offline = "#ff4a00";
//   const error = "#f36900";
//   const warning = "#ea7d3f";
//   const highlightedCool = "#dce7f5";
//   const highlightedWarm = "#f5dcdc";

//REVBLUE
//#1D9AD6

//REVGREY
//#77838F

//slate grey
//#405465

//rev green
//#3aae49

//chalk blue
//#7cb7c9

//dark green
//#319044

// mint
//#09e6a0

//crazy green
//#ccff00



export default {
   bg0: '#303f4c',
   bg1: '#405465',
   bg2: '#98a8b6',
   fg0: 'white',
   fg1: '#dddddd',
   fg2: 'grey',
   crazy: '#cbff00',

   primary: '#3aae49',
   primaryDark : '#319044',
   primaryDarker: '#425565',
 
   primaryLight : '#dddddd',
   primaryLighter: 'white',
 
   secondary : '#1d9ad6',
   secondaryDark: '#13688f',
   secondaryDarker: '#211e16',
 
   secondaryLight: '#dddddd',
   secondaryLighter: 'white',




   SelectableIconColor: 'grey',
   SelectedIconColor: 'black',

   Secondary: "#fbb040",
   SecondaryLight: "#ffeaca",
   SecondaryLighter: "#f2e5d2",


   Primary: "#005838",
   PrimaryDark: "#003622",
   PrimaryDarker: "black",
   PrimaryLighter: "white",


   OkGreenColor: "#7CFC00",
   CancelRedColor: "#CC0000",
   DisabledColor: "rgba(194, 194, 194, 1.0)",
   WarningOrange: "orange",

   SelectedFieldStrokeColor: "#cbff00",
   SelectableFieldStrokeColor: "#8fad1b",
   UnselectableFieldStrokeColor: "grey",
   TentativeFieldStrokeColor: "#cbff00",

   SelectedFieldStrokeWeight: 4.0,
   SelectableFieldStrokeWeight: 3.0,
   UnselectableFieldStrokeWeight: 3.0,

   SelectedFieldFillColor: "transparent",
   SelectableFieldFillColor: "rgba(73, 69, 40, 0.5)",
   UnselectableFieldFillColor: "black",

   SelectedFieldFillOpacity: 0.0,
   SelectableFieldFillOpacity: 0.75,
   UnselectableFieldFillOpacity: 0.75,

   SelectedPipePathStrokeColor: "white",
   SelectablePipePathStrokeColor: "#b6b6b6",
   UnselectablePipePathStrokeColor: "#797979",

   SelectedPipePathStrokeWeight: 6.0,
   SelectablePipePathStrokeWeight: 6.0,
   UnselectablePipePathStrokeWeight: 6.00,

   ElevationStrokeWeight: 16.0,

   SelectedPipePathFillColor: "#599eb6",
   SelectablePipePathFillColor: "#5e9cb4",
   UnselectablePipePathFillColor: "#005268",

   ElevationInflectionPointMiddleColor: "yellow",

   JdYellow: "rgb(255, 221, 0)",
   JdGreen: "#387c2b"
}
<template>
        <div id="pipe_path_menu">

            <icon-button description="Set specific properties for Water sources" label="Water Source Settings"
                @click="showIrrigationSystemDialog()"><img :src="PipeSettingsIcon" :draggable="false"></icon-button>

            <icon-button description="Move Water Source" label="Move Water Source" class="button_margin_top"
                faIcon="fa-arrows" @click="enterMoveIrrigationSystemMode()" />

            <icon-button class="button_margin_top" description="Remove Water Source" label="Remove Water Source"
                faIcon="fa-minus-circle" faColorClass="cancel_red" @click="removeWaterSource" />
        </div>
</template>

<script>
import IrrigationSystemDialog from '@/components/maps/irrigation_systems/IrrigationSystemDialog'
import EditIcon from '@/assets/edit_line_points_icon.svg'
import ElevationIcon from '@/assets/elevation_icon.svg'
import HeadIcon from '@/assets/head_icon.svg'
import AddInlineTeeIcon from '@/assets/add_inline_tee_icon.png'
import WaterBothSidesIcon from '@/assets/water_both_sides_icon.svg'
import PipeSettingsIcon from '@/assets/settings-gears.svg'

export default {
    components: {
    },
    data: function () {
        return {
            EditIcon, ElevationIcon, HeadIcon, AddInlineTeeIcon,
            WaterBothSidesIcon, PipeSettingsIcon
        }
    },
    computed: {
        selectedIrrigationSystem: (vm) => vm.$store.state.selectedIrrigationSystem,
        selectedFarm: (vm) => vm.$store.state.selectedFarm
    },
    watch: {
    },
    mounted() {
    },
    methods: {
        async showIrrigationSystemDialog() {
            const irrigationSystem = this.selectedIrrigationSystem

            let farm = this.selectedFarm
            let fromSource = 'WaterSourceSettings'

            await this.$dialog.push(IrrigationSystemDialog, {
                irrigationSystem, farm, fromSource
            })
        },
        removeWaterSource() {
            let irrigationSystemId = this.selectedIrrigationSystem.id
            let farmId = this.selectedFarm.id
            const doTheRemove = async () => {
                await this.$store.dispatch('removeIrrigationSystem', { irrigationSystemId, farmId })
            }

            this.$dialog.confirm(doTheRemove)

        },
        enterMoveIrrigationSystemMode() {
            this.$store.dispatch('enterMoveIrrigationSystemMode')
        }

    }
}
</script>

<style scoped lang="css">
#flow_rate_widget {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#flow_rate {
    margin-right: 5px;
    width: 5em;
    text-align: center;
}

#suggested_flow_rates {
    display: inline-block;
}

#pressure_limits_widget {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pressure_limit_label {
    width: 2em;
}

#min_head_override {
    width: 3em;
    text-align: center;
    margin-right: 5px;
}

#max_head_override {
    width: 3em;
    text-align: center;
    margin-right: 5px;
}

.invalid_head {
    font-weight: bold;
    color: var(--error-red);
}

.invalid_head::selection {
    font-weight: bold;
    color: var(--error-red);
}
</style>

import { HoleSide } from '@/design/DesignDto'
import { GeoUtil } from '@/geo/GeoUtil'
import { Direction, FieldMode } from '@/store/Dto'
import PipePathAlg from '@/design/PipePathAlg'
import { ConnectorType } from '@/store/Dto'
class FurrowLineArray extends Array {
    constructor(...furrowLines) {
        super(furrowLines)
    }
}

class FieldGeoFactory {
    constructor(preferences) {
        const designPreferences = (preferences == null || preferences.debug == null || preferences.debug.design == null) ? false : preferences.debug.design
        const furrowPreferences = (preferences == null || preferences.furrows == null) ? 
            {
                minLengthInFeet: 0, 
                pipeActsAsSupplyConditionBearingDifferenceLessThanDegrees: 19.5,
                pipeActsAsSupplyConditionSectionWidthLessThanFeet: 50
            } : preferences.furrows
        const leveePreferences = (preferences == null || preferences.levees == null) ?
            {minAreaInSquareMeters: 0} : preferences.levees
        const pipePreferences = {minSupplyLengthInFeet: 5, snapOffsetFromFieldInteriorInMeters: 1}
        Object.assign(pipePreferences, (preferences != null) && ('pipe' in preferences) ? preferences.pipe : {})

        this.preferences = preferences
        this.minFurrowLengthInFeet = furrowPreferences.minLengthInFeet
        this.snapOffsetFromFieldInteriorInMeters = pipePreferences.snapOffsetFromFieldInteriorInMeters
        this.minSupplyLengthInFeet = pipePreferences.minSupplyLengthInFeet
        this.pipeActsAsSupplyConditionSectionWidthLessThanFeet = furrowPreferences.pipeActsAsSupplyConditionSectionWidthLessThanFeet
        this.maxFurrows = furrowPreferences.maxFurrows
        this.minAreaInSquareMeters = leveePreferences.minAreaInSquareMeters
        this.showFinalLeveeSplitPolys = designPreferences && designPreferences.showFinalLeveeSplitPolys
        this.showLeveePolySplitStages = designPreferences && designPreferences.showLeveePolySplitStages
        this.showLeveeSplitLines = designPreferences && designPreferences.showLeveeSplitLines
        this.showFieldFurrowBearingSplitSteps = designPreferences && designPreferences.showFieldFurrowBearingSplitSteps
        this.showFieldFurrowBearingGeos = designPreferences && designPreferences.showFieldFurrowBearingGeos
        this.showFurrowLines = designPreferences && designPreferences.showFurrowLines
        this.showFurrowPipeSegmentsNotTouching = designPreferences && designPreferences.showFurrowPipeSegmentsNotTouching
        this.showPipeClippingSteps = designPreferences && designPreferences.showPipeClippingSteps
        this.showSplitPipePaths = designPreferences && designPreferences.showSplitPipePaths
    }

    getWateringToleranceInMeters() {
        if(this.preferences == null || this.preferences.pipe == null || this.preferences.pipe.wateringToleranceInMeters == null) {
            return 0
        }
        else {
            return this.preferences.pipe.wateringToleranceInMeters
        }
    }

    buildFurrowPolys(fieldLayout, pipePath, debugPolys) {
        const furrowLines = this.buildPrimaryAndSecondaryFurrowLinesForPipePath(fieldLayout, pipePath, debugPolys)

        const {primary, secondary} = furrowLines

        const ret = []

        const addPolyFromCoords = (coords) => {
            if(coords.length === 0) {
                return
            }

            const polyCoords = []

            // coords along the pipe path
            coords.forEach((c) => {
                polyCoords.push(c[0])
            })

            // coords coming back along the outer end of the furrow
            coords.reverse().forEach((c) => { // note that reverse() modifies coords in place
                polyCoords.push(c[1])
            })

            polyCoords.push(coords[coords.length - 1][0])

            const geo = GeoUtil.Coords.toGeoJson([polyCoords])

            if(geo == null) {
                return
            }

            if(polyCoords.length > 16) {
                GeoUtil.GeoJson.simplify(geo, {tolerance: 0.00001, mutate: true})
            }

            ret.push(geo)
        }

        const addPolysFromFurrowLines = (furrowLines) => {
            let coords = []
            let currentGroupIndex = 0
            furrowLines.forEach((ls) => {
                if(ls.properties.groupIndex != currentGroupIndex) {
                    addPolyFromCoords(coords)
    
                    coords = []
    
                    currentGroupIndex = ls.properties.groupIndex
                }
    
                coords.push(GeoUtil.GeoJson.getCoords(ls))
            })
    
            addPolyFromCoords(coords)
        }

        addPolysFromFurrowLines(primary)
        addPolysFromFurrowLines(secondary)

        return ret
    }

    buildFurrowLinesFromBounds(path, bearing, furrowSpacingInInches, maxFurrows=10000) {
        if(furrowSpacingInInches < 1) {
            return []
        }

        const fieldGeo = GeoUtil.LatLngs.toPolygon(path)
        const perimeter = GeoUtil.GeoJson.length(fieldGeo, {units: 'kilometers'})

        const bearingReversed = GeoUtil.bearingReversed(bearing)
        const bearingPerpendicular = GeoUtil.bearingPerpendicular(bearing)
        const bearingPerpendicularReversed = GeoUtil.bearingReversed(bearingPerpendicular)

        const buildFurrowsFromPoint = (pointGeo) => {
            // create a line projection from the point
            const c = pointGeo.geometry.coordinates
            const p1 = GeoUtil.GeoJson.getCoord(GeoUtil.Coords.destination(c, perimeter, bearing))
            const p2 = GeoUtil.GeoJson.getCoord(GeoUtil.Coords.destination(c, perimeter, bearingReversed))
            const ls = GeoUtil.Coords.toLineString([p1, p2])

            // find intersection points with field geo
            const intersections = GeoUtil.GeoJson.lineIntersect(ls, fieldGeo)

            if(intersections == null 
                    || intersections.features == null 
                    || intersections.features.length === 0) {
                return []
            }

            // sort the intersections for use as line segments
            const farCoord = GeoUtil.GeoJson.getCoord(p1)
            intersections.features.sort((a, b) => {
                const ca = GeoUtil.GeoJson.getCoord(a)
                const cb = GeoUtil.GeoJson.getCoord(b)
                return GeoUtil.Coords.distance(farCoord, ca) 
                    - GeoUtil.Coords.distance(farCoord, cb)
            })

            const ret = []

            let prevPointGeo = null
            GeoUtil.GeoJson.featureEach(intersections, (geo) => {
                if(prevPointGeo == null) {
                    prevPointGeo = geo
                    return
                }

                const c1 = GeoUtil.GeoJson.getCoord(prevPointGeo)
                const c2 = GeoUtil.GeoJson.getCoord(geo)
                const midpoint = GeoUtil.Coords.midpoint(c1, c2)

                prevPointGeo = geo

                // remove segments that don't have a midpoint inside the field
                if(! GeoUtil.GeoJson.booleanPointInPolygon(midpoint, fieldGeo)) {
                    return
                }

                // this is probably a good furrow!
                const segmentLs = GeoUtil.Coords.toLineString([c1, c2])
                ret.push(segmentLs)
            })

            return ret
        }

        const centroid = GeoUtil.GeoJson.centroid(fieldGeo)

        const furrowSpacingInMiles = furrowSpacingInInches * 0.00001578283

        let ret = buildFurrowsFromPoint(centroid)
        let cPerp = centroid.geometry.coordinates
        let cPerpRev = centroid.geometry.coordinates
        let bearingPerpendicularDone = false
        let bearingPerpendicularReversedDone = false
        while(! (bearingPerpendicularDone && bearingPerpendicularReversedDone)) {
            if(ret.length > maxFurrows) {
                console.warn('Max Furrows Reached -- Breaking')
                break
            }

            if(! bearingPerpendicularDone) {
                const newPoint = GeoUtil.Coords.destination(
                    cPerp, furrowSpacingInMiles, bearingPerpendicular, {units: 'miles'})
                cPerp = newPoint

                const lengthBefore = ret.length

                ret = ret.concat(buildFurrowsFromPoint(newPoint))

                if(ret.length === lengthBefore) {
                    bearingPerpendicularDone = true
                }
            }

            if(! bearingPerpendicularReversedDone) {
                const newPoint = GeoUtil.Coords.destination(
                    cPerpRev, furrowSpacingInMiles, bearingPerpendicularReversed, {units: 'miles'})
                cPerpRev = newPoint

                const lengthBefore = ret.length

                ret = ret.concat(buildFurrowsFromPoint(newPoint))

                if(ret.length === lengthBefore) {
                    bearingPerpendicularReversedDone = true
                }
            }
        }
        
        return ret
    }

    buildFurrowLines(field, pipePath, debugPolys) {
        const {collectively} = this.buildPrimaryAndSecondaryFurrowLinesForPipePath(
            field, pipePath, debugPolys)

        return collectively
    }

    /*
        This is intended for callers who need to process the furrows
        in the order they appear along the pipe.

        @param {Object} callbacks Should have properties:
            supply(length, pipeType)
            furrow(lineString)
            pushJunction()
            popJunction()
            pushJunctionBranch(direction)
            popJunctionBranch()
            done()            
    */
    traversePipePathWithFurrows(fieldLayout, pipePath, callbacks, debugPolys) {
        this.buildPrimaryAndSecondaryFurrowLinesForPipePath(fieldLayout, pipePath, debugPolys, callbacks)
    }

    buildPrimaryAndSecondaryFurrowLinesForPipePath(fieldLayout, pipePath, debugPolys, furrowTraversalCallbacks) { //TODO: This needs to use the field layouts
        if(fieldLayout.mode !== FieldMode.Furrows || fieldLayout.furrowBearing == null) {
            return {
                primary: [], secondary: [],
                collectively: []
            }
        }

        if(furrowTraversalCallbacks == null) {
            furrowTraversalCallbacks = StubFurrowTraversalCallbacks()
        }

        let furrowSet = null
        if(pipePath.furrowSetId) {
            furrowSet = fieldLayout.furrowSetDetails.resultingFurrowSets.find(
                (furrowSet) => furrowSet.id == pipePath.furrowSetId)
            if(furrowSet == null) {
                throw new Error("Pipe Path Furrow Set Not Found: " + pipePath.furrowSetId)
            }
        }
    
        let boundsGeo = (furrowSet == null) ?
            GeoUtil.LatLngs.toPolygon(fieldLayout.path) 
            : GeoUtil.LatLngs.toPolygon(furrowSet.path)

        GeoUtil.GeoJson.simplify(
            boundsGeo, {tolerance: 0.00001, mutate: true})

        const wateringToleranceInMeters = this.getWateringToleranceInMeters()
        if(wateringToleranceInMeters > 0) {
            boundsGeo = GeoUtil.GeoJson.buffer(boundsGeo, wateringToleranceInMeters, {units: 'meters'})
            GeoUtil.GeoJson.simplify(boundsGeo, {tolerance: 0.00001, mutate: true})
        }

        const pipeLineStrings = PipePathAlg.buildPipeLineStringsWithMetadata(pipePath)

        if(this.showSplitPipePaths) {
            pipeLineStrings.forEach(ls => {
                ls.properties.strokeColor = 'red';
                ls.properties.strokeWeight = 16;
                ls.properties.zIndex = 6000;
                ls.properties.infoWindowContent = JSON.stringify(ls.properties.pipeType);
            })
            debugPolys.push(...pipeLineStrings)
        }

        const flattenedPipeLineStrings = pipeLineStrings.flat(1024) // should be sufficient number of levels...

        const waterBothSides = ('waterBothSides' in pipePath) && (pipePath.waterBothSides)
        const waterOtherSide = ('waterOtherSide' in pipePath) && (pipePath.waterOtherSide)

        let primary = []
        let secondary = []
        let collectively = []
        let primaryGroupIndex = 0
        let secondaryGroupIndex = 0

        const traversePipeLineStrings = (lineStringsArray, treatAsJunction) => {
            if(treatAsJunction) {
                furrowTraversalCallbacks.pushJunction(lineStringsArray.type)
            }

            let remainderFromLastSegInFeet = 0
            lineStringsArray.forEach((lineStringOrArray, i) => {
                if(treatAsJunction) {
                    let direction = Direction.Left
                    if( !(lineStringOrArray instanceof Array)) {
                        direction = lineStringOrArray.properties.direction
                    }
                    else {
                        direction = lineStringOrArray.direction
                    }

                    furrowTraversalCallbacks.pushJunctionBranch(direction)

                    primaryGroupIndex += 1
                    secondaryGroupIndex += 1
                }

                if(lineStringOrArray instanceof Array) {
                    traversePipeLineStrings(lineStringOrArray, ! treatAsJunction)

                    if(treatAsJunction) {
                        furrowTraversalCallbacks.popJunctionBranch()
                    }
                    
                    return
                }

                const pipeLineString = lineStringOrArray
                const pipeType = pipeLineString.properties.pipeType

                GeoUtil.GeoJson.segmentEach(pipeLineString, (segLs) => {
                    remainderFromLastSegInFeet = this.buildFurrowLinesAlongPipePathSegment(
                        segLs, remainderFromLastSegInFeet, pipeType, fieldLayout,
                        boundsGeo, flattenedPipeLineStrings,
                        primary, primaryGroupIndex, 
                        secondary, secondaryGroupIndex,
                        collectively, waterBothSides, waterOtherSide,
                        debugPolys,
                        furrowTraversalCallbacks) 
                    {
                        const lastPrimaryFurrowLine = primary.length > 0 ?
                            primary[primary.length - 1] : null
                        if(lastPrimaryFurrowLine != null) {
                            primaryGroupIndex = Math.max(primaryGroupIndex,
                                lastPrimaryFurrowLine.properties.groupIndex)
                        }
                    }
    
                    {
                        const lastSecondaryFurrowLine = secondary.length > 0 ?
                            secondary[secondary.length - 1] : null
                        if(lastSecondaryFurrowLine != null) {
                            secondaryGroupIndex = Math.max(secondaryGroupIndex,
                                lastSecondaryFurrowLine.properties.groupIndex)
                        }
                    }
                })

                const lastConnectorType = pipeLineString.properties.lastConnectorType
                if(lastConnectorType === ConnectorType.TiedOffEnd) {
                    furrowTraversalCallbacks.tiedOffEnd()
                }

                if(treatAsJunction) {
                    furrowTraversalCallbacks.popJunctionBranch()
                }
            })

            if(treatAsJunction) {
                furrowTraversalCallbacks.popJunction()
            }
        }

        traversePipeLineStrings(pipeLineStrings, false)

        furrowTraversalCallbacks.done()

        return {primary, secondary, collectively}
    }

    furrowLineValid(furrowLineString, pipeLineStrings, debugPolys) {
        if(furrowLineString == null) {
            return false
        }

        if(this.furrowLineRunsIntoPipe(furrowLineString, pipeLineStrings, debugPolys)) {
            return false
        }

        const lengthInFeet = GeoUtil.GeoJson.length(furrowLineString, {units: 'feet'})
        if(lengthInFeet < this.minFurrowLengthInFeet) {
            return false
        }

        return true
    }

    // compare w/DataServices.cs, GetPipeSegmentsForSet
    buildFurrowLinesAlongPipePathSegment(startingSegLs, 
            remainderFromLastSegInFeet, pipeType,
            fieldLayout, boundsGeo, pipeLineStrings, 
            primary, startingPrimaryGroupIndex,
            secondary, startingSecondaryGroupIndex,
            collectively, waterBothSides, waterOtherSide,
            debugPolys, furrowTraversalCallbacks) {
        let pipeSegmentLineString = startingSegLs

        // add the partially completed furrow from the last segment if necessary
        if(remainderFromLastSegInFeet > 0) {
            const newStartPoint = GeoUtil.GeoJson.along(
                pipeSegmentLineString, remainderFromLastSegInFeet, {units: 'feet'})
            const segCoords = GeoUtil.GeoJson.getCoords(pipeSegmentLineString)
            const startCoord = GeoUtil.GeoJson.getCoord(newStartPoint)
            pipeSegmentLineString = GeoUtil.Coords.toLineString(
                [startCoord, segCoords[segCoords.length - 1]])

            // TODO: ideally would perhaps adjust the elevationDifferenceInFeet proportionally here...
            // ...but assuming the furrow distances are not GIGANTIC, should not matter...
            Object.assign(pipeSegmentLineString.properties, startingSegLs.properties)
        }

        const primaryBearing = waterOtherSide ?
            GeoUtil.bearingReversed(fieldLayout.furrowBearing) : fieldLayout.furrowBearing                
        const secondaryBearing = GeoUtil.bearingReversed(primaryBearing)
        const perpendicularFurrowBearing = GeoUtil.bearingPerpendicular(primaryBearing)
        const pipeSegmentLengthInFeet = GeoUtil.GeoJson.length(pipeSegmentLineString, {units: 'feet'})

        if(pipeSegmentLengthInFeet < 1) {
            return 0
        }

        const elevationDifferenceInFeet = pipeSegmentLineString.properties.elevationDifferenceInFeet
        const elevationSlopeInFeetPerFoot = Number.isFinite(elevationDifferenceInFeet) ?
            (elevationDifferenceInFeet / pipeSegmentLengthInFeet) : 0
            
        const coords = GeoUtil.GeoJson.getCoords(pipeSegmentLineString)
        const segmentBearing = GeoUtil.Coords.bearing(coords[0], coords[coords.length - 1])
        const segmentBearingDifference = GeoUtil.bidirectionalBearingDifference(
            perpendicularFurrowBearing,
            segmentBearing
        )
        
        const segmentBearingDifferenceInDegrees = GeoUtil.bearingToAzimuth(segmentBearingDifference)
        const segmentBearingDifferenceInRadians = segmentBearingDifferenceInDegrees
             * Math.PI / 180.0
        const segmentWidthInFeet = GeoUtil.GeoJson.length(pipeSegmentLineString, {units: 'feet'})
        const wateredSegmentWidthInFeet = Math.sin((Math.PI / 2) - segmentBearingDifferenceInRadians) * segmentWidthInFeet

        const primaryHoleSide = waterBothSides ? 
            this.holeSideForFurrowBearingRelativeToSegmentBearing(primaryBearing, segmentBearing) : HoleSide.Left
        const secondaryHoleSide = (primaryHoleSide == HoleSide.Left ? HoleSide.Right : HoleSide.Left)

        const furrowSpacingInInches = fieldLayout.furrowSpacingInInches
            * (fieldLayout.alternatingFurrows ? 2.0 : 1.0)
        const stepDistanceInInches = furrowSpacingInInches
            / Math.cos(segmentBearingDifferenceInRadians)
        const stepDistanceInFeet = stepDistanceInInches / 12.0

        // Detecting what looks to be a run of supply, from DesignManager.cs in PP3
        if(wateredSegmentWidthInFeet < this.pipeActsAsSupplyConditionSectionWidthLessThanFeet) {
            if(pipeSegmentLengthInFeet > (wateredSegmentWidthInFeet * 3)) {
                const elevationDifferenceInFeet = pipeSegmentLengthInFeet * elevationSlopeInFeetPerFoot

                furrowTraversalCallbacks.supply(pipeSegmentLengthInFeet, elevationDifferenceInFeet, pipeType)
                return 0
            }
        }-0

        const estimatedFurrowCount = Math.round(pipeSegmentLengthInFeet / stepDistanceInFeet)
        if(estimatedFurrowCount > this.maxFurrows) {
            throw new Error('Furrow Limit Exceeded: ' + estimatedFurrowCount + ' > '+ this.maxFurrows)
        }

        const segmentChunks = GeoUtil.GeoJson.lineChunk(pipeSegmentLineString, 
            stepDistanceInInches, {units: "inches"})

        let primaryGroupIndex = startingPrimaryGroupIndex
        let secondaryGroupIndex = startingSecondaryGroupIndex
        let startNewPrimaryFurrowLineGroup = false
        let startNewSecondaryFurrowLineGroup = false
        let supplySegmentCount = 0

        const pushSupply = () => {
            if(supplySegmentCount < 1) {
                return
            }

            const supplyLengthInFeet = supplySegmentCount * stepDistanceInFeet
            const elevationDifferenceInFeet = supplySegmentCount * stepDistanceInFeet * elevationSlopeInFeetPerFoot

            furrowTraversalCallbacks.supply(supplyLengthInFeet, elevationDifferenceInFeet, pipeType)

            supplySegmentCount = 0
        }

        let remainderInFeet = 0
        GeoUtil.GeoJson.featureEach(segmentChunks, (furrowLineString, i) => {
            let furrowFound = false
            const furrowCoords = GeoUtil.GeoJson.getCoords(furrowLineString)
            const outletPoint = GeoUtil.Coords.toPoint(furrowCoords[0])
            const furrowLengthInFeet = GeoUtil.GeoJson.length(furrowLineString, {units: 'feet'})
            if(Math.abs(furrowLengthInFeet - stepDistanceInFeet) > 0.01) {
                remainderInFeet = (furrowLengthInFeet / stepDistanceInFeet) 
                    * (furrowSpacingInInches / 12)
            }

            if(! GeoUtil.GeoJson.booleanContains(boundsGeo, outletPoint)) {
                supplySegmentCount += 1
                startNewPrimaryFurrowLineGroup = true
                startNewSecondaryFurrowLineGroup = true
                return
            }

            // process the primary furrow line
            {
                const primaryFurrowLine = GeoUtil.GeoJson.getInteriorLineAlongBearingFromPoint(
                    boundsGeo, primaryBearing, outletPoint)

                if(! this.furrowLineValid(primaryFurrowLine, pipeLineStrings, debugPolys)) {
                    startNewPrimaryFurrowLineGroup = true
                }
                else {
                    if(startNewPrimaryFurrowLineGroup) {
                        startNewPrimaryFurrowLineGroup = false
                        primaryGroupIndex += 1
                    }

                    pushSupply()

                    const elevationDifferenceInFeet = (supplySegmentCount + 1) * stepDistanceInFeet * elevationSlopeInFeetPerFoot
        
                    primaryFurrowLine.properties.holeSide = primaryHoleSide
                    primaryFurrowLine.properties.groupIndex = primaryGroupIndex
                    primaryFurrowLine.properties.pipeWidthInInches = stepDistanceInInches
                    primaryFurrowLine.properties.elevationDifferenceInFeet = elevationDifferenceInFeet
                    primaryFurrowLine.properties.pipeType = pipeType

                    primary.push(primaryFurrowLine)
                    collectively.push(primaryFurrowLine)

                    furrowFound = true

                    if(this.showFurrowLines) {
                        debugPolys.push(primaryFurrowLine)
                    }
                    
                    furrowTraversalCallbacks.furrow(primaryFurrowLine)
                }
            }

            // process the secondary furrow line, if waterBothSides
            if(waterBothSides) {
                const secondaryFurrowLine = GeoUtil.GeoJson.getInteriorLineAlongBearingFromPoint(
                    boundsGeo, secondaryBearing, outletPoint)

                if(! this.furrowLineValid(secondaryFurrowLine, pipeLineStrings)) {
                    startNewSecondaryFurrowLineGroup = true
                }
                else {
                    if(startNewSecondaryFurrowLineGroup) {
                        startNewSecondaryFurrowLineGroup = false
                        secondaryGroupIndex += 1
                    }

                    pushSupply()

                    const elevationDifferenceInFeet = (supplySegmentCount + 1) * stepDistanceInFeet * elevationSlopeInFeetPerFoot
        
                    secondaryFurrowLine.properties.holeSide = secondaryHoleSide
                    secondaryFurrowLine.properties.groupIndex = secondaryGroupIndex
                    secondaryFurrowLine.properties.pipeWidthInInches =
                        furrowFound ? 0 : stepDistanceInInches
                    secondaryFurrowLine.properties.elevationDifferenceInFeet = elevationDifferenceInFeet
                    secondaryFurrowLine.properties.pipeType = pipeType

                    secondary.push(secondaryFurrowLine)
                    collectively.push(secondaryFurrowLine)

                    furrowFound = true

                    if(this.showFurrowLines) {
                        debugPolys.push(secondaryFurrowLine)
                    }

                    furrowTraversalCallbacks.furrow(secondaryFurrowLine)
                }
            }

            if(! furrowFound) {
                supplySegmentCount += 1
            }
            else {
                supplySegmentCount = 0
            }
        })

        pushSupply()

        return remainderInFeet
    }

    furrowLineRunsIntoPipe(furrowLineString, pipeLineStrings, debugPolys) {
        return pipeLineStrings.some((ls) => {
            const featureCollection = GeoUtil.GeoJson.lineIntersect(furrowLineString, ls)

            if(featureCollection.features.length === 0) {
                return false
            }
    
            if(featureCollection.features.length > 1) {
                return true
            }

            const intersectionCoords = GeoUtil.GeoJson.getCoords(featureCollection.features[0])
            const furrowCoords = GeoUtil.GeoJson.getCoords(furrowLineString)
            let distance = GeoUtil.Coords.distance(
                intersectionCoords, furrowCoords[0], {units: 'feet'})

            const couldBeIntentionalOverlap = 
                (distance < (this.snapOffsetFromFieldInteriorInMeters / 2))

            return ! couldBeIntentionalOverlap
        })
    }

    holeSideForFurrowBearingRelativeToSegmentBearing(furrowBearing, segmentBearing) {
        const direction = GeoUtil.directionForBearingRelativeToBearing(furrowBearing, segmentBearing)
        return direction === Direction.Left ?
            HoleSide.Left : HoleSide.Right
    }

    showFurrowLineSegment(ls, debugPolys) {
        let debugPoly = JSON.parse(JSON.stringify(ls))
        debugPoly.properties.strokeColor = '#7FFFD4'
        debugPoly.properties.strokeWeight = 5
        debugPoly.properties.fillColor = '#7FFFD4'
        debugPoly.properties.infoWindowContent = "Furrow Line Segment<br>" + JSON.stringify(ls)
        debugPolys.push(debugPoly)
    }

    showFurrowPipeSegmentsNotTouchingIfNecessary(furrowGeo, pipeSegment, debugPolys) {
        if(! this.showFurrowPipeSegmentsNotTouching) {
            return
        }

        let debugPoly = JSON.parse(JSON.stringify(furrowGeo))
        debugPoly.properties.strokeColor = 'green'
        debugPoly.properties.fillColor = 'green'
        debugPoly.properties.infoWindowContent = ("Furrow Poly")
        debugPolys.push(debugPoly)
        
        debugPoly = GeoUtil.GeoJson.lineStringToGeoJsonDebugPoly(pipeSegment)
        debugPoly.properties.strokeColor = 'orange'
        debugPoly.properties.fillColor = 'red'
        debugPoly.properties.infoWindowContent = ("Not Touching Any Furrow Poly")
        debugPolys.push(debugPoly)
    }

    getFallPerLeveeInFeet(fieldLayout) {
        return (fieldLayout.fallPerLeveeInInches / 12.0) * - 1.0
    }

    buildExtendedLineStringToEnsureItSplitsLastPaddy(ls) {
        const coords = GeoUtil.GeoJson.getCoords(ls)
        if(coords.length < 2) {
            return
        }

        const lastCoord = coords[coords.length - 1]
        const lastSegment = [coords[coords.length - 2], lastCoord]
        const bearing = GeoUtil.Coords.bearing(lastSegment[0], lastSegment[1])
        const distanceInKm = GeoUtil.Coords.distance(lastSegment[0], lastSegment[1])
        const newDistanceInKm = distanceInKm +
            (this.snapOffsetFromFieldInteriorInMeters / 1000.0) * 3
        const newLastPoint = GeoUtil.Coords.destination(lastSegment[0], 
            newDistanceInKm, bearing, {units: 'kilometers'})
        const newLastCoord = GeoUtil.GeoJson.getCoord(newLastPoint)

        const newCoords = coords.slice()
        newCoords[newCoords.length - 1] = newLastCoord

        return GeoUtil.Coords.toLineString(newCoords)
    }
    
    traversePipePathWithLevees(fieldLayout, pipePath, leveeTraversalCallbacks, debugPolys) {
        const fieldGeo = GeoUtil.LatLngs.toPolygon(fieldLayout.path)
        const leveePaddyGeos = this.buildLeveePaddyGeos(fieldLayout, 
            this.getWateringToleranceInMeters(), debugPolys)

        // Add a 'traversed' property. only traverse each paddy once.
        // The enter and exit points will be used to determine pipe and supply lengths.
        // The 'pipeLength' property will be added to as segments are processed.
        leveePaddyGeos.forEach((geo) => {
            const p = geo.properties
            p.traversed = false
            p.pipeEnterGeoJsonPoint = null
            p.pipeExitGeoJsonPoint = null
            p.pipeLastGeoJsonPoint = null // can be either the pipeExitGeoJsonPoint or a point inside the geo
            p.pipeLengthInFeet = 0
            p.pipeType = null
            p.paddyIndex = -1
            p.holeSide = HoleSide.Left
        })

        let PaddyIndex = -1
        const NextPaddyIndex = function() {
            PaddyIndex += 1
            return PaddyIndex
        }

        const firstMatchingPipeType = (geoPoint, lineStringsArray) => {
            const lineString = lineStringsArray.find((ls) => {
                if(ls instanceof Array) {
                    return false
                }

                return GeoUtil.GeoJson.booleanPointOnLine(geoPoint, ls)
            })

            if(lineString) {
                return lineString.properties.pipeType
            }

            return null
        }

        const addPropertiesToPaddyGeos = (branchLineString, lineStringsArray) => {
            leveePaddyGeos.forEach((geo) => {
                if(geo.properties.pipeExitGeoJsonPoint != null) {
                    return
                }

                if(geo.properties.traversed) {
                    return
                }

                // Feature collection of points where the line hits the paddy.
                let intersected = GeoUtil.GeoJson.lineIntersect(geo, branchLineString)
                if(intersected == null || intersected.features.length === 0) {
                    return
                }
                
                // Add property distanceFromStartOfLineInFeet to each intersection point.
                GeoUtil.GeoJson.featureEach(intersected, (f) => {
                    const distance = GeoUtil.GeoJson.pointDistanceFromStartOfLine(
                        f, branchLineString, {units: 'feet'})

                    f.properties.distanceFromStartOfLineInFeet = distance
                })

                // Sort intersection points by closest first.
                intersected.features.sort((a, b) => 
                    a.properties.distanceFromStartOfLineInFeet
                    - b.properties.distanceFromStartOfLineInFeet)

                // Establish the enter point if necessary.
                if(geo.properties.pipeEnterGeoJsonPoint == null) {
                    geo.properties.pipeEnterGeoJsonPoint = intersected.features[0]
                }

                geo.properties.pipeType = firstMatchingPipeType(
                    geo.properties.pipeEnterGeoJsonPoint, lineStringsArray)

                // Establish the exit point if necessary.
                {
                    // We exit the geo with the second intersection point.
                    if(intersected.features.length > 1) {
                        if((intersected.features.length % 2) === 0) {
                            geo.properties.pipeExitGeoJsonPoint = 
                                intersected.features[intersected.features.length - 1]
                        }

                        geo.properties.pipeLastGeoJsonPoint = 
                            intersected.features[intersected.features.length - 1]
                    }
                    else if(intersected.features.length === 1) {
                        // This piece of pipe ends in the middle of a paddy.
                        // Use the last point of the line as the "exit point".

                        const pipeCoords = GeoUtil.GeoJson.getCoords(branchLineString)
                        const lastPipeCoord = pipeCoords[pipeCoords.length - 1]
                        const lastPipePoint = GeoUtil.Coords.toPoint(lastPipeCoord)
                        lastPipePoint.properties.distanceFromStartOfLineInFeet = 
                            GeoUtil.GeoJson.pointDistanceFromStartOfLine(
                                lastPipePoint, branchLineString, {units: 'feet'})
                        geo.properties.pipeLastGeoJsonPoint = lastPipePoint
                    }
                    else {
                        throw new Error('Should Not Reach Because Zero Length Check Was Done Earlier')
                    }
                }

                // Pipe length
                {
                    geo.properties.pipeLengthInFeet = 
                        geo.properties.pipeLastGeoJsonPoint.properties.distanceFromStartOfLineInFeet
                        - geo.properties.pipeEnterGeoJsonPoint.properties.distanceFromStartOfLineInFeet
                }

                // Elevation difference
                geo.properties.elevationDifferenceInFeet = this.getFallPerLeveeInFeet(fieldLayout)
            })
        }

        const computeFullPaddyHoleSide = (paddyGeo) => {
            const props = paddyGeo.properties
            const generalPipeBearing = GeoUtil.GeoJson.bearing(
                props.pipeEnterGeoJsonPoint,
                props.pipeExitGeoJsonPoint ?
                    props.pipeExitGeoJsonPoint : props.pipeLastGeoJsonPoint
            )

            const paddyCenterPoint = GeoUtil.GeoJson.centerOfMass(paddyGeo)
            const pipeEnterToPaddyCenterBearing = GeoUtil.GeoJson.bearing(
                paddyGeo.properties.pipeEnterGeoJsonPoint, paddyCenterPoint
            )

            const direction = GeoUtil.directionForBearingRelativeToBearing(
                pipeEnterToPaddyCenterBearing, generalPipeBearing)

            return direction === Direction.Left ?
                HoleSide.Left : HoleSide.Right
        }

        const computeSplitPaddyHoleSide = (paddyGeo, paddySplitGeo) => {
            // We compute the HoleSide of the split paddy by comparing the 
            // general bearing of the pipe enter/exit line
            // to the bearing from the enter point to the center of the split paddy.

            const generalPipeBearing = GeoUtil.GeoJson.bearing(
                paddyGeo.properties.pipeEnterGeoJsonPoint,
                paddyGeo.properties.pipeLastGeoJsonPoint
            )

            const splitCenterPoint = GeoUtil.GeoJson.centerOfMass(paddySplitGeo)
            const pipeEnterToSplitCenterBearing = GeoUtil.GeoJson.bearing(
                paddyGeo.properties.pipeEnterGeoJsonPoint, splitCenterPoint
            )

            const direction = GeoUtil.directionForBearingRelativeToBearing(
                pipeEnterToSplitCenterBearing, generalPipeBearing)

            return direction === Direction.Left ?
                HoleSide.Left : HoleSide.Right
        }

        const traversePipeLineStrings = (lineStringsArray, treatAsJunction) => {
            if(treatAsJunction) {
                leveeTraversalCallbacks.pushJunction(lineStringsArray.type)
                
                lineStringsArray.forEach((lineStringOrArray) => {
                    let direction = Direction.Left
                    if( !(lineStringOrArray instanceof Array)) {
                        direction = lineStringOrArray.properties.direction
                    }
                    else {
                        direction = lineStringOrArray.direction
                    }

                    leveeTraversalCallbacks.pushJunctionBranch(direction)

                    const branchArray = (lineStringOrArray instanceof Array) ?
                        lineStringOrArray : [lineStringOrArray]

                    traversePipeLineStrings(branchArray, ! treatAsJunction)

                    leveeTraversalCallbacks.popJunctionBranch(direction)
                })

                leveeTraversalCallbacks.popJunction()

                return
            }

            let branchLineString = ('branchLineString' in lineStringsArray) ?
                lineStringsArray.branchLineString : lineStringsArray[0]

            branchLineString = this.buildExtendedLineStringToEnsureItSplitsLastPaddy(branchLineString)

            addPropertiesToPaddyGeos(branchLineString, lineStringsArray)

            const branchRelevantLeveePaddyGeos = leveePaddyGeos.filter((geo) => 
                (geo.properties.pipeEnterGeoJsonPoint != null) 
                && (geo.properties.traversed === false))

            branchRelevantLeveePaddyGeos.sort((a, b) => 
                a.properties.pipeEnterGeoJsonPoint.properties.distanceFromStartOfLineInFeet
                - b.properties.pipeEnterGeoJsonPoint.properties.distanceFromStartOfLineInFeet)

            const processEnteringFieldSupply = (segmentLineString, segmentRelevantPaddyGeos) => {
                if(segmentRelevantPaddyGeos.length === 0) {
                    return
                }

                const firstPaddyGeo = segmentRelevantPaddyGeos[0]
                if(firstPaddyGeo.properties.traversed) {
                    return
                }

                const supplyLengthInFeet = GeoUtil.GeoJson.pointDistanceFromStartOfLine(
                    firstPaddyGeo.properties.pipeEnterGeoJsonPoint, 
                    segmentLineString, {units: 'feet'})

                if(supplyLengthInFeet < this.minSupplyLengthInFeet) {
                    return
                }

                const elevationDifferenceInFeet = 0 // TODO: get a proper elevation or mark it a zero?
                const segmentPipeType = segmentLineString.properties.pipeType
                
                leveeTraversalCallbacks.supply(supplyLengthInFeet, elevationDifferenceInFeet, segmentPipeType)
            }

            const processExitingFieldSupply = (segmentLineString, segmentRelevantPaddyGeos) => {
                if(segmentRelevantPaddyGeos.length === 0) {
                    return
                }

                const lastPaddyGeo = segmentRelevantPaddyGeos[segmentRelevantPaddyGeos.length - 1]
                if(lastPaddyGeo.properties.pipeExitGeoJsonPoint === null) {
                    return
                }

                if(! GeoUtil.GeoJson.booleanPointOnLine(
                        lastPaddyGeo.properties.pipeExitGeoJsonPoint, segmentLineString)) {
                    return
                }

                const segmentLengthInFeet = GeoUtil.GeoJson.length(segmentLineString, {units: 'feet'})
                const pipeLengthToExitPoint = GeoUtil.GeoJson.pointDistanceFromStartOfLine(
                    lastPaddyGeo.properties.pipeExitGeoJsonPoint, 
                    segmentLineString, {units: 'feet'})
                const supplyLengthInFeet = segmentLengthInFeet - pipeLengthToExitPoint

                if(supplyLengthInFeet < this.minSupplyLengthInFeet) {
                    return
                }

                const elevationDifferenceInFeet = 0 // TODO: get a proper elevation or mark it a zero?
                const segmentPipeType = segmentLineString.properties.pipeType
                
                leveeTraversalCallbacks.supply(supplyLengthInFeet, elevationDifferenceInFeet, segmentPipeType)
            }
            
            const processPaddyIfNecessary = (paddyGeo, segmentLineString) => {
                paddyGeo.properties.pipeType = segmentLineString.properties.pipeType
                paddyGeo.properties.paddyIndex = NextPaddyIndex()

                const WaterBothSides = true // for the forseeable future...
                if(WaterBothSides) {
                    const startPoint = paddyGeo.properties.pipeEnterGeoJsonPoint
                    const stopPoint = paddyGeo.properties.pipeLastGeoJsonPoint
                    const geoSplitLineString = GeoUtil.GeoJson.lineSlice(
                        startPoint, stopPoint, branchLineString)

                    let splitGeos = GeoUtil.GeoJson.splitGeoWithLine(paddyGeo, geoSplitLineString)
                    
                    splitGeos.forEach((geo) => {
                        geo.properties.areaInSquareMeters =
                            GeoUtil.GeoJson.area(geo)
                    })

                    if(splitGeos.length > 2) {
                        // sort by area descending and take the two biggest ones
                        splitGeos.sort((a, b) => 
                            b.properties.areaInSquareMeters - a.properties.areaInSquareMeters)
                        splitGeos = splitGeos.slice(0, 2)
                    }

                    // filter out geos that don't meet the min area
                    splitGeos = splitGeos.filter((geo) => 
                        geo.properties.areaInSquareMeters > this.minAreaInSquareMeters)

                    if(splitGeos == null || splitGeos.length < 2) {
                        paddyGeo.properties.holeSide = computeFullPaddyHoleSide(paddyGeo)
                        leveeTraversalCallbacks.levee(paddyGeo)
                    }
                    else {
                        let firstHoleSide = null
                        let otherHoleSide = null

                        // add hole sides
                        
                        splitGeos.forEach((splitGeo, splitGeoIndex) => {
                            if(splitGeoIndex > 1) {
                                return
                            }

                            if(splitGeoIndex === 0) {
                                firstHoleSide = 
                                    computeSplitPaddyHoleSide(paddyGeo, splitGeo)
                                splitGeo.properties.holeSide = firstHoleSide

                                otherHoleSide = firstHoleSide === HoleSide.Left ?
                                    HoleSide.Right : HoleSide.Left
                            }
                            else {
                                splitGeo.properties.holeSide = otherHoleSide
                            }
                        })

                        // sort by hole side (always left to right)
                        splitGeos.sort((a, b) => {
                            if(a.properties.holeSide === b.properties.holeSide) {
                                return 0
                            }

                            if(a.properties.holeSide === HoleSide.Left) {
                                return -1
                            }

                            return 1
                        })

                        // Remove pipe length and elevation from all geos except the first (leftmost)
                        splitGeos.forEach((geo, i) => {
                            if(i > 0) {
                                geo.properties.pipeLengthInFeet = 0
                                geo.properties.elevationDifferenceInFeet = 0
                            }
                        })

                        splitGeos.forEach((splitGeo) => {
                            leveeTraversalCallbacks.levee(splitGeo)
                        })
                    }
                }
                else {
                    leveeTraversalCallbacks.levee(paddyGeo)
                }

                paddyGeo.properties.traversed = true
                
            }
            
            lineStringsArray.forEach((lineStringOrArray, someIndex) => {
                if(lineStringOrArray instanceof Array) {
                    traversePipeLineStrings(lineStringOrArray, ! treatAsJunction)
                    return
                }

                const segmentLineString = lineStringOrArray

                const segmentRelevantPaddyGeos = branchRelevantLeveePaddyGeos.filter(
                    (geo) => {
                        if(GeoUtil.GeoJson.booleanPointOnLine(
                                geo.properties.pipeEnterGeoJsonPoint, segmentLineString)) {
                            return true
                        }

                        const exitPoint = geo.properties.pipeExitGeoJsonPoint
                        if(! exitPoint) {
                            return false
                        }

                        return GeoUtil.GeoJson.booleanPointOnLine(
                            exitPoint, segmentLineString)
                    }
                )

                const segmentShouldBeIgnored = (segmentRelevantPaddyGeos.length === 0)
                    && GeoUtil.GeoJson.booleanContains(fieldGeo, segmentLineString)
                if(segmentShouldBeIgnored) {
                    // TODO: If the user changes the pipe within a paddy, this will ignore it. 
                    // 99% of the time this is the correct thing to do though
                    return
                }

                //supply outside the field
                if(segmentRelevantPaddyGeos.length === 0) {
                    const segmentLengthInFeet = GeoUtil.GeoJson.length(segmentLineString, {units: 'feet'})
                    
                    const segmentPipeType = segmentLineString.properties.pipeType
                    const segmentElevationDifferenceInFeet = segmentLineString.properties.elevationDifferenceInFeet

                    leveeTraversalCallbacks.supply(segmentLengthInFeet, segmentElevationDifferenceInFeet, segmentPipeType)

                    return
                }

                {
                    let color = GeoUtil.randomColor()
                    segmentLineString.properties.strokeColor = color;
                    debugPolys.push(segmentLineString)
                    segmentRelevantPaddyGeos.forEach((geo) => {
                        const copy = JSON.parse(JSON.stringify(geo));
                        copy.properties.strokeColor = color
                        copy.properties.fillColor = color
                        debugPolys.push(copy)
                    })
                }

                processEnteringFieldSupply(segmentLineString, segmentRelevantPaddyGeos)
                
                segmentRelevantPaddyGeos.forEach((paddyGeo) => {
                    if(paddyGeo.properties.traversed) {
                        return
                    }

                    processPaddyIfNecessary(paddyGeo, segmentLineString)
                })

                processExitingFieldSupply(segmentLineString, segmentRelevantPaddyGeos)
            })
        }

        const pipeLineStrings = PipePathAlg.buildPipeLineStringsWithMetadata(pipePath)

        traversePipeLineStrings(pipeLineStrings, false)
    }

    buildLeveePaddyGeosForPipePath(fieldLayout, pipePath, debugPolys) {
        const ret = []

        const callbacks = StubLeveeTraversalCallbacks()
        callbacks.levee = (geo) => {
            ret.push(geo)
        }

        this.traversePipePathWithLevees(fieldLayout, pipePath, callbacks, debugPolys)

        return ret
    }

    buildLeveePaddyGeos(fieldLayout, bufferInMeters, debugPolys) {
        let fieldPath = fieldLayout.path
        let fieldGeo = GeoUtil.LatLngs.toPolygon(fieldLayout.path)
        if(bufferInMeters !== 0) {
            fieldGeo = GeoUtil.GeoJson.buffer(fieldGeo, bufferInMeters, {units: 'meters'})
            fieldPath = GeoUtil.GeoJson.toLatLngs(fieldGeo)
        }
        

        let ret = [fieldGeo]

        this.showLeveeSplitStageIfNecessary(ret, debugPolys, -1)

        const stretchedLeveeLineStrings = fieldLayout.leveePaths.map((leveePath) => {
            const leveeLs = GeoUtil.LatLngs.toLineString(leveePath)
            const leeveePathLength = GeoUtil.GeoJson.length(leveeLs)
            const stretchedLs = GeoUtil.LatLngs.buildLineStretchedToPolygon(leveePath, fieldPath)
            const stretchedPathLength = GeoUtil.GeoJson.length(stretchedLs)

            if((stretchedPathLength / leeveePathLength) > 1.1) { // cap our stretch to 10%
                return leveeLs
            }

            return stretchedLs
        })

        stretchedLeveeLineStrings.forEach((stretchedLeveeLineString, leveePathIndex) => {
            let newRet = []

            this.showLeveeSplitLineIfNecessary(stretchedLeveeLineString, debugPolys)

            ret.forEach((polyGeo) => {
                let splitGeos = GeoUtil.GeoJson.splitPolyWithLine(polyGeo, stretchedLeveeLineString)

                if(splitGeos == null) {
                    newRet.push(polyGeo)
                }
                else {
                    splitGeos.forEach((splitGeo) => {
                        newRet.push(splitGeo)
                    })

                    this.showLeveeSplitStageIfNecessary(splitGeos, debugPolys, leveePathIndex)
                }
            })

            ret = newRet
        })

        this.showFinalLeveeSplitPolysIfNecessary(ret, debugPolys)

        return ret.filter(geo => {
            const area = GeoUtil.GeoJson.area(geo, {units: 'meters'})
            return area > this.minAreaInSquareMeters
        })
    }

    showLeveeSplitLineIfNecessary(lineString, debugPolys) {
        if(! this.showLeveeSplitLines) {
            return
        }

        let debugPoly = JSON.parse(JSON.stringify(lineString))
        debugPoly.properties.fillColor = "red"
        debugPoly.properties.strokeColor = "red"
        debugPolys.push(debugPoly)
    }

    showFinalLeveeSplitPolysIfNecessary(ret, debugPolys) {
        if(! this.showFinalLeveeSplitPolys) {
            return
        }

        ret.forEach((latLngs) => {
            let debugPoly = GeoUtil.LatLngs.toPolygon(latLngs)
            debugPoly.properties.fillColor = "orange"
            debugPoly.properties.strokeColor = "orange"
            debugPoly.properties.infoWindowContent = JSON.stringify(latLngs)
            debugPolys.push(debugPoly)
        })
    }

    showLeveeSplitStageIfNecessary(splitPolys, debugPolys, stage) {
        if(! this.showLeveePolySplitStages) {
            return
        }

        let color = GeoUtil.randomColor()

        splitPolys.forEach((poly) => {
            let debugPoly = JSON.parse(JSON.stringify(poly))
            debugPoly.properties.fillColor = color
            debugPoly.properties.strokeColor = color
            debugPoly.properties.infoWindowContent = "Poly Split Stage: " + stage
            debugPolys.push(debugPoly)
        })
    }
}

const StubFurrowTraversalCallbacks = () => {
    return {
        supply() {
        },
        furrow() {
        },
        tiedOffEnd() {
        },
        pushJunction(_type) {
        },
        popJunction() {
        },
        pushJunctionBranch() {
        },
        popJunctionBranch() {
        },
        done() {
        }
    }
}

const StubLeveeTraversalCallbacks = () => {
    return {
        supply() {
        },
        levee(_geo) {
        },
        pushJunction(_type) {
        },
        popJunction() {
        },
        pushJunctionBranch() {
        },
        popJunctionBranch() {
        },
        done() {
        }
    }
}

const LoggingFurrowTraversalCallbacks = (debugPolys) => {
    let indentDepth = 0
    let furrows = []

    const indent = () => {
        let ret = ''
        for(let i=0;i  < indentDepth;i++) {
            ret += '    '
        }
        return ret
    }

    const popFurrows = () => {
        if(furrows.length > 0) {
            console.log(indent() + 'Furrows: ' + furrows.length)
        }

        furrows = []
    }
    
    return {
        supply(length) {
            if(length <= 0) {
                return
            }

            popFurrows()

            console.log(indent() + 'Supply of Length: ' + length)
        },
        furrow(lineString) {
            debugPolys.push(lineString)
            furrows.push(lineString)
        },
        pushJunction(_type) {
            popFurrows()
            indentDepth += 1
            
        },
        popJunction() {
            indentDepth -= 1
        },
        pushJunctionBranch(direction) {
            const directionString = (direction === Direction.Left) ?
                'Left' : 'Right'
            console.log(indent() + '=====' + directionString + '=====')
        },
        popJunctionBranch() {
            popFurrows()
        },
        done() {
            popFurrows()
        }
    }
}

export {
    FieldGeoFactory, FurrowLineArray, StubFurrowTraversalCallbacks
}

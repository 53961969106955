<template>
   <pp4-field v-if="fieldLayout" :field="field" :fieldLayout="fieldLayout" :clickable="false" selectionStyle="selected"
      :showLabel="false" :showFurrowDirection="false" />

   <fullpageform title="Select Irrigation Mode or Divide Boundary" @cancel="cancel" :enableHotkeys="true">
      <div class="big_button_select_div select_field_type">
         <div class="field_name_div">
            <div class="field_name_div_property">
               <label for="addfieldwizard-field-name">Field Name:</label>
               <input v-model="fieldName" class="button_margin_left" type="text" id="addfieldwizard-field-name"
                  :placeholder="fieldName">
            </div>

            <div class="field_name_div_property">
               <label for="addfieldwizard-layout_name">Layout Name:</label>
               <input v-model="layoutName" class="button_margin_left" type="text" id="addfieldwizard-layout_name"
                  :placeholder="layoutName">
            </div>
            <!-- <div class="field_name_div_property">
               <soil-type-select v-model="fieldLayoutInProgress.soilType" />
            </div> -->
         </div>
         <div class="big_button_select_buttons_div widget_margin_top">
            <icon-button label="Furrow" description="Design for a field with furrows"
               class="huge_icon_button big_button_select_button" :big="true" @click="furrowModeSelected">
               <img :src="FurrowIcon" :draggable="false">
            </icon-button>

            <icon-button label="Levee" description="Design for a field with paddys and levees"
               class="huge_icon_button big_button_select_button" :big="true" @click="leveeModeSelected" :draggable="false">
               <img :src="LeveeIcon" :draggable="false">
            </icon-button>

            <icon-button label="Divide Boundary" :jd="true" description="Divide large boundaries into areas more appropriate for irrigation planning"
               class="huge_icon_button big_button_select_button" :big="true" @click="splitBoundarySelected" :draggable="false">
               <img :src="GuidanceLinesIcon" :draggable="false">
            </icon-button>

            <icon-button label="Remove" description="Remove this boundary mapping from Pipe Planner (does not affect John Deere data)"
               class="huge_icon_button big_button_select_button" :big="true" @click="removeField" :draggable="false">
               <img :src="RemoveIcon" :draggable="false">
            </icon-button>
         </div>
      </div>
   </fullpageform>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import * as NamingAlg from '@/util/NamingAlg'
import { FieldMode } from '@/store/Dto'

import FurrowIcon from '@/assets/furrow_icon.svg'
import LeveeIcon from '@/assets/levee_icon.svg'
import GuidanceLinesIcon from '@/assets/guidance_lines.svg'
import RemoveIcon from '@/assets/remove_icon.svg'

const props = defineProps(['field', 'fieldLayout'])
const emit = defineEmits(['done', 'split-boundary', 'removed', 'cancel'])

const $store = useStore()

const existingLayoutNames = computed(() => {
   return props.field.layouts.map(l => l.name)
})
const fieldName = defineModel('fieldName')
const layoutName = defineModel('layoutName', { default: NamingAlg.fieldLayoutName([]) })
const fieldMode = defineModel('fieldMode')

onMounted(() => {
   if (existingLayoutNames.value.length) {
      layoutName.value = NamingAlg.fieldLayoutName(existingLayoutNames.value)
   }
})

function cancel() {
   emit('cancel')
}

function furrowModeSelected() {
   fieldMode.value = FieldMode.Furrows
   emit('done')
}
function leveeModeSelected() {
   fieldMode.value = FieldMode.Levees
   emit('done')
}
function splitBoundarySelected() {
   emit('split-boundary')
}
function removeField() {
   $store.dispatch('removeSelectedField')
   emit('removed')
}
</script>

<style lang="css">
.big_button_select_div {
   align-items: center;
   display: flex;
   flex-direction: column;
}

.big_button_select_buttons_div {
    display: flex;
    justify-content: center;
    align-content: center;
    column-gap: var(--general-padding);
}

.select_field_type {
   text-align: center;
}

.field_name_div {
   display: flex;
   flex-direction: column;
}

.field_name_div_property {
   margin: 0.3em;
}

@media screen and (min-width: 100px) {
   .huge_icon_button {
      zoom: 1.5;
   }
}

@media screen and (min-width: 800px) {
   .huge_icon_button {
      zoom: 2;
   }
}
</style>
<template>
  <fullpageform title="Enter Distance Between Levees" 
            submitLabel="Continue" cancelLabel="Cancel"
            @submit="submit" @cancel="cancel">
        <div class="theform">
            <label
                for="levee_distance_in_feet">Levee Spacing (ft)</label>
            <input type="number" step="1" size="4" min="10" max="10000" 
                id="levee_distance_in_feet"
                ref="leveeDistanceInFeet"
                class="widget_margin_left" 
                v-model.number="leveeDistanceInFeet"
                v-on:keyup.enter="submit">
        </div>
  </fullpageform>
</template>

<script>
export default {
    data: function() {
        return {
            leveeDistanceInFeet: 50
        }
    },
    methods: {
        submit() {
            this.$dialog.pop()       

            this.$emit('continue', this.leveeDistanceInFeet)
        },
        cancel: function() {
            this.$dialog.pop()
        }
    },
    mounted: function() {
        this.$nextTick(() => {
            this.$refs['leveeDistanceInFeet'].focus()
            this.$refs['leveeDistanceInFeet'].select()
        })
    }
}
</script>

<style lang="css" scoped>
.theform {
    display: flex;
    flex-direction: row;
    align-content: center;
}

#levee_distance_in_feet {
    width: 5em;
}
</style>

<script>
import Pp4MapObject from "@/components/maps/Pp4MapObject"
import { GeoUtil } from "@/geo/GeoUtil"
import { LatLng } from "@/store/Dto"

export default {
  extends: Pp4MapObject,
  data: function () {
    return {
      zoomedToFirstField: false,
      pathToPanToWhenMapReady: null,
      lastRequestedPanToLatLng: null,
      cancelPanToCurrentLocation: false
    }
  },
  computed: {
    selectedFieldLayout: vm => vm.$store.state.selectedFieldLayout,
    selectedFurrowSet: vm => vm.$store.state.selectedFurrowSet,
    requestedPanToLatLng: vm => vm.$store.state.mapObjects.requestedPanToLatLng,
    selectedFarm: vm => vm.$store.state.selectedFarm,

  },
  watch: {
    requestedPanToLatLng: function (newLatLng) {
      if (newLatLng === this.lastRequestedPanToLatLng) {
        return
      }

      this.lastRequestedPanToLatLng = newLatLng

      if (this.map != null && newLatLng != null) {
        this.map.panTo(newLatLng)
        if (this.map.getZoom() > 16) {
          this.map.setZoom(16)
        }
        else if (this.map.getZoom() < 13) {
          this.map.setZoom(13)
        }
      }
    },
    selectedFieldLayout: function (fieldLayout) {
      if (!fieldLayout) {
        return
      }

      if (this.map === null) {
        this.pathToPanToWhenMapReady = fieldLayout.path
      }
      else {
        this.panToPath(fieldLayout.path)
      }
    },
    selectedFurrowSet: function (furrowSet) {
      if (!furrowSet) {
        return
      }

      if (this.map === null) {
        this.pathToPanToWhenMapReady = furrowSet.path
      }
      else {
        this.panToPath(furrowSet.path, true)
      }
    },
  },
  methods: {
    mapReady() {
      if (this.pathToPanToWhenMapReady != null) {
        this.panToPath(this.pathToPanToWhenMapReady)
        this.pathToPanToWhenMapReady = null
        return
      }

      const selectedFurrowSet = this.$store.state.selectedFurrowSet
      if (selectedFurrowSet) {
        this.panToPath(selectedFurrowSet.path)
        return
      }

      const selectedFieldLayout = this.$store.state.selectedFieldLayout
      if (selectedFieldLayout) {
        this.panToPath(selectedFieldLayout.path)
      }
    },
    bracketZoom() {
      const currentZoom = this.map.getZoom()

      // At zoom levels further out than 15, text
      // on the field is unreadable.
      // Let's go ahead and zoom in for them.
      if (currentZoom < 15) {
        this.map.setZoom(15)
      }
      // At zoom levels closer than 17, we usually can't
      // view a field in the ~50 acre ballpark. Let's zoom
      // out to a 18, where most fields look pretty good.
      else if (currentZoom > 18) {
        this.map.setZoom(18)
      }
    },
    panToCurrentLocationOrZoomedOutUsa() {
      let success = pos => {
        if (this.cancelPanToCurrentLocation) {
          this.cancelPanToCurrentLocation = false
          return
        }

        let latLng = new LatLng(pos.coords.latitude, pos.coords.longitude)
        this.map.panTo(latLng)
        this.bracketZoom()
      }

      let error = err => {
        if (this.cancelPanToCurrentLocation) {
          this.cancelPanToCurrentLocation = false
          return
        }

        console.warn(`Geolocation Failed: ${err.message}`)
        let usa = new LatLng(37.09024, -95.712891)
        this.map.panTo(usa)
        this.map.setZoom(5)
      }

      navigator.geolocation.getCurrentPosition(success, error)
    },
    panToPath(path) {
      if (! path) {
        this.cancelPanToCurrentLocation = false
        this.panToCurrentLocationOrZoomedOutUsa()
        return
      }

      this.cancelPanToCurrentLocation = true

      const geo = GeoUtil.LatLngs.toPolygon(path)

      // This is a special case, we really want to fit the first field correctly for the device.
      if (!this.zoomedToFirstField) {
        const bbox = GeoUtil.GeoJson.bbox(geo)
        const latLngBounds = this.googleMapUtils.bboxToLatLngBounds(bbox)
        this.map.fitBounds(latLngBounds)
        this.bracketZoom()

        this.zoomedToFirstField = true

        return
      }

      const bbox = GeoUtil.GeoJson.bbox(geo)
      const latLngBounds = this.googleMapUtils.bboxToLatLngBounds(bbox)

      this.map.panToBounds(latLngBounds, 400)
    }
  }
}
</script>

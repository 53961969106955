<script>

import { GeoUtil } from '@/geo/GeoUtil'
import { ZIndexes } from '@/maps/ZIndexes'

import Pp4MapObject from '@/components/maps/Pp4MapObject'

const BaseIcon = {
    path: "M 10 10 L 90 90 M 10 90 L 90 10",
    anchor: { x: 50, y: 50 },
    scale: 0.225,
    strokeOpacity: 0.7,
    fillOpacity: 0.7,
    strokeWeight: 5,
    zIndex: ZIndexes.CsvElevation
};

export default {
    extends: Pp4MapObject,
    props: {
        latLngsWithElevation: {
            type: Array,
            required: true,
            validator: function (value) {
                return !(value == null || value.length < 1)
            }
        },
        zIndex: {
            type: Number,
            required: false,
            default: ZIndexes.PipePaths - 1
        },
        clickable: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    computed: {
        pointFeatures: (vm) => vm.latLngsWithElevation.map((lle, index) => {
            const ret = GeoUtil.LatLngs.toGeoJsonPoint(lle)
            ret.properties.latLng = lle;
            ret.properties.elevationInFeet = lle.elevationInFeet
            ret.properties.index = index;
            return ret
        }),
        pointFeaturesWithMetadata: (vm) => {
            let max = Number.MIN_VALUE
            let min = Number.MAX_VALUE
            vm.latLngsWithElevation.forEach((lle) => {
                max = Math.max(max, lle.elevationInFeet)
                min = Math.min(min, lle.elevationInFeet)
            })

            const change = max - min
            const step = change / (vm.ElevationColors.length - 1)

            const ret = vm.pointFeatures

            ret.forEach((pointGeo) => {
                const index = Math.round((max - pointGeo.properties.elevationInFeet) / step)
                pointGeo.properties.color = vm.ElevationColors[index]
            })

            return ret
        },
        pointFeaturesCollection: (vm) => GeoUtil.GeoJson.featureCollection(vm.pointFeaturesWithMetadata)
    },
    data: function () {
        return {
            overlayMapObject: null,
            mouseoverIndex: -1,
            ElevationColors: [
                '#1E1D59',
                '#27286B',
                '#26419A',
                '#3A53A3',
                '#3E6FB4',
                '#36BDED',
                '#70C6B9',
                '#70C067',
                '#6EBF46',
                '#6DBE45',
                '#88C440',
                '#D1DC38',
                '#F6C926',
                '#F48B20',
                '#EF5322',
                '#ED2623',
                '#B21F25'
            ].reverse()
        }
    },
    watch: {
        latLngsWithElevation: function () {
            this.mapReady()
        }
    },
    methods: {
        updateStyle() {
            this.overlayMapObject.setStyle((feature, i) => {
                const icon = {...BaseIcon,
                    strokeColor: feature.getProperty('color'),
                    fillColor: feature.getProperty('color')
                };

                if (this.mouseoverIndex >= 0 && (feature.getProperty("index") == this.mouseoverIndex)) {
                    icon.strokeOpacity = 1.0;
                }

                return {
                    clickable: this.clickable,
                    // caution: using title will force 'clickable' to true
                    title: feature.getProperty('elevationInFeet').toFixed(1) + ' ft',
                    zIndex: ZIndexes.CsvElevation,
                    icon
                }
            })
        },
        buildGoogleStyleLatLngEvent(ll) {
            return {
                latLng: {
                    ...ll,
                    lat: () => ll.lat, lng: () => ll.lng
                },
                stop() {

                }
            }
        },
        mapReady() {
            this.removeMapObject(this.overlayMapObject)

            this.overlayMapObject = new this.api.Data()
            this.overlayMapObject.setDrawingMode(null)
            this.overlayMapObject.setControls(null)
            this.overlayMapObject.setMap(this.map)
            this.overlayMapObject.addGeoJson(this.pointFeaturesCollection)
            this.updateStyle()

            this.mapObjects.push(this.overlayMapObject)

            if (this.clickable) {
                this.overlayMapObject.addListener('click', (e) => {
                    const index = e.feature.getProperty('index')
                    this.$emit('click',
                        this.buildGoogleStyleLatLngEvent(this.latLngsWithElevation[index]))
                })

                this.overlayMapObject.addListener('dblclick', (e) => {
                    const index = e.feature.getProperty('index')
                    this.$emit('dblclick',
                        this.buildGoogleStyleLatLngEvent(this.latLngsWithElevation[index]))
                })

                this.addListener('mouseover', (e) => {
                    this.mouseoverIndex = e.feature.getProperty('index')
                    this.updateStyle();
                })

                this.addListener('mouseout', () => {
                    this.mouseoverIndex = -1;
                    this.updateStyle();
                })
            }
        }
    }
}
</script>

import { GeoUtil } from '@/geo/GeoUtil'
import { Pp4Uuid, FurrowSetDetails, FurrowSet } from '@/store/Dto'

class FurrowSetAlg {
  constructor() {
  }

  buildSplitPathGeos(outerPath, splitPaths) {
    let ret = [GeoUtil.LatLngs.toPolygon(outerPath)]

    if (!splitPaths?.length) {
      return ret
    }

    let splitLineStrings = splitPaths
      .slice()
      .map((path) => {
        const pathLs = GeoUtil.LatLngs.toLineString(path)
        const pathLength = GeoUtil.GeoJson.length(pathLs, { units: 'meters' })

        if (pathLength < 10) {
          return null
        }

        const stretchedLs = GeoUtil.LatLngs.buildLineStretchedToPolygon(path, outerPath)
        const stretchedPathLength = GeoUtil.GeoJson.length(stretchedLs, { units: 'meters' })

        const finalSplitterLine =
          stretchedPathLength / pathLength > 1.1 // cap our stretch to 10%
            ? pathLs
            : stretchedLs

        return finalSplitterLine
      })
      .filter((ls) => (ls ? true : false))

    let attempts = 0
    while (true) {
      attempts += 1

      let anySplitOccurred = false

      const newSplitLineStrings = []

      splitLineStrings.forEach((ls) => {
        let lsSplitOccurred = false
        let newGeos = []

        ret.forEach((geo) => {
          const split = GeoUtil.GeoJson.splitGeoWithLine(geo, ls)
          if (split == null || split.length < 2) {
            newGeos.push(geo)
            return
          }

          lsSplitOccurred = true
          anySplitOccurred |= lsSplitOccurred

          for (let splitGeo of split) {
            const areaInAcres = GeoUtil.GeoJson.areaInAcres(splitGeo)
            if (areaInAcres > 2) {
              splitGeo.properties.areaInAcres = areaInAcres
              newGeos.push(splitGeo)
            }
          }
        })

        ret = newGeos

        if (!lsSplitOccurred) {
          newSplitLineStrings.push(ls)
        }
      })

      splitLineStrings = newSplitLineStrings

      if (!anySplitOccurred) {
        break
      }

      if (attempts > 100) {
        console.warn('Over 100 Split Attempts Used -- Breaking')
        break //just figure some sort of limit is good
      }
    }

    return ret
  }

  buildSplitterLatLngs(outerGeo, debugPolys = []) {
    const innerGeo = GeoUtil.GeoJson.buffer(outerGeo, -10, { units: 'meters' })
    const innerLineString = GeoUtil.GeoJson.polygonToLine(innerGeo)
    const innerLineStringLength = GeoUtil.GeoJson.length(innerLineString, { units: 'meters' })

    const lineStrings =
      innerLineString.type === 'FeatureCollection' ? innerLineString.features : [innerLineString]


    // if (innerLineString.type === 'FeatureCollection') {
    //   debugPolys.push(...innerLineString.features)
    // }

    const ret = []

    // debugPolys.push(...lineStrings)

    lineStrings.forEach((ls) => {
      const Iterations = 200
      const StepLengthInMeters = Math.max(9.17, innerLineStringLength / Iterations)
      const chunks = GeoUtil.GeoJson.lineChunk(innerLineString, StepLengthInMeters, {
        units: 'meters'
      })

      // debugPolys.push(...chunks.features) 

      ret.push(
        ...chunks.features.map((ls) => {
          return GeoUtil.Coords.toLatLng(ls.geometry.coordinates[0])
        })
      )
    })

    return ret
  }

  buildFurrowSetDetailsUsingMaxSetAcres(
    maxAcres,
    splitPaths,
    bearing,
    outerPath,
    waterSourceLocation,
    debugPolys = []
  ) {
    const bestSetAreaInAcres = maxAcres
    const splitGeos = this.buildSplitPathGeos(outerPath, splitPaths)

    let finalSplitterLatLngs = []

    //perform the alg for each split path geo
    splitGeos.forEach((geoToSplit) => {
      const startingGeoToSplit = geoToSplit

      const possibleSplitterLatLngs = this.buildSplitterLatLngs(geoToSplit, debugPolys)

      const geoAreaInAcres = GeoUtil.GeoJson.areaInAcres(geoToSplit)
      const targetSetAreaInAcres = geoAreaInAcres / Math.ceil(geoAreaInAcres / bestSetAreaInAcres)

      const count = Math.ceil(geoAreaInAcres / targetSetAreaInAcres)

      let chosenSplitterLatLngs = []
      for (let c = 0; c < count - 1; c++) {
        let bestSoFar = null
        possibleSplitterLatLngs.forEach((latLng) => {
          let split = GeoUtil.GeoJson.interiorSplitGeoAlongBearingFromPoint(
            geoToSplit,
            bearing,
            GeoUtil.LatLngs.toGeoJsonPoint(latLng)
          )
          
          if ((! split) || (split.length < 2)) {
            return
          }

          const splitInfos = split.map(s => {
            const area = GeoUtil.GeoJson.areaInAcres(s)
            const difference = Math.abs(targetSetAreaInAcres - area)
            return {
              latLng, remainingGeo: null, geo: s, area, difference
            }
          })

          splitInfos.sort((a, b) => a.difference - b.difference)

          const bestThisTime = splitInfos[0]
          if ((!bestSoFar) || (bestThisTime.difference < bestSoFar.difference)) {
            const otherSplitInfos = splitInfos.slice(1)
            otherSplitInfos.sort((a, b) => b.area - a.area)

            const biggestRemainingGeo = otherSplitInfos[0].geo

            bestThisTime.remainingGeo = biggestRemainingGeo

            bestSoFar = bestThisTime
          }
        })

        if (bestSoFar == null) {
          break
        }

        geoToSplit = bestSoFar.remainingGeo
        chosenSplitterLatLngs.push(bestSoFar.latLng)
      }

      chosenSplitterLatLngs = chosenSplitterLatLngs.map(
        this.buildBuildBetterLookingLatLngFromMidpoint(startingGeoToSplit, bearing)
      )

      finalSplitterLatLngs.push(...chosenSplitterLatLngs)
    })

    return this.buildFurrowSetDetailsFromSplitPointLatLngsAndSplitPaths(
      finalSplitterLatLngs,
      splitPaths,
      bearing,
      outerPath,
      waterSourceLocation
    )
  }

  buildBuildBetterLookingLatLngFromMidpoint(outerGeo, bearing) {
    return function (latLng) {
      const splitLineString = GeoUtil.GeoJson.getInteriorSplitLineAlongBearingFromPoint(
        outerGeo,
        bearing,
        GeoUtil.LatLngs.toGeoJsonPoint(latLng)
      )

      const coords = GeoUtil.GeoJson.getCoords(splitLineString)

      const midpoint = GeoUtil.Coords.midpoint(coords[0], coords[coords.length - 1])

      return GeoUtil.GeoJson.toLatLngs(midpoint)
    }
  }

  // leaving the old alg for reference
  // buildFurrowSetDetailsUsingFurrowSetCount(
  //   count,
  //   splitPaths,
  //   bearing,
  //   outerPath,
  //   waterSourceLocation
  // ) {
  //   const outerGeo = GeoUtil.LatLngs.toPolygon(outerPath)
  //   const targetSetArea = GeoUtil.GeoJson.area(outerGeo, { units: 'meters' }) / count

  //   const possibleSplitterLatLngs = this.buildSplitterLatLngs(outerGeo)

  //   let chosenSplitterLatLngs = []
  //   {
  //     let geoToSplit = outerGeo
  //     for (let c = 0; c < count - 1; c++) {
  //       let bestSoFar = null
  //       possibleSplitterLatLngs.forEach((latLng) => {
  //         let split = GeoUtil.GeoJson.interiorSplitGeoAlongBearingFromPoint(
  //           geoToSplit,
  //           bearing,
  //           GeoUtil.LatLngs.toGeoJsonPoint(latLng)
  //         )
  //         if (split == null || split.length != 2) {
  //           return
  //         }

  //         const areaOne = GeoUtil.GeoJson.areaInAcres(split[0])
  //         const areaTwo = GeoUtil.GeoJson.areaInAcres(split[1])
  //         const differenceOne = Math.abs(targetSetArea - areaOne)
  //         const differenceTwo = Math.abs(targetSetArea - areaTwo)
  //         const toCompare =
  //           differenceOne < differenceTwo
  //             ? { latLng, remainingGeo: split[1], difference: differenceOne }
  //             : { latLng, remainingGeo: split[0], difference: differenceTwo }

  //         if (bestSoFar == null || toCompare.difference < bestSoFar.difference) {
  //           bestSoFar = toCompare
  //         }
  //       })

  //       if (bestSoFar == null) {
  //         break
  //       }

  //       geoToSplit = bestSoFar.remainingGeo
  //       chosenSplitterLatLngs.push(bestSoFar.latLng)
  //     }
  //   }

  //   chosenSplitterLatLngs = chosenSplitterLatLngs.map(
  //     this.buildBuildBetterLookingLatLngFromMidpoint(outerGeo, bearing)
  //   )

  //   return this.buildFurrowSetDetailsFromSplitPointLatLngs(
  //     chosenSplitterLatLngs,
  //     bearing,
  //     outerPath,
  //     waterSourceLocation
  //   )
  // }

  buildFurrowSetDetailsFromSplitPointLatLngs(latLngs, bearing, outerPath, waterSourceLocation) {
    return this.buildFurrowSetDetailsFromSplitPointLatLngsAndSplitPaths(
      latLngs,
      [],
      bearing,
      outerPath,
      waterSourceLocation
    )
  }

  buildFurrowSetDetailsFromSplitPointLatLngsAndSplitPaths(
    latLngs,
    splitPaths,
    bearing,
    outerPath,
    waterSourceLocation
  ) {
    let ret = new FurrowSetDetails()
    ret.splitPointLatLngs = latLngs
    if (splitPaths?.length) {
      ret.splitPaths = splitPaths
    }

    let geos = this.buildSplitPathGeos(outerPath, splitPaths)

    latLngs.forEach((pointLatLng) => {
      let pointGeo = GeoUtil.LatLngs.toGeoJsonPoint(pointLatLng)

      let newGeos = []

      geos.forEach((geo) => {
        let split = GeoUtil.GeoJson.interiorSplitGeoAlongBearingFromPoint(geo, bearing, pointGeo)
        if (! split) {
          newGeos.push(geo)
          return
        }

        const bigEnough = split.filter(s => GeoUtil.GeoJson.areaInAcres(s) > 2)

        newGeos.push(... bigEnough)
      })

      geos = newGeos
    })

    if (geos.length > 1) {
      geos.forEach((geo, i) => {
        let set = new FurrowSet(Pp4Uuid(), 'Set ' + (i + 1), GeoUtil.GeoJson.toLatLngs(geo))
        ret.resultingFurrowSets.push(set)
      })
    }

    this.nameAndSortFurrowSetsWithRespectToWaterSourceLocation(ret, waterSourceLocation)

    return ret
  }

  nameAndSortFurrowSetsWithRespectToWaterSourceLocation(furrowSetDetails, waterSourceLocation) {
    const waterSourcePointGeo = waterSourceLocation
      ? GeoUtil.LatLngs.toGeoJsonPoint(waterSourceLocation)
      : null

    let furrowSetsWithMetadata = furrowSetDetails.resultingFurrowSets.map((furrowSet, i) => {
      if (!waterSourcePointGeo) {
        return {
          furrowSet,
          distanceFromWaterSourceLocation: i
        }
      }

      const lineString = GeoUtil.LatLngs.toLineString(furrowSet.path)

      return {
        furrowSet,
        distanceFromWaterSourceLocation: GeoUtil.GeoJson.pointToLineDistance(
          waterSourcePointGeo,
          lineString
        )
      }
    })

    furrowSetsWithMetadata.sort(
      (a, b) => a.distanceFromWaterSourceLocation - b.distanceFromWaterSourceLocation
    )

    furrowSetsWithMetadata.forEach((o, i) => {
      o.furrowSet.name = 'Set ' + (i + 1).toString()
    })
    furrowSetDetails.resultingFurrowSets = furrowSetsWithMetadata.map((o) => o.furrowSet)
  }
}

export { FurrowSetAlg }

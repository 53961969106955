<template>
    <div>
        <template v-if="dialogMode">
            <div class="stylin dialog_absolute_position">
                <slot />
            </div>
        </template>
        <template v-else>
            <map-controls position="RIGHT_CENTER">
                <div class="stylin">
                    <slot />
                </div>
            </map-controls>
        </template>
    </div>
</template>

<script>
import MapControls from '@/components/maps/MapControls'

export default {
    props: {
        dialogMode: {
            type: Boolean,
            default: false
        }
    },
    components: {
        MapControls
    },
    methods: {
        emitClick() {
            this.$emit('click')
        }
    }
}
</script>

<style lang="css" scoped>
.stylin {
    border-top-left-radius: var(--menu-border-radius);
    border-bottom-left-radius: var(--menu-border-radius);
    padding: var(--menu-border-size);
    flex-flow: column;
    overflow: auto;
    background: var(--menu-background-color);
}

.dialog_absolute_position {
    position: absolute;
    right: 0px;
    top: 50%;
    z-index: 1000;
}
</style>

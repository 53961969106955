<template>
   <remove-levee-paths :field="props.field" :fieldLayout="props.fieldLayout"
        v-model="model"/>

    <water-source v-for="irrigationSystem in farm.irrigationSystems" :key="irrigationSystem.id"
        :location="irrigationSystem.waterSourceLocation" :name="irrigationSystem.name" :showLabel="true" :clickable="false"
        selectionStyle="unselectable" />

    <slot />

    <context-menu>
      <!-- <toggle-button v-if="removePointsAvailable" label="Remove Points" class="button_margin_top" :active="data.removePointsActive"
        description="Remove Levee Points" :disabled="removePointsDisabled" @click="toggleRemovePoints()">
        <img :src="RemovePointsIcon" draggable="false" />
      </toggle-button>

      <icon-button label="Create Straight Levees" class="button_group_margin_top" v-show="precisionSpacingAvailable"
        faIcon="fa-repeat" @click="beginPrecisionSpacing" /> -->

      <icon-button v-if="jdAvailable" label="Guidance Levees"
        description="Import associated John Deere Guidance lines as levees"
        @click="importJdGuidanceLines" :jd="true">
        <img :src="GuidanceLinesIcon" :draggable="false">
      </icon-button>

      <!-- <div class="radio_button_group button_group_margin_top">
        <radio-button id="levee_edit_mode_add" name="levee_edit_mode" v-model="data.mode" :radioValue="LeveeEditMode.Add"
          faIcon="fa-plus-circle" label="Add" description="Add Levee Paths" />
        <radio-button id="levee_edit_mode_edit" name="levee_edit_mode" v-model="data.mode"
          :radioValue="LeveeEditMode.Edit" faIcon="fa-pencil" label="Edit" description="Edit Levee Paths" />
        <radio-button id="levee_edit_mode_remove" name="levee_edit_mode" v-model="data.mode"
          :radioValue="LeveeEditMode.Remove" faIcon="fa-minus-circle" label="Remove" description="Remove Levee Paths" />
      </div> -->
    </context-menu>
</template>

<script setup>
import { inject, onMounted, ref, computed, onUnmounted } from 'vue'

import GuidanceLinesIcon from '@/assets/guidance_lines.svg'

const $store = inject("$store")
const $dialog = inject("$dialog")

const props = defineProps({
    farm: {
        type: Object,
        required: false
    },
    field: {
        type: Object,
        required: false
    },
    fieldLayout: {
        type: Object,
        required: true,
        validator: function (value) { return value != null && value.id != null }
    }
})

const emit = defineEmits(['done'])

const model = defineModel()

const jdAvailable = computed(() => props.field?.jd ? true : false)

async function importJdGuidanceLines() {
    const jd = props.field.jd

    model.value = await $store.getters['jd/getFieldGuidanceLines'](
        jd.organizationId, jd.fieldId
    )

    $dialog.toast({message: "Guidance Lines Found: " + model.value.length, jd: true})
}

function done() {
    emit('done')
}

onMounted(() => {
    importJdGuidanceLines()
})

onUnmounted(() => {
})
</script>

<style lang="css"></style>

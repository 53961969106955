<template>
    <template v-if="props.lineStyle === 'pipe-segment' ">
        <pipe-segment v-if="prevPath" :preview="true" :path="prevPath"/>
        <pipe-segment v-if="nextPath" :preview="true" :path="nextPath"/>
    </template>
    <template v-else>
        <pp4-polyline v-if="prevPath" :path="prevPath"/>
        <pp4-polyline v-if="nextPath" :path="nextPath"/>
    </template>
</template>

<script setup>
import { inject, computed } from 'vue'

const props = defineProps({
    prev: {
        type: Object, required: false, default: null
    },
    next: {
        type: Object, required: false, default: null
    },
    lineStyle: {
        type: String,
        required: false,
        default: "pipe-segment",
        validator: (val) => (val === "pipe-segment")
            || (val === 'polyline')
    }
})

const pointMarkerData = inject('pointMarkerData')

const prevPath = computed(() => {
    if(! props.prev) {
        return null
    }

    const loc = pointMarkerData.location
    if(! loc) {
        return null
    }

    return [props.prev, loc]
})

const nextPath = computed(() => {
    if(! props.next) {
        return null
    }

    const loc = pointMarkerData.location
    if(! loc) {
        return null
    }

    return [loc, props.next]
})

</script>
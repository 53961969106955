import { toRaw } from 'vue'

import { LatLng } from '@/store/Dto'
import { GeoUtil } from '@/geo/GeoUtil'
import { GoogleMapUtils } from './GoogleMapUtils'
import { ZIndexes } from './ZIndexes'
import Vars from '@/Vars'

import RemoveIcon from '@/assets/remove_icon.svg';
import EditIcon from '@/assets/edit_icon.svg';

// The naming on this shows some age but the general 
// idea is it creates map objects.
class InterpretationFactory {
    constructor(api, map, store) {
        this.api = api 
        this.map = map
        this.googleMapUtils = new GoogleMapUtils(api, map, store)

        this.RemoveIcon = RemoveIcon

        this.BoundsPointIcon = {
            path: this.api.SymbolPath.CIRCLE,
            fillColor: Vars.SelectedFieldStrokeColor,
            strokeColor: Vars.SelectedFieldStrokeColor,
            fillOpacity: 1.0,
            strokeOpacity: 1.0,
            scale: 4.0
        }

        this.RemoveBoundsPointIcon = {
            scaledSize: new this.api.Size(16, 16, "px", "px"), 
            url: this.RemoveIcon,
            anchor: new this.api.Point(8, 14) 
        }

        this.PipePathPointIcon = {
            icon: {
                path: this.api.SymbolPath.CIRCLE,
                fillColor: '#599eb6',
                strokeColor: 'white',
                fillOpacity: 1.0,
                strokeOpacity: 1.0,
                scale: 4.0
            }
        }
    }

    buildLabelOverlay(labelText, latLng, {elementClass, targetPane} = {elementClass: null, targetPane: null}) {
        let element = document.createElement('div')
        element.innerHTML = labelText

        if(elementClass) {
            element.classList.add(elementClass)
        }
    
        let finalPane = targetPane || 'markerLayer'
    
        let ret = this.buildElementOverlay(element, latLng, {pane: finalPane})

        let oldClass = elementClass
        let googleMapUtils = this.googleMapUtils

        ret.addListener = function (name, callback) {
            element.addEventListener(name, callback)

            return {
                remove() {
                    element.removeEventListener(name, callback)
                }
            }
        }

        ret.setOptions = function({labelText, latLng, elementClass, visible}) {
            if(labelText) {
                this.element.innerHTML = labelText
            }
            if(latLng) {
                this.centerLatLng = googleMapUtils.toGoogleLatLng(latLng)
            }

            if(visible === false) {
                this.element.classList.add('hidden')
            }
            else {
                this.element.classList.remove('hidden')
            }

            if(elementClass) {
                if(oldClass) {
                    this.element.classList.remove(oldClass)
                }

                this.element.classList.add(elementClass)
                oldClass = elementClass
            }

            this.draw()
        }

        return ret
    }

    buildElementOverlay(element, geoPoint, options) {
        let ret = BuildElementOverlay(this.api, element, geoPoint, options)

        ret.setMap(this.map)

        return ret
    }

    buildBoundedOverlay(element, googleLatLngBounds, options) {
        let ret = BuildBoundedOverlay(this.api, element, googleLatLngBounds, options)

        ret.setMap(this.map)

        return ret
    }

    buildPolyline(latLngs, options) {
        if(! options) {
            options = {}
        }

        if(! options.visible) {
            options.visible = true
        }

        if(! options.editable) {
            options.editable = false
        }

        if(! options.strokeColor) {
            options.strokeColor = "white"
        }

        if(! options.strokeWeight) {
            options.strokeWeight = 5.0
        }

        if(! options.zIndex) {
            options.zIndex = 100
        }
        
        options.paths = latLngs

        let ret = new this.api.Polyline(options)

        ret.setMap(this.map)

        return ret
    }

    buildPoly(latLngs, options) {
        if(! options) {
            options = {}
        }

        options.paths = latLngs

        let ret = new this.api.Polygon(options)

        ret.setMap(this.map)

        return ret
    }

    buildInfoWindow(content) {
        let contentWrapper = document.createElement('div')
        contentWrapper.innerHTML = content
        contentWrapper.className += 'info_window_content'
        return new this.api.InfoWindow({
            content: contentWrapper
        })
    }

    buildRemoveMarker(latLng) {
        return this.buildMarker(latLng, RemoveIcon)
    }

    buildEditMarker(latLng) {
        return this.buildMarker(latLng, EditIcon)
    }

    buildWaterSourceMarker(latLng) {
        let waterSourceIcon = this.buildWaterSourceMapIcon('white', '#599eb6')

        let ret = this.buildMarker(latLng)

        ret.setOptions({icon : waterSourceIcon.icon})

        return ret
    }

    buildPipePathPointMarker(latLng) {
        let ret = this.buildMarker(latLng)

        ret.setOptions({icon : this.PipePathPointIcon.icon})

        return ret
    }

    buildBoundsMarker(latLng) {
        let ret = this.buildMarker(latLng)

        ret.setOptions({
            icon : this.BoundsPointIcon,
            clickable: false
        })

        return ret
    }

    boundsMarkerIcon() {
        return this.BoundsPointIcon
    }

    removeBoundsMarkerIcon() {
        return this.RemoveBoundsPointIcon
    }

    buildMarker(latLng, icon) {
        return new this.api.Marker({
            map: this.map, 
            position: latLng, 
            visible: true,
            icon: icon ? { 
                scaledSize: new this.api.Size(32, 32, "px", "px"), 
                url: icon,
                anchor: new this.api.Point(16, 16) 
            } : null
        })
    }

    buildWaterSourceMapIcon(pipeColor, waterColor) {
        return {
            icon: {
                path: 'm 95.249999,101.20833 c 0,0 66.720211,47.79256 81.993971,84.66667 8.77514,21.18507 16.63193,50.59963 2.67269,68.79166 -17.78666,23.18003 -55.44887,34.44623 -84.666661,34.44623 -25.56557,0 -58.52,-11.3279 -74.083333,-31.61043 C 5.5581996,237.16111 9.6263064,211.92046 19.028509,188.06688 32.300278,154.39613 95.25,101.20833 95.25,101.20833',
                anchor: new this.api.Point(100, 60),
                scale: 0.125,
                fillColor: waterColor,
                fillOpacity: 1.0,
                strokeColor: pipeColor,
                strokeOpacity: 1.0,
                strokeWeight: 3
            },
            offset: '0%',
            fixedRotation: true
        }
    }

    buildPipePathPolyline(latLngs, pipeColor, waterColor) {
        const RunningWaterIcon = {
            icon: {
                path: 'M 0,0 0,0',
                strokeOpacity: 1.0,
                strokeWeight: 2.0,
                strokeColor: waterColor
            },
            offset: '0',
            repeat: '1px'
        }

        let ret = new this.api.Polyline({
            path: this.googleMapUtils.toGooglePath(latLngs),
            clickable: false,
            strokeColor: pipeColor,
            strokeOpacity: 1.0,
            strokeWeight: 6,
            icons: [this.buildWaterSourceMapIcon(pipeColor, waterColor), RunningWaterIcon],
            zIndex: ZIndexes.PipePaths
        })

        ret.setMap(this.map)

        return ret
    }

    buildFurrowArrow({fieldLayout, fieldPoly, bearing, isPreview, color, strokeWeight}) {
        let fieldBounds = this.googleMapUtils.polyBounds(fieldPoly)
        let fieldGeo = GeoUtil.LatLngs.toPolygon(fieldLayout.path)
        let fieldCenterGeo = GeoUtil.GeoJson.centerOfMass(fieldGeo)
        let fieldCenterLatLng = new LatLng(fieldCenterGeo.geometry.coordinates[1], fieldCenterGeo.geometry.coordinates[0])
        
        let finalColor = color ? 
            color : 'green'
        let finalStrokeWeight = strokeWeight ?
            strokeWeight : 1.0

        let arrowLength = () => {
            let ne = fieldBounds.getNorthEast()
            let sw = fieldBounds.getSouthWest()

            ne = new LatLng(ne.lat(), ne.lng())
            sw = new LatLng(sw.lat(), sw.lng())

            return GeoUtil.LatLngs.distance(ne, sw) * 0.25
        }
        
        let bearingPoint = GeoUtil.destination(fieldCenterLatLng, arrowLength(), bearing)

        let path = [fieldCenterLatLng, {lat: bearingPoint.geometry.coordinates[1], lng: bearingPoint.geometry.coordinates[0]}]

        const ArrowIcon = {
            icon: {
                path: this.api.SymbolPath.FORWARD_CLOSED_ARROW,
                fillColor: finalColor,
                fillOpacity: 1.0,
                strokeWeight: finalStrokeWeight,
                strokeOpacity: 1.0,
            },
            offset: '100%'
        }

        const DashedLineIcon = {
            icon: {
                path: 'M 0,-1.5 0,0',
                strokeOpacity: 1.0,
                strokeWeight: finalStrokeWeight,
                scale: 4
            },
            offset: '0',
            repeat: '20px'
        }

        let icons = []

        if(isPreview) {
            icons.push(DashedLineIcon)
        }

        icons.push(ArrowIcon)

        let ret = new this.api.Polyline({
            path: path,
            clickable: false,
            strokeColor: finalColor,
            strokeOpacity: isPreview ? 0.0 : 1.0,
            strokeWeight: finalStrokeWeight,
            zIndex: ZIndexes.FurrowArrows,
            icons: icons
        })

        ret.setMap(this.map)
        
        return ret
    }
}

let ElementOverlayClass = null

function BuildElementOverlay(api, element, centerLatLng, options) {
    if(! ElementOverlayClass) {
        ElementOverlayClass = function(element, centerLatLng, pane) {
            this.element = element
            this.centerLatLng = centerLatLng
            this.pane = pane
            this.elementWidth = null
            this.elementHeight = null

            if(! this.element) {
                throw new Error("Element Required")
            }
        }

        ElementOverlayClass.prototype = new api.OverlayView()

        ElementOverlayClass.prototype.onAdd = function() {
            if(! this.element) {
                return
            }

            this.getPanes()[this.pane].appendChild(this.element)
        }

        ElementOverlayClass.prototype.onRemove = function() {
            toRaw(this.element).remove()
            this.element = null
        }

        ElementOverlayClass.prototype.draw = function() {
            if(! this.element) {
                return
            }

            let proj = this.getProjection()
            if(proj == null) {
                return
            }
            
            if(! this.elementWidth) {
                this.elementWidth = this.element.clientWidth
            }
            if(! this.elementHeight) {
                this.elementHeight = this.element.clientHeight
            }

            let xy = proj.fromLatLngToDivPixel(this.centerLatLng)

            let style = this.element.style

            Object.assign(style, {
                left: (xy.x- (this.elementWidth/2)).toString() +"px",
                top: (xy.y- (this.elementHeight/2)).toString() +"px"
            })
        }

        ElementOverlayClass.prototype.setVisible = function(val) {
            if(val) {
                this.element.classList.remove('hidden')
            }
            else {
                this.element.classList.add('hidden')
            }
        }
    }

    let pane = (options && options.pane) ? options.pane : 'mapPane'

    return new ElementOverlayClass(element, new api.LatLng(centerLatLng), pane)
}

let BoundedOverlayClass = null

function BuildBoundedOverlay(api, element, googleLatLngBounds, options) {
    if(! BoundedOverlayClass) {
        BoundedOverlayClass = function(element, googleLatLngBounds, pane) {
            this.element = element
            this.bounds = googleLatLngBounds
            this.pane = pane
            this.elementWidth = null
            this.elementHeight = null

            if(! this.element) {
                throw new Error("Element Required")
            }
        }

        BoundedOverlayClass.prototype = new api.OverlayView()

        BoundedOverlayClass.prototype.onAdd = function() {
            this.getPanes()[this.pane].appendChild(this.element)
        }

        BoundedOverlayClass.prototype.onRemove = function() {
            this.element.remove()
        }

        BoundedOverlayClass.prototype.draw = function() {
            let proj = this.getProjection()
            if(proj == null) {
                return
            }
            
            const sw = proj.fromLatLngToDivPixel(
                this.bounds.getSouthWest()
            )
            const ne = proj.fromLatLngToDivPixel(
                this.bounds.getNorthEast()
            )

            let style = this.element.style

            Object.assign(style, {
                left: sw.x.toString() +"px",
                top: ne.y.toString() +"px",
                width: (ne.x - sw.x) + "px",
                height: (sw.y - ne.y) + "px"
            })
        }

        BoundedOverlayClass.prototype.setVisible = function(val) {
            if(val) {
                this.element.classList.remove('hidden')
            }
            else {
                this.element.classList.add('hidden')
            }
        }
    }

    let pane = (options && options.pane) ? options.pane : 'mapPane'

    return new BoundedOverlayClass(element, googleLatLngBounds, pane)
}

export {
    InterpretationFactory
}

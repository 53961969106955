import { GeoUtil } from './GeoUtil'

function ApplyElevationToLatLngs(
  latLngs,
  latLngsWithElevation,
  MaximumExternalDataPointDistanceInFeet = 50
) {
  for (let latLng of latLngs) {
    let closest = null

    //Loop through each external GPS point to find the closest;
    const DistanceOpts = { units: 'feet' }
    for (const latLngEle of latLngsWithElevation) {
      let distance = GeoUtil.LatLngs.distance(latLng, latLngEle, DistanceOpts)
      if (!closest || distance < closest.distance) {
        closest = { latLngEle: latLngEle, distance: distance }
      }
    }

    //Check the point and if valid, apply the elevation
    if (closest && closest.distance <= MaximumExternalDataPointDistanceInFeet) {
      latLng.elevationInFeet =
        Math.round((closest.latLngEle.elevationInFeet + Number.EPSILON) * 100) / 100
      latLng.interpolated = false
    }
  }
}

function BuildSimplifiedBufferedGeo(geo, offsetInMeters) {
  const simplifiedGeo = GeoUtil.GeoJson.simplify(geo, { tolerance: 0.000001, mutate: false })

  const bufferedGeo = GeoUtil.GeoJson.buffer(simplifiedGeo, offsetInMeters, { units: 'meters' })

  if (bufferedGeo) {
    GeoUtil.GeoJson.simplify(bufferedGeo, { tolerance: 0.000001, mutate: true })
  }

  return bufferedGeo ? bufferedGeo : geo // the buffering may fail
}

function BuildLatLngSnapCallback(
  snapThresholdInMeters,
  interiorOffsetInMeters,
  snapGeos,
  options = { EnablePointGravity: true, OutsideBoundsAlwaysSnaps: false }
) {
  const buildInteriorDataForLevel = (level) => {
    const bufferedSnapGeos = snapGeos.map((snapGeo) => {
      if (GeoUtil.GeoJson.isLineString(snapGeo)) {
        return snapGeo
      }

      return BuildSimplifiedBufferedGeo(snapGeo, (level + 1) * -1 * interiorOffsetInMeters)
    })

    const lineStrings = bufferedSnapGeos.map((geo) => {
      if (GeoUtil.GeoJson.isLineString(geo)) {
        return geo
      }

      return GeoUtil.GeoJson.polygonToLine(geo)
    })

    const pointsFeatureCollection = GeoUtil.GeoJson.featureCollection(
      lineStrings.reduce((accum, ls) => {
        return accum.concat(GeoUtil.GeoJson.getCoords(ls).map(GeoUtil.Coords.toPoint))
      }, [])
    )

    return { lineStrings, pointsFeatureCollection }
  }

  const interiorDataForLevel = {
    0: buildInteriorDataForLevel(0)
  }

  return (latLng, level = 0) => {
    if (!(level in interiorDataForLevel)) {
      interiorDataForLevel[level] = buildInteriorDataForLevel(level)
    }

    const interiorData = interiorDataForLevel[level]

    const geoPoint = GeoUtil.LatLngs.toGeoJsonPoint(latLng)
    const coord = GeoUtil.LatLngs.toCoord(latLng)

    const snapBecauseOutsideBounds =
      options.OutsideBoundsAlwaysSnaps &&
      !snapGeos.some((geo) => 
        GeoUtil.GeoJson.isPolygon(geo) &&
        GeoUtil.GeoJson.booleanPointInPolygon(coord, geo))

    // Gravitate a bit more aggressively to the vertices themselves
    if (options.EnablePointGravity) {
      const nearestInteriorPoint = GeoUtil.GeoJson.nearestPoint(
        coord,
        interiorData.pointsFeatureCollection
      )
      const distanceInMeters = GeoUtil.Coords.distance(
        GeoUtil.GeoJson.getCoord(nearestInteriorPoint),
        coord,
        { units: 'meters' }
      )

      if (distanceInMeters < snapThresholdInMeters / 2) {
        return GeoUtil.GeoJson.toLatLngs(nearestInteriorPoint)
      }
    }

    let snappedGeoPoints = interiorData.lineStrings.reduce((accum, ls) => {
      let nearestPointOnInterior = GeoUtil.GeoJson.nearestPointOnLineString(ls, geoPoint, {
        units: 'meters'
      })

      if (
        snapBecauseOutsideBounds ||
        snapThresholdInMeters == null ||
        nearestPointOnInterior.properties.dist < snapThresholdInMeters
      ) {
        accum.push(nearestPointOnInterior)
        return accum
      }

      return accum
    }, [])

    if (snappedGeoPoints.length === 0) {
      return latLng
    }

    snappedGeoPoints.sort((a, b) => a.properties.dist - b.properties.dist)

    const closestSnappedGeoPoint = snappedGeoPoints[0]

    return GeoUtil.GeoJson.toLatLngs(closestSnappedGeoPoint)
  }
}

function BuildSnapAndFillCallback(
  geos,
  latLngsWithElevation,
  offsetPerLevelInMeters,
  MaximumExternalDataPointDistanceInFeet = 50
) {
  const buildLineStringsForLevel = (level) => {
    return geos.map((geo) => {
      if (GeoUtil.GeoJson.isLineString(geo)) {
        return geo
      }

      const newGeo = BuildSimplifiedBufferedGeo(geo, (level + 1) * -1 * offsetPerLevelInMeters)

      return GeoUtil.GeoJson.polygonToLine(newGeo)
    })
  }

  const lineStringsForLevels = {
    0: buildLineStringsForLevel(0)
  }

  return (ll0, ll1, level = 0, applyElevation=true) => {
    if (level > 5) {
      level = 5 // establish a hard limit
    }
    if (level < 0) {
      level = 0
    }

    if (!(level.toString() in lineStringsForLevels)) {
      lineStringsForLevels[level.toString()] = buildLineStringsForLevel(level)
    }

    const lineStrings = lineStringsForLevels[level.toString()]

    const ret = BuildLatLngsSnappedToLineStrings(lineStrings, ll0, ll1)

    if (applyElevation && latLngsWithElevation?.length) {
      ApplyElevationToLatLngs(ret, latLngsWithElevation, MaximumExternalDataPointDistanceInFeet)
    }

    return ret
  }
}

function BuildLatLngsSnappedToLineStrings(lineStrings, ll0, ll1) {
  if (!ll0) {
    return [ll1]
  }

  if (!ll1) {
    return [ll0]
  }

  let p0 = GeoUtil.LatLngs.toGeoJsonPoint(ll0)
  let p1 = GeoUtil.LatLngs.toGeoJsonPoint(ll1)

  const closestLineString = lineStrings.reduce(
    (accum, ls) => {
      const distance = GeoUtil.GeoJson.pointToLineDistance(p1, ls, {method: 'planar'})
      if (!accum?.closest) {
        return { closest: ls, distance }
      }

      if (distance < accum.distance) {
        return { closest: ls, distance }
      }

      return accum
    },
    { closest: null, distance: Number.MAX_SAFE_INTEGER }
  )
  const lineString = closestLineString.closest

  if (
    !(
      GeoUtil.GeoJson.booleanPointOnLine(p0, lineString) &&
      GeoUtil.GeoJson.booleanPointOnLine(p1, lineString)
    )
  ) {
    return [ll0, ll1]
  }

  let lineLatLngs = GeoUtil.GeoJson.toLatLngs(lineString)

  const linePointDistancesFromStart = lineLatLngs.map((ll, i) => {
    if (i === lineLatLngs.length - 1) {
      return GeoUtil.GeoJson.length(lineString)
    }

    const p = GeoUtil.LatLngs.toGeoJsonPoint(ll)
    return GeoUtil.GeoJson.pointDistanceFromStartOfLine(p, lineString)
  })

  let p0Dist = GeoUtil.GeoJson.pointDistanceFromStartOfLine(p0, lineString)
  let p1Dist = GeoUtil.GeoJson.pointDistanceFromStartOfLine(p1, lineString)

  // need to swap here to be able to draw pipes counter clockwise
  const swapPoints = p0Dist > p1Dist
  if (swapPoints) {
    let tmp = ll0
    ll0 = ll1
    ll1 = tmp

    tmp = p0Dist
    p0Dist = p1Dist
    p1Dist = tmp

    tmp = p0
    p0 = p1
    p1 = tmp
  }

  //splice the two given points into lineLatLngs and establish p0Index and p1Index
  let p0Index = -1
  let p1Index = -1
  {
    for (let i = 0; i < linePointDistancesFromStart.length; i++) {
      if (linePointDistancesFromStart[i] >= p0Dist) {
        lineLatLngs.splice(i, 0, ll0)
        linePointDistancesFromStart.splice(i, 0, p0Dist) // distances need to be updated with this new point
        p0Index = i
        break
      }
    }

    for (let i = 0; i < linePointDistancesFromStart.length; i++) {
      if (linePointDistancesFromStart[i] >= p1Dist) {
        lineLatLngs.splice(i, 0, ll1)
        p1Index = i
        break
      }
    }
  }

  // They are essentially the same point -- no interpolation possible. Punt.
  if (Math.abs(p0Index - p1Index) <= 1) {
    return swapPoints ? [ll1, ll0] : [ll0, ll1]
  }

  // create two lines using the two points
  let line0LatLngs = lineLatLngs.slice(p0Index, p1Index + 1)
  let line1LatLngs = lineLatLngs.slice(p1Index)
  line1LatLngs.push(...lineLatLngs.slice(0, p0Index + 1))

  // the shorter of the two lines will be returned
  const line0Ls = GeoUtil.LatLngs.toLineString(line0LatLngs)
  const line1Ls = GeoUtil.LatLngs.toLineString(line1LatLngs)
  const line0Distance = GeoUtil.GeoJson.length(line0Ls, { units: 'kilometers' })
  const line1Distance = GeoUtil.GeoJson.length(line1Ls, { units: 'kilometers' })
  const chooseLine0 = line0Distance <= line1Distance

  const cleanup = (ls) => {
    if (ls.geometry.coordinates.length <= 3) {
      return
    }

    GeoUtil.GeoJson.truncate(ls, { mutate: true })
    GeoUtil.GeoJson.cleanCoords(ls, { mutate: true })
  }

  if (chooseLine0) {
    cleanup(line0Ls)

    if (swapPoints) {
      return GeoUtil.GeoJson.toLatLngs(line0Ls).reverse()
    } else {
      return GeoUtil.GeoJson.toLatLngs(line0Ls)
    }
  } else {
    cleanup(line1Ls)

    if (swapPoints) {
      return GeoUtil.GeoJson.toLatLngs(line1Ls)
    } else {
      return GeoUtil.GeoJson.toLatLngs(line1Ls).reverse()
    }
  }
}

// TODO: maybe do this as a plugin to get all the prefs?
function BuildSnapCallback(
  snapGeos,
  latLngsWithElevation,
  snapThresholdInMeters,
  offsetFromFieldInterior,
  MaximumExternalDataPointDistanceInFeet = 50
) {
  const useElevation = latLngsWithElevation?.length >= 2

  if (useElevation) {
    const ls = GeoUtil.LatLngs.toLineString(latLngsWithElevation)
    snapGeos.push(ls)
  }

  const snapCallback = BuildLatLngSnapCallback(
    snapThresholdInMeters,
    offsetFromFieldInterior,
    snapGeos
  )

  if (!useElevation) {
    return snapCallback
  }

  return (latLng, level = 0) => {
    const ret = snapCallback(latLng, level)

    ApplyElevationToLatLngs([ret], latLngsWithElevation, MaximumExternalDataPointDistanceInFeet)

    if (Number.isFinite(ret.elevationInFeet)) {
      ret.interpolated = false
      // console.log("! APPLIED THAT LELVATION: " + ret.elevationInFeet)
    }

    return ret
  }
}

export {
  BuildLatLngSnapCallback,
  BuildLatLngsSnappedToLineStrings,
  BuildSnapCallback,
  BuildSnapAndFillCallback,
  ApplyElevationToLatLngs
}

import { reactive } from 'vue'

import Vars from '@/Vars'
import { FieldMode } from '@/store/Dto'
import { GeoUtil } from '@/geo/GeoUtil'

const MaxStaticUrlLength = 8192

const LineColor = '0xcbff00ff'

function buildInstance(options) {

    return reactive({
        baseUrl: options.baseUrl,
        apiKey: options.apiKey,
        encodeUrlParamsData(params) {
            return Object.keys(params).map(function (key) {
                const value = params[key]

                if (value instanceof Array) {
                    return value.map((encodedValue) => key + '=' + encodedValue).join('&')
                }
                else {
                    return [key, params[key]].join('=');
                }
            }).join('&');
        },

        urlCoord(coord) {
            return coord[1].toFixed(6) + ',' + coord[0].toFixed(6)
        },

        buildPolyPathParam(latLngs, strokeColor, strokeWeight, fillColor) {
            if ((!latLngs) || (latLngs.length === 0)) {
                return ''
            }

            if(latLngs.length > 16) {
                latLngs = GeoUtil.LatLngs.simplify(latLngs, {tolerance: 0.0001})
            }

            let coordsString = latLngs.map((ll) => {
                return this.urlCoord([ll.lng, ll.lat])
            }).join('|')

            const first = latLngs[0]
            coordsString += '|' + this.urlCoord([first.lng, first.lat])

            return 'color:' + strokeColor
                + '|weight:' + strokeWeight
                + '|fillcolor:' + this.formatColor(fillColor)
                + '|' + coordsString
        },

        buildLinePathParam(latLngs, strokeColor, strokeWeight, fillColor) {
            if ((!latLngs) || (latLngs.length === 0)) {
                return ''
            }

            let coordsString = latLngs.map((ll) => {
                return this.urlCoord([ll.lng, ll.lat])
            }).join('|')

            return 'color:' + strokeColor
                + '|weight:' + strokeWeight
                + '|fillcolor:' + this.formatColor(fillColor)
                + '|' + coordsString
        },

        buildMarkerParam({ color, location }) {
            return 'color:' + color
                + '|size:mid'
                + '|' + this.urlCoord([location.lng, location.lat])
        },

        buildFurrowDirectionPath(layout) {
            if(! Number.isFinite(layout.furrowBearing)) {
                console.warn('Layout w/No Furrow Bearing: ' + layout.id)
                return ''
            }

            if((layout?.path?.length || 0) < 4) {
                console.warn('Layout w/No Path: ' + layout.id)
                return ''
            }

            let fieldGeo = GeoUtil.LatLngs.toPolygon(layout.path)
            let fieldLs = GeoUtil.LatLngs.toLineString(layout.path)
            let fieldCenterCoord = () => {
                const fieldCenterGeo = GeoUtil.GeoJson.centerOfMass(fieldGeo)
                return GeoUtil.GeoJson.getCoord(fieldCenterGeo)
            }

            let arrowLength = GeoUtil.GeoJson.length(fieldLs, { units: 'kilometers' }) * 0.1

            let bearingPoint = GeoUtil.Coords.destination(fieldCenterCoord(), arrowLength, layout.furrowBearing)
            let bearingCoord = GeoUtil.GeoJson.getCoord(bearingPoint)
            let mainPath = [fieldCenterCoord(), bearingCoord].map(GeoUtil.Coords.toLatLng) // this is the main arrow line

            let getArrowPath = (bearing) => {
                let reversedBearing = GeoUtil.bearingReversed(layout.furrowBearing)
                let endOfMainPathCoord = GeoUtil.LatLngs.toCoord(mainPath[mainPath.length - 1])
                let bearingOffsetLeft = GeoUtil.bearingOffset(reversedBearing, bearing)
                let offsetLeftBearingPoint = GeoUtil.Coords.destination(endOfMainPathCoord, 20, bearingOffsetLeft, { units: 'meters' })
                let offsetLeftBearingCoord = GeoUtil.GeoJson.getCoord(offsetLeftBearingPoint)
                let arrowPath = [endOfMainPathCoord, offsetLeftBearingCoord].map(GeoUtil.Coords.toLatLng) // this is either side of the arrow tip

                return arrowPath.map((ll) => {
                    return this.urlCoord([ll.lng, ll.lat])
                }).join('|')
            }

            let mainPathCoordsString = mainPath.map((ll) => {
                return this.urlCoord([ll.lng, ll.lat])
            }).join('|')

            return 'color:' + LineColor
                + '|weight:' + Vars.SelectedFieldStrokeWeight
                + '|fillcolor:' + Vars.SelectedFieldFillColor
                + '|' + mainPathCoordsString
                + '|' + getArrowPath(-225)
                + '|' + getArrowPath(225)
        },

        formatColor(s) {
            if (s[0] === '#') {
                return '0x' + s.substring(1)
            }

            return s
        },

        buildLineStringPathParam(latLngs, strokeColor, strokeWeight) {
            let coordsString = latLngs.map((ll) => {
                return this.urlCoord([ll.lng, ll.lat])
            }).join('|')

            return 'color:' + strokeColor
                + '|weight:' + strokeWeight
                + '|' + coordsString
        },

        buildPipePathParam(latLngs) {
            const pathParam = (lls) =>
                this.buildLineStringPathParam(lls, '0xFFFFFFFF', 5)
                + '&path=' + this.buildLineStringPathParam(lls, '0x599eb6FF', 3)

            const traverse = (lls) => {
                let ret = pathParam(lls)

                lls.forEach((ll) => {
                    if (ll.paths) {
                        ll.paths.forEach((junctionPath) => {
                            ret += '&path=' + traverse(junctionPath)
                        })
                    }
                })

                return ret
            }

            return traverse(latLngs)
        },

        //dont' mess with this one brandon
        buildFarmUrl(farm) {
            const baseParams = {
                key: this.apiKey,
                size: '192x192',
                scale: 1,
                format: 'jpg',
                maptype: 'hybrid',
            }

            let ret = this.baseUrl + this.encodeUrlParamsData(baseParams)

            farm.fields.forEach((field) => {
                const layout = field.layouts.find((layout) => layout.id == field.activeLayoutId)
                if (!layout) {
                    return
                }

                if (ret.length > (MaxStaticUrlLength - 192)) {
                    return
                }

                ret += '&path=' + this.buildPolyPathParam(layout.path,
                    LineColor, Vars.SelectedFieldStrokeWeight,
                    Vars.SelectedFieldFillColor)
            })

            return ret
        },

        buildFieldUrl(layout, options = {}) {
            return this.buildFieldPathUrl(layout.path, options)
        },

        buildFieldPathUrl(path, options = {}) {
            const baseParams = Object.assign({
                key: this.apiKey,
                size: '192x192',
                scale: 1,
                format: 'jpg',
                maptype: 'hybrid',
                zoom: 14,
                path: this.buildPolyPathParam(path,
                    LineColor, Vars.SelectedFieldStrokeWeight,
                    Vars.SelectedFieldFillColor)
            }, options)

            return this.baseUrl + this.encodeUrlParamsData(baseParams)
        },

        buildGeoPipePathPathUrl(geoPath, pipePathPath, params = {}) {
            const fieldColor = LineColor

            const googlePath = [
                this.buildPolyPathParam(geoPath,
                    fieldColor, Vars.SelectedFieldStrokeWeight,
                    Vars.SelectedFieldFillColor)
            ]

            const baseParams = Object.assign({
                size: '360x360',
                scale: 1,
                zoom: 14,
                format: 'jpg',
                maptype: 'hybrid',
                key: this.apiKey,
                path: googlePath
            }, params)

            let ret = this.baseUrl + this.encodeUrlParamsData(baseParams)

            ret += '&path=' + this.buildPipePathParam(pipePathPath)

            return ret
        },

        buildFieldPipePathUrl(layout, pipePath, params = {}) {
            const hasFurrowSet = layout.mode == FieldMode.Furrows
                && layout.furrowSetDetails
                && layout.furrowSetDetails.resultingFurrowSets
                && layout.furrowSetDetails.resultingFurrowSets.length > 0
            const furrowSet = hasFurrowSet ?
                layout.furrowSetDetails.resultingFurrowSets.find(
                    (furrowSet) => furrowSet.id === pipePath.furrowSetId) : null

            const fieldColor = furrowSet != null ?
                '0xb9af67FF' : LineColor

            const path = [
                this.buildPolyPathParam(layout.path,
                    fieldColor, Vars.SelectedFieldStrokeWeight,
                    Vars.SelectedFieldFillColor)
            ]

            if (layout.mode == FieldMode.Levees) {
                if(layout.leveePaths.length < 5) {
                    layout.leveePaths.forEach((leveePath) => {
                        path.push(this.buildLinePathParam(
                            leveePath, LineColor, Vars.SelectedFieldStrokeWeight,
                            Vars.SelectedFieldFillColor
                        ))
                    })
                }
                else {
                    const limit = 17
                    const mod = Math.round(layout.leveePaths.length / limit)
                    layout.leveePaths.forEach((leveePath, i) => {
                        if((i % mod) === 0) {
                            const simplified = GeoUtil.LatLngs.simplify(leveePath, {tolerance: 0.0001})
                            path.push(this.buildLinePathParam(
                                simplified, LineColor, Vars.SelectedFieldStrokeWeight,
                                Vars.SelectedFieldFillColor
                            ))
                        }
                    })
                }
            }

            if (layout.mode == FieldMode.Furrows) {
                let fieldOrFurrowpath = furrowSet ? furrowSet.path : layout.path

                path.push(this.buildFurrowDirectionPath(layout), this.buildPolyPathParam(
                    fieldOrFurrowpath, LineColor, Vars.SelectedFieldStrokeWeight,
                    Vars.SelectedFieldFillColor
                ))
            }

            const baseParams = Object.assign({
                size: params.size || '360x360',
                scale: 1,
                format: 'jpg',
                maptype: 'hybrid',
                key: this.apiKey,
                path,
                markers: this.buildMarkerParam({
                    color: '0x599eb6FF',
                    location: pipePath.path[0]
                })
            }, params)

            let ret = this.baseUrl + this.encodeUrlParamsData(baseParams)

            ret += '&path=' + this.buildPipePathParam(pipePath.path)

            return ret
        },

        buildFieldAndWaterSourceUrl(fieldLayout, waterSourceLatLng) {
            const path = [
                this.buildPolyPathParam(fieldLayout.path,
                    LineColor, Vars.SelectedFieldStrokeWeight,
                    Vars.SelectedFieldFillColor)
            ]

            const baseParams = {
                key: this.apiKey,
                size: '192x192',
                scale: 1,
                format: 'jpg',
                maptype: 'hybrid',
                path,
                markers: this.buildMarkerParam({
                    color: '0x599eb6FF',
                    background: '0xFFFFFFFF',
                    location: waterSourceLatLng
                })
            }

            return this.baseUrl + this.encodeUrlParamsData(baseParams)
        }
    })
}

export const StaticImagesPlugin = {
    install(app, options) {
        app.config.globalProperties.$staticImages = buildInstance(options)
    }
}

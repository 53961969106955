<template>
    <div ref="topDiv" class="hidden">
        <pp4-polyline
        v-if="(measurePath != null) && (measurePath.length === 2)"
        :path="measurePath" 
        strokeColor="#228B22"
        :zIndex="ZIndexes.MeasureTool"/>

        <pp4-label v-if="measureToolLengthLabel"
            :labelText="measureToolLengthLabel"
            :latLng="lengthLabelLatLng"
            elementClass="selected_pipe_path_label"/>

        <map-controls position="RIGHT_BOTTOM">
             <toggle-button
                description="Toggle Measure Mode" 
                googleStyle="true"
                :active="measureModeActive" 
                @click="toggleMeasureMode()">
                <img :src="MeasureToolIcon" :draggable="false"></toggle-button>
        </map-controls>
    </div>
</template>

<script>
import { LatLng } from '@/store/Dto'
import { ZIndexes } from '@/maps/ZIndexes'

import Pp4MapObject from '@/components/maps/Pp4MapObject'
import MapControls from '@/components/maps/MapControls'
import Pp4Label from '@/components/maps/Label'
import Pp4Polyline from '@/components/maps/Polyline'
import { GeoUtil } from '@/geo/GeoUtil'

import MeasureToolIcon from '@/assets/MeasureToolIcon.png'

export default {
    extends: Pp4MapObject,
    components: {
        Pp4Polyline,
        Pp4Label,
        MapControls
    },
    data: function() {
        return {
            ZIndexes, MeasureToolIcon,
            latLngs: [],
            cursorLatLng: null,
            active: false
        }
    },
    computed: {
        measureModeActive: (vm) => vm.showMeasureToolPath,
        showMeasureToolPath: (vm) => vm.$store.state.mapObjects.availableMapActions.AddMeasurementTool,
        measurePath: (vm) => {
            if(vm.latLngs.length === 2) {
                return vm.latLngs
            }
            if((vm.latLngs.length === 1) && vm.cursorLatLng) {
                return [vm.latLngs[0], vm.cursorLatLng]
            }

            return null
        },
        lengthLabelLatLng: (vm) => {
            if(!vm.measurePath)
                return null

            const lineString = GeoUtil.LatLngs.toLineString(vm.measurePath)

            const lengthInMeters = GeoUtil.GeoJson.length(lineString, {units: 'meters'})

            const ret = GeoUtil.GeoJson.along(lineString, lengthInMeters * 0.75, {units: 'meters'})

            return GeoUtil.GeoJson.toLatLngs(ret)
        },

        measureToolLengthLabel: (vm) => {
            if(!vm.measurePath)
                return null
            
            const feet = GeoUtil.LatLngs.length(vm.measurePath, {units: 'feet'})
            
            return Math.round(feet) + " ft"
        },
    },
    methods: {
        toggleMeasureMode() {
            this.active = ! this.active

            if(this.active) {
                this.$store.dispatch('enterMeasureToolMode')
                this.map.setOptions({
                    draggableCursor: 'crosshair',
                    disableDoubleClickZoom: true
                });
            }
            else {
                this.$store.dispatch('exitMeasureToolMode')
                this.map.setOptions({
                    draggableCursor: 'default',
                    disableDoubleClickZoom: true
                });
            }
        },
        mapReady() {
            // The measure tool appears way too fast and was driving Matt nuts.
            setTimeout(500, () => {
                this.$refs.topDiv.classList.remove('hidden')
            })

            this.addMapListener('click', (e) => {
                if(! this.active) {
                    return
                }

                let googleLatLng = e.latLng
                let latLng = new LatLng(googleLatLng.lat(), googleLatLng.lng())

                if(this.latLngs.length === 2) {
                    this.latLngs.splice(0, 2, latLng)
                }
                else {
                    this.latLngs.push(latLng)
                }
            });

          this.addMapListener('mousemove', (e) => {
            if(! this.active) {
                return
            }
            
            let googleLatLng = e.latLng
            let latLng = new LatLng(googleLatLng.lat(), googleLatLng.lng())
            this.cursorLatLng = latLng
          })
        }
    }
}
</script>

<style lang="css" scoped>
</style>

<template>
    <div id="pipe_path_menu">

        <icon-button description="Set pipe specific properties" label="Pipe Settings"
            v-on:click="openPipeSettingsDialog()"><img :src="PipeSettingsIcon" :draggable="false"></icon-button>

        <icon-button description="Edit Pipe Points" label="Edit Points" class="button_margin_top"
            @click="enterEditPipePathPointsMode()"><img :src="EditIcon" :draggable="false"></icon-button>

        <toggle-button v-if="selectedFieldLayout && (selectedFieldLayout.mode === FieldMode.Furrows)"
            description="Water from both sides of this pipe" label="Water Both Sides" class="button_margin_top"
            :active="waterBothSides" @click="toggleWaterBothSides">
            <img :src="WaterBothSidesIcon" :draggable="false">
        </toggle-button>

        <toggle-button v-if="selectedFieldLayout && (selectedFieldLayout.mode === FieldMode.Furrows)"
            description="Water other side of the pipe (against chosen furrow direction)" label="Water Other Side" class="button_margin_top"
            :active="waterOtherSide" @click="toggleWaterOtherSide">
            <img :src="WaterOtherSideIcon" :draggable="false">
        </toggle-button>

        <icon-button class="button_group_margin_top" description="Remove Pipe & Associated Design" label="Remove Pipe"
            faIcon="fa-minus-circle" faColorClass="cancel_red" @click="removePipePath" />
    </div>
</template>

<script>
import RemovePipePathDialog from './RemovePipePathDialog'
import PipeSettingsDialog from './PipeSettingsDialog'
import { FieldMode } from '@/store/Dto'

import EditIcon from '@/assets/edit_line_points_icon.svg'
import HeadIcon from '@/assets/head_icon.svg'
import AddInlineTeeIcon from '@/assets/add_inline_tee_icon.png'
import WaterBothSidesIcon from '@/assets/water_both_sides_icon.svg'
import WaterOtherSideIcon from '@/assets/water_other_side_icon.svg'
import PipeSettingsIcon from '@/assets/settings-gears.svg'

export default {
    components: {
    },
    data: function () {
        return {
            EditIcon, HeadIcon, AddInlineTeeIcon,
            WaterOtherSideIcon,
            WaterBothSidesIcon, PipeSettingsIcon, FieldMode,
            editFlowRateActive: false, editPressureLimitsActive: false,
            waterBothSides: false, waterOtherSide: false
        }
    },
    computed: {
        selectedField: (vm) => vm.$store.state.selectedField,
        selectedFieldLayout: (vm) => vm.$store.state.selectedFieldLayout,
        selectedIrrigationSystemPipePath: (vm) => vm.$store.state.selectedIrrigationSystemPipePath,
    },
    watch: {
        'selectedIrrigationSystemPipePath': 'initData'
    },
    mounted() {
        this.initData()
    },
    methods: {
        initData() {
            let pipePath = this.selectedIrrigationSystemPipePath
            if (!pipePath) {
                return
            }

            this.waterBothSides = pipePath.waterBothSides
            this.waterOtherSide = pipePath.waterOtherSide ? true : false
        },
        async removePipePath() {
            const pipePath = this.$store.state.selectedIrrigationSystemPipePath

            await this.$dialog.push(RemovePipePathDialog, { pipePath })
        },
        enterEditPipePathPointsMode() {
            this.$store.dispatch('enterEditPipePathPointsMode')
        },
        async openPipeSettingsDialog() {
            const farm = this.$store.state.selectedFarm
            const field = this.$store.state.selectedField
            const fieldLayout = this.$store.state.selectedFieldLayout
            const irrigationSystem = this.$store.state.selectedIrrigationSystem
            const pipePath = this.$store.state.selectedIrrigationSystemPipePath
            if (irrigationSystem == null || pipePath == null) {
                return
            }

            await this.$dialog.push(PipeSettingsDialog, {
                farm, field, fieldLayout,
                irrigationSystem,
                pipePath
            })
        },
        toggleWaterBothSides() {
            this.waterBothSides = !this.waterBothSides
            this.waterOtherSide = false

            const patches = [
                {
                    op: Object.hasOwn(this.$store.state.selectedIrrigationSystemPipePath, 'waterBothSides') ? 'replace' : 'add',
                    path: '/waterBothSides',
                    value: this.waterBothSides
                },
                {
                    op: Object.hasOwn(this.$store.state.selectedIrrigationSystemPipePath, 'waterOtherSide') ? 'replace' : 'add',
                    path: '/waterOtherSide',
                    value: false
                }
            ]

            this.$store.dispatch('patchSelectedIrrigationSystemPipePath', patches)
        },
        async toggleWaterOtherSide() {
            this.waterBothSides = false
            this.waterOtherSide = !this.waterOtherSide

            const patches = [
                {
                    op: Object.hasOwn(this.$store.state.selectedIrrigationSystemPipePath, 'waterBothSides') ? 'replace' : 'add',
                    path: '/waterBothSides',
                    value: false
                },
                {
                    op: Object.hasOwn(this.$store.state.selectedIrrigationSystemPipePath, 'waterOtherSide') ? 'replace' : 'add',
                    path: '/waterOtherSide',
                    value: this.waterOtherSide
                }
            ]

            await this.$store.dispatch('patchSelectedIrrigationSystemPipePath', patches)
        }
    }
}
</script>

<style scoped lang="css">
#flow_rate_widget {
    display: flex;
    flex-direction: row;
    align-items: center;
}

#flow_rate {
    margin-right: 5px;
    width: 5em;
    text-align: center;
}

#suggested_flow_rates {
    display: inline-block;
}

#pressure_limits_widget {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.pressure_limit_label {
    width: 2em;
}

#min_head_override {
    width: 3em;
    text-align: center;
    margin-right: 5px;
}

#max_head_override {
    width: 3em;
    text-align: center;
    margin-right: 5px;
}

.invalid_head {
    font-weight: bold;
    color: var(--error-red);
}

.invalid_head::selection {
    font-weight: bold;
    color: var(--error-red);
}
</style>

<template>
    <fullpageform @cancel="cancel" :title="props.title" :description="props.description" :backgroundClickCancels="true">
        <div class="jd-guidance-lines-import-top-div">
            <loading :absolute="true" v-if="swrv.isValidating.value" />

            <div class="jd-guidance-line-top-sticky">
                <div class="jd-guidance-line-filters">
                    <button class="jd mr-1" @click="refresh" :disabled="swrv.isValidating.value">
                        <i class="fa fa-refresh"></i>
                    </button>

                    <button class="jd mr-3" @click="selectAll">Select All</button>

                    <JdFilter label="Type" :options="TypeOptions" v-model="data.type" class="mr-1" />

                    <input type="checkbox" class="jd" v-model="data.withinField" id="within-field-filter"><label
                        for="within-field-filter">Within Field</label>

                    <input placeholder="Name..." class="ml-3 " type="text" id="jd-guidance-line-name"
                        v-model="data.name" size="30" />
                </div>

                <div class="jd-guidance-line-row">
                    <div class="jd-guidance-line-title">Image</div>
                    <div class="jd-guidance-line-title" @click="toggleSort($event, 'name')">Name</div>
                    <div class="jd-guidance-line-title" @click="toggleSort($event, 'type')">Type</div>
                    <div class="jd-guidance-line-title" @click="toggleSort($event, 'lengthInMeters')">Length</div>
                </div>
            </div>

            <template v-if="swrv.error.value">
                Data Fetch Error: {{ swrv.error.value }}
            </template>

            <template v-for="line in data.lines" :key="line.key">
                <div v-if="line.filtered" :class="`jd-guidance-line-row${line.selected ? ' selected' : ''}`"
                    @click="toggleSelectedOrEmit(line)">
                    <div>
                        <pp4-image :width="96" :height="96" :src="line.img" />
                    </div>
                    <div class="jd-guidance-line-name">
                        <input type="checkbox" v-model="line.selected" class="jd mr-1" v-if="!props.single" />
                        {{ line.name }}
                    </div>
                    <div class="jd-guidance-line-type">{{ line.type }}</div>
                    <div class="jd-guidance-line-length">{{ line.lengthInMeters }} m.</div>
                </div>
            </template>
        </div>

        <template #action-buttons>
            <button v-if="!props.single" class="jd " :disabled="(!selectedLines.length) ? true : false"
                @click="done">Done ({{
        selectedLines.length
                }})</button>
            <button class="jd ml-1" @click="cancel">Cancel</button>
        </template>
    </fullpageform>
</template>

<script setup>
import { reactive, watch, inject, computed } from 'vue';
import useSWRV from 'swrv'

import JdFilter from '@/components/jd/JdFilter'
import * as JdUtil from '@/util/JdUtil'
import { GeoUtil } from '@/geo/GeoUtil'

const props = defineProps({
    organizationId: {
        type: String,
        required: true
    },
    fieldId: {
        type: String,
        required: true
    },
    boundsGeo: {
        type: Object,
        required: true
    },
    title: {
        type: String,
        required: false,
        default: 'Import Guidance Lines'
    },
    single: {
        type: Boolean,
        required: false,
        default: false
    },
    type: {
        type: String,
        required: false,
        default: null
    }
})

const emit = defineEmits(['done', 'cancel'])

const $api = inject("$api")
const $staticImages = inject("$staticImages")

const data = reactive({
    lines: [],
    withinField: true,
    type: props.type,
    name: '',
    sorts: [] // [{prop, ascending}, ...]
})

const TypeOptions = [
    { id: null, name: 'All' },
    { id: 'ABLine', name: 'ABLine' },
    { id: 'AdaptiveCurve', name: 'AdaptiveCurve' },
    { id: 'ABCurve', name: 'ABCurve' }
]

const swrv = useSWRV(`/api/jd/organizations/${props.organizationId}/fields/${props.fieldId}/guidance-lines`, async (...params) => {
    const result = await $api.fetch(params[0], { method: "GET" })

    const rawJson = await result.json()

    return JdUtil.guidanceLinesToPaths(rawJson)
}, { defaultValue: null, revalidateOnFocus: false })

watch(swrv.data, () => {
    const lines = swrv.data.value

    if (!lines?.length) {
        data.lines = []
        return
    }

    const geoPath = GeoUtil.GeoJson.toLatLngs(props.boundsGeo)

    lines.forEach(line => {
        line.lengthInMeters = Math.round(GeoUtil.LatLngs.length(line, { units: 'meters' }))
        line.key = line.id
        line.lineString = GeoUtil.LatLngs.toLineString(line)
        line.withinField = GeoUtil.GeoJson.booleanWithin(line.lineString, props.boundsGeo)
            || GeoUtil.GeoJson.booleanCrosses(line.lineString, props.boundsGeo)
        line.filtered = true
        line.selected = false
        line.img = $staticImages.buildGeoPipePathPathUrl(geoPath, line, {size: "96x96"})
    })

    data.lines = lines
}, { immediate: true })

const selectedLines = computed(() => {
    return data.lines.filter(l => l.selected ? true : false)
})

watch(() => [data.lines, data.withinField, data.type, data.name], () => {
    const nameLower = data.name?.toLowerCase() || ''

    data.lines.forEach(l => {
        l.filtered = true

        if (data.withinField) {
            if (!l.withinField) {
                l.selected = false
                l.filtered = false
            }
        }

        if (data.type?.length) {
            if (l.type !== data.type) {
                l.selected = false
                l.filtered = false
            }
        }

        if (nameLower.length) {
            if (!l.name.toLowerCase().includes(nameLower)) {
                l.selected = false
                l.filtered = false
            }
        }
    })

}, { immediate: true })

function done() {
    emit('done', selectedLines.value.slice())
}

function cancel() {
    emit('cancel')
}

function refresh() {
    swrv.mutate()
}

function selectAll() {
    if (!data.lines.length) {
        return
    }

    if (data.lines.some(l => l.selected)) {
        data.lines.forEach(l => {
            l.selected = false
        })
    }
    else {
        data.lines.forEach(l => {
            if (l.filtered) {
                l.selected = true
            }
        })
    }
}

function toggleSelectedOrEmit(line) {
    line.selected = !line.selected

    if (props.single) {
        emit('done', line)
    }
}

function sortLines() {
    const sorts = data.sorts
    data.lines.sort((a, b) => {
        for (let s of sorts) {
            const aVal = a[s.prop]
            const bVal = b[s.prop]
            const ret = ((typeof aVal) === 'string') ?
                aVal.localeCompare(bVal) : (aVal - bVal)
            if (ret !== 0) {
                return s.ascending ? ret : (ret * -1)
            }
        }

        return 0
    })
}

function toggleSort(e, prop) {
    const existingSort = data.sorts.find(s => s.prop === prop)
    if (existingSort) {

        if (e.shiftKey) {
            existingSort.ascending = !existingSort.ascending
            data.sorts = data.sorts.slice()
        }
        else {
            data.sorts = [
                { prop, ascending: !existingSort.ascending }
            ]
        }
    }
    else {
        data.sorts.push({
            prop, ascending: true
        })
    }

    sortLines()
}
</script>

<style lang="css" scoped>
.jd-guidance-lines-import-top-div {
    max-width: 90vw;
    height: 80vh;
    max-height: 90vh;
    background-color: var(--bg0);

    display: flex;
    flex-direction: column;
    row-gap: var(--general-padding);

    overflow: scroll;

    position: relative;
}

.jd-guidance-line-top-sticky {
    position: sticky;
    top: 0px;
    opacity: 1.0;
    background-color: var(--bg0);
    z-index: 1;
}

.jd-guidance-line-filters {
    display: flex;
    white-space: nowrap;
    flex-direction: row;
    align-items: center;
    justify-self: center;
    justify-content: center;
    padding: var(--general-padding);
    padding: var(--general-padding);
}

hr {
    border: 2px solid var(--jd-yellow);
    background-color: var(--jd-yellow);
}

.jd-guidance-line-row {
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    column-gap: var(--smaller-padding);
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.jd-guidance-line-title {
    text-align: center;
    font-weight: bold;
}

.jd-guidance-line-row.selected {
    text-decoration: underline;
}

.jd-guidance-line-name {
    font-weight: bold;
    color: var(--jd-yellow);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.jd-guidance-line-row div {
    padding: var(--general-padding);
}

.jd-guidance-line-type {
    text-align: center;
    font-style: italic;
}

.jd-guidance-line-length {
    text-align: right;
    font-style: italic;
}
</style>

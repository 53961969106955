<template>
    <div>
        <!-- ELEVATION LINEAR RANGE FORM -->
        <form @submit.stop.prevent="applyModeToWorkingPath" v-if="isFurrowField">
            <fieldset>
                <legend>Linear Elevation Range</legend>

                <div>
                    <input id="grade_mode" type="radio" name="elevation_mode" value="grade" v-model="mode">
                    <label for="grade_mode">Grade</label>

                    <input id="fall_mode" type="radio" name="elevation_mode" value="fall" v-model="mode"
                        class="widget_margin_left">
                    <label for="fall_mode">Fall</label>

                    <input id="rise_mode" type="radio" name="elevation_mode" value="rise" v-model="mode"
                        class="widget_margin_left">
                    <label for="rise_mode">Rise</label>

                    <input id="gps_mode" type="radio" name="elevation_mode" value="gps" v-model="mode"
                        class="widget_margin_left">
                    <label for="gps_mode">CSV</label>
                </div>

                <div class="widget_margin_top">
                    <input v-if="mode != 'gps'" name="amount" v-model="amount" type="number" step="0.1"
                        id="elevation_amount" autocomplete="off">
                    <template v-if="mode === 'grade'">
                        <label for="amount" class="widget_margin_left">(0.1 ft / 100 ft)</label>
                    </template>
                    <template v-else-if="mode === 'gps'">
                        <gps-data-select ref="gpsDataSelect" @change="elevationDataReceived" :latLngs="workingPathPoints">
                        </gps-data-select>
                    </template>
                    <template v-else>
                        <label for="amount" class="widget_margin_left">ft</label>
                    </template>
                    <br />
                    <button type="button" class="widget_margin_top" v-if="mode != 'gps'"
                        @click.prevent="applyModeToWorkingPathPoints" :disabled="amount == null">Apply</button>
                </div>
            </fieldset>
        </form>

        <!-- PATH POINTS -->
        <div>
            <form class="widget_margin_top" @submit.stop.prevent="submit"
                @reset.stop.prevent="initWorkingPathPointsFromPipePath">
                <fieldset>
                    <legend>Points</legend>

                    <div class="pipe_type_override_div">
                        <a href="#" @click.prevent="toggleShowOverrideAllPipeTypes">Override Pipe Type for All Segments</a>
                    </div>
                    <div v-if="overrideAllPipeTypesShown" class="pipe_type_override_div">
                        <label for="pipe_type_override_all">Type</label>
                        <pipe-type-select class="label_margin_left" v-model="selectedDefaultPipeType" />
                        <button type="button" class="button_margin_left"
                            @click.prevent="applySelectedDefaultPipeType">Apply</button>
                    </div>

                    <div class="elevation_table_div">
                        <table>
                            <thead>
                                <tr>
                                    <th title="Index of point on pipe path.">Point</th>
                                    <th title="Distance from start of pipe.">Distance&nbsp;(ft)</th>
                                    <th title="Pipe size and diameter.">Type</th>
                                    <th title="Difference in elevation from last point." v-if="isFurrowField">
                                        Elevation&nbsp;(ft)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(pp, ppi) in workingPathPoints" :key="pp.key">
                                    <template v-if="pp.junctionBranchLabel">
                                        <td class="junction_branch_label" colspan="4">{{ pp.junctionBranchLabel }}</td>
                                    </template>
                                    <template v-else>
                                        <td>{{ pp.index }}</td>
                                        <td>{{ pp.distanceFromStartInFeet.toFixed(1) }} ft.</td>
                                        <td class="pipe_type">
                                            <template v-if="ppi !== (workingPathPoints.length - 1)">
                                                <template v-if="suggestedPipeType || pp.workingLatLng.pipeType">
                                                    <pipe-type-component v-model="pp.workingLatLng.pipeType"
                                                        :suggestedValue="suggestedPipeType" />
                                                </template>
                                                <template v-else>N/A</template>
                                            </template>
                                            <template v-else>
                                                ---
                                            </template>
                                        </td>
                                        <td class="elevation_input_td" v-if="isFurrowField">
                                            <input name="elevation" class="elevation_input" type="number" step="0.1"
                                                autocomplete="off" @change="workingPathPointChanged(pp)"
                                                v-model.number="pp.workingLatLng.elevationInFeet">

                                            <!-- <input 
                                                name="elevation" 
                                                class="elevation_input" 
                                                type="number"
                                                step="0.1"
                                                autocomplete="off"
                                                v-model.number="pp.elevationInFeet"> -->
                                        </td>
                                    </template>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <input type="reset" value="Reset" title="Reset point value overrides to when form was opened."
                        class="widget_margin_top">
                    <button value="Clear" type="button" title="Clear point value overrides." @click="clearValues"
                        class="widget_margin_left widget_margin_top">Clear</button>
                </fieldset>
            </form>
        </div>
    </div>
</template>

<script>
import jsonpatch from 'json-patch'

import { GeoUtil } from '@/geo/GeoUtil'
import { Direction, FieldMode } from '@/store/Dto'
import PipePathAlg from '@/design/PipePathAlg'

import { PipeType, getSuggestedPipeDiameter } from '@pp4/design'

import PipeTypeSelect from '@/components/maps/irrigation_systems/PipeTypeSelect'
import PipeTypeComponent from '@/components/maps/irrigation_systems/PipeType'

function MakeGoodElevation(val) {
    if (val == null) {
        return null
    }

    const type = (typeof val)
    let ret = null
    if (type === 'string') {
        ret = Number.parseFloat(val)
    }
    else if (type === 'number') {
        ret = val
    }

    if (ret != null) {
        if (Number.isNaN(ret)) {
            ret = null
        }
        else if (!Number.isFinite(ret)) {
            ret = null
        }
    }

    return ret
}

export default {
    props: ['field', 'fieldLayout', 'pipePath', 'flowRateId', 'irrigationSystem'],
    components: {
        PipeTypeSelect, PipeTypeComponent
    },
    data: function () {
        return {
            mode: 'grade',
            amount: null,
            workingPathSrc: null,
            workingPathPoints: [],
            overrideAllPipeTypesShown: false,
            selectedDefaultPipeType: new PipeType(15, 10)
        }
    },
    computed: {
        isFurrowField: (vm) => vm.fieldLayout.mode === FieldMode.Furrows,
        suggestedPipeType: (vm) => {
            const flowRate = vm.irrigationSystem.flowRates.find(
                (fr) => fr.id === vm.flowRateId)
            if (!flowRate) {
                return null
            }

            const diameterInInches = getSuggestedPipeDiameter(flowRate.valueInGpm)
            const thicknessInMils = 10

            return new PipeType(diameterInInches, thicknessInMils)
        }
    },
    methods: {
        applySelectedDefaultPipeType() {
            this.workingPathPoints.forEach((pp) => {
                if (!pp.workingLatLng) {
                    return; // this is a junction label
                }

                pp.workingLatLng.pipeType = JSON.parse(JSON.stringify(this.selectedDefaultPipeType))
            })
        },
        toggleShowOverrideAllPipeTypes() {
            this.overrideAllPipeTypesShown = !this.overrideAllPipeTypesShown
        },
        workingPathPointChanged(pp) {
            // Yes, this is a lot of work, but the full path structure is needed
            // to apply the elevation interpretation alg from PipePathAlg.

            // I think it comes with the territory of representing a tree as a table,
            // and having to convert it back and forth.

            pp.workingLatLng.interpolated = false

            const patches = this.buildPatchesForWorkingPathSrc()

            const newPath = JSON.parse(JSON.stringify(this.workingPathSrc))
            const wrapperObject = { path: newPath }
            jsonpatch.apply(wrapperObject, patches)

            const needs = PipePathAlg.buildInterpolationNeeds(newPath)
            if (needs.length > 0) {
                // console.warn(needs) // TODO: maybe add to UI somehow?
                return
            }

            PipePathAlg.interpolateElevationInFeet(newPath)

            this.initWorkingPathPointsFromPath(newPath)
        },
        applyModeToWorkingPathPoints() {
            this.workingPathPoints.forEach((pp) => {
                if (!pp.workingLatLng) {
                    return // this is just a junction label
                }

                let diff = 0

                if (!pp.shouldHaveElevationLinearRangeApplied) {
                    pp.workingLatLng.elevationInFeet = 0
                    return
                }

                switch (this.mode) {
                    case 'rise':
                        diff = this.amount * pp.distanceFromStartPercent
                        break;
                    case 'fall':
                        diff = this.amount * pp.distanceFromStartPercent * -1
                        break;
                    case 'grade':
                        diff = this.amount * 0.1 / 100 * pp.distanceFromStartInFeet
                        break
                }

                pp.workingLatLng.elevationInFeet = Number.parseFloat(diff.toFixed(2))
            })


            this.workingPathPoints = this.workingPathPoints.slice()
        },
        clearValues() {
            this.workingPathPoints.forEach((pp) => {
                if (!pp.workingLatLng) {
                    return;
                }

                pp.workingLatLng.pipeType = null
                pp.workingLatLng.elevationInFeet = null
            })

            this.workingPathPoints = this.workingPathPoints.slice()
        },
        buildPatches() {
            if (!this.workingPathSrc) {
                return []
            }

            const finalPath = JSON.parse(JSON.stringify(this.workingPathSrc))
            const patches = this.buildPatchesForWorkingPathSrc()
            const wrapperObject = { path: finalPath }
            jsonpatch.apply(wrapperObject, patches)

            return [
                {
                    op: 'replace',
                    path: '/path',
                    value: finalPath
                }
            ]
        },
        buildPatchesForWorkingPathSrc() {
            const ret = []

            this.workingPathPoints.forEach((pp) => {
                if (pp.junctionBranchLabel) {
                    return
                }

                // always do elevation
                {
                    const op = ('elevationInFeet' in pp.originalLatLng) ?
                        'replace' : 'add'

                    let elevationInFeet = MakeGoodElevation(pp.workingLatLng.elevationInFeet)

                    ret.push({
                        op, path: pp.patchPath + '/elevationInFeet',
                        value: elevationInFeet
                    })
                }

                // interpolated always true for this mode?
                {
                    const op = ('interpolated' in pp.originalLatLng) ?
                        'replace' : 'add'

                    ret.push({
                        op, path: pp.patchPath + '/interpolated',
                        value: false
                    })
                }

                // pipeType
                {
                    const removed = ('pipeType' in pp.originalLatLng)
                        && (!('pipeType' in pp.workingLatLng))
                    if (removed) {
                        ret.push({
                            op: 'remove',
                            path: pp.patchPath + '/pipeType'
                        })
                    }
                    else {
                        const op = ('pipeType' in pp.originalLatLng) ?
                            'replace' : 'add'

                        ret.push({
                            op, path: pp.patchPath + '/pipeType',
                            value: JSON.parse(JSON.stringify(pp.workingLatLng.pipeType))
                        })
                    }
                }
            })

            return ret
        },
        elevationDataReceived(latLngsWithElevation) {
            const MaximumExternalDataPointDistanceInFeet =
                this.$store.state.preferences.pipe.maximumExternalDataPointDistanceInFeet
            let pointsApplied = 0

            for (let pathPoint of this.workingPathPoints) {
                let closestPoint = null;

                //Loop through each external GPS point to find the closest;
                for (const latLngEle of latLngsWithElevation) {
                    let distance = GeoUtil.LatLngs.distance(pathPoint, latLngEle, { units: 'feet' });
                    if ((!closestPoint) || (distance < closestPoint.distance)) {
                        closestPoint = { latLngEle: latLngEle, distance: distance }
                    }
                }

                //Check the point and if valid, apply the elevation
                if (closestPoint && closestPoint.distance <= MaximumExternalDataPointDistanceInFeet) {
                    pathPoint.workingLatLng.elevationInFeet =
                        Math.round((closestPoint.latLngEle.elevationInFeet + Number.EPSILON) * 100) / 100
                    pointsApplied += 1
                }
            }

            this.$refs.gpsDataSelect.setPointsStatus(
                pointsApplied, latLngsWithElevation.length)

            this.workingPathPoints = this.workingPathPoints.slice()
        },
        initWorkingPathPointsFromPipePath() {
            this.initWorkingPathPointsFromPath(this.pipePath.path)
        },
        initWorkingPathPointsFromPath(somePath) {
            this.amount = null
            this.selectedDefaultPipeType = new PipeType(12, 10)
            const traversePath = (path, patchPath) => {
                const pathEndsInJunction = PipePathAlg.isJunction(path[path.length - 1])

                let lineString = GeoUtil.LatLngs.toLineString(path)

                const ret = []

                let junctionIndex = 0
                for (let i = 0; i < path.length; i++) {
                    const p = path[i]

                    const pointGeoJson = GeoUtil.LatLngs.toGeoJsonPoint(p)
                    const distanceFromStartInFeet
                        = GeoUtil.GeoJson.pointDistanceFromStartOfLine(pointGeoJson, lineString, { units: 'feet' })
                    const lineLength = GeoUtil.GeoJson.length(lineString, { units: 'feet' })

                    let elevationInFeet = MakeGoodElevation(p.elevationInFeet)
                    if (Number.isFinite(p.elevationInFeet)) {
                        p.elevationInFeet.toFixed(2)
                    }

                    const pointPatchPipePath = patchPath + '/' + i.toString()
                    const workingLatLng = JSON.parse(JSON.stringify(p))
                    workingLatLng.pipeType = p.pipeType ? JSON.parse(JSON.stringify(p.pipeType)) : null
                    workingLatLng.interpolated = Number.isFinite(elevationInFeet)

                    ret.push({
                        patchPath: pointPatchPipePath,
                        originalLatLng: p,
                        lat: p.lat, lng: p.lng,
                        index: i + 1 - junctionIndex,
                        key: 'PathPoint:' + pointPatchPipePath,
                        workingLatLng,
                        hasElevation: elevationInFeet != null,
                        shouldHaveElevationLinearRangeApplied: !pathEndsInJunction,
                        distanceFromStartInFeet,
                        distanceFromStartPercent: (distanceFromStartInFeet / lineLength)
                    })

                    if (!PipePathAlg.isJunction(p)) {
                        continue
                    }

                    const junctionBranchLabelPrefix = PipePathAlg.junctionTypeLabel(p.type)

                    const prevSegmentBearing = i > 0 ?
                        GeoUtil.bearing(path[i - 1], p) : undefined

                    const branchPathsWithMetadata = p.paths.map(
                        (branchPath, branchIndex) => {
                            return { branchPath, branchIndex, direction: null }
                        }
                    )

                    //add the 'direction' metadata, we only handle the case of 2 branches right now
                    if (branchPathsWithMetadata.length === 2) {
                        let branchPathDirections = PipePathAlg.pathDirections(
                            p.paths[0], p.paths[1], prevSegmentBearing)

                        branchPathsWithMetadata[0].direction =
                            branchPathDirections[0]
                        branchPathsWithMetadata[1].direction =
                            branchPathDirections[1]

                        branchPathsWithMetadata.sort((a, b) => {
                            if (a.direction === b.direction) {
                                return 0
                            }

                            return a.direction === Direction.Left ?
                                -1 : 1

                        })
                    }

                    branchPathsWithMetadata.forEach((o) => {
                        const { branchPath, branchIndex, direction } = o

                        let junctionBranchLabel = junctionBranchLabelPrefix

                        if (direction) {
                            junctionBranchLabel += (direction === Direction.Left) ?
                                ' Left' : ' Right'
                        }
                        else {
                            junctionBranchLabel += " " + branchIndex.toString()
                        }

                        ret.push({ junctionBranchLabel })
                        const branchPatchPath = pointPatchPipePath + '/paths/' + branchIndex.toString()
                        ret.push(...traversePath(branchPath, branchPatchPath))
                    })

                    break // assume junction marks end of path
                }

                return ret
            }

            const startingPatchPath = '/path'
            this.workingPathPoints = traversePath(somePath, startingPatchPath)
            this.workingPathSrc = somePath
        }
    },
    mounted: function () {
        this.initWorkingPathPointsFromPipePath()

        this.selectedDefaultPipeType = JSON.parse(JSON.stringify(this.suggestedPipeType))
    }
}
</script>

<style lang="css" scoped>
.elevation_table_div {
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.elevation_input_td {
    text-align: center;
}

.elevation_input {
    text-align: center;
    width: 5em;
}

#elevation_amount {
    width: 3em;
}

.pipe_type_override_div {
    text-align: center;
}

.pipe_type_override_div a {
    font-size: smaller;
    font-style: italic;
}

.pipe_type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.junction_branch_label {
    font-weight: bold;
    text-align: left;
}
</style>

<template>
    <fullpageform title="Edit Farm" submitLabel="Save" cancelLabel="Cancel" @submit="submit" @cancel="cancel">
      <fieldset>
      <label for="farm_name">Name</label>
      <br>
      <input type="text" id="farm_name" v-model="name" ref="name">
      <br>
      <label for="remove_farm" type="button">Remove Farm</label>
      <br>
      <icon-button type="button" id="remove_farm" fa-icon="fa-trash" @click="removeFarm()"/>
      </fieldset>
    </fullpageform>
</template>

<script>
export default {
     props: ['farm'],
     data: function() {
        return {
            name: null
        }
    },
    methods: {
        submit: async function() {
            let modifiedFarm = JSON.parse(JSON.stringify(this.farm))
            modifiedFarm.name = this.name
        
            let patch = {
                op: ('name' in this.farm) ? 'replace' : 'add',
                path: '/name', 
                value: this.name
            }
            
            this.$store.dispatch('patchSelectedFarm', patch)

            this.$dialog.pop()
        },
        cancel: function() {
            this.$dialog.pop()
        },
        async removeFarm() {
            const doTheRemove =  async () => {
                await this.$store.dispatch('removeSelectedFarm')
                this.$dialog.pop()
                this.$router.replace({name: 'Home', params: {}})
            }
            this.$dialog.confirm(doTheRemove)
        },
        updateData() {
            this.name = this.farm.name
        }

    },
    mounted: function() {
        this.updateData()
    }

}
</script>

<style>
</style>
const ZIndexes = {
    Fields : 100,
    FurrowSets: 105,
    UnwateredAreas: 110,
    FurrowWateredAreas: 90,
    LeveeWateredAreas: 110,
    LeveePaths : 140,
    FurrowArrows: 140,
    WaterSources: 150,
    CsvElevation: 160,
    Elevation : 165,
    PipePaths : 170,
    PipePathEditMarkers : 175,
    PipePathElevationEditField : 185,
    HoleDesignErrorMarkers: 190,
    DebugGeos: 200,
    DrawingManager: 300,
    MeasureTool: 2000,
    JdWizardButton: 3000
}

export {
    ZIndexes
}

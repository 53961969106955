<template>
  <div :class="finalClass">
    <select ref="select" :name="props.label" v-model="model">
      <option :value="null" selected class="jd-filter-empty-option">{{ props.label }}</option>

      <option v-for="item in props.options" :value="item.id">{{ item.name }}</option>
    </select>

    <a v-if="props.reset && isResetVisible" @click="resetClicked" class="button jd small"
      style="margin-left: var(--smaller-padding); align-self: center; text-decoration: none;">
      <span class="fa fa-1x fa-remove" />
    </a>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const model = defineModel()

const props = defineProps({
  reset: {
    type: Boolean,
    required: false,
    default: true
  },
  options: {
    type: Array,
    required: false,
    default: []
  },
  label: {
    type: String,
    required: true
  },
  class: {
    type: String,
    required: false,
    default: ""
  }
})

const isResetVisible = computed(() => {
  return props.reset && model.value
})

const resetClicked = () => {
  model.value = null
}

const finalClass = ['jd-filter', props.class].join(' ')
</script>

<style lang="css">
.jd-filter {
  display: flex;
  flex-direction: row;
}

.jd-filter-empty-option {
  font-style: italic;
}

.jd-filter select {
  cursor: pointer;
  border-radius: var(--general-border-radius);
  border: 2px solid var(--jd-yellow);
  padding: var(--general-padding);
  background-color: var(--jd-green);
  color: var(--jd-yellow);
  font-weight: bold;
}
</style>
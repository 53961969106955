<template>
    <pp4-poly :path="furrowSet.path" :label="computedLabel" :showLabel="showLabel"
        v-bind="selectionStyleProps" :clickable="clickable" @click="handleClick" />
</template>

<script>
import Vars from '@/Vars'

import { GeoUtil } from '@/geo/GeoUtil'
import { ZIndexes } from '@/maps/ZIndexes'
import * as WateringTimeAlg from '@/util/WateringTimeAlg'

export default {
    props: {
        fieldLayout: {
            type: Object,
            required: true
        },
        furrowSet: {
            type: Object,
            required: true,
            validator: (o) => o.path?.length && o.path?.length >= 2
        },
        flowRate: {
            type: Object,
            required: false,
            default: null
        },
        showLabel: {
            type: Boolean,
            required: false,
            default: true
        },
        clickable: {
            type: Boolean,
            required: false,
            default: false
        },
        selectionStyle: {
            type: String,
            required: true,
            validator: function (value) { return value == "selected" || value == "selectable" || value == "unselectable" }
        }
    },
    data: function () {
        return {
            ZIndexes
        }
    },
    computed: {
        selectionStyleProps: (vm) => {
            switch (vm.selectionStyle) {
                case 'selected':
                    return {
                        strokeColor: Vars.SelectedFieldStrokeColor,
                        strokeWeight: Vars.SelectedFieldStrokeWeight,
                        labelClass: vm.$style['selected_furrow_set_label'],
                        fillColor: Vars.SelectedFieldFillColor,
                        fillOpacity: Vars.SelectedFieldFillOpacity,
                        zIndex: ZIndexes.FurrowSets + 5
                    }
                case 'selectable':
                    return {
                        strokeColor: Vars.SelectableFieldStrokeColor,
                        strokeWeight: Vars.SelectableFieldStrokeWeight,
                        labelClass: vm.$style['selectable_furrow_set_label'],
                        fillColor: Vars.SelectableFieldFillColor,
                        fillOpacity: Vars.SelectableFieldFillOpacity,
                        zIndex: ZIndexes.FurrowSets + 3
                    }
                case 'unselectable':
                    return {
                        strokeColor: Vars.UnselectableFieldStrokeColor,
                        strokeWeight: Vars.UnselectableFieldStrokeWeight,
                        labelClass: vm.$style['unselectable_furrow_set_label'],
                        fillColor: Vars.UnselectableFieldFillColor,
                        fillOpacity: Vars.UnselectableFieldFillOpacity,
                        zIndex: ZIndexes.FurrowSets
                    }
            }

            return {}
        },
        computedLabel: (vm) => {
            if (!vm.furrowSet?.path?.length) {
                return null
            }

            const furrowSetGeo = GeoUtil.LatLngs.toPolygon(vm.furrowSet.path)
            const furrowSetAreaInSquareMeters = GeoUtil.GeoJson.area(furrowSetGeo)

            const areaInAcres = 0.0002471052 * furrowSetAreaInSquareMeters

            let ret = vm.furrowSet.name + '<br>(' + Math.round(areaInAcres) + ' ac.)'

            if (vm.flowRate) {
                ret += '<br>' + WateringTimeAlg.formattedWateringTimeAndFlowRate(areaInAcres, vm.flowRate, vm.fieldLayout)
            }

            return ret
        }
    },
    emits: ['click'],
    methods: {
        handleClick(e) {
            this.$emit('click', this.furrowSet)
        }
    },
    mounted() {
    }
}
</script>

<style lang="css" module>
.selected_furrow_set_label {
    font-size: small;
    font-weight: bold;
    color: var(--selected-field-stroke-color);
    background: rgba(0, 0, 0, 0.8);
    padding: 0.5em;
    border-radius: var(--softer-border-radius);
    transform: translate(0%, 50%);
    text-align: center;
}

.selectable_furrow_set_label {
    font-size: small;
    color: var(--selectable-field-stroke-color);
    transform: translate(0%, 50%);
    text-align: center;
}

.unselectable_furrow_set_label {
    font-size: small;
    color: var(--unselectable-field-stroke-color);
    transform: translate(0%, 50%);
    text-align: center;
}
</style>

<template>
    <context-menu>
        <loading v-if="data.splitLoading" label="Splitting Field..."/>
        <icon-button label="Guidance Lines" class="mb-1"
            description="Import associated John Deere Guidance lines to split field" @click="importJdGuidanceLines"
            :jd="true">
            <img :src="GuidanceLinesIcon" :draggable="false">
        </icon-button>

        <div id="jd-split-boundary-context-menu">
        </div>

        <!-- <toggle-button v-if="removePointsAvailable" label="Remove Points" class="button_margin_top"
                    :active="data.removePointsActive" description="Remove Levee Points" :disabled="removePointsDisabled"
                    @click="toggleRemovePoints()">
                    <img :src="RemovePointsIcon" draggable="false" />
                </toggle-button>

                <icon-button v-if="jdAvailable" label="Guidance Levees"
                    description="Import associated John Deere Guidance lines as levees" class="button_margin_top"
                    @click="importJdGuidanceLines" :jd="true">
                    <img :src="GuidanceLinesIcon" :draggable="false">
                </icon-button>

                <icon-button v-if="showRemoveAll" label="Remove All" class="button_group_margin_top" faIcon="fa-eraser"
                    @click="removeAll" />
            -->
        <div class="radio_button_group">
            <radio-button id="split-path-add-mode" name="split-path-edit-mode" v-model="data.mode"
                :radioValue="SplitPathMode.Add" faIcon="fa-plus-circle" label="Add" description="Add split path" />
            <radio-button id="split-path-edit-mode" name="split-path-edit-mode" v-model="data.mode"
                :radioValue="SplitPathMode.Edit" faIcon="fa-pencil" label="Edit" description="Edit split paths" />
            <radio-button id="split-path-remove-mode" name="split-path-remove-mode" v-model="data.mode"
                :radioValue="SplitPathMode.Remove" faIcon="fa-minus-circle" label="Remove"
                description="Remove split paths" />
        </div>

        <icon-button v-if="splitPaths?.length" label="Remove All" description="Remove All Split Paths" faIcon="fa-eraser" class="button_margin_top"
        @click="removeAll" />
    </context-menu>

    <next-step>
        <icon-button label="Divide Boundary" description="Divide this boundary into multiple fields using the given paths"
            :big="true" :disabled="splitDisabled" @click="splitField" :useSaveIcon="true" />
    </next-step>

    <map-cancel-button label="Back" @cancel="cancel" />

    <AddPaths v-if="data.mode === SplitPathMode.Add" v-model="splitPaths" :fieldLayout="props.fieldLayout" />

    <EditPaths v-if="data.mode === SplitPathMode.Edit" v-model="splitPaths" :fieldLayout="props.fieldLayout" />

    <RemovePaths v-if="data.mode === SplitPathMode.Remove" v-model="splitPaths" />

    <pp4-field :field="props.field" :fieldLayout="props.fieldLayout" :showFurrowDirection="false" :showLabel="false"
        :showLabelOnMouseover="false"
        selectionStyle="selected" />

    <pp4-poly v-for="o in splitPolys" :key="o.key" :path="o.path" :clickable="false" 
        :label="o.label"
        :fillColor="o.color" strokeColor="transparent" labelClass="paddy_label"/>
</template>

<script setup>
import { inject, ref, reactive, computed } from 'vue'
import * as uuid from 'uuid'
import { useRouter } from 'vue-router'

import AddPaths from './AddPaths'
import RemovePaths from './RemovePaths'
import EditPaths from './EditPaths'
import { FurrowSetAlg } from '@/geo/FurrowSetAlg'
import { GeoUtil } from '@/geo/GeoUtil'
import Vars from '@/Vars'

import GuidanceLinesIcon from '@/assets/guidance_lines.svg'
import JdGuidanceLinesImport from '@/components/jd/JdGuidanceLinesImport'

const SplitPathMode = {
    Add: "add", Edit: "edit", Remove: "Remove"
}
Object.freeze(SplitPathMode)

const emit = defineEmits(['cancel'])
const props = defineProps({
    field: {
        type: Object,
        required: true
    },
    fieldLayout: {
        type: Object,
        required: true
    }
})

const data = reactive({
    mode: SplitPathMode.Add,
    splitLoading: false
})

const $store = inject("$store")
const $api = inject("$api")
const $dialog = inject("$dialog")
const $router = useRouter()

const splitPaths = ref([])

const splitPolys = computed(() => {
    const newFieldGeos = new FurrowSetAlg().buildSplitPathGeos(props.fieldLayout.path, splitPaths.value)

    newFieldGeos.forEach(geo => {
        const center = GeoUtil.GeoJson.toLatLngs(GeoUtil.GeoJson.centerOfMass(geo))
        const areaInAcres = GeoUtil.GeoJson.areaInAcres(geo)
        geo.properties.centerLatLng = center
        geo.properties.areaInAcres = areaInAcres
    })

    // sort from top left to bottom right
    newFieldGeos.sort((a, b) => {
        const ca = a.properties.centerLatLng
        const cb = b.properties.centerLatLng

        return (ca.lng - ca.lat) - (cb.lng - cb.lat)
    });

    return newFieldGeos.map((geo, index) => {
        const path = GeoUtil.GeoJson.toLatLngs(geo)
        const areaInAcres = Math.round(geo.properties.areaInAcres)
        const name = `${props.field.name} / Split ${index + 1}`

        return {
            path, key: uuid.v1(),
            name,
            label: `${name}<br>${areaInAcres} ac.`,
            color: (index % 2) === 0 ? Vars.JdYellow : Vars.JdGreen
        }
    })
})

const splitDisabled = computed(() => splitPolys.value.length < 2)

async function splitField() {
    try {
        const pathsAndNames = splitPolys.value.map(p => {
            return {
                path: p.path, name: p.name
            }
        })

        if (pathsAndNames.length < 2) {
            return
        }

        data.splitLoading = true

        const newFieldIds = await $api.postFieldSplit(
            props.field.id, props.fieldLayout.id, pathsAndNames)

        await $store.dispatch('refreshSelectedFarm')

        if (!newFieldIds?.length) {
            data.splitLoading = false
            return
        }

        $router.push({
            name: 'FarmMapView', params: {
                farmId: props.field.farmId,
                fieldId: newFieldIds[0]
            }
        })

    } catch (error) {
        console.error(error)
        $store.dispatch('warning', 'Split Failed')
    }
    finally {
        data.splitLoading = false
    }
}

async function importJdGuidanceLines() {
    const jd = props.field.jd

    const fieldGeo = GeoUtil.LatLngs.toPolygon(props.fieldLayout.path)

    const instance = await $dialog.push(JdGuidanceLinesImport, {
        organizationId: jd.organizationId,
        fieldId: jd.fieldId,
        boundsGeo: fieldGeo
    })
    instance.on('done', (paths) => {
        paths.forEach(p => {
            p.jd = true
        })

        const newSplitPaths = splitPaths.value.filter(p => ! p.jd)
        newSplitPaths.push(...paths)

        splitPaths.value = newSplitPaths

        $dialog.pop()
    })
    instance.on('cancel', (finalIrrigationSystem) => {
        $dialog.pop()
    })
}

function removeAll() {
    //splitPaths.value.splice(0, splitPaths.value.length)
    splitPaths.value = []
}

function cancel() {
    emit('cancel')
}
</script>

<style lang="css" scoped>
.radio_button_group {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid black;
}
</style>

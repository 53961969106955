<template>
  <template v-if="data.irrigationSystemSelected != true">
    <water-source @click="selectionsChanged" :location="selectedIrrigationSystem.waterSourceLocation" :clickable="true"
      :name="selectedIrrigationSystem.name" :showLabel="true" selectionStyle="selected" />
  </template>

  <template v-if="data.newWaterSourceLocation">
    <water-source :location="data.newWaterSourceLocation" :clickable="true" @click="selectionsChanged"
      :name="selectedIrrigationSystem.name" selectionStyle="selected" />
  </template>

  <template v-if="data.cursorLocation  && data.irrigationSystemSelected">
    <water-source v-if="data.newWaterSourceLocation == null" :location="data.cursorLocation" :clickable="false"
      :name="selectedIrrigationSystem.name" @click="setNewLocation" selectionStyle="selected" />
  </template>

  <context-menu>
    <icon-button label="Save" description="Save" v-bind:disabled="!data.newWaterSourceLocation" :useSaveIcon="true"
      @click="save()" />
    <icon-button label="Cancel" description="Cancel" class="button_margin_top" :useCancelIcon="true" @click="cancel()" />
  </context-menu>
</template>

<script setup>
import WaterSource from "@/components/maps/irrigation_systems/WaterSource"

import { LatLng } from "@/store/Dto"
import { onMounted, reactive, inject, computed } from "vue";

import { useMaps } from "@/composables/useMaps"

const props = defineProps({
  farm: {
    type: Object,
    required: false,
  },
  irrigationSystem: {
    type: Object,
    required: true,
  }
})


const data = reactive({
  irrigationSystemSelected: false,
  cursorLocation: null,
  newWaterSourceLocation: null,
  pipePaths: []
})


const $store = inject('$store')
const maps = useMaps()

const selectedIrrigationSystem = computed(() => $store.state.selectedIrrigationSystem)

function setNewLocation(e) {
  if (data.irrigationSystemSelected == false) {
    return
  }

  let googleLatLng = e.latLng
  let latLng = new LatLng(googleLatLng.lat(), googleLatLng.lng())

  data.newWaterSourceLocation = latLng
  data.saveAvailable = true
  data.cursorLocation = null
}

onMounted(() => {
  $store.dispatch('mapObjects/setHelpKey', 'move_water_sources')

  maps.map.setOptions({
    draggableCursor: "default",
    disableDoubleClickZoom: true,
  })

  maps.addMapListener('mousemove', (e) => {
    let googleLatLng = e.latLng
    let latLng = new LatLng(googleLatLng.lat(), googleLatLng.lng())
    if (data.irrigationSystemSelected == true) {
      data.cursorLocation = latLng
    }
  })

  maps.addMapListener('mouseout', () => {
    data.cursorLocation = null
  })

  maps.addMapListener('click', setNewLocation)

  maps.doDragHack()
})


function selectionsChanged(newSelectedWaterSource) {
  if (data.irrigationSystemSelected == false) {
    data.irrigationSystemSelected = true
  } else if (data.irrigationSystemSelected == true) {
    data.newWaterSourceLocation = null
  }
}

async function save() {
  const patches = []

  patches.push({
    op: "replace",
    path: "/waterSourceLocation",
    value: data.newWaterSourceLocation,
  })

  props.irrigationSystem.pipePaths.forEach((pipePath, i) => {
    patches.push({
      op: 'replace',
      path: `/pipePaths/${i}/path/0`,
      value: data.newWaterSourceLocation
    })
  })

  await $store.dispatch("patchIrrigationSystem", { id: props.irrigationSystem.id, patch: patches })

  $store.dispatch("returnToSelectedIrrigationSystem")
}

function cancel() {
  $store.dispatch("returnToSelectedIrrigationSystem")
}
</script>

<style lang="css"></style>

<script>
export default {
    render() {
        return null
    },
    mounted: function() {
        if(this.$auth.loading) {
            console.log('Auth is Loading...')
            this.$route
        }
        else if(this.$auth.isAuthenticated) {
            console.log('Already Authenticated')
            this.$router.push({name: 'Home', params: {}})
            return
        }
        else {
            console.log('Login with Redirect')
            this.$auth.loginWithRedirect()
        }
    }
}
</script>

export function wateringTimeInHours(wateredAreaInAcres, flowRate, fieldLayout) {
    if((flowRate == null || (! Number.isFinite(flowRate.valueInGpm)) || (flowRate.valueInGpm < 1))) {
        return null
    }
    
    const areaInSquareInches = wateredAreaInAcres * 6272646.0
    const volumeNeededInCubicInches = areaInSquareInches * fieldLayout.grossAppliedInches
    const volumeNeededInGallons = volumeNeededInCubicInches * 0.004329018

    let wateringTimeInHours = (volumeNeededInGallons / flowRate.valueInGpm / 60.0)
    if(fieldLayout.alternatingFurrows) {
        wateringTimeInHours *= 0.65
    }

    return wateringTimeInHours
}

function formattedWateringTime(wateredAreaInAcres, flowRate, fieldLayout) {
    const val = wateringTimeInHours(wateredAreaInAcres, flowRate, fieldLayout)
    if(! val) {
        return "N/A"
    }

    return val.toFixed(1) + ' hrs'
}

export function formattedWateringTimeAndFlowRate(wateredAreaInAcres, flowRate, fieldLayout) {
    if((flowRate == null || (! Number.isFinite(flowRate.valueInGpm)) || (flowRate.valueInGpm < 1))) {
        return 'N/A'
    }
    
    return formattedWateringTime(wateredAreaInAcres, flowRate, fieldLayout) + ' @ ' + flowRate.valueInGpm + ' gpm'
}

export function onlyFormattedWateringTime(wateredAreaInAcres, flowRate, fieldLayout) {
    if((flowRate == null || (! Number.isFinite(flowRate.valueInGpm)) || (flowRate.valueInGpm < 1))) {
        return 'N/A'
    }

    return formattedWateringTime(wateredAreaInAcres, flowRate, fieldLayout)
}

export function setsNeededBasedOnWateringTime(wateredAreaInAcres, flowRate, fieldLayout) {
    if((flowRate == null || (! Number.isFinite(flowRate.valueInGpm)) || (flowRate.valueInGpm < 1))) {
        return 'N/A'
    }
        const desiredWateringTimeInvalid = ! (
            fieldLayout.desiredWateringTimeInHours != null
            && Number.isFinite(fieldLayout.desiredWateringTimeInHours)
            && fieldLayout.desiredWateringTimeInHours >= 1
        )
        const formattedWateringTimeInHours = onlyFormattedWateringTime(wateredAreaInAcres, flowRate, fieldLayout)
        const wateringTimeInHours = parseFloat(formattedWateringTimeInHours)

        let ret = 1

        if(desiredWateringTimeInvalid) {
            return ret
        }

        if(wateringTimeInHours == null) {
            return ret
        }

        while(true) {
            if(ret === 100) {
                break 
            }

            if((wateringTimeInHours / ret) > fieldLayout.desiredWateringTimeInHours) {
                ret += 1
                continue
            }

            break
        }

        return ret
}

export function formattedWateringTimeAndGrossAppliedInches(wateredAreaInAcres, flowRate, fieldLayout) {
    if((flowRate == null || (! Number.isFinite(flowRate.valueInGpm)) || (flowRate.valueInGpm < 1))) {
        return 'N/A'
    }
    
    const areaInSquareInches = wateredAreaInAcres * 6272646.0
    const volumeNeededInCubicInches = areaInSquareInches * fieldLayout.grossAppliedInches
    const volumeNeededInGallons = volumeNeededInCubicInches * 0.004329018

    let wateringTimeInHours = (volumeNeededInGallons / flowRate.valueInGpm / 60.0)
    if(fieldLayout.alternatingFurrows) {
        wateringTimeInHours *= 0.65
    }

    return formattedWateringTime(wateredAreaInAcres, flowRate, fieldLayout) + ' for '
        + fieldLayout.grossAppliedInches + ' in'
}

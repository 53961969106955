<template>
   <Teleport to="#full_dialog_div">
      <slot />
   </Teleport>
</template>

<script setup>
import { onBeforeUnmount, onBeforeMount, Teleport } from 'vue';

onBeforeMount(() => {
   document.getElementById('full_dialog_div').classList.remove('hidden')
})

onBeforeUnmount(() => {
   document.getElementById('full_dialog_div').classList.add('hidden')
})
</script>

<template>
  <div class="jd-top-div">

    <!-- Header -->
    <select-farm-header title="John Deere" style="width: 100%;" :showFarmSearchText="false"
      descriptionText="Import fields directly from John Deere through their Connections API." />

    <!-- Content -->
    <div class="jd-content-div">
      <loading :absolute="true" v-if="userInfoSwrv.isValidating.value" title="Checking User..." />

      <template v-if="showDealerTos">
        <jd-dealer-tos/>
      </template>
      <template v-else>
        <div v-if="(syncState === JohnDeereState.NotEstablished) && syncUri?.length" class="mt-1">
          <icon-button description="Connect to John Deere" label="Connect" :big="true" faIcon="fa-plug"
            @click="syncJohnDeere" :jd="true" />
        </div>
      </template>

      <div v-if="connectionEstablished" class="jd-connection-established-div">
        <JdOrgs />

        <div class="jd-common-bottom-buttons">
          <button @click.prevent="disconnect()" class="jd"> Disconnect</button>
          <span v-if="data.selectOrganizationsUri" class="description">
            <a :href="data.selectOrganizationsUri" class="widget_margin_left" target="_blank">Select Organizations</a>
          </span>

          <!-- <span class="description ml-1">
              <a class="" href="#" @click.stop="openInvoices">Invoices</a>
            </span> -->
        </div>
      </div>
    </div>

    <pp4-footer />
  </div>
</template>

<script setup>
import useSWRV from 'swrv'
import { reactive, computed, onMounted, inject } from 'vue'
import { useStore } from 'vuex'

import { JohnDeereState } from '@/store/JohnDeereStore'
import SelectFarmHeader from '@/components/SelectFarmHeader.vue'
import JdOrgs from '@/components/jd/JdOrgs'
import JdDealerTos from '@/components/jd/JdDealerTos'
import Invoices from '@/components/payments/Invoices'

const data = reactive({
  selectOrganizationsUri: null
})

const $api = inject('$api')
const $auth = inject('$auth')
const $dialog = inject('$dialog')

const userInfoSwrv = useSWRV('/api/user-info', async (url) => {
  const result = await $api.fetch(url, { method: "GET" })

  return await result.json()
}, { defaultValue: null, revalidateOnFocus: false })

const showDealerTos = computed(() => {
  const roles = $auth.user?.value['https://deltaplastics.com/roles'] || []
  if (!roles.length) {
    return false
  }

  if(! roles.some(r => r === 'John Deere Dealer')) {
    return false
  }

  const userInfo = userInfoSwrv.data.value

  return userInfo?.tos?.jdDealer?.needsAcceptance
})

const store = useStore()

const syncState = computed(() => {
  return store.state.jd.state
})

const syncUri = computed(() => {
  return store.state.jd.syncUri
})

const connectionEstablished = computed(() => {
  return store.state.jd.state === JohnDeereState.Established
})

const disconnect = async () => {
  return store.dispatch('jd/disconnect')
}

const syncJohnDeere = async () => {
  window.location = syncUri.value
}

async function openInvoices() {
  const instance = await $dialog.push(Invoices)

  instance.on('cancel', () => {
    $dialog.pop()
  })
}

onMounted(async () => {
  store.dispatch('jd/init')

  let uri = await store.getters['jd/getSelectOrganizationsUri']()

  // uri += `?redirect_uri=${redirectUri.value}`

  data.selectOrganizationsUri = uri
})

</script>

<style lang="css" scoped>
.jd-top-div {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  background-color: var(--bg0);
}

.jd-content-div {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  width: 90%;
  margin-top: var(--general-padding);
  position: relative;
  overflow: auto;
  z-index: 0;
}

.jd-connection-established-div {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.jd-common-bottom-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: var(--general-padding);
  position: sticky;
  bottom: 0px;
  background-color: var(--bg0);
}

.connect_div {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}

.organizations_div {
  display: flex;
  flex-direction: column;
  gap: var(--general-padding);
  flex: 1;
  align-items: center;
  background: var(--primary-dark);
  padding: var(--general-padding);
}

.organizations_accordion_wrapper {
  justify-content: space-evenly;
  display: flex;
  flex-direction: column;
  row-gap: var(--general-padding);
}

.organizations_div .accordion {
  padding: var(--general-padding);
  row-gap: var(--general-padding);
  flex-grow: 0;
}
</style>

<template>
    <geo-json :value="lineFeaturesCollection"/>
</template><script>

import { GeoUtil } from '@/geo/GeoUtil'
import { ZIndexes } from '@/maps/ZIndexes'

import PipePathAlg from '@/design/PipePathAlg'

export default {
    props: {
        path: {
            type: Array,
            required: true,
            validator: function (value) {
                return !(value == null || value.length < 1)
            }
        },
        zIndex: {
            type: Number,
            required: false,
            default: ZIndexes.PipePaths - 1
        }
    },
    computed: {
        elevationLatLngsByBranch: (vm) => {
            return PipePathAlg.buildInterpolatedLatLngsWithElevation(vm.path, 100)
        },
        lineFeaturesWithMetadata: (vm) => {
            const {min, max, paths} = vm.elevationLatLngsByBranch
            
            const change = max - min
            const step = change / (vm.ElevationColors.length - 1)

            const ret = [];

            if(step) {
                for(let path of paths) {
                    for (let i = 1; i < path.length; i++) {
                        const ll0 = path[i - 1];
                        const ll1 = path[i];

                        const colorIndex = Math.round((max - ll0.elevationInFeet) / step)

                        const ls = GeoUtil.LatLngs.toLineString([ll0, ll1]);
                        ls.properties.color = vm.ElevationColors[colorIndex]

                        ret.push(ls)
                    }
                }
            }

            return ret
        },

        lineFeaturesCollection: (vm) => GeoUtil.GeoJson.featureCollection(vm.lineFeaturesWithMetadata)
    },
    data: function () {
        return {
            overlayMapObject: null,
            ElevationColors: [
                "#B21F25",
                "#c12125",
                "#d02324",
                "#de2424",
                "#ED2623",
                "#ee3123",
                "#ee3d23",
                "#ef4822",
                "#EF5322",
                "#f06122",
                "#f26f21",
                "#f37d21",
                "#F48B20",
                "#f59b22",
                "#f5aa23",
                "#f6ba25",
                "#F6C926",
                "#edce2b",
                "#e4d32f",
                "#dad734",
                "#D1DC38",
                "#bfd63a",
                "#add03c",
                "#9aca3e",
                "#88C440",
                "#81c341",
                "#7bc143",
                "#74c044",
                "#6DBE45",
                "#6dbe45",
                "#6ebf46",
                "#6ebf46",
                "#6EBF46",
                "#6fbf4e",
                "#6fc057",
                "#70c05f",
                "#70C067",
                "#70c27c",
                "#70c390",
                "#70c5a5",
                "#70C6B9",
                "#62c4c6",
                "#53c2d3",
                "#45bfe0",
                "#36BDED",
                "#38aadf",
                "#3a96d1",
                "#3c83c2",
                "#3E6FB4",
                "#3d68b0",
                "#3c61ac",
                "#3b5aa7",
                "#3A53A3",
                "#354fa1",
                "#304a9f",
                "#2b469c",
                "#26419A",
                "#263b8e",
                "#273583",
                "#272e77",
                "#27286B",
                "#252567",
                "#232362",
                "#20205e",
                "#1E1D59"
            ]
        }
    },
    methods: {
    }
}
</script>

import { FieldMode } from '@/store/Dto'

import Printer from '@/print/Printer'

import Vars from '@/Vars'

export default class HoleDesignPrinter extends Printer {
    async getSummaryTableAndPicture(data, onFirstPage) {
        let imageData = await this.getBase64ImageFromURL(data.pipeImage)

        const h = (s, o = {}) => {
            return this.buildColumnHeader(s, o)
        }

        const isFurrows = data.fieldLayout.mode === FieldMode.Furrows

        let body = []

        if (data.jd?.organizationId) {
            body = body.concat([
                [h('Organization'), data?.jd?.organization?.name || 'N/A'],
                [h('Client'), data?.jd?.client?.name || 'N/A'],
                [h('Farm'), data?.jd?.farm?.name || 'N/A'],
                [h('Field'), data?.jd?.field?.name || 'N/A'],
                [h('Boundary'), data?.jd?.boundary?.name || 'N/A'],
                [h('PP4 Field'), data.field?.name || 'N/A']
            ])
        }
        else {
            body = body.concat([
                [h('Farm'), data.farmName],
                [h('Field'), data.field.name],
            ])
        }

        if (data.fieldLayout.name) {
            body.push(
                [h('Layout'), data.fieldLayout.name]
            )
        }
        if (data.furrowSet) {
            body.push(
                [h('Set Name'), data.furrowSet.name]
            )
        }

        body.push(...[
            [h('Watered Area'),
            Math.round(data.wateredAreaInAcres, 1) + ' ac'],
            (isFurrows ?
                [h('Furrow Count'), data.sidedWateredAreaCount] :
                [h('Paddies'), data.sidedWateredAreaCount])
        ])

        if (isFurrows) {
            body.push([h('Hole Spacing'), data.holeSpacing])
            body.push([h('Uniformity'), data.uniformity])
        }

        body.push(...[
            [h('Min Head Pressure'), data.minHeadPressureInFeet + ' ft'],
            [h('Max Head Pressure'), data.maxHeadPressureInFeet + ' ft']
        ])

        if (isFurrows) {
            body.push(
                [h('Flow Per Furrow'), data.flowPerAreaInGpm + ' gpm']
            )
        }

        body.push(...[
            [h('Watering Time'), data.formattedWateringTime],
            [h('Flow Rate'), data.flowRateInGpm + ' gpm'],
            [h('Pipe Length'), data.pipeLengthInFeet + ' ft']
        ])

        return {
            pageBreak: onFirstPage ? '' : 'before',
            // margin: [10, 50, 100, 200],
            margin: [0, 0, 0, 0], // left top right bot
            columnGap: 5,
            columns: [
                {
                    //absolutePosition: { x: 50, y:100 },
                    width: 250,
                    layout: {
                        fillOpacity: 1.0,
                        fillColor: (rowIndex, _node, _columnIndex) => {
                            return ((rowIndex % 2) !== 0) ?
                                'white' : Vars.bg2
                        }
                    },
                    table: {
                        widths: ['auto', '*'],
                        body
                    }
                },
                {
                    image: imageData,
                    width: 260, height: 230
                }
            ]
        }
    }

    //Adds the hole data table
    async getHoleDataTable(rolledUpHoleDesign) {
        const branches = rolledUpHoleDesign.rolledUpWateredBranches

        if (branches.length < 1) {
            return {}
        }

        const showBranchNames = branches.length > 1
        const headerRowIndexSet = new Set()

        const tableRows = []
        const holeSizeIndices = []

        const header = (s, o = {}) => {
            headerRowIndexSet.add(tableRows.length)

            return Object.assign(
                { text: s, bold: true, noWrap: true, alignment: 'center' }, o)
        }

        // Poly piranha header info
        // if (rolledUpHoleDesign.fieldLayout.mode === FieldMode.Furrows) {
        //     const polyPiranhaHeader = header(
        //         'Colors from Poly Piranha',
        //         {
        //             alignment: 'right',
        //             colSpan: rolledUpHoleDesign.hasBuildups ? 6 : 5
        //         }
        //     )

        //     tableRows.push([polyPiranhaHeader])
        // }

        const centeredHoleSize = (holeSize, index, pipeFunction, rowIndex) => {
            holeSizeIndices.push({ holeSize, index: rowIndex })

            if (pipeFunction === 'Supply') {
                return { text: '' }
            }

            if (pipeFunction === 'Buildup') {
                return { text: '' }
            }

            const text = [];
            {
                if (holeSize.colorLeft) {
                    text.push({ text: '    ', background: holeSize.colorLeft })
                    text.push('  ')
                }
                text.push(holeSize.name)
                if (holeSize.colorRight) {
                    text.push('  ')
                    text.push({ text: '    ', background: holeSize.colorRight })
                }
            }

            return {
                text, alignment: 'center', background: 'white'
            }
        }

        const centered = (s) => {
            return { text: s, alignment: 'center' }
        }

        const fieldOrPaddyLabel =
            rolledUpHoleDesign.fieldLayout.mode === FieldMode.Furrows ?
                header('Furrow Count') : header('Paddy')

        const holeSizeOrGateLabel =
            rolledUpHoleDesign.fieldLayout.mode === FieldMode.Furrows ?
                header('Hole Size') : header('Gate')

        const headerRow = [
            header('Pipe Size'),
            header('Function'),
            header('Length (ft)'),
            header(holeSizeOrGateLabel),
            header(fieldOrPaddyLabel),
        ]
        if (rolledUpHoleDesign.hasBuildups) {
            headerRow.push(header('Build Up (ft)'))
        }
        tableRows.push(headerRow)

        branches.forEach((branch) => {
            const wateredAreas = branch.rolledUpWateredAreas

            if (showBranchNames) {
                const decoratedBranchName = header(branch.name, { alignment: 'left' })
                decoratedBranchName.colSpan = rolledUpHoleDesign.hasBuildups ? 6 : 5

                const branchNameRow = [decoratedBranchName]

                tableRows.push(branchNameRow)
            }

            wateredAreas.forEach((s, i) => {
                const row = [
                    centered(s.pipeSize),
                    centered(s.pipeFunction),
                    centered(s.pipeLength),
                    centeredHoleSize(s.holeSize, i, s.pipeFunction, tableRows.length),
                    centered(rolledUpHoleDesign.fieldLayout.mode === FieldMode.Furrows ?
                        s.sidedWateredAreaCount : s.wateredAreaRange)
                ]

                if (rolledUpHoleDesign.hasBuildups) {
                    row.push(centered(s.buildupNeededInFeet))
                }

                tableRows.push(row)
            })
        })

        const widths = ['auto', 'auto', '*', '*', 'auto']
        if (rolledUpHoleDesign.hasBuildups) {
            widths.push('auto')
        }

        let tableItem = {
            margin: [0, 20, 0, 15], // left top right bot
            table: {
                headerRows: 1,
                widths,
                body: tableRows
            },
            layout: {
                fillOpacity: 1.0,
                fillColor: (rowIndex, _node, _columnIndex) => {
                    return rowIndex == 0 ? Vars.bg2 : 'white'
                }
            }
        }

        return tableItem;
    }

    //Adds the hole data table
    async getWateringDataTable(wateringEvents) {

        const tableRows = [[
            { text: 'Start', style: 'tableHeader', noWrap: true }
            , { text: 'Stop', style: 'tableHeader', noWrap: true }
            , { text: 'Duration (hrs)', style: 'tableHeader', noWrap: true }
            , { text: 'Water (gal)', style: 'tableHeader', noWrap: true }
            , { text: 'Moisture Method', style: 'tableHeader', noWrap: true }
            , { text: 'Measurement', style: 'tableHeader', noWrap: true }
        ]]

        let totalGallons = 0;
        let totalDuration = 0;
        let totalMeasurement = 0;

        wateringEvents.forEach((s) => {
            totalGallons += s.gallons ? s.gallons : 0;
            totalDuration += s.duration ? parseInt(s.duration) : 0;
            totalMeasurement += s.measurement ? parseFloat(s.measurement) : 0;
            const row = [
                this.getDisplayDateTimeString(s.start),
                this.getDisplayDateTimeString(s.stop),
                s.duration,
                s.gallons.toLocaleString(),
                s.moistureMethod,
                s.measurement
            ]

            tableRows.push(row)
        })

        tableRows.push([
            "Total",
            "",
            totalDuration.toLocaleString(),
            totalGallons.toLocaleString(),
            "",
            totalMeasurement.toLocaleString()
        ]);

        let tableItem = {
            margin: [0, 5, 0, 15],
            table: {
                headerRows: 1,
                body: tableRows
            },
            layout: 'lightHorizontalLines'
        }

        return tableItem;

    }

    buildGateHintImage() {
        return {
            image: 'gatePosition', width: 450,
            alignment: 'center'
        }
    }

    async getPdfContent(rolledUpHoleDesigns) {
        //Loop through each pipe path and make a page.
        let contentArray = [];

        //Multiple
        for (let i = 0; i < rolledUpHoleDesigns.length; i++) {
            contentArray.push(await this.getSummaryTableAndPicture(rolledUpHoleDesigns[i], (i == 0)))
            contentArray.push(await this.getHoleDataTable(rolledUpHoleDesigns[i]))
            contentArray.push(await this.getWateringDataTable(rolledUpHoleDesigns[i].wateringEvents))
        }

        const hadLeveeDesign = rolledUpHoleDesigns.some(d => d.fieldLayout.mode === FieldMode.Levees)
        if (hadLeveeDesign) {
            contentArray.push(this.buildGateHintImage())
        }

        return contentArray;
    }

    getPdfFileName(fieldPipePaths) {
        let fileName = "HoleDesign_" + fieldPipePaths[0].farmName;
        if (fieldPipePaths.length == 1) {
            fileName = fileName + "_" + fieldPipePaths[0].field.name
        }
        return fileName += ".pdf"
    }

    async CreatePDF(mode, fieldPipePaths, jdObjects) {
        var docDefinition = {
            pageMargins: this.buildDefaultPageMargins(),
            header: await this.buildDefaultHeader(),
            footer: this.buildFooterCallback(),
            content: await this.getPdfContent(fieldPipePaths, jdObjects),
            images: await this.buildDefaultImages()
        }

        const PdfMake = await Printer.GetPdfMake()

        const fileName = this.getPdfFileName(fieldPipePaths)

        if (mode === 'print') {
            PdfMake.createPdf(docDefinition).print();
        }
        if (mode === 'save') {
            PdfMake.createPdf(docDefinition).download(fileName);
        }
        if (mode === 'preview') {
            PdfMake.createPdf(docDefinition).open();
        }
        if (mode === 'blob') {
            return new Promise((res, rej) => {
                let blob = PdfMake.createPdf(docDefinition).getBlob((blob) => {
                    res(blob)
                })
            })
        }
    }
}

<template>
    <fullpageform title="Pipe Planner Seasonal Access" :showCancel="true" @cancel="cancel">
        <loading v-if="data.processing" :absolute="true" label="Processing Payment..." />

        <template v-if="availableModes.length > 0">
            <div class="line-item">Pipe Planner Software Connection {{ CurrentSeason() }}: $1500</div>

            <div class="payment-mode-select">
                <div>Payment Method:</div>

                <template v-for="mode in availableModes" :key="mode.key">
                    <div :class="data.mode === mode.id ? 'payment-mode selected' : 'payment-mode'"
                        @click="setMode(mode.id)">
                        {{ mode.name }}
                    </div>
                </template>
            </div>
        </template>

        <div class="processing-error mt-1 mb-1" v-if="data.processingErrorMessage">
            {{ data.processingErrorMessage }}
        </div>

        <template v-if="data.mode === 'card'">
            <form action="/payment" v-on:submit="submit" class="mt-1">
                <div class="label-input-form-row">
                    <label for="cardNumber" class="mr-1">Card Number:</label>
                    <input type="text" id="cardNumber" class="pt-encrypt" name="cardNumber" @input="formatCard"
                        placeholder="---- ---- ---- ----" pattern="\d{4} \d{4} \d{4} \d{4}" required>
                </div>

                <div class="label-input-form-row">
                    <label for="cvv" class="mr-1" title="3 Digit code on back of card">CVV:</label>
                    <input type="text" id="cvv" name="cvv" class="pt-encrypt" placeholder="---" size="3" maxlength="3"
                        minlength="3" pattern="\d\d\d" required="true" @input="formatCcv">
                </div>

                <div class="label-input-form-row">
                    <label for="cardHolder" class="mr-1">Card Holder:</label>
                    <input type="text" id="cardHolder" name="cardHolder" placeholder="---" required size="26" minlength="1">
                </div>

                <div class="label-input-form-row">
                    <label for="expires" class="mr-1">Expires:</label>
                    <input type="text" id="expiration-month" name="expirationMonth" placeholder="MM" required size="4"
                        @input="formatExpirationMonth" pattern="\d\d" minlength="2">
                    <input type="text" id="expiration-year" name="expirationYear" placeholder="YYYY" required size="4"
                        class="ml-1" @input="formatExpirationYear" pattern="\d\d\d\d" minlength="4">
                </div>

                <hr>

                <div class="label-input-form-row">
                    <label for="address" class="mr-1">Billing Address:</label>
                    <input type="text" id="address" name="address" placeholder="---" required size="26" minlength="1">
                </div>
                <div class="label-input-form-row">
                    <label for="city" class="mr-1">City:</label>
                    <input type="text" id="city" name="city" placeholder="---" required size="26" minlength="1">
                </div>
                <div class="label-input-form-row">
                    <label for="state" class="mr-1">State:</label>
                    <input type="text" id="state" name="state" placeholder="--" required size="2" minlength="2"
                        @input="formatState">
                </div>
                <div class="label-input-form-row">
                    <label for="zip" class="mr-1">Zip:</label>
                    <input type="text" id="zip" name="zip" placeholder="-----" required size="12" minlength="5">
                </div>

                <hr>

                <div style="flex-direction: row; display: flex; justify-content: center;" class="mt-1">
                    <button type="submit" value="Submit" class="mr-1">Submit</button>
                    <button @click="cancel" value="Cancel">Cancel</button>
                </div>
            </form>
        </template>
        <template v-else-if="data.mode === 'dealer'">
            <div class="general-wrapper">
                <div class="dealer-payment-info">
                    <div class="mb-1">Payment for this connection will be processed through your Delta Plastics
                        dealer account.</div>

                    <div class="statement mt-1">
                        <div>Contact our team for more information:</div>
                        <div class="mt-1">
                            <a href="tel:+15017720161">1-501-772-0161</a>
                            <div>or</div>
                            <a href="mailto:cdeclerk@deltapl.com">cdeclerk@deltapl.com</a>
                        </div>
                    </div>
                </div>

                <div style="flex-direction: row; display: flex; justify-content: center;" class="mt-1">
                    <button type="submit" value="Submit" class="mr-1" @click="submit">Submit</button>
                    <button @click="cancel" value="Cancel">Cancel</button>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="line-item">Pipe Planner Software Connection {{ CurrentSeason() }}</div>

            <div class="general-wrapper">
                <div class="statement">
                    John Deere Connectivity is Currently Only Available Through Authorized Dealers
                </div>

                <div class="statement mt-1">
                    <div>Contact our team for more information:</div>
                    <div class="mt-1">
                        <a href="tel:+15017720161">1-501-772-0161</a>
                        <div>or</div>
                        <a href="mailto:cdeclerk@deltapl.com">cdeclerk@deltapl.com</a>
                    </div>
                </div>
            </div>
        </template>
    </fullpageform>
</template>

<script setup>
import { inject, computed, onMounted, reactive } from 'vue'

import { CurrentSeason } from '@/util/SeasonAlg'

const $api = inject("$api")
const $store = inject("$store")
const $env = inject("$env")
const $auth = inject("$auth")
const $analytics = inject("$analytics")

const $emit = defineEmits(['done', 'cancel'])

const props = defineProps({
    organizationId: {
        type: String,
        required: true
    }
})

const availableModes = computed(() => {
    const ret = []

    // ret.push({
    //     id: 'card',
    //     key: 'payment-mode-card',
    //     name: "Card"
    // })

    if ($auth.isJohnDeereDealer()) {
        ret.push({
            id: 'dealer',
            key: 'payment-mode-dealer',
            name: "Dealer"
        })
    }

    if(! ret.length) {
        $analytics?.jdActivateBlockedBecauseNotDealer()
    }

    return ret
})

const data = reactive({
    mode: availableModes.value.length ? availableModes.value[0].id : null,
    processing: false,
    processingErrorMessage: null
})

function setMode(mode) {
    data.mode = mode
}

function formatCard(e) {
    let input = e.target;
    let rawValue = input.value.replace(/\D/g, ''); // Remove non-numeric characters
    rawValue = rawValue.slice(0, 16); // Limit to 16 digits

    // if(value.length === 16) {
    //     document.getElementById('cvv').focus(); // Switch focus to cvv
    // }

    const formattedValue = rawValue.replace(/(\d{4})(?=\d)/g, '$1 '); // Add space after every 4 digits

    input.value = formattedValue;
}

function formatCcv(e) {
    let input = e.target;
    let value = input.value.replace(/\D/g, ''); // Remove non-numeric characters
    value = value.slice(0, 3);

    // if(value.length === 3) {
    //     document.getElementById('cardHolder').focus(); // Switch focus to cvv
    // }
}

function formatExpirationMonth(e) {
    let input = e.target;
    let value = input.value.replace(/\D]/g, ''); // Remove non-numeric characters
    value = value.slice(0, 2); // Limit to 2 characters (MM)

    if (value.length === 2) {
        if (value >= '01' && value <= '12') {
            input.value = value;
            // document.getElementById('expiration-year').focus(); // Switch focus to expirationYear
        } else {
            input.value = ''; // Clear value
        }
    }
}

function formatExpirationYear(e) {
    let input = e.target;
    let value = input.value.replace(/^[\D\/]/g, ''); // Remove non-numeric characters
    value = value.slice(0, 5); // Limit to 4 characters (MMYY)

    input.value = value;
}

function formatState(e) {
    const validStateCodes = ['AK', 'AL', 'AR', 'AZ', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VT', 'WA', 'WI', 'WV', 'WY'];

    let input = e.target;
    let value = input.value.trim().slice(0, 2).toUpperCase();

    if (validStateCodes.includes(value)) {
        input.value = value;
    } else if (value.length === 2) {
        input.value = '';
    }
}

function delay(ms) {
    return new Promise(res => setTimeout(res, ms))
}

async function postDealerPayment(e) {
    data.processing = true
    e.target.disabled = true

    try {
        data.processingErrorMessage = null

        await $api.jd.postOrganizationDealerPayment(props.organizationId)

        $emit('done', { message: "Dealer Payment Initiated" })
    }
    catch (e) {
        console.error(e)
        data.processingErrorMessage = `Unexpected Processing Error -- Please Retry or Contact Support`
    }

    data.processing = false
    e.target.disabled = false
}

async function postCardPayment(e) {
    if(! paytrace) {
        return
    }
    
    data.processing = true
    e.target.disabled = true

    data.processingErrorMessage = null

    const cardNumber = e.target.cardNumber.value.replace(/\s/g, '')
    const cvv = e.target.cvv.value
    const cardHolder = e.target.cardHolder.value
    const expiresMonth = e.target.expirationMonth.value
    const expiresYear = e.target.expirationYear.value
    const streetAddress = e.target.address.value
    const city = e.target.city.value
    const state = e.target.state.value
    const zip = e.target.zip.value

    const postBody = {
        "amount": $env.SOFTWARE_CONNECTION_PRICE || "1500",
        "credit_card": {
            "encrypted_number": paytrace.encryptValue(cardNumber),
            "expiration_month": expiresMonth,
            "expiration_year": expiresYear
        },
        "encrypted_csc": paytrace.encryptValue(cvv),
        "billing_address": {
            "name": cardHolder,
            "street_address": streetAddress,
            "city": city,
            "state": state,
            "zip": zip
        }
    }

    try {
        await $api.jd.postOrganizationCardPayment(props.organizationId, postBody)

        $emit('done', { message: "Card Payment Successful" })
    }
    catch (e) {
        console.error(e)
        data.processingErrorMessage = `Unexpected Processing Error -- Please Retry or Contact Support`
    }

    data.processing = false
    e.target.disabled = false
}

async function submit(e) {
    e.stopPropagation();
    e.preventDefault();

    if (data.mode === 'dealer') {
        await postDealerPayment(e)
    }
    else if (data.mode === 'card') {
        await postCardPayment(e)
    }
    else {
        console.warn('Unknown Mode: ' + data.mode)
        return
    }
}

function cancel() {
    $emit('cancel')
}

onMounted(() => {
    if (!paytrace) {
        $store.disaptch('error', 'Payment Processor Not Configured -- Contact Support')
        return
    }

    try {
        paytrace.setKey($env.VUE_APP_PAYTRACE_E2EE_PUBLIC_KEY)

        console.log("Paytrace E2EE Enabled")
    } catch (e) {
        $store.dispatch('Paytrace E2EE Public Key Not Configured')
    }
})
</script>

<style lang="css" scoped>
hr {
    background-color: var(--secondary);
}

.processing-error {
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-weight: bold;
    font-size: large;
    font-style: italic;
    color: var(--error-red);
}

.label-input-form-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    line-height: 2.25em;
    min-width: 32em;
}

.label-input-form-row label {
    min-width: 50%;
    text-align: right;
}

.header {
    color: var(--fg1);
    font-style: italic;
    margin-bottom: var(--general-padding);
    font-size: large;
}

.line-item {
    color: var(--fg0);
    font-size: large;
    font-weight: bold;
}

.total {
    margin-top: var(--general-padding);
    margin-bottom: var(--general-padding);
    font-weight: bold;
    color: var(--crazy);
}

.payment-mode-select {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: var(--bg1);
    margin-top: var(--general-padding);
    padding: var(--general-padding);
    background-color: var(--bg0);
}

.payment-mode {
    font-size: x-large;
    background-color: var(--bg0);
    padding: var(--general-padding);
    cursor: pointer;
    color: var(--fg2);
    min-width: 4em;
    text-align: center;
    user-select: none;
    font-family: var(--font-secondary);
}

.payment-mode.selected {
    font-weight: bold;
    text-decoration: underline;
    color: var(--fg0);
    background-color: var(--secondary);
}

.payment-mode:not(:first-child) {
    margin-left: var(--general-padding);
}

.general-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--general-padding);
    text-align: center;
    min-height: 15em;
}

.dealer-payment-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    min-height: 10em;
    min-width: 30em;
    max-width: 31em;
}
</style>

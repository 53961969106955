<template>
    <template v-if="shown">
        <poly v-if="isGeoPolygon" :path="selectedGeoLatLngs" v-bind="selectedGeoJsonProps"
            :zIndex="ZIndexes.DebugGeos" />
        <pp4-polyline v-else-if="isGeoPolyline" :path="selectedGeoLatLngs" v-bind="selectedGeoJsonProps"
            :zIndex="ZIndexes.DebugGeos" />
        <template v-else-if="isGeoPoint">
            <div>{{ selectedGeoLatLngs }}</div>
            <point-marker :location="selectedGeoLatLngs" :zIndex="ZIndexes.DebugGeos" :isLastPoint="false"/>
        </template>
        <template v-else-if="isGeoMultiPolygon">
            <poly v-for="(path, index) in selectedGeoMultiPolygonPaths" :path="path" :key="index"
                v-bind="selectedGeoJsonProps" :zIndex="ZIndexes.DebugGeos" />
        </template>

        <template v-else-if="isFeatureCollection">
            <pp4-polyline v-for="(ls, i) in selectedGeoJson.features" :path="GeoUtil.GeoJson.toLatLngs(ls)"
                v-bind="selectedGeoJsonProps" :zIndex="ZIndexes.DebugGeos" />
        </template>

        <map-controls position="RIGHT_TOP">
            <debug-menu />
        </map-controls>
    </template>
</template>

<script>
import Poly from '@/components/maps/Poly'
import Pp4Polyline from '@/components/maps/Polyline'
import Pp4Marker from '@/components/maps/Marker'
import DebugMenu from '@/components/maps/debug_objects/DebugMenu'
import MapControls from '@/components/maps/MapControls'

import { GeoUtil } from '@/geo/GeoUtil'
import { ZIndexes } from '@/maps/ZIndexes'

export default {
    components: {
        Poly, Pp4Polyline, MapControls, Pp4Marker, DebugMenu
    },
    data: function () {
        return {
            ZIndexes, GeoUtil
        }
    },
    computed: {
        selectedGeoJson: (vm) => vm.$store.state.mapObjects.debug.selectedGeoJsonPoly,
        selectedGeoJsonProps: (vm) => {
            const ret = vm.selectedGeoJson.properties

            if (!ret.strokeColor) {
                ret.strokeColor = GeoUtil.randomColor()
            }
            if (!ret.fillColor) {
                ret.fillColor = GeoUtil.randomColor()
            }
            if (!ret.color) {
                ret.color = GeoUtil.randomColor()
            }

            return ret
        },
        shown: (vm) => true,// vm.selectedGeoJson != null,
        isGeoPolygon: (vm) => GeoUtil.GeoJson.isPolygon(vm.selectedGeoJson),
        isGeoMultiPolygon: (vm) => GeoUtil.GeoJson.isMultiPolygon(vm.selectedGeoJson),
        isGeoPolyline: (vm) => GeoUtil.GeoJson.isLineString(vm.selectedGeoJson),
        isGeoPoint: (vm) => {
            const ret = GeoUtil.GeoJson.isPoint(vm.selectedGeoJson)

            return ret
        },
        isFeatureCollection: (vm) => GeoUtil.GeoJson.isFeatureCollection(vm.selectedGeoJson),
        selectedGeoLatLngs: (vm) => {
            const ret = GeoUtil.GeoJson.toLatLngs(vm.selectedGeoJson)

            return ret
        },
        propertiesLabel: (vm) => {
            if (!vm.selectedGeoJson) {
                return null
            }

            if (!('properties' in vm.selectedGeoJson)) {
                return null
            }

            const properties = vm.selectedGeoJson.properties
            if (!('label' in properties)) {
                return null
            }

            return properties.label
        },
        selectedGeoMultiPolygonPaths: (vm) => {
            let geo = vm.selectedGeoJson

            if (!GeoUtil.GeoJson.isMultiPolygon(geo)) {
                return []
            }

            let geos = GeoUtil.GeoJson.multiPolygonToPolygons(geo)

            return geos.map(GeoUtil.GeoJson.toLatLngs)
        }
    },
    methods: {
    },
    mounted() {
    }
};
</script>

<style lang="css"></style>

<script>
import { render as vueRender, toRaw, h } from 'vue'

export default {
    props: {
        latLng: {
            type: Object,
            required: true
        },
        layer: {
            type: String,
            default: 'overlayMouseTarget'
        }
    },
    data: function () {
        return {
            element: null
        }
    },
    inject: ['provider'],
    beforeMount() {
        const { map, api } = this.provider

        class ElementOverlayClass extends api.OverlayView {
            constructor(centerLatLng, pane) {
                super()

                this.element = document.createElement("div")
                this.element.classList.add('map_element')

                // element
                this.centerLatLng = centerLatLng
                this.pane = pane
                this.elementWidth = null
                this.elementHeight = null

                if (!this.element) {
                    throw new Error("Element Required")
                }
            }

            onAdd() {
                if (!this.element) {
                    return
                }

                this.getPanes()[this.pane].appendChild(this.element)
            }

            onRemove() {
                toRaw(this.element).remove()
                this.element = null
            }

            render(hmm) {
                const hd = h(hmm)
                vueRender(hd, this.element)
            }

            draw() {
                if (!this.element) {
                    return
                }

                let proj = this.getProjection()
                if (proj == null) {
                    return
                }

                let xy = proj.fromLatLngToDivPixel(this.centerLatLng)

                let style = this.element.style

                Object.assign(style, {
                    left: `${xy.x}px`,
                    top: `${xy.y}px`
                })
            }

            setVisible = function (val) {
                if (val) {
                    this.element.classList.remove('hidden')
                }
                else {
                    this.element.classList.add('hidden')
                }
            }
        }

        this.element = new ElementOverlayClass(this.latLng, this.layer)
        this.element.setMap(map)
        this.element.render(this.$slots.default)
    },
    unmounted() {
        if (this.element) {
            this.element.setMap(null)
        }
    },
    render() {
        this.element?.render(this.$slots.default)
    }
}
</script>

<style lang="css">
.map_element {
    position: absolute;
    display: block;
    transform: translate(-50%, -50%);
}
</style>
import { HoleSide } from '@/design/DesignDto'
import { GeoUtil } from '@/geo/GeoUtil'
import { IrrigationStepType } from '@pp4/design'
import { FieldMode } from '@/store/Dto'
import PipePathAlg from '@/design/PipePathAlg'
import * as WateringTimeAlg from '@/util/WateringTimeAlg'

export default class RollupAlg {
    static wateredAreaOutletsString(wateredArea, desiredHoleSide, allSameHoleSide) {
        const waterableArea = wateredArea.waterableArea
        if (!waterableArea) {
            return ''
        }

        const formatOutlet = (o) => (o instanceof Object) ? o.name : o

        if (!('holeSide' in waterableArea)) {
            if (desiredHoleSide === HoleSide.Left) {
                return wateredArea.outlets.map(formatOutlet).join(', ')
            }

            return ''
        }

        if (waterableArea.holeSide !== desiredHoleSide) {
            return ''
        }

        const prefix = allSameHoleSide ? '' :
            ((waterableArea.holeSide === HoleSide.Left) ? 'L ' : 'R ')

        return prefix + wateredArea.outlets.map(formatOutlet).join(', ')
    }

    static buildAssociatedJdObject(field, jdObjects={}) {
        const ret = {
            ...field.jd
        }

        const boundary = jdObjects?.boundaries[field.jd.boundaryId] 
        if (boundary) {
            ret.boundary = boundary
        }

        const jdField = jdObjects?.fields[field.jd.fieldId]
        if (jdField) {
            ret.field = jdField

            ret.organization = jdObjects.organizations[field.jd.organizationId]

            if(jdField.clients.length) {
                ret.client = jdField.clients[0]
            }
            if(jdField.farms.length) {
                ret.farm = jdField.farms[0]
            }
        }

        return ret
    }

    static buildRolledUpHoleDesignFromPipePath(farm, fieldForPipePath,
        layoutForPipePath, irrigationSystem, pipePath, furrowSet, jdObjects) {
        const designParams = pipePath.designParams
        const holeDesign = designParams.holeDesign
        const wateredBranches = 'wateredBranches' in holeDesign ?
            holeDesign.wateredBranches : []
        wateredBranches.id = 'WateredArea:' + pipePath.id

        const flowRate = irrigationSystem.flowRates.find((flowRate) => flowRate.id === pipePath.flowRateId)
        const rolledUpWateredBranches = wateredBranches.map(
            (branch, i) => RollupAlg.buildRolledUpBranch(branch,
                layoutForPipePath, pipePath, flowRate, i)
        )

        const wateredAreaCount = rolledUpWateredBranches.reduce(
            (accum, b) => accum + b.wateredAreaCount, 0)
        const sidedWateredAreaCount = rolledUpWateredBranches.reduce(
            (accum, b) => accum + ((('sidedWateredAreaCount' in b) && Number.isFinite(b.sidedWateredAreaCount)) ? b.sidedWateredAreaCount : 0), 0)
        const wateredAreaInAcres = rolledUpWateredBranches.reduce((accum, b) => accum + b.wateredAreaInAcres, 0)
        const hasBuildups = rolledUpWateredBranches.some((b) => b.hasBuildups)

        const holeSpacing = (layoutForPipePath.alternatingFurrows === true) ?
            (layoutForPipePath.furrowSpacingInInches + '" -- Alternating Furrows')
            : (layoutForPipePath.furrowSpacingInInches + '" -- Every Furrow')

        const pipeLengthInFeet = Math.round(PipePathAlg.lengthInFeetIncludingJunctions(pipePath.path))

        const areaInSquareInches = wateredAreaInAcres * 6272646.0
        const volumeNeededInCubicInches = areaInSquareInches * layoutForPipePath.grossAppliedInches
        const volumeNeededInGallons = volumeNeededInCubicInches * 0.004329018
        const wateringTimeInHours = (flowRate == null || (!Number.isFinite(flowRate.valueInGpm)) || (flowRate.valueInGpm < 1)) ?
            'N/A' : (volumeNeededInGallons / flowRate.valueInGpm / 60.0)
        const formattedWateringTime = WateringTimeAlg.formattedWateringTimeAndGrossAppliedInches(
            wateredAreaInAcres, flowRate, layoutForPipePath)
        const flowPerAreaInGpm = (layoutForPipePath.mode === FieldMode.Furrows) ?
            ((holeDesign.startingFlowRateInGpm / wateredAreaCount).toFixed(2))
            : ((holeDesign.startingFlowRateInGpm / sidedWateredAreaCount).toFixed(2))

        let jd = undefined
        if (fieldForPipePath.jd) {
            jd = this.buildAssociatedJdObject(fieldForPipePath, jdObjects)
        }

        return {
            id: 'RolledUpHoleDesign:' + pipePath.id,
            jd,
            farm,
            farmName: farm.name,
            irrigationSystem,
            field: fieldForPipePath,
            fieldLayout: layoutForPipePath,
            flowRateInGpm: flowRate.valueInGpm,
            pipePath,
            pipeImage: null,
            wateredAreaInAcres,
            wateredAreaCount, sidedWateredAreaCount,
            holeSpacing,
            rolledUpWateredBranches,
            startingFlowRateInGpm: holeDesign.startingFlowRateInGpm,
            uniformity: (holeDesign.uniformity * 100).toFixed(0) + ' %',
            minHeadPressureInFeet: holeDesign.minHeadPressureInFeet?.toFixed(2) || null,
            maxHeadPressureInFeet: holeDesign.maxHeadPressureInFeet?.toFixed(2) || null,
            flowPerAreaInGpm,
            wateringTimeInHours: wateringTimeInHours.toFixed(2),
            formattedWateringTime,
            pipeLengthInFeet,
            hasBuildups,
            furrowSet
        }
    }

    static buildRolledUpBranch(wateredBranch, fieldLayout, pipePath, flowRate, index) {
        const wateredAreas = wateredBranch.irrigationSteps.filter((s) => s.type === IrrigationStepType.WateredArea)

        const wateredAreaInAcres = wateredAreas.reduce((accum, wa) => {
            return accum + wa.waterableArea.areaInSquareFeet
        }, 0) * 0.00002295682

        const hasBuildups = (fieldLayout.mode === FieldMode.Furrows)
            && (
                (wateredBranch.remainingHeadInFeet > 0)
                || wateredAreas.some((wa) =>
                    Number.isFinite(wa.buildupNeededInFeet) && wa.buildupNeededInFeet > 0)
            )
        const remainingHeadInFeet = hasBuildups ?
            wateredBranch.remainingHeadInFeet : 0

        const rolledUpWateredAreas = RollupAlg.rollupIrrigationSteps(
            wateredBranch.irrigationSteps, { remainingHeadInFeet, ignoreBuildups: (!hasBuildups) })
        if (rolledUpWateredAreas == null) {
            return null
        }

        // add watered area ranges
        {
            let rangeStart = 1
            rolledUpWateredAreas.forEach((wa, i) => {
                if (wa.type !== 'Irrigation') {
                    wa.wateredAreaRange = " "
                    return
                }

                const outletsOnBothSides = (wa.outletsLeft.length > 0) && (wa.outletsRight.length > 0)
                const difference = outletsOnBothSides ?
                    (Math.floor(wa.wateredAreaCount / 2) + (wa.wateredAreaCount % 2))
                    : wa.wateredAreaCount

                if (difference === 1) {
                    wa.wateredAreaRange = rangeStart.toString()
                }
                else {
                    wa.wateredAreaRange = rangeStart.toString()
                        + '-'
                        + (difference + rangeStart - 1).toString()
                }

                rangeStart += difference
            })
        }

        // add sided watered area counts
        {
            rolledUpWateredAreas.forEach((wa, i) => {
                if (wa.type !== 'Irrigation') {
                    wa.sidedWateredAreaCount = null
                    return
                }

                const outletsOnBothSides = (wa.outletsLeft.length > 0) && (wa.outletsRight.length > 0)

                wa.sidedWateredAreaCount = outletsOnBothSides ?
                    (wa.wateredAreaCount / 2) : wa.wateredAreaCount
            })
        }

        const wateredAreaCount = rolledUpWateredAreas.reduce(
            (accum, wa) => accum + (('wateredAreaCount' in wa) ? wa.wateredAreaCount : 0), 0)
        const sidedWateredAreaCount = rolledUpWateredAreas.reduce(
            (accum, wa) => accum + (('sidedWateredAreaCount' in wa) ? wa.sidedWateredAreaCount : 0), 0)

        let lengthOffset = 0
        const formattedRolledUpWateredAreas = rolledUpWateredAreas.map((rowa) => {
            const pipeLengthInFeet = Math.round(rowa.pipeLengthInFeet)

            const isIrrigation = rowa.type === 'Irrigation'
            const holeSizeName = (!isIrrigation) ? null :
                (rowa.outletsLeft +
                    ((rowa.outletsLeft.length > 0 && rowa.outletsRight.length > 0) ? ' ' : '') +
                    rowa.outletsRight)
            const holeSizeColorLeft =
                (isIrrigation && (rowa.outletsLeft.length > 0))
                    ? RollupAlg.getHoleSizeBackGround(rowa.outletsLeft) : null
            const holeSizeColorRight =
                (isIrrigation && (rowa.outletsRight.length > 0))
                    ? RollupAlg.getHoleSizeBackGround(rowa.outletsRight) : null

            const ret = {
                path: rowa.path,
                pipeSize: rowa.type === 'Buildup' ? '' :
                    (rowa.pipeDiameterInInches + 'x' + rowa.pipeThicknessInMilliInches),
                pipeFunction: rowa.type,
                pipeLength: rowa.pipeLengthInFeet === 0 ?
                    lengthOffset : (lengthOffset + ' - ' + (lengthOffset + pipeLengthInFeet)),
                gatesLeft: rowa.outletsLeft,
                gatesRight: rowa.outletsRight,
                holeSize: {
                    name: holeSizeName,
                    colorLeft: holeSizeColorLeft, colorRight: holeSizeColorRight
                },
                wateredAreaCount: rowa.wateredAreaCount,
                sidedWateredAreaCount: rowa.sidedWateredAreaCount,
                wateredAreaRange: rowa.wateredAreaRange,
                buildUp: 0,
                buildupNeededInFeet: (Number.isFinite(rowa.buildupNeededInFeet)
                    && (rowa.buildupNeededInFeet > 0)) ?
                    rowa.buildupNeededInFeet.toFixed(2) : null
            }

            lengthOffset += pipeLengthInFeet

            return ret
        })

        // More Details Rows
        let moreDetailsRows = []
        {
            let currentLengthInFeet = 0
            let currentElevationInFeet = 0
            let currentHeadInFeet = wateredBranch.startingHeadInFeet

            // When dealing with alternating sides, the length offsets get a bit tricky.
            // The first watered area has the length of the pipe run, the second has a 
            // pipe length of zero. So we have to hold on to the length, then append it
            // when the second area is reached.
            let lastPipeLengthInFeet = 0

            moreDetailsRows = wateredBranch.irrigationSteps.reduce((accum, step, i) => {
                if (step.type === IrrigationStepType.Supply) {
                    accum.push({
                        function: 'Supply',
                        holeSize: '',
                        lengthInFeet: currentLengthInFeet.toFixed(0),
                        holeSpacingInFeet: '',
                        elevationInFeet: currentElevationInFeet.toFixed(2),
                        pressure: currentHeadInFeet.toFixed(2),
                        flowInGpm: '',
                        coverage: '',
                        furrowSpacing: '',
                        furrowLength: '',
                        buildUp: ''
                    })

                    currentLengthInFeet += lastPipeLengthInFeet + step.lengthInFeet
                    currentElevationInFeet += step.elevationDifferenceInFeet
                    lastPipeLengthInFeet = 0

                    return accum
                }

                if (('type' in step) && (step.type !== IrrigationStepType.WateredArea)) {
                    return accum
                }

                const wa = step

                if (wa.waterableArea.supplyLengthInFeet > 0) {
                    accum.push({
                        function: 'Supply',
                        holeSize: '',
                        lengthInFeet: currentLengthInFeet.toFixed(0),
                        holeSpacingInFeet: '',
                        elevationInFeet: currentElevationInFeet.toFixed(2),
                        pressure: currentHeadInFeet.toFixed(2),
                        flowInGpm: '',
                        coverage: '',
                        furrowSpacing: '',
                        furrowLength: '',
                        buildUp: ''
                    })

                    currentLengthInFeet += wa.waterableArea.supplyLengthInFeet
                    lastPipeLengthInFeet = 0
                }

                let holeSize = {
                    name: wa.outlets.map((o) => o.name).join(', '),
                    backGround: RollupAlg.getHoleSizeBackGround(wa.outlets[0].name)
                }


                currentElevationInFeet += wa.waterableArea.elevationDifferenceInFeet
                accum.push({
                    function: 'Irrigation',
                    holeSize,
                    lengthInFeet: currentLengthInFeet.toFixed(0),
                    holeSpacingInFeet: (wa.waterableArea.pipeSegment.lengthInFeet - wa.waterableArea.supplyLengthInFeet).toFixed(2),
                    elevationInFeet: currentElevationInFeet.toFixed(2),
                    pressure: wa.headAtOutletsInFeet.toFixed(2),
                    flowInGpm: wa.flowRateUsedInGpm.toFixed(2),
                    coverage: wa.coverage.toFixed(2),
                    furrowSpacing: (fieldLayout.furrowSpacingInInches / 12).toFixed(2),
                    furrowLength: Math.round((fieldLayout.alternatingFurrows === true ? wa.waterableArea.areaInSquareFeet / 2 : wa.waterableArea.areaInSquareFeet) / (fieldLayout.furrowSpacingInInches / 12.0)),
                    buildUp: wa.buildupNeededInFeet != null ? wa.buildupNeededInFeet.toFixed(1) : ""
                })

                currentLengthInFeet += lastPipeLengthInFeet
                lastPipeLengthInFeet = wa.waterableArea.pipeSegment.lengthInFeet - wa.waterableArea.supplyLengthInFeet

                return accum
            }, [])
        }

        return {
            id: 'RolledUpWateredBranch:' + fieldLayout.id + ':' + pipePath.id + ':' + index,
            name: wateredBranch.name,
            wateredAreaInAcres,
            moreDetailsRows,
            wateredAreaCount, sidedWateredAreaCount,
            rolledUpWateredAreas: formattedRolledUpWateredAreas,
            hasBuildups
        }
    }

    static getHoleSizeBackGround(holeSize) {
        let color = null

        if (holeSize != null) {
            if (holeSize.includes('1/4"')) {
                color = '#FFD30D'
            }
            else if (holeSize.includes('5/16"')) {
                color = '#11D1EF'
            }
            else if (holeSize.includes('3/8"')) {
                color = '#B85A19'
            }
            else if (holeSize.includes('7/16"')) {
                color = '#919091'
            }
            else if (holeSize.includes('1/2"')) {
                color = '#CCCACC'
            }
            else if (holeSize.includes('9/16"')) {
                color = '#0F010D'
            }
            else if (holeSize.includes('5/8"')) {
                color = '#D056BC'
            }
            else if (holeSize.includes('11/16"')) {
                color = '#4166AB'
            }
            else if (holeSize.includes('3/4"')) {
                color = '#058C03'
            }
            else if (holeSize.includes('13/16"')) {
                color = '#F5EE0B'
            }
            else if (holeSize.includes('7/8"')) {
                color = '#F99114'
            }
            else if (holeSize.includes('15/16"')) {
                color = '#D10D5A'
            }
            else if (holeSize.includes('1"')) {
                color = 'blue'
            }
        }

        return color
    }

    static rollupIrrigationSteps(irrigationSteps, {
        remainingHeadInFeet = 0, ignoreBuildups = false
    } = {}) {
        const ret = []

        const validHoleSide = (holeSide) => {
            return (holeSide === HoleSide.Left || holeSide === HoleSide.Right)
        }

        const holeSideSet = irrigationSteps.reduce((accum, step) => {
            if (('type' in step) && (step.type !== IrrigationStepType.WateredArea)) {
                return accum
            }

            const waterableArea = step.waterableArea
            if (!waterableArea) {
                return accum
            }

            if ('holeSide' in waterableArea) {
                accum.add(waterableArea.holeSide)
            }
            return accum
        }, new Set())
        const allSameHoleSide = holeSideSet.size === 1

        const buildIrrigationRollup = (wateredArea) => {
            const waterableArea = wateredArea.waterableArea

            if (!waterableArea) {
                throw new Error('No Valid waterableArea in WateredArea')
            }

            if (!validHoleSide(waterableArea.holeSide)) {
                throw new Error('No Valid holeSide in WaterableArea')
            }

            const pipeSegment = waterableArea.pipeSegment

            const supplyLengthInFeet = Number.isFinite(waterableArea.supplyLengthInFeet) ?
                waterableArea.supplyLengthInFeet : 0

            return {
                type: 'Irrigation',
                pipeDiameterInInches: pipeSegment.diameterInInches,
                pipeThicknessInMilliInches: pipeSegment.thicknessInMils,
                pipeLengthInFeet: waterableArea.pipeSegment.lengthInFeet - supplyLengthInFeet,
                primarySide: waterableArea.holeSide,
                lastSide: waterableArea.holeSide,
                outletsLeft: RollupAlg.wateredAreaOutletsString(wateredArea, HoleSide.Left, allSameHoleSide),
                outletsRight: RollupAlg.wateredAreaOutletsString(wateredArea, HoleSide.Right, allSameHoleSide),
                lastGeoJsonPoint: waterableArea.pipeEnterGeoJsonPoint,
                path: [GeoUtil.GeoJson.toLatLngs(waterableArea.pipeEnterGeoJsonPoint)],
                wateredAreaCount: 1,
                sidedWateredAreaCount: null
            }
        }

        const buildSupplyRollupFromWateredArea = (wateredArea) => {
            const waterableArea = wateredArea.waterableArea

            const pipeSegment = waterableArea.pipeSegment
            
            return {
                type: 'Supply',
                pipeDiameterInInches: pipeSegment.diameterInInches,
                pipeThicknessInMilliInches: pipeSegment.thicknessInMils,
                pipeLengthInFeet: waterableArea.supplyLengthInFeet,
                outletsLeft: '',
                outletsRight: '',
                wateredAreaCount: 0, sidedWateredAreaCount: null
            }
        }

        const buildSupplyRollupFromSupply = (supply) => {
            return {
                type: 'Supply',
                pipeDiameterInInches: supply.diameterInInches,
                pipeThicknessInMilliInches: supply.thicknessInMils || 10,
                pipeLengthInFeet: supply.lengthInFeet,
                outletsLeft: '',
                outletsRight: '',
                wateredAreaCount: 0
            }
        }

        const buildBuildupRollup = (relatedPipeSection) => {
            return {
                type: 'Buildup',
                pipeLengthInFeet: 0,
                wateredAreaCount: null,
                buildupNeededInFeet: relatedPipeSection.buildupNeededInFeet
            }
        }

        const addPrimarySideToIrrigationRollup = (rollup, lastRollup) => {
            lastRollup.wateredAreaCount += 1
            lastRollup.pipeLengthInFeet += rollup.pipeLengthInFeet

            lastRollup.lastSide = rollup.primarySide
            lastRollup.path.push(GeoUtil.GeoJson.toLatLngs(rollup.lastGeoJsonPoint))
            lastRollup.lastGeoJsonPoint = rollup.lastGeoJsonPoint
        }

        const addSecondarySideToIrrigationRollup = (rollup, lastRollup) => {
            lastRollup.wateredAreaCount += 1

            if (lastRollup.outletsLeft.length === 0) {
                lastRollup.outletsLeft = rollup.outletsLeft
            }
            else if (lastRollup.outletsRight.length === 0) {
                lastRollup.outletsRight = rollup.outletsRight
            }

            lastRollup.lastSide = rollup.primarySide
        }

        const supplyOnWateredArea = (step) =>
            (Number.isFinite(step?.waterableArea?.supplyLengthInFeet)
            && (step.waterableArea.supplyLengthInFeet > 0))

        const buildupEncountered = (wateredArea) => (!ignoreBuildups)
            && (wateredArea.buildupNeededInFeet > 0)

        const pipeChanged = (a, b) => {
            if (a !== null && b === null) {
                return false
            }

            if (b !== null && a === null) {
                return false
            }

            return a.type !== b.type
                || a.pipeDiameterInInches !== b.pipeDiameterInInches
                || a.pipeThicknessInMilliInches !== b.pipeThicknessInMilliInches
        }

        const isSecondarySideRollup = (rollup, lastRollup) => {
            return lastRollup != null && lastRollup.type === 'Irrigation'
                && rollup.primarySide !== lastRollup.primarySide
                && (
                    rollup.pipeLengthInFeet === 0
                    || GeoUtil.GeoJson.pointsExactlyEqual(
                        rollup.lastGeoJsonPoint,
                        lastRollup.lastGeoJsonPoint)
                )
        }

        const isAddableToPrimarySide = (rollup, lastRollup, nextIrrigationRollup) => {
            // Bail if the new rollup isn't on the primary side.
            if (rollup.primarySide !== lastRollup.primarySide) {
                return false
            }

            const lastRollupHadBothSides = lastRollup != null
                && lastRollup.outletsLeft.length > 0
                && lastRollup.outletsRight.length > 0

            if (nextIrrigationRollup == null) {
                if (lastRollupHadBothSides) {
                    return false
                }
            }

            // If outlets don't match bail.
            if (lastRollup.primarySide === HoleSide.Left) {
                if (rollup.outletsLeft !== lastRollup.outletsLeft) {
                    return false
                }
            }
            else {
                if (rollup.outletsRight !== lastRollup.outletsRight) {
                    return false
                }
            }

            const nextRollupIsSecondarySide = (nextIrrigationRollup != null)
                && isSecondarySideRollup(nextIrrigationRollup, rollup)
            if (nextRollupIsSecondarySide) {
                const lastStepWasOnPrimarySide =
                    (lastRollup.lastSide === rollup.primarySide)
                if (lastStepWasOnPrimarySide) {
                    return false
                }

                const lastRollupSecondaryOutlets = (lastRollup.primarySide === HoleSide.Left) ?
                    lastRollup.outletsRight : lastRollup.outletsLeft
                const nextRollupOutlets = (nextIrrigationRollup.primarySide === HoleSide.Left) ?
                    nextIrrigationRollup.outletsLeft : nextIrrigationRollup.outletsRight
                const nextRollupSecondaryOutletsWillChange = nextRollupOutlets !== lastRollupSecondaryOutlets

                return !nextRollupSecondaryOutletsWillChange
            }

            return lastRollupHadBothSides ? false : true
        }

        const isAddableToSecondarySide = (rollup, lastRollup) => {
            // Secondary side should have no length
            const isSecondarySide = isSecondarySideRollup(rollup, lastRollup)
            if (!isSecondarySide) {
                return false
            }

            // Bail if it's on the same side
            if (rollup.primarySide === lastRollup.primarySide) {
                return false
            }

            // Bail if last side as also the secondary side.
            if (rollup.primarySide === lastRollup.lastSide) {
                return false
            }

            // Add the secondary side outlets for the first time.
            // If outlet sizes match return false.
            if (rollup.primarySide === HoleSide.Left) {
                return (lastRollup.outletsLeft === '')
                    || (lastRollup.outletsLeft === rollup.outletsLeft)
            }
            else {
                return (lastRollup.outletsRight === '')
                    || (lastRollup.outletsRight === rollup.outletsRight)
            }
        }

        const supplySectionPipeMatches = (s1, s2) => {
            return s1.pipeDiameterInInches === s2.pipeDiameterInInches
        }

        try {
            let lastRollup = null

            const pushRollup = (newRollup) => {
                if (lastRollup != null) {
                    ret.push(lastRollup)
                }

                lastRollup = newRollup
            }

            for (let i = 0; i < irrigationSteps.length; i++) {
                const step = irrigationSteps[i]

                if (('type' in step) && (step.type === IrrigationStepType.Supply)) {
                    const supplyRollup = buildSupplyRollupFromSupply(step)
                    pushRollup(supplyRollup)

                    if(step.buildupNeededInFeet) {
                        pushRollup(buildBuildupRollup(step))
                    }

                    continue
                }

                const wateredArea = step

                if (supplyOnWateredArea(wateredArea)) {
                    const supplyRollup = buildSupplyRollupFromWateredArea(wateredArea)
                    if (lastRollup != null && lastRollup.type === 'Supply' && supplySectionPipeMatches(lastRollup, supplyRollup)) {
                        lastRollup.pipeLengthInFeet += supplyRollup.pipeLengthInFeet
                    }
                    else {
                        pushRollup(supplyRollup)
                    }
                }

                // We are now presumed to be operating on an IrrigationStepType.WateredArea

                const rollup = buildIrrigationRollup(wateredArea)

                if (!lastRollup) {
                    lastRollup = rollup
                    continue
                }

                if (buildupEncountered(wateredArea)) {
                    const buildupRollup = buildBuildupRollup(wateredArea)

                    pushRollup(buildupRollup)
                }

                if (pipeChanged(rollup, lastRollup)) {
                    pushRollup(rollup)
                }
                else {
                    const nextStep = (i < (irrigationSteps.length - 1)) ?
                        irrigationSteps[i + 1] : null
                    const nextIrrigationRollup = (nextStep != null) &&
                        (
                            ((!('type' in nextStep))
                                || (nextStep.type === IrrigationStepType.WateredArea))
                            && (!buildupEncountered(nextStep))
                        ) ? buildIrrigationRollup(nextStep) : null

                    if (isAddableToPrimarySide(rollup, lastRollup, nextIrrigationRollup)) {
                        addPrimarySideToIrrigationRollup(rollup, lastRollup)
                    }
                    else if (isAddableToSecondarySide(rollup, lastRollup)) {
                        addSecondarySideToIrrigationRollup(rollup, lastRollup)
                    }
                    else {
                        pushRollup(rollup)
                    }
                }
            }

            if (lastRollup != null) {
                ret.push(lastRollup)
            }
        }
        catch (e) {
            console.error('Pipe Section Rollup Failed: ' + e)
            return null
        }

        if (remainingHeadInFeet > 0) {
            const buildupSection = buildBuildupRollup({ buildupNeededInFeet: remainingHeadInFeet })

            ret.push(buildupSection)
        }

        return ret
    }
}

<template>
    <div id="furrow_menu" v-show="shown">
        <icon-button title="Furrow Direction" label="Furrow Direction" v-on:click="enterEditFurrowDirectionMode()"><img :src="FurrowDirectionIcon" :draggable="false"></icon-button>
        <icon-button title="Edit Sets" label="Sets" class="button_margin_top" v-on:click="enterEditFurrowSetsMode()"><img :src="FurrowSetsMenuIcon" :draggable="false"></icon-button>
    </div>
</template>

<script>
import FurrowDirectionIcon from '@/assets/furrow_direction_icon.svg'
import FurrowSetsMenuIcon from '@/assets/furrow_sets_menu_icon.svg'

import { FieldMode } from '@/store/Dto'

export default {
    data: function() {
        return {
            FurrowDirectionIcon, FurrowSetsMenuIcon
        }
    },
    components: { },
    methods: {
        enterEditFurrowDirectionMode() {
            this.$store.dispatch('enterEditFurrowDirectionMode')
        },
        enterEditFurrowSetsMode() {
            this.$store.dispatch('enterEditFurrowSetsMode')
        }
    },
    computed: {
        shown: (vm) => vm.$store.state.selectedField != null &&  vm.$store.state.selectedFieldLayout != null && vm.$store.state.selectedFieldLayout.mode == FieldMode.Furrows,
    }
}
</script>

<style scoped>
#furrow_menu {
    display: flex;
    flex-direction: column;
}
</style>

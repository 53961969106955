import Vars from '@/Vars'
import * as FormatAlg from '@/util/FormatAlg'
import Printer from  '@/print/Printer'
import PipePathAlg from '../design/PipePathAlg'
import { GeoUtil } from '@/geo/GeoUtil'
import { AllGates, PipeType, IrrigationStepType, getSuggestedPipeDiameter } from '@pp4/design'

const ProductInfo = {
    "PipeTypeRollSizesInFeet": {
        "5x6": 500,
        "7x7": 1320,
        "7x10": 1320,
        "9x7": 1320,
        "9x10": 1320,
        "10x7": 1320,
        "10x9": 1320,
        "10x10": 1320,
        "12x7": 1320,
        "12x9": 1320,
        "12x10": 1320,
        "15x7": 1320,
        "15x9": 1320,
        "15x10": 1320,
        "18x9": 1320,
        "18x10": 1320,
        "22x10": 1320,
        "10xtrans": 660,
        "12xtrans": 660,
        "15xtrans": 660,
        "18xtrans": 660
    }
}

const GateNames = new Set()
AllGates.forEach((g) => {
    GateNames[g.name]
})

export default class ShoppingListPrinter extends Printer {
    buildPipeLengthsByType(pipePath, suggestedPipeType) {
        const ret = {}

        const pipeLineStrings = PipePathAlg.buildPipeLineStringsWithMetadata(pipePath)
        const flattenedPipeLineStrings = pipeLineStrings.flat(1024)

        flattenedPipeLineStrings.forEach((ls) => {
            const pipeType = (('pipeType' in ls.properties) && (ls.properties.pipeType != null)) ? 
                ls.properties.pipeType : suggestedPipeType

            const s = FormatAlg.pipeType(pipeType)
            if(! (s in ret)) {
                ret[s] = 0
            }

            ret[s] += GeoUtil.GeoJson.length(ls, {units: 'feet'})
        })

        return ret
    }

    buildSortedAndFormattedPipeTypesAndLengths(pipeLengthsObject) {
        const keys = Object.keys(pipeLengthsObject)
        keys.sort((a, b) => a < b)

        let ret = []

        for(let key of keys) {
            const pipeLength = pipeLengthsObject[key]

            const rollsPerFoot = (key in ProductInfo.PipeTypeRollSizesInFeet) ?
                ProductInfo.PipeTypeRollSizesInFeet[key] : null
            const pipeRollsString = rollsPerFoot ? 
                (Math.ceil(pipeLength / rollsPerFoot) + ' Roll(s) @ ' + rollsPerFoot + ' ft/roll') : 'N/A'
                
            ret.push({
                pipeType: key, 
                pipeLength,
                pipeTypeString: key,
                pipeLengthString: FormatAlg.pipeLength(pipeLength),
                pipeRollsString
            })
        }

        return ret
    }

    buildPipeLengthsString(pipeLengthsObject) {
        const sortedAndFormatted = this.buildSortedAndFormattedPipeTypesAndLengths(
            pipeLengthsObject)
        return sortedAndFormatted.map((o) => o.pipeTypeString + ': ' + o.pipeLengthString).join('\n')
    }

    mergePipeLengths(target, o) {
        for(let key in o) {
            if(key in target) {
                target[key] += o[key]
            }
            else {
                target[key] = o[key]
            }
        }
    }

    async getPdfContent(farm, vm) {
        let ret = []

        const h = (s, o={alignment: 'left'}) => {
            return this.buildColumnHeader(s, o)
        }

        ret.push({
            text: 'Shopping List for "' + farm.name + '"',
            color: Vars.PrimaryDark
        })

        const fieldsById = {}
        farm.fields.forEach((field) => {
            fieldsById[field.id] = field
        })

        const flowRatesById = {}
        farm.irrigationSystems.forEach((irrigationSystem) => {
            irrigationSystem.flowRates.forEach((flowRate) => {
                flowRatesById[flowRate.id] = flowRate
            })
        })

        const pipePathsTableBody = []
        const summaryPipeLengths = {}        
        let gatesNeeded = 0

        const addPipePath = async (pipePath) => {
            const field = fieldsById[pipePath.fieldId]
            if(! field) {
                return
            }

            const fieldLayout = field.layouts.find((layout) => layout.id === field.activeLayoutId)
            if(! fieldLayout) {
                return
            }

            if(fieldLayout.needsWizard) {
                return
            }

            const flowRate = flowRatesById[pipePath.flowRateId]
            if(! flowRate) {
                return
            }

            const suggestedPipeDiameterInInches = getSuggestedPipeDiameter(flowRate.valueInGpm)
            const suggestedPipeType = new PipeType(suggestedPipeDiameterInInches, 10)

            const onFurrowSet = ('furrowSetId' in pipePath)
            const furrowSetId = pipePath.furrowSetId
            const furrowSet = fieldLayout.furrowSetDetails?.resultingFurrowSets?.find(s => s.id === furrowSetId)
            if(onFurrowSet && (! furrowSet)) {
                return
            }

            const name = field.name +
                (onFurrowSet ? ', ' + furrowSet.name : '')
            
            const imageUrl = vm.$staticImages.buildFieldPipePathUrl(
                fieldLayout, pipePath, {size: '200x200'})
            
            let imageData = ''
            try {
                imageData = await this.getBase64ImageFromURL(imageUrl)
            }
            catch(e) {
                console.error('Failed to Load Field Image: ' + imageUrl)
            }

            const pipeLengths = this.buildPipeLengthsByType(pipePath, suggestedPipeType)
            this.mergePipeLengths(summaryPipeLengths, pipeLengths)

            pipePathsTableBody.push([
                //'theimage',
                {
                    image: imageData,
                    width: 120, height: 120
                },
                 name, this.buildPipeLengthsString(pipeLengths)
            ])

            // update gatesNeeded
            {
                const gateNameMatch = (name) => {
                    return AllGates.some((g) => {
                        return name.indexOf(g.name) >= 0
                    })
                }
                const branches = pipePath.designParams.holeDesign.wateredBranches || []
                branches.forEach((b) => {
                    b.irrigationSteps.forEach((s) => {
                        if(s.type === IrrigationStepType.WateredArea) {
                            s.outlets.forEach((o) => {
                                if(GateNames.has(o.name) || gateNameMatch(o.name)) {
                                    gatesNeeded += o.count
                                }
                            })
                        }
                    })
                })
            }
        }

        for(let irrigationSystem of farm.irrigationSystems) {
            for(let pipePath of irrigationSystem.pipePaths) {
                await addPipePath(pipePath)
            }
        }

        if(! pipePathsTableBody.length) {
            pipePathsTableBody.push([
                'N/A', 'N/A', 'N/A'
            ])
        }

        const pipePathsTable = {
            // pageBreak: onFirstPage ? '' : 'before',
            // margin: [10, 50, 100, 200],
            margin: [0, 8, 0, 0], // left top right bot
            columnGap: 5,
            columns: [
                {
                    width: 515,
                    layout: {
                        fillOpacity: 1.0
                    },
                    table: {
                        dontBreakRows: true,
                        headerRows: 1,
                        widths: [ 'auto', 'auto', '*'],
                        body: pipePathsTableBody
                    }
                }
            ]
        }
        
        const summaryTableBody = []
        {
            summaryTableBody.push([
                h('Type'), h('Feet Needed'), h('Rolls Needed')
            ])
            
            const summaryData = this.buildSortedAndFormattedPipeTypesAndLengths(summaryPipeLengths)
            summaryTableBody.push(... summaryData.map((d) => {
                return [d.pipeTypeString, d.pipeLengthString, d.pipeRollsString]
            }))

            if(gatesNeeded > 0) {
                summaryTableBody.push([
                    h('Blue Gates Needed: ' + gatesNeeded, {colSpan: 3, alignment: 'left'})
                ])
            }
        }
        const summaryTable = {
            margin: [0, 8, 0, 0], // left top right bot
            columnGap: 5,
            columns: [
                {
                    width: 515,
                    layout: {
                        fillOpacity: 1.0
                    },
                    table: {
                        dontBreakRows: true,
                        headerRows: 1,
                        widths: [ 'auto', 'auto', '*'],
                        body: summaryTableBody
                    }
                }
            ]
        }

        ret.push(summaryTable)
        ret.push(pipePathsTable)

        return ret
    }

    async buildPdf(farm, mode, vm) {
        var docDefinition = { 
            pageMargins: this.buildDefaultPageMargins(),
            header: await this.buildDefaultHeader(),
            footer: this.buildFooterCallback(),
            content: await this.getPdfContent(farm, vm),
            images: await this.buildDefaultImages()
        }

        const PdfMake = await Printer.GetPdfMake()
        
        const fileName = farm.name + ' Shopping List.pdf'

        if(mode === 'print') {
            PdfMake.createPdf(docDefinition).print()
        }
        if(mode === 'save') {    
            PdfMake.createPdf(docDefinition).download(fileName)
        }
        if(mode === 'preview') {
            PdfMake.createPdf(docDefinition).open()
        }
    }

    async buildPdfForJdFields(fileName, farm, fieldFilter, mode, vm) {
        const tempFarm = Object.assign({}, farm)
        tempFarm.fields = tempFarm.fields.filter(fieldFilter)

        const docDefinition = { 
            pageMargins: this.buildDefaultPageMargins(),
            header: await this.buildDefaultHeader(),
            footer: this.buildFooterCallback(),
            content: await this.getPdfContent(tempFarm, vm),
            images: await this.buildDefaultImages()
        }

        const PdfMake = await Printer.GetPdfMake()
        
        if(mode === 'print') {
            PdfMake.createPdf(docDefinition).print()
        }
        if(mode === 'save') {    
            PdfMake.createPdf(docDefinition).download(fileName)
        }
        if(mode === 'preview') {
            PdfMake.createPdf(docDefinition).open()
        }
    }
}

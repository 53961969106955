<template>
    <div class='header'>
        <div class="main-content">
            <slot name="float-left"/>
            <slot/>
            <h1 v-if="title">{{ title }}</h1>
            <slot name="float-right"/>
        </div>

        <div style="display: flex; flex-direction: column; flex: 1; justify-content: center; padding-left: 25px;">
            <slot name="bottom"/>
        </div>
        
        <div v-if="descriptionPassed" className="description">
            <slot name="description"/>
        </div>
    </div>
</template>

<script>
export default {
    props: ['title'],
    data: function() {
        return {}
    },
    computed: {
        descriptionPassed: (vm) => vm.$slots.description != null
    },
    methods: {
        clicked: function() {
            this.$parent.$emit(this.event)
        }
    }
};
</script>

<style lang="css" scoped>
.header {
    display: flex;
    flex-direction: column;
    padding: var(--general-padding);
    background-color: var(--bg1);
}

.main-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.description {
    margin-top: var(--general-padding);
    font-size: smaller;
    font-style: italic;
}

@media only screen and (max-width: 720px) {
  h1 {
    font-size: medium;
  }
}
@media only screen and (min-width: 720px) {
  h1 {
      font-size: x-large;
  }
}
</style>

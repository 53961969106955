import { watch, inject, ref, reactive, onMounted, onUnmounted, toRaw } from 'vue'

export function useEvents(mapObject, props, allowedEventProps) {
  let listeners = []

  allowedEventProps.forEach((eventProp) => {
    const callback = props[eventProp]
    if (!callback) {
      return
    }

    const mapEvent = eventProp.slice(2).toLowerCase()

    const listener = mapObject.addListener(mapEvent, props[eventProp])

    listeners.push(listener)
  })

  onUnmounted(() => {
    listeners.forEach(l => l.remove())
    listeners = []
  })
}

export function useGmpEvents(mapObject, props, allowedEventProps, eventEnrichCallback = (e) => e) {
  let listeners = []

  allowedEventProps.forEach((eventProp) => {
    const callback = props[eventProp]
    if (!callback) {
      return
    }

    const wrappedCallback = (e) => {
      callback(eventEnrichCallback(e))
    }

    const mapEvent = "gmp-" + eventProp.slice(2).toLowerCase()

    mapObject.addEventListener(mapEvent, wrappedCallback)

    const listener = {
      remove() {
        mapObject.removeEventListener(mapEvent, wrappedCallback)
      }
    }; // *sigh*

    listeners.push(listener)
  })

  onUnmounted(() => {
    listeners.forEach(l => l.remove())
    listeners = []
  })
}

export function useMaps() {
  const provider = inject('provider')

  const { map, api, googleMapUtils } = provider

  const mapListenersRef = ref([])

  function clearMapListeners() {
    const mapListeners = mapListenersRef.value
    mapListeners.forEach((listener) => {
      api.event.removeListener(listener)
    })

    mapListenersRef.value = []
  }

  function addMapListener(eventName, callback) {
    let listener = api.event.addListener(map, eventName, callback)
    mapListenersRef.value.push(listener)
  }

  // TODO: deprecate this
  function addMapDomListener(eventName, callback) {
    let listener = api.event.addListener(map, eventName, callback)
    mapListenersRef.value.push(listener)
  }

  function doDragHack() {
    // https://stackoverflow.com/questions/75409120/google-maps-losses-mousemove-event-listener-after-simultaneous-dragstart-and-dra

    let dragStartCenter = null

    addMapListener("dragstart", function () {
      dragStartCenter = map.getCenter();
    });

    addMapListener("dragend", function () {
      if (dragStartCenter && dragStartCenter.equals(map.getCenter())) {
        // execute panBy() if the map has not been moved during dragging.
        map.panBy(0, 0);
      }
    });
  }

  function fitBbox(bbox) {
    // sw, ne
    // let w1 = bb1[0]
    // let s1 = bb1[1]
    // let e1 = bb1[2]
    // let n1 = bb1[3]
    const mapsBbox = {
      east: bbox[2],
      west: bbox[0],
      north: bbox[3],
      south: bbox[1]
    }

    map.fitBounds(mapsBbox)
  }

  function centerOnBbox(bbox) {
    const mapsBbox = new api.LatLngBounds({
      east: bbox[2],
      west: bbox[0],
      north: bbox[3],
      south: bbox[1]
    })

    map.setCenter(mapsBbox.getCenter())
  }

  function panToBbox(bbox) {
    const mapsBbox = {
      east: bbox[2],
      west: bbox[0],
      north: bbox[3],
      south: bbox[1]
    }

    map.panToBounds(mapsBbox)
  }

  function removeMapObject(o) {
    if (!o) return

    api.event.clearInstanceListeners(o)
    toRaw(o).setMap(null)
  }

  onMounted(() => {
    clearMapListeners()
  })

  onUnmounted(() => {
    if (mapListenersRef.value?.length) {
      map.setOptions(googleMapUtils.defaultMapOptions())
    }
    clearMapListeners()
  })

  return {
    ...provider,
    clearMapListeners,
    addMapListener,
    addMapDomListener,
    fitBbox,
    centerOnBbox,
    panToBbox,
    removeMapObject,
    doDragHack
  }
}

<template>
    <slot />
</template>

<script setup>
import { useMaps, useGmpEvents, useEvents } from '@/composables/useMaps'

import { watch, reactive, toRaw, onUnmounted } from 'vue';

const props = defineProps({
    location: {
        type: Object,
        required: true
    },
    zIndex: {
        type: Number,
        required: false,
        default: 0
    },
    label: {
        type: String,
        required: false,
        default: null
    },
    labelClass: {
        type: String,
        required: false,
        default: null
    },
    icon: {
        type: Object,
        required: false
    },
    clickable: {
        type: Boolean,
        required: false,
        default: false
    },
    draggable: {
        type: Boolean,
        required: false,
        default: false
    },
    opacity: {
        type: Number,
        required: false,
        default: 1.0
    },

    //events
    onClick: {
        required: false
    },
    onDragstart: {
        required: false
    },
    onDrag: {
        required: false
    },
    onDragend: {
        required: false
    }
})

const data = reactive({
    mapObject: null
})

const { map, api } = useMaps()

function updateMapObject() {
    if (!data.mapObject) {
        let content = null
        if(props.icon) {
            content = document.createElement('img')
            content.classList.add('pp4-marker')
            content.src = props.icon.url
        }
        else {
            content = document.createElement('div')
            content.innerText = props.label
            if(props.labelClass) {
                content.classList.add(props.labelClass)
            }
            else {
                content.classList.add('pp4-marker')
            }
        }

        content.style.userSelect = 'none'

        if(props.opacity !== 1.0) {
            content.style.opacity = props.opacity
        }

        if(props.clickable || props.draggable) {
            content.style.cursor = 'pointer'
        }

        const options = {
            gmpClickable: props.clickable,
            gmpDraggable: props.draggable,
            zIndex: props.zIndex,
            position: props.location,
            content,
            map: toRaw(map)
        }

        data.mapObject  = new api.AdvancedMarkerElement(options)

        // this is so stupid, they have a new event format with no latlng property...
        const eventEnrich = function (e) {
            e.latLng = new maps.api.LatLng(props.location)
            return e
        }
        
        useEvents(data.mapObject, props, ['onClick', 'onDragstart', 'onDrag', 'onDragend'])
        // useGmpEvents(toRaw(data.mapObject), props, ['onClick', 'onDragstart', 'onDrag', 'onDragend'], eventEnrich)
    }
    else {
        const raw = toRaw(data.mapObject)
        // what happened to setOptions() ? :~(
        raw.position = props.location
        raw.gmpClickable = props.clickable
        raw.gmpDraggable = props.draggable
    }
}

onUnmounted(() => {
    if (data.mapObject) {
        toRaw(data.mapObject).setMap(null)
        data.mapObject = null
    }
})

watch(props, updateMapObject, { immediate: true })
</script>

<style lang="css">
/* I do NOT want to have to do this but it looks funny without it... */
gmp-advanced-marker {
    outline: none;
}

.pp4-marker {
    width: 2rem;
    height: 2rem;
    transform: translate(0%, 60%);
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
}

.pp4-marker:focus {
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
}
</style>

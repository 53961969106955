<template>
    <pp4-polyline v-for="o in segments" :key="o.key" :path="o.path" :strokeOpacity="0.6" />

    <template v-if="props.editMode === GeometryEditMode.EditPoints">
        <point-marker v-for="o in pointMarkers" :key="o.key" 
            :location="o.latLng" :snapCallback="props.snapCallback"
            :isLastPoint="false" @change="updatePointMarker(o, $event)">
            <point-marker-drag-preview :prev="o.prev" :next="o.next" lineStyle="polyline" />
        </point-marker>

        <midpoint-marker v-for="o in midpointMarkers" :key="o.key"
            :location="o.latLng" :ll0="o.ll0" :ll1="o.ll1" :snapCallback="props.snapCallback"
            lineStyle="polyline"
            @change="addMidpointAfter($event, o.modelIndex)">
            <point-marker-drag-preview :prev="o.ll0" :next="o.ll1" lineStyle="polyline" />
        </midpoint-marker>
    </template>
    <template v-else>
        <remove-marker v-for="o in removePointMarkers" :key="o.key" :location="o.latLng"
            @click="removePointOrSelf(o.index)" />
    </template>
</template>

<script setup>
import { computed, onMounted, reactive } from 'vue'
import { useStore } from 'vuex'
import * as uuid from 'uuid'

import { GeometryEditMode, LatLng } from '@/store/Dto'
import { GeoUtil } from '@/geo/GeoUtil'

const props = defineProps({
    snapCallback: {
        type: Function,
        required: true
    },
    editMode: {
        type: Number,
        required: true,
        validator: function (value) {
            return (value === GeometryEditMode.EditPoints)
                || (value === GeometryEditMode.RemovePoints)
        }
    }
})

const model = defineModel()
const emit = defineEmits(['change', 'remove'])
const store = useStore()

const data = reactive({
    workingPath: []
})

onMounted(() => {
    data.workingPath = JSON.parse(JSON.stringify(model.value))//model.value.map(o => Object.assign({}, o))
})

const segments = computed(() => {
    const ret = []

    GeoUtil.LatLngs.segmentEach(data.workingPath, (segPath, i) => {
        ret.push({
            path: segPath,
            key: `EditablePath:${uuid.v1()}`,
            index: i
        })
    })

    return ret
})

const midpointMarkers = computed(() => {
    const ret = []

    segments.value.forEach((o, i) => {
        const [ll0, ll1] = o.path

        const lineString = GeoUtil.LatLngs.toLineString([ll0, ll1])

        const lengthInMeters = GeoUtil.GeoJson.length(
            lineString, { units: 'meters' })

        if (lengthInMeters < store.state.preferences.fields.stopShowingEditMidpointsIfLessThanMeters) {
            return
        }

        const midpointOfLineString = GeoUtil.GeoJson.along(
            lineString, lengthInMeters / 2, { units: 'meters' })
        const midpoint = new LatLng(
            midpointOfLineString.geometry.coordinates[1],
            midpointOfLineString.geometry.coordinates[0])

        ret.push({
            ll0, ll1,
            latLng: midpoint, key: `Midpoint:${i}`,
            modelIndex: i
        })
    })

    return ret
})

function addMidpointAfter(newLatLng, index) {
    model.value.splice(index + 1, 0, newLatLng)
}

// Note that pointMarkers uses an index key
const pointMarkers = computed(() => {
    const len = data.workingPath.length

    return data.workingPath.map((latLng, index) => {
        return {
            prev: index > 0 ? data.workingPath[index - 1] : null,
            next: index < (len - 1) ? data.workingPath[index + 1] : null,
            latLng, index, key: `PointMarker:${uuid.v1()}`
        }
    })
})

// Note that removePointMarkers uses a specific key
const removePointMarkers = computed(() => {
    return data.workingPath.map((latLng, index) => {
        return {
            latLng, index, key: `RemovePointMarker:${uuid.v1()}`
        }
    })
})

function updatePointMarker(o, e) {
    model.value.splice(o.index, 1, JSON.parse(JSON.stringify(
        {...data.workingPath[o.index], ...e}
    )))
}

function removePointOrSelf(index) {
    if (model.value.length === 2) {
        emit('remove')
        return
    }

    model.value.splice(index, 1)
    model.value = model.value.slice()
    data.workingPath.splice(index, 1)
}
</script>

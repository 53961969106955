<template>
    <div>
        <!-- LEVEE ELEVATION FORM -->
        <form>
            <fieldset>
                <legend>Levee Elevation</legend>

                <div id="elevation_input">
                    <div class="input_divs" >
                        <label for="levee_height">Levee Height (in)</label>
                        <input 
                            id="levee_height" 
                            type="number" 
                            name="elevation_mode"
                            v-model.number="leveeHeightInInches"
                            class="input">
                    </div>

                    <div class="input_divs" >
                        <label for="avg_fall_per_levee">Avg Fall per Levee (in)</label>
                        <input 
                            id="avg_fall_per_levee" 
                            type="number" 
                            name="elevation_mode"
                            step="0.1"
                            v-model.number="fallPerLeveeInFeet"
                            class="widget_margin_left input">
                    </div>
                </div>
            </fieldset>
        </form>
  </div>
</template>

<script>
export default {
    props: ['fieldLayout'],
    components: {
    },
    data: function() {
        return {
            leveeHeightInInches: 0,
            fallPerLeveeInFeet: 0,
        }
    },
    computed: {
    },
    methods: {
        buildPatches() {
            const ret = []

            ret.push({
                op: 'replace', path: '/fallPerLeveeInInches',
                value: this.fallPerLeveeInFeet
            })

            ret.push({
                op: 'replace', path: '/leveeHeightInInches',
                value: this.leveeHeightInInches
            })
                
            return ret
        }
    },
    mounted: function() {
        this.leveeHeightInInches = this.fieldLayout.leveeHeightInInches
        this.fallPerLeveeInFeet = this.fieldLayout.fallPerLeveeInInches
    }
}
</script>

<style lang="css" scoped>
label {
    white-space: nowrap;
}

#elevation_input {
    display: flex;
    flex-direction: column;
}
.input {
    margin: 5px;
    float: right;
    max-width: 3em;
    margin-right: 20%;
}

.apply_button {
    text-align: center;
}

.input_divs {
    display: flex;
    align-items: center;
    font-weight: bold;
}
</style>

<template>
    <span class="pipe_type">
        <template v-if="!model">
            <span @click="handleSuggestedValueClick" class="suggested_pipe_type">
                {{ suggestedValue.diameterInInches }}x{{ suggestedValue.thicknessInMils }}
            </span>
        </template>
        <template v-else>
            <pipe-type-select v-model="model" />

            <button type="button" @click="clearValue" class="clear_button"
                title="Reset to suggested pipe type (based on flow rate).">
                <span class="fa fa-times fa-1x"></span>
            </button>
        </template>
    </span>
</template>

<script setup>
import PipeTypeSelect from '@/components/maps/irrigation_systems/PipeTypeSelect'

const model = defineModel()

const props = defineProps({
    suggestedValue: {
        type: Object,
        required: true
    }
})


function clearValue() {
    model.value = null
}

function handleSuggestedValueClick() {
    model.value = JSON.parse(JSON.stringify(props.suggestedValue))
}
</script>

<style lang="css" scoped>
@media (max-width: 720px) {
    body {
        font-size: x-small;
    }
}

.pipe_type {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
}

.suggested_pipe_type {
    cursor: pointer;
    color: var(--disabled);
}

.clear_button {
    display: flex;
    margin-left: var(--general-padding);
    padding: 0.25em;
    margin-left: var(--general-padding);
    align-self: center;
    justify-self: center;
}

.clear_button .fa {
    margin: 0px;
}
</style>

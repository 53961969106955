<template>
    <fullpageform 
        @submit="confirm"
        @cancel="cancel"
        :title="title" 
        :submitLabel="confirmLabel"  
        :cancelLabel="cancelLabel">
    </fullpageform>
</template>

<script>
export default {
    props: {
        confirmCallback: {
            type: Function,
            default: null,
            required: true
        },
        cancelCallback: {
            type: Function,
            default: null,
            required: false
        },
        title: {
            type: String,
            default: 'Confirm or Cancel',
            required: false
        },
        confirmLabel: {
            type: String,
            default: 'Confirm',
            required: false
        },
        cancelLabel: {
            type: String,
            default: 'Cancel',
            required: false
        }
    },
    methods: {
        confirm: async function() {
            if(this.confirmCallback) {
                this.confirmCallback()
            }

            this.$dialog.pop()
        },
        cancel: function() {
            if(this.cancelCallback) {
                this.cancelCallback()
            }

            this.$dialog.pop()
        }
    },
    mounted() {
    }

}
</script>

<style>
</style>
<template>
    <div id="help_bubble_forced_margins" :class="computedClasses">
        <div id="help_bubble" ref="help_bubble" v-show="(helpKey != null)" @click="hintClicked" :class="boxComputedClasses">
            <template v-if="dismissed">...</template>
            <template v-else>
                 <!-- Special case that can exist across multiple help keys, maybe introduce as a concept later. -->
                <template v-if="helpParams?.flowRateMissing">
                Tap <icon-button  class="inline_button" @click="invokeHelpParamsCallback('editFieldSettingsCallback')" label="Field Settings"><img :src="SettingsIcon" :draggable="false"/></icon-button> to set flow rate.
                </template>

                <span v-if="helpKey === 'add_field'">
                    Search for the location then tap <icon-button 
                    class="inline_button" label="Add Field" 
                    @click="enterAddFieldMode"
                    faIcon="fa-plus"/> to begin.
                </span>
                <span v-else-if="helpKey === 'begin_add_layout_wiz'">
                    This feature allows you to specify crop rotation <br> without redrawing the field boundary
                </span>
                <span v-else-if="helpKey === 'tap_field_bounds_points'">
                    Tap to add field bounds points. Double tap to add last point.
                </span>
                <span v-else-if="helpKey === 'finish_editing_bounds_furrows'">
                    Finish editing field bounds points then edit furrow direction.
                </span>
                <span v-else-if="helpKey === 'finish_editing_bounds_levees'">
                    Finish editing field bounds points then add or select water source.
                </span>
                <span v-else-if="helpKey === 'add_pipe'">
                    Edit field settings then tap <icon-button  class="inline_button" @click="invokeHelpParamsCallback('enterAddPipeModeCallback')" label="Add Pipe"><img :src="AddIrrigationSystemIcon" :draggable="false"/></icon-button> to place irrigation pipe.
                </span>
                <span v-else-if="helpKey === 'edit_pipe'">
                    Tap end point to add junction or close pipe.
                </span>
                <span v-else-if="helpKey === 'edit_pipe_with_junction_and_no_branches'">
                    Tap <icon-button class="inline_button" @click="invokeHelpParamsCallback('addBranchCallback')" label="Add Branch"><img :src="AddIrrigationSystemIcon" :draggable="false"/></icon-button> to add first branch.
                </span>
                <span v-else-if="helpKey === 'edit_pipe_with_junction_and_one_branch'">
                    Tap <icon-button class="inline_button" @click="invokeHelpParamsCallback('addBranchCallback')" label="Add Branch"><img :src="AddIrrigationSystemIcon" :draggable="false"/></icon-button> to add next branch.
                </span>
                <span v-else-if="helpKey === 'edit_pipe_with_junction_ready_to_save'">
                    <icon-button 
                label="Save" description="Save" class="inline_button" 
                :useSaveIcon="true" @click="invokeHelpParamsCallback('saveCallback')"/> or  <icon-button 
                label="Cancel" description="Cancel" 
                class="inline_button" 
                :useCancelIcon="true" @click="invokeHelpParamsCallback('cancelCallback')"/>
                </span>
                <span v-else-if="helpKey === 'edit_pipe_path_elevation'">
                    <div>Tap points to enter elevation in feet.</div>
                    <div v-if="editPipePathElevationNeedsJunction" style="margin-top: 1rem">
                        Elevation required at junctions.
                    </div>
                </span>
                
                <span v-else-if="helpKey === 'add_junction_branch_1'">
                    Tap junction to begin drawing pipe.
                </span>
                <span v-else-if="helpKey === 'water_source_too_close'">
                    New Water Source Is Too Close To Existing Water Source
                </span>
                <span v-else-if="helpKey === 'add_levee_path_begin'">
                    Tap to begin drawing levee path.
                </span>
                <span v-else-if="helpKey === 'add_levee_path_middle'">
                    Tap next levee point in field. Double tap, or tap field border to end levee path.
                </span>
                <span v-else-if="helpKey === 'add_levee_path_end'">
                    Levee path complete. Tap to begin a new levee. Save or cancel to exit.
                </span>
                <span v-else-if="helpKey === 'add_split_path_begin'">
                    Tap to begin drawing split path.
                </span>
                <span v-else-if="helpKey === 'add_split_path_middle'">
                    Tap next split point in field. Double tap, or tap field border to end split path.
                </span>
                <span v-else-if="helpKey === 'add_split_path_end'">
                    Split path complete. Tap to begin a new split. Save or cancel to exit.
                </span>
                <span v-else-if="helpKey === 'edit_paths'">
                    Drag to move and insert points.
                </span>
                <span v-else-if="helpKey === 'remove_paths'">
                    Tap remove markers to remove paths.
                </span>
                
                <span v-else-if="helpKey === 'edit_levee_paths_select_mode'">
                    Tap <icon-button class="inline_button" @click="invokeHelpParamsCallback('addCallback')" label="Add " faIcon="fa-plus-circle"/>, <icon-button class="inline_button" @click="invokeHelpParamsCallback('editCallback')" label="Edit " faIcon="fa-pencil"/>, or <icon-button class="inline_button" @click="invokeHelpParamsCallback('removeCallback')" label="Remove " faIcon="fa-minus-circle"/>.
                </span>
                <span v-else-if="helpKey === 'edit_levee_paths'">
                    Drag to move and insert points. Save or cancel to exit.
                </span>
                <span v-else-if="helpKey === 'remove_levee_paths'">
                    Tap remove marker to remove paths. Save or cancel to exit.
                </span>
                <span v-else-if="helpKey === 'add_junction_branch_2'">
                    Tap to add pipe points. Double tap to add last point.
                </span>
                <span v-else-if="helpKey === 'set_furrow_direction'">
                    Tap map relative to center of field to set furrow direction.
                </span>
                <span v-else-if="helpKey === 'set_furrow_direction_jd'" style="text-align: left;">
                    FURROW DIRECTION:  <br/>
                    <ul>
                        <li>Tap guidance if present.</li>
                        <li>Tap center of field if no guidance present.</li>
                        <li>Double click arrow to change direction.</li>
                    </ul>
                </span>
                <span v-else-if="helpKey === 'add_surge_valve'">
                    Tap pipe to add a surge valve.
                </span>
                <span v-else-if="helpKey === 'add_tee_1'">
                    Tap pipe to begin tee path.
                </span>
                <span v-else-if="helpKey === 'add_tee_2'">
                    Tap to add additional points. Double tap to add last point.
                </span>
                <span v-else-if="helpKey === 'add_tee_3'">
                    Drag to modify and insert path points then "Save".
                </span>
                <span v-else-if="helpKey === 'add_furrow_set'">
                    Tap to split the field. <br>
                     <template v-if="helpParams.desiredWateringTime < helpParams.wateringTime && helpParams.setsNeeded > helpParams.currentSetAmount">
                    {{helpParams.setsNeeded}} set(s) needed to target the watering time of {{helpParams.desiredWateringTime}} hours.
                    </template>
                </span>
                <span v-else-if="helpKey === 'edit_furrow_sets'">
                    Drag split points to adjust sets. Move split point off the field to remove it. <br> 
                    <template v-if="helpParams.desiredWateringTime < helpParams.wateringTime && helpParams.setsNeeded > helpParams.currentSetAmount">
                    {{helpParams.setsNeeded}} set(s) needed to target the watering time of {{helpParams.desiredWateringTime}} hours.
                    </template>
                </span>
                <span v-else-if="helpKey === 'add_irrigation_system'">
                    Tap location of new water source.
                </span>
                <span v-else-if="helpKey === 'move_new_irrigation_system'">
                    Tap to move water source or continue.
                </span>
                <span v-else-if="helpKey === 'select_water_sources'">
                    Tap location of existing water source.
                </span>
                <span v-else-if="helpKey === 'move_water_sources'">
                    Tap water source to move to a new location
                </span>
                <span v-else-if="helpKey === 'irrigation_system_mode'">
                    Edit water source then tap field or pipe.
                </span>
                <span v-else-if="helpKey === 'begin_add_pipe'">
                    Tap water source to begin drawing pipe.
                </span>
                <span v-else-if="helpKey === 'continue_or_complete_add_pipe'">
                    Tap next point. Double tap to complete or begin junction.
                </span>
                <span v-else-if="helpKey === 'pipe_path_min_points'">
                    No more points can be removed. Cancel edit to remove entire pipe.
                </span>
                <span v-else-if="helpKey === 'valid_hole_design_exists_and_pipe_path_selected'">
                    <template v-if="helpParams.fieldLayout && helpParams.fieldLayout.mode === FieldMode.Furrows">
                        Tap <icon-button  @click="invokeHelpParamsCallback('showPipeSettingsCallback')"  class="inline_button" label="Pipe Settings" title="Set Pipe Specific Properties"><img :src="SettingsIcon" :draggable="false"></icon-button> to add elevation, tap <icon-button  @click="invokeHelpParamsCallback('showHoleDesignCallback')"  class="inline_button" label="Hole Designs" title="View All Hole Designs for Farm" 
                    faIcon="fa-book"/>  or tap the field to edit.
                    </template>
                    <template v-else>
                        Tap field, then <icon-button  @click="invokeHelpParamsCallback('showFieldSettingsCallback')"  class="inline_button" label="Field Settings" title="Field Settings"><img :src="SettingsIcon" :draggable="false"></icon-button> to modify levee settings, or tap <icon-button  @click="invokeHelpParamsCallback('showHoleDesignCallback')"  class="inline_button" label="Hole Designs" title="View All Hole Designs for Farm" 
                    faIcon="fa-book"/>.
                    </template>
                </span>
                <span v-else-if="helpKey === 'invalid_hole_design_exists_and_pipe_path_selected'">
                    Tap red error icon for hole design error details.
                </span>
                <span v-else-if="helpKey === 'valid_hole_design_and_field_selected'">
                    Tap pipe to edit or tap <icon-button class="inline_button" @click="invokeHelpParamsCallback('showHoleDesignCallback')" label="Hole Designs" title="View All Hole Designs for Farm" 
                faIcon="fa-book"/>
                </span>
                <span v-else-if="helpKey === 'invalid_hole_design_and_field_selected'">
                    Hole design has errors. Tap pipe for more info. 
                </span>
                <span v-else-if="helpKey === 'processing_hole_design_and_field_selected'">
                    Hole design is currently processing tap  <icon-button class="inline_button" @click="invokeHelpParamsCallback('showHoleDesignCallback')" label="Hole Designs" title="View All Hole Designs for Farm" 
                faIcon="fa-book" /> to view.
                </span>
            </template>
        </div>
    </div>
</template>

<script>
import { FieldMode } from '@/store/Dto'
import PipePathAlg from '@/design/PipePathAlg'

import SettingsIcon from '@/assets/settings-gears.svg'
import AddIrrigationSystemIcon from '@/assets/add_irrigation_system_icon.png'

export default {
    data: function() {
        return {
            dismissed: false,
            PipePathAlg, FieldMode, AddIrrigationSystemIcon, SettingsIcon
        }
    },
    watch: {
        helpKey: 'showNewKey',
    },
    computed: {
        computedClasses: (vm) => vm.dismissed ? "dismissed" : "",
        boxComputedClasses: vm => {
            if(vm.helpParams?.jd) {
                return "jd"
            }

            return ""
        },
        helpKey: (vm) => vm.$store.state.mapObjects.helpKey,
        helpParams: (vm) => vm.$store.state.mapObjects.helpParams,
        selectedIrrigationSystem: (vm) => vm.$store.state.selectedIrrigationSystem,
        selectedFurrowSet: (vm) => vm.$store.state.selectedFurrowSet,
        editPipePathElevationNeedsJunction: (vm) => {
            if(! ('needs' in vm.helpParams)) {
                return false
            }

            const found = vm.helpParams.needs.find(
                (need) => need === PipePathAlg.InterpolationNeeds.ElevationRequiredAtJunctions)

            return found
        }
    },
    methods: {
        enterAddFieldMode() {
            this.$store.dispatch('enterAddFieldMode')
        },
        hintClicked() {
            this.dismissed =  ! this.dismissed
        },
        showNewKey() {
            this.playIntroAnimation()
        },
        playIntroAnimation() {
            this.$refs.help_bubble.classList.remove('help_bubble_intro_animation')

            if(this.helpKey == null || this.dismissed) {
                return
            }

            setTimeout(() => {
                this.$refs.help_bubble.classList.add('help_bubble_intro_animation')
            }, 1)
        },
        invokeHelpParamsCallback(key) {
            const helpParams = this.$store.state.mapObjects.helpParams
            if(key in helpParams) {
                helpParams[key]()
            }
        }
    }

}
</script>

<style lang="css" scoped>
@keyframes help_bubble_movement {
    from {
        margin-bottom: 20vh;
    }

    to {
        margin-bottom: 25px;
    }
}

@media screen and (min-width: 10px) {
    #help_bubble {
        font-size: x-small;
        padding: var(--general-padding);
        border-radius: var(--general-border-radius);
    }
}

@media screen and (min-width: 800px) {
    #help_bubble {
        font-size: initial;
        padding: var(--general-padding);
        border-radius: var(--general-border-radius);
    }
}

.help_bubble_intro_animation {
    animation-name: help_bubble_movement;
    animation-duration: 350ms;
    animation-timing-function: linear;
}

#help_bubble_forced_margins {
    max-width: 65vw;
    min-width: 65vw;
    display: flex;
    justify-content: center;
}

#help_bubble {
    background: var(--secondary-dark);
    color: var(--primary-lighter);
    max-width: 80vw;
    margin-bottom: 15px;
    text-align: center;
}

#help_bubble.jd {
    background-color: var(--jd-green);
    color: var(--jd-yellow);
}

.dismissed {
    line-height: 0.25em;
    padding: 0em !important;
}

.inline_button {
    margin-top: 0.2em;
    vertical-align: middle;
    display: inline-flex;
}

#info_icon {
    position: absolute;
    top: 5px; 
    left: 5px;
    color: darkgrey;

}

.hint {
    color: white;
    font-size: smaller;
}
</style>

<script>

import Pp4MapObject from '@/components/maps/Pp4MapObject'

import { ZIndexes } from '@/maps/ZIndexes'
import { GeoUtil } from '@/geo/GeoUtil'

import Vars from '@/Vars'

export default {
    extends: Pp4MapObject,
    props: {
        fieldLayout: {
            type: Object,
            required: true
        },
        bearing: {
            type: Number,
            required: false
        },
        zIndex: {
            type: Number,
            required: false,
            default: ZIndexes.FurrowArrows
        },
        dashed: {
            type: Boolean,
            required: false,
            default: false
        },
        selectionStyle: {
            type: String,
            required: true,
            validator: function(value) { return value == "selected" || value == "selectable" || value == "unselectable" }
        }
    },
    watch: {
        fieldLayout: 'buildFurrowArrow',
        bearing: 'buildFurrowArrow',
    },
    computed: {
        selectionStyleProps: (vm) => {
            switch(vm.selectionStyle) {
                case 'selected':
                    return {
                        strokeOpacity: 1.0,
                        strokeColor: Vars.SelectedFieldStrokeColor,
                        strokeWeight: Vars.SelectedFieldStrokeWeight,
                        fillColor: Vars.SelectedFieldFillColor,
                        fillOpacity: Vars.SelectedFieldFillOpacity,
                        zIndex: vm.zIndex
                    }
                case 'selectable':
                    return {
                        strokeOpacity: 1.0,
                        strokeColor: Vars.SelectableFieldStrokeColor,
                        strokeWeight: Vars.SelectableFieldStrokeWeight,
                        fillColor: Vars.SelectableFieldFillColor,
                        fillOpacity: Vars.SelectableFieldFillOpacity,
                        zIndex: vm.zIndex
                    }
                case 'unselectable':
                    return {
                        strokeOpacity: 1.0,
                        strokeColor: Vars.UnselectableFieldStrokeColor,
                        strokeWeight: Vars.UnselectableFieldStrokeWeight,
                        fillColor: Vars.UnselectableFieldFillColor,
                        fillOpacity: Vars.UnselectableFieldFillOpacity,
                        zIndex: vm.zIndex
                    }
            }

            return {}
        },
        fieldGeo: (vm) => GeoUtil.LatLngs.toPolygon(vm.fieldLayout.path),
        arrowLength: (vm) => {
            const bbox = GeoUtil.GeoJson.bbox(vm.fieldGeo)
            return GeoUtil.Coords.distance([bbox[0], bbox[1]], [bbox[2], bbox[3]]) * 0.15
        },
        fieldCenterCoord: (vm) => {
            const fieldCenterGeo = GeoUtil.GeoJson.centerOfMass(vm.fieldGeo)
            return GeoUtil.GeoJson.getCoord(fieldCenterGeo)
        },
        computedBearing: (vm) => vm.bearing != null ? vm.bearing : vm.fieldLayout.furrowBearing
    },
    methods: {
        mapReady() {
            this.buildFurrowArrow()
        },
        buildFurrowArrow() {
            this.clearMapObjects()

            let bearingPoint = GeoUtil.Coords.destination(this.fieldCenterCoord, this.arrowLength, this.computedBearing)
            let bearingCoord = GeoUtil.GeoJson.getCoord(bearingPoint)

            let path = [this.fieldCenterCoord, bearingCoord].map(GeoUtil.Coords.toLatLng)

            const arrowIconOptions = Object.assign({}, this.selectionStyleProps)
            arrowIconOptions.path = this.api.SymbolPath.FORWARD_CLOSED_ARROW
            const ArrowIcon = {
                icon: arrowIconOptions,
                offset: '100%'
            }

            let icons = []

            if(this.dashed) {
                const DashedLineIcon = {
                    icon: {
                        path: 'M 0,-1.5 0,0',
                        strokeOpacity: 1.0,
                        strokeWeight: this.selectionStyleProps.strokeWeight,
                        scale: 4
                    },
                    offset: '0',
                    repeat: '20px'
                }

                icons.push(DashedLineIcon)
            }

            icons.push(ArrowIcon)

            const polylineOptions = Object.assign({}, this.selectionStyleProps)
            polylineOptions.path = path
            polylineOptions.clickable = false
            polylineOptions.icons = icons
            if(this.dashed) {
                polylineOptions.strokeOpacity = 0.0
            }
            
            const polyline = new this.api.Polyline(polylineOptions)

            polyline.setMap(this.map)

            this.mapObjects.push(polyline)
        }
    }
}
</script>

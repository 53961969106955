<template>
    <pp4-dialog v-if="finalWarningMessage">
        <fullpageform :showCancel="true" title="⚠️" cancelLabel="Done" @cancel="dismiss">
            <div class="warning_message_box" @click="dismiss">
                <div class="warning_message"><pre v-html="finalWarningMessage"></pre></div>
            </div>
        </fullpageform>
    </pp4-dialog>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
    message: {
        type: String,
        required: false,
        default: null
    }
})

const $store = useStore()

const finalWarningMessage = computed(() => {
    return props.message || $store?.state?.warningMessage || "No Warning"
})

function dismiss() {
    $store.dispatch('warn', null)
}

</script>

<style lang="css" scoped>
.warning_message {
    background: var(--secondary-dark);
    color: var(--fg0);
    font-weight: bold;
    font-size: large;
    padding: 1em;
    min-width: 10vw;
}
</style>

<template>
    <span class='hint'>
        <slot />
    </span>
</template>

<script>
export default {
    data: function () {
        return {
        }
    },
    mounted() {
    }
}
</script>

<style lang="css" scoped>
.hint {
    font-style: italic;
    color: var(--primary-light);
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>

import * as shapefile from 'shapefile';

import { ShapefileParser } from '@/drag_and_drop/ShapefileParser'
import { FieldMode } from '@/store/Dto'

class FileSorter {
    constructor(store) {
        this.store = store
    }

    readAsLeveeField(fileList, callback) {
        if(fileList.length < 1) {
            callback(null)
            return
        }

        this.readFile(fileList[0], FieldMode.Levees, callback)
    }   

    readAsFurrowField(fileList, callback) {
        if(fileList.length < 1) {
            callback(null)
            return
        }

        this.readFile(fileList[0], FieldMode.Furrows, callback)
    }

    readFiles(fileList, callback) {
        for(let i=0;i < fileList.length;i++) {
            const file = fileList[i]
            this.readFile(file, callback)
        }
    }

    readFile(file, fieldMode, callback) {
        let reader = new FileReader()

        reader.onload = () => {
            console.log("Checking for Shapefile Data: " + file.name)

            this.checkForShapefile(reader.result, file.name, fieldMode, callback)
        }

        reader.onabort = (e) => {
            this.store.dispatch('warn', 'File Read Failed: ' + e)
            callback(null)
        }

        reader.readAsArrayBuffer(file)
    }

    async checkForShapefile(data, name, fieldMode, callback) {
        const handleError = (e) => {
            console.error(e)
            this.store.dispatch('warn', 'No GEO Data Found in "' + name + '"')
            callback(null)
        }

        try {
            const geoJson = await shapefile.read(data)

            let parser = new ShapefileParser(this.store)

            let fieldLayout = null

            if(fieldMode === FieldMode.Furrows) {
                fieldLayout = parser.parseFurrowFieldLayout(geoJson, name)
            } else {
                fieldLayout = parser.parseLeveeFieldLayout(geoJson, name)
            }

            if(fieldLayout == null) {
                handleError('Failed to Find Valid Field Layout in "' + name + '"')
                return
            }

            callback(fieldLayout)
        }
        catch(e) {
            console.error(e)
            handleError('No GEO Data Found in "' + name + '"')
        }
    }
}

export { 
    FileSorter
}

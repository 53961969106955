import { v4 as uuidv4 } from 'uuid';

function Pp4Uuid() {
    return uuidv4()
}

function LeveeHeightInInches(field, pipePath) {
    //need to check if the pipe path has the levee height and average fall per levee otherwise update it from the field. 

    let leveeHeightInInches = null

    if (!field.leveeHeightInInches) {
        throw new Error('No Levee Height on the field')
    }
    else if (!pipePath.leveeHeightInInches) {
        leveeHeightInInches = field.leveeHeightInInches
    }
    else {
        leveeHeightInInches = pipePath.leveeHeightInInches
    }

    return leveeHeightInInches
}

function FallPerLeveeInInches(field, pipePath) {
    //need to check if the pipe path has the levee height and average fall per levee otherwise update it from the field. 
    let fallPerLeveeInInches = null
    if (!field.fallPerLeveeInInches) {
        throw new Error('No fall per levee on field')
    }
    else if (!pipePath.fallPerLeveeInInches) {
        fallPerLeveeInInches = field.fallPerLeveeInInches
    }
    else {
        fallPerLeveeInInches = pipePath.fallPerLeveeInInches
    }
    return fallPerLeveeInInches
}

const ConnectorType = {
    OpenEnd: 14,
    TiedOffEnd: 15
}
Object.freeze(ConnectorType)

class LatLng {
    constructor(latOrParams, lng) {
        if (latOrParams instanceof Object) {
            this.lat = latOrParams.lat
            this.lng = latOrParams.lng

            if ('connectorType' in latOrParams) {
                this.connectorType = latOrParams.connectorType
            }
            if ('elevationInFeet' in latOrParams) {
                this.elevationInFeet = latOrParams.elevationInFeet
            }
            if ('interpolated' in latOrParams) {
                this.interpolated = latOrParams.interpolated
            }
            if ('type' in latOrParams) {
                this.type = latOrParams.type
            }
        }
        else {
            this.lat = latOrParams
            this.lng = lng
        }

        // connectorType
        // elevationInFeet
    }
}

class Junction extends LatLng {
    constructor(id, type, latLng, paths) {
        super(latLng)

        this.id = id
        this.type = type
        this.paths = paths

        if (!this.id) { throw new TypeError('Missing Param id') }
        if (!this.type) { throw new TypeError('Missing Param type') }
        if (!this.paths) { throw new TypeError('Missing Param paths') }


        paths.forEach((path) => {
            if (path.length < 1) {
                throw new Error('Invalid Path on Junction of Length: ' + path.length)
            }

            if (path[0].lat != latLng.lat || path[0].lng != latLng.lng) {
                throw new Error('Mismatched First Path Point on Junction: ' + JSON.stringify(path) + ' != ' + JSON.stringify(latLng))
            }
        })
    }
}

class InlineTee extends Junction {
    constructor(params) {
        super(params.id, 'inline_tee', params.latLng, params.paths)
    }
}

class SurgeValve extends Junction {
    constructor(params) {
        super(params.id, 'surge_valve', params.latLng, params.paths)
    }
}

class FlowRate {
    constructor(name, val) {
        this.id = Pp4Uuid()
        this.name = name
        this.valueInGpm = val
    }
}

class JohnDeerePipePathSource {
    constructor(flagId, flagCategoryId) {
        this.type = 'jd'
        this.flagId = flagId
        this.flagCategoryId = flagCategoryId
        this.lastSynced = new Date()
    }
}

class PipePath {
    constructor(params) {
        this.id = params.id
        this.fieldId = params.fieldId
        this.fieldLayoutId = params.fieldLayoutId
        this.flowRateId = params.flowRateId
        this.path = params.path
        this.waterBothSides = false
        this.designParams = null
        this.availableHoleSizes = [] // farmer only has these hole sizes, empty list assumes all can be tried
        this.minHeadInFeet = null
        this.maxHeadInFeet = null
        this.gatesEnabled = false
        // Let's get this back to the Field level
        // this.fallPerLeveeInInches = 2.4 
        // this.leveeHeightInInches = 24

        if (params.furrowSetId) {
            this.furrowSetId = params.furrowSetId
        }
        if (params.waterBothSides) {
            this.waterBothSides = params.waterBothSides
        }

        if (params.availableHoleSizes) {
            this.availableHoleSizes = params.availableHoleSizes
        }

        if (params.minHeadInFeet) {
            this.minHeadInFeet = params.minHeadInFeet
        }

        if (params.maxHeadInFeet) {
            this.maxHeadInFeet = params.maxHeadInFeet
        }

        if (!this.id) { throw new TypeError('Missing Param id: ' + JSON.stringify(params)) }
        if (!this.fieldId) { throw new TypeError('Missing Param fieldId') }
        if (!this.fieldId) { throw new TypeError('Missing Param fieldLayoutId') }
        if (!this.path) { throw new TypeError('Missing Param path') }
        if (!this.flowRateId) { throw new TypeError('Missing Param flowRateId') }
    }
}

class IrrigationSystem {
    constructor() {
        this.id = Pp4Uuid()
        this.name = 'Water Source'
        this.waterSourceLocation = null
        this.pipePaths = []
        this.flowRates = []
        this.history = []
    }
}

const FieldMode = {
    Furrows: 1,
    Levees: 2
}
Object.freeze(FieldMode)

const Direction = {
    Left: 1,
    Right: 2
}
Object.freeze(Direction)

class FurrowSet {
    constructor(id, name, path) {
        this.id = id
        this.name = name
        this.path = path
    }
}

class FurrowSetDetails {
    constructor() {
        this.splitPointLatLngs = []
        // this.splitPaths = undefined // only added through jd workflows...
        this.splitPaths = []
        this.resultingFurrowSets = []
    }
}
class JohnDeereFieldSource {
    constructor(orgId, fieldId, boundaryId) {
        this.type = 'jd'
        this.orgId = orgId
        this.fieldId = fieldId
        this.boundaryId = boundaryId
        this.lastSynced = new Date()
    }
}
class Field {
    constructor(id, name, firstLayout) {
        this.id = id
        this.name = name
        this.layouts = [firstLayout] // we will nest all the designs in the field
        this.activeLayoutId = firstLayout.id

        if (firstLayout instanceof Array) {
            throw new Error('Field invoked with old constructor')
        }

        if (!this.id) { throw new TypeError('Missing Param id') }

        //this.source = null
    }
}

class FieldLayout { // this should allow us to make independant irrigation designs and then relate them to the fields
    constructor(id, mode, path) {
        this.id = id
        this.mode = mode
        this.name = ""
        this.soilType = null
        this.primaryFlowRateId = null
        this.grossAppliedInches = 3
        this.path = path
        this.desiredWateringTimeInHours = 24
        this.wateringEvents = null

        // Furrow defaults
        this.furrowBearing = 0.0
        this.alternatingFurrows = false
        this.perpendicularSets = false //this is new, in older instances will be undfined
        this.furrowSpacingInInches = 38
        this.furrowSetDetails = new FurrowSetDetails()

        // Levee defaults
        this.leveePaths = []
        this.fallPerLeveeInInches = 2.4
        this.leveeHeightInInches = 24
        this.fromShapeFile = false
    }
}

class FieldElevationMap {
    // coords is array of arrays of 3 points ([[lng, lat, elevation], ...])
    constructor(fieldId, coords, sources) {
        this.fieldId = fieldId
        this.coords = coords
        this.sources = sources
    }
}

// John Deere Farm Source
class JohnDeereFarmSource {
    constructor(organizationId, farmId) {
        this.type = 'jd'
        this.organizationId = organizationId
        this.farmId = farmId
        this.lastSynced = new Date()
    }
}

class Farm {
    constructor(id, name, fields) {
        this.id = id
        this.name = name
        this.fields = fields
        this.history = []
        this.irrigationSystems = []
        //this.source = null
    }
}

class WateringEvent {
    constructor(id, start, stop, duration, gallons, moistureMethod, measurement,
        farmId, fieldId, fieldLayoutId, furrowSetId, irrigationSystemId, pipePathId) {
        this.id = id
        this.start = start
        this.stop = stop
        this.duration = duration
        this.gallons = gallons
        this.moistureMethod = moistureMethod
        this.measurement = measurement

        this.farmId = farmId
        this.fieldId = fieldId
        this.fieldLayoutId = fieldLayoutId
        this.furrowSetId = furrowSetId
        this.irrigationSystemId = irrigationSystemId
        this.pipePathId = pipePathId
    }
}

const GeometryEditMode = {
    None: 1,
    EditPoints: 2,
    RemovePoints: 3
}
Object.freeze(GeometryEditMode)

const PipePathEditMode = Object.assign({}, GeometryEditMode)
PipePathEditMode.AddJunctionBranch = 14
PipePathEditMode.EditElevation = 15
Object.freeze(PipePathEditMode)

export {
    Pp4Uuid,
    PipePath,
    InlineTee,
    SurgeValve,
    IrrigationSystem,
    LeveeHeightInInches,
    FallPerLeveeInInches,
    Farm,
    Field,
    FieldLayout,
    JohnDeereFarmSource, JohnDeereFieldSource,
    FlowRate,
    FieldMode,
    Direction,
    LatLng,
    GeometryEditMode, PipePathEditMode,
    FurrowSet, FurrowSetDetails,
    WateringEvent,
    ConnectorType
}

<template>
    <div>
      <template v-if="! done">
        <pp4-polyline
          v-if="latLngs.length >= 2"
          :path="latLngs"/>
      </template>

      <pp4-polyline
        v-if="previewPath"
        :path="previewPath"
        :strokeColor="Vars.TentativeFieldStrokeColor"/>
    </div>
</template>

<script>
// import CrosshairIcon from '@/assets/crosshair.svg'
import Pp4MapObject from '@/components/maps/Pp4MapObject'
import { GeoUtil } from '@/geo/GeoUtil'
import Pp4Polyline from '@/components/maps/Polyline'
import { LatLng } from '@/store/Dto'

import Vars from '@/Vars'

export default {
  extends: Pp4MapObject,
  components: {
    Pp4Polyline
  },
  data:
    function() {
      return {
        Vars,
        done: false,
        previewLatLng: null,
        latLngs: [],
        markers: [],
        overlay: null
      }
  },
  computed: {
    previewPath: (vm) => {
      if(! vm.previewLatLng) {
        return null
      }

      if(vm.latLngs.length === 0) {
        return null
      }

      const ret = [
        vm.latLngs[vm.latLngs.length - 1], 
        vm.previewLatLng
      ]

      return ret
    }
  },
  beforeUnmount() {
    this.map.setOptions(this.googleMapUtils.defaultMapOptions())
  },
  methods: {
    // initCrosshair() {
    //   const crosshair = document.createElement('img')
    //   crosshair.setAttribute('id', 'flerp')
    //   crosshair.setAttribute('src', CrosshairIcon)
    //   crosshair.classList.add(this.$style['crosshair'])

    //   // this is a bit odd, we have an overlay element but we're gonna just always leave it at the center of the screen
    //   const throwawayLatLng = {lat: 0, lng: 0}

    //   this.overlay = this.interpretationFactory.buildElementOverlay(
    //     crosshair, throwawayLatLng, 
    //     {elementClass: this.$style['crosshair'], pane: 'floatPane'}
    //   )

    //   console.log("! overlay? " + this.overlay)

    //   this.overlay.setMap(this.map)

    //   this.mapObjects.push(this.overlay)
    // },
    markAsDone() {
      this.$emit('poly-ready', this.latLngs) 
      this.done = true
    },
    onPoint(ll, targetLatLng) {
      if(this.latLngs.length < 1) {
        return false
      }

      let snapThreshold = 
        this.$store.state.preferences.fields.snapThresholdInMeters
        * this.$store.state.OneMeterInLatLng

      if(this.zoomLevel < 17) {
        const factor = 17 - this.zoomLevel
        const adjustment = Math.pow(2, factor)
        snapThreshold = snapThreshold * adjustment
      }

      return GeoUtil.LatLngs.pointsEqual(
        targetLatLng, ll, snapThreshold)
    },
    onFirstPoint(ll) {
      if(this.latLngs.length < 1) {
        return false
      }

      return this.onPoint(ll, this.latLngs[0])
    },
    onLastPoint(ll) {
      if(this.latLngs.length < 1) {
        return false
      }

      return this.onPoint(ll, this.latLngs[this.latLngs.length - 1])
    },
    snapToFirstPointIfNecessary(ll) {
      if(this.latLngs.length < 3) {
        return ll
      }

      return this.onFirstPoint(ll) ?
        new LatLng(this.latLngs[0]) : ll
    },
    mapReady() {
      this.map.setOptions({
        draggableCursor: 'crosshair',
        disableDoubleClickZoom: true
      })

      this.addMapListener('mousemove', (e) => {
        if(this.done) {
          return
        }

        this.previewLatLng = this.snapToFirstPointIfNecessary(
          {lat: e.latLng.lat(), lng: e.latLng.lng()})
      })

      this.addMapListener('click', (e) => {
        this.previewLatLng = null
        if(this.done) {
          return
        }

        const ll = this.snapToFirstPointIfNecessary(
          {lat: e.latLng.lat(), lng: e.latLng.lng()})

        if((this.latLngs.length >= 3) && this.onFirstPoint(ll)) {
          this.markAsDone()
          return
        }
        
        this.latLngs.push(ll)

        const marker = this.interpretationFactory.buildBoundsMarker(ll)
        this.markers.push(marker)
        this.mapObjects.push(marker)
      })

      this.addMapListener('dblclick', (e) => {
        if(this.done) {
          return
        }

        if(this.latLngs.length < 2) {
          return
        }

        const ll = {lat: e.latLng.lat(), lng: e.latLng.lng()}

        if(this.latLngs.length >= 3)  {
          if(this.onFirstPoint(ll) || this.onLastPoint(ll)) {
            this.markAsDone()
            return false
          }
        }

        this.latLngs.push(ll)
        this.markAsDone()

        return false
      })
    }
  }
}
</script>

<style lang="css" module>
.crosshair {
    left: -50%;
    right: -50%;
    background: green;
}
</style>

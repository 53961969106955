<template>
    <template v-if="furrowSet">
      <furrow-set v-if="furrowSet" :fieldLayout="fieldLayout" :furrowSet="furrowSet" selectionStyle="selected"
        :clickable="false" :showLabel="true" />
    </template>
    <template v-else>
      <pp4-field :field="field" :fieldLayout="fieldLayout" ref="field" :clickable="false" selectionStyle="selected"
        :showLeveePaths="true" :showLabel="false" />
    </template>

    <edit-pipe-path-path v-if="modifiedPath?.length" v-model:editMode="editMode" v-model:path="modifiedPath"
      @endclick="showEndOfPipeSelectDialog" :snapCallback="finalSnapCallback"
      :snapAndFillCallback="finalSnapAndFillCallback" />

    <end-of-pipe-select-dialog v-if="endPointToEdit" @done="clearEndPointEdit" @cancel="clearEndPointEdit"
      v-model="endPointToEdit" />

    <context-menu>
      <toggle-button v-if="!elevationModeHidden" description="Add Elevation Manually" label="Elevation"
        :disabled="elevationModeDisabled" :active="elevationModeActive" faIcon="fa-area-chart"
        @click="toggleElevationMode" />

      <icon-button v-if="(!elevationModeHidden) && hasElevationData" label="Clear Elevation"
        :class="elevationModeHidden ? '' : 'button_margin_top'" description="Clear elevation data"
        :disabled="elevationModeDisabled" faIcon="fa-minus-circle" faColorClass="cancel_red" @click="clearElevation" />

      <toggle-button description="Toggle Remove Points Mode" label="Remove Points" :disabled="removeModeDisabled"
        :active="removeModeActive" :class="elevationModeHidden ? '' : 'button_margin_top'"
        v-on:click="toggleRemoveMode()">
        <img :src="RemoveLinePointsIcon" draggable="false" />
      </toggle-button>
      <toggle-button label="Snap" :disabled="snapDisabled" :active="snapActive" description="Snap Points to Field Bounds"
        class="button_margin_top" @click="toggleSnap()">
        <img :src="SnapIcon" draggable="false" />
      </toggle-button>

      <toggle-button label="Add Branch" class="button_group_margin_top" :active="addJunctionBranchModeActive"
        description="Add Branch -- Junction Required at End of Pipe" :disabled="addJunctionBranchDisabled"
        @click="toggleAddJunctionBranch()"><img :src="AddIrrigationSystemIcon" draggable="false" /></toggle-button>
      <icon-button label="Save" :disabled="saveDisabled" class="button_group_margin_top" description="Save"
        :useSaveIcon="true" @click="save()" />
      <icon-button label="Cancel" class="button_margin_top" description="Cancel" :useCancelIcon="true"
        v-on:click="cancel()" />
    </context-menu>

    <loading v-if="saving" label="Saving..." />
</template>

<script>
import PipePath from "@/components/maps/irrigation_systems/PipePath";
import PipePathAlg from "@/design/PipePathAlg";
import Pp4Field from "@/components/maps/Pp4Field";
import FurrowSet from "@/components/maps/FurrowSet";

import { PipePathEditMode, FieldMode } from "@/store/Dto";
import { GeoUtil } from "@/geo/GeoUtil";
import {
  BuildSnapCallback,
  BuildSnapAndFillCallback,
} from "@/geo/PipeSnapAlg";

import AddInlineTeeIcon from "@/assets/add_inline_tee_icon.png";
import RemoveLinePointsIcon from "@/assets/remove_line_points_icon.svg";
import AddIrrigationSystemIcon from "@/assets/add_irrigation_system_icon.png";
import SnapIcon from "@/assets/snap_icon.svg";

export default {
  components: {
    PipePath,
    Pp4Field,
    FurrowSet
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    fieldLayout: {
      type: Object,
      required: true
    },
    furrowSet: {
      type: Object,
      required: false,
      default: null,
    },
    pipePath: {
      type: Object,
      required: true,
    },
    irrigationSystem: {
      type: Object,
      required: true,
    }
  },
  data: function () {
    return {
      RemoveLinePointsIcon,
      AddInlineTeeIcon,
      AddIrrigationSystemIcon,
      SnapIcon,
      modifiedPath: null,
      activeMarker: null,
      editMode: PipePathEditMode.EditPoints,
      snapActive: true,
      snapAndFillCallback: null,
      elevationOverlay: true,
      endPointToEdit: null,
      saving: false
    };
  },
  watch: {
    // modifiedPath: 'updateHelpKey',
    editMode: 'updateHelpKey',
    removeModePossible: 'switchToEditModeIfNecessary'
  },
  computed: {
    modifiedPipePath: (vm) => {
      if (!vm.modifiedPath) {
        return vm.pipePath;
      }

      const ret = Object.assign({}, vm.pipePath);

      ret.path = vm.modifiedPath;

      return ret;
    },
    elevationModeHidden: (vm) => {
      return vm.fieldLayout.mode === FieldMode.Levees
    },
    elevationModeDisabled: (vm) => {
      return (vm.editMode !== PipePathEditMode.EditPoints)
        && (vm.editMode !== PipePathEditMode.EditElevation)
    },
    elevationModeActive: (vm) => {
      return vm.editMode === PipePathEditMode.EditElevation
    },
    removeModePossible: (vm) => PipePathAlg.removeModePossible(
      vm.modifiedPath ? vm.modifiedPath : vm.pipePath.path),
    removeModeDisabled: (vm) => {
      if ((vm.editMode !== PipePathEditMode.RemovePoints)
        && (vm.editMode !== PipePathEditMode.EditPoints)) {
        return true
      }

      return !vm.removeModePossible
    },
    removeModeActive: (vm) => {
      return vm.editMode === PipePathEditMode.RemovePoints;
    },
    addJunctionBranchModeActive: vm => {
      return vm.editMode === PipePathEditMode.AddJunctionBranch
    },
    finalSnapCallback(vm) {
      return vm.snapActive ? vm.snapCallback : null;
    },
    finalSnapAndFillCallback(vm) {
      return vm.snapActive ? vm.snapAndFillCallback : null;
    },
    snapDisabled(vm) {
      return (vm.editMode === PipePathEditMode.RemovePoints)
        || (vm.editMode === PipePathEditMode.None)
        || (vm.editMode === PipePathEditMode.EditElevation)
    },
    saveDisabled: (vm) => {
      if (vm.editMode === PipePathEditMode.AddJunctionBranch) {
        return true;
      }

      return !(vm.modifiedPath != null && vm.modifiedPath.length >= 2);
    },
    addJunctionBranchDisabled: (vm) => {
      if (vm.editMode === PipePathEditMode.EditElevation) {
        return true
      }

      let path = vm.modifiedPath ? vm.modifiedPath : vm.pipePath.path

      const lastLatLng = path[path.length - 1]

      return !PipePathAlg.isJunction(lastLatLng)
    },
    hasElevationData: (vm) => {
      let ret = false

      const path = vm?.pipePath?.path || []
      PipePathAlg.traversePathBranches(path, (branchPath) => {
        if (branchPath.some((ll) => Number.isFinite(ll.elevationInFeet))) {
          ret = true
        }
      })

      return ret
    },
    fieldGeo: (vm) => GeoUtil.LatLngs.toPolygon(vm.fieldLayout.path),
    furrowSetGeo: (vm) => vm.furrowSet ? GeoUtil.LatLngs.toPolygon(vm.furrowSet.path) : null,
    latLngsWithElevation: vm => vm.$store.state.mapObjects.debug.latLngsWithElevation,
    snapThresholdInMeters: (vm) => vm.$store.state.preferences.pipe.snapThresholdInMeters,
    offsetFromFieldInterior: (vm) => vm.$store.state.preferences.pipe.snapOffsetFromFieldInteriorInMeters,
    snapCallback: vm => {
      const snapGeos = []
      if (vm.furrowSetGeo) {
        snapGeos.push(vm.furrowSetGeo)
      }

      snapGeos.push(vm.fieldGeo)

      return BuildSnapCallback(snapGeos, vm.latLngsWithElevation,
        vm.snapThresholdInMeters, vm.offsetFromFieldInterior)
    },
  },
  methods: {
    switchToEditModeIfNecessary() {
      if (this.editMode !== PipePathEditMode.RemovePoints) {
        return
      }

      if (!this.removeModePossible) {
        this.editMode = PipePathEditMode.EditPoints
      }
    },
    updateHelpKey() {
      if (this.editMode === PipePathEditMode.RemovePoints) {
        this.$store.dispatch("mapObjects/setHelpKey", null);
        return;
      }

      if (this.editMode === PipePathEditMode.EditElevation) {
        // this is handled inside PipePath.vue
        // this.$store.dispatch("mapObjects/setHelpKey", 'edit_pipe_path_elevation');
        return;
      }

      if (this.editMode !== PipePathEditMode.EditPoints) {
        this.$store.dispatch("mapObjects/setHelpKey", null);
        return;
      }

      if (this.editMode === PipePathEditMode.AddJunctionBranch) {
        this.$store.dispatch('mapObjects/setHelpKey', 'add_junction_branch_1')
        data.selectedJunction = null // why editing stuff when updating help key?
        data.junctionPath = []
      }

      if ((this.modifiedPath === null) || (this.modifiedPath.length < 1)) {
        this.$store.dispatch("mapObjects/setHelpKey", 'edit_pipe')
        return;
      }

      const lastLatLng = this.modifiedPath[this.modifiedPath.length - 1];
      const endsWithJunction = PipePathAlg.isJunction(lastLatLng);

      if (!endsWithJunction) {
        this.$store.dispatch("mapObjects/setHelpKey", "edit_pipe");
        return;
      }

      const branches = PipePathAlg.junctionBranches(lastLatLng);
      const branchCount = branches.length;

      const addBranchCallback = () => {
        this.toggleAddJunctionBranch();
      };

      if (branchCount === 0) {
        this.$store.dispatch("mapObjects/setHelpKeyAndParams", {
          key: "edit_pipe_with_junction_and_no_branches",
          params: { branchCount, addBranchCallback },
        });
      } else if (branchCount === 1) {
        this.$store.dispatch("mapObjects/setHelpKeyAndParams", {
          key: "edit_pipe_with_junction_and_one_branch",
          params: { branchCount, addBranchCallback },
        });
      }
    },
    toggleSnap() {
      this.snapActive = !this.snapActive;
    },
    toggleElevationMode() {
      if (this.editMode === PipePathEditMode.EditElevation) {
        this.editMode = PipePathEditMode.EditPoints
      }
      else {
        this.editMode = PipePathEditMode.EditElevation
      }
      //this.elevationOverlay = !this.elevationOverlay
    },
    toggleRemoveMode() {
      if (this.editMode === PipePathEditMode.RemovePoints) {
        this.editMode = PipePathEditMode.EditPoints;
      } else {
        this.editMode = PipePathEditMode.RemovePoints;
      }
    },
    junctionBranchAdded() {
      this.editMode = PipePathEditMode.EditPoints;
    },
    toggleAddJunctionBranch() {
      if (this.editMode !== PipePathEditMode.AddJunctionBranch) {
        this.editMode = PipePathEditMode.AddJunctionBranch;
      } else {
        this.editMode = PipePathEditMode.EditPoints;
      }
    },
    async save() {
      try {
        this.saving = true

        await this.$store.dispatch(
          "patchIrrigationSystemPipePath",
          {
            pipePath: this.pipePath,
            patch: {
              op: 'replace',
              path: '/path',
              value: this.modifiedPath
            }
          }
        );
        await this.$store.dispatch("returnToSelectedIrrigationSystemPipePath");
      }
      catch (e) {
        this.$store.dispatch('warn', 'Save Failed -- Please Retry in a Moment or Refresh Page')
      }
      finally {
        this.saving = false
      }
    },
    cancel() {
      this.$store.dispatch("returnToSelectedIrrigationSystemPipePath");
    },
    clearElevation() {
      PipePathAlg.traversePath(this.modifiedPath, (ll) => {
        delete ll.elevationInFeet
        delete ll.interpolated
      })

      // this.modifiedPath = this.modifiedPath.slice()
    },
    showEndOfPipeSelectDialog(o) {
      const { branchPath, latLng } = PipePathAlg.lookupFromTraversalIndexes(
        this.modifiedPath, o.traversalIndexes)

      this.endPointToEdit = latLng
    },
    clearEndPointEdit() {
      this.endPointToEdit = null
    }
  },
  mounted() {
    // Deep copy needed because we'll be modifying stuff within this
    this.modifiedPath = JSON.parse(JSON.stringify(this.pipePath.path))

    const offsetFromFieldInterior = this.$store.state.preferences.pipe
      .snapOffsetFromFieldInteriorInMeters;
    const fieldGeo = GeoUtil.LatLngs.toPolygon(this.fieldLayout.path);
    const snapGeos = [fieldGeo];
    if (this.furrowSet) {
      snapGeos.push(GeoUtil.LatLngs.toPolygon(this.furrowSet.path));
    }

    const latLngsWithElevation = this.$store.state.mapObjects.debug.latLngsWithElevation
    if (latLngsWithElevation?.length >= 2) {
      const ls = GeoUtil.LatLngs.toLineString(latLngsWithElevation);
      snapGeos.push(ls)
    }

    this.snapAndFillCallback = BuildSnapAndFillCallback(
      snapGeos, latLngsWithElevation,
      offsetFromFieldInterior
    )

    const traversalIndexes = this.$store.state.mapObjects
      .selectedPipePathEndPointTraversalIndexes;
    if (traversalIndexes) {
      this.showEndOfPipeSelectDialog({ traversalIndexes })
    }

    this.updateHelpKey()
  },
};
</script>

<style lang="css"></style>

import { FieldMode } from '@/store/Dto'

export default class HoleDesignErrors {
    buildErrorMessage(fieldLayout, pipePath, error) {
        if(error == null || error.code == null){
            return 'N/A'
        }
    
        const code = error.code
        const index = error.index
        const validIndex = Number.isFinite(index)
        const smallerHoleSizesAvailable = pipePath.availableHoleSizes.length > 0 //TODO: this is not perfect, user could have selected smallest hole size
        const areaName = (fieldLayout.mode === FieldMode.Furrows) ? 'Furrow' : 'Paddy'
        
        if(code === 'insufficient_head' && validIndex && (index === 0)) {
            return this.buildSupplyLineFrictionLossErrorMessage(error)
        }
        if(code === 'insufficient_flow') {
            return this.buildInsufficientFlowErrorMessage(smallerHoleSizesAvailable, index, areaName)
        }
        if(code === 'pressure_range_broken'){
            return this.buildPressureRangeBrokenErrorMessage(index, areaName)
        }
        if(code === 'no_hole_sizes_selected'){
            return this.buildNoHoleSizesErrorMessage()
        }
        if(code === 'calculation_exception'){
            return this.buildCalculationException(error)
        }
        if(code === 'check_furrow_direction'){
            return this.buildCheckFurrowDirectionException(error)
        }
            
        return '<pre>' + JSON.stringify(error, null, 2) + '</pre>'
    }

    buildCalculationException(error) {
        let ret = 'Internal Error Calculating Hole Design -- Please Try Redrawing Field and/or Pipe'

        if(error.details) {
            ret +=  '<br><br>'
                + error.details
        }

        return ret
    }

    buildCheckFurrowDirectionException(error) {
        return `No Furrows Detected<ul>
            <li>Tap field to check furrow direction in relation to pipe.</li>
            <li>Ensure pipe is drawn within field boundies.</li>
            <li>If sets are used, pipe must be drawn within associated set.</li>
            <li>Pipe that is nearly parallel to furrow direction may be treated as supply.</li>
        </ul>`
    }

    buildSupplyLineFrictionLossErrorMessage(error) {
        const distance = Math.round(error?.waterableArea?.pipeSegment?.lengthInFeet || 0)
        const elevationDiff = Math.ceil(error?.waterableArea?.elevationDifferenceInFeet || 0)
        const diameter = error?.waterableArea?.pipeSegment?.diameterInInches || "N/A"

        return 'Friction loss or elevation issue.'
            + '<br><br>'
            + `Over ${distance} ft. and Pipe Diameter ${diameter}", ${elevationDiff} ft. Change in Elevation`
            + '<br><br>'
            + 'Possible Solutions:'
            + '<ul>'
            + '<li>Check the elevation data on the pipe, add if necessary.</li>'
            + '<li>Change to a smaller pipe size for the supply line.</li>'
            + '<li>Use a water source closer to the field.</li>'
            + '</ul>'
    }
    
     buildInsufficientFlowErrorMessage(smallerHoleSizesAvailable, index, areaName) {
        return 'Unable to create a design that covers given area.<br>'
        + 'Ran out of water at ' + areaName.toLowerCase() + ' number ' + index.toString()
        + '<br><br>'
        + 'Possible Solutions:'
        + '<ul>'
        + '<li>If pipe is along a fall, add elevation data.</li>'
        + '<li>Use a water source closer to the field.</li>'
        + (smallerHoleSizesAvailable ?
        '<li>Allow smaller hole sizes</li>' : '')
        + '</ul>'
    }

    buildPressureRangeBrokenErrorMessage(index, areaName){
        return '<h3>Pressure threshold hit at ' + areaName.toLowerCase() + ' number ' + index.toString() + "</h3>"
        + 'Possible Solutions:'
        + '<ul>'
        + '<li>Change the pipe diameter or the max/min head pressure.</li>'
        + '<li>Set the pipe end to "Open" so that buildups can be used to relieve pressure buildup on steep inclines.</li>'
        + '<li>Check that pipe elevation was entered correctly.</li>'
        + '</ul>'
     }
}

import { GeoUtil } from "@/geo/GeoUtil"

import { Pp4Uuid, FieldLayout, FieldMode } from '@/store/Dto'

class ShapefileParser {
    constructor(store) {
        this.store = store
    }

    parseLeveeFieldLayout(geoJson, name) {
        if(geoJson == null) {
            return null
        }

        let boundsGeoJson = GeoUtil.GeoJson.envelope(geoJson)
        const area = GeoUtil.GeoJson.area(boundsGeoJson)
        if(Number.isNaN(area) || area <= 0) {
            return null
        }

        let boundsLatLngs = GeoUtil.GeoJson.toLatLngs(boundsGeoJson)

        let ret = new FieldLayout(Pp4Uuid(), FieldMode.Levees, boundsLatLngs)
        ret.name = name
        ret.fromShapeFile = true

        const lines = geoJson.features.filter((f) => f.geometry.type === 'LineString')
        if(lines.length > 0) {
            this.simplifyLineFeatures(geoJson)
            this.removeLineKinks(geoJson)
            this.resolveIntersectingAndTinyLeveeLines(geoJson)

            ret.leveePaths.push(... this.buildLeveePathsFromFeatures(geoJson))

            return ret
        }
        
        // I'm just gonna leave this in cuz it does kinda work
        const points = geoJson.features.filter((f) => f.geometry.type === 'Point')
        if (points.length > 0) {
            const PointsLineBreakDistanceInMeters = this.store.state.preferences.fileUpload.pointsLineBreakDistanceInMeters

            const allCoords = points.map(GeoUtil.GeoJson.getCoord)
            const coords = this.buildMergedCoords(allCoords, PointsLineBreakDistanceInMeters / 2)

            let linesFromPoints = []
            let lineCoords = []
            let lastCoord = null
            coords.forEach((coord) => {
                if (lastCoord == null) {
                    lineCoords.push(coord)
                    lastCoord = coord
                    return
                }

                const distance = GeoUtil.Coords.distance(lastCoord, coord, { units: 'meters' })
                if (distance < PointsLineBreakDistanceInMeters) {
                    lineCoords.push(coord)
                    lastCoord = coord
                    return
                }

                // we are > PointsLineBreakDistanceInMeters from the last coord, need to start a new line
                {
                    lineCoords.push(coord)
                    if (lineCoords.length >= 2) {
                        linesFromPoints.push(GeoUtil.Coords.toLineString(lineCoords))
                    }

                    lineCoords = []
                    lastCoord = null
                }
            })

            if (lineCoords.length >= 2) {
                linesFromPoints.push(GeoUtil.Coords.toLineString(lineCoords))
            }

            const fc = GeoUtil.GeoJson.featureCollection(linesFromPoints)

            this.simplifyLineFeatures(fc)
            this.removeLineKinks(fc)
            this.resolveIntersectingAndTinyLeveeLines(fc)
            ret.leveePaths.push(... this.buildLeveePathsFromFeatures(fc))
        }

        return ret
    }

    /*
    Merge similar points while preserving point order.
    */
    buildMergedCoords(coordsArray, ThresholdInMeters=10) {
        const indexesToRemove = new Set()

        for(let i=0;i < coordsArray.length;i++) {
            if(indexesToRemove.has(i)) {
                continue
            }

            for(let j=i + 1;j < coordsArray.length;j++) {
                if(indexesToRemove.has(j)) {
                    continue
                }

                const ci= coordsArray[i]
                const cj= coordsArray[j]

                const distance = GeoUtil.Coords.distance(ci, cj, {units: 'meters'})
                if(distance < ThresholdInMeters) {
                    indexesToRemove.add(j)
                }
            }
        }

        return coordsArray.filter((_c, i) => ! indexesToRemove.has(i))
    }

    parseFurrowFieldLayout(geoJson, name) {
        if(geoJson == null) {
            return null
        }

        const simplifiedGeoJson = GeoUtil.GeoJson.simplify(geoJson, {
            tolerance: 0.00009,
            highQuality: false
        })

        const workingGeoJson = simplifiedGeoJson.features ? simplifiedGeoJson.features[0] : simplifiedGeoJson

        let hasPoly = GeoUtil.GeoJson.isPolygon(workingGeoJson)

        GeoUtil.GeoJson.featureEach(workingGeoJson, (g) => {
            if(GeoUtil.GeoJson.isPolygon(g)) {
                hasPoly = true
            }
        })
        
        const bbox = GeoUtil.GeoJson.bbox(workingGeoJson)
        const fieldGeo = hasPoly ? workingGeoJson : GeoUtil.GeoJson.bboxPolygon(bbox)

        const area = GeoUtil.GeoJson.area(fieldGeo)
        if(Number.isNaN(area) || area <= 0) {
            return null
        }

        const fieldPath = GeoUtil.GeoJson.toLatLngs(fieldGeo)

        let ret = new FieldLayout(Pp4Uuid(), FieldMode.Furrows, fieldPath)
        ret.name = name
        ret.fromShapeFile = true

        return ret
    }

    buildLeveePathsFromFeatures(geoJson) {
        let lines = geoJson.features.filter(this.isFeatureProbablyLine.bind(this))

        return lines.map((f) => {
            return GeoUtil.GeoJson.toLatLngs(f)
        })
    }

    resolveIntersectingAndTinyLeveeLines(geoJson) {
        let features = geoJson.features

        let indexesToRemove = []

        let lengths = features.map((f) => {
            return GeoUtil.GeoJson.length(f, {units: 'kilometers'}) * 1000
        })

        features.forEach((f, index) => {
            if(indexesToRemove.indexOf(index) >= 0) {
                return; //already marked for removal
            }

            if(lengths[index] <= this.store.state.preferences.fileUpload.leveeMinLengthInMeters) {
                indexesToRemove.push(index)
                return
            }

            for(let index2=index + 1;index2 < features.length;index2++) {
                let f2 = features[index2]

                let intersectionPoint = GeoUtil.GeoJson.lineIntersect(f, f2)

                if(intersectionPoint.features.length <= 0) {
                    continue
                }

                indexesToRemove.push(lengths[index] > lengths[index2] ? index2 : index)
            }
        })

        indexesToRemove.sort((a, b) => b - a) //reverse the indexes

        indexesToRemove.forEach((i) => {
            geoJson.features.splice(i, 1)
        })
    }

    removeLineKinks(geoJson) {
        geoJson.features.forEach((f) => {
            if(! this.isFeatureProbablyLine(f)) {
                return
            }

            GeoUtil.GeoJson.removeLineKinks(f)
        })
    }

    simplifyLineFeatures(geoJson) {
        geoJson.features.forEach((f) => {
            if(! this.isFeatureProbablyLine(f)) {
                return
            }
        
            GeoUtil.GeoJson.simplify(f, {
                mutate: true, 
                tolerance: this.store.state.preferences.fileUpload.leveeSimplifyTolerance, 
                highQuality: false
            })
        })
    }

    isFeatureProbablyLine(f) {
        return (
            (f.type != null) 
            && (f.type == 'Feature' )
            && (f.geometry != null) 
            && (f.geometry.type == 'LineString'
            && (f.geometry.coordinates.length >= 2))
        )
    }
}

export {
    ShapefileParser
}

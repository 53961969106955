<template>
    <pp4-label v-for="(labelData, i) in holeSizeLabels" :key="i" :labelText="labelData.labelText" :latLng="labelData.latLng"
        :elementClass="labelData.elementClass" />

    <template v-if="false">
        <pp4-polyline v-for="(furrowLine, i) in furrowLines" :key="i" strokeColor="#ff0000" strokeWeight="1.0"
            :path="furrowLine.path" />
    </template>
</template>

<script>
import Pp4Label from '@/components/maps/Label'
import Pp4Polyline from '@/components/maps/Polyline'

import { GeoUtil } from '@/geo/GeoUtil'
import {FieldMode} from '@/store/Dto'

export default {
    props: {
        designParams: {
            type: Object, required: true
        },
        fieldLayout: {
            type: Object, required: true
        }
    },
    components: {
        Pp4Label, Pp4Polyline
    },
    emits: [],
    computed: {
        holeSizeLabels: (vm) => {
            const branches = vm.designParams?.holeDesign?.wateredBranches || []

            let ret = []

            for (let branch of branches) {
                let lastLabelText = null

                for (let step of branch.irrigationSteps) {
                    if (!step.outlets) {
                        continue
                    }

                    const labelText = step.outlets.map(o => o.name).join(', ')
                    if (labelText === lastLabelText) {
                        continue
                    }

                    lastLabelText = labelText

                    ret.push({
                        latLng: GeoUtil.GeoJson.toLatLngs(step.waterableArea.pipeEnterGeoJsonPoint),
                        labelText,
                        elementClass: vm.$style['outlet_label']
                    })
                }
            }

            return ret
        },
        furrowLines: (vm) => {
            if(vm.fieldLayout.mode !== FieldMode.Furrows) {
                return []
            }

            const branches = vm.designParams?.holeDesign?.wateredBranches || []
            const bearing = vm.fieldLayout.furrowBearing

            let ret = []

            for (let branch of branches) {
                let lastLabelText = null

                for (let step of branch.irrigationSteps) {
                    const p = step?.waterableArea?.pipeEnterGeoJsonPoint
                    if (!p) {
                        continue
                    }

                    // we need to reverse engineer the furrow length
                    const areaInSquareFeet = step.waterableArea.areaInSquareFeet


                    const furrowLengthInFeet = step.waterableArea.areaInSquareFeet /
                        (vm.fieldLayout.furrowSpacingInInches
                            * (vm.fieldLayout.alternatingFurrows ? 2.0 : 1.0)
                            / 12.0)

                    const coords = GeoUtil.GeoJson.getCoords(p)

                    const projectedP = GeoUtil.Coords.destination(coords, furrowLengthInFeet * step.coverage, bearing, {units: 'feet'})
                    
                    ret.push({ 
                        path: [GeoUtil.GeoJson.toLatLngs(p), GeoUtil.GeoJson.toLatLngs(projectedP)]
                    })
                }
            }

            return ret
        }
    }
}
</script>

<style lang="css" module>
.outlet_label {
    font-size: medium;
    color: white;
    position: absolute;
    background: rgba(0, 0, 0, 0.9);
    padding: 0.5em;
    border-radius: var(--softer-border-radius);
    cursor: pointer;
}
</style>

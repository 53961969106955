export function elevation(valueInFeet) {
    if(valueInFeet == null) {
        return ''
    }
    
    return valueInFeet.toPrecision(2) + ' ft'
}

export function pipeLength(valueInFeet) {
    if(valueInFeet == null) {
        return ''
    }
    
    return Math.round(valueInFeet) + ' ft'
}

export function leveeDistance(valueInFeet) {
    if(valueInFeet == null) {
        return ''
    }
    
    return Math.round(valueInFeet) + ' ft'
}

// definition of PipeType from wizard.ts
export function pipeType(pipeType) {
    if(! pipeType) {
        return ''
    }

    return pipeType.diameterInInches + 'x' +
        ((pipeType.thicknessInMils === 15) ? 'trans' : pipeType.thicknessInMils)
}

export function wateringTime(dateValue) {
    return dateValue.toLocaleDateString('en', { day: '2-digit', month: '2-digit', year: 'numeric' }) 
    + " " + dateValue.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})
}

<template>
    <div :class='classes' @click="bgClick">
        <pp4-header v-if="title?.length || description?.length" class="fullpageform_header" :title="title">
            <template #description v-if="description?.length">{{ description }}</template>
            <template #float-right>
                <slot name="float-right" />

                <a class="button widget_margin_left" v-if="showCancel"
                    @click="cancel()"><i class="fa fa-times fa_button_icon"></i></a>
            </template>
            <template #bottom>
                <slot name="bottom" />
            </template>
        </pp4-header>

        <div class="form_content" >
            <div class="content_and_action_buttons" @click.stop="contentClick">
                <form v-show="hasDefaultSlot" class='form center_content' v-on:submit.prevent='submit'>
                    <slot />
                </form>

                <div class='action_buttons center_content' v-if="hasActionButtons">
                    <template v-if="submitLabel != null">
                        <button type="submit" v-on:click.once="submit()">{{ submitLabel }}</button>
                    </template>

                    <template v-if="cancelLabel != null">
                        <button class="button_margin_left" v-on:click="cancel()">{{ cancelLabel }}</button>
                    </template>

                    <slot name="action-buttons" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import hotkeys from 'hotkeys-js';

export default {
    props: {
        title: {
            type: String,
            default: null,
            required: false
        },
        description: {
            type: String,
            default: '',
            required: false
        },
        submitLabel: {
            type: String,
            default: null,
            required: false,
        },
        cancelLabel: {
            type: String,
            default: null,
            required: false
        },
        opaqueBackground: {
            type: Boolean,
            default: true,
            required: false
        },
        enableHotkeys: {
            type: Boolean,
            default: false,
            required: false
        },
        backgroundClickCancels: {
            type: Boolean,
            default: false,
            required: false
        },
        showCancel: {
            type: Boolean,
            default: false,
            required: false

        }
    },
    computed: {
        hasDefaultSlot: (vm) => !!vm.$slots.default,
        hasActionButtonsSlot: (vm) => !!vm.$slots['action-buttons'],
        hasActionButtons: vm => vm.hasActionButtonsSlot || vm.submitLabel?.length || vm.cancelLabel?.length,
        classes: (vm) => vm.opaqueBackground ? 'fullpageform opaque_background' : 'fullpageform'
    },
    methods: {
        bgClick: function () {
            if (this.backgroundClickCancels) {
                this.cancel()
            }
        },
        contentClick() {
        },
        submit: function (e) {
            this.$emit('submit', e) // TODO: ok this is a bit silly, let's change to just emit the event at this level right??
        },
        cancel: function (e) {
            this.$emit('cancel', e)
        }
    },
    mounted() {
        hotkeys.setScope("FullPageForm")

        if (this.enableHotkeys || this.hasActionButtons) {
            hotkeys('escape', () => {
                this.cancel();
            });
        }
    },
    unmounted() {
        hotkeys.deleteScope("FullPageForm")
    }
}
</script>

<style lang="css" scoped>
.fullpageform_header {
    position: sticky;
    left: 0px;
    right: 0px;
    top: 0px;
}

.opaque_background {
    background: var(--opaque-background-color);
}

.fullpageform {
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    height: 100%;
}

.content_and_action_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    padding: 0.5em;
    justify-content: center;
}

.action_buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-self: stretch;
    display: flex;
    align-items: center;
    margin-top: 0.75em;
    min-width: 12em;
}
</style>

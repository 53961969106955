<template>
    <div id="bounds_menu">
        <icon-button title="Edit Field Outer Boundary" label="Edit Area" v-on:click="enterEditBoundsMode()"><img :src="EditPolyPointsIcon" :draggable="false"></icon-button>
    </div>
</template>

<script>
import EditPolyPointsIcon from '@/assets/edit_poly_points_icon.svg'

export default {
    data: function() {
        return {
            EditPolyPointsIcon
        }
    },
    methods: {
        enterEditBoundsMode() {
            this.$store.dispatch('enterEditFieldBoundsPointsMode')
        }
    }
}
</script>

<style scoped>
#bounds_menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
</style>

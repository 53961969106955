<template>
    <fullpageform v-if="sharedResult == null" title="Share Hole Designs" 
        submitLabel="Share" cancelLabel="Cancel"
        @submit="submit" @cancel="cancel">
        <fieldset>
            <label for="">Recipient's Email</label>
            <div class="email">
                <input type="email" v-model="recipientEmail">
            </div>
        </fieldset>
    </fullpageform>
    <fullpageform v-else title="Hole Designs Shared" cancelLabel="OK"
        @submit="cancel" @cancel="cancel">
        <fieldset>
            <div class="email">
                <label>{{ sharedResult }}</label>
            </div>
        </fieldset>
    </fullpageform>
</template>

<script>
// user will be given a copy of the farm as it is now, not a link to the farm

export default {
    props: ['holeDesignPdf', 'fileName'],
    data: function () {
        return {
            recipientEmail: null,
            sharedResult: null,
        }
    },
    methods: {
        submit: async function () {
            const pdf = this.holeDesignPdf
            const recipientEmail = this.recipientEmail
            const fileName = this.fileName
            await this.$store.dispatch('shareHoleDesigns', { recipientEmail, holeDesignPdf: pdf, fileName })
            this.sharedResult = `Your Hole Plans Have Been Sent To ${recipientEmail}`
        },
        cancel: function () {
            this.$dialog.pop()
        },
    },
    mounted() {
    }

}
</script>

<style>
input[type="text"]:disabled {
    background: #787878;
    border-color: black;
}
</style>
import { Direction } from '@/store/Dto'
import { GeoUtil } from '@/geo/GeoUtil'
import sha1 from 'sha1'

const InterpolationNeeds = Object.freeze({
    ElevationRequiredAtJunctions: 10,
    NoElevationsSet: 11,
    MinimumTwoElevationsRequired: 12
})

function hasValidElevation(ll) {
    if(! ll) {
        return false
    }
    return Object.hasOwn(ll, 'elevationInFeet')
        && Number.isFinite(ll.elevationInFeet)
        && (!(
            ('interpolated' in ll)
            && (ll.interpolated === true)
        ))
}

export default class PipePathAlg {
    static InterpolationNeeds = InterpolationNeeds

    static hasValidElevation = hasValidElevation

    static checksum(path) {
        return sha1(JSON.stringify(path))
    }

    static junctionTypeLabel(junctionType) {
        if ('inline_tee' === junctionType) {
            return 'Inline Tee'
        }

        if ('surge_valve' === junctionType) {
            return 'Surge Valve'
        }

        return 'Unknown Junction Type'
    }

    static traversePathSegmentswithJunctions(path, callback, isPrimaryBranch = true, parentJunction = null) {
        let ll0 = null
        for (let i = 0; i < path.length; i++) {
            const ll = path[i]
            if (PipePathAlg.isJunction(ll)) {
                ll.paths.forEach((subPath) => {
                    // invalid subpath..
                    if (subPath.length <= 0) {
                        return
                    }

                    subPath[0].lat = ll.lat
                    subPath[0].lng = ll.lng
                    this.traversePathSegmentswithJunctions(subPath, callback, false, ll)
                })
            }

            if (ll0 == null) {
                ll0 = ll
                continue
            }
            if (ll0 != null) {
                callback(ll0, ll, isPrimaryBranch, parentJunction, i - 1)
                ll0 = ll
            }
        }
    }

    static traversePathSegments(path, callback) {
        let ll0 = null
        for (let i = 0; i < path.length; i++) {
            const ll = path[i]

            if (ll0 == null) {
                ll0 = ll
                continue
            }
            if (ll0 != null) {
                const isLast = i === (path.length - 1)
                callback(ll0, ll, isLast)
                ll0 = ll
            }
        }
    }

    static insertAfter = (targetLl, val, path) => {
        for (let i = 0; i < path.length; i++) {
            const ll = path[i]

            if (ll == targetLl) {
                path.splice(i + 1, 0, val)
                return true
            }

            if (PipePathAlg.isJunction(ll)) {
                const branches = ll.paths
                for (let branchIndex = 0; branchIndex < branches.length; branchIndex++) {
                    const branchPath = branches[branchIndex]
                    if (this.insertAfter(targetLl, val, branchPath)) {
                        return true
                    }
                }
            }
        }
        return false
    }


    // Callback form: callback(originalPath, path, index, parentJunction, parentJunctionBranchIndex)
    static traverseToUsingIndexes(originalPath, traversalIndexes, callback) {
        let path = originalPath
        let parentJunction = null
        let parentJunctionBranchIndex = null

        traversalIndexes = traversalIndexes.slice().reverse()

        while (true) {
            if (traversalIndexes.length === 0) {
                return
            }

            const index = traversalIndexes.pop()
            const latLng = path[index]

            if (traversalIndexes.length === 0) {
                callback(originalPath, path, index, parentJunction, parentJunctionBranchIndex)
                
                return
            }

            //we have a junction
            {
                const junctionBranchIndex = traversalIndexes.pop()

                path = latLng.paths[junctionBranchIndex]
                parentJunction = latLng
                parentJunctionBranchIndex = junctionBranchIndex
            }
        }
    }

    static traversePath(path, callback,
        isPrimaryBranch = true, traversalIndexes = [],
        parentJunction = null, parentJunctionIndex = null) {
        path.forEach((ll, i) => {
            const llTraversalIndexes = traversalIndexes.concat([i])

            callback(ll, i, path, isPrimaryBranch, llTraversalIndexes, parentJunction, parentJunctionIndex)

            if (PipePathAlg.isJunction(ll)) {
                ll.paths.forEach((junctionPath, junctionPathIndex) => {
                    if (junctionPath.length < 2) {
                        console.warn('Invalid Junction Branch Path')
                        return
                    }

                    const junctionTraversalIndexes = llTraversalIndexes.concat([junctionPathIndex])

                    PipePathAlg.traversePath(junctionPath, callback, false,
                        junctionTraversalIndexes, ll, junctionPathIndex)
                })
            }
        })
    }

    static buildInterpolationNeeds(path) {
        const ret = new Set()

        let validElevationCount = 0
        PipePathAlg.traversePathBranches(path, (branchPath) => {
            branchPath.forEach((ll) => {
                if (hasValidElevation(ll)) {
                    validElevationCount += 1
                    return
                }

                if (this.isJunction(ll)) {
                    ret.add(InterpolationNeeds.ElevationRequiredAtJunctions)
                }
            })
        })

        if (validElevationCount < 2) {
            ret.add(InterpolationNeeds.MinimumTwoElevationsRequired)
        }

        return Array.from(ret)
    }

    static logElev(path) {
        PipePathAlg.traversePathBranches(path, (branchPath) => {
            console.dir(branchPath.map(ll => {
                return { val: ll.elevationInFeet, interpolated: ll.interpolated }
            }))
        })
    }

    static interpolateElevationInFeet(path) {
        // The basic alg works three cases: 
        //  1) Interpolate points in a segment with elevations at each end.
        //  2) Backfill all points of a segment up to a given elevation on the last element.
        //  3) Forward fill the remaining values in a segment from a valid elevation.
        const theBasicAlg = (segment) => {
            const isInterpolatedSegment = hasValidElevation(segment[0])
                && hasValidElevation(segment[segment.length - 1])

            if (isInterpolatedSegment) {
                const segmentLength = GeoUtil.LatLngs.length(segment)
                const elevationDifference = segment[segment.length - 1].elevationInFeet - segment[0].elevationInFeet

                let prevLatLng = segment[0]

                segment.forEach((ll, i) => {
                    if (i === 0 || i === (segment.length - 1)) {
                        return
                    }
                    const subSegmentLength = GeoUtil.LatLngs.length([prevLatLng, ll])
                    ll.elevationInFeet = parseFloat((prevLatLng.elevationInFeet +
                        (elevationDifference * (subSegmentLength / segmentLength))).toFixed(1))

                    ll.interpolated = true

                    prevLatLng = ll
                })
            }
            else {
                let elevationInFeet = null
                let latLngsToBackfill = []
                segment.forEach((ll) => {
                    if (elevationInFeet == null) {
                        if (hasValidElevation(ll)) {
                            elevationInFeet = ll.elevationInFeet
                            latLngsToBackfill.forEach((backfillLatLng) => { // complete the backfill
                                backfillLatLng.elevationInFeet = ll.elevationInFeet
                                backfillLatLng.interpolated = true
                            })
                            latLngsToBackfill = []
                        }
                        else {
                            latLngsToBackfill.push(ll)
                        }
                    } else { // forward fill
                        ll.elevationInFeet = elevationInFeet
                        ll.interpolated = true
                    }
                })
            }
        }

        // This alg is assuming we traverse in descending order...
        PipePathAlg.traversePathBranches(path, (branchPath, parentLatLng) => {
            let currentSegment = []

            branchPath.forEach((ll, i) => {
                currentSegment.push(ll)

                let llHasValidElevation = hasValidElevation(ll)
            
                if((i === 0) && (! llHasValidElevation) && hasValidElevation(parentLatLng)) {
                    if(parentLatLng) {
                        ll.elevationInFeet = parentLatLng.elevationInFeet
                        llHasValidElevation = true
                    }
                }

                const isJunction = PipePathAlg.isJunction(ll)
                if (isJunction) {
                    if(!llHasValidElevation) {
                        return
                    }
                }

                const isLastLatLng = (i === (branchPath.length - 1))
                const runBasicAlg = isLastLatLng
                    || (llHasValidElevation && currentSegment.length > 1)

                if (runBasicAlg) {
                    theBasicAlg(currentSegment)

                    currentSegment = [currentSegment[currentSegment.length - 1]]
                }
            })
        })
    }

    static removeModePossible(path) {
        if (path == null) {
            return false
        }

        if (path.length <= 1) {
            return false
        }

        let ret = false

        PipePathAlg.traversePathBranches(path, (branch, parentLatLng) => {
            if (ret) {
                return
            }

            if (parentLatLng) {
                ret = (branch.length > 2)
            }
            else {
                ret = true
            }
        })

        return ret
    }

    static lookupFromTraversalIndexes(path, traversalIndexes) {
        traversalIndexes = traversalIndexes.slice().reverse()

        while (true) {
            if (traversalIndexes.length === 0) {
                return null
            }

            const index = traversalIndexes.pop()
            const latLng = path[index]

            if (traversalIndexes.length === 0) {
                return { branchPath: path, latLng }
            }

            //we have a junction

            const junctionBranchIndex = traversalIndexes.pop()
            path = latLng.paths[junctionBranchIndex]
        }
    }

    static traversePathBranches(path, callback, parentJunctionLatLng = null) {
        if (!path?.length) {
            return // arguably we should invoke the callback with the empty path...
        }

        callback(path, parentJunctionLatLng)

        const latLatLng = path[path.length - 1]
        if (PipePathAlg.isJunction(latLatLng)) {
            latLatLng.paths.forEach((junctionPath) => {
                PipePathAlg.traversePathBranches(junctionPath, callback, latLatLng)
            })
        }
    }

    static isJunction(latLng) {
        return latLng?.paths ? true : false
    }

    static junctionBranches(latLng) {
        if (!this.isJunction(latLng)) {
            throw new Error('Not a Junction')
        }

        return latLng.paths
    }

    // For a pair of paths, return a pair of Direction values as an array.
    static pathDirections(path0, path1, parentSegmentBearing) {
        if ((path0.length < 2) || (path1.length < 2)) {
            console.warn('Invalid Paths -- Cannot Compute Directions')
            return [null, null]
        }

        const bearingWhileIgnoringDupePointsAtStart = (p) => {
            if (p.length < 2) {
                return 0
            }

            for (let i = 1; i < p.length; i++) {
                const ll0 = p[i - 1]
                const ll1 = p[i]
                const OneTenthOfOneMeterInLatLng = 0.000000898311 //I think...
                const threshold = (OneTenthOfOneMeterInLatLng * 10) * 20
                if (GeoUtil.LatLngs.pointsEqual(ll0, ll1, threshold)) {
                    continue
                }

                return GeoUtil.bearing(ll0, ll1)
            }

            return GeoUtil.bearing(p[0], p[1])
        }

        let b0 = bearingWhileIgnoringDupePointsAtStart(path0)
        let b1 = bearingWhileIgnoringDupePointsAtStart(path1)

        const useLastPathPointsToDetermineBearing = (b0 === b1)

        if (useLastPathPointsToDetermineBearing) {
            b0 = GeoUtil.bearing(path0[0], path0[path0.length - 1])
            b1 = GeoUtil.bearing(path1[0], path1[path1.length - 1])

        }

        const compareBearingsOnly = () => {
            const leftmostBearing = GeoUtil.leftmostBearing(b0, b1)

            if (leftmostBearing == null) {
                return [null, null]
            }

            return b0 === leftmostBearing ?
                [Direction.Left, Direction.Right] : [Direction.Right, Direction.Left]
        }

        if (parentSegmentBearing == undefined) {
            return compareBearingsOnly()
        }

        const d0 = GeoUtil.directionForBearingRelativeToBearing(b0, parentSegmentBearing)
        const d1 = GeoUtil.directionForBearingRelativeToBearing(b1, parentSegmentBearing)

        if (d0 !== d1) {
            return [d0, d1]
        }

        return compareBearingsOnly()
    }

    // Returned as an array of arrays.
    // For example: [A, [B, C], D, [E, [F, G]], H]
    // Junctions are represented as arrays.
    // At junctions a 'type' property is added to the array to identify the junction type. e.g. "inline_tee"
    // At junction branches a 'direction' property is added to each line string array, of type Direction.
    // A 'direction' property is added to line string properties, of type Direction.
    // A 'level' property is added to line string properties, indicating the nesting level of the pipe branch. This level is 0 based.
    // An 'elevationDifferenceInFeet' property is added each line string.
    // A 'lastConnectorType' property is added for the last latlng in each array. Can be null.
    // A 'branchLineString' property is added to each branch path.
    // A 'pipeType' property is added if one exists, else suggestedPipeType.
    static buildPipeLineStringsWithMetadata(pipePath, suggestedPipeType = null) {
        let traverseBranch = null // nested recursion

        const traverseJunction = (type, branchPaths, parentSegmentBearing, level, elevationInFeet) => {
            let ret = []

            let branchPathDirections = branchPaths.map(() => null)
            if (branchPaths.length === 2) {
                branchPathDirections = PipePathAlg.pathDirections(branchPaths[0], branchPaths[1], parentSegmentBearing)
            }

            branchPaths.forEach((branchPath, branchPathIndex) => {
                // if(branchPath.length <= 2) {
                //     return
                // }

                const branch = traverseBranch(branchPath, level + 1,
                    branchPathDirections[branchPathIndex], elevationInFeet)
                if (branch) {
                    if (branch instanceof Array) {
                        branch.direction = branchPathDirections[branchPathIndex]
                    }

                    ret.push(branch)
                }
            })

            if (ret.length === 0) {
                return null
            }

            ret.type = type

            return ret
        }

        traverseBranch = (path, level, direction, startingElevationInFeet) => {
            const ret = []

            ret.branchLineString = GeoUtil.LatLngs.toLineString(path)

            let subPath = []

            const addSubPathIfNecessary = (addLastConnectorType) => {
                if (subPath.length < 2) {
                    return
                }

                const ll0 = subPath[0]
                const ll1 = subPath[1]

                const elevation0 = ('elevationInFeet' in ll0) && Number.isFinite(ll0.elevationInFeet) ?
                    ll0.elevationInFeet : startingElevationInFeet
                const elevation1 = ('elevationInFeet' in ll1) && Number.isFinite(ll1.elevationInFeet) ?
                    ll1.elevationInFeet : 0

                startingElevationInFeet = elevation1

                const lineString = GeoUtil.LatLngs.toLineString(subPath)
                lineString.properties.direction = direction
                lineString.properties.level = level
                lineString.properties.elevationDifferenceInFeet = elevation1 - elevation0
                lineString.properties.pipeType = ll0?.pipeType || suggestedPipeType
                lineString.properties.lastConnectorType = null

                if (addLastConnectorType && ('connectorType' in ll1)) {
                    lineString.properties.lastConnectorType = ll1.connectorType
                }

                ret.push(lineString)

                subPath = [subPath[subPath.length - 1]]
            }

            for (let i = 0; i < path.length; i++) {
                const ll = path[i]
                const onLastLatLng = (i === (path.length - 1))

                subPath.push(ll)

                addSubPathIfNecessary(onLastLatLng)

                if (PipePathAlg.isJunction(ll)) {
                    let parentSegmentBearing = 0
                    if (i > 0) {
                        const ll0 = path[i - 1]
                        parentSegmentBearing = GeoUtil.bearing(ll0, ll)
                    }

                    addSubPathIfNecessary(onLastLatLng)

                    const remainingPath = path.slice(i)

                    const isValidPath = (p) => p != null && p.length >= 2

                    const branchPaths = PipePathAlg.junctionBranches(ll).slice().filter(isValidPath)
                    if (remainingPath.length > 1) {
                        //clean up the first point to prevent infinite recursion on the junction
                        const cleanedUpFirstPoint = JSON.parse(JSON.stringify(remainingPath[0]))
                        delete cleanedUpFirstPoint.paths

                        remainingPath[0] = cleanedUpFirstPoint

                        branchPaths.push(remainingPath)
                    }

                    const junctionPaths = traverseJunction(ll.type, branchPaths,
                        parentSegmentBearing, level, startingElevationInFeet)

                    if (junctionPaths != null) {
                        ret.push(junctionPaths)
                    }

                    break
                }
            }

            addSubPathIfNecessary(true)

            if (ret.length === 0) {
                return null
            }

            if (ret.length === 1) {
                return ret[0]
            }

            return ret
        }

        const ret = traverseBranch(pipePath.path, 0, null, 0)

        if (ret == null) {
            return null
        }

        if (ret instanceof Array) {
            return ret
        }

        return [ret]
    }

    // Format of first arg is result of buildPipeLineStringsWithMetadata
    // propertiesCmpMerge should compare two ls props and return null if can't be merged,
    // and the new props if they can.
    static mergePipeLineStrings(lineStringNestedArray, propertiesCmpMerge) {
        const ret = []

        let currentLs = null

        for(let lsOrArray of lineStringNestedArray) {
            if(lsOrArray instanceof Array) {
                ret.push(... PipePathAlg.mergePipeLineStrings(lsOrArray, propertiesCmpMerge))
                continue
            }

            if(! currentLs) {
                currentLs = lsOrArray
                ret.push(currentLs)            
                continue
            }

            const merged = propertiesCmpMerge(currentLs.properties, lsOrArray.properties)
            if(merged) {
                currentLs.geometry.coordinates.push(... lsOrArray.geometry.coordinates.slice(1))
                currentLs.properties = merged
            }
            else {
                currentLs = lsOrArray
                ret.push(currentLs)
            }
        }

        return ret
    }

    static lengthInFeetIncludingJunctions(path) {
        const paths = []

        const traversePath = (p) => {
            if (p.length == 0) {
                return
            }
            paths.push(p)

            p.forEach((ll) => {
                if (ll.paths) {
                    ll.paths.forEach(traversePath)
                }
            })
        }

        traversePath(path)

        return paths.reduce((accum, p) => accum + GeoUtil.LatLngs.length(p, { units: 'feet' }), 0)
    }

    // Pretty much only intended for use in an ElevationOverlay. 
    // Will _not_ return the precise count, just in that ballpark (due to rounding issues).
    static buildInterpolatedLatLngsWithElevation(path, desiredCount, minDistanceDifferenceInFeet = 30) {
        const ret = {
            min: null,
            max: null,
            paths: []
        }

        const totalLengthInFeet = PipePathAlg.lengthInFeetIncludingJunctions(path)
        const minDistanceDifferenceInKm = minDistanceDifferenceInFeet * 0.0003048

        PipePathAlg.traversePath(path, (ll) => {
            const elevation = ll.elevationInFeet
            if (!Number.isFinite(elevation)) {
                return
            }

            if (!Number.isFinite(ret.min)) {
                ret.min = elevation
            }
            else {
                ret.min = Math.min(ret.min, elevation)
            }


            if (!Number.isFinite(ret.max)) {
                ret.max = elevation
            }
            else {
                ret.max = Math.max(ret.max, elevation)
            }
        })

        if (!Number.isFinite(ret.min)) {
            ret.min = 0
        }
        if (!Number.isFinite(ret.max)) {
            ret.max = 0
        }

        let startingElevation = 0.0

        PipePathAlg.traversePathBranches(path, (branchPath, parentJunction) => {
            const interpolatedPath = []

            PipePathAlg.traversePathSegments(branchPath, (ll0, ll1, isLastBranchSegment) => {
                const startingElevationOrParentJunctionElevation =
                    ((parentJunction != null) && Number.isFinite(parentJunction.elevationInFeet)) ?
                        parentJunction.elevationInFeet : startingElevation
                const elevation0 = Number.isFinite(ll0.elevationInFeet) ?
                    ll0.elevationInFeet : startingElevationOrParentJunctionElevation
                const elevation1 = Number.isFinite(ll1.elevationInFeet) ?
                    ll1.elevationInFeet : 0.0
                const elevationDiff = elevation1 - elevation0

                startingElevation = elevation1

                const segmentLs = GeoUtil.LatLngs.toLineString([ll0, ll1])
                const segmentLengthInFeet = GeoUtil.GeoJson.length(segmentLs, { units: 'feet' })
                const segmentLengthInKm = 0.0003048 * segmentLengthInFeet

                const segmentPointCount = Math.ceil(segmentLengthInFeet / totalLengthInFeet * desiredCount)
                const subSegmentLengthInKm = Math.max(minDistanceDifferenceInKm,
                    segmentLengthInKm / segmentPointCount)

                const segments = GeoUtil.GeoJson.lineChunk(segmentLs, subSegmentLengthInKm)
                const segmentCount = segments.features.length

                GeoUtil.GeoJson.featureEach(segments, (ls, segmentIndex) => {
                    const isLastSegment = (segmentIndex === (segmentCount - 1))

                    const coords = GeoUtil.GeoJson.getCoords(ls);

                    if (coords.length !== 2) {
                        console.warn("Bad Segment") // should never see this...
                        return
                    }

                    const ll = GeoUtil.Coords.toLatLng(coords[0])
                    ll.elevationInFeet = elevation0
                        + ((segmentIndex / segmentCount) * elevationDiff)
                    interpolatedPath.push(ll)

                    if (isLastBranchSegment && isLastSegment) {
                        const lastLl = GeoUtil.Coords.toLatLng(coords[1])
                        lastLl.elevationInFeet = elevation1
                        interpolatedPath.push(lastLl)
                    }
                })
            })

            if (interpolatedPath.length) {
                ret.paths.push(interpolatedPath)
            }
        })

        return ret
    }

    static subdividePath(path, count) {
        const ret = []

        const lineString = GeoUtil.LatLngs.toLineString(path)
        const lengthInMeters = GeoUtil.GeoJson.length(lineString, { units: 'meters' })
        const stepLengthInMeters = Math.max(lengthInMeters / count, 5)
        for (let i = 0; (i < count) && ((i * stepLengthInMeters) < lengthInMeters); i++) {
            const pointGeo = GeoUtil.GeoJson.along(
                lineString, i * stepLengthInMeters, { units: 'meters' })
            const latLng = GeoUtil.GeoJson.toLatLngs(pointGeo)

            ret.push(latLng)
        }

        return ret
    }
}

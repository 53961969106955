import { FieldMode } from '@/store/Dto'

export function defaultName(existingNames, 
    {nameRegex=null, namePrefix=null, wrapIndexInParens=false}={}) {
    let maxUsedIndex = existingNames.length

    existingNames.forEach((name) => {
        const match = name.match(nameRegex)
        if(match == null) {
            return
        }

        maxUsedIndex = Math.max(maxUsedIndex, parseInt(match[1]))
    })
    
    const indexString = wrapIndexInParens ?
        '(' + (maxUsedIndex + 1) + ')' : (maxUsedIndex + 1)

    return namePrefix + indexString
}

// Not sure where this should go. Also present in pp4-api...
export function getCurrentCropYear() {
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() + 4)
    return currentDate.getFullYear()
}

export function fieldLayoutName(existingNames) {
    const now = new Date()
    const month = now.toLocaleString([], {month: 'short'})
    const year = now.getFullYear()
    const name = `${month} ${year}`

    if(existingNames.indexOf(name) < 0) {
        return name
    }

    const nameRegex = new RegExp(`^${month} ${year} ([0-9])$`)
    const namePrefix = name + ' '

    return defaultName(existingNames, {nameRegex, 
        namePrefix, wrapIndexInParens: true})
}

export function productNameForExport(pipeSize) {
    return 'Poly ' + pipeSize
}
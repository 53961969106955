<template>
  <div ref="app" id="app_top">
    <div v-if="data.auth0Error">
      Authentication Error: {{ data.auth0Error }}
    </div>
    <div v-else-if="auth0.isLoading.value" className="app_content">
      <loading :label="data.message" />
    </div>
    <div v-else className="app_content">
      <template v-if="auth0.isAuthenticated.value">
        <google-maps-loader :apiKey="GoogleMapsApiKey" id="mainmap">
          <router-view />
        </google-maps-loader>
            <WarningMessage v-if="computedWarningMessage"/>
      </template>
      <template v-else>
        <loading :label="data.message" />
      </template>
    </div>
  </div>

  <div id="jelenius_modal_container">
    <DialogContainer />
  </div>
</template>

<script setup>
import { ref, watch, computed, reactive, inject, provide, onMounted } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue';
import { useStore } from 'vuex'
import { container as DialogContainer } from "jenesius-vue-modal";

import GoogleMapsLoader from '@/components/maps/GoogleMapsLoader'
import WarningMessage from '@/components/WarningMessage'

const app = ref(null);
const auth0 = useAuth0();
inject("$auth0", auth0);

const data = reactive({
  message: "Authenticating...",
  auth0Error: null
})

const droppedFiles = ref([])
const env = inject('$env');
const store = useStore();

const GoogleMapsApiKey = env.VUE_APP_GOOGLE_MAPS_API_KEY

const computedWarningMessage = computed(() => {
  return store.state.warningMessage
})

watch([auth0.isAuthenticated, auth0.isLoading], async () => {
  if (auth0.isAuthenticated.value) {
    return
  }

  if (auth0.isLoading.value) {
    return
  }

  if (auth0.error.value) {
    data.auth0Error = auth0.error.value
    return
  }

  await auth0.loginWithRedirect({
  })
})

watch(auth0.isAuthenticated, () => {
  store.dispatch('checkValidationThenGetFarms')
})

//dispatch API to store?

provide('$auth', auth0)
provide('droppedFiles', droppedFiles)

onMounted(() => {
  const el = app.value

  let eventsToBlock = ['dragover']
  eventsToBlock.forEach((name) => {
    el.addEventListener(name, (e) => {
      e.preventDefault()
    })
  })

  el.addEventListener('drop', (e) => {
    e = e.originalEvent || e
    e.preventDefault()
    if (e.dataTransfer && e.dataTransfer.files) {
      droppedFiles.value = e.dataTransfer.files
    }
  })
})
</script>

<style lang="css">
#app_top {
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.app_content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.modal-container {
  background-color: var(--opaque-background-color);
}

#jelenius_modal_container {
  position: relative;
  z-index: var(--z-index-dialog);
}
</style>

<template></template>

<script setup>
import { watch, reactive, computed, onUnmounted, toRaw } from 'vue'

import { ZIndexes } from "@/maps/ZIndexes";
import { useMaps, useGmpEvents, useEvents } from '@/composables/useMaps'

import SelectedIcon from '@/assets/water_source_selected.svg'
import SelectableIcon from '@/assets/water_source_selectable.svg'
import UnselectableIcon from '@/assets/water_source_unselectable.svg'

const props = defineProps({
  location: {
    type: Object,
    required: true,
  },
  name: {
    type: String,
    required: false,
    default: null,
  },
  flowRateInGpm: {
    type: Number,
    required: false,
    default: null,
  },
  connectedPipeDiameterInInches: {
    type: Number,
    required: false,
    default: null,
  },
  selectionStyle: {
    type: String,
    required: true,
    validator: function (value) {
      return value == "selected" || value == "selectable" || value == "unselectable";
    },
  },
  showLabel: {
    type: Boolean,
    required: false,
    default: true,
  },
  clickable: {
    type: Boolean,
    required: false,
    default: false
  },

  //events
  onClick: {
    required: false
  }
})

const data = reactive({
  markerMapObject: null,
})

const maps = useMaps()

function zIndex(vm) {
  switch (vm.selectionStyle) {
    case "selected":
      return ZIndexes.WaterSources + 2;
    case "selectable":
      return ZIndexes.WaterSources + 1;
    case "unselectable":
    default:
      return ZIndexes.WaterSources;
  }
}

function svgUrl(vm) {
  switch (vm.selectionStyle) {
    case "selected":
      return SelectedIcon
    case "selectable":
      return SelectableIcon
    case "unselectable":
    default:
      return UnselectableIcon
  }
}

const mapObjectProps = computed(() => {
  const ret = {
    map: toRaw(maps.map),
    position: maps.googleMapUtils.toGoogleLatLng(props.location),
    zIndex: zIndex(props),
    gmpClickable: props.clickable ? true : false
  }

  const flowRateValid = Number.isFinite(props.flowRateInGpm) && (props.flowRateInGpm > 0);
  const nameValid = props?.name?.length && (props.name.length > 0) ? true : false;
  const labelAvailable = flowRateValid || nameValid ? true : false;

  const div = document.createElement('div')
  div.style.userSelect = 'none'
  div.classList.add('water-source')
  div.classList.add(props.selectionStyle)

  const img = document.createElement('img')
  img.src = svgUrl(props)
  div.appendChild(img)

  ret.content = div

  if (props.showLabel && labelAvailable) {
    let labelText = "";

    if (nameValid) {
      labelText += props.name;
    }

    if (flowRateValid) {
      labelText += props.flowRateInGpm.toString();
    }

    if (props.connectedPipeDiameterInInches) {
      labelText += ", " + props.connectedPipeDiameterInInches.toString() + '"';
    }

    let labelTitle = "";
    if (flowRateValid) {
      labelTitle = "Flow rate: " + props.flowRateInGpm.toString() + " gpm";

      if (props.connectedPipeDiameterInInches) {
        labelTitle += ", Pipe Diameter" + props.connectedPipeDiameterInInches.toString() + '"';
      }
    }

    const label = document.createElement('label')
    label.innerText = labelText
    div.appendChild(label)
  }

  return ret
})

watch(mapObjectProps, (newProps) => {
  if (!data.markerMapObject) {
    data.markerMapObject = new maps.api.AdvancedMarkerElement(newProps)

    // this is so stupid, they have a new event format with no latlng property...
    const eventEnrich = function (e) {
      e.latLng = new maps.api.LatLng(props.location)
      return e
    }

    useGmpEvents(data.markerMapObject, props, ['onClick'], eventEnrich)
    // useEvents(data.markerMapObject, props, ['onClick'])
  } else {
    const raw = toRaw(data.markerMapObject)
    raw.position = newProps.position
    raw.gmpClickable = newProps.gmpClickable
    raw.content = newProps.content
  }
}, { immediate: true })

onUnmounted(() => {
  const val = toRaw(data.markerMapObject)

  if (val) {
    val.setMap(null)
    data.markerMapObject = null
  }
})
</script>

<template>
    <div class="wrapper_div">
        <div>
            <label>Head Pressure Limits</label>
        </div>

        <div class="pressure_input">
            <label for="pressure_range_edit_min_head" title="Min pressure in head feet"
                :class="minHeadInvalidClass">Min:</label>
            <input type="number" min="0.1" name="min_head" id="pressure_range_edit_min_head" v-model.number="minValue"
                ref="minHeadPressure" step="0.1" :class="minHeadInvalidClass"> FT
        </div>

        <div class="pressure_input">
            <label for="pressure_range_edit_max_head" title="Max pressure in head feet"
                :class="maxHeadInvalidClass">Max:</label>
            <input id="pressure_range_edit_max_head" type="number" min="0.1" name="max_head" v-model.number="maxValue"
                ref="maxHeadPressure" step="0.1" :class="maxHeadInvalidClass"> FT
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const minValue = computed({
    get() {
        return props.modelValue.min
    },
    set(value) {
        emit('update:modelValue', { min: value, max: props.modelValue.max })
    }
})

const maxValue = computed({
    get() {
        return props.modelValue.max
    },
    set(value) {
        emit('update:modelValue', { max: value, min: props.modelValue.min })
    }
})

const minHeadInvalidClass = computed(() => {
    const val = props.modelValue.min
    if (val == null || (!Number.isFinite(val))) {
        return ''
    }

    const other = props.modelValue.max
    if ((other != null) && Number.isFinite(other) && (val > other)) {
        return 'invalid_head'
    }

    return ''
})

const maxHeadInvalidClass = computed(() => {
    const val = props.modelValue.max
    if (val == null || (!Number.isFinite(val))) {
        return ''
    }

    if ((props.modelValue.min != null) && Number.isFinite(props.modelValue.min) && (val < props.modelValue.min)) {
        return 'invalid_head'
    }

    return ''
})
</script>


<style lang="css" scoped>
input[type=number] {
    width: 3em;
}

.wrapper_div {
    display: flex;
    flex-basis: auto;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    row-gap: var(--general-padding);
    column-gap: var(--general-padding);
    font-size: small;
}

.invalid_head {
    color: var(--error-red);
    font-weight: bold;
}

.head_pressure_limits {
    white-space: nowrap;
}

.pressure_input {
    width: 100%;
}

.pressure_input>label {
    width: 2em;
}

.pressure_input>input {
    width: 4em;
    margin-left: 1em;
    text-align: left;
}
</style>
<template></template>

<script setup>
import { ZIndexes } from '@/maps/ZIndexes'
import { GeoUtil } from '../../geo/GeoUtil';
import { computed, inject, watch, reactive, toRaw, onUnmounted } from 'vue';

import { useMaps } from '@/composables/useMaps';

const props = defineProps({
   geoJson: {
      type: Object,
      required: true
   }
})

const data = reactive({
   mapObject: null
})

const $store = inject('$store')

const visible = computed(() => !$store.state.mapObjects.editingFarm)

const maps = useMaps()

function mapReady() {
   if (data.mapObject) {
      data.mapObject.setMap(null)
      toRaw(data.mapObject).setMap(null)
      data.mapObject = null
   }

   const mapObject = new maps.api.Data()

   data.mapObject = mapObject

   mapObject.setDrawingMode(null)
   mapObject.setControls(null)
   mapObject.setMap(toRaw(maps.map))
   mapObject.addGeoJson(props.geoJson)
   mapObject.setStyle({
      fillColor: GeoUtil.randomColor(),
      fillOpacity: 0.5,
      clickable: true,
      title: props.geoJson.properties?.name || "Untitled",
      zIndex: ZIndexes.Fields,
      strokeColor: 'yellow',
      strokeWeight: 5,
      icon: {
         path: maps.api.SymbolPath.CIRCLE,
         fillColor: 'yellow',
         fillOpacity: 0.35,
         strokeWeight: 0,
         scale: 8,
         zIndex: ZIndexes.Fields
      },
      visible: visible.value
   })

   mapObject.addListener('click', () => {
      $store.dispatch('enterAddFieldMode', props.geoJson)
   })

}

onUnmounted(() => {
   maps.removeMapObject(data.mapObject)
   data.mapObject = null
})

watch(props, mapReady, { immediate: true })
</script>

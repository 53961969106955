<template>
  <div id="field_menus">
    <BoundsMenu />
    <FurrowMenu class="button_margin_top" />


    <icon-button v-if="editLeveesAvailable" label="Levees" description="Levees" @click="enterEditLeveesMode()"
      class="button_margin_top"><img :src="EditLeveeIcon" :draggable="false" /></icon-button>

    <icon-button description="Field Settings" label="Field Settings" class="button_margin_top"
      v-show="addPipePathAvailable" v-on:click="openFieldSettingsDialog()"><img :src="PipeSettingsIcon"
        :draggable="false" /></icon-button>

    <icon-button class="button_margin_top" label="Add Water Source" title="Add Water Source" faIcon="fa-tint"
      v-show="addWaterSourceAvailable" v-on:click="enterAddIrrigationSystemMode()" />

    <icon-button description="Add Pipe" label="Add Pipe" class="button_margin_top" v-show="addPipePathAvailable"
      v-on:click="enterAddPipePathMode()"><img :src="AddIrrigationSystemIcon" :draggable="false" /></icon-button>

    <icon-button class="button_group_margin_top" description="Remove Field" label="Remove Field" faIcon="fa-minus-circle"
      faColorClass="cancel_red" @click="removeField" />
  </div>
</template>

<script>
import FurrowMenu from '@/components/field_menus/FurrowMenu'
import BoundsMenu from '@/components/field_menus/BoundsMenu'
import RemoveFieldDialog from './RemoveFieldDialog'
import Pp4Poly from '../maps/Poly'
import FieldSettingsDialog from '@/components/maps/irrigation_systems/FieldSettingsDialog'
import { FieldMode } from '@/store/Dto'

import PipeSettingsIcon from '@/assets/settings-gears.svg'
import AddIrrigationSystemIcon from '@/assets/add_irrigation_system_icon.png'
import EditLeveeIcon from '@/assets/edit_levee_icon.svg'

export default {
  components: {
    FurrowMenu,
    BoundsMenu,
    Pp4Poly
  },
  data: function () {
    return {
      AddIrrigationSystemIcon,
      PipeSettingsIcon,
      EditLeveeIcon
    }
  },
  methods: {
    enterEditLeveesMode() {
      this.$store.dispatch('enterEditLeveesMode')
    },
    async removeField() {
      await this.$dialog.push(RemoveFieldDialog)
    },
    async openFieldSettingsDialog() {
      const farm = this.$store.state.selectedFarm
      const field = this.$store.state.selectedField
      const fieldLayout = this.$store.state.selectedFieldLayout
      const irrigationSystems = this.$store.state.mapObjects.selectableIrrigationSystems
      if (field == null) {
        return
      }

      await this.$dialog.push(FieldSettingsDialog, {
        farm,
        field,
        fieldLayout,
        irrigationSystems
      })
    },
    enterAddIrrigationSystemMode() {
      this.$store.dispatch('enterAddIrrigationSystemMode')
    },
    enterAddPipePathMode() {
      this.$store.dispatch('enterAddPipePathMode')
    }
  },
  mounted() { },
  computed: {
    addPipePathAvailable: (vm) =>
      vm.$store.state.mapObjects.availableMapActions.EnterAddPipePathMode,
    addWaterSourceAvailable: (vm) =>
      vm.$store.state.mapObjects.availableMapActions.EnterAddIrrigationSystemMode,
    editLeveesAvailable: (vm) =>
      vm.$store.state.selectedField &&
      vm.$store.state.selectedFieldLayout.mode === FieldMode.Levees &&
      vm.$store.state.mapObjects.availableMapActions.EnterEditLeveesMode,
    selectableIrrigationSystems: (vm) => vm.$store.state.mapObjects.selectableIrrigationSystems
  }
}
</script>

<style scoped></style>

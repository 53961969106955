<template>
  <fullpageform title="Login As User" cancelLabel="Cancel" @cancel="cancel">
        <filter-users @userSelected="userClicked" />
  </fullpageform>
</template>

<script>
import FilterUsers from './FilterUsers.vue'
export default {
    components: {
        FilterUsers
    },
    methods: {
        async userClicked(user) {
            await this.$store.dispatch('setPuppetUser', user)
            this.$dialog.pop()       
        },
        cancel: function() {
         this.$dialog.pop()  
        }
    },
    mounted: async function() {
    }
}
</script>

<template>
    <div id="field_nav_menu" v-show="shown">
       <icon-button 
        description="Prev Field" label="Previous Field" class="" 
        faIcon="fa-arrow-left"
        v-on:click="prev()"/>
       <icon-button 
        description="Next Field" label="Next<br>Field" 
        class="button_margin_left" 
        faIcon="fa-arrow-right"
        v-on:click="next()"/>
    </div>
</template>

<script>
export default {
    components: {
    },
    methods: {
        prev() {
            this.$store.dispatch('selectPreviousField')
        },
        next() {
            this.$store.dispatch('selectNextField')
        }
    },
    computed: {
        shown: (vm) => {
            const count = vm.$store.state.selectedFarm?.fields?.length
            if(! count) {
                return false
            }

            if((count === 1) && vm.$store.state.selectedField) {
                return false
            }

            return true
        }
    }
}
</script>

<style scoped>
#field_nav_menu {
    display: flex;
    flex-direction: row;
}
</style>

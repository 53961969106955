import { DesignParams, HoleSide} from "./DesignDto"
import { GeoUtil } from '@/geo/GeoUtil'
import { FieldGeoFactory, FurrowLineArray } from "./FieldGeoFactory"

import { PipeSegment, WaterableArea, DesignSteps, InlineTee, SurgeValve,
    runTheWizard, AllHoles, AllGates, getSuggestedPipeDiameter, getSuggestedHeadRange,
    BuildPunchedHolesFromSizes, Pp3PunchedHolePicker, Pp3GatePicker, PipeType,
    TiedOffEnd, HoleDesignError,
    SupplyDesignStep} from '@pp4/design'
import { FieldMode, Direction } from "../store/Dto"
import PipePathAlg from '@/design/PipePathAlg'

class DesignParamsFactory {
    constructor(preferences) {
        this.fieldGeoFactory = new FieldGeoFactory(preferences)

        const designPreferences = (preferences == null || preferences.debug == null || preferences.debug.design == null) ? false : preferences.debug.design
        const furrowPreferences = (preferences == null || preferences.furrows == null) ? false : preferences.furrows
        this.showPipeIntersectionPoints = designPreferences && designPreferences.showPipeIntersectionPoints
        this.showPipeClippingSteps = designPreferences && designPreferences.showPipeClippingSteps
    }

    buildSortedAndFilteredDesignStepsByPipePathIntersectionPointsAndAddSupplyLength(designSteps, pipePathLatLngs, debugPolys) {
        let steps = designSteps.steps

        steps = steps.filter((step) => {
            return step.pipeEnterGeoJsonPoint != null
        })

        let pipePathGeoJson = GeoUtil.LatLngs.toLineString(pipePathLatLngs)

        steps.sort((a, b) => {
            let aDistance = GeoUtil.GeoJson.pointDistanceFromStartOfLine(a.pipeEnterGeoJsonPoint, pipePathGeoJson)
            let bDistance = GeoUtil.GeoJson.pointDistanceFromStartOfLine(b.pipeEnterGeoJsonPoint, pipePathGeoJson)

            return aDistance < bDistance ? -1 : 1
        })

        // add supply length
        {
            let lastOutletCoord = GeoUtil.LatLngs.toCoord(pipePathLatLngs[0])

            steps.forEach((step, i) => {
                const enterPoint = step.pipeEnterGeoJsonPoint
                const enterCoord = GeoUtil.GeoJson.getCoord(enterPoint)

                this.addPipeClippedStepIfNecessary(lastOutletCoord, enterCoord, debugPolys)

                const feetFromLastOutlet = GeoUtil.GeoJson.coordDistanceOnLine(lastOutletCoord, 
                    enterCoord, pipePathGeoJson, {units: 'feet'})

                const priorPipeSectionLength = (i === 0) ? 
                    0 : steps[i - 1].pipeLengthInFeet

                step.supplyLengthInFeet = feetFromLastOutlet - priorPipeSectionLength
                if(step.supplyLengthInFeet < 1) {
                    step.supplyLengthInFeet = 0
                }

                lastOutletCoord = enterCoord
            })
        }

        designSteps.steps = steps

        return designSteps
    }

    addPipeClippedStepIfNecessary(coord1, coord2, debugPolys) {
        if(! this.showPipeClippingSteps) {
            return
        }

        const lineString = GeoUtil.Coords.toLineString([coord1, coord2])

        let debugPoly = JSON.parse(JSON.stringify(lineString))
        debugPoly.properties.strokeColor = '#FFC310'
        debugPoly.properties.fillColor = '#FFC310'
        debugPoly.properties.infoWindowContent = 'Pipe Clip Step'
        debugPolys.push(debugPoly)
    }

    filterPipeSectionsNotMeetingAreaRequirement(designParams, fieldLayout) {
        let fieldGeo = GeoUtil.LatLngs.toPolygon(fieldLayout.path)
        let fieldArea = GeoUtil.GeoJson.area(fieldGeo)
        const MinSectorAreaInSquareMeters = fieldArea * 0.01
        designParams.pipeSections = designParams.pipeSections.filter((section) => {
            let geoArea = GeoUtil.GeoJson.area(section.polyGeoJson)
            return geoArea > MinSectorAreaInSquareMeters
        })
    }

    buildFurrowDesignParamsAndDebugPolys(fieldLayout, pipePath, irrigationSystem) {
        let debugPolys = []
        const designParams = new DesignParams()
        const designSteps = new DesignSteps()
        let currentDesignSteps = designSteps
        const stepsStack = [currentDesignSteps]
        let currentJunction = null
        const junctionsStack = []
        
        const flowRate = irrigationSystem.flowRates.find((flowRate) => flowRate.id === pipePath.flowRateId)
        if(! flowRate) {
            throw new Error('Flow Rate Not Found: ' + pipePath.flowRateId)
        }

        const MinimumSupplyLengthInFeet = 10
        const suggestedPipeDiameterInInches = getSuggestedPipeDiameter(flowRate.valueInGpm)
        const suggestedPipeType = new PipeType(suggestedPipeDiameterInInches, 10)
        let hasWateredAreas = false
        let hasTiedOffEnd = false

        const stepBuilder = () => {
            let activeSupplyLength = 0
            let activeSupplyElevationDifferenceInFeet = 0
            let activePipeType = suggestedPipeType

            const pushSupplyStepIfNecessary = () => {
                if(activeSupplyLength <= 0) {
                    return;
                }

                const step = new SupplyDesignStep(activeSupplyLength, 
                    activePipeType.diameterInInches,
                    activeSupplyElevationDifferenceInFeet,
                    activePipeType.thicknessInMils)
                currentDesignSteps.steps.push(step)

                activeSupplyLength = 0
                activeSupplyElevationDifferenceInFeet = 0
            };

            return {
                supply(length, elevationDifferenceInFeet, pipeType) {
                    if(length <= 0) {
                        return
                    }

                    const assumedPipeType = pipeType || activePipeType;
                    
                    if(activeSupplyLength > 0) {
                        const pipeTypeEqual = 
                            (assumedPipeType.diameterInInches === activePipeType.diameterInInches)
                            && (assumedPipeType.thicknessInMils === activePipeType.thicknessInMils)

                        if(! pipeTypeEqual) {
                            pushSupplyStepIfNecessary();                            

                            activeSupplyLength = length
                            activeSupplyElevationDifferenceInFeet = elevationDifferenceInFeet
                            activePipeType = assumedPipeType

                            return
                        }
                    }
        
                    activeSupplyLength += length
                    activeSupplyElevationDifferenceInFeet += elevationDifferenceInFeet
                    activePipeType = assumedPipeType
                },
                furrow(lineString) {
                    pushSupplyStepIfNecessary();

                    hasWateredAreas = true

                    const furrowLengthInFeet = GeoUtil.GeoJson.length(lineString, {units: "feet"})
                    const furrowCoords = GeoUtil.GeoJson.getCoords(lineString)
                    const lineStringProperties = lineString.properties
                    const holeSide = lineStringProperties.holeSide
                    let pipeType = lineStringProperties.pipeType
                    if(! pipeType) {
                        pipeType = suggestedPipeType
                    }

                    const pipeLengthInFeet = lineString.properties.pipeWidthInInches / 12.0
                    
                    const areaInSquareFeet = furrowLengthInFeet *
                        (fieldLayout.furrowSpacingInInches 
                            * (fieldLayout.alternatingFurrows ? 2.0 : 1.0)
                            / 12.0)

                    const pipeSegment = new PipeSegment(
                        pipeLengthInFeet, 
                        pipeType.diameterInInches)
                    pipeSegment.thicknessInMils = pipeType.thicknessInMils
                    const elevationDifferenceInFeet = lineString.properties.elevationDifferenceInFeet

                    const step = new WaterableArea(areaInSquareFeet,
                        elevationDifferenceInFeet, pipeSegment)

                    // not part of WaterableArea...
                    step.pipeEnterGeoJsonPoint = GeoUtil.Coords.toPoint(furrowCoords[0])
                    step.holeSide = holeSide
                    step.pipeLengthInFeet = pipeLengthInFeet
                    step.supplyLengthInFeet = 0
                    // ret.polyGeoJson = ls // conserving space for now

                    currentDesignSteps.steps.push(step)
                },
                tiedOffEnd() {
                    currentDesignSteps.steps.push(new TiedOffEnd())

                    hasTiedOffEnd = true
                },
                pushJunction(junctionType) {
                    const branches = []
                    const pipeSegment = new PipeSegment(activeSupplyLength, activePipeType.diameterInInches)
                    pipeSegment.thicknessInMils = activePipeType.thicknessInMils

                    if(junctionType === 'inline_tee') {
                        currentJunction = new InlineTee(branches, pipeSegment, activeSupplyElevationDifferenceInFeet)
                    }
                    else if(junctionType === 'surge_valve') {
                        currentJunction = new SurgeValve(branches, pipeSegment, activeSupplyElevationDifferenceInFeet)
                    }
                    else {
                        throw new Error('Unknown Junction Type: ' + junctionType)
                    }

                    junctionsStack.push(currentJunction)

                    currentDesignSteps.steps.push(currentJunction)

                    activeSupplyLength = 0
                    activeSupplyElevationDifferenceInFeet = 0
                },
                popJunction() {
                    currentJunction.branches.sort((a, b) => {
                        if(a.direction === b.direction) {
                            return 0
                        }

                        return a.direction === Direction.Left ?
                            -1 : 1
                    })

                    junctionsStack.pop()

                    currentJunction = junctionsStack.length > 0 ?
                        junctionsStack[junctionsStack.length - 1] : null
                },
                pushJunctionBranch(direction) {
                    let branchName = PipePathAlg.junctionTypeLabel(currentJunction.junctionType)
                    if(direction) {
                        branchName += ((direction === Direction.Left) ? ' Left' : ' Right')
                    }
                    if(stepsStack.length > 1) {
                        const parentSteps = stepsStack[
                            stepsStack.length - 1]
                        branchName += ' (of '+ parentSteps.name + ')'
                    }

                    const branch = new DesignSteps()
                    branch.name = branchName
                    branch.direction = direction
                    currentJunction.branches.push(branch)

                    stepsStack.push(branch)
                    currentDesignSteps = branch
                },
                popJunctionBranch() {
                    stepsStack.pop()
                    currentDesignSteps = stepsStack[stepsStack.length - 1]
                },
                done() {
                }
            }
        }

        this.fieldGeoFactory.traversePipePathWithFurrows(fieldLayout, pipePath, stepBuilder(), debugPolys)

        designParams.designSteps = designSteps

        if(! hasWateredAreas) {
            designParams.holeDesign = {
                error: {code: 'check_furrow_direction'}
            }

            return {designParams, debugPolys}
        }

        this.addHoleOutletsAndPipeDiameter(designParams, 
            fieldLayout, pipePath, irrigationSystem, flowRate, 
            { enableBuildups: hasTiedOffEnd ? false : true})

        return {designParams, debugPolys}
    }

    addHoleOutletsAndPipeDiameter(designParams, fieldLayout, pipePath, 
        irrigationSystem, flowRate, wizardOptionsOverrides) { 
        const defaultWizardOptions = {
            mergeOutletCountsLowerThan: 6,
            outletPicker: fieldLayout.mode === FieldMode.Furrows ? 
                Pp3PunchedHolePicker : Pp3GatePicker,
            forceBuildupsMode: false,
            storyCallback() { }
        }
        const wizardOptions = Object.assign({}, 
            defaultWizardOptions, wizardOptionsOverrides)
        
        const pipeDiameterInInches = getSuggestedPipeDiameter(flowRate.valueInGpm)
        const headRange = getSuggestedHeadRange(pipeDiameterInInches, 10) // hard-coding to ten mils for now, maybe change from getsuggestedpipediameter to getsuggestedpipeproduct?

        // See DataService.cs, GetPipeSegmentsForSet()
        if(fieldLayout.mode === FieldMode.Levees) {
            // This moved back to the Field level from PipePath
            headRange[0] = (fieldLayout.leveeHeightInInches - fieldLayout.fallPerLeveeInInches) / 12
        }

        const minHeadInFeet = pipePath.minHeadInFeet ?
            pipePath.minHeadInFeet : headRange[0]
        const maxHeadInFeet = pipePath.maxHeadInFeet ? 
            pipePath.maxHeadInFeet : headRange[1]

        let availableOutlets = []
        if(fieldLayout.mode === FieldMode.Furrows) {
            availableOutlets = (('availableHoleSizes' in pipePath) && (pipePath.availableHoleSizes.length > 0)) ?
                BuildPunchedHolesFromSizes(pipePath.availableHoleSizes) : AllHoles
        }
        else if(fieldLayout.mode === FieldMode.Levees) {
            availableOutlets = AllGates
        }

        try {
            const holeDesign = runTheWizard(designParams.designSteps, 
                flowRate.valueInGpm, minHeadInFeet, maxHeadInFeet, availableOutlets,
                wizardOptions)

            designParams.holeDesign = holeDesign
        }
        catch(e) {
            if(designParams.holeDesign.error.code == 'check_furrow_direction'){
                const error = {
                    code: 'check_furrow_direction'
                }

                designParams.holeDesign = {
                    error
                }

            } else {
                const error = {
                    code: 'calculation_exception',
                    details: e.toString()
                }
    
                designParams.holeDesign = {
                    error,
                    errorString: 'toString' in e ? e.toString() : null
                }
            }
        }
    }

    buildLeveeDesignParamsAndDebugPolys(fieldLayout, pipePath, irrigationSystem) {
        let debugPolys = []
        const designParams = new DesignParams()
        const designSteps = new DesignSteps()
        let currentDesignSteps = designSteps
        const stepsStack = [currentDesignSteps]
        let currentJunction = null
        const junctionsStack = []

        const flowRate = irrigationSystem.flowRates.find((flowRate) => flowRate.id === pipePath.flowRateId)
        if(! flowRate) {
            throw new Error('Flow Rate Not Found: ' + pipePath.flowRateId)
        }

        const suggestedPipeDiameterInInches = getSuggestedPipeDiameter(flowRate.valueInGpm)
        const suggestedPipeType = new PipeType(suggestedPipeDiameterInInches, 10)

        const stepBuilder = () => {
            let activeSupplyLength = 0
            let activeSupplyElevationDifferenceInFeet = 0
            let activeSupplyPipeType = suggestedPipeType

            return {
                supply(lengthInFeet, elevationDifferenceInFeet, pipeType) {
                    if(lengthInFeet <= 0) {
                        return
                    }
        
                    activeSupplyLength += lengthInFeet
                    activeSupplyElevationDifferenceInFeet += elevationDifferenceInFeet
                    activeSupplyPipeType = pipeType ? pipeType : suggestedPipeType

                    // TODO: we are not handling the case where the supply changes before the first furrow!
                },
                levee(geo) {
                    let pipeType = geo.properties.pipeType
                    if(! pipeType) {
                        pipeType = activeSupplyPipeType
                    }

                    const areaInSquareMeters = GeoUtil.GeoJson.area(geo)
                    const areaInSquareFeet = areaInSquareMeters * 10.76391

                    const pipeSegment = new PipeSegment(
                        activeSupplyLength + geo.properties.pipeLengthInFeet,
                        pipeType.diameterInInches)

                    pipeSegment.thicknessInMils = pipeType.thicknessInMils
                    const elevationDifferenceInFeet = 
                        geo.properties.elevationDifferenceInFeet + activeSupplyElevationDifferenceInFeet

                    const step = new WaterableArea(areaInSquareFeet,
                        elevationDifferenceInFeet, pipeSegment)

                    // not part of WaterableArea...
                    step.pipeEnterGeoJsonPoint = geo.properties.pipeEnterGeoJsonPoint
                    step.holeSide = geo.properties.holeSide
                    step.pipeLengthInFeet = pipeSegment.lengthInFeet
                    step.supplyLengthInFeet = activeSupplyLength
                    // ret.polyGeoJson = ls // conserving space for now

                    currentDesignSteps.steps.push(step)

                    activeSupplyLength = 0
                    activeSupplyElevationDifferenceInFeet = 0
                },
                pushJunction(junctionType) {
                    const branches = []
                    const pipeSegment = new PipeSegment(activeSupplyLength, activeSupplyPipeType.diameterInInches)
                    pipeSegment.thicknessInMils = activeSupplyPipeType.thicknessInMils

                    if(junctionType === 'inline_tee') {
                        currentJunction = new InlineTee(branches, pipeSegment)
                    }
                    else if(junctionType === 'surge_valve') {
                        currentJunction = new SurgeValve(branches, pipeSegment)
                    }
                    else {
                        throw new Error('Unknown Junction Type: ' + junctionType)
                    }

                    junctionsStack.push(currentJunction)

                    currentDesignSteps.steps.push(currentJunction)

                    activeSupplyLength = 0
                },
                popJunction() {
                    currentJunction.branches.sort((a, b) => {
                        if(a.direction === b.direction) {
                            return 0
                        }

                        return a.direction === Direction.Left ?
                            -1 : 1
                    })

                    junctionsStack.pop()

                    currentJunction = junctionsStack.length > 0 ?
                        junctionsStack[junctionsStack.length - 1] : null
                },
                pushJunctionBranch(direction) {
                    const directionString = (direction === Direction.Left) ?
                        'Left' : 'Right'

                    const branch = new DesignSteps()
                    branch.name = directionString + ' Path'
                    branch.direction = direction
                    currentJunction.branches.push(branch)

                    stepsStack.push(branch)
                    currentDesignSteps = branch
                },
                popJunctionBranch() {
                    stepsStack.pop()
                    currentDesignSteps = stepsStack[stepsStack.length - 1]
                },
                done() {
                }
            }
        }

        this.fieldGeoFactory.traversePipePathWithLevees(
            fieldLayout, pipePath, stepBuilder(), debugPolys)

        designParams.designSteps = designSteps

        this.addHoleOutletsAndPipeDiameter(
            designParams, fieldLayout, pipePath, irrigationSystem, flowRate,
            {
                mergeOutletCountsLowerThan: Number.MAX_VALUE,
                forceBuildupMode: true,
                performHeadAdjustments: false
            })

        return {designParams, debugPolys}
    }

    addDebugPolys(designParams, debugPolys, pipePathLatLngs) {
        // designParams.pipeSections.forEach((section, sectionIndex) => {
        //     let areaInAcres = Math.round(0.0002471052 * section.area)
        //     const debugPolyGeoJson = JSON.stringify(section.polyGeoJson)
        //     let debugPoly = JSON.parse(debugPolyGeoJson)
        //     debugPoly.properties.strokeColor = 'skyblue'
        //     debugPoly.properties.fillColor = 'skyblue'
        //     debugPoly.properties.infoWindowContent = ('Index: ' + sectionIndex + ', ' + areaInAcres + 'ac.')
        //     debugPoly.properties.infoWindowContent += '<br>Pipe Enter Point:'
        //     debugPoly.properties.infoWindowContent += "<br><textarea style='width: 24em; height: 8em;'>" + JSON.stringify(section.pipeEnterGeoJsonPoint) + "</textarea>"

        //     let pipePathGeoJson = GeoUtil.LatLngs.toLineString(pipePathLatLngs)

        //     if(section.pipeEnterGeoJsonPoint != null) {
        //         let pipeStartDistance = GeoUtil.GeoJson.pointDistanceFromStartOfLine(section.pipeEnterGeoJsonPoint, pipePathGeoJson)
        //         debugPoly.properties.infoWindowContent += '<br>Start @ ' + pipeStartDistance
        //     }

        //     if(section.pipeLengthInFeet) {
        //         debugPoly.properties.infoWindowContent += '<br>Pipe Length: ' + section.pipeLengthInFeet + 'ft'
        //     }

        //     if(section.pipeLengthInFeet) {
        //         debugPoly.properties.infoWindowContent += '<br>Pipe width: ' + section.pipeLengthInFeet + ' ft.'
        //         debugPoly.properties.label = section.outlets ? section.outlets.reduce((accum, outlet) => {
        //             return accum == null ? outlet.toString() : ", " + outlet.toString()
        //         }, null) : "No Outlets"
        //         debugPoly.properties.label += " -- " + section.pipeDiameterInInches + '"'
        //         debugPoly.properties.labelClass = 'design_poly_label'
        //     }
        //     if(section.leftSideLengthInFt) {
        //         debugPoly.properties.infoWindowContent += '<br>Left Side: ' + section.leftSideLengthInFt + 'ft'
        //     }
        //     if(section.rightSideLengthInFt) {
        //         debugPoly.properties.infoWindowContent += '<br>Right Side: ' + section.rightSideLengthInFt + 'ft'
        //     }

        //     if(section.rateOfChange != null) {
        //         debugPoly.properties.infoWindowContent += '<br>Rate of Change: ' + section.rateOfChange
        //     }

        //     debugPoly.properties.infoWindowContent += "<br><textarea style='width: 24em; height: 8em;'>" + debugPolyGeoJson + "</textarea>"

        //     debugPolys.push(debugPoly)
        // })
    }
}

export {
    DesignParamsFactory
}

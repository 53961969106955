import * as JdUtil from '@/util/JdUtil'

const JohnDeereState = {
    Retrieving: 1,
    NotEstablished: 2,
    Established: 3
}
Object.freeze(JohnDeereState)

function DefaultState(state) {
    state.state = JohnDeereState.Retrieving
    state.syncUri = null
    return state
}

function BuildJohnDeereStore(api) {
    return {
        namespaced: true,
        state: DefaultState({}),
        mutations: {
            setState(state, value) {
                state.state = value
            },
            setSyncUri(state, value) {
                state.syncUri = value
            }
        },
        getters: {
            getSelectOrganizationsUri: () => async () => {
                return await api.jd.getSelectOrganizationsUri()
            },
            getPpFarmAndField: () => async (orgId, fieldId, boundaryId, suggestedFieldName, path) => {
                return await api.jd.getPpFarmAndFieldForJdObjects(orgId, fieldId, boundaryId, suggestedFieldName, path)
            },
            getFieldMapLayerSummaries: () => async (orgId, fieldId) => {
                return await api.jd.getFieldMapLayerSummaries(orgId, fieldId)
            },
            getFieldGuidanceLines: () => async (orgId, fieldId) => {
                const jdLines = await api.jd.getFieldGuidanceLines(orgId, fieldId)

                return JdUtil.guidanceLinesToPaths(jdLines)
            }
        },
        actions: {
            async init({ commit }) {
                let jdState = null
                try {
                    jdState = await api.jd.getState()

                    commit('setState', jdState.synced ?
                        JohnDeereState.Established : JohnDeereState.NotEstablished)
                    commit('setSyncUri', jdState.syncUri)
                }
                catch (e) {
                    alert('Failed to Get John Deere State')
                    console.error(e)

                    return
                }
            },
            async disconnect({ commit, dispatch }) {
                await api.jd.disconnect()

                commit('setState', JohnDeereState.NotEstablished)

                dispatch('init')
            }
        }
    }
}

export {
    BuildJohnDeereStore, JohnDeereState
}

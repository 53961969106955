<template>
  <div>
    <levee-path v-for="pathWithKey in existingLeveePathsWithKey" :key="pathWithKey.key" :path="pathWithKey.path"
      :clickable="false" :showInPreviewMode="false" />

    <template v-if="leveePathBeingAddedPath.length > 0">
      <levee-path :path="leveePathBeingAddedPath" :snapCallback="data.snapCallback" selectionStyle="selected"
        :clickable="false" :showInPreviewMode="true" />
    </template>

    <levee-path v-if="previewLinePath" selectionStyle="selected" :clickable="false" :showInPreviewMode="true"
      :path="previewLinePath" />

    <distance-hint v-if="data.cursorLatLng" :latLng="distanceHintLatLng"
      :segmentPathsToCompare="distanceHintSegmentPaths" />
  </div>
</template>

<script setup>
import DistanceHint from '@/components/maps/edit_levees/DistanceHint'
import LeveePath from '@/components/maps/irrigation_systems/LeveePath'
import Vars from '@/Vars'
import { BuildLatLngSnapCallback } from '@/geo/PipeSnapAlg'
import { GeoUtil } from '@/geo/GeoUtil'
import { LatLng, GeometryEditMode } from '@/store/Dto'

import { useMaps } from '../../../composables/useMaps'
import { useStore } from 'vuex'
import { reactive, onMounted, computed, onBeforeUnmount, toRaw, defineModel } from 'vue'

const model = defineModel()

const maps = useMaps()

const store = useStore()

const props = defineProps({
  snapActive: {
    type: Boolean,
    required: true
  },
  removeActive: {
    type: Boolean,
    required: true
  },
  fieldLayout: {
    type: Object,
    required: true
  },
})

const data = reactive({
  leveePathBeingAdded: [],
  mouseOverMarkerMapObject: null,
  mouseOverMarkerMapObjects: [],
  cursorLatLng: null,
  snapCallback: null
})

const existingLeveePathsWithKey = computed(() =>
  model.value.map((path, i) => {
    return { path, key: 'ExistingLeveePath:' + i }
  })
)

const leveePathBeingAddedPath = computed(() => {
  return data.leveePathBeingAdded.length ? data.leveePathBeingAdded : []
})

const previewLinePath = computed(() => {
  if (data.cursorLatLng == null) {
    return null
  }

  if (data.leveePathBeingAdded.length === 0) {
    return null
  }

  const lastPoint = data.leveePathBeingAdded[data.leveePathBeingAdded.length - 1]

  return [lastPoint, data.cursorLatLng]
})

const fieldGeo = computed(() => GeoUtil.LatLngs.toPolygon(store.state.selectedFieldLayout.path))

const leveePathBeingAddedLengthIncludingPreviewInFeet = computed(() => {
  let ret = GeoUtil.LatLngs.length(data.leveePathBeingAdded, { units: 'feet' })

  const previewLinePath = previewLinePath
  if (previewLinePath != null) {
    ret += GeoUtil.LatLngs.length(previewLinePath, { units: 'feet' })
  }

  return ret
})

const editMode = computed(() =>
  props.removeActive ? GeometryEditMode.RemovePoints : GeometryEditMode.EditPoints
)

const distanceHintLatLng = computed(() => data.cursorLatLng)

const distanceHintSegmentPaths = computed(() => {
  const ret = []

  let seg = []
  props.fieldLayout.path.forEach((ll) => {
    seg.push(ll)
    if (seg.length === 2) {
      ret.push(seg)
      seg = [ll]
    }
  })

  // loop back to last point
  if (seg.length > 0 && !GeoUtil.LatLngs.pointsEqual(seg[0], props.fieldLayout.path[0])) {
    seg.push(props.fieldLayout.path[0])
    ret.push(seg)
  }

  return ret
})

onMounted(() => {
  store.dispatch('mapObjects/setHelpKey', 'add_levee_path_begin')

  const fieldOffsetInMeters = 0

  const longSnapCallback = BuildLatLngSnapCallback(
    store.state.preferences.levees.longSnapThresholdInMeters,
    fieldOffsetInMeters,
    [fieldGeo.value],
    { EnablePointGravity: false, OutsideBoundsAlwaysSnaps: true }
  )

  const shortSnapCallback = BuildLatLngSnapCallback(
    store.state.preferences.levees.shortSnapThresholdInMeters,
    fieldOffsetInMeters,
    [fieldGeo.value],
    { EnablePointGravity: false, OutsideBoundsAlwaysSnaps: true }
  )

  const ignoreSnapUntilLeveeIsThisManyFeetLong =
    store.state.preferences.levees.ignoreSnapUntilLeveeIsThisManyFeetLong
  const useShortSnapThresholdIfLastSegmentDistanceLessThanMeters =
    store.state.preferences.levees.useShortSnapThresholdIfLastSegmentDistanceLessThanMeters

  data.snapCallback = (ll) => {
    if (!props.snapActive) {
      return ll
    }

    if (data.leveePathBeingAdded.length === 0) {
      return longSnapCallback(ll)
    }

    const coord = GeoUtil.LatLngs.toCoord(ll)

    if (!GeoUtil.GeoJson.booleanPointInPolygon(coord, fieldGeo.value)) {
      return longSnapCallback(ll)
    }

    if (leveePathBeingAddedLengthIncludingPreviewInFeet < ignoreSnapUntilLeveeIsThisManyFeetLong) {
      return ll
    }

    const lastCoord = GeoUtil.LatLngs.toCoord(
      data.leveePathBeingAdded[data.leveePathBeingAdded.length - 1]
    )

    // allow snap back to first point to create pockets
    if (data.leveePathBeingAdded.length > 2) {
      const firstCoord = GeoUtil.LatLngs.toCoord(data.leveePathBeingAdded[0])
      const distanceToFirstInMeters = GeoUtil.Coords.distance(coord, firstCoord, {
        units: 'meters'
      })
      if(distanceToFirstInMeters < store.state.preferences.levees.longSnapThresholdInMeters) {
        return Object.assign({}, data.leveePathBeingAdded[0])
      }
    }

    const lastSegmentLengthInMeters = GeoUtil.Coords.distance(coord, lastCoord, {
      units: 'meters'
    })
    if (lastSegmentLengthInMeters < useShortSnapThresholdIfLastSegmentDistanceLessThanMeters) {
      return shortSnapCallback(ll)
    }

    return longSnapCallback(ll)
  }

  mapReady()
})

const updateMouseOverMarkerMapObjectIfNecessary = () => {
  if (data.cursorLatLng == null) {
    const mapObject = data.mouseOverMarkerMapObjects.find(
      (mo) => mo == data.mouseOverMarkerMapObject
    )

    maps.removeMapObject(mapObject)
    data.mouseOverMarkerMapObject = null
    return
  }

  if (data.mouseOverMarkerMapObject != null) {
    data.mouseOverMarkerMapObject.setPosition(data.cursorLatLng)
  } else {
    data.mouseOverMarkerMapObject = maps.interpretationFactory.buildBoundsMarker(data.cursorLatLng)
    data.mouseOverMarkerMapObject.getIcon().strokeColor = Vars.TentativeFieldStrokeColor

    data.mouseOverMarkerMapObject.getIcon().fillColor = Vars.TentativeFieldStrokeColor
    data.mouseOverMarkerMapObject.setOptions({
      clickable: false
    })
    data.mouseOverMarkerMapObject.setClickable(false)
    // TODO: this.mapObjects?
    data.mouseOverMarkerMapObjects.push(data.mouseOverMarkerMapObject)
  }
}

onBeforeUnmount(() => {
  // maps.setOptions(this.googleMapUtils.defaultMapOptions())
})

const leveePathChanged = (index, newLeveePath) => {
  model.value[index] = newLeveePath

  const valueIndex = model.value.length + index

  model.value.splice(valueIndex, 1, newLeveePath)
}

const backoff = (callback, spacingInMilliseconds) => {
  let timeout = null
  let finalParam = null

  return (mostRecentParam) => {
    if (timeout != null) {
      finalParam = mostRecentParam
      return
    }

    const invokeCallback = function () {
      timeout = null
      callback(finalParam)
    }

    timeout = setTimeout(invokeCallback, spacingInMilliseconds)
  }
}

const mapReady = () => {
  maps.addMapListener(
    'mousemove',
    backoff((e) => {
      if (e == null) return
      const latLng = new LatLng(e.latLng.lat(), e.latLng.lng())
      const finalLatLng = data.snapCallback ? data.snapCallback(latLng) : latLng
      data.cursorLatLng = finalLatLng
      updateMouseOverMarkerMapObjectIfNecessary()
    }, 25)
  )

  maps.addMapListener('mouseout', (e) => {
    data.cursorLatLng = null
    updateMouseOverMarkerMapObjectIfNecessary()
  })

  const addLeveePathIfComplete = () => {
    if (data.leveePathBeingAdded.length < 2) {
      return
    }

    model.value.push(data.leveePathBeingAdded)
    data.leveePathBeingAdded = []

    store.dispatch('mapObjects/setHelpKey', 'add_levee_path_end')
  }

  maps.addMapListener('click', (e) => {
    const latLng = new LatLng(e.latLng.lat(), e.latLng.lng())
    const finalLatLng = props.snapActive ? data.snapCallback(latLng, fieldGeo) : latLng
    const coord = [finalLatLng.lng, finalLatLng.lat]

    data.cursorLatLng = null

    data.leveePathBeingAdded = [...data.leveePathBeingAdded, finalLatLng]

    const pathComplete = data.leveePathBeingAdded.length > 1
    const clickOutsideFieldBounds = !GeoUtil.GeoJson.booleanPointInPolygon(coord, fieldGeo.value, {
      ignoreBoundary: false
    })
    const pointSnapped = latLng !== finalLatLng
    if (pathComplete && (clickOutsideFieldBounds || pointSnapped)) {
      addLeveePathIfComplete()
    }

    const middleHelpKeyNeeded = data.leveePathBeingAdded.length === 1 && !pathComplete
    if (middleHelpKeyNeeded) {
      store.dispatch('mapObjects/setHelpKey', 'add_levee_path_middle')
    }
  })

  maps.addMapListener('dblclick', (e) => {
    data.cursorLatLng = null

    addLeveePathIfComplete()
  })
}
</script>

<style lang="css"></style>

<script>

import { GeoUtil } from '@/geo/GeoUtil'
import { ZIndexes } from '@/maps/ZIndexes'
import { LatLng } from '@/store/Dto'

import Pp4MapObject from '@/components/maps/Pp4MapObject'

export default {
    extends: Pp4MapObject,
    props: {
        latLng: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        fieldLayout: {
            type: Object,
            required: true
        }
    },
    computed: {
        fieldBounds: (vm) => GeoUtil.LatLngs.toPolygon(vm.fieldLayout.path)
    },
    watch: {
        latLng: 'invokeMapReadyIfAvailable',
        index: 'invokeMapReadyIfAvailable'
    },
    methods: {
        mapReady() {
            this.clearMapObjects()
            
            let marker = this.interpretationFactory.buildBoundsMarker(this.latLng)
            marker.setOptions({
                draggable: true,
                zIndex: ZIndexes.FurrowSets + 1
            })

            let dragEndCallback = (e) => {
                let coord = [e.latLng.lng(), e.latLng.lat()]

                if(GeoUtil.GeoJson.booleanPointInPolygon(coord, this.fieldBounds)) {
                    let latLng = new LatLng(e.latLng.lat(), e.latLng.lng())
                    this.$emit('split-point-moved', this.index, latLng)
                }
                else {
                    this.$emit('split-point-removed', this.index)
                }
            }

            let updateToRemoveIconIfOutsideFieldBounds = (e) => {
                let coord = [e.latLng.lng(), e.latLng.lat()]
                if(GeoUtil.GeoJson.booleanPointInPolygon(coord, this.fieldBounds)) {
                    marker.setOptions({
                        icon: this.interpretationFactory.boundsMarkerIcon()
                    })
                }
                else {
                    marker.setOptions({
                        icon: this.interpretationFactory.removeBoundsMarkerIcon()
                    })
                }
            }

            let dragCallback = (e) => {
                updateToRemoveIconIfOutsideFieldBounds(e)

                let latLng = new LatLng(e.latLng.lat(), e.latLng.lng())

                this.$emit('split-point-dragged', this.index, latLng)
            }

            this.api.event.addListener(marker, 'dragend', dragEndCallback)
            this.api.event.addListener(marker, 'drag', dragCallback)

            this.mapObjects.push(marker)
        }
    }
}
</script>

<template>
    <template>
        <pp4-field :fieldLayout="fieldLayout" :field="field" ref="field" :clickable="false" selectionStyle="selected"
            :showLeveePaths="false" :showLabel="false" />

        <pp4-polyline v-for="(o, i) in modelWithMetadata" :key="o.key" :strokeColor="Vars.SelectedFieldStrokeColor" :path="o.path" />

        <remove-marker v-for="o in removeMarkers" :key="o.key" :location="o.location" 
            @click="removePath(o.index)"/>
    </template>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import * as uuid from 'uuid'

import Pp4Field from '@/components/maps/Pp4Field'
import Pp4Polyline from '@/components/maps/Polyline'
import Vars from '@/Vars'
import { GeoUtil } from '@/geo/GeoUtil'


const store = useStore()

const props = defineProps({
    field: {
        type: Object,
        required: true
    },
    fieldLayout: {
        type: Object,
        required: true
    }
})

const model = defineModel({ default: [] })

onMounted(() => {
    store.dispatch('mapObjects/setHelpKey', 'remove_levee_paths')
})

const modelWithMetadata = computed(() => {
    return model.value.map((path) => {
        return { path, key: uuid.v1() }
    })
})

const getBestLocationForRemoveMarker = (p1, p2, markerLocations) => {
    let p1BestDistance = Number.MAX_VALUE
    let p2BestDistance = Number.MAX_VALUE

    markerLocations.forEach((latLng) => {
        p1BestDistance = Math.min(p1BestDistance, GeoUtil.LatLngs.distance(latLng, p1))
        p2BestDistance = Math.min(p2BestDistance, GeoUtil.LatLngs.distance(latLng, p2))
    })

    return p1BestDistance >= p2BestDistance ? p1 : p2
}

const removeMarkers = computed(() => {
    const ret = []
    
    model.value.forEach((path, index) => {
        let firstPoint = path[0]
        let lastPoint = path[path.length - 1]

        let bestPoint = getBestLocationForRemoveMarker(firstPoint, lastPoint, ret)

        ret.push({
            location: bestPoint,
            key: uuid.v1(), index
        })
    })

    return ret
})

function removePath(index) {
    model.value.splice(index, 1)
}
</script>

<style lang="css"></style>

<template>
   <div class="field_tile" :draggable="interactive" @dragstart="onDragStart">
      <label class="field_label" @click="routeClicked"> {{ field.name }}</label>
      <img :class="imgClasses" :src="fieldPreviewImageUrl" :draggable="false" @click="routeClicked" />
      <div v-if="interactive" class="field_menu_buttons">
         <router-link 
            v-if="!hasHoleDesign"
            class="button small"
            :to="{ path: '/FarmMapView/' + farm.id + '/' + field.id }"><i class="fa fa-edit"></i>Create Hole
            Design</router-link>

         <button class="small" v-show="hasHoleDesign" @click="showHoleDesign()">Hole
            Design</button>
         <button class="small" v-show="hasHoleDesign" @click="showWateringDialog()"><i
               class="fa fa-tint"></i>Irrigation</button>
      </div>
   </div>
</template>

<script>
import WateringDialog from '../components/WateringDialog'
import HoleDesignDialog from '../components/HoleDesignDialog'

export default {
   data: function () {
      return {

      }
   },
   components: {
   },
   props: {
      farm: {
         type: Object, required: true
      },
      field: {
         type: Object, required: true
      },
      interactive: {
         type: Boolean,
         required: false, default: true
      }
   },
   computed: {
      imgClasses: vm => vm.interactive ? "field_img clickable" : "field_img",
      fieldPreviewImageUrl: vm => {
         const layout = vm.field.layouts.find((layout) => layout.id == vm.field.activeLayoutId)
         if (!layout) {
            console.warn('Layout Not Found: ' + vm.field.activeLayoutId)
            return null
         }

         return vm.$staticImages.buildFieldUrl(layout)
      },
      hasHoleDesign: vm => {
         if (!vm.farm)
            return false;

         for (let irrigationSystem of vm.farm.irrigationSystems) {
            for (let pipePath of irrigationSystem.pipePaths) {
               if (pipePath.fieldId === vm.field.id && pipePath.fieldLayoutId === vm.field.activeLayoutId) {
                  if ((pipePath.designParams != null) && (pipePath.designParams.holeDesign != null)) {
                     return true
                  }
               }
            }
         }
         return false;
      },
      isThirdParty: vm => {
         return vm.field.jd || vm.farm.jd ? true : false
      }
   },
   watch: {
   },
   methods: {
      routeClicked() {
         if (!this.interactive) {
            return;
         }

         const to = { path: '/FarmMapView/' + this.farm.id + '/' + this.field.id };
         this.$router.push(to);
      },
      onDragStart(e) {
         // e.preventDefault();

         if (!this.interactive) {
            e.preventDefault()
            return;
         }

         if(this.isThirdParty) {
            e.preventDefault()
            return
         }

         e.dataTransfer.effectAllowed = "move"

         e.dataTransfer.setData('application/pp4-field', JSON.stringify({
            sourceFarm: this.farm, // need to send everything...
            fields: [this.field]
         }))

         e.dataTransfer.setData('text/plain', JSON.stringify(this.field));
      },
      async showWateringDialog() {
         const selectedFarm = this.$store.state.selectedFarm

         await this.$dialog.push(WateringDialog, {
            farm: this.farm,
            field: this.field
         })
      },
      async showHoleDesign() {
         await this.$dialog.push(HoleDesignDialog, {
            farm: this.farm,
            field: this.field
         })
      }
   },
   mounted() {
   }
}
</script>

<style lang="css" scoped>
.field_tile {
   position: relative;
   background-color: var(--bg1);
   display: flex;
   font-weight: bold;
   flex-direction: column;
   justify-content: center;
   align-content: center;
   border: none;
}

.field_label {
   display: block;
   background-color: var(--secondary);
   color: white;
   width: 100%;
   line-height: 2em;
   font-weight: bold;
   font-size: initial;
   max-width: 70vw;
   text-align: center;
   text-decoration: none;
   white-space: nowrap;
   overflow: visible;
}
.field_menu_buttons {
   display: flex;
   flex-direction: row;
   margin-top: var(--smaller-padding);
   column-gap: var(--smaller-padding);
   justify-content: center;
}

.field_menu_buttons button {
   flex: 1;
}
.field_menu_buttons .button {
   flex: 1;
}

.field_img {
   max-width: 80vw;
   max-height: 80vw;
   width: 192px;
   height: 192px;
   border: 2px solid var(--secondary);
   border-radius: var(--smaller-border-radius);
   display: flex;
   align-self: center;
}

.clickable {
   cursor: pointer;
}
</style>
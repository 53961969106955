<template>
    <div class="cancel_button_in_header">
        <icon-button 
        :big="true"
        :label="label" :description="description"
        :useCancelIcon="true"
        @click="cancel()"/>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: 'Cancel Add Field'
        },
        description: {
            type: String,
            default: 'Cancel Add Field -- All Progress Will Be Lost'
        }
    },
    methods: {
        cancel() {
            this.$emit('cancel')
        }
    }
    
}
</script>

<style lang="css">
.cancel_button_in_header {
    border-bottom-left-radius: var(--general-border-radius);
    padding: var(--menu-border-size);
    float: right;
    background: var(--bg1);
    position: absolute;
    top: 0px;
    right: 0px;
}
</style>

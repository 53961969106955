function DefaultState(state) {
    return state
}

function BuildWateringEventsStore(api) {
    return {
        namespaced: true,
        state: DefaultState({}),
        inject: ['$api'],
        getters: {
            events: (_state, _getters, rootState) => {
                const farm = rootState.selectedFarm
                
                if(! farm) {
                    return []
                }

                if(! farm.wateringEvents) {
                    console.warn('No Watering Events on Farm')
                    return []
                }

                return farm.wateringEvents
            },
            eventsWithMetadata: (_state, getters, rootState) => {
                const farm = rootState.selectedFarm
                
                const ret = getters.events.map((event) => {
                    const field = farm.fields.find((f) => f.id === event.fieldId)
                    if(! field) {
                        return null
                    }

                    const fieldLayout = field.layouts.find((l) => l.id === event.fieldLayoutId)
                    if(! fieldLayout) {
                        return null
                    }

                    const furrowSet = event.furrowSetId ?
                        fieldLayout.furrowSetDetails.resultingFurrowSets.find((s) => s.id === event.furrowSetId) : null

                    const irrigationSystem = farm.irrigationSystems.find((i) => i.id === event.irrigationSystemId)
                    if(! irrigationSystem) {
                        return null
                    }

                    const pipePath = irrigationSystem.pipePaths.find((p) => p.id === event.pipePathId)
                    if(! pipePath) {
                        return null
                    }

                    return {
                        event, farm, field, fieldLayout, furrowSet, irrigationSystem, pipePath
                    }
                })

                return ret.filter((o) => o != null)
            },
            pipePathEvents: (_state, getters) => (pipePathId) => {
                return getters.events.filter((e) => 
                    e.pipePathId === pipePathId
                )
            }
        },
        actions: {
            async add({dispatch}, event) {
                await api.addWateringEvent(event)
                await dispatch("refreshSelectedFarmWateringEvents", null, {root: true})
            },
            async remove({dispatch}, event) {
                await api.removeWateringEvent(event.pipePathId, event.id)
                await dispatch("refreshSelectedFarmWateringEvents", null, {root: true})
            }
        }
    }
}

export {
    BuildWateringEventsStore
}

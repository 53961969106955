import { reactive } from 'vue'
import pako from 'pako'

import { Pp4Uuid } from '@/store/Dto'

// It's just a global! smdh
let ApiInstance = null

function InitApiInstance(auth0, defaultErrorCallback) {
  const Common = reactive({
    puppetUserId: null,
    setPuppetUserId(puppetUserId) {
      this.puppetUserId = puppetUserId
    },

    async fetchWrapper(url, method, headers = {}, body, ignore404 = false) {
      let bearerToken = null

      try {
        bearerToken = await auth0.getAccessTokenSilently()
      }
      catch (e) {
        if (e?.error) {
          console.warn("Fetch Auth0 Token Error: " + e.error)
          await auth0.logout()
          return new Promise((res, rej) => undefined) // don't resolve
        }
        else {
          console.dir(e)
          console.error(e)

          throw e
        }
      }

      const finalHeaders = {
        Authorization: 'Bearer ' + bearerToken
      }

      if (this.puppetUserId?.length) {
        finalHeaders['puppet-user-id'] = this.puppetUserId
      }

      Object.assign(finalHeaders, headers)

      let ret = await fetch(url, {
        method: method,
        headers: finalHeaders,
        body
      })

      if (defaultErrorCallback) {
        if (ret.status == 401) {
          defaultErrorCallback('CustomerInvalid')
          return
        }
      }

      if (!ret.ok) {
        if (ignore404 && (ret.status === 404)) {
          return ret
        }

        const text = await ret.text()
        throw new Error('GET Failed: ' + text)
      }

      return ret
    },

    async fetch(url, options) {
      if (!options.method) {
        throw 'options.method required'
      }

      return await this.fetchWrapper(
        url,
        options.method,
        options.headers || {},
        options.body || null
      )
    }
  })

  ApiInstance = reactive({
    setPuppetUserId(puppetUserId) {
      Common.puppetUserId = puppetUserId
    },

    async isInvalidUser() {
      const result = await Common.fetchWrapper('/api/invalid_user', 'GET')

      if (result.ok) {
        return result.json()
      }

      const text = await result.text()

      throw new Error('Invalid User Check Failed: ' + text)
    },

    fetch(url, options) {
      return Common.fetch(url, options)
    },

    jdAdmin: {
      async getOrganizationsAndStatus() {
        const result = await Common.fetchWrapper('/api/jd-admin/organizations', 'GET')

        const json = await result.json()

        return json
      },

      async patchOrganizationAndStatus(id, jd) {
        const headers = {}
        headers['Content-Type'] = 'application/json'

        await Common.fetchWrapper(`/api/jd-admin/organizations/${id}`, 'PATCH', headers, JSON.stringify(jd))
      },
    },

    jd: {
      async postOrganizationDealerPayment(orgId) {
        const result = await Common.fetchWrapper(`/api/jd/organizations/${orgId}/payment/dealer`, 'POST')

        if (!result.ok) {
          const text = await result.text()
          throw new Error('John Deere Payment Failed: ' + text)
        }
      },

      async postOrganizationCardPayment(orgId, body) {
        const headers = {}
        headers['Content-Type'] = 'application/json'

        const result = await Common.fetchWrapper(`/api/jd/organizations/${orgId}/payment/card`, 'POST',
          headers, JSON.stringify(body))

        if (!result.ok) {
          const text = await result.text()
          throw new Error('John Deere Payment Failed: ' + text)
        }
      },

      async postFlagCategory(orgId, body) {
        const headers = {}
        headers['Content-Type'] = 'application/json'

        return await Common.fetchWrapper(`/api/jd/organizations/${orgId}/flag-categories`, 'POST',
          headers, JSON.stringify(body))
      },

      async postFlag(orgId, fieldId, categoryId, body) {
        const headers = {}
        headers['Content-Type'] = 'application/json'

        return await Common.fetchWrapper(`/api/jd/organizations/${orgId}/fields/${fieldId}/categories/${categoryId}/flags`, 'POST',
          headers, JSON.stringify(body))
      },

      async getInvoice(invoiceId) {
        const result = await Common.fetchWrapper(`/api/jd/invoices/${invoiceId}`, 'GET')

        const blob = await result.blob()

        const url = URL.createObjectURL(blob)
        window.open(url)
      },

      async getFieldMapLayerSummaries(orgId, fieldId) {
        const result = await Common.fetchWrapper(`/api/jd/organizations/${orgId}/fields/${fieldId}/map-layer-summaries`, 'GET')

        const json = await result.json()

        return json
      },
      async getFieldGuidanceLines(orgId, fieldId) {
        const result = await Common.fetchWrapper(`/api/jd/organizations/${orgId}/fields/${fieldId}/guidance-lines`, 'GET')

        const json = await result.json()

        return json
      },
      async disconnect() {
        const result = await Common.fetchWrapper('/api/jd/disconnect', 'POST')

        if (!result.ok) {
          const text = await result.text()
          throw new Error('John Deere Disconnect Failed: ' + text)
        }

        const json = await result.json()

        return json
      },

      async getState() {
        const result = await Common.fetchWrapper('/api/jd/state', 'GET')

        if (!result.ok) {
          const text = await result.text()
          throw new Error('John Deere State GET Failed: ' + text)
        }

        const json = await result.json()

        return json
      },


      async getSyncUri() {
        const result = await Common.fetchWrapper('/api/jd/get_sync_uri', 'GET')

        if (!result.ok) {
          const text = await result.text()
          throw new Error('Get John Deere Sync URI Failed: ' + text)
        }

        const json = await result.json()

        return json
      },

      async getSelectOrganizationsUri() {
        const result = await Common.fetchWrapper('/api/jd/select_organizations_uri', 'GET')

        if (!result.ok) {
          const text = await result.text()
          throw new Error('Get John Deere Select Organizations URI Failed: ' + text)
        }

        const json = await result.json()

        return json
      },

      async getOrganizations() {
        const result = await Common.fetchWrapper('/api/jd/organizations', 'GET')

        if (!result.ok) {
          const text = await result.text()
          throw new Error('John Deere Organizations GET Failed: ' + text)
        }

        const json = await result.json()

        return json
      },

      async getOrganizationFarms(orgId) {
        const result = await Common.fetchWrapper(`/api/jd/organizations/${orgId}/farms`, 'GET')

        if (!result.ok) {
          const text = await result.text()
          throw new Error('John Deere Farm/Field/Boundaries GET Failed: ' + text)
        }

        const json = await result.json()

        return json
      },

      async getPpFarmAndFieldForJdObjects(orgId, fieldId, boundaryId, suggestedFieldName, path) {
        const headers = {}
        headers['Content-Type'] = 'application/json'

        const newFieldId = Pp4Uuid()

        const result = await Common.fetchWrapper(
          `/api/jd/organizations/${orgId}/fields/${fieldId}/boundaries/${boundaryId}/path-as-pp-field/${newFieldId}?name=${encodeURIComponent(suggestedFieldName)}`,
          'POST', headers, JSON.stringify(path)
        )

        const json = await result.json()

        return json
      },
    },

    async getUserAcreReport() {
      const result = await Common.fetchWrapper('/api/reporting/user_acres', 'GET')

      const res = await result.blob()

      return res
    },

    async getAdminAcreReport() {
      const result = await Common.fetchWrapper('/api/reporting/admin_acres', 'GET')

      const res = await result.blob()

      return res
    },

    async getAcresByGeoReport() {
      const result = await Common.fetchWrapper('/api/reporting/state_county_acres', 'GET')

      const res = await result.blob()

      return res
    },

    async getFlowratesByGeoReport() {
      const result = await Common.fetchWrapper('/api/reporting/flowrates/', 'GET')
      const res = await result.blob()

      return res
    },

    async getUsageMoistureMethodReport() {
      const result = await Common.fetchWrapper('/api/reporting/soil_moisture_usage', 'GET')

      const res = await result.blob()

      return res
    },

    async getUsageSurgeValveMethod() {
      const result = await Common.fetchWrapper('/api/reporting/surge_valve_usage', 'GET')

      const res = await result.blob()

      return res
    },

    async getUsageShapefileMethod() {
      const result = await Common.fetchWrapper('/api/reporting/shapefile_usage', 'GET')

      const res = await result.blob()

      return res
    },

    async getUserAccountsReport() {
      const result = await Common.fetchWrapper('/api/reporting/user_accounts', 'GET')

      const res = await result.blob()

      return res
    },

    async getElevation(latLngs) {
      const headers = {}

      const encodedLocations = latLngs.map((latLng) => {
        const lat = latLng.lat.toFixed(6)
        const lng = latLng.lng.toFixed(6)
        return (lat + ',' + lng)
      }).join('|')

      const result = await Common.fetchWrapper(
        `/api/elevation?locations=${encodedLocations}`,
        'GET',
        headers
      )

      if (!result.ok) {
        return null
      }

      const text = await result.text()

      return JSON.parse(text)
    },

    async addFarm(farm) {
      const headers = {}
      headers['Content-Type'] = 'application/json'

      const result = await Common.fetchWrapper('/api/farms', 'PUT', headers, JSON.stringify(farm))

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('/farms PUT Failed: ' + text)
    },

    async addFieldToFarm(field, farmId) {
      const headers = {}
      headers['Content-Type'] = 'application/json'

      const result = await Common.fetchWrapper(
        `/api/farms/${farmId}/fields/${field.id}`,
        'PUT',
        headers,
        JSON.stringify(field)
      )

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('addFieldToFarm Failed: ' + text)
    },

    async shareHoleDesigns(recipientEmail, holeDesignPdf, fileName) {
      const headers = {}

      const formData = new FormData()
      formData.append('email', recipientEmail)
      formData.append('pdf', holeDesignPdf, fileName)

      const result = await Common.fetchWrapper('/api/share_hole_design', 'POST', headers, formData)

      if (result.ok) {
        return result.json()
      }

      const text = await result.text()

      throw new Error('Share Hole Design Failed: ' + text)
    },

    async upsertFarmIrrigationSystem(farmId, irrigationSystem) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['Content-Encoding'] = 'deflate'

      const packed = pako.deflate(JSON.stringify(irrigationSystem))
      const blob = new Blob([packed])

      const result = await Common.fetchWrapper(
        `/api/farms/${farmId}/irrigation-systems`,
        'PUT',
        headers,
        blob
      )

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('upsert_farm_irrigation_system Failed: ' + text)
    },

    async removePipePath(pipePathId) {
      const headers = {}

      const result = await Common.fetchWrapper(
        `/api/pipe-paths/${pipePathId}`,
        'DELETE',
        headers
      )

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('remove_farm_irrigation_system_pipe_path Failed: ' + text)
    },

    async addFieldLayout(fieldLayout, fieldId, farmId) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['Content-Encoding'] = 'deflate'

      const packed = pako.deflate(JSON.stringify(fieldLayout))
      const blob = new Blob([packed])

      const result = await Common.fetchWrapper(`/api/fields/${fieldId}/layouts`, 'POST', headers, blob)

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('POST Field Layout Failed: ' + text)
    },

    async getFarms({ mode } = { mode: 'default' }) {
      let url = '/api/farms'
      if (mode !== 'default') {
        url += `?mode=${mode}`
      }

      const result = await Common.fetchWrapper(url, 'GET')

      if (result.ok) {
        return result.json()
      }

      const text = await result.text()

      throw new Error('Get Farms Failed: ' + text)
    },

    async getFarm(farmId) {
      const headers = {}
      headers['Content-Type'] = 'application/json'

      const result = await Common.fetchWrapper('/api/farms/' + farmId, 'GET', {}, null, true)

      if (!result) {
        throw new Error("Get Farm Returned Nothing")
      }

      if (result.ok) {
        return await result.json()
      }

      if (result.status === 404) {
        return null
      }

      const text = await result.text()

      throw new Error('Get Farm Failed: ' + text)
    },



    async getUsers() {
      const result = await Common.fetchWrapper('/api/users/', 'GET')

      if (result.ok) {
        return result.json()
      }

      const text = await result.text()

      throw new Error('Get Users Failed: ' + text)
    },

    async transferFarm(farmId, newUserId) {
      const headers = {}
      headers['Content-Type'] = 'application/json'

      const result = await Common.fetchWrapper(
        '/api/transfer-farm',
        'POST',
        headers,
        JSON.stringify({ farmId, newUserId })
      )

      if (result.ok) {
        return
      }
      const text = await result.text()

      throw new Error('Transfer Farm failed ' + text)
    },

    async transferAllFarms(oldUserId, newUserId) {
      const headers = {}
      headers['Content-Type'] = 'application/json'

      const result = await Common.fetchWrapper(
        '/api/transfer-all-farms',
        'POST',
        headers,
        JSON.stringify({ oldUserId, newUserId })
      )

      if (result.ok) {
        return
      }
      const text = await result.text()

      throw new Error('Transfer All Farms failed ' + text)
    },

    async addPipePath(_farmId, irrigationSystemId, pipePath) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['Content-Encoding'] = 'deflate'

      const jsons = JSON.stringify({ irrigationSystemId, pipePath })
      const packed = pako.deflate(jsons)
      const blob = new Blob([packed])

      const result = await Common.fetchWrapper(
        `/api/irrigation-systems/${irrigationSystemId}/pipe-paths`,
        'POST', headers, blob)

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('PUT pipe_paths Failed: ' + text)
    },

    async patchPipePath(pipePathId, patch) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['Content-Encoding'] = 'deflate'

      const jsons = JSON.stringify({ pipePathId, patch })
      const packed = pako.deflate(jsons)
      const blob = new Blob([packed])

      const result = await Common.fetchWrapper(
        `/api/pipe-paths/${pipePathId}`,
        'PATCH', headers, blob)

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('patch_pipe_path Failed: ' + text)
    },

    async patchIrrigationSystem(irrigationSystemId, patch) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['Content-Encoding'] = 'deflate'

      const jsons = JSON.stringify(patch)
      const packed = pako.deflate(jsons)
      const blob = new Blob([packed])

      const result = await Common.fetchWrapper(
        `/api//irrigation-systems/${irrigationSystemId}`,
        'PATCH',
        headers,
        blob
      )

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('irrigation_systems PATCH Failed: ' + text)
    },

    async putHoleDesign(holeDesign) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['Content-Encoding'] = 'deflate'

      const pipePathId = holeDesign.pipePathId
      const packed = pako.deflate(JSON.stringify(holeDesign))
      const blob = new Blob([packed])

      const result = await Common.fetchWrapper(
        '/api/hole_designs/' + String(pipePathId),
        'PUT',
        headers,
        blob
      )

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('hole_designs PUT Failed: ' + text)
    },

    async patchField(fieldId, patch) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['Content-Encoding'] = 'deflate'

      const jsons = JSON.stringify(patch)
      const packed = pako.deflate(jsons)
      const blob = new Blob([packed])

      const result = await Common.fetchWrapper(`/api/fields/${fieldId}`, 'PATCH', headers, blob)

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('patch_field Failed: ' + text)
    },

    async removeFieldLayout(fieldId, fieldLayoutId) {
      const headers = {}

      const result = await Common.fetchWrapper(
        `/api/fields/${fieldId}/layouts/${fieldLayoutId}`,
        'DELETE',
        headers
      )

      if (!result.ok) {
        const text = await result.text()
        throw new Error('remove_field_layout Failed: ' + text)
      }

      return result.json()
    },

    async saveFieldLayoutIdToField(activeLayoutId, fieldId) {
      const headers = {}
      headers['Content-Type'] = 'application/json'

      const result = await Common.fetchWrapper(
        '/api/save_field_layout_id_to_field',
        'PATCH',
        headers,
        JSON.stringify({ activeLayoutId, fieldId })
      )

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('save_field_layout_id_to_field Failed: ' + text)
    },

    async patchFieldLayout(fieldId, fieldLayoutId, patch) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['Content-Encoding'] = 'deflate'

      const jsons = JSON.stringify(patch)
      const packed = pako.deflate(jsons)
      const blob = new Blob([packed])

      const result = await Common.fetchWrapper(
        `/api/fields/${fieldId}/layouts/${fieldLayoutId}`,
        'PATCH',
        headers,
        blob
      )

      if (result?.ok) {
        return
      }

      const text = await result.text()

      throw new Error('PATCH Field Layout Failed: ' + text)
    },

    async postFieldSplit(fieldId, fieldLayoutId, newPathsAndNamesArray) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['Content-Encoding'] = 'deflate'

      const jsons = JSON.stringify(newPathsAndNamesArray)
      const packed = pako.deflate(jsons)
      const blob = new Blob([packed])

      const result = await Common.fetchWrapper(
        `/api/fields/${fieldId}/layouts/${fieldLayoutId}/split-to-new-fields`,
        'POST', headers, blob)

      if (result.ok) {
        return result.json()
      }

      const text = await result.text()

      throw new Error('POST Field Split Failed: ' + text)
    },

    async postFarmTransaction(transaction) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['Content-Encoding'] = 'deflate'

      const jsons = JSON.stringify(transaction)
      const packed = pako.deflate(jsons)
      const blob = new Blob([packed])

      const result = await Common.fetchWrapper('/api/farm_transaction', 'POST', headers, blob)

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('farm_PATCH Failed: ' + text)
    },

    async patchFarm(farmId, patch) {
      const headers = {}
      headers['Content-Type'] = 'application/json'
      headers['Content-Encoding'] = 'deflate'

      const jsons = JSON.stringify(patch)
      const packed = pako.deflate(jsons)
      const blob = new Blob([packed])

      const result = await Common.fetchWrapper('/api/farms/' + String(farmId), 'PATCH', headers, blob)

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('farm_PATCH Failed: ' + text)
    },

    async removeFarm(farmId) {
      const headers = {}
      headers['Content-Type'] = 'application/json'

      const result = await Common.fetchWrapper(
        '/api/farms/' + String(farmId),
        'DELETE',
        headers,
        JSON.stringify({ farmId })
      )

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('remove_Farm Failed: ' + text)
    },

    async removeIrrigationSystem(irrigationSystemId) {
      const headers = {}
      headers['Content-Type'] = 'application/json'

      const result = await Common.fetchWrapper(
        '/api/irrigation_systems/' + String(irrigationSystemId),
        'DELETE'
      )

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('remove_irrigation_system Failed: ' + text)
    },

    async removeField(fieldId) {
      const headers = {}

      const result = await Common.fetchWrapper(
        `/api/fields/${fieldId}`,
        'DELETE',
        headers
      )

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('DELETE Field Failed: ' + text)
    },

    async getFarmWateringEvents(farmId) {
      const headers = {}
      headers['Content-Type'] = 'application/json'

      const result = await Common.fetchWrapper(`/api/farms/${farmId}/watering-events`, 'GET')

      return result.json()
    },

    async addWateringEvent(event) {
      const headers = {}
      headers['Content-Type'] = 'application/json'

      const result = await Common.fetchWrapper(
        `/api/pipe-paths/${event.pipePathId}/watering-events`,
        'PUT',
        headers,
        JSON.stringify(event)
      )

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('PUT watering_event Failed: ' + text)
    },

    async removeWateringEvent(pipePathId, eventId) {
      const headers = {}

      const result = await Common.fetchWrapper(`/api/pipe-paths/${pipePathId}/watering-events/${eventId}`, 'DELETE')

      if (result.ok) {
        return
      }

      const text = await result.text()

      throw new Error('DELETE Watering Event Failed: ' + text)
    }
  })
}

export function createApi({ auth, defaultErrorCallback }) {
  InitApiInstance(auth, defaultErrorCallback)

  return {
    install(app) {
      app.config.globalProperties.$api = ApiInstance
      app.provide('$api', ApiInstance)
    }
  }
}

export function useApi() {
  return ApiInstance
}

import { GeoUtil } from '@/geo/GeoUtil'

const BuildCleanedUpPathDefaultOpts = {
    simplify: true, snap: false,
    targetBearing: null,
    bearingSnapThreshold: 7,
    segmentMinLengthToSnapInMeters: 50
}
export default class FieldCleanupAlg {
    static buildCleanedUpFieldPath(path, overrides = {}) {
        const opts = Object.assign(
            Object.assign({}, BuildCleanedUpPathDefaultOpts), overrides)
            
        if((! path) || (path.length < 3)) {
            return path
        }

        const ls = GeoUtil.LatLngs.toLineString(path)

        GeoUtil.GeoJson.cleanCoords(ls, {mutate: true})

        const simplifiedPath = GeoUtil.GeoJson.toLatLngs(ls)
        if(simplifiedPath.length < 3) {
            return path
        }

        if((! opts.snap) || (opts.targetBearing == null)) {
            return simplifiedPath
        }

        const segments = []
        {
            const pushSegment = (segment) => {
                const bearing = GeoUtil.bearing(segment[0], segment[1])
            
                const lengthInKm = GeoUtil.LatLngs.length(
                    segment, {units: 'kilometers'})
                segments.push({segment, bearing, lengthInKm})
            }

            GeoUtil.LatLngs.segmentEach(simplifiedPath, pushSegment)

            // Segment back to the first point
            const first = simplifiedPath[0] 
            const last = simplifiedPath[simplifiedPath.length - 1]
            const segment = [last, first]
            pushSegment(segment)
        }

        // Snap alg
        {
            const targetBearing = opts.targetBearing

            const ret = []
            let lastLatLng = null // needed to correct segments as we go
            segments.forEach((o, i) => {
                const isFirst = (i === 0)
                if(isFirst) {
                    ret.push(o.segment[0])
                }
                else if(lastLatLng) {
                    o.segment[0] = lastLatLng
                }

                if(o.lengthInKm < (opts.segmentMinLengthToSnapInMeters / 1000)) {
                    lastLatLng = o.segment[1]
                    ret.push(o.segment[1])
                    return
                }

                const isLast = (i === (segments.length - 1))

                const bearingDifference = GeoUtil.bearingDifference(
                    targetBearing, o.bearing)
                const reversedBearingDifference = GeoUtil.bearingDifference(
                    GeoUtil.bearingReversed(targetBearing), o.bearing)
                const minDifference = Math.min(
                    Math.abs(bearingDifference), Math.abs(reversedBearingDifference))
                if(minDifference > opts.bearingSnapThreshold) {
                    if(! isLast) {
                        lastLatLng = o.segment[1]
                        ret.push(o.segment[1])
                    }
                    return
                }

                const newBearing = minDifference === bearingDifference ? 
                    targetBearing : GeoUtil.bearingReversed(targetBearing)
                    
                const newPoint = GeoUtil.destination(o.segment[0], o.lengthInKm, 
                    newBearing, {units: 'kilometers'})
                const newLatLng = GeoUtil.GeoJson.toLatLngs(newPoint)

                if(isLast) {
                    ret[0] = newLatLng
                }
                else {
                    ret.push(newLatLng)
                    lastLatLng = newLatLng
                }
            })

            return ret
        }
    }
}

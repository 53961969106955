<template>
  <fullpageform title="Calculate Furrow Sets">
    <fieldset>
      <h3 style="margin-bottom: var(--general-padding);">Enter Field Parameters</h3>

      <!-- flow-rate-select has a slot that takes these inputs -->
      <flow-rate-select
        :class="flowRateInvalid ? 'invalid' : ''"
        v-model="primaryFlowRateId"
        :field="field"
        :fieldLayout="fieldLayout"
        :showApplyFlowRateChanges="false"
        :irrigationSystems="farm.irrigationSystems"
        :lineStringForDistanceCalculation="fieldLineString"
      >
        <!-- Furrow spacing -->
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          "
        >
          <label :class="furrowSpacingInvalid ? 'invalid' : ''" for="furrow_spacing_in_inches"
            >Furrow Spacing (in)</label
          >
          <input
            style="width: 50px"
            type="number"
            step="1"
            size="4"
            min="12"
            id="furrow_spacing_in_inches"
            v-model.number="furrowSpacingInInches"
            v-on:keyup.enter="$emit('updatesets')"
          />
        </div>

        <!-- Alternating Furrows -->
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          "
        >
          <input type="checkbox" id="cf_alternating_furrows" v-model="alternatingFurrows" />
          <label for="cf_alternating_furrows">Alternating Furrows</label><br />
        </div>

        <span v-show="alternatingFurrows" style="font-size: 10px; font-weight: 600">
          Watering time will be adjusted by a factor of 0.65.
        </span>

        <!-- Gross Applied (in) -->
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          "
        >
          <label for="gross_applied_inches">Gross Applied (in)</label>
          <select
            v-model.number="grossAppliedInches"
            v-on:keyup.enter="updateSets"
            id="gross_applied_inches"
            name="gross_applied_inches"
          >
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
      </flow-rate-select>


      <!-- Total Acres / Watering Time -->

      <br />

      <div id="watering_time_and_number_of_sets" class="table">
        <table>
          <tr>
            <th>Total Acres</th>
            <th>Watering Time</th>
          </tr>
          <tr>
            <td>{{ totalAcres.toFixed(1) }}</td>
            <td :class="wateringTimeWarning ? 'wateringTimeWarningClass' : ''">
              {{ wateringTimeInHours != null ? wateringTimeInHours.toFixed(2) + ' hrs' : 'N/A' }}
            </td>
          </tr>

          <tr>
            <th colspan="2">Avg. Flow Per Furrow</th>
          </tr>
          <tr>
            <td colspan="2">
              {{ flowPerFurrowInGpm != null ? flowPerFurrowInGpm.toFixed(2) + ' gpm' : 'N/A' }}
            </td>
          </tr>
        </table>
      </div>

      <div v-show="wateringTimeWarning" class="description watering_time_warning">
        Your watering time is more than your desired watering time
      </div>

      <label
        :class="desiredWateringTimeInvalid ? 'invalid widget_margin_top' : 'widget_margin_top'"
        for="desired_watering_time"
        >Desired Watering Time</label
      >
      <input
        id="desired_watering_time"
        type="number"
        class="widget_margin_top short_number widget_margin_left"
        step="1"
        min="1"
        v-model.number="desiredWateringTimeInHours"
        v-on:keyup.enter="updateSets"
      />

      <div v-if="allFieldsValid" class="center widget_margin_top">
        <label for="sets" v-if="fromWizard">Would You Like To Use Sets?</label>
        <label for="sets" v-else>Would You Like To Modify Sets?</label>
        <br />

        <input
          type="radio"
          id="true"
          name="sets"
          value="true"
          @change="toggleSets(1)"
          v-model="sets"
        />
        <label for="true">Yes</label>

        <input
          type="radio"
          id="false"
          name="sets"
          value="false"
          @change="toggleSets(2)"
          v-model="sets"
        />
        <label for="false">No</label>
      </div>

      <div
        v-if="sets === true && allFieldsValid"
        id="watering_time_and_number_of_sets"
        class="widget_margin_top"
      >
        <table>
          <tr>
            <th>Acres Per Set</th>
            <th>Avg. Flow Per Furrow</th>
          </tr>

          <tr>
            <td>{{ acresPerSet.toFixed(1) }}</td>
            <td>{{ (setsNeeded * flowPerFurrowInGpm).toFixed(2) }} gpm</td>
          </tr>

          <tr>
            <th>Number of Sets</th>
            <th>Watering Time Per Set</th>
          </tr>

          <tr>
            <td>{{ setsNeeded }}</td>
            <td>{{ wateringTimePerSetInHours.toFixed(2) }} hrs</td>
          </tr>
        </table>
      </div>
    </fieldset>

    <template v-slot:action-buttons>
      <button type="submit" v-if="showUpdateAndDrawSets" v-on:click.once="emitUpdateSets">
        Update Sets
      </button>
      <button
        class="button_margin_left"
        v-if="showUpdateAndDrawSets"
        v-on:click.once="emitDrawSets"
      >
        Draw Sets
      </button>
      <button class="standard" @click="emitSave()" v-if="showSaveCancel">Done</button>
      <button class="standard button_margin_left" @click="emitCancel()" v-if="showSaveCancel">
        Cancel
      </button>
    </template>
  </fullpageform>
</template>

<script setup>
import { computed, inject, ref, onMounted, onUnmounted } from 'vue'
import hotkeys from 'hotkeys-js'

import { GeoUtil } from '@/geo/GeoUtil'
import { FieldGeoFactory } from '../../../design/FieldGeoFactory'

const $store = inject('$store')
const fieldGeoFactory = new FieldGeoFactory($store.state.preferences)

const props = defineProps({
  farm: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  },
  fieldLayout: {
    // this has not been fully implemented. need to make sure that the field layout is being passed down
    type: Object,
    required: true
  },
  fromWizard: {
    type: Boolean,
    required: false,
    default: true
  }
})

const furrowSpacingInInches = ref(props.fieldLayout.furrowSpacingInInches)
const alternatingFurrows = ref(props.fieldLayout.alternatingFurrows)
const primaryFlowRateId = ref(props.fieldLayout.primaryFlowRateId)
const desiredWateringTimeInHours = ref(props.fieldLayout.desiredWateringTimeInHours)
const grossAppliedInches = ref(props.fieldLayout.grossAppliedInches)
const sets = ref(false)

const emit = defineEmits(['updatesets', 'save', 'cancel', 'drawsets'])

const flowRateInGpm = computed(() => {
  for (let irrigationSystem of props.farm.irrigationSystems) {
    // idk how this is working?
    for (let flowRate of irrigationSystem.flowRates) {
      if (flowRate.id === primaryFlowRateId.value) {
        return flowRate.valueInGpm
      }
    }
  }

  return 0
})

const furrowLines = computed(() => {
  let ret = fieldGeoFactory.buildFurrowLinesFromBounds(
    props.fieldLayout.path,
    props.fieldLayout.furrowBearing,
    furrowSpacingInInches.value * (alternatingFurrows.value ? 2 : 1)
  )

  ret.forEach((ls) => {
    ls.properties.lengthInFeet = GeoUtil.GeoJson.length(ls, { units: 'feet' })
  })

  const minLengthInFeet = $store.state.preferences.furrows.minLengthInFeet

  ret = ret.filter((ls) => ls.properties.lengthInFeet > minLengthInFeet)

  if (ret != null) {
    return ret
  }
})

const flowPerFurrowInGpm = computed(() => {
  return flowRateInGpm.value ? flowRateInGpm.value / furrowLines.value.length : null
})

const desiredWateringTimeInvalid = computed(() => {
  return !(
    Number.isFinite(desiredWateringTimeInHours.value) && desiredWateringTimeInHours.value >= 0
  )
})

const areaInSquareMeters = computed(() => GeoUtil.LatLngs.area(props.fieldLayout.path))

const wateringTimeInHours = computed(() => {
  if (!flowRateInGpm.value) {
    return null
  }

  const areaInSquareInches = areaInSquareMeters.value * 1550.0
  const volumeNeededInCubicInches = areaInSquareInches * grossAppliedInches.value
  const volumeNeededInGallons = volumeNeededInCubicInches * 0.004329018

  let ret = volumeNeededInGallons / flowRateInGpm.value / 60.0
  if (alternatingFurrows.value) {
    ret *= 0.65
  }

  if (Number.isFinite(ret)) {
    return ret
  }

  return null
})

const wateringTimeWarning = computed(
  () => wateringTimeInHours.value > desiredWateringTimeInHours.value
)

const furrowSpacingInvalid = computed(
  () => !(Number.isFinite(furrowSpacingInInches.value) && furrowSpacingInInches.value >= 1)
)

const flowRateInvalid = computed(
  () => !(flowRateInGpm.value && Number.isFinite(flowRateInGpm.value) && flowRateInGpm.value >= 1)
)

const allFieldsValid = computed(() => {
  return !(desiredWateringTimeInvalid.value || furrowSpacingInvalid.value || flowRateInvalid.value)
})

const totalAcres = computed(() => areaInSquareMeters.value * 0.0002471052)

const setsNeeded = computed(() => {
  let ret = 1

  if (desiredWateringTimeInvalid.value) {
    return ret
  }

  if (!wateringTimeInHours.value) {
    return ret
  }

  return Math.ceil(wateringTimeInHours.value / desiredWateringTimeInHours.value)
})

const acresPerSet = computed(() => totalAcres.value / setsNeeded.value)

const idealAcresPerSet = computed(() => {
  if (desiredWateringTimeInvalid.value) {
    return totalAcres.value
  }

  if (!wateringTimeInHours.value) {
    return totalAcres.value
  }

  const ratio = wateringTimeInHours.value / desiredWateringTimeInHours.value

  return totalAcres.value / ratio
})

const wateringTimePerSetInHours = computed(() => wateringTimeInHours.value / setsNeeded.value)

const fieldLineString = computed(() => GeoUtil.LatLngs.toLineString(props.fieldLayout.path))

const showUpdateAndDrawSets = computed(() => {
  return sets.value === true && allFieldsValid.value
})
const showSaveCancel = computed(() => !showUpdateAndDrawSets.value)

function toggleSets(val) {
  if (val === 1) {
    sets.value = true
  } else {
    sets.value = false
  }
}

const emitValues = computed(() => {
  return {
    setsNeeded: setsNeeded.value,
    idealAcresPerSet: idealAcresPerSet.value,
    furrowSpacingInInches: furrowSpacingInInches.value,
    alternatingFurrows: alternatingFurrows.value,
    primaryFlowRateId: primaryFlowRateId.value,
    desiredWateringTimeInHours: desiredWateringTimeInHours.value,
    grossAppliedInches: grossAppliedInches.value
  }
})

function emitUpdateSets() {
  emit('updatesets', emitValues.value)
}

function emitDrawSets() {
  emit('drawsets', emitValues.value)
}

function emitSave() {
  emit('save', emitValues.value)
}

function emitCancel() {
  emit('cancel')
}

onMounted(() => {
  hotkeys('escape', 'CalculatorForm', () => {
    if (showSaveCancel) {
      emit('cancel')
    }
  })

  hotkeys.setScope('CalculatorForm')
})

onUnmounted(() => {
  hotkeys.deleteScope('CalculatorForm')
})
</script>

<style lang="css" scoped>
.invalid {
  color: var(--error-red);
  font-weight: bold;
}

.single_th {
  display: flex;
  text-align: center;
}

.center {
  text-align: center;
}

.checkbox {
  margin-left: 0px;
  padding-left: 0px;
}

.watering_time_warning {
  text-align: center;
  display: block;
}

.wateringTimeWarningClass {
  background-color: var(--warning-orange);
}

#watering_time_and_number_of_sets {
  font-style: italic;
  font-size: small;
  line-height: initial;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

#watering_time_and_number_of_sets table th {
  text-align: center;
}

#watering_time_and_number_of_sets table td {
  text-align: center;
}
</style>

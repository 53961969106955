<template>
    <div class="body">
        <div class="centerInfo" >
            <h2>We've encountered a problem. <br> Please call Delta Plastics: <br> 1-800-277-9172</h2>
            <button class="logoutButton" @click="logout">Logout</button>
        </div>
    </div>
   
</template>

<script>
export default {
   methods:{
      logout() {
         this.$router.replace({name: 'Logout'})
      }
   },
   mounted() {
   }
}
</script>

<style lang="css" scoped>
.centerInfo {
    color: var(--secondary-light);
    font-weight: bolder;
    margin: auto;
    width: 50%;
    text-align: center;
    padding: 10px;
}
.logoutButton {
    border: 1px var(--secondary-light) solid;
    font-size: large;
}
.body {
    background-color: var(--primary);
    border-radius: var(--general-border-radius);
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    clear: both;
    display: flex;
    flex-direction: column;
}
</style>
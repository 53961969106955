
<template>
    <pp4-marker v-bind="markerProps" />
</template>

<script setup>
import { useMaps } from '@/composables/useMaps'
import { ZIndexes } from '@/maps/ZIndexes'

import RemoveIcon from '@/assets/remove_icon.svg';

const props = defineProps({
    location : {
        required: true
    }
})

const { api } = useMaps()

const markerProps = {
    clickable: false,
    draggable: false,
    location: props.location,
    zIndex: ZIndexes.PipePathEditMarkers,
    icon: {
        scaledSize: new api.Size(32, 32, "px", "px"),
        url: RemoveIcon,
        anchor: new api.Point(16, 16)
    }
}
</script>
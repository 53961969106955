<template>
  <template>
    <template v-if="furrowSet">
      <furrow-set :fieldLayout="fieldLayout" :key="furrowSet.id" :furrowSet="furrowSet" selectionStyle="selected"
        :clickable="false" :showLabel="true" />

      <furrow-set v-for="unselectableFurrowSet in unselectableFurrowSets" :fieldLayout="fieldLayout"
        :key="unselectableFurrowSet.id" :furrowSet="unselectableFurrowSet" selectionStyle="unselectable" />
    </template>
    <template v-else>
      <pp4-field :field="field" :fieldLayout="fieldLayout" ref="field" :clickable="false" selectionStyle="selected"
        :showLeveePaths="true" :showLabel="false" />
    </template>

    <template v-if="newIrrigationSystem != null">
      <water-source :location="newIrrigationSystem.waterSourceLocation" :clickable="false" :showLabel="true"
        :name="newIrrigationSystem.name" selectionStyle="selected" />
    </template>

    <water-source v-if="cursorLocation != null && (!newIrrigationSystem)" :location="cursorLocation" :clickable="false"
      :showLabel="true" 
      selectionStyle="selected" />

    <water-source v-for="irrigationSystem in unselectableIrrigationSystems"
      :key="'UnselectableIrrigationSystem:' + irrigationSystem.id" :name="irrigationSystem.name"
      :location="irrigationSystem.waterSourceLocation" :clickable="false" selectionStyle="unselectable" />

    <next-step v-if="saveActive">
      <icon-button label="Set Flow Rate" description="Set flow rate for new water source." :useSaveIcon="true" :big="true"
        @click="addFlowRate">
      </icon-button>
    </next-step>

    <map-cancel-button v-if="fromSource != 'Wizard'" @cancel="cancel" label="Cancel Add Water Source"
      description="Cancel Add Water Source" />
  </template>
</template>

<script>
import Pp4MapObject from '@/components/maps/Pp4MapObject'
import Pp4Field from '@/components/maps/Pp4Field'
import FurrowSet from '@/components/maps/FurrowSet'
import WaterSource from '@/components/maps/irrigation_systems/WaterSource'
import IrrigationSystemDialog from '@/components/maps/irrigation_systems/IrrigationSystemDialog'

import * as NamingAlg from '@/util/NamingAlg'
import { GeoUtil } from '@/geo/GeoUtil'
import { LatLng, GeometryEditMode } from '@/store/Dto'
import { IrrigationSystem } from '@/store/Dto'

export default {
  extends: Pp4MapObject,
  components: {
    Pp4Field,
    FurrowSet, WaterSource
  },
  props: {
    farm: {
      type: Object,
      required: false
    },
    field: {
      type: Object,
      required: false
    },
    fieldLayout: {
      type: Object,
      required: false
    },
    furrowSet: {
      type: Object,
      required: false,
      default: null
    },
    fromSource: {
      type: String,
      required: false,
      // default: false
    }
  },
  data: function () {
    return {
      GeometryEditMode,
      cursorLocation: null,
      newIrrigationSystem: null,
      location: null,
      hint: false
    }
  },
  watch: {
    location: 'updateNewIrrigationSystem'
  },
  computed: {
    saveActive: (vm) => vm.newIrrigationSystem != null,
    irrigationSystems: (vm) => {
      return vm.farm.irrigationSystems
    },
    unselectableIrrigationSystems: (vm) => vm.$store.state.mapObjects.unselectableIrrigationSystems,
    unselectableFurrowSets: (vm) => vm.$store.state.mapObjects.unselectableFurrowSets
  },
  beforeUnmount() {
    this.map.setOptions(this.googleMapUtils.defaultMapOptions())
  },
  emits: ['added'],
  methods: {
    updateNewIrrigationSystem() {
      if (this.location == null) {
        this.newIrrigationSystem = null
        return
      }

      this.newIrrigationSystem = new IrrigationSystem()

      {
        let existingNames = this.farm.irrigationSystems.map((o) => o.name)

        let name = null
        if (this.field != null) {
          const fieldName = this.field.name
          const waterSourceWithSameNameExists = existingNames.some((n) => n === fieldName)

          if (!waterSourceWithSameNameExists) {
            name = fieldName
          }
        }

        if (!name) {
          const nameRegex = /^Water Source ([0-9]+)$/
          const namePrefix = 'Water Source '
          name = NamingAlg.defaultName(existingNames,
            { nameRegex, namePrefix })
        }

        this.newIrrigationSystem.name = name
      }

      let tooCloseToExisting = (location) => {
        let firstCoord = GeoUtil.LatLngs.toCoord(location)

        return this.$store.state.selectedFarm.irrigationSystems.find((irrigationSystem) => {
          const waterSourceCoord = GeoUtil.LatLngs.toCoord(irrigationSystem.waterSourceLocation)
          const distance = GeoUtil.Coords.distance(
            firstCoord, waterSourceCoord, { units: 'feet' })

          if (distance < this.$store.state.preferences.pipe.minimumWaterSourceSeparationInFeet) {
            return true
          }

          return false
        })
      }
      if (this.location == null) {
        return
      }
      if (tooCloseToExisting(this.location)) {
        this.$store.dispatch('mapObjects/setHelpKey', 'water_source_too_close')
        this.newIrrigationSystem = null
        return
      } else {
        this.$store.dispatch('mapObjects/setHelpKey', 'move_new_irrigation_system')
      }

      this.newIrrigationSystem.waterSourceLocation = this.location
    },
    cancel() {
      this.$store.dispatch('returnToSelectedFieldOrFurrowSet')
    },
    async addFlowRate() {
      const dialogProps = {
        irrigationSystem: this.newIrrigationSystem,
        farm: this.farm, field: this.field,
        fieldLayout: this.fieldLayout,
        fromSource: this.fromSource
      }

      if (this.fromSource !== 'Wizard') {
        await this.$store.dispatch('returnToSelectedFieldOrFurrowSet')
      } else {
        this.saveAvailable = false
      }
      
      const instance = await this.$dialog.push(IrrigationSystemDialog, dialogProps)
      instance.on('added', (finalIrrigationSystem) => {
        this.$emit('added', finalIrrigationSystem)
      })
    },
    mapReady() {
      this.map.setOptions({
        draggableCursor: 'default',
        disableDoubleClickZoom: true
      })

      this.addMapDomListener('mousemove', (e) => {
        let googleLatLng = e.latLng
        let latLng = new LatLng(googleLatLng.lat(), googleLatLng.lng())
        this.cursorLocation = latLng
      })

      this.addMapDomListener('mouseout', () => {
        this.clearCursorLocation()
      })

      this.addMapListener('click', (e) => this.handleClick(e))
    },
    handleClick(e) {
      let googleLatLng = e.latLng
      let latLng = new LatLng(googleLatLng.lat(), googleLatLng.lng())

      this.location = latLng
      this.saveAvailable = true

      this.$store.dispatch('mapObjects/setHelpKey', 'move_new_irrigation_system')
    },
    clearCursorLocation() {
      this.cursorLocation = null
    }
  },
  unmounted() {
  },
  mounted() {
    this.$store.dispatch('mapObjects/setHelpKey', 'add_irrigation_system')
  }
}
</script>

<style></style>

<template>
    <div class="jd-flag-exporter-wrapper">
        <template v-if="flagCategoriesNeeded.size">
            <div>Flag Categories Needed: {{ Array.from(flagCategoriesNeeded).join(', ') || "None" }}</div>

            <div class="error mt-1" v-if="data.error">
                {{ data.error }}
            </div>

            <button class="mt-1" @click="postFlagCategories">Post Flag Categories</button>
        </template>
        <template v-else-if="jdOrgFlagCategoriesResult.isValidating.value">
            <div class="fill-content">
                <loading :absolute="true" label="Loading Categories..." />
            </div>
        </template>
        <template v-else-if="jdOrgFlags.isValidating.value">
            <div class="fill-content">
                <loading :absolute="true" label="Loading Flags..."></loading>
            </div>
        </template>
        <template v-else-if="data.posting">
            <div class="fill-content">
                <loading v-if="data.posting" :inline="true">{{ data.postingMessage }}</loading>
            </div>
        </template>
        <template v-else-if="pipeFlagsToBePosted.length">
            <div class="fill-content">
                Flags to be Created: {{ pipeFlagsToBePosted.length }}
            </div>
            <button class="mt-1" @click="postPipeFlags">Create Pipe Flags</button>
        </template>
        <template v-else>
            <div>
                All Flags Created Successfully
            </div>
        </template>
    </div>
</template>

<script setup>
import { reactive, computed, inject } from 'vue'
import useSWRV from 'swrv'

import * as JdUtil from '@/util/JdUtil'
import * as NamingAlg from '@/util/NamingAlg'
import RollupAlg from '@/design/RollupAlg'
import { GeoUtil } from '@/geo/GeoUtil'
import { FieldMode } from '@/store/Dto'

const props = defineProps({
    farm: {
        type: Object,
        required: true,
        validator: (value) => {
            return value && value?.jd?.organizationId
        }
    },
    ppFieldFilter: {
        type: Function,
        required: true
    }
})

const data = reactive({
    jdFlagCategoryId: null,
    error: null,
    posting: false,
    postingMessage: ''
})

const $api = inject('$api')

const jdOrgFlagCategoriesUrl = `/api/jd/organizations/${props.farm.jd.organizationId}/flag-categories`
const jdOrgFlagCategoriesResult = useSWRV(jdOrgFlagCategoriesUrl, async (url) => {
    if ((!url?.length) || (url === "null")) { // !! swrv does not react to null values, use empties
        return []
    }

    const result = await $api.fetch(url, { method: "GET" })

    const o = await result.json()

    return o?.values || []
}, { defaultValue: null, revalidateOnFocus: false })

const jdOrgFlagsUrl = computed(() => {
    //!! be extra cautious about removing this, i'm seeing reactivity issues when isValidating is not also checked...
    if (jdOrgFlagCategoriesResult.isValidating.value) {
        return 'null'
    }

    const categories = jdOrgFlagCategoriesResult.data.value
    if (!categories.length) {
        return 'null'
    }

    const pp4Categories = categories.filter(c => c.isPp4)

    const flagCategoryIds = pp4Categories.map(c => c.id).join(',')

    return `/api/jd/organizations/${props.farm.jd.organizationId}/flags?category_ids=${flagCategoryIds}`
})
const jdOrgFlags = useSWRV(jdOrgFlagsUrl, async (url) => {
    if ((!url?.length) || (url === "null")) { // !! swrv does not react to null values, use empties
        return []
    }

    const result = await $api.fetch(url, { method: "GET" })

    const o = await result.json()

    return o?.values || []
}, { defaultValue: null, revalidateOnFocus: false })

const rolledUpHoleDesigns = computed(() => {
    const farm = props.farm
    if (farm == null) {
        return []
    }

    const dummyJdObjects = JdUtil.buildFarmFieldBoundariesObjectMaps()

    const ret = farm.irrigationSystems.reduce((accum, irrigationSystem) => {
        return irrigationSystem.pipePaths.reduce((accum, pipePath) => {
            if (pipePath.zombie) {
                return accum
            }

            const fieldForPipePath = farm.fields.find(
                (f) => f.id === pipePath.fieldId)
            if (!fieldForPipePath) {
                console.warn('Field Not Found: ' + pipePath.fieldId)
                console.dir(pipePath)
                return accum
            }

            if(! props.ppFieldFilter(fieldForPipePath)) {
                return accum
            }

            if (props.filter) {
                if (!props.filter(fieldForPipePath, pipePath)) {
                    return accum
                }
            }

            const layoutForPipePath = fieldForPipePath.layouts.find(
                (layout) => layout.id == pipePath.fieldLayoutId)
            if (!layoutForPipePath) {
                console.warn('Field Layout Not Found: ' + fieldForPipePath.activeLayoutId)
                return accum
            }

            if (layoutForPipePath.id !== fieldForPipePath.activeLayoutId) {
                return accum
            }

            let furrowSet = null
            if (pipePath.furrowSetId) {
                furrowSet = layoutForPipePath.furrowSetDetails.resultingFurrowSets.find(
                    (resultingFurrowSet) => resultingFurrowSet.id === pipePath.furrowSetId)
                if (!furrowSet) {
                    console.warn('Furrow Set Not Found: ' + pipePath.furrowSetId)
                    return accum
                }
            }

            const designParamsValid =
                (pipePath.designParams != null)
                && (pipePath.designParams.holeDesign != null)
            if (!designParamsValid) {
                return accum
            }

            const rolledUpHoleDesign = RollupAlg.buildRolledUpHoleDesignFromPipePath(
                farm, fieldForPipePath, layoutForPipePath, irrigationSystem, pipePath, furrowSet,
                dummyJdObjects)

            if (rolledUpHoleDesign) {
                accum.push(rolledUpHoleDesign)
            }

            return accum
        }, accum)
    }, [])

    function sortByFieldThenFurrowSet(a, b) {
        let cmp = a.field.name.localeCompare(b.field.name)
        if (cmp !== 0) {
            return cmp
        }

        if (a.furrowSet && b.furrowSet) {
            return a.furrowSet.name.localeCompare(b.furrowSet.name)
        }

        return 0
    }

    ret.sort(sortByFieldThenFurrowSet)

    return ret
})

// const HoleCategoryColors = {
//     'Pipe Planner 1/4"': '#FFD30D',
//     'Pipe Planner 5/16"': "#11D1EF",
//     'Pipe Planner 3/8"': "#B85A19",
//     'Pipe Planner 7/16"': "#919091",
//     'Pipe Planner 1/2"': "#CCCACC",
//     'Pipe Planner 9/16"': "#0F010D",
//     'Pipe Planner 5/8"': "#D056BC",
//     'Pipe Planner 11/16"': "#4166AB",
//     'Pipe Planner 3/4"': "#058C03",
//     'Pipe Planner 13/16"': "#F5EE0B",
//     'Pipe Planner 7/8"': "#F99114",
//     'Pipe Planner 15/16"': "#D10D5A",
//     'Pipe Planner 1"': "#00FF00",
// }

const HoleCategoryColors = {
    'Hole Sizes 1': '#cc0000',
    'Hole Sizes 2': '#ffffff',
    'Hole Sizes 3': '#0099db'
}

const flagCategoriesNeeded = computed(() => {
    const ret = new Set()

    if (jdOrgFlagCategoriesResult.isValidating.value) {
        return ret
    }

    const existingCategories = new Set(jdOrgFlagCategoriesResult.data.value.map(c => c.categoryTitle))

    Object.keys(HoleCategoryColors).forEach(c => {
        if (!existingCategories.has(c)) {
            ret.add(c)
        }
    })

    // These are the Poly XxY categories..
    // rolledUpHoleDesigns.value.forEach((holeDesign) => {
    //     holeDesign?.rolledUpWateredBranches.forEach(b => {
    //         b.rolledUpWateredAreas.forEach(a => {
    //             if (!a?.pipeSize?.length) {
    //                 return
    //             }

    //             const categoryName = NamingAlg.productNameForExport(a.pipeSize)
    //             if (existingCategories.has(categoryName)) {
    //                 return
    //             }

    //             ret.add(categoryName)
    //         })
    //     })
    // })

    return ret
})

const pipeFlagsToBePosted = computed(() => {
    const orgId = props.farm?.jd?.organizationId
    if (!orgId.length) {
        return []
    }

    if (jdOrgFlagCategoriesResult.isValidating.value) {
        return []
    }

    if (jdOrgFlags.isValidating.value) {
        return []
    }

    if (flagCategoriesNeeded.value.size > 0) {
        return []
    }

    // We're assuming the checksum will be the same across all flags exported in relation to a pipePathId
    // (They should be)
    const exportedFlagPipePathIdToChecksum = {}

    jdOrgFlags.data.value.forEach(f => {
        const pipePathId = f?.metadata?.find(m => m.name === 'pipePathId')
        const checksum = f?.metadata?.find(m => m.name === 'checksum')

        if (pipePathId && checksum) {
            exportedFlagPipePathIdToChecksum[pipePathId.value] = checksum.value
        }
    })

    const ret = []

    rolledUpHoleDesigns.value.forEach(rolledUpHoleDesign => {
        const pipePath = rolledUpHoleDesign.pipePath
        const field = rolledUpHoleDesign.field
        const fieldLayout = rolledUpHoleDesign.fieldLayout
        const irrigationSystem = rolledUpHoleDesign.irrigationSystem
        const checksum = pipePath?.designParams?.checksum || 'N/A'

        if (rolledUpHoleDesign.error) {
            return
        }

        if (checksum === exportedFlagPipePathIdToChecksum[pipePath.id]) {
            return // there already exist flags with this checksum
        }

        const flowRate = irrigationSystem.flowRates.find((flowRate) => flowRate.id === pipePath.flowRateId)
        if (!flowRate) {
            console.warn('Flow Rate Not Found: ' + pipePath.flowRateId)
            return
        }

        // Pipe segments
        // {
        //     const suggestedPipeDiameterInInches = getSuggestedPipeDiameter(flowRate.valueInGpm)
        //     const suggestedPipeType = new PipeType(suggestedPipeDiameterInInches, 10)

        //     const pipeLineStrings = PipePathAlg.buildPipeLineStringsWithMetadata(
        //         rolledUpHoleDesign.pipePath, suggestedPipeType)

        //     const merged = PipePathAlg.mergePipeLineStrings(pipeLineStrings,
        //         (a, b) => {
        //             const cmp = JSON.stringify(a?.pipeType) === JSON.stringify(b?.pipeType)
        //             if (!cmp) {
        //                 return null
        //             }

        //             return {
        //                 pipeType: a.pipeType || suggestedPipeType,
        //             }
        //         }
        //     )

        //     merged.forEach(ls => {
        //         const pipeType = ls.properties.pipeType
        //         const pipeTypeName = `${pipeType.diameterInInches}x${pipeType.thicknessInMils}`
        //         // const categoryName = NamingAlg.productNameForExport(pipeTypeName)

        //         // ls.properties.categoryName = categoryName
        //         ls.properties.jdFieldId = field.jd.fieldId
        //         ls.properties.pipePathId = pipePath.id
        //         ls.properties.checksum = checksum
        //     })

        //     ret.push(...merged)
        // }

        // Hole Sizes
        {
            rolledUpHoleDesign.rolledUpWateredBranches.forEach(b => {
                b.rolledUpWateredAreas.forEach(wa => {
                    if (!wa.path) {
                        return
                    }

                    if (wa.path.length < 1) {
                        return
                    }

                    const geo = wa.path.length === 1 ?
                        GeoUtil.LatLngs.toGeoJsonPoint(wa.path[0])
                        : GeoUtil.LatLngs.toLineString(wa.path)

                    const notes = (wa?.holeSize?.name +
                        ", " + wa.sidedWateredAreaCount +
                        (fieldLayout.mode === FieldMode.Furrows ? " Furrows" : " Levees") +
                        "\n\nFrom Pipe Planner on " + (new Date().toLocaleString())) || "N/A"

                    geo.properties = {
                        // categoryName: "Pipe Planner Holes",
                        jdFieldId: field.jd.fieldId,
                        pipePathId: pipePath.id,
                        notes,
                        checksum
                    }

                    ret.push(geo)
                })
            })
        }
    })

    return ret
})

async function postFlagCategories() {
    const orgId = props.farm?.jd?.organizationId
    if (!orgId.length) {
        return
    }

    try {
        const flagCategories = Array.from(flagCategoriesNeeded.value)
        for (let flagCategory of flagCategories) {
            const body = {
                "@type": "FlagCategory",
                categoryTitle: flagCategory,
                archived: false,
                preferred: true,
                hexColor: HoleCategoryColors[flagCategory] || null
                //sourceNode will be added on server
            }

            await $api.jd.postFlagCategory(orgId, body)
        }

        jdOrgFlagCategoriesResult.mutate()
        jdOrgFlags.mutate()
    }
    catch (e) {
        console.error(e)
        data.error = "Could Not Create Flag Categories"
    }
}

async function postPipeFlags() {
    const orgId = props.farm?.jd?.organizationId
    if (!orgId.length) {
        return
    }

    const toDo = pipeFlagsToBePosted.value

    const flagCategoriesIdsByTitle = {}
    jdOrgFlagCategoriesResult.data.value.forEach(c => {
        flagCategoriesIdsByTitle[c.categoryTitle] = c.id
    })

    try {
        data.posting = true

        let index = 0
        for (let ls of toDo) {
            data.postingMessage = `Creating Flag ${index + 1}/${toDo.length}`

            const categoryName = `Hole Sizes ${(index % 3) + 1}`
            const categoryId = flagCategoriesIdsByTitle[categoryName]
            const jdFieldId = ls.properties.jdFieldId

            const body = {
                "@type": "Flag",
                geometry: {
                    "type": ls.geometry.type,//"LineString",
                    "coordinates": ls.geometry.coordinates
                },
                notes: ls.properties.notes || "From Pipe Planner on " + (new Date().toLocaleString()),
                archived: false,
                proximityAlertEnabled: false,
                metadata: [
                    { '@type': 'Metadata', name: 'actionRequired', value: 'true' },
                    { "@type": "Metadata", name: 'pipePathId', value: ls.properties.pipePathId },
                    { "@type": "Metadata", name: 'cropYear', value: NamingAlg.getCurrentCropYear() },
                    { "@type": "Metadata", name: 'checksum', value: ls.properties.checksum }
                ]
            }

            await $api.jd.postFlag(orgId, jdFieldId, categoryId, body)

            index += 1
        }
    }
    catch (e) {
        console.error(e)
        data.error = "Could Not Create Flag Categories"
    }
    finally {
        data.posting = false

        jdOrgFlagCategoriesResult.mutate()
    }
}
</script>

<style lang="css" scoped>
.jd-flag-exporter-wrapper {
    min-width: 20em;
    min-height: 20em;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    position: relative;
}

.fill-content {
    align-self: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
<template>
  <div className="top-select-farm-div">
    <select-farm-header :showFarmSearchText="farms.length >= 5" v-model:farmSearchText="data.searchFarms"
      :searchFarmsLabel="data.searchFarms.length > 0 ?
        `${filteredListOfFarms.length}/${farms.length}` : ''" />

    <loading v-if="!farmsLoaded" label="Loading Farms..." />

    <div class="farms-list-div">
      <div id="farms_list" v-show="filteredListOfFarms.length > 0">

        <div class="accordion" v-for="o in filteredListOfFarms" :id="`farm-accordion-${o.farm.id}`" :key="o.farm.id"
          :class="{ active: o.selected }" @click="farmAccordionToggle(o.farm)">
          <div class="accordion_header" @dragover="($event) => (o.selected ? undefined : onFarmDragOver($event, o))"
            @dragenter="($event) => (o.selected ? undefined : onFarmDragEnter($event, o))"
            @dragleave="($event) => (o.selected ? undefined : onFarmDragLeave($event, o))"
            @drop="($event) => (o.selected ? undefined : onFarmDrop($event, o))">

            <label class="farm_label" :title="o.farm.name">
              {{ o.farm.name }}
              <img v-if="o.farm.source && o.farm.source.type === 'jd'" src="@/assets/johndeere.svg" class="farm_src_img"
                title="Synchronzized w/John Deere" />
              <i v-show="isFarmLoading(o.farm)" class="fa fa-circle-o-notch fa-spin fa-1x fa-fw widget_margin_left"></i>
            </label>
            <a v-show="!o.selected" class="icon" href="#"><i class="fa fa-angle-down"></i></a>
            <a v-show="o.selected" class="icon" href="#"><i class="fa fa-angle-up"></i></a>
          </div>

          <div v-if="o.selected"
            style="display: flex; flex-direction: row; gap: 1.5em; padding-bottom: var(--general-padding);"
            class="farm_menu" @click.stop="() => { }">
            <div style="display: flex; flex-direction: column; min-width: 200px;">
              <router-link :to="{ path: '/FarmMapView/' + o.farm.id }">
                <img :alt="o.farm.name" style="width: 100%" :src="buildFarmPreviewImageUrl(o.farm)" />
              </router-link>

              <div class="filter_div" style="width: 100%">
                <input class="filter_input" style="width: 100%" type="text"
                  placeholder="Filter Fields" v-bind:value="data.searchFields"
                  @input="updateFilteredListOfFields($event.target.value)" />
              </div>
              <button class="widget_margin_top" style="text-align: left;" @click="addFieldMode(o.farm)"><i
                  class="fa fa-plus-square" />Add
                Field</button>
              <button class="widget_margin_top" style="text-align: left;" @click="editFarmProperties(o.farm)"><i
                  class="fa fa-edit"></i>Edit
                Farm
              </button>
              <button class="widget_margin_top" style="text-align: left;" @click="removeFarm(o.farm)"><i
                  class="fa fa-minus-square"></i>Delete
                Farm
              </button>
              <button class="widget_margin_top" style="text-align: left;" @click="showHoleDesign(o.farm, null)"><i
                  class="fa fa-file-text"></i>Hole Designs
              </button>
              <button class="widget_margin_top" style="text-align: left;" v-if="selectedFarm"
                @click="showShoppingList"><i class="fa fa-shopping-cart"></i>Shopping List</button>
            </div>

            <div class="field_tiles_and_label">
              <div class="search_fields_label" v-show="data.searchFields.length > 0">
                <label v-text="`${filteredListOfFields.length} of ${o.farm.fields.length} Fields`"></label>
              </div>
              <div class="field_tiles">
                <field-tile :field="field" :farm="o.farm" v-for="(field, fieldIndex) in filteredListOfFields"
                  :key="fieldIndex" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div style="
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background-color: var(--bg0);
        " v-if="farmsLoaded && (farms.length < 2)">
      <h2>First time here?</h2>
      <h4>The instructional videos below can help you get started!</h4>
      <iframe width="560" height="315" style="margin: 0 auto"
        :src="`https://www.youtube.com/embed/videoseries?list=PLBKdC5bUZ4uH0g_X4mhmZeNIEq0QRWH0O`"
        title="YouTube video player" frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        allowfullscreen></iframe>
    </div>

    <pp4-footer />
  </div>
</template>

<script setup>
import { computed, inject, onMounted, reactive, watch } from "vue";
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

import { ValidationStatus } from "@/store/Pp4Store";
import SelectFarmHeader from "@/components/SelectFarmHeader.vue";
import FieldTile from "@/components/FieldTile.vue";
import FieldDropDialog from "@/components/FieldDropDialog.vue";
import HoleDesignDialog from "@/components/HoleDesignDialog";
import EditFarmDialog from "@/components/field_menus/EditFarmDialog";
import ShoppingListPrinter from "@/print/ShoppingListPrinter";
import { getAllFieldRelatedObjects } from "@/util/RelationAlg";

const provider = inject('provider')
const $auth = inject('$auth')
const $staticImages = inject('$staticImages')
const $dialog = inject('$dialog')
const $router = useRouter()
const $store = useStore()

const data = reactive({
  searchFarms: "",
  searchFields: "",
  showFarm: false,
  icon: false,
  placeholder: 'Filter Fields',
  farmLoading: null,
  shoppingListPrinter: null,
  dropEffect: "copy", // drag and drop 'drop' event implementation is broken -- hack around it
})

const props = defineProps(["farmId"])

const validationStatus = computed(() => $store.state.validationStatus)
const farmsLoaded = computed(() => $store.state.farmsLoaded)
const farms = computed(() => $store.state.farms)
const selectedFarm = computed(() => $store.state.selectedFarm)
// const getPendingFarms = computed(() => $store.state.pendingSharedFarms)
// const puppetUser = computed(() => $store.state.puppetUser)
// const report = computed(() => $store.state.reports)
const filteredListOfFields = computed(() => {
  if (!selectedFarm.value) {
    return []
  }

  const fields = selectedFarm.value.fields;
  return fields.reduce((accum, field) => {
    if (!field.name.toLowerCase().includes(data.searchFields.toLowerCase())) {
      return accum;
    }
    accum.push(field);
    return accum;
  }, []);
})

const filteredListOfFarms = computed(() => {
  const sf = selectedFarm.value;

  const lowerSearch = data.searchFarms.toLowerCase()
  const emptyStringSearch = (lowerSearch === '""') || (lowerSearch === "''");

  return farms.value.reduce((accum, farm) => {
    if (!farm.name) {
      if (emptyStringSearch) {
        accum.push({
          farm: selected ? sf : farm,
          selected,
        });
      }

      return accum;
    }

    if (!farm.name.toLowerCase().includes(lowerSearch)) {
      return accum;
    }

    const selected = sf && (farm.id === sf.id);

    accum.push({
      farm: selected ? sf : farm,
      selected,
    });

    return accum;
  }, []);
})

const isAdmin = computed(() => {
  const roles = vm.$auth.user["https://deltaplastics.com/roles"];
  if (!roles) {
    return false;
  }

  return roles.some((role) => role == "PP Admin");
})

function onFarmDragEnter(e, filteredFarm) { }
function onFarmDragOver(e, filteredFarm) {
  if (e?.dataTransfer?.files?.length) {
    return;
  }

  if (!filteredFarm.selected) {
    e.stopPropagation();
    e.preventDefault(); // enable drop
    //if (e.ctrlKey || e.altKey) { // hope we're correctly catering to Mac here too...
    if (false) {
      e.dataTransfer.dropEffect = "copy";
      data.dropEffect = "copy";
    } else {
      e.dataTransfer.dropEffect = "move";
      data.dropEffect = "move";
    }
    e.target.classList.add("accordion_header_drag_over");
  }
}

function onFarmDragLeave(e, _filteredFarm) {
  e.target.classList.remove("accordion_header_drag_over");
}

async function onFarmDrop(e, filteredFarm) {
  const dropEffect = data.dropEffect; //e?.dataTransfer?.dropEffect || "copy";

  if (e?.dataTransfer?.files?.length) {
    return;
  }

  const jsonString = e.dataTransfer.getData("application/pp4-field");

  let dropObject = null;
  try {
    dropObject = JSON.parse(jsonString);
  } catch (e) {
    alert("Failed to Parse Drop Object");
    return;
  }

  const fields = dropObject?.fields;
  const sourceFarm = dropObject?.sourceFarm;

  if (!(fields && sourceFarm)) {
    return;
  }

  const allFieldRelatedObjects = getAllFieldRelatedObjects(fields, sourceFarm);

  await $dialog.push(FieldDropDialog, {
    dropEffect,
    allFieldRelatedObjects,
    targetFarm: filteredFarm.farm,
  });
}

function validationStatusChanged() {
  if (validationStatus.value === ValidationStatus.Valid) {
    farmIdChanged();
  } else if (validationStatus.value === ValidationStatus.Invalid) {
    $router.replace({ name: "ValidationNeeded" });
  }
  else if (validationStatus.value === ValidationStatus.CheckFailed) {
    logout()
  }
}

function showShoppingList() {
  const farm = selectedFarm.value;
  if (!farm) {
    return;
  }

  if (!data.shoppingListPrinter) {
    data.shoppingListPrinter = new ShoppingListPrinter();
  }

  // hmm, tricky... we need to pass the $staticImages object to the ShoppingListPrinter
  const vm = {
    $staticImages
  }

  data.shoppingListPrinter.buildPdf(farm, "save", vm);
}

function updateFilteredListOfFields(value) {
  data.searchFields = value;
}

function logout() {
  $auth.logout({
    returnTo: window.location.origin,
  });
}

function buildFarmPreviewImageUrl(farm) {
  return $staticImages.buildFarmUrl(farm);
}

async function farmIdChanged() {
  if (
    selectedFarm.value &&
    props.farmId === selectedFarm.value?.id
  ) {
    return;
  }

  if (!props.farmId) {
    await $store.dispatch("setSelectedFarm", null);
    return;
  }

  data.farmLoading = props.farmId;

  const fullFarm = await $store.getters.getFarm(props.farmId);

  if(! fullFarm) {
    await $router.replace({ name: "SelectFarm", params: { farmId: null } });
  }
  else {
    $store.dispatch("setSelectedFarm", fullFarm);
  }

  data.farmLoading = null;
}

async function farmAccordionToggle(farm) {
  data.searchFields = "";

  if (farm.id === props.farmId) {
    await $router.replace({ name: "SelectFarm", params: { farmId: null } });
  } else {
    await $router.replace({ name: "SelectFarm", params: { farmId: farm.id } });
  }
}
async function addFieldMode(farm) {
  let lastFieldId = null;
  if (farm.fields.length > 0) {
    lastFieldId = farm.fields[farm.fields.length - 1].id;
  }

  await $router.push({
    name: "FarmMapView",
    params: { farmId: farm.id, fieldId: lastFieldId },
  });

  $store.dispatch("enterAddFieldMode");
}

async function removeFarm(farm) {
  const doTheRemove = async () => {
    $store.dispatch("removeSelectedFarm");
    $dialog.pop();
  };
  $dialog.confirm(doTheRemove);
}
async function showHoleDesign(farm, field) {
  await $dialog.push(HoleDesignDialog, {
    farm,
    field,
  });
}

async function editFarmProperties(farm) {
  await $dialog.push(EditFarmDialog, {
    farm,
  });
}

function isFarmLoading(farm) {
  return data.farmLoading && data.farmLoading == farm.id;
}

onMounted(() => {
  provider.googleMapUtils.hideMap();

  validationStatusChanged();
})

watch(() => validationStatus.value, validationStatusChanged)
watch(props, farmIdChanged)

</script>

<style lang="css" scoped>
@media only screen and (max-width: 690px) {
  .farm_menu {
    flex-wrap: wrap;
    margin: auto;
    overflow: hidden;
  }

  .delta_plastics_logo {
    max-width: 7em !important;
    max-height: 7em !important;
    margin-right: 10px !important;
  }
}

.farm_src_img {
  margin-left: var(--label-margin);
  width: 1.25em;
  height: 1.25em;
  padding: 0.25em;
  border-radius: var(--general-border-radius);
  background: var(--primary-lighter);
  vertical-align: middle;
}

.help_video_button {
  margin: 0.5em 0em;
}

.puppetUserLabel {
  margin-top: 0.5em;
  color: var(--secondary-light);
  text-decoration: underline;
  font-weight: bold;
}

i {
  margin-right: 5px;
}

.showing_farms {
  background-color: var(--primary-dark);
}

.icon {
  position: absolute;
  right: 0px;
  float: right;
}

.field_tiles {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 1.5em;
  column-gap: 1.5em;
  flex-grow: 1;
  padding: 0px;
  margin: 0px;
}

.shared_farm {
  margin: auto;
  margin-top: var(--general-padding);
  text-align: center;
}

.field_tiles_and_label {
  display: flex;
}

.search_fields_label {
  margin: 1em;
  font-weight: bold;
}

.filter_div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  vertical-align: middle;
  margin-top: var(--general-padding);
  margin-right: var(--general-padding);
  text-align: center;
}

.farm_menu {
  display: inline-flex;
  flex-direction: row;
  text-decoration: none;
  overflow: hidden;
  min-width: 100%;
  text-align: center;
  background-color: var(--bg1);
  border-radius: var(--general-border-radius);
  align-items: flex-start;
  align-content: flex-start;
  cursor: initial;
}

#admin_tool {
  display: flex;
  flex-direction: column;
}

.header_link {
  margin-left: 5px;
  margin: 3px;
  text-decoration: underline;
  font-size: inherit;
  font-weight: bold;
}

.admin_tools {
  margin-top: 0.5em;
  margin-left: 10px;
  text-decoration: underline;
  font-size: inherit;
  font-weight: bold;
}

.farm_label {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85vw;
  float: left;
  line-height: 2em;
  vertical-align: middle;
  flex-grow: 1;
  text-align: left;
}

.farm_img {
  max-width: 80vw;
  max-height: 80vw;
  width: 192px;
  height: 192px;
  border: 2px solid var(--primary-light);
  border-radius: var(--general-border-radius);
  margin: 5px;
  display: flex;
  align-self: center;
  cursor: pointer;
}

.farms-list-div {
  flex-grow: 1;
  margin: var(--general-padding);
  border-radius: var(--general-border-radius);
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  align-self: center;
}

#app_menu {
  float: right;
  display: inline-flex;
}

.top-select-farm-div {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow: auto;
}

#farms_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: var(--general-padding);
  column-gap: var(--general-padding);
  overflow: scroll;
}
</style>

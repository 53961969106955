<template>
  <fullpageform title="John Deere Access" cancelLabel="Done" @cancel="cancelOrDone">
    <div style="width: 90vw; height: 80vh; display: flex; flex-direction: column; overflow-y: scroll;">
      <div v-for="(item, index) in farms" class="farm-card">
        <div style="display: flex; flex-direction: column; flex: 1">
          <div>
            <label class="label mr-1">John Deere Organization:</label><span>{{ item.name }}</span>
          </div>
          <div>
            <label class="label mr-1">John Deere ID: </label>{{ item.jd.organizationId }}
          </div>
          <div>
            <label class="label mr-1">Created By: </label>{{ item.createdBy?.email }} ({{ item.createdBy?.userId }})
          </div>
          <div style="display: flex; flex-direction: row; gap: 10px; align-items: center;">
            <label :for="`jd-access-label${item.id}}`" class="label">John Deere Access</label>
            <label class="switch">
              <input type="checkbox" :id="`jd-access-label${item.id}}`" :value="index" :checked="item.jd.access"
                @click="toggleAccess">
              <span class="slider round"></span>
            </label>
          </div>

        </div>
        <div style="display: flex;flex-direction: column; flex: 1">
          <div v-if="item.invoices.length > 0"
            style="display: flex; flex-direction: column; flex: 1; overflow-y: scroll;">
            <label class="label">Invoices</label>
            <div v-for="(invoice, index) in item.invoices"
              style="display: flex; flex-direction: column; border: solid 1px; padding: var(--general-padding); margin-bottom: var(--general-padding);">
              <label>Invoice ID: <span>{{ invoice?.id }}</span></label>
              <label>John Deere ID: <span>{{ invoice?.jd?.organizationId }}</span></label>
              <label>Created: <span>{{ new Date(invoice?.createdAt).toLocaleString() }}</span></label>
              <label>Created By: <span>{{ invoice.createdBy?.email || invoice?.userId }}</span></label>
              <label>Amount: <span>{{ invoice?.amount?.$numberDecimal }}</span></label>
              <label>Product ID: <span>{{ invoice?.productId }}</span></label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </fullpageform>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue'

const $api = inject('$api')
const $dialog = inject('$dialog')

const farms = ref([])

const refreshFarm = async () => {
  const results = await $api.jdAdmin.getOrganizationsAndStatus()
  const vals = results.map((r) => {
    if (!r.jd.access) {
      return { ...r, jd: { ...r.jd, access: false } }
    }
    return r
  })

  vals.sort((a, b) => {
    return a.name?.localeCompare(b.name)
  })

  farms.value = vals
}

async function cancelOrDone() {
  await $dialog.pop()
}

const toggleAccess = async (event) => {
  const farm = farms.value[event.target.value]
  let { id, jd } = farm
  jd.access = !jd.access
  await $api.jdAdmin.patchOrganizationAndStatus(id, jd)
  await refreshFarm()
}

onMounted(async () => {
  await refreshFarm()
})

</script>

<style lang="css" scoped>
@media (max-width: 720px) {
  .mobile-hide {
    display: none !important;
  }
}

@media (min-width: 720px) {
  .mobile-show {
    display: none !important;
  }
}

.label {
  font-weight: 700;
}

.farm-card {
  max-height: 200px;
  border: solid 1px;
  margin-top: var(--general-padding);
  padding: var(--general-padding);
  display: flex;
  flex-direction: row
}

.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: var(--jd-green);
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>

<template>
    <template>
        <pipe-segment :path="completedPath" :preview="false" />
        <pipe-segment v-if="previewPath" :path="previewPath" :preview="true" />
    </template>
</template>
    
<script setup>
import { computed, reactive, onMounted } from 'vue';

import { useMaps } from '@/composables/useMaps'
import { LatLng } from '@/store/Dto'

const props = defineProps({
    parentLatLng: {
        type: Object,
        required: true
    },
    snapCallback: {
        type: Function,
        required: false
    },
    snapAndFillCallback: {
        type: Function,
        required: false
    },
    snapLevel: {
        type: Number,
        required: false,
        default: 1
    }
})

const data = reactive({
    mouseLatLng: null
})

const path = defineModel()
const emit = defineEmits(['done'])

const { map, addMapListener, doDragHack } = useMaps()

const completedPath = computed(() => {
    return [new LatLng(props.parentLatLng), ...path.value]
})

function snapIfNecessary(latLng) {
    return props.snapCallback ? props.snapCallback(latLng, props.snapLevel) : latLng
}

const previewPath = computed(() => {
    if (!data.mouseLatLng) {
        return null
    }

    const lastLatLng = completedPath.value[completedPath.value.length - 1]

    if (props.snapAndFillCallback) {
        const snappedLatLng = snapIfNecessary(data.mouseLatLng)

        const snappedAndFilledLatLngs = props.snapAndFillCallback(lastLatLng, snappedLatLng, props.snapLevel)

        return snappedAndFilledLatLngs
    } else {
        return [lastLatLng, data.mouseLatLng]
    }
})

function click(e) {
    let latLng = new LatLng(e.latLng.lat(), e.latLng.lng())

    if (props.snapAndFillCallback) {
        const lastLatLng = completedPath.value[completedPath.value.length - 1]
        const snappedLatLng = snapIfNecessary(latLng)

        const snappedAndFilledLatLngs = props.snapAndFillCallback(lastLatLng, snappedLatLng, props.snapLevel)

        path.value.push(...snappedAndFilledLatLngs)
    }
    else {
        path.value.push(latLng)
    }

    data.mouseLatLng = null
}

function dblclick(e) {
    emit('done')
}

onMounted(() => {
    map.setOptions({
        disableDoubleClickZoom: true
    })

    doDragHack()

    addMapListener('mousemove', (e) => {
        const latLng = new LatLng(e.latLng.lat(), e.latLng.lng())

        if (props.snapCallback) {
            const snappedLatLng = props.snapCallback(latLng, props.snapLevel)

            if (snappedLatLng) {
                data.mouseLatLng = snappedLatLng
                return
            }
        }

        data.mouseLatLng = latLng
    })

    addMapListener('click', click)
    addMapListener('dblclick', dblclick)
})
</script>
<template>
  <div :class="containerClass">
    <div>
      <slot />
    </div>
    <div class="loading-spinner">
      <span class="fa fa-3x fa-fw fa-circle-o-notch fa-spin "></span>
    </div>
    <div v-if="label" class="loading-label">{{ label }}</div>
  </div>
</template>

<script>
export default {
  props: {
    inline: {
      type: Boolean,
      required: false,
      default: false
    },
    absolute: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    containerClass: (vm) => {
      if (vm.inline) {
        return 'loading_container_inline'
      }

      if (vm.absolute) {
        return 'loading_container_absolute'
      }

      return 'loading_container_fullscreen'
    }
  }
}
</script>

<style lang="css" scoped>
.loading_container_fullscreen {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: var(--opaque-background-color);
  color: var(--bg0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: italic;
  z-index: 10000;
}

.loading_container_inline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: italic;
  background: var(--opaque-background-color);
  color: var(--bg0);
}

.loading_container_absolute {
  position: absolute;
  background: var(--opaque-background-color);
  z-index: 1000;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-style: italic;
  color: var(--bg0);
}

.loading-spinner {
  background: transparent;
  opacity: 1.0;
  font-size: x-large;
  text-align: center;
  font-style: italic;
  align-self: center;
}

.loading-label {
  font-size: medium;
  text-align: center;
}
</style>

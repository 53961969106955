<template>
    <div>
        <div class="hole_sizes">
            <span>
                <input id="available_hole_sizes_quarter_inch" type="checkbox" v-model="data.quarterInch">
                <label for="available_hole_sizes_quarter_inch">1/4"</label>
            </span>

            <span>
                <input id="available_hole_sizes_five_sixteenths" type="checkbox" v-model="data.fiveSixteenths">
                <label for="available_hole_sizes_five_sixteenths">5/16"</label>
            </span>

            <span>
                <input id="available_hole_sizes_three_eighths" type="checkbox" v-model="data.threeEighths">
                <label for="available_hole_sizes_three_eighths">3/8"</label>
            </span>

            <span>
                <input id="available_hole_sizes_seven_sixteenths" type="checkbox" v-model="data.sevenSixteenths">
                <label for="available_hole_sizes_seven_sixteenths">7/16"</label>
            </span>

            <span>
                <input id="available_hole_sizes_half_inch" type="checkbox" v-model="data.halfInch">
                <label for="available_hole_sizes_half_inch">1/2"</label>
            </span>

            <span>
                <input id="available_hole_sizes_nine_sixteenths" type="checkbox" v-model="data.nineSixteenths">
                <label for="available_hole_sizes_nine_sixteenths">9/16"</label>
            </span>

            <span>
                <input id="available_hole_sizes_five_eighths" type="checkbox" v-model="data.fiveEighths">
                <label for="available_hole_sizes_five_eighths">5/8"</label>
            </span>

            <span>
                <input id="available_hole_sizes_eleven_sixteenths" type="checkbox" v-model="data.elevenSixteenths">
                <label for="available_hole_sizes_eleven_sixteenths">11/16"</label>
            </span>


            <span>
                <input id="available_hole_sizes_three_quarter_inch" type="checkbox" v-model="data.threeQuarterInch">
                <label for="available_hole_sizes_three_quarter_inch">3/4"</label>
            </span>

            <span>
                <input id="available_hole_sizes_thirteen_sixteenths" type="checkbox" v-model="data.thirteenSixteenths">
                <label for="available_hole_sizes_thirteen_sixteenths">13/16"</label>
            </span>

            <span>
                <input id="available_hole_sizes_seven_eighths" type="checkbox" v-model="data.sevenEighths">
                <label for="available_hole_sizes_seven_eighths">7/8"</label>
            </span>

            <span>
                <input id="available_hole_sizes_fifteen_sixteenths" type="checkbox" v-model="data.fifteenSixteenths">
                <label for="available_hole_sizes_fifteen_sixteenths">15/16"</label>
            </span>

            <span>
                <input id="available_hole_sizes_one_inch" type="checkbox" v-model="data.oneInch">
                <label for="available_hole_sizes_one_inch">1"</label>
            </span>
        </div>


        <div class="all_or_none">
            <a class="button" alt="All" v-on:click="makeAllTrue()">All</a>
            <a class="button widget_margin_left" alt="None" v-on:click="makeAllFalse()">None</a>
        </div>
    </div>
</template>

<script setup>
import { reactive, watch, onMounted } from 'vue'

const model = defineModel()

const PropertyToValueMap = {
    quarterInch: '1/4',
    fiveSixteenths: '5/16',
    threeEighths: '3/8',
    sevenSixteenths: '7/16',
    halfInch: '1/2',
    nineSixteenths: '9/16',
    fiveEighths: '5/8',
    elevenSixteenths: '11/16',
    threeQuarterInch: '3/4',
    thirteenSixteenths: '13/16',
    sevenEighths: '7/8',
    fifteenSixteenths: '15/16',
    oneInch: '1',
}

const data = reactive({
    quarterInch: true,
    fiveSixteenths: true,
    threeEighths: true,
    sevenSixteenths: true,
    halfInch: true,
    nineSixteenths: true,
    fiveEighths: true,
    elevenSixteenths: true,
    threeQuarterInch: true,
    thirteenSixteenths: true,
    sevenEighths: true,
    fifteenSixteenths: true,
    oneInch: true,
})

onMounted(() => {
    // update data from model
    const modelValue = model.value
    for (let key in PropertyToValueMap) {
        if ((modelValue.length === 0) || modelValue.some(v => v === PropertyToValueMap[key])) {
            data[key] = true
        }
        else {
            data[key] = false
        }
    }
})

watch(data, () => {
    const newVal = []

    for (let key in PropertyToValueMap) {
        if (data[key] === true) {
            newVal.push(PropertyToValueMap[key])
        }
    }

    model.value = newVal
})

function makeAllTrue() {
    data.quarterInch = true
    data.fiveSixteenths = true
    data.threeEighths = true
    data.sevenSixteenths = true
    data.halfInch = true
    data.nineSixteenths = true
    data.fiveEighths = true
    data.elevenSixteenths = true
    data.threeQuarterInch = true
    data.thirteenSixteenths = true
    data.sevenEighths = true
    data.fifteenSixteenths = true
    data.oneInch = true
}

function makeAllFalse() {
    data.quarterInch = false
    data.fiveSixteenths = false
    data.threeEighths = false
    data.sevenSixteenths = false
    data.halfInch = false
    data.nineSixteenths = false
    data.fiveEighths = false
    data.elevenSixteenths = false
    data.threeQuarterInch = false
    data.thirteenSixteenths = false
    data.sevenEighths = false
    data.fifteenSixteenths = false
    data.oneInch = false
}
</script>

<style lang="css">
.hole_sizes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    color: white;
}

.hole_sizes>span {
    width: 4em;
    margin: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.hole_sizes>span:hover {
    font-weight: bold;
}

.all_or_none {
    text-align: center;
    margin-top: var(--general-padding);
}

.all_or_none>a {
    width: 3em;
}
</style>

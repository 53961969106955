<template>
    <div>
        <label class="file-upload-element">
            <input type="file" ref="gpsCSVFile" @change.stop="csvFileSelected" /> <!-- .stop is needed to prevent event bubble -->
            <i class="fa fa-upload fa_button_icon"></i> Upload CSV
        </label>

        <label class="google-gps-element" @click="getGoogleGPSData">
            <i class="fa fa-cloud fa_button_icon"></i> Google Data
        </label>

        <div>
            <hint>CSV Format: [LAT],[LNG],[ELEVATION]</hint>
        </div>

        <label v-show="fileComplete">File imported and applied below. Points applied: {{ pointsApplied }}/{{ pointsImported
        }}</label>

        <label v-if="googleGpsComplete && (!errorMessage)">Google data imported successfully.</label>

        <label v-if="errorMessage" class="error">{{ errorMessage }}</label>
    </div>
</template>

<script>
import { Pp3ElevationCsvParser } from '@/drag_and_drop/Pp3ElevationCsvParser'

export default {
    components: {
    },
    props: ['latLngs'],
    data: function () {
        return {
            pointsApplied: 0, pointsImported: 0,
            fileComplete: false,
            googleGpsComplete: false,
            errorMessage: null
        }
    },
    computed: {},
    methods: {
        cancel: function () {
            this.$dialog.pop()
        },
        getGoogleGPSData: async function () {
            const confirmed = confirm('INFORMATION FROM THE GOOGLE MAPS SERVICES MAY DIFFER FROM ACTUAL CONDITIONS AND MAY NOT BE SUITABLE FOR YOUR USE\n\nINDEPENDENT JUDGMENT MUST BE EXERCISED WHEN USING THIS DATA', 'CONTINUE?')
            if (!confirmed) {
                return
            }

            // const PointCount = 50
            // const latLngs = PipePathAlg.subdividePath(this.latLngs, PointCount)

            const goodLatLngs = this.latLngs.filter((ll) =>
                ('lat' in ll) && ('lng' in ll)
            )

            try {
                const elevationResponse = await this.$store.getters.getElevation(goodLatLngs)

                const latLngsWithElevation = elevationResponse.results.map((o) => {
                     //Convert to feet, 1 decimal
                    const cleanedUpElevation = Number.parseFloat((o.elevation * 3.28084).toFixed(1))
                    
                    return {
                        lat: o.location.lat,
                        lng: o.location.lng,
                        elevationInFeet: cleanedUpElevation 
                    }
                })

                // this.$store.dispatch('mapObjects/debug/setElevationOverlay', latLngsWithElevation)

                this.emitLatLngsWithElevation(latLngsWithElevation)
                this.googleGpsComplete = true
                this.fileComplete = false
                this.errorMessage = null
            }
            catch (e) {
                this.errorMessage = "Elevation Fetch Failed: " + e

                this.$store.dispatch('warn', 'Elevation Fetch Failed: ' + e)
            }
        },
        async csvFileSelected() {
            const files = this.$refs.gpsCSVFile.files
            if (files.length === 0) {
                return
            }

            try {
                const file = files[0]
                const results = await Pp3ElevationCsvParser(file) || []

                this.$store.dispatch('mapObjects/debug/setElevationOverlay', results)
                this.fileComplete = true
                this.googleGpsComplete = false
                this.emitLatLngsWithElevation(results)
                this.errorMessage = null
            }
            catch (e) {
                this.$store.dispatch('warn', e)
            }
        },
        emitLatLngsWithElevation(latLngsWithElevation) {
            this.$emit('change', latLngsWithElevation)
        },
        setPointsStatus(pointsApplied, pointsImported) {
            this.pointsApplied = pointsApplied
            this.pointsImported = pointsImported
        }
    },
    mounted() {
    }
}
</script>

<style lang="css" scoped>
label {
    margin-left: 3px;
    width: auto;
    min-width: 30px;
}

.fieldset-group {
    border: 2px solid var(--primary);
    padding: 10px;
}

.fieldset-group-set {
    background-color: var(--primary);
    font-size: small;
    border-radius: 5px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

input[type="file"] {
    display: none;
}

.file-upload-element {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}

.google-gps-element {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}</style>

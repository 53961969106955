<template>
  <div>
    <div class="container">
      <div class="container-body">
        <div class="container-pipe-path text-dark">
          <div class="top-bar">
            <table class="summary_table">
              <tr>
                <td>
                  <b>Farm:</b>
                </td>
                <td>{{ farm.name }}</td>
              </tr>
              <tr>
                <td>
                  <b>Field:</b>
                </td>
                <td>{{ field.name }}</td>
              </tr>
              <tr>
                <td>
                  <b>Field Layout:</b>
                </td>
                <td>{{ fieldLayout.name }}</td>
              </tr>
              <tr v-if="furrowSet">
                <td>
                  <b>Set:</b>
                </td>
                <td>{{ furrowSet.name }}</td>
              </tr>
              <tr>
                <td>
                  <b>Flow Rate:</b>
                </td>
                <td>{{ flowRate?.valueInGpm }} gpm</td>
              </tr>
            </table>
            <a class="button" @click="showHoleDesign"><i class="fa fa-file-text"></i> Hole Design</a>
          </div>
          <div class="identity-row-container">
            <div class="identity-column">
              <div class="map-image-container">
                <img class="map-image" :src="pipeImageUrl" />
              </div>
            </div>

            <div class="identity-column">
              <span>Start Set Irrigation</span>
              <div class="watering-input-items">
                <div class="input-group">
                  <label for="start-date">Start</label>
                  <br />
                  <input name="start-date" type="datetime-local" :value="startString"
                    @input="startValueChanged($event.target.value)" />
                </div>
                <div class="input-group">
                  <label for="duration">Duration(hrs)</label>
                  <br />
                  <input style="height:20px; width:75px" name="duration" autocomplete="off" type="number" min="0"
                    :value="durationValue" @input="durationChanged($event.target.value)" />
                </div>
                <div class="input-group">
                  <label for="stop-date">Stop</label>
                  <br />
                  <input name="stop-date" :min="minStop" type="datetime-local" :value="stopString"
                    @input="stopValueChanged($event.target.value)" />
                </div>
              </div>
              <br />
              <span>Irrigation Scheduling Method</span>
              <div class="moisture-input-items">
                <div class="input-group">
                  <label for="moisture-method">Method</label>
                  <br />
                  <select name="moisture-method" v-model="moistureMethod" style="height:30px">
                    <option>Calendar (number of days)</option>
                    <option>Soil moisture sensor (centibars)</option>
                    <option>E/T (inches per day)</option>
                    <option>Paddy Depth (inches)</option>
                    <option>Feel and Appearance (inch needed)</option>
                  </select>
                </div>
                <div class="input-group">
                  <label for="measurement">Measurement</label>
                  <br />
                  <input style="height:20px; width:95%;" name="measurement" autocomplete="off" type="number"
                    v-model="measurement" />
                </div>
              </div>
              <button class="button" @click="addWateringEvent()" :disabled="! addEnabled">
                <i class="fa fa-plus fa-lg" style="position:relative; padding-top:0; margin-right:5px;"></i>Add
              </button>
            </div>

            <div class="watering-table" v-if="wateringEvents.length > 0">
              <table class="hole-location-table">
                <thead>
                  <tr>
                    <th>Start</th>
                    <th class="mobile-hide">Stop</th>
                    <th>Duration (hrs)</th>
                    <th>Water (gal)</th>
                    <th class="mobile-hide">Moisture Method</th>
                    <th class="mobile-hide">Measurement</th>
                    <!-- <th class="mobile-hide">User</th> -->
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(event) in wateringEvents" :key="event.id">
                      <td class="nowrap">{{ getDisplayDateTimeString(event.start) }}</td>
                      <td class="mobile-hide nowrap">{{ getDisplayDateTimeString(event.stop) }}</td>
                      <td>{{ event.duration }}</td>
                      <td>{{ event.gallons ? event.gallons.toLocaleString() : 0 }}</td>
                      <td class="mobile-hide">{{ event.moistureMethod }}</td>
                      <td class="mobile-hide">{{ event.measurement }}</td>
                      <!-- <td class="mobile-hide">{{ event.user }}</td> -->
                      <td><i class="fa fa-lg fa-trash" style="cursor:pointer;" @click="removeRow(event)"></i></td>
                  </tr>
                  <tr>
                      <td><b>Total</b></td>
                      <td class="mobile-hide"></td>
                      <td><b>{{ totalWateringEvent.duration }}</b></td>
                      <td><b>{{ totalWateringEvent.gallons.toLocaleString() }}</b></td>
                      <td class="mobile-hide"></td>
                      <td class="mobile-hide"><b>{{ totalWateringEvent.measurement }}</b></td>
                      <!-- <td class="mobile-hide"></td> -->
                      <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="no-irrigation-events-div" v-else>
              -- No Irrigation Events --
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WateringEvent } from "@/store/Dto"
import { v4 as uuidv4 } from 'uuid';
import HoleDesignDialog from '../components/HoleDesignDialog'
import * as FormatAlg from '@/util/FormatAlg'

export default {
  components: {},
  props: {
    farm: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true,
      default: null
    },
    fieldLayout: {
      type: Object,
      required: true,
      default: null
    },
    furrowSet: {
      type: Object,
      required: false,
      default: null
    },
    irrigationSystem: {
      type: Object,
      required: true,
      default: null
    },
    pipePath: {
      type: Object,
      required: true,
      default: null
    }
  },
  computed: {
    addEnabled: vm => {
      return vm.startString?.length &&
        vm.stopString?.length &&
        vm.moistureMethod?.length &&
        Number.isFinite(vm.measurement)
    },
    wateringEvents: (vm) => {
      const ret = vm.$store.getters['wateringEvents/pipePathEvents'](vm.pipePath.id)

      return ret
    },
    totalWateringEvent: vm => {
      let totalGallons = 0;
      let totalDuration = 0;
      let totalMeasurements = [];
      let totalMoistureMethods = []
      let finalMoistureMethods = []
      vm.wateringEvents.forEach(wateringEvent => {
        totalGallons += wateringEvent.gallons ? wateringEvent.gallons : 0;
        totalDuration += wateringEvent.duration ? parseInt(wateringEvent.duration) : 0;

        if (!wateringEvent.moistureMethod?.length) {
          return;
        }

        if (!totalMoistureMethods.includes(wateringEvent.moistureMethod)) {
          totalMoistureMethods.push(wateringEvent.moistureMethod)
          let measurement = {
            value: wateringEvent.measurement,
            method: wateringEvent.moistureMethod
          }
          totalMeasurements.push(measurement)
        } else {
          totalMeasurements.forEach((measurement) => {
            if (measurement.method === wateringEvent.moistureMethod) {
              measurement.value = parseFloat(wateringEvent.measurement) + parseFloat(measurement.value)
            }
          })
        }
      })
      totalMeasurements.forEach((m) => {
        finalMoistureMethods.push(`${m.method}: ${m.value}`)
      })

      return {
        duration: totalDuration
        , measurement: finalMoistureMethods.join(', ')
        , gallons: totalGallons.toLocaleString()
      };
    },
    pipeImageUrl: vm => {
      return vm.$staticImages.buildFieldPipePathUrl(
        vm.fieldLayout,
        vm.pipePath
      )
    },
    maxStart: vm => {
      return vm.stopString;
    },
    minStop: vm => {
      return vm.startString;
    },
    flowRate: vm => vm.irrigationSystem.flowRates.find(
      (fr) => fr.id === vm.pipePath.flowRateId)
  },
  data: function () {
    return {
      pipeLengthRef: 0,
      durationValue: 0,
      startString: null,
      stopString: null,
      moistureMethod: null,
      measurement: null
    };
  },
  methods: {
    getDisplayDateTimeString: function (d) {
      const dateValue = new Date(d)
      return FormatAlg.wateringTime(dateValue)
    },

    getLocalDateTimeString: function (d) {
      const offsetMs = d.getTimezoneOffset() * 60 * 1000;
      const dateLocal = new Date(d.getTime() - offsetMs)
      return dateLocal.toISOString().slice(0, 16)
    },

    durationChanged: function (value) {
      this.durationValue = Number.parseFloat(value);

      if(! Number.isFinite(this.durationValue)) {
        return
      }

      const stopValue = new Date(this.startString + 'Z') //need to add an 'Z' to the end of the 
      const startValue = new Date(this.startString + 'Z')//string so the date is recognized as UTC
      stopValue.setHours(startValue.getHours() + parseInt(this.durationValue))//otherwise toISOString will change the date value
      this.stopString = stopValue.toISOString().slice(0, 16)
    },

    startValueChanged: function (value) {
      this.startString = value;

      if (!this.startString?.length) {
        this.durationValue = 0;
        return;
      }

      let stopValue = new Date(this.stopString + 'Z')
      const startValue = new Date(this.startString + 'Z')
      if (stopValue < startValue) {
        this.stopString = value;
        stopValue = new Date(this.stopString)
      }
      this.durationValue = Math.ceil((stopValue - startValue) / 36e5)
    },

    stopValueChanged: function (value) {
      this.stopString = value;

      if (!this.stopString?.length) {
        this.durationValue = 0;
        return;
      }

      let stopValue = new Date(this.stopString)
      const startValue = new Date(this.startString)
      if (stopValue < startValue) {
        this.stopString = this.startString;
        stopValue = new Date(this.stopString)
      }
      this.durationValue = Math.ceil((stopValue - startValue) / 36e5)
    },

    addWateringEvent: function () {
      if (!this.flowRate) {
        alert('Internal Error - No Flow Rate On This Hole Design!');
        return;
      }

      const gallons = (this.durationValue * (this.flowRate.valueInGpm * 60))
      const event = new WateringEvent(
        uuidv4(),
        this.startString,
        this.stopString,
        this.durationValue,
        gallons,
        this.moistureMethod,
        this.measurement,
        this.farm.id, this.field.id, this.fieldLayout.id,
        this.furrowSet ? this.furrowSetId : null,
        this.irrigationSystem.id,
        this.pipePath.id
      )

      this.$store.dispatch("wateringEvents/add", event)
    },
    removeRow(event) {
      this.$store.dispatch("wateringEvents/remove", event)
    },
    async showHoleDesign() {
      this.$dialog.pop()

      this.$dialog.push(HoleDesignDialog, {
        farm: this.farm, field: this.field
      })
    }
  },
  mounted: function () {
    this.startString = this.getLocalDateTimeString(new Date())
    this.stopString = this.getLocalDateTimeString(new Date())
  }
};
</script>

<style lang="css" scoped>
@media (max-width: 576px) {
  .mobile-hide {
    display: none !important;
  }

  .map-image-container {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .top-bar {
    flex-flow: column wrap;
  }
}

@media (min-width: 576px) {
  .container-pipe-path {
    width: 8.5in;
  }
}

.container {
  display: flex;
  flex-direction: column;
  height: inherit;
  background: var(--primary-lighter);
  border-radius: var(--general-border-radius);
}

.action-buttons {
  display: flex;

  .button {
    width: 100px;
    background-color: #41b883;
    border: 1px solid var(--secondary-light);
    border: none;
    color: white;
    padding: 4px 10px 4px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    line-height: 1;
    margin: 5px;
    height: 30px;
  }

  .button:hover {
    color: var(--secondary-light);
    background-color: var(--primary-light);
  }
}

.container-body {
  display: flex;
  flex-direction: column;
  overflow-y: auto !important;
  user-select: text;
  width: 100%;
}

.container-pipe-path {
  margin-left: auto;
  margin-right: auto;
  border-radius: var(--general-border-radius);
  border: 1.5px solid var(--secondary-light);
  padding: var(--general-padding);
}

.identity-row-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-radius: var(--general-border-radius);
  padding: var(--general-padding);
  background-color: white;

}

.map-image-container {
  margin: 5px;
}

.map-image {
  width: 100%;
}

.identity-column {
  margin: 5px 0px;
  padding: 5px 0px;
}

.hole-design-button {
  margin: 0.5em;
}

.hole-location-table {
  margin: 5px;
  table-layout: fixed;
  
  & tr:nth-child(even) {
    background-color: var(--secondary-light);
  }

  & thead {
    text-align: left;
    background: var(--secondary-light);
    color: black;
  }

  & th, td {
    padding: 5px;
    text-align: left;
  }

  & td {
    font-size: 12px;
  }

  & tr:hover {
    background: var(--bg1);
    color: var(--secondary-light);
  }
}

.watering-table {
  margin: 5px;
  width: 100%;
}

.no-irrigation-events-div {
  text-align: center;
  display: flex;
  flex-grow: 2;
  justify-content: center;
}

.watering-input-items {
  border: 1px solid var(--secondary-light);
  border-radius: var(--general-border-radius);
  background-color: var(--bg1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 0.5em 0.5em 0.5em 0em;
}

.moisture-input-items {
  border: 1px solid var(--secondary-light);
  border-radius: var(--general-border-radius);
  background-color: var(--bg1);
  padding: 10px;
  display: flex;
  flex-direction: column;
  margin: 0.5em 0.5em 0.5em 0em;
}

.text-dark {
  color: var(--bg0);
  font-weight: bold;
}

.input-group {
  display: inline-block;
  margin: 5px;
}

label {
  color: var(--secondary-light);
  font-size: 14px;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5em 0em 0.5em 0em;
}

.summary_table {
  min-width: 200px;
  max-width: 300px;
}
</style>

<template>
    <fullpageform :title="title">
        <fieldset id="edit_water_source_fieldset">
            <label for="water_source_name">Water Source Name</label>
            <br>
            <input type="text" id="water_source_name" v-model="irrigationSystemName"
                @click="$event.target.select()">
        </fieldset>

        <br>

        <fieldset>
            <legend>Flow Rates</legend>
            <div >
              <div id="flow_rates_table_wrapper" > 
                <table id="flow_rates_table" v-if="flowRatesWithMetadata.length >= 1">
                    <tr>
                        <th>Flow Name</th>
                        <th>Value (gpm)</th>
                        <th>Used</th>
                    </tr>
                        <tr 
                            v-for="o in flowRatesWithMetadata"
                            :key="o.flowRate.id">
                            <td>
                                <input 
                                    class="flow_rate_name"
                                    @click="$event.srcElement.select()"
                                    @keyup.enter="save"
                                    v-model="o.flowRate.name">
                            </td>
                            <td>
                                <input
                                    class="flow_rate_value"
                                    v-model.number="o.flowRate.valueInGpm"
                                    type="number"
                                    min="100"
                                    max="10000"
                                    step="1"
                                    @change="rateChanged(o.flowRate, $event)"
                                    @keyup.enter="save"
                                    @click="$event.srcElement.select()">
                            </td>
                            <td>
                                <template v-if="o.referencedFieldNames.length > 0">
                                    <span :title="'Used by ' + o.referencedFieldNames.join(', ')">{{ o.referencedFieldNames.length }}x</span>
                                </template>
                                <template v-else>
                                    Unused
                                </template>
                            </td>
                            <td v-if="o.referencedFieldNames.length === 0"><a   href="#" @click.stop="removeFlowRateWithMetadata(o)"><i class="fa fa-trash remove_flow_rate"/></a></td>
                        </tr>
                </table>

                </div>

                <div class="hint_div">
                    <hint v-if="flowRatesWithMetadata.length > 0">
                        Tap flow rate name or value to edit.
                    </hint>
                    <hint v-else>
                        No Flow Rates Added
                    </hint>
                </div>
            </div>

        </fieldset>

        <template v-slot:action-buttons>
            <button v-if="showCalculateSetsAsSaveButton"
                :disabled="! calculateSetsEnabled"
                @click.once="save()">Calculate Sets</button>
            <button v-else
                type="submit"
                :disabled="! saveEnabled "
                @click.once="save()">Save All</button>

            <div id="add_flow_rate_buttons" class="button_margin_left">
                <button type="button" @click="addNewFlowRate">New Flow</button>
            </div>

            <button
                class="button_margin_left"
                v-if="showCancel"
                @click.once="cancel">Cancel</button>
        </template>
    </fullpageform>
</template>

<script>
import {FlowRate, FieldMode} from '@/store/Dto'
import * as NamingAlg from '@/util/NamingAlg'

class FlowRateAndMetadata {
    constructor(flowRate) {
        this.flowRate = flowRate
        this.referencedFieldNames = []
    }
}

export default {
    props:{
       irrigationSystem: {
          type: Object,
          required: true
      },
      farm: {
          type: Object,
          required: true 
      },
      field: {
          type: Object,
          required: false
      },
      fieldLayout: {
          type: Object,
          required: false
      },
      fromSource: {
        type: String,
        required: false,
        default: ''
      },
      showCancel: {
        type: Boolean,
        required: false,
        default: true
      },
      title: {
          type: String,
          required: false,
          default: 'Edit Water Source'
      }
    },
    data: function() {
        return {
            irrigationSystemName: null,
            flowRatesWithMetadata: [],
            defaultFlowRateInGpm: 1000,
            edit: false,
            FieldMode
        }
    },
    computed: {
        irrigationSystemNamePatch: vm => {
            if(vm.irrigationSystemName === vm.irrigationSystem.name) {
                return null
            }

            return {
                op: ('name' in vm.irrigationSystem) ? 'replace' : 'add',
                path: '/name', 
                value: vm.irrigationSystemName
            }
        },
        showCalculateSetsAsSaveButton: (vm) =>
             vm.fromSource == 'Wizard' && vm.fieldLayout.mode === FieldMode.Furrows,
        calculateSetsEnabled: (vm) => vm.flowRatesWithMetadata.length > 0,
        saveEnabled: (vm) => {
            if(vm.irrigationSystemNamePatch != null && vm.flowRatesWithMetadata.length > 0 ) {
                return true
            }

            const newFlowRates = vm.flowRatesWithMetadata.map((o) => o.flowRate)

            return JSON.stringify(newFlowRates) !== JSON.stringify(vm.irrigationSystem.flowRates)
        }
    },
    watch: {
    },
    methods: {
        rateChanged(flowRate) {
            const FallbackValue = 1000

            const isBad = ((typeof flowRate.valueInGpm) !== 'number')
                || flowRate.valueInGpm < 100
                
            if(isBad) {
                flowRate.valueInGpm = FallbackValue
            }
        },
        save: async function() {
            let patches = []
            let newFlowRates = null

            if(this.irrigationSystemNamePatch != null) {
                patches.push(this.irrigationSystemNamePatch)
            }

            newFlowRates = this.flowRatesWithMetadata.map((o) => o.flowRate)
            const flowRatesPatch = {
                op: ('flowRates' in this.irrigationSystem) ? 'replace' : 'add',
                path: '/flowRates',
                value: newFlowRates
            }
            patches.push(flowRatesPatch)

            if(this.fromSource == 'Wizard') {
                const finalIrrigationSystem = {
                    ...this.irrigationSystem,
                    flowRates: newFlowRates, name: this.irrigationSystemName
                }

                this.$emit('added', finalIrrigationSystem)
            }
            else if(this.fromSource == 'WaterSourceSettings') {
                await this.$store.dispatch('patchIrrigationSystem', 
                    {id: this.irrigationSystem.id, patch: patches})
                this.$emit('patched')
            }
            else {
                const finalIrrigationSystem = {
                    ...this.irrigationSystem,
                    flowRates: newFlowRates, name: this.irrigationSystemName
                }
                await this.$store.dispatch('addIrrigationSystem', finalIrrigationSystem)
            }

            this.$dialog.pop()
        },
        cancel: function() {
            this.$dialog.pop()
        },
        removeFlowRateWithMetadata(flowRateWithMetadata) {
            const index = this.flowRatesWithMetadata.indexOf(flowRateWithMetadata)
            this.flowRatesWithMetadata.splice(index, 1)
        },
        async addNewFlowRate() {
            const existingNames = this.flowRatesWithMetadata.map((fr) =>  fr.flowRate.name )
            const nameRegex = /^Rate ([0-9]+)$/
            const namePrefix = 'Rate '
            const name = NamingAlg.defaultName(existingNames, 
                {nameRegex, namePrefix})

            const flowRate = new FlowRate(name, this.defaultFlowRateInGpm)

            this.flowRatesWithMetadata.push(new FlowRateAndMetadata(flowRate))
        }
    },
    mounted: function() {  
        if(this.fromSource == "Wizard") {
            this.irrigationSystemName = this.field.name
        } else{
            this.irrigationSystemName = this.irrigationSystem.name
        }

        const fieldName = (id) => {
            const field = this.farm.fields.find((f) => f.id === id)

            if(field == null ) {
                return 'Not Found'
            }

            return field.name
        }

        if(this.irrigationSystem.flowRates.length >= 1) {
            this.flowRatesWithMetadata = this.irrigationSystem.flowRates.map(
                (flowRate) => new FlowRateAndMetadata(JSON.parse(JSON.stringify(flowRate))))

            const addReferencedFields = (flowRateWithMetadata) => {
                const fieldNames = new Set()

                const flowRateId = flowRateWithMetadata.flowRate.id

                this.farm.fields.forEach((field) => {
                    let activeLayout = field.layouts.find((layout) => field.activeLayoutId == layout.id) 
                    if(activeLayout.primaryFlowRateId === flowRateId) {
                        fieldNames.add(field.name)
                    }
                })

                this.farm.irrigationSystems.forEach((irrigationSystem) => {
                    irrigationSystem.pipePaths.forEach((pipePath) => { // this will need to find the layout id related to the given pipepath not the field any longer
                        if(pipePath.flowRateId === flowRateId) {
                            fieldNames.add(fieldName(pipePath.fieldId))
                        }
                    })
                })

                flowRateWithMetadata.referencedFieldNames = Array.from(fieldNames)
            }

            this.flowRatesWithMetadata.forEach(addReferencedFields)
        }else{
            this.addNewFlowRate()
        }
    }
}
</script>

<style lang='css' scoped>
#edit_water_source_fieldset {
    min-width: 15em;
    max-width: 90vw;
}

#add_flow_rate_buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#flow_rates_table_wrapper {
    display: flex;
}

#flow_rates_table {
    flex-grow: 1;
    border-radius: var(--softer-border-radius);
    padding: var(--smaller-padding);
}

#flow_rates_table td {
    background-color: var(--secondary-lighter);
}

.remove_flow_rate {
    color: var(--primary-darker);
    font-size: 1.2em;
    text-align: center;
}

.hint_div {
    margin-top: var(--general-padding);
    text-align: center;
    padding: 0px;
}

.flow_rate_name {
    min-width: 6em;
    width: 10em;
}

.flow_rate_value {
    min-width: 4em;
}
</style>
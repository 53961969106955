<template>
  <map-controls position="LEFT_CENTER">
    <div class="context_menu">
      <slot />
    </div>
  </map-controls>
</template>

<script>
import MapControls from '@/components/maps/MapControls'

export default {
  components: {
    MapControls
  },
  props: {
  }
}
</script>

<style lang="css">
.context_menu {
  border-top-right-radius: var(--menu-border-radius);
  border-bottom-right-radius: var(--menu-border-radius);
  padding: var(--menu-border-size);
  flex-flow: column;
  overflow: auto;
  background: var(--menu-background-color);
}

@media screen and (orientation:portrait) {
  .context_menu {
    max-height: 80vh;
  }
}

@media screen and (orientation:landscape) {
  .context_menu {
    max-height: 80vh;
  }
}

.context_menu_popout {
  background-color: var(--primary-dark);
  font-size: small;
  padding: var(--general-border-width);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
</style>

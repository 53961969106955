<template>
  <pp4-dialog>
    <CalculatorForm
      v-if="workingFieldLayout"
      :farm="farm"
      :field="field"
      :fieldLayout="workingFieldLayout"
      :fromWizard="fromTheWizard"
      v-bind="workingFieldLayout"
      @cancel="onCancel"
      @save="onSave"
      @updatesets="onUpdateSets"
      @drawsets="onDrawSets"
    />
  </pp4-dialog>
</template>

<script>
import CalculatorForm from '@/components/maps/edit_furrow_sets/CalculatorForm'
import { FurrowSetAlg } from '@/geo/FurrowSetAlg'

export default {
  components: {
    CalculatorForm
  },
  props: {
    farm: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    fieldLayout: {
      type: Object,
      required: true
    },
    fromTheWizard: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: function () {
    return {
      workingFieldLayout: null,
      alg: new FurrowSetAlg()
    }
  },
  emits: ['change', 'cancel'],
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onUpdateSets(calculatorResults) {
      this.updateWorkingFieldLayout(calculatorResults, true)
    },
    onSave(calculatorResults) {
      this.updateWorkingFieldLayout(calculatorResults, false)
    },
    onDrawSets(calculatorResults) {
      this.updateWorkingFieldLayout(calculatorResults, false)
    },
    updateWorkingFieldLayout(calculatorResults, updateFurrowSetDetails) {
      const renameSetsNeeded =
        this.workingFieldLayout.primaryFlowRateId !== calculatorResults.primaryFlowRateId

      const newFieldLayoutProps = {
        furrowSpacingInInches: calculatorResults.furrowSpacingInInches,
        alternatingFurrows: calculatorResults.alternatingFurrows,
        primaryFlowRateId: calculatorResults.primaryFlowRateId,
        desiredWateringTimeInHours: calculatorResults.desiredWateringTimeInHours,
        grossAppliedInches: calculatorResults.grossAppliedInches
      }

      Object.assign(this.workingFieldLayout, newFieldLayoutProps)

      if (renameSetsNeeded) {
        this.renameSetsWithRespectToWaterSourceLocation()
      }

      if (updateFurrowSetDetails) {
        this.splitField(calculatorResults.idealAcresPerSet, calculatorResults.primaryFlowRateId)
      }
      this.$emit('change', this.workingFieldLayout)
    },
    renameSetsWithRespectToWaterSourceLocation() {
      const primaryFlowRateId = this.workingFieldLayout.primaryFlowRateId

      const primaryIrrigationSystem = this.farm.irrigationSystems.find((irrigationSystem) => {
        return irrigationSystem.flowRates.some((flowRate) => flowRate.id === primaryFlowRateId)
      })

      const waterSourceLocation = primaryIrrigationSystem
        ? primaryIrrigationSystem.waterSourceLocation
        : null

      const newFurrowSetDetails = JSON.parse(JSON.stringify(this.fieldLayout.furrowSetDetails))

      this.alg.nameAndSortFurrowSetsWithRespectToWaterSourceLocation(
        newFurrowSetDetails,
        waterSourceLocation
      )

      this.workingFieldLayout.furrowSetDetails = newFurrowSetDetails
    },
    splitField(idealAcresPerSet, primaryFlowRateId) {
      const primaryIrrigationSystem = this.farm.irrigationSystems.find((irrigationSystem) => {
        return irrigationSystem.flowRates.some((flowRate) => flowRate.id === primaryFlowRateId)
      })

      const waterSourceLocation = primaryIrrigationSystem
        ? primaryIrrigationSystem.waterSourceLocation
        : null

      const splitPaths = []

      const debugPolys = []

      const newFurrowSetDetails = this.alg.buildFurrowSetDetailsUsingMaxSetAcres(
        idealAcresPerSet,
        splitPaths,
        this.workingFieldLayout.furrowBearing,
        this.workingFieldLayout.path,
        waterSourceLocation,
        debugPolys
      )

      if(debugPolys.length) {
        this.$store.dispatch('mapObjects/debug/setGeoJsonPolys', debugPolys)
      }

      this.workingFieldLayout.furrowSetDetails = newFurrowSetDetails
    }
  },
  mounted() {
    this.workingFieldLayout = JSON.parse(JSON.stringify(this.fieldLayout))
  }
}
</script>

<style lang="css"></style>

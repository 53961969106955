
<script>
import { toRaw } from 'vue'

export default {
    inject: ['provider'],
    computed: {
        api: (vm) => vm.provider.api,
        map: (vm) => vm.provider.map,
        interpretationFactory: (vm) => vm.provider.interpretationFactory,
        googleMapUtils: (vm) => vm.provider.googleMapUtils,
        zoomLevel: (vm) => vm.provider.zoomLevel,
        boundsGeo: (vm) => vm.provider.boundsGeo
    },
    data: function() {
        return {
            mapObjects: [],
            mapListeners: []
        }
    },
    beforeUnmount() {
        this.clearMapObjects()
        this.clearMapListeners()
    },
    watch: {
    },
    render() {
    },
    mounted() {
       this.mapReady()
    },
    methods: {
        mapReady() {
        },
        invokeMapReadyIfAvailable() {
            if(this.api && this.map && this.interpretationFactory) {
                this.mapReady()
            }
        },
        removeMapObject(o) {
            if(! o) {
                return
            }

            let index = this.mapObjects.indexOf(o)
            if(index < 0) {
                index = this.mapObjects.indexOf(toRaw(o))
                if(index < 0) {
                    return
                }
            }
            
            this.mapObjects.splice(index, 1)

            this.api.event.clearInstanceListeners(o)
            o.setMap(null)
            toRaw(o).setMap(null)
        },
        clearMapObjects: function () {
            this.mapObjects.forEach((o) => {
                this.api.event.clearInstanceListeners(o)
                toRaw(o).setMap(null)
            })
            this.mapObjects = []
        },
        clearMapListeners: function() {
            this.mapListeners.forEach((listener) => {
                this.api.event.removeListener(listener)
            })

            this.mapListeners = []
        },
        addListener(eventName, callback) {
            this.mapObjects.forEach((o) => {
                this.api.event.addListener(o, eventName, callback)
            })
        },
        addMapListener(eventName, callback) {
            let listener = this.api.event.addListener(this.map, eventName, callback)
            this.mapListeners.push(listener)
        },
        addMapDomListener(eventName, callback) {
            let listener = this.api.event.addListener(this.map, eventName, callback)
            this.mapListeners.push(listener)
        },
        fitBbox(bbox) {
            // sw, ne
            // let w1 = bb1[0]
            // let s1 = bb1[1]
            // let e1 = bb1[2]
            // let n1 = bb1[3]
            const mapsBbox = {
                east: bbox[2],
                west: bbox[0],
                north: bbox[3],
                south: bbox[1]
            }

            this.map.fitBounds(mapsBbox)
        },
        centerOnBbox(bbox) {
            const mapsBbox = new this.api.LatLngBounds({
                east: bbox[2],
                west: bbox[0],
                north: bbox[3],
                south: bbox[1]
            })

            this.map.setCenter(mapsBbox.getCenter())
        },
        panToBbox(bbox) {
            const mapsBbox = {
                east: bbox[2],
                west: bbox[0],
                north: bbox[3],
                south: bbox[1]
            }

            this.map.panToBounds(mapsBbox)
        },
    }
}
</script>

<template>
    <div id="debug_menu" v-show="shown">
       <icon-button description="Previous Debug Object" label="Prev" class="" v-on:click="prev()"><img :src="PrevIcon"></icon-button>
       <icon-button description="Next Debug Object" label="Next" class="button_margin_left" v-on:click="next()"><img :src="NextIcon" :draggable="false"></icon-button>
       <icon-button description="Remove Debug Object" label="Delete" class="button_margin_left" v-on:click="remove()"><img :src="RemoveIcon" :draggable="false"></icon-button>
    </div>
</template>

<script>

import NextIcon from '@/assets/next_debug_object_icon.svg'
import PrevIcon from '@/assets/prev_debug_object_icon.svg'
import RemoveIcon from '@/assets/remove_debug_object_icon.svg'

export default {
    components: {
    },
    data: function() {
        return {
            NextIcon, PrevIcon, RemoveIcon
        }
    },
    methods: {
        prev() {
            this.$store.dispatch('mapObjects/debug/selectPrevGeoJsonPoly')
        },
        next() {
            this.$store.dispatch('mapObjects/debug/selectNextGeoJsonPoly')
        },
        remove() {
            this.$store.dispatch('mapObjects/debug/removeSelectedGeoJsonPoly')
        }
    },
    computed: {
        shown: (vm) => vm.$store.state.mapObjects.debug.selectedGeoJsonPoly != null
    }
}
</script>

<style scoped>
#debug_menu {
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
}
</style>

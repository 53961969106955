<template>
    <Teleport :to="`#${target}`">
        <div :key="new Date()">
            <slot/>
        </div>
    </Teleport>
</template>

<script setup>
import { useMaps } from '@/composables/useMaps'

const props = defineProps({
    position: {
        type: String,
        required: true
    }
})

const maps = useMaps()

const target = `map-controls-${props.position}`

// utterly ridiculous, but this will prevent the controls from shifting as they are substituted...
// 0 values do not work
maps.map.panBy(0, new Date().getTime() % 2 === 0 ? 1 : -1);
</script>

<style>
</style>

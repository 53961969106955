<template>
  <map-controls position="TOP_CENTER" v-if="showMainNavMenu">
    <main-nav-menu />
  </map-controls>
  <map-controls position="BOTTOM_CENTER">
    <HelpBubble />
  </map-controls>

  <pan-action />
  <!-- <field-clusters /> -->

  <add-field-wizard
    v-if="showAddField || showAddFieldLayout"
    @starting-geojson-used="startingGeoJsonUsedForAddField"
    :farm="selectedFarm"
    :parentField="showAddFieldLayout ? selectedField : null"
  />
  <edit-field-bounds
    v-if="showEditFieldBounds"
    :field="selectedField"
    :fieldLayout="selectedFieldLayout"
  />
  <!-- <edit-field-elevation v-if="showEditFieldElevation" 
        :field="selectedField" /> -->
  <edit-furrow-sets
    v-if="showEditFurrowSets"
    :farm="selectedFarm"
    :fieldLayout="selectedFieldLayout"
    @change="selectedFieldLayoutChanged"
    @cancel="returnToSelectedFieldOrFurrowSet"
    :field="selectedField"
    :flowRateInGpm="1500"
  />
  <!-- TODO: extract this from something more meaningful -->
  <template v-if="showEditFurrowDirection && selectedField">
    <edit-furrow-direction v-if="! selectedField.jd"
      :fieldLayout="selectedFieldLayout"
      :field="selectedField"
      :irrigationSystem="primaryIrrigationSystemForSelectedField"
    />
    <jd-edit-furrow-direction v-if="selectedField.jd"
      v-model="selectedFieldLayout.furrowBearing"
      :farm="selectedFarm"
      :field="selectedField"
      :fieldLayout="selectedFieldLayout"
      :irrigationSystem="primaryIrrigationSystemForSelectedField"
      @done="returnToSelectedFieldOrFurrowSet"
      :enableSave="true" @cancel="returnToSelectedFieldOrFurrowSet"
    />
  </template>
  <add-irrigation-system
    v-if="showAddWaterSource"
    :farm="selectedFarm"
    :field="selectedField"
    :fieldLayout="selectedFieldLayout"
    :furrowSet="selectedFurrowSet"
  />
  <move-irrigation-system
    v-if="showMoveIrrigationSystem"
    :farm="selectedFarm"
    :irrigationSystem="selectedIrrigationSystem"
  />
  <add-pipe-path
    v-if="showAddPipePath"
    :field="selectedField"
    :fieldLayout="selectedFieldLayout"
    :furrowSet="selectedFurrowSet"
  />
  <edit-pipe-path
    v-if="showEditPipePath"
    :field="selectedField"
    :fieldLayout="selectedFieldLayout"
    :furrowSet="selectedFurrowSet"
    :pipePath="selectedIrrigationSystemPipePath"
    :irrigationSystem="selectedIrrigationSystem"
  />

  <irrigation-systems v-if="!inJdFieldSetupMode" />
  <debug-objects />
  <edit-levees v-if="showEditLevees" :field="selectedField" :fieldLayout="selectedFieldLayout" />

  <context-menu v-if="showFieldMenus">
    <FieldMenus />
  </context-menu>

  <template v-if="showSelectedField">
    <template v-if="selectedFieldNeedsJdWizard">
      <jd-begin-wizard-button
        :farm="selectedFarm"
        :field="selectedField"
        :fieldLayout="selectedFieldLayout"
        @wizard-started="jdWizardStarted"
        @wizard-cancelled="jdWizardCancelledOrCompleted"
        @wizard-done="jdWizardCancelledOrCompleted"
      />
    </template>
    <template v-else-if="showingFurrowSets">
      <furrow-set
        v-for="furrowSet in selectedFurrowSets"
        :key="`sfs:${furrowSet.id}`"
        :furrowSet="furrowSet"
        :fieldLayout="selectedFieldLayout"
        :flowRate="getFlowRateForFurrowSet(furrowSet, selectedField)"
        selectionStyle="selected"
        :clickable="true"
        v-on:click="furrowSetClicked"
        :showLabel="!selectedIrrigationSystemPipePath"
      />

      <furrow-set
        v-for="furrowSet in selectableFurrowSets"
        :key="furrowSet.id"
        :fieldLayout="selectedFieldLayout"
        :furrowSet="furrowSet"
        :flowRate="getFlowRateForFurrowSet(furrowSet, selectedField)"
        selectionStyle="selectable"
        :clickable="true"
        v-on:click="furrowSetClicked"
        :showLabel="!selectedIrrigationSystemPipePath"
      />

      <pp4-label
        :labelText="selectedField.name"
        :latLng="selectedFieldBottomLabelLatLng"
        elementClass="selected_field_label"
      />
    </template>
    <template v-else>
      <pp4-field
        :field="selectedField"
        :fieldLayout="selectedFieldLayout"
        :flowRate="getFlowRateForField(selectedField)"
        selection-style="selected"
        :clickable="true"
        @click="selectField(selectedField)"
        :showFurrowDirection="false"
        :showLeveePaths="true"
        :key="selectedField.id"
        :showLabel="!selectedIrrigationSystemPipePath"
        :showLabelOnMouseover="false"
      />
    </template>
  </template>

  <furrow-set
    v-for="furrowSet in unselectableFurrowSets"
    :field="selectedField"
    :fieldLayout="selectedFieldLayout"
    :key="furrowSet.id"
    :furrowSet="furrowSet"
    selectionStyle="unselectable"
  />

  <fields-in-map-bounds :fields="unselectableFields">
    <pp4-field
      v-for="field in unselectableFields"
      :field="field"
      :fieldLayout="getActiveLayout(field)"
      :flowRate="getFlowRateForField(field)"
      :showFurrowDirection="false"
      :showLabel="false"
      selection-style="unselectable"
      :key="field.id"
    />
  </fields-in-map-bounds>
  <fields-in-map-bounds :fields="selectableFields">
    <pp4-field
      v-for="field in selectableFields"
      :field="field"
      :fieldLayout="getActiveLayout(field)"
      :flowRate="getFlowRateForField(field)"
      selection-style="selectable"
      :clickable="true"
      :showLabel="false"
      :showFurrowDirection="false"
      @click="selectField(field)"
      :key="field.id"
    />
  </fields-in-map-bounds>

  <measure-tool />

  <!-- <puncher v-if="inPuncherMode"
      :farm="selectedFarm"
      :field="selectedField"
      :fieldLayout="selectedFieldLayout"
      :furrowSet="selectedFurrowSet"
      :irrigationSystem="selectedIrrigationSystem"
      :pipePath="selectedIrrigationSystemPipePath"/> -->

  <div v-show="farmNotFound">
    Farm not found. <router-link class="button" to="/">Home</router-link>
  </div>
  
  <dropped-geo-json 
    v-if="! editingAnything"
    v-for="geo in droppedGeoJsons" :key="geo.properties.key" :geoJson="geo" />

  <elevation-point-overlay v-if="showElevationOverlay"
  :clickable="false"
  :latLngsWithElevation="elevationOverlayLatLngsWithElevation"/>

  <!-- <plotly-elevation-overlay v-if="selectedFieldLayout" :fieldLayout="selectedFieldLayout"/> -->
</template>

<script>
import Pp4MapObject from '@/components/maps/Pp4MapObject'
import Pp4Field from '@/components/maps/Pp4Field'
import FieldClusters from '@/components/maps/FieldClusters'
import DesignParams from '@/components/maps/design/DesignParams'
import FieldsInMapBounds from '@/components/maps/FieldsInMapBounds'
import PanAction from '@/components/maps/PanAction'

import FieldSettingsDialog from '@/components/maps/irrigation_systems/FieldSettingsDialog'
import HoleDesignDialog from '@/components/HoleDesignDialog'
import PipeSettingsDialog from '@/components/maps/irrigation_systems/PipeSettingsDialog'

import Pp4Label from '@/components/maps/Label'
import MapControls from '@/components/maps/MapControls'
import MainNavMenu from '@/components/MainNavMenu'
import FieldMenus from '@/components/field_menus/FieldMenus'
import HelpBubble from '@/components/HelpBubble'

import AddFieldWizard from '@/components/maps/add_field/AddFieldWizard'
import EditFieldBounds from '@/components/maps/edit_field_bounds/EditFieldBounds'
import EditFurrowSets from '@/components/maps/edit_furrow_sets/EditFurrowSets'
import EditFurrowDirection from '@/components/maps/edit_furrow_direction/EditFurrowDirection'
import JdEditFurrowDirection from '@/components/jd/JdEditFurrowDirection'
// import EditFieldElevation from "@/components/maps/edit_field_elevation/EditFieldElevation";
import EditLevees from '@/components/maps/edit_levees/EditLevees'
import AddIrrigationSystem from '@/components/maps/irrigation_systems/AddIrrigationSystem'
import MoveIrrigationSystem from '@/components/maps/irrigation_systems/MoveIrrigationSystem'
import AddPipePath from '@/components/maps/irrigation_systems/AddPipePath'
import EditPipePath from '@/components/maps/irrigation_systems/EditPipePath'

import IrrigationSystems from '@/components/maps/irrigation_systems/IrrigationSystems'
import DebugObjects from '@/components/maps/debug_objects/DebugObjects'
import FurrowSet from '@/components/maps/FurrowSet'
import MeasureTool from '@/components/maps/irrigation_systems/MeasureTool'
import DroppedGeoJson from '@/components/maps/DroppedGeoJson'
//import Puncher from '@/components/maps/Puncher'
import { Pp4Uuid, LatLng, GeometryEditMode } from '@/store/Dto'
import { GeoUtil } from '@/geo/GeoUtil'
import { IsPp3ElevationCsv, Pp3ElevationCsvParser } from '@/drag_and_drop/Pp3ElevationCsvParser'
import * as shapefile from 'shapefile'

// import PlotlyElevationOverlay from '@/components/maps/edit_field_elevation/PlotlyElevationOverlay'

export default {
  extends: Pp4MapObject,
  components: {
    // PlotlyElevationOverlay,
    Pp4Field,
    PanAction,
    Pp4Label,
    FieldClusters,
    AddFieldWizard,
    EditFieldBounds,
    FieldsInMapBounds,
    EditFurrowSets,
    EditFurrowDirection,
    JdEditFurrowDirection,
    // EditFieldElevation,
    IrrigationSystems,
    AddIrrigationSystem,
    MoveIrrigationSystem,
    MapControls,
    DebugObjects,
    FurrowSet,
    EditLevees,
    DesignParams,
    AddPipePath,
    EditPipePath,
    MeasureTool,
    DroppedGeoJson,
    MainNavMenu,
    FieldMenus,
    HelpBubble
    //Puncher
  },
  inject: ['provider', 'droppedFiles'],
  data: function () {
    return {
      inJdFieldSetupMode: false,
      farmNotFound: false,
      panToTrigger: 0,
      field: null,
      droppedGeoJsons: [],
      clickedGeoJson: null
    }
  },
  props: {
    farmId: {
      required: false,
      default: null
    },
    fieldId: {
      required: false,
      default: null
    }
  },
  computed: {
    navLocation: (vm) => vm.$nav.location,
    // inPuncherMode: (vm) => vm.$store.state.mapObjects.inPuncherMode,
    selectedFarm: (vm) => vm.$store.state.selectedFarm,
    selectedField: (vm) => {
      return vm.$store.state.selectedField
    },
    // inJdFieldSetupMode: vm => {
    //   return vm.selectedField?.jd && vm.selectedField?.layouts.some(l => l.needsWizard)
    // },
    showMainNavMenu: (vm) => {
      if(vm.inJdFieldSetupMode) {
        return false
      }

      return vm.selectedFarm ? true : false
    },
    primaryIrrigationSystemForSelectedField: (vm) => {
      const field = vm.selectedField
      if (!field) {
        return null
      }

      const irrigationSystems = vm.selectedFarm.irrigationSystems

      for (let irrigationSystem of irrigationSystems) {
        if (
          irrigationSystem.flowRates.some((flowRate) => {
            return flowRate.id === field.primaryFlowRateId
          })
        ) {
          return irrigationSystem
        }
      }

      return null
    },
    selectedFieldLayout: (vm) => {
      return vm.$store.state.selectedFieldLayout
    },
    selectedFieldNeedsJdWizard: (vm) => vm.$store.state.selectedFieldLayout?.needsWizard === true,
    showFieldMenus: (vm) => {
      return (
        !(vm.$store.state.mapObjects.editingField || vm.$store.state.mapObjects.editingFarm) &&
        vm.$store.state.selectedField != null &&
        vm.$store.state.selectedIrrigationSystem == null &&
        vm.$store.state.selectedIrrigationSystemPipePath == null &&
        !vm.selectedFieldNeedsJdWizard &&
        !vm.inJdFieldSetupMode
      )
    },
    showSelectedField: (vm) => {
      const ret = vm.notEditingFarmOrFieldOrPipePath && vm.$store.state.selectedField != null

      return ret
    },
    notEditingFarmOrFieldOrPipePath: (vm) => {
      return (
        !vm.$store.state.mapObjects.editingFarm &&
        !vm.$store.state.mapObjects.editingField &&
        !vm.$store.state.mapObjects.editingPipePath
      )
    },
    editingAnything: (vm) => {
      return (
        vm.$store.state.mapObjects.editingFarm ||
        vm.$store.state.mapObjects.editingField ||
        vm.$store.state.mapObjects.editingPipePath
      )
    },
    selectedFields: (vm) => vm.$store.state.mapObjects.selectedFields,
    selectableFields: (vm) => {
      if (vm.inJdFieldSetupMode) {
        return []
      }

      return vm.$store.state.mapObjects.selectableFields
    },
    unselectableFields: (vm) => {
      if (vm.inJdFieldSetupMode) {
        return vm.$store.state.mapObjects.selectableFields
      }

      return vm.$store.state.mapObjects.unselectableFields
    },
    selectedFurrowSet: (vm) => vm.$store.state.selectedFurrowSet,
    selectedIrrigationSystem: (vm) => vm.$store.state.selectedIrrigationSystem,
    selectedIrrigationSystemPipePath: (vm) => vm.$store.state.selectedIrrigationSystemPipePath,
    selectableIrrigationSystems: (vm) => vm.$store.state.mapObjects.selectableIrrigationSystems,
    showingFurrowSets: (vm) => {
      return (
        vm.$store.state.mapObjects.editingField == false &&
        vm.$store.state.mapObjects.selectedFurrowSets.length > 0
      )
    },
    selectedFurrowSets: (vm) => {
      const ret = vm.$store.state.mapObjects.selectedFurrowSets
      
      return ret
    },
    selectableFurrowSets: (vm) => {
      const ret = vm.$store.state.mapObjects.selectableFurrowSets
      return ret
    },
    unselectableFurrowSets: (vm) => vm.$store.state.mapObjects.unselectableFurrowSets,
    showAddField: (vm) => vm.$store.state.mapObjects.availableMapActions.AddFieldBounds,
    showAddFieldLayout: (vm) => vm.$store.state.mapObjects.availableMapActions.AddFieldLayout,
    showEditFieldBounds: (vm) =>
      vm.$store.state.mapObjects.availableMapActions.EditFieldBoundsMode != GeometryEditMode.None &&
      !vm.showAddField &&
      !vm.showAddFieldLayout,
    showEditFieldElevation: (vm) =>
      vm.$store.state.mapObjects.availableMapActions.SaveFieldElevation,
    showEditFurrowSets: (vm) => vm.$store.state.mapObjects.availableMapActions.SaveFurrowSets,
    showEditFurrowDirection: (vm) =>
      vm.$store.state.mapObjects.availableMapActions.SaveFurrowDirection,
    showAddWaterSource: (vm) => vm.$store.state.mapObjects.availableMapActions.AddIrrigationSystem,
    showMoveIrrigationSystem: (vm) =>
      vm.$store.state.mapObjects.availableMapActions.MoveIrrigationSystem,
    showAddPipePath: (vm) => vm.$store.state.mapObjects.availableMapActions.AddPipePath,
    showEditPipePath: (vm) =>
      vm.$store.state.mapObjects.availableMapActions.EditPipePathMode != GeometryEditMode.None,
    pipePathEditMode: (vm) => vm.$store.state.mapObjects.availableMapActions.EditPipePathMode,
    showAddLeveePaths: (vm) => vm.$store.state.mapObjects.availableMapActions.AddLeveePath,
    showRemoveLeveePaths: (vm) => vm.$store.state.mapObjects.availableMapActions.RemoveLeveePaths,
    showEditLeveePathsPoints: (vm) =>
      vm.$store.state.mapObjects.availableMapActions.SaveLeveePathsPoints,
    showEditLevees: (vm) => vm.$store.state.mapObjects.availableMapActions.SaveLevees,
    selectedFieldBottomLabelLatLng: (vm) => {
      if (!vm.selectedField) {
        return null
      }

      const fieldGeo = GeoUtil.LatLngs.toPolygon(vm.selectedFieldLayout.path)
      const bbox = GeoUtil.GeoJson.bbox(fieldGeo)
      const sw = [bbox[0], bbox[1]]
      // const ne = [bbox[2], bbox[3]]
      const center = GeoUtil.GeoJson.centerOfMass(fieldGeo)
      const OneMeterInLatLng = 0.00000898311

      const lat = sw[1] - OneMeterInLatLng * 25
      const lng = GeoUtil.GeoJson.getCoord(center)[0]

      return new LatLng(lat, lng)
    },
    elevationOverlayLatLngsWithElevation: (vm) => {
      return vm.$store.state.mapObjects.debug.latLngsWithElevation
    },
    showElevationOverlay: (vm) => {
      if (!vm.elevationOverlayLatLngsWithElevation?.length) {
        return false
      }

      if(vm.$store.state.mapObjects.availableMapActions.AddPipePath) {
        return false
      }

      return (
        vm.$store.state.mapObjects.availableMapActions.EditPipePathMode === GeometryEditMode.None
      )
    }
  },
  watch: {
    fieldId() {
      if (this.$store.state.selectedField && this.fieldId === this.$store.state.selectedField.id) {
        return
      }

      const field = this.$store.state.selectedFarm.fields.find((f) => f.id === this.fieldId)
      if (!field) {
        return
      }

      this.$store.dispatch('setSelectedField', field)
    },
    selectedIrrigationSystemPipePath: 'updateSelectedFieldHelpKey', // TODO: let's refactor out a <field-selected> mode of some kind
    editingAnything() {
      // TODO: let's refactor out a <field-selected> mode of some kind
      this.updateSelectedFieldHelpKey()
    },
    selectedFurrowSet() {
      this.updateSelectedFieldHelpKey()
    },
    selectedField() {
      this.updateSelectedFieldHelpKey() // TODO: let's refactor out a <field-selected> mode of some kind

      const farm = this.$store.state.selectedFarm
      if (!farm) {
        return
      }

      const field = this.$store.state.selectedField

      const farmId = farm.id
      const fieldId = field ? field.id : null

      if (farmId === this.farmId && fieldId === this.fieldId) {
        return
      }

      this.$router.push({ name: 'FarmMapView', params: { farmId, fieldId } })

      this.jdWizardCancelledOrCompleted()
    },
    selectedFieldNeedsJdWizard(newVal) {
      if (!newVal) {
        this.jdWizardCancelledOrCompleted()
      }
    },
    droppedFiles: 'filesDropped'
  },
  methods: {
    selectedFieldLayoutChanged(newFieldLayout) {
      if(! this.selectedFieldLayout) {
        return
      }

      const patches = []

      const keys = Object.keys(newFieldLayout)

      for (let key of keys) {
        if (JSON.stringify(this.selectedFieldLayout[key]) === JSON.stringify(newFieldLayout[key])) {
          continue
        }

        patches.push({
          op: Object.hasOwn(this.selectedFieldLayout, key) ? 'replace' : 'add', // my kingdom for upsert...
          path: '/' + key,
          value: newFieldLayout[key]
        })
      }


      this.$store.dispatch('patchSelectedFieldLayout', patches)
    },
    returnToSelectedFieldOrFurrowSet() {
      this.$store.dispatch('returnToSelectedFieldOrFurrowSet')
    },
    jdWizardStarted() {
      this.inJdFieldSetupMode = true
    },
    jdWizardCancelledOrCompleted() {
      this.inJdFieldSetupMode = false
    },
    startingGeoJsonUsedForAddField(startingGeoJson) {
      this.droppedGeoJsons = this.droppedGeoJsons.filter((geo) => geo !== startingGeoJson)
    },
    updateSelectedIrrigationSystemPipePathHelpKey() {
      const pp = this.selectedIrrigationSystemPipePath

      if (!pp) {
        return
      }

      let params = { field: this.selectedField, fieldLayout: this.selectedFieldLayout }

      if (pp?.designParams?.holeDesign?.error) {
        this.$store.dispatch(
          'mapObjects/setHelpKey',
          'invalid_hole_design_exists_and_pipe_path_selected'
        )
      } else if (pp?.designParams?.holeDesign) {
        params.showPipeSettingsCallback = () => {
          this.showPipeSettings()
        }
        params.showFieldSettingsCallback = async () => {
          const field = this.$store.state.selectedField
          if (!field) {
            return
          }

          await this.$store.dispatch('setSelectedField', field)
          this.showFieldSettingsDialog()
        }
        params.showHoleDesignCallback = () => {
          this.showHoleDesignDialog()
        }
        this.$store.dispatch('mapObjects/setHelpKeyAndParams', {
          key: 'valid_hole_design_exists_and_pipe_path_selected',
          params
        })
      }
    },
    updateSelectedFieldHelpKey() {
      // TODO: let's refactor out a <field-selected> mode of some kind
      if (this.editingAnything) {
        return
      }

      if (this.selectedIrrigationSystemPipePath != null) {
        this.updateSelectedIrrigationSystemPipePathHelpKey()
        return
      }

      if (this.selectedIrrigationSystem != null) {
        return
      }

      if (this.inJdFieldSetupMode) {
        return
      }

      if (this.selectedFieldNeedsJdWizard) {
        return
      }

      const farm = this.selectedFarm
      if (!farm) {
        return
      }

      const field = this.selectedField
      if (!field) {
        return
      }

      const furrowSet = this.selectedFurrowSet

      let pipeIsOnSelectedObject = false
      let pipeOnSelectedObjectHasHoleDesign = false
      let pipeOnSelectedObjectIsValid = false

      for (let irrigationSystem of farm.irrigationSystems) {
        for (let pipePath of irrigationSystem.pipePaths) {
          if (pipePath.fieldId !== field.id) {
            continue
          }

          if (furrowSet && furrowSet.id !== pipePath.furrowSetId) {
            continue
          }

          pipeIsOnSelectedObject = true

          pipeOnSelectedObjectHasHoleDesign =
            pipePath.designParams != null && pipePath.designParams.holeDesign != null

          if (pipeOnSelectedObjectHasHoleDesign) {
            pipeOnSelectedObjectIsValid = pipePath.designParams.holeDesign.error == null
          }

          break
        }
      }

      const params = {}
      const flowRateMissing = !('primaryFlowRateId' in field) || field.primaryFlowRateId == null
      params.showHoleDesignCallback = () => {
        this.showHoleDesignDialog()
      }
      params.editFieldSettingsCallback = () => {
        this.showFieldSettingsDialog()
      }
      params.enterAddPipeModeCallback = () => {
        this.enterAddPipeMode()
      }
      if (flowRateMissing) {
        params.flowRateMissing = true
      }

      if (!pipeIsOnSelectedObject) {
        this.$store.dispatch('mapObjects/setHelpKeyAndParams', { key: 'add_pipe', params })
        return
      }

      if (pipeOnSelectedObjectIsValid) {
        this.$store.dispatch('mapObjects/setHelpKeyAndParams', {
          key: 'valid_hole_design_and_field_selected',
          params
        })
      } else if (pipeOnSelectedObjectHasHoleDesign) {
        this.$store.dispatch('mapObjects/setHelpKeyAndParams', {
          key: 'invalid_hole_design_and_field_selected',
          params
        })
      } else {
        this.$store.dispatch('mapObjects/setHelpKeyAndParams', {
          key: 'processing_hole_design_and_field_selected',
          params
        })
      }
    },
    async loadFarm() {
      if (!this.farmId) {
        return
      }

      try {
        const farm = await this.$store.getters.getFarm(this.farmId)

        if (farm == null) {
          this.farmNotFound = true
          return
        }

        this.provider.googleMapUtils.showMap()

        this.farmNotFound = false

        if (this.fieldId) {
          const field = farm.fields.find((field) => field.id === this.fieldId)
          this.$store.dispatch('setSelectedFarmAndField', { farm, field })
        } else {
          this.$store.dispatch('setSelectedFarm', farm)
        }
      } catch (e) {
        console.warn('Get Farm Error')
        this.farmNotFound = true
      }
    },
    async showFieldSettingsDialog() {
      const farm = this.selectedFarm
      if (!farm) {
        return
      }

      const field = this.selectedField
      if (!field) {
        return
      }

      const fieldLayout = this.selectedFieldLayout
      if (!fieldLayout) {
        return
      }

      const irrigationSystems = this.selectableIrrigationSystems

      await this.$dialog.push(FieldSettingsDialog, {
        farm,
        field,
        fieldLayout,
        irrigationSystems
      })
    },
    getActiveLayout(field) {
      return field.layouts.find((layout) => layout.id == field.activeLayoutId)
    },
    async showHoleDesignDialog() {
      let farm = this.selectedFarm
      let field = this.selectedField
      let fieldLayout = this.selectedFieldLayout
      let furrowSet = this.selectedFurrowSet

      await this.$dialog.push(HoleDesignDialog, {
        farm,
        field,
        fieldLayout,
        furrowSet
      })
    },
    async showPipeSettings() {
      const farm = this.$store.state.selectedFarm
      const field = this.$store.state.selectedField
      const fieldLayout = this.$store.state.selectedFieldLayout
      const irrigationSystem = this.$store.state.selectedIrrigationSystem
      const pipePath = this.$store.state.selectedIrrigationSystemPipePath
      if (irrigationSystem == null || pipePath == null) {
        return
      }

      await this.$dialog.push(PipeSettingsDialog, {
        farm,
        field,
        fieldLayout,
        irrigationSystem,
        pipePath
      })
    },
    enterAddPipeMode() {
      this.$store.dispatch('enterAddPipePathMode')
    },
    async loadFarmIfNecessary() {
      const selectedFarm = this.$store.state.selectedFarm
      const selectedFarmAlreadyLoaded = selectedFarm != null && this.farmId === selectedFarm.id

      if (!selectedFarmAlreadyLoaded) {
        await this.loadFarm()
        return
      }

      const noFieldSelected = this.fieldId == null
      if (selectedFarmAlreadyLoaded && noFieldSelected) {
        this.provider.googleMapUtils.showMap()
        return
      }

      const selectedField = this.$store.state.selectedField
      const fieldAlreadySelected = selectedField != null && selectedField.id === this.fieldId
      if (fieldAlreadySelected) {
        this.provider.googleMapUtils.showMap()
        return
      }

      const field = selectedFarm.fields.find((f) => f.id === this.fieldId)
      if (field == null) {
        this.provider.googleMapUtils.showMap()
        return
      }

      this.provider.googleMapUtils.showMap()
      await this.$store.dispatch('setSelectedField', field)
    },
    selectFirstFieldIfNoneSelected() {
      if (this.editingAnything) {
        return
      }

      if (this.fieldId) {
        return
      }

      const farm = this.selectedFarm
      if (farm === null) {
        return
      }

      if (farm.fields.length === 0) {
        return
      }

      const field = farm.fields[0]

      this.$router.replace({
        name: 'FarmMapView',
        params: { farmId: this.farmId, fieldId: field.id }
      })
    },
    async selectField(field) {
      const editingPipePath = this.$store.state.mapObjects.editingPipePath
      if (editingPipePath) {
        return
      }

      this.$store.dispatch('setSelectedField', field)
    },
    furrowSetClicked(furrowSet) {
      this.$store.dispatch('setSelectedFurrowSet', furrowSet)
    },
    getFlowRateForId(id) {
      if (!this.selectedFarm) {
        return null
      }

      const irrigationSystems = this.selectedFarm.irrigationSystems
      if (!irrigationSystems) {
        return null
      }

      for (let irrigationSystem of irrigationSystems) {
        const flowRates = irrigationSystem.flowRates
        if (!flowRates) {
          continue
        }

        for (let flowRate of flowRates) {
          if (flowRate.id === id) {
            return flowRate
          }
        }
      }

      return null
    },
    getFlowRateForField(field) {
      if (!this.selectedFarm) {
        return null
      }

      const irrigationSystems = this.selectedFarm.irrigationSystems

      for (let irrigationSystem of irrigationSystems) {
        const pipePaths = irrigationSystem.pipePaths
        for (let pipePath of pipePaths) {
          if ('furrowSetId' in pipePath) {
            continue
          }

          if (field.id === pipePath.fieldId) {
            const flowRateId = pipePath.flowRateId
            return this.getFlowRateForId(flowRateId)
          }
        }
      }

      return this.getFlowRateForId(field.primaryFlowRateId)
    },
    getFlowRateForFurrowSet(furrowSet, field) {
      if (!furrowSet) {
        return null
      }

      if (!this.selectedFarm) {
        return null
      }

      const irrigationSystems = this.selectedFarm.irrigationSystems

      for (let irrigationSystem of irrigationSystems) {
        const pipePaths = irrigationSystem.pipePaths
        for (let pipePath of pipePaths) {
          if (pipePath.furrowSetId === furrowSet.id) {
            const flowRateId = pipePath.flowRateId
            return this.getFlowRateForId(flowRateId)
          }
        }
      }

      return this.getFlowRateForId(field.primaryFlowRateId)
    },
    async filesDropped(files) {
      if (this.showAddField) {
        return
      }
      
      this.droppedGeoJsons = []

      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const isElevationCsv = await IsPp3ElevationCsv(file)
        if (isElevationCsv) {
          const results = await Pp3ElevationCsvParser(file)
          
          if (results.length > 1) {
            this.$store.dispatch('mapObjects/debug/setElevationOverlay', results)

            const ls = GeoUtil.LatLngs.toLineString(results)
            const bbox = GeoUtil.GeoJson.bbox(ls)

            if (this.provider.zoomLevel > 15) {
              this.centerOnBbox(bbox)
            } else {
              this.fitBbox(bbox)
            }
          }
        } else {
          try {
            const arrayBuffer = await file.arrayBuffer()
            const geo = await shapefile.read(arrayBuffer)

            if (geo?.features?.length) {
              const flattened = GeoUtil.GeoJson.flatten(geo)

              const allPolys = flattened.features.filter((f) => f.geometry?.type === 'Polygon')
              const simplifiedPolys = allPolys.reduce((accum, poly) => {
                const coordinates = poly.geometry.coordinates
                if (!coordinates?.length) {
                  return accum
                }

                coordinates.forEach((coords) => {
                  const childPoly = { ...poly }
                  childPoly.geometry = { ...poly.geometry, coordinates: [coords] }
                  childPoly.properties = { ...poly.properties }
                  childPoly.properties.key = Pp4Uuid()

                  if(GeoUtil.GeoJson.areaInAcres(childPoly) > 5) {
                    accum.push(childPoly)
                  }
                })

                return accum
              }, [])

              if (simplifiedPolys.length) {
                this.droppedGeoJsons.push(...simplifiedPolys)
              }

              const points = flattened.features.filter((f) => f.geometry?.type === 'Point')
              if (points?.length) {
                this.droppedGeoJsons.push(GeoUtil.GeoJson.featureCollection(points))
              }

              const lines = flattened.features.filter((f) => f.geometry?.type === 'LineString')
              if (lines?.length) {
                this.droppedGeoJsons.push(GeoUtil.GeoJson.featureCollection(lines))
              }
            }
          } catch (e) {
            console.error(e)
          }
        }
      }

      //center on it
      if (this.droppedGeoJsons.length) {
        const fc = GeoUtil.GeoJson.featureCollection([])

        this.droppedGeoJsons.forEach((geo) => {
          if (geo?.features?.length) {
            fc.features.push(...geo.features)
          } else if (geo.geometry) {
            fc.features.push(geo)
          }
        })

        const bbox = GeoUtil.GeoJson.bbox(fc)

        this.fitBbox(bbox)
      }
    }
  },
  async mounted() {
    await this.loadFarmIfNecessary()

    this.selectFirstFieldIfNoneSelected()
  }
}
</script>

<style lang="css">
@media only screen and (max-width: 500px) {
  #delta_logo {
    display: none;
  }
}
</style>
<template>
  <fullpageform
    title="Remove Field?"
    submitLabel="Remove Field"
    cancelLabel="Cancel"
    @cancel="cancel"
    @submit="submit"
  >
    
  </fullpageform>
  <loading v-if="loading"/>
</template>

<script>
export default {
  components: {},
  data: function () {
    return {
      loading: false
    }
  },
  methods: {
    submit: async function () {
      try {
        this.loading = true
        await this.$store.dispatch('removeSelectedField')

      }
      finally {
        this.loading = false
      }

      this.$dialog.pop()
    },
    cancel: function () {
      this.$dialog.pop()
    }
  },
  computed: {},
  mounted: function () {}
}
</script>

<style scoped></style>

<template>
    <template>
        <poly ref="thePoly" :path="fieldLayout.path" :label="computedLabel" v-bind="selectionStyleProps"
            :clickable="clickable" :showLabel="finalShowLabel" @click="emitClick" 
            @mousemove="mousemove" @mouseout="mouseout"
            @mouseover="mouseover" 
            @poly_changed="polyChanged" />
        <furrow-direction v-if="showFurrowDirection && (fieldLayout.mode == FieldMode.Furrows)" :fieldLayout="fieldLayout"
            :selectionStyle="selectionStyle" />
        <template v-if="showLeveePaths">
            <pp4-polyline v-for="leveePath in leveePaths" :path="leveePath.path" :key="leveePath.key"
                :zIndex="ZIndexes.LeveePaths" v-bind="selectionStyleProps" />
        </template>
    </template>
</template>

<script setup>
import FurrowDirection from '@/components/maps/FurrowDirection'
import Pp4Polyline from '@/components/maps/Polyline'
import Poly from '@/components/maps/Poly'
import Vars from '@/Vars'
import { ZIndexes } from '@/maps/ZIndexes'
import * as WateringTimeAlg from '@/util/WateringTimeAlg'
import { FieldMode } from '@/store/Dto'
import { GeoUtil } from '@/geo/GeoUtil'

import { computed, reactive } from 'vue'

const props = defineProps({
    fieldLayout: {
        type: Object,
        required: true
    },
    field: {
        type: Object,
        required: false
    },
    clickable: {
        type: Boolean,
        required: false,
        default: false
    },
    showFurrowDirection: {
        type: Boolean,
        required: false,
        default: true
    },
    showLeveePaths: {
        type: Boolean,
        required: false,
        default: false
    },
    showLabel: {
        type: Boolean,
        required: false,
        default: true
    },
    showLabelOnMouseover: {
        type: Boolean,
        required: false,
        default: true
    },
    flowRate: {
        type: Object,
        required: false,
        default: null
    },
    selectionStyle: {
        type: String,
        required: true,
        validator: function (value) { return value == "selected" || value == "selectable" || value == "unselectable" }
    },

    //events
    onMousemove: {
        required: false
    },
    onMouseover: {
        required: false
    },
    onMouseout: {
        required: false
    }
})

const data = reactive({
    isMouseover: false
})

const finalShowLabel = computed(() => {
    if(props.showLabelOnMouseover && data.isMouseover) {
        return true
    }

    return props.showLabel
})

function mouseover(e) {
    props.onMouseover && props.onMouseover(e)
    
    if(! props.showLabelOnMouseover) {
        return
    }

    data.isMouseover = true
}

function mouseout(e) {
    props.onMouseout && props.onMouseout(e)

    if(! props.showLabelOnMouseover) {
        return
    }
    
    data.isMouseover = false
}

function mousemove(e) {
    props.onMousemove && props.onMousemove(e)
}

const emit = defineEmits(['click', 'mousemove', 'mouseover', 'mouseout', 'poly_changed'])

const selectionStyleProps = computed(() => {
    switch (props.selectionStyle) {
        case 'selected':
            return {
                strokeOpacity: 1.0,
                strokeColor: Vars.SelectedFieldStrokeColor,
                strokeWeight: Vars.SelectedFieldStrokeWeight,
                fillColor: Vars.SelectedFieldFillColor,
                fillOpacity: Vars.SelectedFieldFillOpacity,
                zIndex: ZIndexes.Fields + 2,
                labelClass: 'selected_field_label'
            }
        case 'selectable':
            return {
                strokeOpacity: 1.0,
                strokeColor: Vars.SelectableFieldStrokeColor,
                strokeWeight: Vars.SelectableFieldStrokeWeight,
                fillColor: Vars.SelectableFieldFillColor,
                fillOpacity: Vars.SelectableFieldFillOpacity,
                zIndex: ZIndexes.Fields + 1,
                labelClass: 'selectable_field_label'
            }
        case 'unselectable':
            return {
                strokeOpacity: 1.0,
                strokeColor: Vars.UnselectableFieldStrokeColor,
                strokeWeight: Vars.UnselectableFieldStrokeWeight,
                fillColor: Vars.UnselectableFieldFillColor,
                fillOpacity: Vars.UnselectableFieldFillOpacity,
                zIndex: ZIndexes.Fields,
                labelClass: 'unselectable_field_label'
            }
    }

    return {}
})


const fieldGeo = computed(() => GeoUtil.LatLngs.toPolygon(props.fieldLayout.path))

const fieldAreaInAcres = computed(() => {
    const squareMeters = GeoUtil.GeoJson.area(fieldGeo.value)
    return squareMeters * 0.0002471052
})

const computedLabel = computed(() => {
    let ret = props.field.name + '<br>(' + Math.round(fieldAreaInAcres.value) + ' ac.)'

    if (props.flowRate) {
        ret += '<br>' + WateringTimeAlg.formattedWateringTimeAndFlowRate(fieldAreaInAcres.value, props.flowRate, props.fieldLayout)
    }

    return ret
})

const leveePaths = computed(() => {
    if (props.fieldLayout.mode !== FieldMode.Levees) {
        return []
    }

    return props.fieldLayout.leveePaths.map((path, index) => {
        return {
            path, key: (props.fieldLayout.id + ':leveePath:' + index)
        }
    })
})


function emitClick(e) {
    emit('click', e)
}

function polyChanged(latLngs) {
    emit('poly_changed', latLngs)
}
</script>
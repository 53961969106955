<template></template>

<script setup>
import { inject, reactive, onUnmounted } from 'vue'

const props = defineProps({
    points: {
        type: Array,
        required: true
    }
})

const data = reactive({
    remainingPoints: props.points.slice()
})

const emit = defineEmits(['remove'])

function removePoint(latLng) {
    const index = data.remainingPoints.indexOf(latLng)

    const newVal = data.remainingPoints.slice()

    newVal.splice(index, 1)

    data.remainingPoints = newVal

    emit('remove', data.remainingPoints)
}

const { interpretationFactory } = inject('provider')

const markers = props.points.map(ll => {
    const ret = interpretationFactory.buildRemoveMarker(ll)

    ret.addListener('click', () => {
        ret.setMap(null)
        removePoint(ll)
    })

    return ret
})

onUnmounted(() => {
    markers.forEach(m => {
        m.setMap(null)
    })
})
</script>
<template>
    <div class="top_menu">

        <div id="main_nav_menu" v-show="topDivShown">
            <icon-button description="Home" label="Home" class="Home" image="/home-icon.png" v-show="homeAvailable"
                v-on:click="home()" />

            <icon-button description="Add Field" label="Add Field" faIcon="fa-plus" class="button_margin_left"
                v-if="canAddField"
                v-show="addFieldAvailable" v-on:click="enterAddFieldMode()" />

            <icon-button :class="homeAvailable || addFieldAvailable ? 'button_group_margin_left' : ''" label="Hole Designs"
                title="View hole designs for field" faIcon="fa-book" v-show="holeDesignsAndIrrigationSystemsActive"
                :disabled="!holeDesignsAndIrrigationEventsAvailable" @click="showHoleDesigns()" />

            <icon-button class="button_margin_left" label="Irrigation" title="View irrigation events for field"
                faIcon="fa-tint" v-show="holeDesignsAndIrrigationSystemsActive"
                :disabled="!holeDesignsAndIrrigationEventsAvailable" @click="showIrrigationDialog()" />

            <icon-button :class="homeAvailable || holeDesignsAndIrrigationSystemsActive ? 'button_group_margin_left' : ''"
                description="Center" label="Center Location" faIcon="fa-bullseye" v-show="centerAvailable"
                v-on:click="center()" />

            <map-search v-show="searchLocationAvailable" class="button_margin_left" />

            <field-nav-menu class="button_group_margin_left" />
        </div>

        <div class="puppetUser" v-if="puppetUser != null">
            <label>Logged in as:</label>
            <label>{{ puppetUser.email }}</label>
        </div>
    </div>
</template>

<script>
import FieldNavMenu from '@/components/FieldNavMenu'
import { LatLng } from '@/store/Dto'
import MapSearch from '@/components/MapSearch'
import HoleDesignDialog from '@/components/HoleDesignDialog'
import WateringDialog from './WateringDialog.vue'

export default {
    components: {
        FieldNavMenu,
        MapSearch
    },
    data: function () {
        return {
        }
    },
    methods: {
        home() {
            const potentialJdBack = this.$router.options?.history?.state?.back || ""
            if(potentialJdBack.startsWith("/JohnDeere")) {
                this.$router.back()
                return
            }
            
            const selectedFarm = this.$store.state.selectedFarm

            if (selectedFarm?.jd) {
                this.$router.push({
                    name: 'JohnDeere',
                    params: { selectedOrganizationId: selectedFarm.jd.organizationId }
                })

                return
            }

            this.$router.push({
                name: 'SelectFarm',
                params: { farmId: selectedFarm ? selectedFarm.id : null }
            })
        },
        center() {
            if (!navigator.geolocation) {
                return
            }

            navigator.geolocation.getCurrentPosition((position) => {
                const latLng = new LatLng(
                    position.coords.latitude,
                    position.coords.longitude,
                )

                this.$store.dispatch('mapObjects/setRequestedPanToLatLng', latLng)
            }, (error) => {
                console.warn(error.message)
            })
        },
        enterAddFieldMode() {
            this.$store.dispatch('enterAddFieldMode')
        },
        enterAddIrrigationSystemMode() {
            this.$store.dispatch('enterAddIrrigationSystemMode')
        },
        async showHoleDesigns() {
            let farm = this.$store.state.selectedFarm
            let field = this.$store.state.selectedField
            let furrowSet = this.$store.state.selectedFurrowSet

            await this.$dialog.push(HoleDesignDialog, {
                farm,
                field,
                furrowSet
            })
        },
        async showIrrigationDialog() {
            const selectedFarm = this.$store.state.selectedFarm
            const selectedField = this.$store.state.selectedField
            const selectedFieldLayout = this.$store.state.selectedFieldLayout

            await this.$dialog.push(WateringDialog, {
                farm: selectedFarm,
                field: selectedField,
                fieldLayout: selectedFieldLayout
            })
        }
    },
    computed: {
        canAddField: vm => {
            return vm.$store.state.selectedFarm?.jd === true ? false : true
        },
        topDivShown: (vm) => {
            return vm.homeAvailable || vm.addFieldAvailable ||
            vm.holeDesignsAndIrrigationEventsAvailable
            || vm.centerAvailable || vm.searchLocationAvailable
            || vm.editFarmAvailable
        },
        centerAvailable: (vm) => vm.$store.state.mapObjects.availableMapActions.Center,
        homeAvailable: (vm) => vm.$store.state.mapObjects.availableMapActions.Home,
        searchLocationAvailable: (vm) => vm.$store.state.mapObjects.availableMapActions.SearchLocation,
        addFieldAvailable: (vm) => vm.$store.state.mapObjects.availableMapActions.EnterAddFieldMode,
        addWaterSourceAvailable: (vm) => vm.$store.state.mapObjects.availableMapActions.EnterAddIrrigationSystemMode,
        editFarmAvailable: (vm) => vm.$store.state.mapObjects.availableMapActions.EditFarm,
        puppetUser: (vm) => vm.$store.state.puppetUser,
        holeDesignsAndIrrigationSystemsActive: (vm) => {
            return vm.$store.state.selectedField
        },
        holeDesignsAndIrrigationEventsAvailable: (vm) => {
            const selectedFarm = vm.$store.state.selectedFarm
            if (! selectedFarm) {
                return false
            }

            if (!selectedFarm.irrigationSystems) {
                return false
            }

            const selectedField = vm.$store.state.selectedField
            if (! selectedField) {
                return false
            }

            const hasHoleDesignForSelectedField = (irrigationSystem) => {
                return irrigationSystem.pipePaths.some((pipePath) => {
                    return pipePath.designParams
                        && pipePath.designParams.holeDesign
                        && pipePath.fieldId === selectedField.id
                })
            }

            return vm.$store.state.mapObjects.availableMapActions.Home
                && selectedFarm.fields.length > 0
                && selectedFarm.irrigationSystems.some(hasHoleDesignForSelectedField)
        }
    }
}
</script>

<style lang="css" scoped>
#main_nav_menu {
    padding-top: calc(var(--menu-border-size) / 2);
    padding-left: var(--menu-border-size);
    padding-right: var(--menu-border-size);
    padding-bottom: var(--menu-border-size);
    display: inline-flex;
    flex-direction: row;
    overflow: auto;
    align-items: flex-start;
    max-width: 90vw;
}

.delta_plastics_logo {
    align-self: center;
    max-width: 50px !important;
    max-height: 50px !important;
}

@media only screen and (max-width: 380px) {
    .button_group_margin_left {
        margin-left: 5px !important;
    }

    .button_margin_left {
        margin-left: 5px !important;
    }
}

.puppetUser {
    color: var(--secondary-light);
    margin-right: 1em;
    text-align: center;
    font-size: small;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    padding-left: var(--menu-border-size);
    padding-right: var(--menu-border-size);
    padding-bottom: var(--menu-border-size);
}

.top_menu {
    display: flex;
    flex-direction: column;
    background: var(--menu-background-color);
    border-bottom-left-radius: var(--menu-border-radius);
    border-bottom-right-radius: var(--menu-border-radius);
}
</style>

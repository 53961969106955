<template>
    <template v-for="o in roundedPoints" :key="o.key">
        <point-marker v-if="o.interpolated" :location="o.latLng" @click="editLatLngs(o)" :isLastPoint="false" 
            :draggable="false"/>

        <map-element v-else :latLng="o.latLng" layer="floatPane">
            <elevation-field :placeholder="o.inConflict ? o.avgElevationInFeet : o.elevationInFeet"
                :requestFocus="o.requestFocus" :interpolated="o.interpolated" :inConflict="o.inConflict"
                @change="updateLatLngs(o, $event)" />
        </map-element>
    </template>
</template>

<script setup>
import { computed, ref } from 'vue';

import PipePathAlg from '@/design/PipePathAlg'

const model = defineModel()

const focusKey = ref(null)
 
function buildKey(ll) {
    const lat = Number.parseFloat(ll.lat.toFixed(4))
    const lng = Number.parseFloat(ll.lng.toFixed(4))
    return `${lat},${lng}`
}

const roundedPoints = computed(() => {
    const byKey = {}
    const ret = []

    PipePathAlg.traversePath(model.value, (ll) => {
        const key = buildKey(ll)

        if (key in byKey) {
            byKey[key].latLngs.push(ll)
            return
        }

        byKey[key] = {
            elevationInFeet: ll.elevationInFeet, interpolated: ll.interpolated,
            key, latLng: { lat: ll.lat, lng: ll.lng},
            latLngs: [ll],
            requestFocus: key === focusKey.value
        }

        ret.push(byKey[key])
    })

    ret.forEach(o => {
        const elevationInFeet = o.latLngs[0].elevationInFeet
        const interpolated = o.latLngs[0].interpolated === true ? true : false
        const inConflict = !o.latLngs.every(o => {
            return o.elevationInFeet === elevationInFeet
                && o.interpolated === interpolated
        })
        const avgLat = o.latLngs.reduce((accum, ll) => accum + ll.lat, 0) / o.latLngs.length
        const avgLng = o.latLngs.reduce((accum, ll) => accum + ll.lng, 0) / o.latLngs.length
        const validElevations = o.latLngs.map(o => o.elevationInFeet).filter(Number.isFinite)
        const avgElevationInFeet = Number.parseFloat((validElevations.reduce((accum, e) => accum + e, 0) / validElevations.length).toFixed(1))

        o.latLng = { lat: avgLat, lng: avgLng }

        if (inConflict) {
            o.inConflict = true
            o.label = '!'
            o.title = 'Conflicting Elevation Values for This Location -- Tap to Re-Enter',
                o.elevationInFeet = null
            o.interpolated = true //treat as interpolated
            o.avgElevationInFeet = avgElevationInFeet
        }
        else if (Number.isFinite(elevationInFeet)) {
            o.inConflict = false
            o.elevationInFeet = elevationInFeet
            o.interpolated = interpolated
            o.label = elevationInFeet.toString()
            o.title = null
            o.avgElevationInFeet = o.elevationInFeet
        }
        else {
            o.inConflict = false
            o.elevationInFeet = null
            o.interpolated = false
            o.avgElevationInFeet = null
        }
    })

    return ret
})

function editLatLngs(o) {
    focusKey.value = buildKey(o.latLng)

    o.latLngs.forEach(ll => {
        ll.interpolated = false
    })
}

function updateLatLngs(o, newVal) {
    delete o.requestFocus

    o.latLngs.forEach(ll => {
        ll.elevationInFeet = newVal

        ll.interpolated = !Number.isFinite(newVal)
    })
    o.elevationInFeet = newVal
    o.interpolated = !Number.isFinite(newVal)

    const needs = PipePathAlg.buildInterpolationNeeds(model.value)
    if (!needs.length) {
        PipePathAlg.interpolateElevationInFeet(model.value)
    }
}
</script>

<style lang="css"></style>
<template>
    <span :title="description" :class='computedToggleButtonClasses' @click="internalClick" @pointermove.stop @mousedown.stop
        @mousemove.stop @touchmove.passive.stop>
        <slot />
        <span v-if="faIconValid" class="fa fa-2x" :class="computedFaIconClasses"></span>
        <span :class="computedToggleLabelClass" v-if="label">{{ label }}</span>
    </span>
</template>

<script>
export default {
    props: ['label', 'description', 'active', 'disabled', 'faIcon', 'faColorClass', 'big', 'googleStyle', 'jd'],
    data: function () {
        return {
            iconColorClasses: ""
        }
    },
    computed: {
        labelClass: (vm) => vm.enabled ? `${this.computedToggleLabelClass}` : `${this.computedToggleLabelClass} disabled`,
        computedToggleButtonClasses: (vm) => {
            let ret = 'toggle_button'

            if (vm.big) {
                ret = 'toggle_button_big'
            }

            if (vm.googleStyle) {
                ret = 'toggle_button_google'
            }

            ret += vm.disabled ? ' disabled' : ' enabled'

            if (vm.active) {
                ret += ' active'
            }

            return ret
        },
        computedToggleLabelClass: (vm) => {
            let ret = vm.big ? 'toggle_label_big' : 'toggle_label'

            if (vm.jd) {
                ret += ' jd'
            }

            return ret
        },
        enabled: (vm) => !vm.disabled,
        computedFaIconClasses: (vm) => {
            let ret = ""

            if (vm.faIcon) {
                ret += " " + vm.faIcon

                if (vm.faColorClass != null && vm.faColorClass.length > 0) {
                    ret += " " + vm.faColorClass
                }
            }

            return ret
        },
        faIconValid: (vm) => vm.computedFaIconClasses.length > 0
    },
    emits: ['click'],
    methods: {
        internalClick() {
            if (!this.disabled) {
                this.$emit('click')
            }
        }
    },
    created: function () {
        if (!this.title) {
            this.title = this.$data.label
        }
    }
}
</script>

<style lang="css">
.toggle_button {
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    font-size: medium;
    padding: 0px;
    cursor: pointer;
    width: 2.5em;
    overflow: hidden;
    border-style: solid;
}

.toggle_button_big {
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: var(--secondary-dark);
    font-size: medium;
    padding: 0px;
    cursor: pointer;
    width: 8em;
    height: 9em;
    max-width: 10em;
    max-height: 10em;
    margin: 0.25em;
    border-style: solid;
    border-color: black;

    overflow: hidden;
}

.toggle_button_google {
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    background-color: #FFFFFF;
    font-size: medium;
    padding: 0px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-style: none;
    border-radius: 2px;
    overflow: hidden;
}

@media screen and (min-width: 100px) {
    .toggle_button {
        font-size: small;
    }
}

@media screen and (min-width: 800px) {
    .toggle_button {
        font-size: initial;
    }
}

.toggle_button:hover {
    background-color: var(--secondary-light);
}

.toggle_button:not(.disabled):not(.active) {
    border-color: black;
}

.toggle_button:not(.disabled).active {
    border-color: yellow;
}

.toggle_button.active.disabled {
    border-color: rgb(154, 154, 0);
}

.toggle_button.disabled:not(.active) {
    border-color: black;
}

.toggle_button.disabled {
    background: grey;
}

.toggle_button_big.disabled {
    background: grey;
}

.toggle_button_big:hover {
    border-color: white;
}

.toggle_button_big.active.enabled:hover {
    border-color: yellow;
}

.toggle_button_big:not(.disabled):not(.active) {
    border-color: black;
}

.toggle_button_big:not(.disabled).active {
    border-color: yellow;
}

.toggle_button_big.active.disabled {
    border-color: rgb(154, 154, 0);
}

.toggle_button_big.disabled:not(.active) {
    border-color: black;
}

.toggle_label_big:not(.disabled).active {
    border-bottom-color: rgb(154, 154, 0);
}

.toggle_button img {
    border: 4px solid transparent;
    width: 1.75em;
    height: 1.75em;
    max-width: 10vw;
    max-height: 10vw;
    padding: 2px;
}

.toggle_button_big img {
    border: 4px solid transparent;
    width: 7em;
    height: 7em;
    max-width: 9em;
    max-height: 9em;
    padding: 2px;
}

.toggle_button_google img {
    width: 20px;
    height: 20px;
    padding: 10px;
}

.toggle_label {
    display: block;
    font-size: xx-small;
    color: white;
    padding: 0px;
    margin: 0px;
    position: relative;
    left: 0px;
    right: 0px;
    min-height: 1em;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    background-color: var(--secondary-dark);
    align-self: stretch;
    justify-self: center;
    flex-grow: 1;
}

.toggle_label_big {
    display: block;
    font-size: medium;
    color: white;
    padding: 0px;
    padding-bottom: 9px;
    margin-bottom: 5px;
    position: relative;
    left: 0px;
    right: 0px;
    min-height: 1em;
    overflow: hidden;
    text-overflow: initial;
    text-align: center;
    background-color: var(--primary);
    align-self: stretch;
    flex-grow: 1;
    justify-self: start;
}

.toggle_label.jd {
    background-color: var(--jd-green) !important;
    color: var(--jd-yellow) !important;
}

.disabled {
    color: var(--disabled);
}

.enabled {
    color: black;
}

.toggle_button.enabled:hover {
    background-color: var(--secondary-light);
}

.toggle_button_big.enabled:hover {
    border-color: white;
}

.fa {
    vertical-align: middle;
    text-align: center;
    margin: 0px;
    padding: 0.15em;
}
</style>

<template>
    <div class="elevation_field" :class="classes" :title="title" @click="inputClicked">
        <input ref="theInput" :class="classes" v-model.number="internalValue" type="text" step="0.1" autocomplete="off"
            :focus="requestFocus"
            @click="inputClicked" @focusout="submit" @keyup.escape="cancel" @keyup.enter="submit" @keydown.tab="submit">

    </div>
</template>

<script setup>
import { watch, ref, computed } from 'vue'

const props = defineProps({
    placeholder: {
        type: Number,
        required: false,
        default: null
    },
    interpolated: {
        type: Boolean,
        required: false,
        default: false
    },
    inConflict: {
        type: Boolean,
        required: false,
        default: false
    },
    requestFocus: {
        type: Boolean,
        required: false,
        default: false
    }
})

const theInput = ref(null)

const emit = defineEmits(['change'])

const internalValue = defineModel('internal', { local: true })

const classes = computed(() => {
    return `${props.interpolated ? 'interpolated' : ''}`
})

const title = computed(() => {
    if (props.inConflict) {
        return 'Multiple different elevation values entered here, tap to enter new, commmon value'
    }

    if (props.interpolated) {
        return 'Interpolated elevation'
    }
})

function focus() {
    if (!theInput.value) {
        setTimeout(() => {
            theInput?.value?.select()
            // theInput?.value?.focus()
        }, 50)
    }
    else {
        theInput.value.select()
        // theInput.value.focus()
    }
}

function inputClicked() {
    focus()
}

function cancel() {
}

function submit() {
    let value = Number.parseFloat(internalValue.value)
    if (!Number.isFinite(value)) {
        value = null
    }

    emit('change', value)
}

watch([props], () => {
    if (Number.isFinite(props.placeholder)) {
        internalValue.value = props.placeholder
    }
    else {
        internalValue.value = null
    }

    if (props.requestFocus) {
        focus()
    }
}, { immediate: true })
</script>

<style lang="css" scoped>
.elevation_field {
    display: block;
    font-size: medium;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    background: rgba(0, 0, 0, 0.85);
    border-radius: var(--softer-border-radius);
    /* padding: 0.5rem; */
    /* height: 2rem; */
    padding: 0em;
}

.elevation_field input {
    padding: 0.75rem;
    margin: 0px;
    /* min-height: 1rem; */
    min-width: 1rem;
    background: rgba(0, 0, 0, 0.0);
    color: var(--secondary-light);
    max-width: 2.5em;
    font-size: large;
    border-image: none !important;
    outline: none;
    cursor: pointer;
    transition: none !important;

    font-size: large;
    border: none;
    border-image: none !important;
    border-radius: var(--softer-border-radius);
}

.elevation_field input:focus {
    cursor: initial;
}

.elevation_field label {
    min-width: 0px;
    cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.interpolated {
    color: rgba(255, 234, 202, 0.5) !important;
    /* font-size: small !important; */
    /* color: red !important; */
}
</style>

<template>
    <span>
        <div class="soil_select_wrapper">
            <template>
                <label for="select_soil_type">Soil Type:</label>
                <select class="widget_margin_left" v-model="workingValue" id="select_soil_type">
                    <option v-for="soilType in selectableSoilTypes" 
                        :key="soilType" 
                        :selected="value === soilType"
                        :value="soilType">
                        {{soilType}}
                    </option>
                </select>
            </template>
        </div>
    </span>
</template>
<script>
export default {
    props: ['value'],
    data: function() {
        return {
            workingValue: null,
            emitted: false,
            selectableSoilTypes: [
                "Unknown", 
                "Sand",
                "Loam",
                "Clay"
            ]
        }
    },
    watch: {
        workingValue: 'workingValueChanged'
    },
    methods: {
        workingValueChanged() {
            if(! this.emitted) {
                if(this.value === this.workingValue) {
                    return
                }

                this.emitted = true
            }

            this.$emit('change', this.workingValue)
        }
    },
    mounted() {
        this.workingValue = this.value
    }
}
</script>

<style>

</style>
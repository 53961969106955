<template>
    <template>
      <pp4-field v-if="startingFieldLayout "
        :field="field"
        :fieldLayout="startingFieldLayout" 
        :clickable="false"
        selectionStyle="selected"
        :showLabel="false"
        :showFurrowDirection="false"/>

      <template v-if="bearing != null">
        <furrow-direction 
          :fieldLayout="fieldLayout" 
          selectionStyle="selected"
          :bearing="bearing"/>
      </template>

      <pp4-poly
        v-if="cleanupFieldActive"
        :strokeColor="Vars.SelectedFieldStrokeColor"
        :strokeOpacity="0.45"
        :fillOpacity="0.0"
        :clickable="false"
        :path="cleanedUpPath"/>

      <pp4-polyline 
        v-for="p of matchingFieldSegmentPaths"
        :key="p.key"
        strokeColor="white"
        :strokeWeight="5"
        :path="p.path" 
        :zIndex="ZIndexes.DebugGeos"/>

      <pp4-polyline 
        v-for="p of matchingCleanedUpFieldSegmentPaths"
        :key="p.key"
        strokeColor="white"
        :strokeOpacity="0.75"
        :strokeWeight="5"
        :path="p.path" 
        :zIndex="ZIndexes.DebugGeos"/>
      <slot/>

      <context-menu>
        <toggle-button description="Snap Furrow Direction to Field Boundaries" 
            label="Snap to Field" 
            :active="snapActive" 
            @click="toggleSnap"><img :src="SnapIcon" :draggable="false"></toggle-button>
        <toggle-button 
            class="button_margin_top"
            description="Straighten Field Edges Similar to Furrow Direction" 
            label="Square Up Field" 
            :active="cleanupFieldActive" 
            @click="toggleCleanupField" faIcon="fa-check-square"/>
        <div v-if="shown">
          <icon-button 
            label="Save" description="Save" 
            class="button_group_margin_top" 
            :useSaveIcon="true"
            v-bind:disabled="! saveActive" v-on:click="save()"/>
          <icon-button 
            label="Cancel" description="Cancel" 
            class="button_margin_top" 
            :useCancelIcon="true"
            v-on:click="cancel()"/>
          </div>
      </context-menu>
    </template>
</template>

<script>
import Pp4MapObject from '@/components/maps/Pp4MapObject'
import Pp4Polyline from '@/components/maps/Polyline'
import Pp4Poly from '@/components/maps/Poly'
import Pp4Field from '@/components/maps/Pp4Field'
import FurrowDirection from '@/components/maps/FurrowDirection'

import { GeoUtil } from '@/geo/GeoUtil'
import { FurrowSetAlg } from '@/geo/FurrowSetAlg'
import { ZIndexes } from '@/maps/ZIndexes'
import Vars from '@/Vars'
import SnapIcon from '@/assets/furrow_direction_snap_icon.svg'
import FieldCleanupAlg from '@/geo/FieldCleanupAlg'

function buildSnapBearings(path, keyPrefix) {
  const ret = []

  const keysForDupeChecking = new Set()

  path.forEach((p, index) => {
    let p1 = p
    let p2 = (index != (path.length - 1)) ?
        path[index + 1] : path[0]

    const key = keyPrefix + JSON.stringify([p1, p2])
    if(keysForDupeChecking.has(key)) {
      return
    }

    keysForDupeChecking.add(key)

    ret.push({
        key, p1: p1, p2: p2, 
        bearing: GeoUtil.bearing(p1, p2),
        bearingReversed: GeoUtil.bearing(p2, p1)
    })
  })

  return ret
}

function buildMatchedSnapBearingPaths(snapBearings, bearing) {
  const threshold = 0.01

  const matchedSnapBearings = snapBearings.filter((sb) => {
    return (Math.abs(sb.bearing - bearing) < threshold)
      || (Math.abs(sb.bearingReversed - bearing) < threshold)
  })

  return matchedSnapBearings.map((sb) => {
    return {
      path: [sb.p1, sb.p2],
      key: sb.key
    }
  })
}

export default {
  extends: Pp4MapObject,
  props: {
    field: {
      type: Object,
      required: false
    },
    fieldLayout: {
      type: Object,
      required: true,
      validator: function(value) { return value != null && value.id != null }
    },
    irrigationSystem: {
      type: Object,
      required: false,
      default: null
    }
  },
  components: {
    FurrowDirection, Pp4Field, Pp4Polyline, Pp4Poly
  },
  data: function() {
      return {
        Vars,  ZIndexes,
        bearing: null,
        startingFieldLayout: null,
        snapActive: true,
        cleanupFieldActive: false,
        SnapIcon
      }
  },
  watch: {
  },
  computed: {
    saveActive: (vm) => (vm.$store.state.mapObjects.availableMapActions.SaveFurrowDirection
      && vm.bearing != vm.fieldLayout.furrowBearing),
    shown: (vm) => vm.$store.state.mapObjects.availableMapActions.SaveFurrowDirection,
    cleanedUpPath: (vm) => {
      if(vm.fieldLayout == null || vm.startingFieldLayout.path == null || vm.startingFieldLayout.path.length < 3) {
          return []
      }

      const ret = FieldCleanupAlg.buildCleanedUpFieldPath(
        vm.startingFieldLayout.path, {snap: true, targetBearing: vm.bearing}
      )
      return ret
    },
    unselectableFields: (vm) => vm.$store.state.mapObjects.unselectableFields,
    fieldGeo: (vm) => GeoUtil.LatLngs.toPolygon(vm.startingFieldLayout.path),
    fieldCenterCoord: (vm) => {
        const fieldCenterGeo = GeoUtil.GeoJson.centerOfMass(vm.fieldGeo)
        return GeoUtil.GeoJson.getCoord(fieldCenterGeo)
    },
    snapBearings: (vm) => {
      if(vm.fieldLayout == null || vm.startingFieldLayout.path == null || vm.startingFieldLayout.path.length < 3) {
          return []
      }

      return buildSnapBearings(vm.startingFieldLayout.path, 'sb:') 
    },
    cleanedUpFieldSnapBearings: (vm) => {
      if(! vm.cleanupFieldActive) {
        return []
      }

      if(vm.cleanedUpPath.length < 3) {
        return []
      }

      return buildSnapBearings(vm.cleanedUpPath, 'cufsb:') 
    },
    matchingFieldSegmentPaths: (vm) => {
      if(vm.bearing == null) {
        return []
      }

      return buildMatchedSnapBearingPaths(
        vm.snapBearings, vm.bearing)
    },
    matchingCleanedUpFieldSegmentPaths: (vm) => {
      if(vm.bearing == null) {
        return []
      }

      return buildMatchedSnapBearingPaths(
        vm.cleanedUpFieldSnapBearings, vm.bearing)
    },
  },
  mounted() {
    this.startingFieldLayout = JSON.parse(JSON.stringify(this.fieldLayout))
  },
  methods: {
    toggleSnap() {
      this.snapActive = ! this.snapActive
    },
    toggleCleanupField() {
        this.cleanupFieldActive = ! this.cleanupFieldActive
        this.emitFurrowDirectionChanged()
    },
    async save() {
      const patches = []

      const furrowBearingPatch = this.buildFurrowBearingPatch()
      patches.push(furrowBearingPatch)

      const cleanedUpFieldPatch = this.buildCleanedUpFieldPatch()
      if(cleanedUpFieldPatch) {
        patches.push(cleanedUpFieldPatch)
      }

      const waterSourceLocation = this.irrigationSystem ?
        this.irrigationSystem.waterSourceLocation : null
      
      const furrowSetDetails =  new FurrowSetAlg().buildFurrowSetDetailsFromSplitPointLatLngs(
          this.fieldLayout.furrowSetDetails.splitPointLatLngs,
          this.bearing, 
          this.cleanedUpPath ? this.cleanedUpPath : this.fieldLayout.path,
          waterSourceLocation)
      patches.push({
        op: 'replace',
        path: '/furrowSetDetails',
        value: furrowSetDetails
      })

      await this.$store.dispatch('patchFieldLayout', {
        field: this.field, fieldLayout: this.fieldLayout, patch: patches
      })

      await this.$store.dispatch('returnToSelectedFieldOrFurrowSet')

    },
    cancel() {
        this.$store.dispatch('returnToSelectedFieldOrFurrowSet')
    },
    emitFurrowDirectionChanged() {
      if(this.cleanupFieldActive) {
        this.$emit('furrowDirectionChanged', this.bearing, this.cleanedUpPath)
        return
      }
      
      this.$emit('furrowDirectionChanged', this.bearing, null)
    },
    buildFurrowBearingPatch() {
      return {
        op: 'replace', 
        path: '/furrowBearing',
        value: this.bearing
      }
    },
    buildCleanedUpFieldPatch() {
      if(! this.cleanupFieldActive) {
        return null
      }

      const path = this.cleanedUpPath
      if((! path) || (path.length < 4)) {
        return null
      }

      return {
        op: 'replace',
        path: '/path',
        value: path
      }
    },
    mapReady() {
      this.bearing = this.fieldLayout.furrowBearing

      this.map.setOptions({
        draggableCursor: 'default',
        disableDoubleClickZoom: true
      })

      this.addMapListener('click', (e) => {
        let googleLatLng = e.latLng
        let mouseCoord = [googleLatLng.lng(), googleLatLng.lat()]

        this.bearing = this.getNearestSnapBearingIfActive(
          GeoUtil.Coords.bearing(this.fieldCenterCoord, mouseCoord))

        this.emitFurrowDirectionChanged()
      })
    },
    getNearestSnapBearingIfActive(userBearing) {
      if(! this.snapActive) {
          return userBearing
      }

      let closestBearingDifference = Number.MAX_VALUE
      let ret = userBearing

      this.snapBearings.forEach((snapBearing) => {
          let difference1 = GeoUtil.bearingDifference(userBearing, snapBearing.bearing)
          if(difference1 < closestBearingDifference) {
              closestBearingDifference = difference1
              ret = snapBearing.bearing
          }

          let difference2 = GeoUtil.bearingDifference(userBearing, snapBearing.bearingReversed)
          if(difference2 < closestBearingDifference) {
              closestBearingDifference = difference2
              ret = snapBearing.bearingReversed
          }
      })
      
      return ret
    }
  }
};
</script>

<style lang="css">
</style>

<template></template>

<script setup>
import { ref, toRaw, watch, onUnmounted, reactive, inject } from 'vue'

import Vars from '@/Vars'
import { ZIndexes } from '@/maps/ZIndexes'

const props = defineProps({
    value: {
        type: Object,
        required: true
    }
})

const data = reactive({
    mapObject: null
})

function clear() {
    if (data.mapObject) {
        toRaw(data.mapObject).setMap(null)
        data.mapObject = null
    }
}

onUnmounted(() => {
    clear()
})

const { api, map } = inject('provider')

function updateOrCreate() {
    const options = {
        map
    }

    clear()


    data.mapObject = new api.Data({ map })

    data.mapObject.addGeoJson(props.value)

    data.mapObject.setStyle((feature, i) => {
        const color = feature.getProperty('color')
        return {
            strokeColor: color,
            fillColor: color,
            strokeWeight: Vars.ElevationStrokeWeight, // TODO: this is a bit tied to elevation stuff for the moment
            strokeOpacity: 1,
            fillOpacity: 1,
            clickable: false,//this.clickable === true,
            // caution: using title will force 'clickable' to true
            zIndex: ZIndexes.Elevation,
            // icon
        }
    })
}

watch(props, updateOrCreate, {immediate: true})
</script>
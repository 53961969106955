<template></template>

<script setup>
import { reactive, watch, onUnmounted, toRaw } from 'vue';

import { useMaps } from '@/composables/useMaps'
import { ZIndexes } from '@/maps/ZIndexes'
import Vars from '@/Vars'

const props = defineProps({
   path: {
      type: Array,
      required: true
   },
   preview: {
      type: Boolean,
      required: false,
      default: true
   },
   styleProps: {
      type: Object,
      required: false,
      default: (vm) => {
         return {
            strokeColor: Vars.SelectedPipePathStrokeColor,
            strokeWeight: Number.parseFloat(Vars.SelectedPipePathStrokeWeight),
            zIndex: ZIndexes.PipePaths + 2,
            icons: [{
               icon: {
                  path: 'M 0,0 0,0',
                  strokeOpacity: 1.0,
                  strokeWeight: 2.0,
                  strokeColor: Vars.SelectedPipePathFillColor
               },
               offset: '0',
               repeat: '1px'
            }],
            opacity: vm.preview ? 0.5 : 1.0,
            strokeOpacity: vm.preview ? 0.5 : 1.0,
            fillOpacity: vm.preview ? 0.5 : 1.0,
            clickable: false
         }
      }
   }
})

const data = reactive({
   polylineMapObject: null
})

const { map, api } = useMaps()

function updateOrCreatePolyline() {
   if (!data.polylineMapObject) {
      let options = {
         ...props.styleProps,
         path: props.path
      }

      data.polylineMapObject = new api.Polyline(options)

      data.polylineMapObject.setMap(map)
   }
   else {
      data.polylineMapObject.setPath(props.path)
   }
}

watch([props], updateOrCreatePolyline, {immediate: true})

onUnmounted(() => {
   if (data.polylineMapObject) {
      toRaw(data.polylineMapObject).setMap(null)
   }
})
</script>

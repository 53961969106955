<template>
    <template>
        <template v-if="editMode === PipePathEditMode.EditPoints">
            <point-marker v-for="o in points" :key="o.key" :snapCallback="snapCallback" :snapLevel="o.snapLevel"
                :location="o.location" :isLastPoint="o.isLastPoint" @click="o.isLastPoint ? endPointClicked(o) : null"
                @change="pointChanged(o, $event)">
                <point-marker-drag-preview :prev="o.prev" :next="o.next" />
            </point-marker>
            <midpoint-marker v-for="o in midpoints" :key="o.key" :snapCallback="snapCallback" :snapLevel="o.snapLevel"
                :location="o.location" :ll0="o.ll0" :ll1="o.ll1"
                @change="(midLatLng) => addMidpoint(o.ll0, o.ll1, midLatLng)" />
        </template>

        <template v-else-if="editMode === PipePathEditMode.RemovePoints && points.length > 2">
            <remove-marker v-for="o in points" :key="o.removeKey" :location="o.location"
                @click="() => removePoint(o.traversalIndexes)" />
        </template>

        <template v-else-if="editMode === PipePathEditMode.EditElevation">
            <edit-elevation v-model="model" />
        </template>

        <template v-else-if="editMode === PipePathEditMode.AddJunctionBranch">
            <template v-if="!data.selectedJunction">
                <point-marker v-for="o in junctionPoints" :key="o.key" @click="beginAddJunctionFromPoint(o)"
                    :draggable="false" :opacity="0.75" :location="o.location" :isLastPoint="true" />
            </template>
            <template v-else>
                <point-marker :draggable="false" :location="data.selectedJunction" :isLastPoint="true" />

                <add-junction-path v-if="data.selectedJunction" v-model="data.junctionPath" :snapCallback="snapCallback"
                    :snapAndFillCallback="props.snapAndFillCallback" :parentLatLng="data.selectedJunction"
                    @done="completeJunction" />
            </template>
        </template>

        <elevation-line-overlay :path="model" />

        <template>
            <pipe-segment v-for="o in segments" :key="o.key" v-bind="o.options" :preview="false" />
        </template>
    </template>
</template>

<script setup>
import { reactive, computed, watch, inject } from 'vue'
import * as uuid from 'uuid'

import { PipePathEditMode, LatLng } from '@/store/Dto'
import { GeoUtil } from '@/geo/GeoUtil'
import PipePathAlg from '@/design/PipePathAlg'
import AddJunctionPath from '@/components/maps/irrigation_systems/AddJunctionPath'
import EditElevation from '@/components/maps/irrigation_systems/EditElevation'

const props = defineProps({
    snapCallback: {
        type: Function,
        required: false
    },
    snapAndFillCallback: {
        type: Function,
        required: false
    }
})

const model = defineModel('path')

const editMode = defineModel('editMode', {
    type: Number,
    required: true,
    default: PipePathEditMode.EditPoints,
    validator: function (value) {
        return Object.values(PipePathEditMode).includes(value)

    }
})
const emit = defineEmits(['endclick'])

const $store = inject('$store')

const data = reactive({
    selectedJunction: null,
    junctionPath: []
})

const points = computed(() => {
    const ret = []

    let isFirstPoint = true

    PipePathAlg.traversePath(model.value, (ll, index, branchPath, isPrimaryBranch, traversalIndexes, parentJunction) => {
        if ((!isPrimaryBranch) && (index === 0)) {
            return
        }

        // skip the first point because it's the water source
        if (isFirstPoint) {
            isFirstPoint = false
            return
        }

        const isLastPoint = index === (branchPath.length - 1)

        const snapLevel = parentJunction ? 1 : 0

        ret.push({
            location: ll,
            index,
            prev: index > 0 ? branchPath[index - 1] : null,
            next: isLastPoint ? null : branchPath[index + 1],
            key: `edit:${JSON.stringify(traversalIndexes)}:${JSON.stringify(ll)}`,
            removeKey: `remove:${JSON.stringify(traversalIndexes)}:${JSON.stringify(ll)}`, // this remove key yields a performant & accurate behavior
            snapLevel,
            branchPath,
            isLastPoint,
            traversalIndexes,
            parentJunction
        })

        return ret
    })

    return ret
})

const endPoints = computed(() => points.value.filter(p => p.isLastPoint))
const junctionPoints = computed(() => endPoints.value.filter(p => PipePathAlg.isJunction(p.location)))

const midpoints = computed(() => {
    const ret = []

    let index = 0
    PipePathAlg.traversePathSegmentswithJunctions(model.value, (ll0, ll1, _isPrimaryBranch, parentJunction) => {
        const segmentLength = GeoUtil.LatLngs.length([ll0, ll1], {units: 'meters'})
        if(segmentLength < 20) {
            return
        }

        const midPoint = GeoUtil.Coords.midpoint([ll0.lng, ll0.lat], [ll1.lng, ll1.lat])
        const midLatLng = GeoUtil.GeoJson.toLatLngs(midPoint)

        index += 1

        ret.push({
            ll0, ll1,
            location: midLatLng,
            key: `mid:${JSON.stringify([ll0, ll1])}:${index}`,
            snapLevel: parentJunction ? 1 : 0
        })
    })

    return ret
})

const segments = computed(() => {
    const ret = []

    PipePathAlg.traversePathSegmentswithJunctions(model.value, (ll0, ll1) => {
        ret.push({
            options: {
                path: [ll0, ll1]
            },
            key: uuid.v1()//`segment:${JSON.stringify([ll0, ll1])}`
        })
    })

    return ret
})

watch([editMode], () => {
    if (editMode.value === PipePathEditMode.AddJunctionBranch) {
        data.selectedJunction = null
        data.junctionPath = []
    }
}, { immediate: true })

function endPointClicked(o) {
    emit('endclick', o)
}

function beginAddJunctionFromPoint(o) {
    data.selectedJunction = o.location
    data.junctionPath = []

    $store.dispatch('mapObjects/setHelpKey', 'add_junction_branch_2')
}

function completeJunction() {
    data.selectedJunction.paths.push([
        new LatLng(data.selectedJunction.lat, data.selectedJunction.lng), ...data.junctionPath
    ])

    editMode.value = PipePathEditMode.EditPoints

    const needs = PipePathAlg.buildInterpolationNeeds(model.value)
    if (!needs.length) {
        PipePathAlg.interpolateElevationInFeet(model.value)
    }
}

function pointChanged(o, newLatLng) {
    function updateCallback(_originalPath, path, index) {
        //path[index] = {...path[index], newLatLng}
        Object.assign(path[index], newLatLng)
        // console.dir(path[index]) 
        //model.value = model.value.slice()
    }

    PipePathAlg.traverseToUsingIndexes(model.value, o.traversalIndexes, updateCallback)

    const needs = PipePathAlg.buildInterpolationNeeds(model.value)
    if (!needs.length) {
        PipePathAlg.interpolateElevationInFeet(model.value)
    }
}

function addMidpoint(ll0, ll1, midLll) {
    if (PipePathAlg.hasValidElevation(ll0) && PipePathAlg.hasValidElevation(ll1)) {
        const elevationInFeet = (ll1.elevationInFeet
            + ll0.elevationInFeet) / 2.0
        midLll.elevationInFeet = elevationInFeet
        midLll.interpolated = true
    }

    PipePathAlg.insertAfter(ll0, midLll, model.value)

    const needs = PipePathAlg.buildInterpolationNeeds(model.value)
    if (!needs.length) {
        PipePathAlg.interpolateElevationInFeet(model.value)
    }
}

function removePoint(traversalIndexes) {
    function removeCallback(_originalPath, path, index, parentJunction, parentJunctionBranchIndex) {
        path.splice(index, 1)

        if (parentJunction && (path.length < 2)) {
            // clear out the empty junction branch
            parentJunction.paths.splice(parentJunctionBranchIndex, 1)
        }
    }

    PipePathAlg.traverseToUsingIndexes(model.value, traversalIndexes, removeCallback)
}
</script>

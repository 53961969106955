<template></template>

<script setup>
import { useStore } from 'vuex'
import { onUnmounted, reactive, watch, toRaw } from 'vue';

import { ZIndexes } from '@/maps/ZIndexes'
import { useMaps } from '@/composables/useMaps'

const props = defineProps({
    location: {
        type: Object,
        required: true
    },
    message: {
        type: String,
        required: true
    }
})

const data = reactive({
    markerMapObject: null
})

const $store = useStore()
const maps = useMaps()

async function click() {
    await $store.dispatch('warn', props.message)
}

function updateOrCreateMarker() {
    const options = {
        title: 'Hole Design Error',
        map: toRaw(maps.map),
        strokeColor: 'white',
        position: props.location,
        visible: true,
        icon: 'https://maps.google.com/mapfiles/kml/pal3/icon51.png',
        zIndex: ZIndexes.HoleDesignErrorMarkers
    }

    if (!data.markerMapObject) {
        data.markerMapObject = new maps.api.Marker(options)

        data.markerMapObject.addListener('click', click)
    }
    else {
        data.markerMapObject.setOptions(options)
    }
}

onUnmounted(() => {
    if (data.markerMapObject) {
        maps.removeMapObject(data.markerMapObject)
    }
})

watch(props, updateOrCreateMarker, { immediate: true })
</script>

<script>
import RemoveIcon from '@/assets/remove_icon.svg'

import Pp4MapObject from '@/components/maps/Pp4MapObject'

import { LatLng, GeometryEditMode } from '@/store/Dto'
import { GeoUtil } from '@/geo/GeoUtil'
import { ZIndexes } from '@/maps/ZIndexes'

import Vars from '@/Vars'

export default {
    extends:  Pp4MapObject,
    props: {
        path: {
            type: Array,
            required: true
        },
        selectionStyle: {
            type: String,
            required: false,
            default: 'selected',
            validator: function(value) {
                return value === "selected" 
                || value === "selectable" 
                || value === "unselectable" }
        },
        clickable: {
            type: Boolean,
            required: false,
            default: false
        },
        snapCallback: {
            type: Function,
            required: false,
            default: null
        },
        editMode: {
            type: Number,
            required: false,
            default: GeometryEditMode.None
        },
        showInPreviewMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    watch: {
        'path': 'invokeMapReadyIfAvailable',
        'editMode': 'buildMapObjectsFromWorkingPath'
    },
    data: function() {
        return {
            workingPath: null,
            ZIndexes, GeometryEditMode,
            pathSegmentPolylines: [],
            pathSegmentPreviewPolylines: [],
            midPointMarkers: [],
            removePointMarkers: [],
            moveMarkers: []
        }
    },
    computed: {
        selectionStyleProps: (vm) => {
            switch(vm.selectionStyle) {
                case 'selected':
                    return {
                        strokeColor: Vars.SelectedFieldStrokeColor,
                        fillColor: Vars.SelectedFieldStrokeColor,
                        strokeWeight: Number.parseFloat(Vars.SelectedFieldStrokeWeight),
                        zIndex: ZIndexes.LeveePaths + 2,
                    }
                case 'selectable':
                    return {
                        strokeColor: Vars.SelectableFieldStrokeColor,
                        fillColor: Vars.SelectableFieldStrokeColor,
                        strokeWeight: Vars.SelectableFieldStrokeWeight,
                        zIndex: ZIndexes.LeveePaths + 1
                    }
                case 'unselectable':
                    return {
                        strokeColor: Vars.UnselectableFieldStrokeColor,
                        fillColor: Vars.UnselectableFieldStrokeColor,
                        strokeWeight: Number.parseFloat(Vars.UnselectableFieldStrokeWeight),
                        zIndex: ZIndexes.LeveePaths
                    }
            }

            return {}
        }
    },
    mounted() {
    },
    methods: {
        snapIfNecessary(latLng, originalLatLng) {
            if(this.snapCallback == null) {
                return latLng
            }

            if(this.workingPath.length === 0) {
                return latLng
            }

            if((originalLatLng != null) && (this.workingPath.length > 0)) {
                const p = this.workingPath
                const isOriginalFirstOrLastLatLng = (originalLatLng === p[0])
                    || (originalLatLng === p[p.length - 1])
                if(! isOriginalFirstOrLastLatLng) {
                    return latLng
                }
            }

            return this.snapCallback(latLng)
        },
        buildRemovePointMarker(ll, index) {
            let options = {
                label: null,
                title: null,
                position: ll, 
                visible: true,
                clickable: true,
                icon: {
                    anchor: new this.api.Point(18, 18),
                    scale: 0.125,
                    fillOpacity: 1.0,
                    strokeOpacity: 1.0,
                    strokeWeight: 3,
                    scaledSize: new this.api.Size(36, 36),
                    url: RemoveIcon,
                }
            }

            const removeMarker = new this.api.Marker(options)
            this.mapObjects.push(removeMarker)
            removeMarker.setMap(this.map)

            let removeCallback = () => {
                this.workingPath.splice(index, 1)

                this.$nextTick(() => {
                    this.buildMapObjectsFromWorkingPath()
                    this.emitChange()
                })
            }

            this.api.event.addListener(removeMarker, 'click', removeCallback)
        },
        // Intended to be used by other methods
        buildGenericPointMarker(ll) {
            const buildIcon = () => {
                const ret = Object.assign({}, this.selectionStyleProps)

                ret.path = this.api.SymbolPath.CIRCLE,
                ret.strokeWeight = 1.0
                ret.fillOpacity = 1.0
                ret.scale = 6

                return ret
            }

            let options = {
                visible: true,
                clickable: false,
                label: null,
                title: null,
                position: ll,
                zIndex: this.selectionStyleProps.zIndex + 1,
                draggable: false,
                map: this.map,
                icon: buildIcon()
            }

            const marker = new this.api.Marker(options)
            marker.setMap(this.map)
            this.mapObjects.push(marker)

            return marker
        },
        buildMoveMarker(ll, index) {
            const marker = this.buildGenericPointMarker(ll)
            marker.setOptions({
                clickable: true,
                zIndex: marker.getZIndex() + 1}
            )

            marker.setDraggable(true)

            this.moveMarkers.push(marker)

            const path = this.workingPath

            let prevLl = (index === 0) ? null : path[index - 1]
            let nextLl = (index === (path.length - 1)) ? null : path[index + 1]
            let prevSegmentPreview = null
            let nextSegmentPreview = null

            const removePathSegmentPreviewPolylines = () => {
                this.pathSegmentPreviewPolylines.forEach((polyline) => this.removeMapObject(polyline))
                this.pathSegmentPreviewPolylines = []
            }

            const updateOrBuildPathSegmentPreviewPolylines = (newLatLng) => {
                const anythingToShow = (prevLl != null) || (nextLl != null) 
                if(! anythingToShow) {
                    return
                }

                const doUpdate = (prevSegmentPreview != null) || (nextSegmentPreview != null)
                if(doUpdate) {
                    if(prevSegmentPreview != null) {
                        prevSegmentPreview.setPath([prevLl, newLatLng])
                    }
                    if(nextSegmentPreview != null) {
                        nextSegmentPreview.setPath([newLatLng, nextLl])
                    }

                    return
                }

                removePathSegmentPreviewPolylines()                

                if(prevLl) {
                    prevSegmentPreview = this.buildPathSegmentPreviewPolyline(prevLl, newLatLng)
                }
                if(nextLl) {
                    nextSegmentPreview = this.buildPathSegmentPreviewPolyline(newLatLng, nextLl)
                }
            }

            const dragCallback = (e) => {
                const newLatLng = new LatLng(e.latLng.lat(), e.latLng.lng())
                
                if(this.snapCallback) {
                    const snappedLatLng = this.snapIfNecessary(newLatLng, ll)
                    marker.setPosition(snappedLatLng)
                    updateOrBuildPathSegmentPreviewPolylines(snappedLatLng)
                }
                else {
                    updateOrBuildPathSegmentPreviewPolylines(newLatLng)
                }
            }

            const dragEndCallback = (e) => {
                const newLatLng = new LatLng(e.latLng.lat(), e.latLng.lng())

                if(this.snapCallback) {
                    const snappedLatLng = this.snapIfNecessary(newLatLng, ll)
                    ll.lat = snappedLatLng.lat
                    ll.lng = snappedLatLng.lng
                }
                else {
                    ll.lat = newLatLng.lat
                    ll.lng = newLatLng.lng
                }

                this.buildMapObjectsFromWorkingPath()
                this.emitChange()
            }

            this.api.event.addListener(marker, 'drag', dragCallback)
            this.api.event.addListener(marker, 'dragend', dragEndCallback)

            const clickCallback = () => {
                this.handleClick()
            }

            this.api.event.addListener(marker, 'click', clickCallback)
        },
        buildMidpointMarker(ll0, ll1, ll0Index) {
            const lineString = GeoUtil.LatLngs.toLineString([ll0, ll1])
            const lengthInMeters = GeoUtil.GeoJson.length(
                lineString, {units: 'meters'})

            if(lengthInMeters < this.$store.state.preferences.levees.stopShowingEditMidpointsIfLessThanMeters) {
                return
            }

            const midpointOfLineString = GeoUtil.GeoJson.along(
                lineString, lengthInMeters / 2, {units: 'meters'})
            const midpoint = new this.api.LatLng(
                midpointOfLineString.geometry.coordinates[1], 
                midpointOfLineString.geometry.coordinates[0])

            let options = {
                visible: true,
                label: null,
                title: null,
                position: midpoint,
                zIndex: this.selectionStyleProps.zIndex + 1,
                draggable: true,
                clickable: true,
                crossOnDrag: false,
                icon: {
                    path: this.api.SymbolPath.CIRCLE,
                    fillColor: this.selectionStyleProps.fillColor,
                    fillOpacity: 0.5,
                    strokeWeight: 0.0,
                    clickable: false,
                    scale: 6
                }
            }

            const midpointMarker = new this.api.Marker(options)
            this.mapObjects.push(midpointMarker)
            this.midPointMarkers.push(midpointMarker)
            midpointMarker.setMap(this.map)

            let line0 = null
            let line1= null

            const dragStartCallback = (e) => {
                line0 = this.buildPathSegmentPreviewPolyline(ll0, midpoint)
                line1 = this.buildPathSegmentPreviewPolyline(midpoint, ll1)
            }

            const dragCallback = (e) => {
                const latLng = new LatLng(e.latLng.lat(), e.latLng.lng())

                line0.setPath([ll0, latLng])
                line1.setPath([latLng, ll1])
            }

            const dragEndCallback = (e) => {
                const latLng = new LatLng(e.latLng.lat(), e.latLng.lng())

                this.workingPath.splice(ll0Index + 1, 0, latLng)

                this.buildMapObjectsFromWorkingPath()
                this.emitChange()
            }

            this.api.event.addListener(midpointMarker, 'dragstart', dragStartCallback)
            this.api.event.addListener(midpointMarker, 'drag', dragCallback)
            this.api.event.addListener(midpointMarker, 'dragend', dragEndCallback)
        },
        buildPathSegmentPreviewPolyline(ll0, ll1) {
            let polylineOptions = {
                path: [ll0, ll1],
                editable: false, clickable: false,
                strokeColor: Vars.TentativeFieldStrokeColor,
                strokeWeight: this.selectionStyleProps.strokeWeight,
                fillOpacity: 1.0,
                strokeOpacity: 1.0,
                icons: this.selectionStyleProps.icons,
                zIndex: this.selectionStyleProps.zIndex
            }

            const ret = new this.api.Polyline(polylineOptions)
            this.mapObjects.push(ret)
            this.pathSegmentPreviewPolylines.push(ret)
            ret.setMap(this.map)

            return ret
        },
        buildPathSegmentPolyline(ll0, ll1) {
            let polylineOptions = {
                path: [ll0, ll1],
                editable: false,
                clickable: this.clickable,
                strokeColor: this.selectionStyleProps.strokeColor,
                strokeWeight: this.selectionStyleProps.strokeWeight,
                icons: this.selectionStyleProps.icons,
                zIndex: this.selectionStyleProps.zIndex
            }

            const polyline = new this.api.Polyline(polylineOptions)
            this.mapObjects.push(polyline)
            this.pathSegmentPolylines.push(polyline)
            polyline.setMap(this.map)
            
            this.api.event.addListener(polyline, 'click', this.handleClick)
        },
        buildPathSegmentPolylines() {
            this.pathSegmentPolylines.forEach((pl) => this.removeMapObject(pl))
            this.pathSegmentPolylines = []

            this.workingPath.forEach((ll1, i) => {
                if(i === 0) {
                    return
                }

                const ll0 = this.workingPath[i - 1]

                if(this.showInPreviewMode) {
                    this.buildPathSegmentPreviewPolyline(ll0, ll1)
                }
                else {
                    this.buildPathSegmentPolyline(ll0, ll1)
                }
            })
        },
        buildMapObjectsFromWorkingPath() {
            this.clearMapListeners()
            this.clearMapObjects()

            this.pathSegmentPolylines = []
            this.pathSegmentPreviewPolylines = []
            this.midPointMarkers = []
            this.removePointMarkers = []
            this.moveMarkers = []

            this.buildPathSegmentPolylines()

            if(this.editMode == GeometryEditMode.EditPoints) {
                this.workingPath.forEach((ll, i) => {
                    this.buildMoveMarker(ll, i) 

                    if(i > 0) {
                        const prevLl = this.workingPath[i - 1]
    
                        this.buildMidpointMarker(prevLl, ll, i - 1)
                    }
                })
            }
            else if(this.editMode == GeometryEditMode.RemovePoints 
                    && (this.workingPath.length > 2)) {
                this.workingPath.forEach((ll, index) => {
                    this.buildRemovePointMarker(ll, index)
                })
            }
            else if(this.showInPreviewMode) {
                this.workingPath.forEach((ll) => {
                    const marker = this.buildGenericPointMarker(ll)
                    marker.getIcon().fillOpacity = 1.0
                    marker.getIcon().fillColor = Vars.TentativeFieldStrokeColor
                    marker.getIcon().strokeColor = Vars.TentativeFieldStrokeColor
                })
            }
        },
        handleClick(e) {
            this.$emit('click', e)
        },
        emitChange() {
            this.$emit('change', this.workingPath)
        },
        mapReady() {
            this.workingPath = JSON.parse(JSON.stringify(this.path))
            this.buildMapObjectsFromWorkingPath()
        }
    }
}
</script>

<template>
   <!-- <map-controls position="TOP_CENTER">
      <div id="jd-wizard-steps">
         <icon-button :big="true" faIcon="fa-home" label="Furrow/Levee"/>
         <icon-button :big="true" faIcon="fa-gear" label="Direction"/>
         <icon-button :big="true" faIcon="fa-gear" label="test"/>
      </div>
   </map-controls> -->
   <template v-if="currentStep === Steps.SetFieldMode">
      <JdSelectFieldMode v-model:fieldName="data.fieldName" v-model:layoutName="data.layoutName"
         v-model:fieldMode="data.fieldMode" :field="field" :fieldLayout="fieldLayout" @done="goToNextEmptyStep"
         @split-boundary="enterSplitBoundarydMode" @removed="removed" @cancel="cancelWizard" />
      <header-cancel-button label="Back" @cancel="cancelWizard" />
   </template>

   <JdSplitBoundary v-if="currentStep === Steps.SplitBoundary" :field="props.field" :fieldLayout="props.fieldLayout"
      @cancel="cancelSplitBoundary" />

   <JdEditFurrowDirection v-if="currentStep === Steps.SetFurrowDirection" :farm="farm" :field="field"
      :fieldLayout="fieldLayout" v-model="data.bearing">
      <next-step v-if="readyForWaterSource">
         <icon-button label="Add Water Source" description="Add Water Source" :big="true" faIcon="fa-arrow-right"
            @click="addWaterSource" /> <br>
         <icon-button label="Select Water Source" description="Select Water Source" :big="true" faIcon="fa-arrow-right"
            @click="selectWaterSource">
         </icon-button>
      </next-step>

      <header-cancel-button label="Back" @cancel="selectFieldMode" />
   </JdEditFurrowDirection>

   <JdEditLevees v-if="currentStep === Steps.EditLevees" :farm="farm" :field="field" :fieldLayout="workingFieldLayout"
      v-model="data.leveePaths" @done="goToNextEmptyStep">
      <next-step>
         <icon-button label="Add Water Source" description="Add Water Source" :big="true" faIcon="fa-arrow-right"
            @click="addWaterSource" /> <br>
         <icon-button label="Select Water Source" description="Select Water Source" :big="true" faIcon="fa-arrow-right"
            @click="selectWaterSource">
         </icon-button>
      </next-step>

      <header-cancel-button label="Back" @cancel="selectFieldMode" />
   </JdEditLevees>

   <template v-if="currentStep === Steps.AddWaterSource">
      <header-cancel-button label="Back" @cancel="cancelAddOrSelectWaterSource" />

      <add-irrigation-system @added="handleIrrigationSystemAdded" :farm="farm" :field="field"
         :fieldLayout="workingFieldLayout" fromSource="Wizard" />
   </template>

   <template v-else-if="currentStep === Steps.SelectWaterSource">
      <header-cancel-button label="Back" @cancel="cancelAddOrSelectWaterSource" />

      <pp4-field :field="field" :fieldLayout="workingFieldLayout" selection-style="selected" :clickable="false"
         :showLabel="false" />

      <select-water-sources :farm="farm" @changed="handleIrrigationSystemSelected" />
   </template>

   <template v-else-if="currentStep === Steps.CalculateSets">
      <header-cancel-button label="Back" @cancel="cancelEditFurrowSets" />

      <next-step>
         <icon-button label="Finish Field" description="Finish Field and Add Pipe" :big="true" :useSaveIcon="true"
            @click="doneCalculatingFurrowSets" />
      </next-step>

      <edit-furrow-sets v-if="data.selectedIrrigationSystem?.flowRates[0]?.valueInGpm" :farm="farm" :field="field"
         :fieldLayout="workingFieldLayout" :flowRateInGpm="data.selectedIrrigationSystem.flowRates[0].valueInGpm"
         :showSaveAndCancelButtons="false" :fromWizard="true" @goBack="cancelAddOrSelectWaterSource"
         :startingMode="EditFurrowSetsMode.Calculate" @change="handleFurrowSetsChanged"
         :primaryFlowRateId="data.selectedIrrigationSystem.flowRates[0].id" />
   </template>

   <water-source v-for="irrigationSystem in farm.irrigationSystems" :location="irrigationSystem.waterSourceLocation"
      :name="irrigationSystem.name" selectionStyle="unselectable" />
</template>

<script setup>
import { reactive, computed, onMounted, onUnmounted, ref, inject, watch } from 'vue';

import AddIrrigationSystem from '@/components/maps/irrigation_systems/AddIrrigationSystem'
import SelectWaterSources from '@/components/maps/add_field/SelectWaterSources'

import WaterSource from '@/components/maps/irrigation_systems/WaterSource'
import EditFurrowSets from '@/components/maps/edit_furrow_sets/EditFurrowSets'
import { EditFurrowSetsMode } from '@/components/maps/edit_furrow_sets/EditFurrowSets'

import { FurrowSetDetails, FieldLayout, FieldMode } from '@/store/Dto'

import JdSelectFieldMode from './JdSelectFieldMode';
import JdEditFurrowDirection from './JdEditFurrowDirection';
import JdEditLevees from './JdEditLevees';
import JdSplitBoundary from './JdSplitBoundary'

const props = defineProps(['farm', 'field', 'fieldLayout'])
const emit = defineEmits(['cancel', 'done'])

const farm = props.farm
const field = props.field
const fieldLayout = props.fieldLayout
const jd = field.jd

const data = reactive({
   inSplitBoundaryMode: false,
   fieldMode: null,
   fieldName: field.name,
   layoutName: null,
   bearing: null,
   selectedIrrigationSystem: null,
   primaryFlowRateId: null,
   grossAppliedInches: 3,
   desiredWateringTimeInHours: 24,
   furrowSpacingInInches: 38,
   alternatingFurrows: false,
   furrowSetDetails: new FurrowSetDetails(),
   fallPerLeveeInInches: 2.4,
   leveeHeightInInches: 24,
   leveePaths: []
})

const workingFieldLayout = computed(() => {
   const ret = new FieldLayout(fieldLayout.id, fieldLayout.mode, fieldLayout.path)

   Object.assign(ret, {
      ...fieldLayout, mode: data.fieldMode,
      name: data.layoutName, furrowBearing: data.bearing,
      grossAppliedInches: data.grossAppliedInches,
      desiredWateringTimeInHours: data.desiredWateringTimeInHours,
      furrowSpacingInInches: data.furrowSpacingInInches,
      alternatingFurrows: data.alternatingFurrows,
      furrowSetDetails: data.furrowSetDetails,
      primaryFlowRateId: data.primaryFlowRateId,
      leveePaths: data.leveePaths,
      fallPerLeveeInInches: data.fallPerLeveeInInches,
      leveeHeightInInches: data.leveeHeightInInches
   })

   return ret
})

const Steps = {
   SetFieldMode: 'set_field_mode',
   SplitBoundary: 'split_boundary',
   SetFurrowDirection: 'set_furrow_direction',
   EditLevees: 'edit_levees',
   SelectWaterSource: 'select_water_source',
   AddWaterSource: 'add_water_source',
   CalculateSets: 'calculate_sets'
}
const currentStep = ref(Steps.SetFieldMode)

const $store = inject("$store")

const readyForWaterSource = computed(() => {
   return Number.isFinite(data.bearing)
})

function goToNextEmptyStep() {
   switch (currentStep.value) {
      case Steps.SetFieldMode:
         currentStep.value = (data.fieldMode === FieldMode.Furrows) ?
            Steps.SetFurrowDirection : Steps.EditLevees
         break
      case Steps.SetFurrowDirection:
         break
   }
}

function selectFieldMode() {
   data.fieldMode = null
   $store.dispatch('mapObjects/setHelpKey', null)
   currentStep.value = Steps.SetFieldMode
}

function enterSplitBoundarydMode() {
   currentStep.value = Steps.SplitBoundary
}
function cancelSplitBoundary() {
   currentStep.value = Steps.SetFieldMode
}

function selectWaterSource() {
   currentStep.value = Steps.SelectWaterSource
}

function addWaterSource() {
   currentStep.value = Steps.AddWaterSource
}

function cancelWizard() {
   emit('cancel')
}

function removed() {
   emit('cancel')
}

function cancelAddOrSelectWaterSource() {
   currentStep.value = Steps.SetFurrowDirection
}

function cancelEditFurrowSets() {
   currentStep.value = Steps.SetFurrowDirection
}

async function handleIrrigationSystemAdded(irrigationSystem) {
   await $store.dispatch('addIrrigationSystem', irrigationSystem)

   data.selectedIrrigationSystem = irrigationSystem

   if (irrigationSystem.flowRates.length) {
      data.primaryFlowRateId = irrigationSystem.flowRates[0].id
   }

   if (data.fieldMode === FieldMode.Furrows) {
      currentStep.value = Steps.CalculateSets
   }
   else {
      await doneWithLevees()
   }
}

async function handleIrrigationSystemSelected(irrigationSystem) {
   data.selectedIrrigationSystem = irrigationSystem

   if (irrigationSystem.flowRates.length) {
      data.primaryFlowRateId = irrigationSystem.flowRates[0].id
   }

   if (data.fieldMode === FieldMode.Furrows) {
      currentStep.value = Steps.CalculateSets
   }
   else {
      await doneWithLevees()
   }
}

function handleFurrowSetsChanged(val) {
   data.primaryFlowRateId = val.primaryFlowRateId
   data.furrowSpacingInInches = val.furrowSpacingInInches
   data.alternatingFurrows = val.alternatingFurrows
   data.grossAppliedInches = val.grossAppliedInches
   data.desiredWateringTimeInHours = val.desiredWateringTimeInHours
   data.furrowSetDetails = val.furrowSetDetails
}

async function doneWithLevees() {
   const patchProps = ['mode', 'leveePaths', 'name', 'primaryFlowRateId', 'fallPerLeveeInInches',
      'leveeHeightInInches']

   const patches = patchProps.map(pp => {
      const op = Object.hasOwn(props.fieldLayout, pp) ?
         'replace' : 'add'

      return {
         path: `/${pp}`,
         op, value: workingFieldLayout.value[pp]
      }
   })

   patches.push({
      op: 'remove', path: '/needsWizard'
   })

   await $store.dispatch('patchFieldLayout', {
      field: props.field, fieldLayout: props.fieldLayout, patch: patches
   })

   await $store.dispatch('setSelectedField', props.field)

   emit('done')
}

async function patchFieldLayout() {
   const patchProps = ['alternatingFurrows', 'desiredWateringTimeInHours', 'furrowBearing',
      'furrowSpacingInInches', 'grossAppliedInches', 'name', 'primaryFlowRateId', 'furrowSetDetails']

   const patches = patchProps.map(pp => {
      const op = Object.hasOwn(props.fieldLayout, pp) ?
         'replace' : 'add'

      return {
         path: `/${pp}`,
         op, value: workingFieldLayout.value[pp]
      }
   })

   patches.push({
      op: 'remove', path: '/needsWizard'
   })

   await $store.dispatch('patchFieldLayout', {
      field: props.field, fieldLayout: props.fieldLayout, patch: patches
   })
}

async function patchField() { // only the name..
   if (props.field.name === data.fieldName) {
      return
   }

   const patches = [
      {
         path: '/name', op: Object.hasOwn(props.field, 'name') ? 'replace' : 'add',
         value: data.fieldName
      }
   ]

   await $store.dispatch('patchField', {
      field: props.field, patch: patches
   })
}

async function doneCalculatingFurrowSets() {
   await patchField()

   await patchFieldLayout()

   await $store.dispatch('returnToSelectedFieldOrFurrowSet')
}

watch(() => data.bearing, goToNextEmptyStep)

onMounted(() => {
})

onUnmounted(() => {
})

defineExpose({ ...props, jd, selectFieldMode })
</script>

<style lang="css">
#jd-wizard-steps {
   display: flex;
   flex-direction: row;
   padding: var(--menu-border-size);
   padding-top: 0px;
   background: blue;
   border-bottom-left-radius: var(--menu-border-radius);
   border-bottom-right-radius: var(--menu-border-radius);
   column-gap: var(--menu-border-size);
}
</style>

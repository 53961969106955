<template>
  <pp4-field
    :field="field"
    ref="field"
    :fieldLayout="fieldLayout"
    :clickable="true"
    :showLabel="false"
    :showLabelOnMouseover="false"
    :showFurrowDirection="false"
    selectionStyle="selected"
    @mousemove="updatePreviewOfSetsToBeAdded"
    @click="addSplitPoint"
  />

  <pp4-polyline :zIndex="ZIndexes.Fields + 50" v-if="splitLineLatLngs" :path="splitLineLatLngs" />

  <furrow-set
    v-for="furrowSet in furrowSetsNotBeingSplit"
    selectionStyle="selected"
    :key="furrowSet.id"
    :furrowSet="furrowSet"
    :fieldLayout="fieldLayout"
    :showLabel="false"
    :zIndex="ZIndexes.Fields - 1"
  />

  <watering-times
    v-for="(o, i) in wateringTimePathsAndIrrigationSystems"
    :key="i"
    :grossAppliedInches="fieldLayout.grossAppliedInches"
    :alternatingFurrows="fieldLayout.alternatingFurrows"
    :desiredWateringTime="fieldLayout.desiredWateringTimeInHours"
    :path="o.path"
    :flowRate="flowRate"
    :irrigationSystem="o.irrigationSystem"
  />

  <water-source
    v-if="irrigationSystem"
    :location="irrigationSystem.waterSourceLocation"
    :name="irrigationSystem.name"
    :clickable="false"
    :showLabel="true"
    selectionStyle="selected"
  />
</template>

<script>
import Pp4Field from '@/components/maps/Pp4Field'
import FurrowSet from '@/components/maps/FurrowSet'
import Pp4Polyline from '@/components/maps/Polyline'
import WaterSource from '@/components/maps/irrigation_systems/WaterSource'
import WateringTimes from '@/components/maps/irrigation_systems/WateringTimes'
import Vars from '@/Vars'

import { ZIndexes } from '@/maps/ZIndexes'
import { LatLng } from '@/store/Dto'
import { GeoUtil } from '@/geo/GeoUtil'
import { Pp4Uuid } from '@/store/Dto'
import { FurrowSetAlg } from '@/geo/FurrowSetAlg'

export default {
  props: {
    farm: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    fieldLayout: {
      type: Object,
      required: true
    }
  },
  components: {
    Pp4Field,
    Pp4Polyline,
    FurrowSet,
    WaterSource,
    WateringTimes
  },
  mounted() {
    const flowRateId = this.fieldLayout.primaryFlowRateId

    this.farm.irrigationSystems.find((is) =>
      is.flowRates.find((fr) => {
        if (fr.id == flowRateId) {
          this.irrigationSystem = is
        }
      })
    )
  },
  data: function () {
    return {
      furrowSetToSplit: null,
      smallerSplitPolyPath: null,
      largerSplitPolyPath: null,
      smallerSplitPolyLabel: null,
      largerSplitPolyLabel: null,
      splitLineLatLngs: null,
      irrigationSystem: null,
      Vars,
      ZIndexes
    }
  },
  computed: {
    irrigationSystemsCloseEnoughTofield: (vm) => {
      const maxDistanceInFeet = vm.$store.state.preferences.pipe.maxDistanceFromFieldInFeet
      const fieldLineString = GeoUtil.LatLngs.toLineString(vm.fieldLayout.path)

      return vm.farm.irrigationSystems.filter((irrigationSystem) => {
        const pointGeo = GeoUtil.LatLngs.toGeoJsonPoint(irrigationSystem.waterSourceLocation)

        const distanceInFeet = GeoUtil.GeoJson.pointToLineDistance(pointGeo, fieldLineString, {
          units: 'feet'
        })

        return distanceInFeet < maxDistanceInFeet
      })
    },
    wateringTimePathsAndIrrigationSystems: (vm) => {
      const ret = []

      const furrowSetPaths = []
      if (vm.smallerSplitPolyPath != null) {
        furrowSetPaths.push(vm.smallerSplitPolyPath)
      }
      if (vm.largerSplitPolyPath != null) {
        furrowSetPaths.push(vm.largerSplitPolyPath)
      }

      furrowSetPaths.forEach((furrowSetPath) => {
        const pathLineString = GeoUtil.LatLngs.toLineString(furrowSetPath)

        let closestDistanceInFeet = Number.MAX_VALUE

        let irrigationSystem = vm.irrigationSystem
        if (irrigationSystem == null) {
          irrigationSystem = vm.irrigationSystemsCloseEnoughTofield.reduce((closest, is) => {
            const pointGeo = GeoUtil.LatLngs.toGeoJsonPoint(is.waterSourceLocation)

            const distanceInFeet = GeoUtil.GeoJson.pointToLineDistance(pointGeo, pathLineString, {
              units: 'feet'
            })
            if (closest == null || distanceInFeet < closestDistanceInFeet) {
              closestDistanceInFeet = distanceInFeet
              return is
            }

            return closest
          }, null)
        }
        if (irrigationSystem == null) {
          return
        }

        ret.push({ id: Pp4Uuid(), path: furrowSetPath, irrigationSystem })
      })

      return ret
    },
    furrowSetsNotBeingSplit: (vm) => {
      return vm.fieldLayout.furrowSetDetails.resultingFurrowSets.filter(
        (furrowSet) => furrowSet != vm.furrowSetToSplit
      )
    },
    smallerSplitPolyLabelClass: (vm) => vm.$style['smaller_furrow_set_split_label'],
    largerSplitPolyLabelClass: (vm) => vm.$style['larger_furrow_set_split_label'],
    fieldGeo: (vm) => GeoUtil.LatLngs.toPolygon(vm.fieldLayout.path),
    fieldArea: (vm) => GeoUtil.GeoJson.area(vm.fieldGeo),
    splitBearing: (vm) => {
      return vm.fieldLayout.perpendicularSets
        ? GeoUtil.bearingPerpendicular(vm.fieldLayout.furrowBearing)
        : vm.fieldLayout.furrowBearing
    },
    flowRate: (vm) => {
      if (!vm.irrigationSystem) {
        return null
      }

      return vm.irrigationSystem.flowRates.find((fr) => fr.id == vm.fieldLayout.primaryFlowRateId)
    }
  },
  emits: ['change'],
  methods: {
    addSplitPoint(e) {
      const latLng = new LatLng(e.latLng.lat(), e.latLng.lng())

      const waterSourceLocation = this.irrigationSystem
        ? this.irrigationSystem.waterSourceLocation
        : null

      const newSplitPointLatLngs = this.fieldLayout.furrowSetDetails.splitPointLatLngs.concat([
        latLng
      ])
      const currentFurrowSetDetails = this.fieldLayout.furrowSetDetails

      const newFurrowSetDetails =
        new FurrowSetAlg().buildFurrowSetDetailsFromSplitPointLatLngsAndSplitPaths(
          newSplitPointLatLngs,
          currentFurrowSetDetails.splitPaths,
          this.splitBearing,
          this.fieldLayout.path,
          waterSourceLocation
        )

      const newFieldLayout = JSON.parse(JSON.stringify(this.fieldLayout))
      newFieldLayout.furrowSetDetails = newFurrowSetDetails

      this.$emit('change', newFieldLayout)
    },
    updatePreviewOfSetsToBeAdded(e) {
      let latLng = new LatLng(e.latLng.lat(), e.latLng.lng())

      let pointGeo = GeoUtil.LatLngs.toGeoJsonPoint(latLng)

      if (!GeoUtil.GeoJson.booleanContains(this.fieldGeo, pointGeo)) {
        return
      }

      let splitLine = GeoUtil.GeoJson.getInteriorSplitLineAlongBearingFromPoint(
        this.fieldGeo,
        this.splitBearing,
        pointGeo
      )

      if (splitLine == null) {
        return
      }

      this.furrowSetToSplit = this.fieldLayout.furrowSetDetails.resultingFurrowSets.find(
        (furrowSet) => {
          let furrowSetGeo = GeoUtil.LatLngs.toPolygon(furrowSet.path)
          return GeoUtil.GeoJson.booleanContains(furrowSetGeo, pointGeo)
        }
      )

      let geoToSplit =
        this.furrowSetToSplit == null
          ? this.fieldGeo
          : GeoUtil.LatLngs.toPolygon(this.furrowSetToSplit.path)

      let splitGeos = GeoUtil.GeoJson.interiorSplitGeoAlongBearingFromPoint(
        geoToSplit,
        this.splitBearing,
        pointGeo
      )

      if ((! splitGeos) || (splitGeos.length < 2)) {
        return
      }

      splitGeos.forEach(geo => {
        geo.properties.areaInAcres = GeoUtil.GeoJson.areaInAcres(geo)
      })

      splitGeos.sort((a, b) => (b.properties.areaInAcres - a.properties.areaInAcres))

      {
        const preciseSplitLine = GeoUtil.GeoJson.getInteriorSplitLineAlongBearingFromPoint(
          geoToSplit,
          this.splitBearing,
          pointGeo
        )
        this.splitLineLatLngs = GeoUtil.GeoJson.toLatLngs(preciseSplitLine)
      }

      let geoArea0 = GeoUtil.GeoJson.area(splitGeos[0])
      let geoArea1 = GeoUtil.GeoJson.area(splitGeos[1])

      let smallerGeo = null
      let largerGeo = null
      let smallerArea = null
      let largerArea = null
      if (geoArea0 > geoArea1) {
        largerGeo = splitGeos[0]
        smallerGeo = splitGeos[1]
        largerArea = geoArea0
        smallerArea = geoArea1
      } else {
        largerGeo = splitGeos[1]
        smallerGeo = splitGeos[0]
        largerArea = geoArea1
        smallerArea = geoArea0
      }

      let smallerAreaPercent = Math.round((100.0 * smallerArea) / this.fieldArea)
      let largerAreaPercent = Math.round((100.0 * largerArea) / this.fieldArea)

      this.smallerSplitPolyLabel = smallerAreaPercent + '%'
      this.largerSplitPolyLabel = largerAreaPercent + '%'

      this.smallerSplitPolyPath = GeoUtil.GeoJson.toLatLngs(smallerGeo)
      this.largerSplitPolyPath = GeoUtil.GeoJson.toLatLngs(largerGeo)
    }
  }
}
</script>

<style lang="css" module>
.smaller_furrow_set_split_label {
  color: yellow;
  position: absolute;
  margin-top: 2.5em;
}

.larger_furrow_set_split_label {
  color: yellow;
  position: absolute;
  margin-top: 2.5em;
}
</style>

<template>
  <template>
    <add-split-point-mode v-if="addingSplitPoint" @change="splitPointAdded" :farm="farm" :field="field"
      :fieldLayout="workingFieldLayout" />

    <edit-split-points-mode v-if="editingSplitPoints" @change="splitPointMoved" :farm="farm" :field="field"
      :fieldLayout="workingFieldLayout" />

    <calculate-split-points-mode v-if="calculatingSplitPoints" :farm="farm" :fromTheWizard="fromWizard" :field="field"
      :fieldLayout="workingFieldLayout" @change="calculatorDone" @cancel="enterEditFurrowSetsMode" />

    <!-- <pp4-field 
        :field="field"
        :fieldLayout="workingFieldLayout" 
        :clickable="false"
        selectionStyle="selected"
        :showLabel="false"
        :showFurrowDirection="false"/> -->

    <context-menu>
      <icon-button label="Calculator" :active="calculatingSplitPoints"
        description="Split Field Into Sets Based on Flow Rate and Watering Needs" faIcon="fa-calculator"
        @click="enterCalculateMode" />

      <toggle-button label="Add Set" description="Add Set" class="button_margin_top" :active="addingSplitPoint"
        @click="enterAddFurrowSetMode"><img :src="AddFurrowSetIcon" :draggable="false" /></toggle-button>
      <toggle-button label="Edit Sets" description="Edit Sets" class="button_margin_top" :active="editingSplitPoints"
        @click="enterEditFurrowSetsMode"><img :src="EditFurrowSetsIcon" :draggable="false" /></toggle-button>
      <icon-button label="Erase Sets" description="Erase Sets" faIcon="fa-eraser" class="button_margin_top"
        @click="eraseFurrowSets" />

      <template v-if="showSaveAndCancelButtons">
        <icon-button label="Save" description="Save" class="button_group_margin_top" v-bind:disabled="!saveActive"
          :useSaveIcon="true" @click="save" />
        <icon-button label="Cancel" description="Cancel" class="button_margin_top" :useCancelIcon="true"
          @click="cancel" />
      </template>
    </context-menu>
  </template>
</template>

<script>
import AddSplitPointMode from '@/components/maps/edit_furrow_sets/AddSplitPointMode'
import EditSplitPointsMode from '@/components/maps/edit_furrow_sets/EditSplitPointsMode'
import CalculateSplitPointsMode from '@/components/maps/edit_furrow_sets/CalculateSplitPointsMode'
import { FurrowSetAlg } from '@/geo/FurrowSetAlg'
import * as WateringTimeAlg from '@/util/WateringTimeAlg'
import { GeoUtil } from '@/geo/GeoUtil'

import AddFurrowSetIcon from '@/assets/add_furrow_set_icon.svg'
import EditFurrowSetsIcon from '@/assets/edit_furrow_sets_icon.svg'
import GuidanceLinesIcon from '@/assets/guidance_lines.svg'

export const EditFurrowSetsMode = {
  Edit: 1,
  Add: 2,
  Calculate: 3
}
Object.freeze(EditFurrowSetsMode)

export default {
  props: {
    farm: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    fieldLayout: {
      type: Object,
      required: true
    },
    primaryFlowRateId: {
      type: String,
      required: false
    },
    showSaveAndCancelButtons: {
      type: Boolean,
      required: false,
      default: true
    },
    fromWizard: {
      type: Boolean,
      required: false,
      default: false
    },
    startingMode: {
      type: Number,
      required: false,
      default: EditFurrowSetsMode.Add
    }
  },
  components: {
    AddSplitPointMode,
    EditSplitPointsMode,
    CalculateSplitPointsMode
  },
  data: function () {
    return {
      mode: EditFurrowSetsMode.Add,
      workingFieldLayout: null,
      EditFurrowSetsIcon,
      AddFurrowSetIcon,
      GuidanceLinesIcon
    }
  },
  computed: {
    jdAvailable: (vm) => (vm.field?.jd ? true : false),
    saveActive: (vm) => {
      return JSON.stringify(vm.workingFieldLayout) !== JSON.stringify(vm.fieldLayout)
    },
    addingSplitPoint: (vm) => vm.mode === EditFurrowSetsMode.Add,
    editingSplitPoints: (vm) => vm.mode === EditFurrowSetsMode.Edit,
    calculatingSplitPoints: (vm) => vm.mode === EditFurrowSetsMode.Calculate,
    splitBearing: (vm) => {
      return vm.fieldLayout.perpendicularSets
        ? GeoUtil.bearingPerpendicular(vm.fieldLayout.furrowBearing)
        : vm.fieldLayout.furrowBearing
    },
    primaryWaterSourceLocation: (vm) => {
      const primaryIrrigationSystem = vm.farm.irrigationSystems.find((irrigationSystem) => {
        return irrigationSystem.flowRates.some(
          (flowRate) => flowRate.id === vm.fieldLayout.primaryFlowRateId
        )
      })

      return primaryIrrigationSystem ? primaryIrrigationSystem.waterSourceLocation : null
    },
    flowRateInGpm: (vm) => {
      for (let irrigationSystem of vm.farm.irrigationSystems) {
        // idk how this is working?
        for (let flowRate of irrigationSystem.flowRates) {
          if (flowRate.id === vm.primaryFlowRateId) {
            return flowRate.valueInGpm
          } else if (flowRate.id === vm.fieldLayout.primaryFlowRateId) {
            return flowRate.valueInGpm
          }
        }
      }

      return null
    },
    fieldLayoutToPreview: (vm) => {
      const ret = Object.assign({}, vm.fieldLayout)

      Object.assign(ret, vm.workingFieldLayout)
      Object.assign(ret.furrowSetDetails, vm.newFurrowSetDetails)

      return ret
    }
  },
  watch: {
    mode: 'updateHelpKey'
  },
  methods: {
    splitPointAdded(newFieldLayout) {
      this.workingFieldLayoutChanged(newFieldLayout)
      this.mode = EditFurrowSetsMode.Edit
    },
    splitPointMoved(newFieldLayout) {
      this.workingFieldLayoutChanged(newFieldLayout)
      this.mode = EditFurrowSetsMode.Edit
    },
    calculatorDone(newFieldLayout) {
      this.workingFieldLayoutChanged(newFieldLayout)
      this.mode = EditFurrowSetsMode.Edit
    },
    workingFieldLayoutChanged(newFieldLayout) {
      this.workingFieldLayout = { ...this.workingFieldLayout, ...newFieldLayout }

      if(! this.showSaveAndCancelButtons) {
        // we'll emit change events as they come in for this workflow--more of a v-model style
        this.$emit('change', this.workingFieldLayout)
      }
    },
    updateHelpKey() {
      const editFurrowSetsHelpTipParams = this.getHelpTipParams()
      const setsNeeded = editFurrowSetsHelpTipParams.setsNeeded
      const desiredWateringTime = this.workingFieldLayout.desiredWateringTimeInHours
      const wateringTime = editFurrowSetsHelpTipParams.wateringTime
      const currentSetAmount = this.workingFieldLayout.furrowSetDetails.resultingFurrowSets.length

      switch (this.mode) {
        case EditFurrowSetsMode.Add:
          this.$store.dispatch('mapObjects/setHelpKeyAndParams', {
            key: 'add_furrow_set',
            params: { setsNeeded, desiredWateringTime, wateringTime, currentSetAmount }
          })
          break
        case EditFurrowSetsMode.Edit:
          this.$store.dispatch('mapObjects/setHelpKeyAndParams', {
            key: 'edit_furrow_sets',
            params: { setsNeeded, desiredWateringTime, wateringTime, currentSetAmount }
          })
          break
        default:
          this.$store.dispatch('mapObjects/setHelpKey', null)
      }
    },
    getHelpTipParams() {
      let waterableAreaInAcres = (GeoUtil.GeoJson.area(GeoUtil.LatLngs.toPolygon(this.fieldLayout.path))) * 0.0002471052
      let fieldLayout = {
        grossAppliedInches: this.fieldLayout.grossAppliedInches,
        alternatingFurrows: this.fieldLayout.alternatingFurrows,
        desiredWateringTimeInHours: this.fieldLayout.desiredWateringTimeInHours
      }
      let flowRate = {
        valueInGpm: this.flowRateInGpm
      }
      let setsNeeded = WateringTimeAlg.setsNeededBasedOnWateringTime(waterableAreaInAcres, flowRate, fieldLayout)
      let wateringTime = parseFloat(WateringTimeAlg.onlyFormattedWateringTime(waterableAreaInAcres, flowRate, fieldLayout))

      return { setsNeeded, wateringTime }
    },
    enterCalculateMode() {
      this.mode = EditFurrowSetsMode.Calculate
    },
    enterAddFurrowSetMode() {
      this.mode = EditFurrowSetsMode.Add
    },
    enterEditFurrowSetsMode() {
      this.mode = EditFurrowSetsMode.Edit
    },
    eraseFurrowSets() {
      const newFieldLayout = JSON.parse(JSON.stringify(this.workingFieldLayout))
      newFieldLayout.furrowSetDetails.splitPointLatLngs = []

      const newFurrowSetDetails =
        new FurrowSetAlg().buildFurrowSetDetailsFromSplitPointLatLngsAndSplitPaths(
          [],
          newFieldLayout.furrowSetDetails.splitPaths,
          this.splitBearing,
          this.fieldLayout.path,
          this.primaryWaterSourceLocation
        )

      newFieldLayout.furrowSetDetails = newFurrowSetDetails

      this.workingFieldLayoutChanged(newFieldLayout)
    },
    async save() {
      this.$emit('change', this.workingFieldLayout)
    },
    cancel() {
      this.$emit('cancel')
    }
  },
  emits: ['change', 'cancel'],
  beforeMount() {
    this.workingFieldLayout = JSON.parse(JSON.stringify(this.fieldLayout))
    this.mode = this.startingMode
  }
}
</script>

<style lang="css"></style>

<template>
    <div class="jd-fields-div">
        <template v-for="client in clientsWithMetadata" :key="`jdfields:${client.id}`">
            <template v-for="fwm in client.farmsWithMetadata" :key="`jdfields:${client.id}:${fwm.farm.id}`">

                <fieldset class="client-farm-fieldset">
                    <legend>{{ client.client.name }} / {{ fwm.farm.name }}</legend>
                    <div style="display: flex;  flex-direction: row; justify-content: center;">
                        <div class="fields-wrapper-div">
                            <template v-for="field in fwm.fields" :key="`jdfields:${fwm.farm.id}:${field.id}`">
                                <jd-boundary v-for="b in field.boundaries"
                                    :key="b.id" :field="field" :boundary="b"
                                    :organizationId="props.organizationId"
                                    :selectedOrganizationHasJdAccess="props.selectedOrganizationHasJdAccess"
                                    :ppFarm="props.ppFarm" />
                            </template>

                            <!-- TODO: all pipe planner fields that are no longer mapped to a jd object -->
                        </div>
                    </div>
                </fieldset>
            </template>
        </template>
    </div>
</template>

<script setup>
import JdBoundary from '@/components/jd/JdBoundary.vue'
import { computed } from 'vue'

const props = defineProps({
    organizationId: {
        type: String,
        required: true
    },
    fields: {
        type: Array,
        required: true
    },
    ppFarm: {
        type: Object,
        required: false,
        default: null
    },
    selectedOrganizationHasJdAccess: {
        type: Boolean,
        required: true,
        default: false
    }
})

const clientsWithMetadata = computed(() => {
    const byId = {}

    props.fields.forEach(field => {
        field.clients.forEach(c => {
            if (!Object.hasOwn(byId, c.id)) {
                byId[c.id] = {
                    client: c,
                    farmsWithMetadataById: {},
                    farmsWithMetadata: []
                }
            }

            const val = byId[c.id]

            field.farms.forEach(f => {
                if (!Object.hasOwn(val.farmsWithMetadataById, f.id)) {
                    val.farmsWithMetadataById[f.id] = {
                        farm: f,
                        fields: []
                    }
                }

                val.farmsWithMetadataById[f.id].fields.push(field)
            })
        })
    })

    const ret = Object.values(byId)

    ret.forEach(val => {
        val.farmsWithMetadata = Object.values(val.farmsWithMetadataById)
        val.farmsWithMetadata.sort((a, b) => a.farm.name.localeCompare(b.farm.name))

        val.farmsWithMetadata.forEach(f => {
            f.fields.sort((a, b) => a.name.localeCompare(b.name))
        })
    })

    ret.sort((a, b) => a.client.name.localeCompare(b.client.name))

    return ret
})
</script>

<style lang="css">
.jd-fields-div {
    display: flex;
    flex-direction: column;
}

.client-farm-fieldset {
    font-weight: bold;
    font-size: larger;
    font-style: italic;
    color: var(--jd-yellow);
    padding-left: var(--general-padding);
    padding-right: var(--general-padding);
    padding-bottom: var(--general-padding);
    padding-top: 0px;
    border: var(--general-border-width) solid var(--jd-yellow);
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: calc(var(--general-padding) * 3);
}

.client-farm-fieldset legend {
    font-size: large;
    color: var(--jd-yellow);
}

.fields-wrapper-div {
    display: flex;
    /* float: left; */
    flex-direction: row;
    flex-grow: 0;
    flex-wrap: wrap;
    justify-self: center;
    align-self: center;
    justify-content: center;
    /* flex-direction: row;
    flex-wrap: wrap;*/
    row-gap: var(--general-padding);
    column-gap: var(--general-padding);
}

.client-farm-fieldset-buttons {
    display: flex;
    flex-direction: row;
}
</style>

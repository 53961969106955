import Vars from '@/Vars'

let PdfMakeInstance = null

async function GetPdfMake() {
    if(PdfMakeInstance) {
        return PdfMakeInstance
    }

    const PdfMake = () => import('pdfmake/build/pdfmake.min.js')
    //const PdfFonts = () => import('pdfmake/build/vfs_fonts.js')

    PdfMakeInstance = (await PdfMake()).default
    //this.pdfFonts = (await PdfFonts()).default
    //this.pdfMake.vfs = this.pdfFonts.pdfMake.vfs
    const fontUrl = (filename) => {
        return window.location.origin + '/fonts/' + filename
    }

    // I think we can use this to get away with not having that vfs object..
    PdfMakeInstance.fonts = {
        'Roboto': {
            normal: fontUrl('Roboto-Regular.ttf'),
            bold: fontUrl('Roboto-Medium.ttf'),
            italics: fontUrl('Roboto-Regular.ttf'),
            bolditalics: fontUrl('Roboto-Regular.ttf')
        }
    }

    return PdfMakeInstance
}

export default class Printer {    
    static GetPdfMake = GetPdfMake
    static HeaderImage = null

    getDisplayDateTimeString(d) {
        const dateValue = new Date(d);
        return (
          dateValue.toLocaleDateString() + " " + dateValue.toLocaleTimeString()
        );
    }

    async getBase64ImageFromURL(url) {
        return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");
        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            var dataURL = canvas.toDataURL("image/png");
            resolve(dataURL);
        };
        img.onerror = error => {
            reject(error);
        };
        img.src = url;
        });
    }

    async getImageAsSvg(url) {
        const res = await fetch(url)
        return await res.text()
    }

    async getHeaderImage() {
        if(! this.HeaderImage) {
            this.HeaderImage = await this.getImageAsSvg('/DPREV_bg0.svg')
        }

        return this.HeaderImage
    }

    async buildDefaultHeader() {
        return {     
            columns: [
                {
                    svg: await this.getHeaderImage(),
                    margin: [40 , 40, 0, 0],
                    width: 256 * 0.7487922705314,
                    height: 32 * 0.7487922705314,
                    noWrap: true
                    // width: 1024, height: 128
                    //margin: [40, 32]
                    // margin: [40, 32, 0, 18], // left top right bot
                }
            ]
        }
    }

    buildColumnHeader(s, o={}) {
        return Object.assign(
            {text: s, bold: true, noWrap: true, margin: [0, 0], alignment: 'right'}, o)
    }

    buildFooterCallback() {
        return function(currentPage, pageCount, _pageSize) {     
            return [
                {
                    canvas: [
                        {
                            type: 'polyline',
                            lineWidth: 3,
                            lineColor: Vars.bg1,
                            closePath: false,
                            points: [{ x: 40, y: 0 }, { x: 555, y: 0 }]
                        }
                    ]
                },
                {
                    columns: [
                        { image: 'emailIcon', width: 10, margin: [ 40, 12, 5, 0 ] },
                        { text: 'sales@deltapl.com', margin: [ 45, 10, 0, 0 ],  width: 250},
                        { image: 'phoneIcon', alignment: 'right', width: 3, margin: [ 0, 12, 3, 0 ] },
                        { text: '501-490-0347', alignment: 'left', margin: [ 0, 10, 0, 0 ]  },
                        { text: currentPage.toString() + ' of ' + pageCount, alignment: 'right', margin: [ 0, 10, 40, 0 ]  },
                    ]
                }
            ]
        }
    }

    buildDefaultPageMargins() {
        return [ 40, 68, 40, 60 ] // left top right bot
    }

    async buildDefaultImages() {
        return {
            emailIcon: await this.getBase64ImageFromURL('/mail_64x64.png'),
            phoneIcon: await this.getBase64ImageFromURL('/telephone_64x64.png'),
            gatePosition: await this.getBase64ImageFromURL('/Blue_Gate_Position.jpg'),
        }
    }
}

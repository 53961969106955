<template>
  <div>
    <fullpageform title="Admin Reports" cancelLabel="Cancel" @cancel="cancel">
      <div class="body">
        <label class="report_label" @click="reportClicked('userAcre')"> <i v-show="this.reportLoading == 'userAcre'"
            class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i> Acres by User </label>
        <label class="report_label" @click="reportClicked('adminAcre')"> <i v-show="this.reportLoading == 'adminAcre'"
            class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i> Acres by Admin </label>
        <label class="report_label" @click="reportClicked('flowratesByAcre')"> <i
            v-show="this.reportLoading == 'flowratesByAcre'" class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i> Flow
          Rates by State and County </label>
        <label class="report_label" @click="reportClicked('acreByStateAndCounty')"> <i
            v-show="this.reportLoading == 'acreByStateAndCounty'" class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
          Acres By State and County </label>
        <label class="report_label" @click="reportClicked('usageMoistureMethod')"> <i
            v-show="this.reportLoading == 'usageMoistureMethod'" class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
          Soil Moisture Usage </label>
        <label class="report_label" @click="reportClicked('usageSurgeValveMethod')"> <i
            v-show="this.reportLoading == 'usageSurgeValveMethod'" class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
          Surge Valve Usage </label>
        <label class="report_label" @click="reportClicked('usageShapefileMethod')"> <i
            v-show="this.reportLoading == 'usageShapefileMethod'" class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>
          Shape File Usage </label>
        <label class="report_label" @click="reportClicked('userAccounts')"> <i
            v-show="this.reportLoading == 'userAccounts'" class="fa fa-circle-o-notch fa-spin fa-1x fa-fw"></i>User
          Accounts</label>
      </div>
    </fullpageform>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      reportLoading: null
    }
  },
  computed: {
    reports: (vm) => vm.$store.state.reports
  },
  methods: {
    cancel: function () {
      this.$dialog.pop()
    },
    async reportClicked(report) {
      this.reportLoading = report
      await this.$store.dispatch('getReport', report)
      let csvName = null
      switch (report) {
        case 'userAcre':
          csvName = 'user_acre_report.csv'
          break;
        case 'adminAcre':
          csvName = 'admin_acre_report.csv'
          break;
        case 'flowratesByAcre':
          csvName = 'flowrate_by_state.csv'
          break;
        case 'acreByStateAndCounty':
          csvName = 'acre_by_state.csv'
          break;
        case 'usageMoistureMethod':
          csvName = 'soil_moisture_usage.csv'
          break;
        case 'usageSurgeValveMethod':
          csvName = 'surge_valve_usage.csv'
          break;
        case 'usageShapefileMethod':
          csvName = 'shape_file_usage.csv'
          break;
        case 'userAccounts':
          csvName = 'pp4_user_accounts.csv'
      }
      if (this.reports) {
        let link = document.createElement('a')
        link.href = URL.createObjectURL(this.reports)
        link.download = csvName
        link.target = "_blank"
        link.type = "text/csv"

        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        this.reportLoading = null
      }
    }
  },
  mounted: function () {
  },


}
</script>

<style lang="css" scoped>
.body {
  background-color: var(--primary-dark);
  color: #444;
  padding: 18px;
  border-radius: var(--general-border-radius);
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  clear: both;
  min-width: 80%;
  display: flex;
  flex-direction: column;
}

.report_label {
  text-align: center;
  margin-top: 4px;
}
</style>
<template>
    <fullpageform title="Hole Design" @cancel="cancel">
        <template v-slot:float-right>
            <div class="action_buttons">
                <a class="button" @click="showShareHoleDesignDialog(rolledUpHoleDesigns)"><i
                        class="fa fa-share"></i>Share</a>
                <a class="button widget_margin_left mobile-hide" :disabled="rolledUpHoleDesigns.length < 1"
                    v-on:click="CreatePDF('print')"><i class="fa fa-print fa_button_icon"></i> Print</a>
                <a class="button widget_margin_left" :disabled="rolledUpHoleDesigns.length < 1"
                    v-on:click="CreatePDF('save')"><i class="fa fa-save fa_button_icon"></i> Download</a>
                <a class="button widget_margin_left mobile-hide" :disabled="rolledUpHoleDesigns.length < 1"
                    v-on:click="CreatePDF('preview')"><i class="fa fa-file fa_button_icon"></i> Preview</a>
                <a class="button widget_margin_left" v-on:click="cancel()"><i class="fa fa-times fa_button_icon"></i></a>
            </div>
        </template>

        <div class="printable_pages_div">
            <div v-if="rolledUpHoleDesigns.length === 0">No Hole Designs Available</div>

            <div v-for="rolledUpHoleDesign in rolledUpHoleDesigns" :key="rolledUpHoleDesign.id">
                <hole-design-page class="flex_hole_design_page" :farm="farm" :rolledUpHoleDesign="rolledUpHoleDesign" />
            </div>
        </div>

        <loading v-if="processing" label="Calculating..."/>
    </fullpageform>
</template>

<script setup>
import { ref, watch, inject, computed } from 'vue'
import useSWRV from 'swrv'

import * as JdUtil from '@/util/JdUtil'
import RollupAlg from '@/design/RollupAlg'
import HoleDesignPage from "@/components/HoleDesignPage.vue"
import ShareHoleDesignDialog from '@/components/ShareHoleDesignDialog'
import HoleDesignPrinter from '@/print/HoleDesignPrinter'

const props = defineProps(['farm', 'field', 'furrowSet', 'filter']) // 'filter' is a callback that accepts (field, pipePath)

const processing = ref(false)

const $store = inject('$store')
const $dialog = inject('$dialog')
const $api = inject('$api')
const $staticImages = inject('$staticImages')
const $holeDesignsProcessor = inject("$holeDesignsProcessor")

watch(() => $holeDesignsProcessor.processing, (newValue) => {
    if(! newValue) {
        // delay this so that the user can see the "Calculating..." message
        setTimeout(() => {
            processing.value = false
        }, 300)
    }
    else {
        processing.value = newValue
    }
})

const jdFarmFieldBoundariesUrl = computed(() => {
    if (!props.farm?.jd?.organizationId) {
        return 'null' // !! swrv does not react to null values, use empties
    }

    return `/api/jd/organizations/${props.farm.jd.organizationId}/farm-field-boundaries`
})

const jdOrganizationsUrl = computed(() => {
    if (!props.farm?.jd?.organizationId) {
        return 'null' // !! swrv does not react to null values, use empties
    }

    return `/api/jd/organizations`
})

const jdBoundariesResult = useSWRV(jdFarmFieldBoundariesUrl, async (url) => {
    if ((!url?.length) || (url === "null")) { // !! swrv does not react to null values, use empties
        return []
    }

    const result = await $api.fetch(url, { method: "GET" })

    return await result.json()
}, { defaultValue: null, revalidateOnFocus: false })

const jdOrganizationsResult = useSWRV(jdOrganizationsUrl, async (url) => {
    if ((!url?.length) || (url === "null")) { // !! swrv does not react to null values, use empties
        return []
    }

    const result = await $api.fetch(url, { method: "GET" })

    return await result.json()
}, { defaultValue: null, revalidateOnFocus: false })

const jdObjects = computed(() => {
    const boundariesValue = jdBoundariesResult?.data?.value
    if (!boundariesValue) {
        return JdUtil.buildFarmFieldBoundariesObjectMaps() //empty it
    }
    const orgsValue = jdOrganizationsResult?.data?.value
    if(! orgsValue) {
        return JdUtil.buildFarmFieldBoundariesObjectMaps() //empty it
    }    

    return JdUtil.buildFarmFieldBoundariesObjectMaps(orgsValue, boundariesValue)
})

const rolledUpHoleDesigns = computed(() => {
    const farm = props.farm
    if (farm == null) {
        return []
    }

    const selectedField = props.field;
    
    const ret = farm.irrigationSystems.reduce((accum, irrigationSystem) => {
        return irrigationSystem.pipePaths.reduce((accum, pipePath) => {
            if (pipePath.zombie) {
                return accum
            }

            if (selectedField) {
                if (selectedField.id !== pipePath.fieldId) {
                    return accum
                }
            }

            const fieldForPipePath = farm.fields.find(
                (f) => f.id === pipePath.fieldId)
            if (!fieldForPipePath) {
                console.warn('Field Not Found: ' + pipePath.fieldId)
                console.dir(pipePath)
                return accum
            }

            if (props.filter) {
                if (!props.filter(fieldForPipePath, pipePath)) {
                    return accum
                }
            }

            const layoutForPipePath = fieldForPipePath.layouts.find(
                (layout) => layout.id == pipePath.fieldLayoutId)
            if (!layoutForPipePath) {
                console.warn('Field Layout Not Found: ' + fieldForPipePath.activeLayoutId)
                return accum
            }

            if(layoutForPipePath.needsWizard) {
                return accum
            }

            if (layoutForPipePath.id !== fieldForPipePath.activeLayoutId) {
                return accum
            }

            let furrowSet = null
            if (pipePath.furrowSetId) {
                furrowSet = layoutForPipePath.furrowSetDetails.resultingFurrowSets.find(
                    (resultingFurrowSet) => resultingFurrowSet.id === pipePath.furrowSetId)
                if (!furrowSet) {
                    console.warn('Furrow Set Not Found: ' + pipePath.furrowSetId)
                    return accum
                }
            }

            const designParamsValid =
                (pipePath.designParams != null)
                && (pipePath.designParams.holeDesign != null)
            if (!designParamsValid) {
                return accum
            }

            const rolledUpHoleDesign = RollupAlg.buildRolledUpHoleDesignFromPipePath(
                farm, fieldForPipePath, layoutForPipePath, irrigationSystem, pipePath, furrowSet, jdObjects.value)

            if (rolledUpHoleDesign) {
                rolledUpHoleDesign.wateringEvents =
                    $store.getters['wateringEvents/pipePathEvents'](pipePath.id)
                rolledUpHoleDesign.pipeImage = $staticImages.buildFieldPipePathUrl(layoutForPipePath, pipePath)
                accum.push(rolledUpHoleDesign)
            }

            return accum
        }, accum)
    }, [])

    function sortByFieldThenFurrowSet(a, b) {
        let cmp = a.field.name.localeCompare(b.field.name)
        if (cmp !== 0) {
            return cmp
        }

        if (a.furrowSet && b.furrowSet) {
            return a.furrowSet.name.localeCompare(b.furrowSet.name)
        }

        return 0
    }

    ret.sort(sortByFieldThenFurrowSet)

    return ret
})

function cancel() {
    $dialog.pop()
}

async function buildPdfBlob() {
    let holeDesignPrinter = new HoleDesignPrinter()
    let blob = await holeDesignPrinter.CreatePDF('blob', rolledUpHoleDesigns.value, jdObjects.value)
    let fileName = await holeDesignPrinter.getPdfFileName(rolledUpHoleDesigns.value)

    return { blob, fileName }
}

async function showShareHoleDesignDialog() {
    const holeDesignPdfAndFileName = await buildPdfBlob()

    await $dialog.push(ShareHoleDesignDialog, {
        holeDesignPdf: holeDesignPdfAndFileName.blob,
        fileName: holeDesignPdfAndFileName.fileName
    })
}

async function CreatePDF(mode) {
    let holeDesignPrinter = new HoleDesignPrinter()
    holeDesignPrinter.CreatePDF(mode, rolledUpHoleDesigns.value)
}
</script>

<style lang="css">
@media (max-width: 720px) {
    .mobile-hide {
        display: none !important;
    }
}

@media (min-width: 720px) {
    .mobile-show {
        display: none !important;
    }
}

.printable_pages_div {
    display: flex;
    flex-direction: column;
    column-gap: calc(var(--general-padding) * 2);
    row-gap: calc(var(--general-padding) * 2);
    overflow: auto;
}

.design_user_is_viewing_hint {
    position: relative;
    top: 0em;
    left: auto;
    right: auto;
    background: red;
    color: green;
}

.toolbar-top {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25em;
    background: var(--primary-dark);
    vertical-align: middle;
}

.action_buttons {
    float: right;
}
</style>

import { GeoUtil } from '@/geo/GeoUtil'

export function buildFarmFieldBoundariesObjectMaps(orgs, farmFieldBoundaries) {
    const organizations = {}
    const farms = {}
    const clients = {}
    const fields = {}
    const boundaries = {}

    if(orgs?.length) {
        orgs.forEach(org => {
            organizations[org.id] = org 
        })
    }

    if(farmFieldBoundaries?.fields?.length) {
        farmFieldBoundaries.fields.forEach(field => {
            fields[field.id] = field

            field.boundaries.forEach(b => {
                boundaries[b.id] = b
            })            

            field.clients.forEach(c => {
                clients[c.id] = c
            })

            field.farms.forEach(f => {
                farms[f.id] = f
            })
            
        })
    }

    return {
        organizations, farms, clients, fields, boundaries
    }
}

export function guidanceLinesToPaths(apiJsonResponse) {
    const curves = apiJsonResponse.values

    let curvePaths = []
    curves.forEach(c => {
        if (c['@type'] === 'AdaptiveCurve') {
            c.shapes.forEach(shape => {
                const path = shape.points.map(p => {
                    return { lat: p.lat, lng: p.lon }
                })

                path.id = c.id
                path.name = c.name
                path.type = c['@type']

                curvePaths.push(path)
            })
        }
        else if (c['@type'] === 'ABLine') {
            const path = [
                { lat: c.aPoint.lat, lng: c.aPoint.lon },
                { lat: c.bPoint.lat, lng: c.bPoint.lon }
            ]

            path.id = c.id
            path.name = c.name || "Unnamed"
            path.type = c['@type']

            curvePaths.push(path)
        }
        else if (c['@type'] === 'ABCurve') {
            c.shapes.forEach(shape => {
                if (shape['@type'] !== 'MultiPoint') {
                    return
                }

                const path = shape.points.map(p => {
                    return { lat: p.lat, lng: p.lon }
                })

                path.id = c.id
                path.name = c.name
                path.type = c['@type']

                curvePaths.push(path)
            })
        }
        else {
            console.warn("Unhandled Guidance Line Type: " + c['@type'])
        }
    })

    const simplifiedPaths = curvePaths.map(path => {
        try {
            const ret = GeoUtil.LatLngs.simplify(path, { tolerance: 0.00004 })
            ret.id = path.id
            ret.name = path.name
            ret.type = path.type
            return ret
        }
        catch(e) {
            console.warn("Simplify Guidance Failed: " + e)
            return path
        }
    })

    return simplifiedPaths
}

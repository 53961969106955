/*

Place all turf calls here.  We'll try and import individual 
modules when we can.

*/

import turfDistance from '@turf/distance'
import turfDestination from '@turf/destination'
import turfBbox from '@turf/bbox'
import turfBboxPolygon from '@turf/bbox-polygon'
import turfArea from '@turf/area'
import turfInterpolate from '@turf/interpolate'
import turfBearing from '@turf/bearing'
import turfKinks from '@turf/kinks'
import turfPointGrid from '@turf/point-grid'
import turfLength from '@turf/length'
import turfMidpoint from '@turf/midpoint'
import turfLineSplit from '@turf/line-split'
import turfLineIntersect from '@turf/line-intersect'
import turfLineSlice from '@turf/line-slice'
import turfLineChunk from '@turf/line-chunk'
import polygonToLine from '@turf/polygon-to-line'
import nearestPointOnLine from '@turf/nearest-point-on-line'
import turfNearestPoint from '@turf/nearest-point'
import turfPointToLineDistance from '@turf/point-to-line-distance'
import { lineString, bearingToAzimuth as turfBearingToAzimuth, multiPolygon as turfMultiPolygon, polygon as turfPolygon, point as turfPoint, featureCollection as turfFeatureCollection, multiPolygon} from '@turf/helpers'
import { getCoord, getCoords } from '@turf/invariant'
import { coordEach as turfCoordEach, segmentEach as turfSegmentEach, segmentReduce as turfSegmentReduce, featureEach as turfFeatureEach, coordAll, coordReduce, flattenReduce } from '@turf/meta'
import turfFlatten from '@turf/flatten'
import simplify from '@turf/simplify'
import turfBooleanDisjoint from '@turf/boolean-disjoint'
import turfBooleanPointInPolygon from '@turf/boolean-point-in-polygon'
import turfBooleanPointOnLine from '@turf/boolean-point-on-line'
import turfPointsWithinPolygon from '@turf/points-within-polygon'
import turfBooleanWithin from '@turf/boolean-within'
import turfCleanCoords from '@turf/clean-coords'
import turfTruncate from '@turf/truncate'
import turfCentroid from '@turf/centroid'
import turfCenterOfMass from '@turf/center-of-mass'
import turfBooleanOverlap from '@turf/boolean-overlap'
import turfBooleanContains from '@turf/boolean-contains'
import turfBooleanCrosses from '@turf/boolean-crosses'
import turfBooleanIntersects from '@turf/boolean-intersects'
import turfUnkinkPolygon from '@turf/unkink-polygon'
import turfClustersKmeans from '@turf/clusters-kmeans'
import turfClustersDbscan from '@turf/clusters-dbscan'
import turfPointOnFeature from '@turf/point-on-feature'
import turfAlong from '@turf/along'
import turfLineToPolygon from '@turf/line-to-polygon'
import buffer from '@turf/buffer'
import envelope from '@turf/envelope'

import turfDifference from '@turf/difference'

import { LatLng, Direction } from '../store/Dto'

const OneTenthOfOneMeterInLatLng = 0.000000898311 //I think...

class GeoUtil {
    static Coords = {
        toLatLng(coord) {
            return new LatLng(coord[1], coord[0])
        },

        toLatLngs(coords) {
            return coords.map((c) => {
                return new LatLng(c[1], c[0])
            })
        },

        toPoint(coord) {
            return turfPoint(coord)
        },

        toLineString(coords, props, options) {
            return lineString(coords, props, options)
        },

        equal(c1, c2) {

            if(c1 == null && c2 == null) {
                return true
            }

            if(c1 == null || c2 == null) {
                return false
            }

            return c1[0] == c2[0] && c1[1] == c2[1]
        },

        bearing(c1, c2) {
            return turfBearing(c1, c2)
        },

        distance(c1, c2, options) {
            return turfDistance(c1, c2, options)
        },

        midpoint(c1, c2) {
            return turfMidpoint(c1, c2)
        },

        isLineCoordArray(arr) {
            return (arr.length >= 2) && (arr[0].length == 2) 
                && Number.isFinite(arr[0][0]) && Number.isFinite(arr[0][1])
        },
    
        isPolygonCoordArray(arr) {
            return GeoUtil.Coords.isLineCoordArray(arr) 
                && (GeoUtil.Coords.equal(arr[0], arr[arr.length - 1]))
                && arr.length >= 4
        },

        isPolygon(arr) {
            return arr.every(GeoUtil.Coords.isPolygonCoordArray)
        },

        isMultiPolygon(arr) {
            return arr.every(GeoUtil.Coords.isPolygon)
        },

        isPoint(arr) {
            return (arr?.length === 2) && (Number.isFinite(arr[0]) && Number.isFinite(arr[1]))
        },

        toGeoJson(coords) {
            if(GeoUtil.Coords.isPolygon(coords)) {
                return turfPolygon(coords)
            }
            else if(GeoUtil.Coords.isPoint(coords)) {
                return turfPoint(coords)
            }
            else if(GeoUtil.Coords.isMultiPolygon(coords)) {
                return coords.length == 1 ? turfPolygon(coords[0]) : turfMultiPolygon(coords)    
            }
            else if(GeoUtil.Coords.isLineCoordArray(coords)) {
                return lineString(coords)
            }
            
            return null
        },

        destination(c, distance, bearing, options) {
            return turfDestination(c, distance, bearing, options)
        }
    }

    static LatLngs = {
        toCoord(latLng) {
            return [latLng.lng, latLng.lat]
        },

        toCoords(latLngs) {
            return latLngs.map((ll) => {
                return [ll.lng, ll.lat]
            })
        },

        pointsEqual(ll1, ll2, threshold) {
            if(ll1 == null && ll2 == null) {
                return true
            }

            if(ll1 == null || ll2 == null) {
                return false
            }

            if(! threshold) {
                threshold = OneTenthOfOneMeterInLatLng
            }

            return (Math.abs(ll1.lat - ll2.lat) < threshold) 
                && (Math.abs(ll1.lng - ll2.lng) < threshold)
        },

        length(latLngs, options) {
            if((! latLngs) || (latLngs.length < 2)) {
                return 0
            }
            
            let geo = GeoUtil.LatLngs.toLineString(latLngs)
            return GeoUtil.GeoJson.length(geo, options)
        },

        segmentEach(latLngs, callback) {
            if(latLngs == null) {
                return
            }

            for(var i=1;i < latLngs.length;i++) {
                callback([latLngs[i - 1], latLngs[i]])
            }
        },

        lineToGeoJsonDebugPoly(latLngs) {
            let geoJson = GeoUtil.LatLngs.toLineString(latLngs)

            let ret = GeoUtil.GeoJson.buffer(geoJson, 0.00001, {units: 'kilometers'})
    
            ret.properties.strokeColor = "orange"
    
            return ret
        },

        polyToGeoJsonDebugPoly(latLngs) {
            let ret = GeoUtil.LatLngs.toPolygon(latLngs)
    
            ret.properties.fillColor = "orange"
            ret.properties.fillOpacity = 0.9
            ret.properties.strokeColor = "orange"

            let kinked = turfUnkinkPolygon(ret).features.length > 1
            let geoJsonString = JSON.stringify(ret)
            ret.properties.infoWindowContent = "Kinked: " + kinked + "<br>\n" + geoJsonString
    
            return ret
        },

        eucDistance(ll0, ll1, options) {
            const DegLen = 110.25
            const x = ll0.lat - ll1.lat
            const y = (ll0.lng - ll1.lng) * Math.cos(ll1.lat)
            
            const km = DegLen * Math.sqrt((x * x) + (y * y))

            if('feet' === options?.units) {
                return km / 3280.84
            }

            return km
        },

        distance(p1, p2, options) {
            return turfDistance([p1.lng, p1.lat], [p2.lng, p2.lat], options)
        },

        area(latLngs) {
            let ls = this.toLineString(latLngs)
            let poly = turfLineToPolygon(ls)

            return turfArea(poly)
        },

        removeLineKinks(latLngs) {
            let line = GeoUtil.LatLngs.toLineString(latLngs)
    
            let kinks = turfKinks(line)
            
            if(! (kinks.features && kinks.features.length > 0 && kinks.features[0].geometry && kinks.features[0].geometry.coordinates && kinks.features[0].geometry.coordinates.length > 0)) {
                return latLngs
            }
    
            kinks.features.forEach((kink) => {
                let split = turfLineSplit(line, kink)
    
                if(split.features.length != 2) {
                    return latLngs
                }
    
                let l1 =  turfLength(split.features[0])
                let l2 = turfLength(split.features[1])
        
                line = l1 > l2 ? split.features[0] : split.features[1]
            })
    
            let ret = []
    
            turfCoordEach(line, (c) => {
                ret.push(new LatLng(c[1], c[0]))
            })
    
            return ret
        },

        buildLineStretchedToPolygon(lineLatLngs, polyLatLngs) {
            let coords = GeoUtil.LatLngs.toCoords(lineLatLngs)
            if(coords.length < 2) {
                return null
            }
    
            let startCoord = coords[0]
            let endCoord = coords[coords.length - 1]

            if(! GeoUtil.LatLngs.pointsEqual(polyLatLngs[0], polyLatLngs[polyLatLngs.length - 1])) {
                polyLatLngs = polyLatLngs.concat([polyLatLngs[0]])
            }
    
            let polyLineString = GeoUtil.LatLngs.toLineString(polyLatLngs)
    
            let newStartPoint = GeoUtil.GeoJson.nearestPointOnLineString(polyLineString, startCoord)
            let newEndPoint  = GeoUtil.GeoJson.nearestPointOnLineString(polyLineString, endCoord)
    
            coords.splice(0, 0, GeoUtil.GeoJson.getCoord(newStartPoint))
            coords.splice(coords.length, 0, GeoUtil.GeoJson.getCoord(newEndPoint))
    
            return GeoUtil.Coords.toLineString(coords)
        },

        clipLineWithPolygon(lineLatLngs, polyLatLngs) {
            let poly = turfLineToPolygon(this.toLineString(polyLatLngs))
            let splitGeo = turfLineSplit(this.toLineString(lineLatLngs), poly)

            if(splitGeo.features.length <= 1) {
                return lineLatLngs
            }

            let mostAreaOverlap = ({bestPercentSoFar, bestLineGeo}, lineGeo) => {
                let lineBufferPoly = GeoUtil.GeoJson.buffer(lineGeo, 0.01)
                let lineArea = turfArea(lineBufferPoly)

                let intersectingPoly = GeoUtil.GeoJson.intersection(lineBufferPoly, poly)

                let intersectionPercent = turfArea(intersectingPoly) / lineArea

                if(intersectionPercent > bestPercentSoFar) {
                    return {bestPercentSoFar: intersectionPercent, bestLineGeo: lineGeo}
                }

                return {bestPercentSoFar, bestLineGeo}
            }

            let ret = splitGeo.features.reduce(mostAreaOverlap, {bestPercentSoFar: 0.0, bestLineGeo: null})

            return (ret == null) ? lineLatLngs : GeoUtil.GeoJson.toLatLngs(ret.bestLineGeo)
        },

        toGeoJsonPoint(latLng) {
            // return {
            //     type: "Feature", properties: {},
            //     geometry: {
            //         type: "Point",
            //         coordinates: [latLng.lng, latLng.lat]
            //     }
            // }
            return turfPoint([latLng.lng, latLng.lat])
        },

        toLineString(latLngs) {
            return lineString(latLngs.map((ll) => {
                return [ll.lng, ll.lat]
            }))
        },

        toPolygon(latLngs) {
            return turfLineToPolygon(GeoUtil.LatLngs.toLineString(latLngs))
        },

        splitLineWithPoly(lineLatLngs, splitterLatLngs) {
            let splitterLineString = this.toPolygon(splitterLatLngs)
            let lineLineString = this.toLineString(lineLatLngs)

            let retGeos = GeoUtil.GeoJson.splitLineWithGeo(lineLineString, splitterLineString)

            return retGeos.map(GeoUtil.GeoJson.toLatLngs)
        },

        splitPolyWithLine(polyLatLngs, lineLatLngs) {
            let lineLineString = GeoUtil.LatLngs.toLineString(lineLatLngs)
    
            let polyGeo = GeoUtil.LatLngs.toPolygon(polyLatLngs)

            let retGeo = GeoUtil.GeoJson.splitGeoWithLine(polyGeo, lineLineString)

            if(retGeo == null || retGeo.length == 1) {
                return null
            }

            return retGeo.map(GeoUtil.GeoJson.toLatLngs)
        },

        simplify(latLngs, options) {
            if(latLngs == null || latLngs.length <= 2) {
                return latLngs
            }
    
            if(options == null) {
                options = {}
            }
    
            if(! options.tolerance) {
                options.tolerance = 1.0
            }
    
            if(! options.highQuality) {
                options.highQuality = false
            }
    
            let lngLatArray = latLngs.map((ll) => {
                return [ll.lng, ll.lat]
            })
    
            let line = lineString(lngLatArray)
    
            simplify(line, {mutate: true, tolerance: options.tolerance, highQuality: options.highQuality})
    
            let ret = []
    
            turfCoordEach(line, (c) => {
                ret.push(new LatLng(c[1], c[0]))
            })
    
            return ret
        }
    }

    static GeoJson = {
        // Trim the tailing part of the line string as it passes through the geo
        // (used for case where pipe is in a field and we want to trim the part 
        //  of the pipe that outside the field)
        trimTail(ls, geo) {
            const points = ls?.geometry?.coordinates

            if((! points.length) || (! geo)) {
                return ls
            }

            for (let i = points.length - 1; i > 0; i--) {
                const p = GeoUtil.Coords.toGeoJson(points[i])
                const within = GeoUtil.GeoJson.booleanWithin(p, geo)
                if(within) {
                    return ls
                }

                const lineSegment = lineString([points[i], points[i - 1]]);
                if (turfBooleanCrosses(lineSegment, geo)) {
                    const intersection = turfLineIntersect(lineSegment, geo);
                    if (intersection.features.length > 0) {
                        const intersectionPoint = intersection.features[0].geometry.coordinates;
                        // Truncate the line string at the intersection point
                        const newLineCoords = points.slice(0, i);
                        newLineCoords.push(intersectionPoint);
                        return lineString(newLineCoords);
                    }
                }
            }

            return ls
        },
        flatten(geo) {
            return turfFlatten(geo)
        },
        
        flattenReduce(geo, callback) {
            return flattenReduce(geo, callback)
        },

        pointGrid(bbox, cellSide, options) {
            return turfPointGrid(bbox, cellSide, options)
        },
        
        length(geoJson, options) {
            return turfLength(geoJson, options)
        },

        bearing(p0, p1) {
            const c0 = getCoords(p0)
            const c1 = getCoords(p1)
            return turfBearing(c0, c1)
        },
        
        buffer(geoJson, radius, options) {
            if(options && ('mutate' in options)) {
                throw new Error('Turf buffer() Does Not Recognize "mutate" Option')
            }
            
            return buffer(geoJson, radius, options)
        },

        envelope(geoJson) {
            return envelope(geoJson)
        },

        truncate(geo, options) {
            return turfTruncate(geo, options)
        },

        cleanCoords(geo, options) {
            return turfCleanCoords(geo, options)
        },

        getSegments(geo) {
            let ret = []

            turfSegmentEach(geo, (segment) => {
                ret.push(segment)
            })

            return ret
        },

        segmentEach(geo, callback) {
            return turfSegmentEach(geo, callback)
        },

        segmentReduce(geo, callback, initialValue) {
            return turfSegmentReduce(geo, callback, initialValue)
        },

        featureEach(geo, callback) {
            return turfFeatureEach(geo, callback)
        },

        lineToPolygon(ls, options) {
            return turfLineToPolygon(ls, options)
        },

        polygonToLine(polygon, options) {
            return polygonToLine(polygon, options)
        },

        nearestPoint(coord, pointsFeatureCollection) {
            return turfNearestPoint(coord, pointsFeatureCollection)
        },

        nearestPointOnLineString(lineString, pt, options) {
            return nearestPointOnLine(lineString, pt, options)
        },

        pointToLineDistance(pt, lineString, options) {
            return turfPointToLineDistance(pt, lineString, options)
        },

        area(geoJson) {
            return turfArea(geoJson)
        },

        areaInAcres(geoJson) {
            return turfArea(geoJson) * 0.0002471052
        },

        interpolate(points, cellSize, options) {
            return turfInterpolate(points, cellSize, options)
        },

        simplify(geoJson, options) {
            return simplify(geoJson, options)
        },

        featureCollection(features) {
            return turfFeatureCollection(features)
        },

        pointsEqual(p1, p2, threshold = OneTenthOfOneMeterInLatLng * 10) {
            const c1 = getCoords(p1)
            const c2 = getCoords(p2)

            if(c1.length !== c2.length) {
                return false
            }

            for(let i=0;i < c1.length;i++) {
                if(Math.abs(c1[i] - c2[i]) > threshold) {
                    return false
                }
            }

            return true
        },

        pointsExactlyEqual(geo1, geo2) {
            const c1 = getCoords(geo1)
            const c2 = getCoords(geo2)

            if(c1.length !== c2.length) {
                return false
            }

            for(let i=0;i < c1.length;i++) {
                if(c1[i] !== c2[i]) {
                    return false
                }
            }

            return true
        },

        booleanContains(geo1, geo2) {
            return turfBooleanContains(geo1, geo2)
        },

        booleanOverlap(geo1, geo2) {
            return turfBooleanOverlap(geo1, geo2)
        },

        booleanCrosses(geo1, geo2) {
            return turfBooleanCrosses(geo1, geo2)
        },

        booleanIntersects(f1, f2) {
            return turfBooleanIntersects(f1, f2)
        },        

        booleanWithin(geo1, geo2) {
            return turfBooleanWithin(geo1, geo2)
        },

        bbox(geo) {
            return turfBbox(geo)
        },

        bboxPolygon(bbox, options) {
            return turfBboxPolygon(bbox, options)
        },

        lineSplit(lineString, splitterGeo) {
            return turfLineSplit(lineString, splitterGeo)
        },

        southernMostPoint(geo) {
            let northPole = [0, 90]
            return turfPoint(coordReduce(geo, (accum, current) => {
                return current[1] < accum[1] ? current : accum
            }, northPole))
        },

        along(lineString, distance, options) {
            return turfAlong(lineString, distance, options)
        },

        splitPolyWithLine(polyGeo, lineString) { 
            let retGeo = GeoUtil.GeoJson.splitGeoWithLine(polyGeo, lineString)

            if(retGeo == null || retGeo.length == 1) {
                return null
            }

            return retGeo
        },

        bboxIntersect(geo1, geo2) {
            let bb1 = geo1.bbox ? geo1.bbox : turfBbox(geo1)
            let bb2 = geo2.bbox ? geo2.bbox : turfBbox(geo2)

            let w1 = bb1[0]
            let s1 = bb1[1]
            let e1 = bb1[2]
            let n1 = bb1[3]

            let w2 = bb2[0]
            let s2 = bb2[1]
            let e2 = bb2[2]
            let n2 = bb2[3]

            let withinEitherWay = (beg1, end1, beg2, end2) => {
                let within = (min, max, val) => {
                    return (val >= min) && (val <= max)
                }

                if(end1 >= beg1) {
                    if((within(beg1, end1, beg2) || within(beg1, end1, end2))) {
                        return true
                    }
                }
                else {
                    if((within(end1, beg1, beg2) || within(end1, beg1, end2))) {
                        return true
                    }
                }

                if(end2 >= beg2) {
                    if((within(beg2, end2, beg1) || within(beg2, end2, end1))) {
                        return true
                    }
                }
                else { 
                    if((within(end2, beg2, beg1) || within(end2, beg2, end1))) {
                        return true
                    }
                }
    
                return false
            }

            return withinEitherWay(w1, e1, w2, e2) && withinEitherWay(s1, n1, s2, n2)
        },

        lineStringToGeoJsonDebugPoly(lineString) {
            let ret = GeoUtil.GeoJson.buffer(lineString, 0.005, {units: 'kilometers'})
    
            ret.properties.strokeColor = "orange"
    
            return ret
        },

        toLatLngs(geoJson) {
            if(geoJson.geometry.type == 'LineString') {
                return geoJson.geometry.coordinates.map((c) => {
                    return new LatLng(c[1], c[0])
                })
            }
            else if(geoJson.geometry.type == 'Point') {
                let coords = geoJson.geometry.coordinates
                return new LatLng(coords[1], coords[0])
            }

            return coordAll(geoJson).map((c) => {
                return new LatLng(c[1], c[0])
            })
        },

        getCoords(geoJson) {
            return getCoords(geoJson)
        },

        getCoord(geoJson) {
            return getCoord(geoJson)
        },

        isPoint(geo) {
            if(geo == null || geo.type !== 'Feature' || geo.geometry == null || geo.geometry.type !== 'Point') {
                return false
            }
            
            let coords = geo.geometry.coordinates
            if(coords == null || coords.length !== 2) {
                return false
            }

            return true
        },

        isFeatureCollection(geo) {
            return geo?.type === 'FeatureCollection'
        },

        isLineString(geo) {
            if(geo == null || geo.type != 'Feature' || geo.geometry == null || geo.geometry.type != 'LineString') {
                return false
            }
            
            let coords = geo.geometry.coordinates
            if(coords == null || coords.length < 2) {
                return false
            }

            return true
        },

        isPolygon(geo) {
            if(geo == null || geo.type != 'Feature' || geo.geometry == null || geo.geometry.type != 'Polygon') {
                return false
            }
            
            let coords = geo.geometry.coordinates
            if(coords == null || coords.length < 1) {
                return false
            }

            return true
        },

        isMultiPolygon(geo) {
            if(geo == null || geo.type != 'Feature' || geo.geometry == null || geo.geometry.type != 'MultiPolygon') {
                return false
            }

            if(! geo.geometry.coordinates.every(GeoUtil.Coords.isPolygon)) {
                return false
            }

            return true
        },

        centroid(geo, properties) {
            return turfCentroid(geo, properties)
        },

        centerOfMass(geo, properties) {
            return turfCenterOfMass(geo, properties)
        },

        multiPolygonToPolygons(multiGeo) {
            if(! this.isMultiPolygon(multiGeo)) {
                throw new Error('Invalid MultiPolygon')
            }

            let converted = multiGeo.geometry.coordinates.map((coords) => {
                if(! GeoUtil.Coords.isPolygon(coords)) {
                    return null
                }

                let ret = turfPolygon(coords)
                ret.properties = Object.assign({}, multiGeo.properties)
                return ret
            })

            return converted.filter((poly) => poly != null)
        },

        polygonsToMultiPolygon(geos) {
            let coords = geos.reduce((accum, geo) => {
                accum.push(geo.geometry.coordinates)
                return accum
            }, [])

            return multiPolygon(coords)
        },

        difference(geo1, geo2) {
            return turfDifference(geo1, geo2)
        },

        booleanDisjoint(geo1, geo2) {
            return turfBooleanDisjoint(geo1, geo2)
        },

        booleanPointInPolygon(coord, geo, options) {
            return turfBooleanPointInPolygon(coord, geo, options)
        },

        booleanPointOnLine(pointGeo, lineString, epsilonInFeet=3) {
            const distanceInFeet = GeoUtil.GeoJson.pointToLineDistance(pointGeo, lineString, {units: 'feet'})

            return distanceInFeet < epsilonInFeet
        },

        turfPointOnLine(point, segment){
            return turfBooleanPointOnLine(point, segment)
        },

        pointsWithinPolygon(pointOrPoints, geo) {
            return turfPointsWithinPolygon(pointOrPoints, geo)
        },

        lineIntersect(lineGeo, otherGeo, {bufferOtherGeoIfIntersectionFails=false} = {}) {
            let ret =  turfLineIntersect(lineGeo, otherGeo)

            if((! bufferOtherGeoIfIntersectionFails) || (ret != null && ret.features.length > 0)) {
                return ret
            }

            let bufferedOtherGeo = GeoUtil.GeoJson.buffer(otherGeo, 1, {units: "meters"})

            return turfLineIntersect(lineGeo, bufferedOtherGeo)
        },

        pointDistanceFromStartOfLine(pointGeoJson, lineString, options) {
            let startLineCoord = lineString.geometry.coordinates[0]
            let stopLineCoord = getCoord(pointGeoJson)

            return this.coordDistanceOnLine(startLineCoord, stopLineCoord, lineString, options)
        },

        coordDistanceOnLine(coord1, coord2, lineString, options) {
            let slice = turfLineSlice(coord1, coord2, lineString)
            
            return turfLength(slice, options)
        },

        lineSlice(startPt, stopPt, lineString) {
            return turfLineSlice(startPt, stopPt, lineString)
        },

        lineChunk(lineString, length, options) {
            return turfLineChunk(lineString, length, options)
        },

        kinks(geo) {
            return turfKinks(geo)
        },

        removeLineKinks(feature) {
            let kinks = turfKinks(feature)
            
            if(! (kinks.features && kinks.features.length > 0 && kinks.features[0].geometry && kinks.features[0].geometry.coordinates && kinks.features[0].geometry.coordinates.length > 0)) {
                return feature
            }

            let ret = feature
    
            kinks.features.forEach((kink) => {
                let split = turfLineSplit(feature, kink)
    
                if(split.features.length != 2) {
                    return feature
                }
    
                let l1 =  turfLength(split.features[0])
                let l2 = turfLength(split.features[1])
        
                ret = l1 > l2 ? split.features[0] : split.features[1]
            })

            return ret
        },

        reducePolygonToQuadrilateral(geo) {
            if(! GeoUtil.GeoJson.isPolygon(geo)) {
                throw new Error("Expected Polygon")
            }

            let coords = geo.geometry.coordinates[0]

            if(coords.length <= 5) {
                return //this poly was already a quad or simpler
            }

            let effectOnPolyWithCoordIndexRemoved = [] //array of {index, effect}
            for(let i=1;i < coords.length - 1;i++) {
                let removedCoord = coords.splice(i, 1)[0]

                let effect = GeoUtil.GeoJson.area(geo)

                effectOnPolyWithCoordIndexRemoved.push({index: i, effect})

                coords.splice(i, 0, removedCoord)
            }

            effectOnPolyWithCoordIndexRemoved.sort((a, b) => {
                return b.effect - a.effect
            })

            let numCoordsToRemove = coords.length - 5

            let indexesToRemove = {}
            for(let i=0;i < numCoordsToRemove;i++) {
                indexesToRemove[effectOnPolyWithCoordIndexRemoved[i].index] = true
            }

            for(let i=coords.length - 1;i > 0;i--) {
                if(indexesToRemove[i]) {
                    coords.splice(i, 1)
                }
            }
        },

        splitLineWithGeo(ls, geo) {
            let intersectingPoints = GeoUtil.GeoJson.lineIntersect(ls, geo)
            if(intersectingPoints == null || intersectingPoints.features == null) {
                return null
            }

            //sort by distance from start of line
            {
                intersectingPoints.features.forEach((pointGeo) => {
                    pointGeo.properties.distanceFromStartOfLine = 
                        GeoUtil.GeoJson.pointDistanceFromStartOfLine(pointGeo, ls)
                })

                intersectingPoints.features = intersectingPoints.features.sort((a, b) => {
                    return a.properties.distanceFromStartOfLine - b.properties.distanceFromStartOfLine
                })
            }

            let ret = []

            let lsCoords = getCoords(ls)
            let startCoord = lsCoords[0]
            let endCoord = lsCoords[lsCoords.length - 1]
            intersectingPoints.features.forEach((pointGeo, i) => {
                if(i == 0) {
                    ret.push(turfLineSlice(startCoord, getCoord(pointGeo), ls))

                    if(i == intersectingPoints.features.length - 1) {
                        ret.push(turfLineSlice(getCoord(pointGeo), endCoord, ls))
                    }
                    else {
                        ret.push(turfLineSlice(getCoord(pointGeo), getCoord(intersectingPoints.features[i + 1]), ls))
                    }
                }
                else if(i == intersectingPoints.features.length - 1) {
                    ret.push(turfLineSlice(getCoord(pointGeo), endCoord, ls))
                }
                else {
                    ret.push(turfLineSlice(getCoord(pointGeo), getCoord(intersectingPoints.features[i + 1]), ls))
                }
            })

            return ret
        },

        segmentOnPoly(segmentCoords, polyGeo) {
            let segmentLineString = GeoUtil.Coords.toGeoJson(segmentCoords)
            let segmentBufferedGeo = GeoUtil.GeoJson.buffer(segmentLineString, 0.00001, {units: 'kilometers'})
            
            let segementPolyIntersection = GeoUtil.GeoJson.intersection(polyGeo, segmentBufferedGeo)
            if(segementPolyIntersection == null) {
                return false
            }

            let segmentIntersectionPercent = (turfArea(segementPolyIntersection) / turfArea(segmentBufferedGeo))
            if(segmentIntersectionPercent < 0.95) {
                return false
            }

            return true
        },

        projectSegmentOntoPolyAlongBearing(segmentCoords, polyGeo, bearing) {
            let polyPerimeter = GeoUtil.GeoJson.length(polyGeo)
            
            let p1 = segmentCoords[0]
            let p2 = segmentCoords[1]
            let p3 = getCoord(turfDestination(p1, polyPerimeter, bearing))
            let p4 = getCoord(turfDestination(p2, polyPerimeter, bearing))

            let sectorLineString = lineString([p1, p2, p4, p3, p1])
            let sectorPolygon = turfLineToPolygon(sectorLineString)

            sectorPolygon.properties = {}

            return sectorPolygon
        },

        projectSegmentOutwardFromBearing(segmentCoords, bearing, length) {
            let bearingReversed = GeoUtil.bearingReversed(bearing)

            let p0 = getCoord(turfDestination(segmentCoords[0], length, bearing))
            let p1 = getCoord(turfDestination(segmentCoords[1], length, bearing))
            let p2 = getCoord(turfDestination(segmentCoords[1], length, bearingReversed))
            let p3 = getCoord(turfDestination(segmentCoords[0], length, bearingReversed))

            let sectorLineString = lineString([p0, p1, p2, p3, p0])
            let sectorPolygon = turfLineToPolygon(sectorLineString)

            sectorPolygon.properties = {}

            return sectorPolygon
        },

        
        /*
            Turf returns some weird cases for what should be simple polygons.
            
            A lot of times the 'hole' part of the polygon is what we wanted.
        */
        convertPolyWithHolesToListOfPolys(geo) {
            return geo.geometry.coordinates.reduce((accum, coords) => {
                let possibleHolePoly = GeoUtil.Coords.toGeoJson([coords])
                
                if(GeoUtil.GeoJson.isPolygon(possibleHolePoly)) {
                    possibleHolePoly.properties = JSON.parse(JSON.stringify(geo.properties))
                    accum.push(possibleHolePoly)
                    return accum
                }

                return accum
            }, [])
        },

        splitGeoWithLine(geo, splitterLine) {
            let splitterLineBuffer = GeoUtil.GeoJson.buffer(splitterLine, 0.0001, {units: 'kilometers'})

            let sectionsGeo = GeoUtil.GeoJson.difference(geo, splitterLineBuffer)
            if(sectionsGeo == null) {
                return null
            }

            if(GeoUtil.GeoJson.isMultiPolygon(sectionsGeo)) {
                let possibleRet = GeoUtil.GeoJson.multiPolygonToPolygons(sectionsGeo)

                possibleRet.forEach((geo) => {
                    geo.properties.areaInSquareMeters =
                        GeoUtil.GeoJson.area(geo)
                })

                let splitWasInsignificant = () => {
                    let originalArea = GeoUtil.GeoJson.area(geo)
                    return possibleRet.some((poly) => (GeoUtil.GeoJson.area(poly) / originalArea) > 0.999)
                }
                
                if(splitWasInsignificant()) {
                    return [geo]
                }

                possibleRet = possibleRet.filter((geo) => geo.properties.areaInSquareMeters > 0)

                return possibleRet.reduce((accum, polyGeo) => {
                    return accum.concat(GeoUtil.GeoJson.convertPolyWithHolesToListOfPolys(polyGeo))
                }, [])
            }
            else if(GeoUtil.GeoJson.isPolygon(sectionsGeo)) {
                return GeoUtil.GeoJson.convertPolyWithHolesToListOfPolys(sectionsGeo)
            }

            return null
        },

        coordEach(geo, callback, excludeWrapCoord) {
            return turfCoordEach(geo, callback, excludeWrapCoord)
        },

        interiorSplitGeoAlongBearingFromPoint(geo, bearing, pointGeo) {
            let splitterLine = GeoUtil.GeoJson.getInteriorSplitLineAlongBearingFromPoint(geo, bearing, pointGeo)

            if(splitterLine == null) {
                return null
            }

            return GeoUtil.GeoJson.splitGeoWithLine(geo, splitterLine)
        },

        getSplitLineAlongBearingFromPoint(bearing, pointGeo, distanceInKm=10) {
            const bearingReversed = GeoUtil.bearingReversed(bearing)

            const c = pointGeo.geometry.coordinates
            const nc0 = GeoUtil.GeoJson.getCoord(GeoUtil.Coords.destination(c, distanceInKm, bearing))
            const nc1 = GeoUtil.GeoJson.getCoord(GeoUtil.Coords.destination(c, distanceInKm, bearingReversed))

            return GeoUtil.Coords.toGeoJson([nc0, nc1])
        },

        getInteriorSplitLineAlongBearingFromPoint(geo, bearing, pointGeo) {
            let perimeter = GeoUtil.GeoJson.length(geo)
            let bearingReversed = GeoUtil.bearingReversed(bearing)

            let c = pointGeo.geometry.coordinates
            let p1 = GeoUtil.GeoJson.getCoord(GeoUtil.Coords.destination(c, perimeter, bearing))
            let p2 = GeoUtil.GeoJson.getCoord(GeoUtil.Coords.destination(c, perimeter, bearingReversed))

            let bearingLineString = GeoUtil.Coords.toGeoJson([c, p1])
            let reverseBearingLineString = GeoUtil.Coords.toGeoJson([c, p2])

            let bearingIntersections = turfLineIntersect(geo, bearingLineString)
            let reverseBearingIntersections = turfLineIntersect(geo, reverseBearingLineString)

            let byDistanceAscending = (a, b) => {
                return turfDistance(a.geometry.coordinates, c) - turfDistance(b.geometry.coordinates, c)
            }

            bearingIntersections.features.sort(byDistanceAscending)
            reverseBearingIntersections.features.sort(byDistanceAscending)

            if(bearingIntersections.features.length < 1 || reverseBearingIntersections.features.length < 1) {
                return null
            }

            return GeoUtil.Coords.toGeoJson([
                bearingIntersections.features[0].geometry.coordinates,
                reverseBearingIntersections.features[0].geometry.coordinates,
            ])
        },

        getInteriorLineAlongBearingFromPoint(geo, bearing, pointGeo) {
            let perimeter = GeoUtil.GeoJson.length(geo)

            let c = pointGeo.geometry.coordinates
            let p1 = GeoUtil.GeoJson.getCoord(GeoUtil.Coords.destination(c, perimeter, bearing))

            let bearingLineString = GeoUtil.Coords.toGeoJson([c, p1])

            let bearingIntersections = turfLineIntersect(geo, bearingLineString)

            let byDistanceAscending = (a, b) => {
                return turfDistance(a.geometry.coordinates, c) - turfDistance(b.geometry.coordinates, c)
            }

            bearingIntersections.features.sort(byDistanceAscending)

            if(bearingIntersections.features.length < 1) {
                return null
            }

            return GeoUtil.Coords.toGeoJson([
                pointGeo.geometry.coordinates,
                bearingIntersections.features[0].geometry.coordinates,
            ])
        },

        clustersKmeans(points, options) {
            return turfClustersKmeans(points, options)
        },

        clustersDbscan(points, maxDistanceInKm, options) {
            return turfClustersDbscan(points, maxDistanceInKm, options)
        },
        
        pointOnFeature(geo) {
            return turfPointOnFeature(geo)
        }
    }

    static directionForBearingRelativeToBearing(bearing, relativeToBearing) {
        if(GeoUtil.bearingDifference(relativeToBearing, bearing) === 0) {
            throw new Error('Cannot Compute Side if Bearings Match')
        }

        // translate things to origin for simplification
        const originTranslation = 0 - relativeToBearing

        let translatedFurrowBearing = bearing + originTranslation
        if(translatedFurrowBearing < -180) {
            translatedFurrowBearing += 360.0
        }
        else if(translatedFurrowBearing > 180) {
            translatedFurrowBearing -= 360.0
        }

        // now compare translatedFurrowBearing to the origin...

        if(translatedFurrowBearing > 0 && translatedFurrowBearing < 180) {
            return Direction.Right
        }

        return Direction.Left
    }

    static bearing(ll0, ll1) {
        return turfBearing([ll0.lng, ll0.lat], [ll1.lng, ll1.lat])
    }

    static bearingReversed(bearing) {
        if(bearing >= 0) {
            return bearing - 180.0
        }

        return bearing + 180
    }

    static bearingPerpendicular(bearing) {
        if((bearing >= -180) && (bearing <= 90)) {
            return bearing + 90
        }

        return bearing - 270
    }

    static bearingOffset(bearing, degree) {
        if(degree > 360) {
            throw new Error('Invalid degree. Degree must be between -360 - 360')
        }
        if(degree > 180) {
            degree -= 180
        }
        else if(degree < -180) {
            degree += 180
        }

        return bearing + degree
    }

    static leftmostBearing() {
        const args = Array.prototype.slice.call(arguments)

        if(args.length < 2) {
            throw new Error('Minimum Two Bearings Required to Compute Leftmost')
        }

        // Sort by azimuth ascending.
        args.sort((b0, b1) => {
            const a0 = turfBearingToAzimuth(b0)
            const a1 = turfBearingToAzimuth(b1)

            return a0 - a1
        })

        if(args.length === 2) {
            const bearingDifference = GeoUtil.bearingDifference(args[0], args[1])
            if(bearingDifference === 0 || bearingDifference === 180.0) {
                return null
            }
        }

        let ret = null
        let retAzimuth = null
        args.forEach((bearing) => {
            const azimuth = turfBearingToAzimuth(bearing)
            if(ret == null) {
                ret = bearing
                retAzimuth = azimuth
                return
            }

            if((azimuth - retAzimuth) >= 180) {
                ret = bearing
                retAzimuth = azimuth
            }
        })

        return ret
    }

    static bearingDifference(b1, b2) {
        let a1 = turfBearingToAzimuth(b1)
        let a2 = turfBearingToAzimuth(b2)

        let diff1 = Math.abs(a2 - a1)
        let diff2 = 360 - diff1

        return Math.min(diff1, diff2)
    }

    static bearingToAzimuth(b) {
        return turfBearingToAzimuth(b)
    }

    static bidirectionalBearingDifference(b1, b2) {
        let b1r = this.bearingReversed(b1)
        let b2r = this.bearingReversed(b2)
        return Math.min(
            this.bearingDifference(b1, b2), 
            this.bearingDifference(b1, b2r),
            this.bearingDifference(b1r, b2),
            this.bearingDifference(b1r, b2r)
        )
    }

    static destination(latLng, length, bearing) {
        return turfDestination([latLng.lng, latLng.lat], length, bearing)
    }

    static randomColor() {
        const letters = '0123456789ABCDEF';

        let ret = '#';

        for(let i=0;i < 6;i++) {
            ret += letters[Math.floor(Math.random() * 16)]
        }

        return ret;
    }
}

export {
    GeoUtil
}

<template>
    <template>
        <template v-if="!editingPipePath">
            <pipe-path v-for="pipePath in selectedPipePaths" :pipePath="pipePath" :key="'Selected-' + pipePath.id"
                :irrigationSystem="irrigationSystemForPipePath(pipePath)" selectionStyle="selected" :clickable="true"
                @click="selectedPipePathClicked(pipePath, $event)" :showElevationOverlay="true"
                :showEndPoints="true" @endclick="selectedPipePathClicked(pipePath, $event)"
                :showLength="true"
                :allowClickToEditModeWhenSelected="true" :editMode="selectedPipePathEditMode" />
        </template>

        <pipe-path-watered-areas v-for="(plan, i) in holeDesignsToShow" :key="'PipePathWateredAreas:' + plan.key + ':' + i"
            :field="plan.field" :fieldLayout="plan.fieldLayout" :flowRate="getFlowRateForField()"
            :pipePath="plan.pipePath" />

        <template v-if="false">
            <design-params v-for="(o, i) in holeDesignsToShow" :key="i"
                :designParams="o?.pipePath?.designParams" :fieldLayout="o?.fieldLayout"/>
        </template>

        <pipe-path v-if="! editingSomething"
            v-for="pipePath in selectablePipePaths" :pipePath="pipePath" :key="'Selectable-' + pipePath.id"
            :irrigationSystem="irrigationSystemForPipePath(pipePath)" selectionStyle="selectable" :clickable="true"
            :showLength="true"
            @click="selectablePipePathClicked(pipePath)" @endclick="selectablePipePathClicked(pipePath, $event)"/>

        <water-source v-for="irrigationSystem in selectableIrrigationSystems"
            :key="'SelectableIrrigationSystem:' + irrigationSystem.id" :location="irrigationSystem.waterSourceLocation"
            :name="irrigationSystem.name" :showLabel="true" :clickable="true"
            @click="irrigationSystemClicked(irrigationSystem)" selectionStyle="selectable" />

        <water-source v-for="irrigationSystem in selectedIrrigationSystems"
            :key="'SelectedIrrigationSystem:' + irrigationSystem.id" :location="irrigationSystem.waterSourceLocation"
            :name="irrigationSystem.name" :showLabel="true" selectionStyle="selected" />

        <water-source v-for="irrigationSystem in unselectableIrrigationSystems"
            :key="'UnselectableIrrigationSystem:' + irrigationSystem.id" :location="irrigationSystem.waterSourceLocation"
            :name="irrigationSystem.name" selectionStyle="unselectable" />

        <pipe-path v-if="! editingSomething"
            v-for="pipePath in unselectablePipePaths" :pipePath="pipePath" :key="'Unselectable-' + pipePath.id"
            :irrigationSystem="irrigationSystemForPipePath(pipePath)" selectionStyle="unselectable" :clickable="false" />

        <furrow-set v-if="furrowSetForPipePathBeingEdited" selectionStyle="selected"
            :key="'PipePatheEdit-' + furrowSetForPipePathBeingEdited.id" :furrowSet="furrowSetForPipePathBeingEdited"
            :fieldLayout="selectedFieldLayout" :showLabel="false" :zIndex="ZIndexes.FurrowSets" />

        <context-menu v-if="this.irrigationSystemMenuShown">
            <irrigation-system-context-menu />
        </context-menu>
        <context-menu v-if="this.pipePathMenuShown">
            <pipe-path-menu />
        </context-menu>
    </template>
</template>

<script>
import { GeometryEditMode } from '@/store/Dto'
import { ZIndexes } from '@/maps/ZIndexes'

export default {
    data: function () {
        return {
            ZIndexes
        }
    },
    computed: {
        editingPipePath: (vm) => vm.$store.state.mapObjects.editingPipePath,
        selectedPipePaths: (vm) => vm.$store.state.mapObjects.selectedPipePaths,
        selectablePipePaths: (vm) => {
            return vm.$store.state.mapObjects.selectablePipePaths
        },
        unselectablePipePaths: (vm) => {
            return vm.$store.state.mapObjects.unselectablePipePaths
        },
        selectedPipePathEditMode: (vm) => vm.$store.state.mapObjects.selectedPipePaths.length < 1 ?
            GeometryEditMode.EditPoints : vm.$store.state.mapObjects.availableMapActions.EditPipePathMode,
        selectedIrrigationSystems: (vm) => vm.$store.state.mapObjects.selectedIrrigationSystems,
        selectableIrrigationSystems: (vm) => vm.$store.state.mapObjects.selectableIrrigationSystems,
        unselectableIrrigationSystems: (vm) => vm.$store.state.mapObjects.unselectableIrrigationSystems,
        selectedField: (vm) => vm.$store.state.selectedField,
        selectedFieldLayout: (vm) => vm.$store.state.selectedFieldLayout,
        selectedFurrowSet: (vm) => vm.$store.state.selectedFurrowSet,
        selectedPipePath: (vm) => vm.$store.state.selectedIrrigationSystemPipePath,
        selectedIrrigationSystem: (vm) => vm.$store.state.selectedIrrigationSystem,
        furrowSetForPipePathBeingEdited: (vm) =>
            vm.$store.state.mapObjects.availableMapActions.EditPipePathMode != GeometryEditMode.None ?
                vm.$store.state.selectedFurrowSet : null,
        editingSomething: (vm) => vm.$store.state.mapObjects.editingField || vm.$store.state.mapObjects.editingFarm || vm.$store.state.mapObjects.editingPipePath || vm.$store.state.mapObjects.editingIrrigationSystem,
        pipePathMenuShown: (vm) => (!vm.editingSomething)
            && (vm.$store.state.selectedIrrigationSystem != null)
            && (vm.$store.state.selectedIrrigationSystemPipePath != null),
        irrigationSystemMenuShown: (vm) => {
            return (!vm.editingSomething)
                && (vm.$store.state.selectedIrrigationSystem != null)
                && (vm.$store.state.selectedIrrigationSystemPipePath == null)
        },
        holeDesignsToShow: (vm) => {
            if (vm.editingSomething) {
                return []
            }

            const field = vm.$store.state.selectedField
            const fieldLayout = vm.$store.state.selectedFieldLayout
            const pipePath = vm.$store.state.selectedIrrigationSystemPipePath

            if (pipePath?.designParams?.holeDesign) {
                return [{
                    key: `hole-design-to-show-${pipePath}`,
                    pipePath: pipePath,
                    holeDesign: pipePath.designParams.holeDesign,
                    field, fieldLayout
                }]
            }

            return []
        }
    },
    methods: {
        irrigationSystemClicked(irrigationSystem) {
            this.$store.dispatch('setSelectedIrrigationSystem', irrigationSystem)
            this.setHelpKey()
        },
        setHelpKey() {
            this.$store.dispatch('mapObjects/setHelpKey', 'irrigation_system_mode')
        },
        selectedPipePathClicked(_pipePath, e={}) {
            if ('traversalIndexes' in e) {
                const traversalIndexes = e.traversalIndexes
                this.$store.dispatch('enterEditPipePathPointsMode', traversalIndexes)
            }
            else {
                this.$store.dispatch('enterEditPipePathPointsMode')
            }
        },
        selectablePipePathClicked(pipePath, o={}) {
            const irrigationSystem = this.irrigationSystemForPipePath(pipePath)

            this.$store.dispatch('setSelectedIrrigationSystemPipePath', { irrigationSystem, irrigationSystemPipePath: pipePath })

            if ('traversalIndexes' in o) {
                const traversalIndexes = o.traversalIndexes
                this.$store.dispatch('enterEditPipePathPointsMode', traversalIndexes)
            }
        },
        fieldForPipePath(pipePath) {
            return this.$store.state.selectedFarm.fields.find((field) => field.id === pipePath.fieldId)
        },
        irrigationSystemForPipePath(pipePath) {
            return this.$store.state.selectedFarm.irrigationSystems.find((irrigationSystem) => {
                return irrigationSystem.pipePaths.some((pp) => pp.id === pipePath.id)
            })
        },
        getFlowRateForId(id) {
            if (!this.selectedField) {
                return null
            }

            if (!this.selectedIrrigationSystem) {
                return null
            }

            if (!this.selectedIrrigationSystem.flowRates) {
                return null
            }

            return this.selectedIrrigationSystem.flowRates.find((fr) => fr.id == id)
        },
        getFlowRateForField() {
            if (this.selectedPipePath) {
                return this.getFlowRateForId(this.selectedPipePath.flowRateId)
            }
            return this.getFlowRateForId(this.selectedField.primaryFlowRateId)
        }
    }
}
</script>

<style lang="css"></style>

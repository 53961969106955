// import { HoleSide } from '@pp4/design'

const HoleSide = {
    Left: 0,
    Right: 1
}
Object.freeze(HoleSide)
class DesignParams {
    constructor() {
        this.designSteps = []
        this.holeDesign = null
        this.checksum = null
    }
}

export {
    DesignParams, HoleSide
}

<template>
    <div style="display: flex;flex-direction: column;align-items: center;">
        <loading :absolute="true" v-if="userInfoSwrv.isValidating.value" title="Checking User..." />

        <embed
            src="/legal/John%20Deere%20Pipe%20Planner%20Software%20Connection%20Terms%20Licensing%20Terms%20March%2027%202024.pdf"
            :width="w60" :height="h60" />

        <icon-button description="Accept Terms" label="Accept Terms" :big="true" faIcon="fa-gavel" class="mt-1"
            @click="agree" :jd="true" />
    </div>
</template>

<script setup>
import { computed, inject } from 'vue';
import useSWRV from 'swrv'

const $api = inject('$api')

const width = Math.min(window.innerWidth * 0.9, 960)
const w60 = computed(() => Math.round(width) + "px")
const h60 = computed(() => Math.round(window.innerHeight * 0.7) + "px")

const userInfoSwrv = useSWRV('/api/user-info', async (url) => {
  const result = await $api.fetch(url, { method: "GET" })

  return await result.json()
}, { defaultValue: null, revalidateOnFocus: false })

async function agree() {
    await $api.fetch('/api/accept-jd-dealer-tos', { method: "POST" })

    userInfoSwrv.mutate()
}
</script>

<style lang="css" scoped></style>

<template>
    <template>
        <template v-if="showLengthLabel">
            <pp4-label v-for="(o, i) in branchLengthLabels" :key="`bll${i}`" :labelText="o.label" :latLng="o.latLng"
                elementClass="selected_pipe_path_label" />
        </template>

        <template v-for="o in pathSegmentPolylines" :key="o.key">
            <pp4-polyline v-bind="o.options" @click="handleClick" />
            <pp4-polyline v-if="o.bufferOptions" v-bind="o.bufferOptions" @click="handleClick" />
        </template>

        <elevation-line-overlay v-if="showElevationOverlay" :path="path" />

        <point-marker v-for="(o, i) in endPoints" :key="o.key" :draggable="false" :selectionStyle="selectionStyle"
            :location="o.latLng" :isLastPoint="true" @click="() => endPointClicked(o)" />

        <!-- for Vue be careful, we can't use simple index keys like we can in React -->
        <!-- <pipe-segment v-for="(path, i) in pathSegmentPreviewPaths" :key="`psp:${i}`"
                :path="path" :styleProps="pipeSegmentPreviewStyleProps"/> -->

        <water-source v-if="showIrrigationSystem" :selectionStyle="selectionStyle" :clickable="false"
            :location="irrigationSystem.waterSourceLocation" :name="irrigationSystem.name" />

        <!-- <design-params v-if="pipePath.designParams" :designParams="pipePath.designParams"/> -->
    </template>
</template>

<script setup>
import { computed } from 'vue'
import * as uuid from 'uuid'

import { GeoUtil } from '@/geo/GeoUtil'
import { ZIndexes } from '@/maps/ZIndexes'
import PipePathAlg from '@/design/PipePathAlg'

import Vars from '@/Vars'

function BuildRunningWaterIcon(waterColor) {
    return {
        icon: {
            path: 'M 0,0 0,0',
            strokeOpacity: 1.0,
            strokeWeight: 2.0,
            strokeColor: waterColor
        },
        offset: '0',
        repeat: '1px'
    }
}

const SelectedRunningWaterIcon = BuildRunningWaterIcon(Vars.SelectedPipePathFillColor)
const SelectableRunningWaterIcon = BuildRunningWaterIcon(Vars.SelectablePipePathFillColor)
const UnselectableRunningWaterIcon = BuildRunningWaterIcon(Vars.UnselectablePipePathFillColor)
 
const props = defineProps({
    irrigationSystem: {
        type: Object,
        required: true
    },
    pipePath: {
        type: Object,
        required: true
    },
    selectionStyle: {
        type: String,
        required: true,
        validator: function (value) { return value == "selected" || value == "selectable" || value == "unselectable" }
    },
    clickable: {
        type: Boolean,
        required: false,
        default: null
    },
    showIrrigationSystem: {
        type: Boolean,
        required: false,
        default: false
    },
    showLength: {
        type: Boolean,
        required: false,
        default: true
    },
    showElevationOverlay: {
        type: Boolean,
        required: false,
        default: false
    },
    showEndPoints: {
        type: Boolean,
        required: false,
        default: true
    }
})

const branchLengthLabels = computed(() => {
    const ret = []

    PipePathAlg.traversePathBranches(props.pipePath?.path || [], (branchPath) => {
        const lineString = GeoUtil.LatLngs.toLineString(branchPath)

        const meters = GeoUtil.GeoJson.length(lineString, { units: 'meters' })
        if (meters < 25) {
            return
        }
        const feet = meters * 3.28084

        const label = Math.round(feet) + " ft."

        const alongPoint = GeoUtil.GeoJson.along(lineString, meters * 0.75, { units: 'meters' })
        const latLng = GeoUtil.GeoJson.toLatLngs(alongPoint)

        ret.push({
            label, latLng
        })
    })

    return ret
})

const selectionStyleProps = computed(() => {
    switch (props.selectionStyle) {
        case 'selected':
            return {
                strokeColor: Vars.SelectedPipePathStrokeColor,
                strokeWeight: Number.parseFloat(Vars.SelectedPipePathStrokeWeight),
                zIndex: ZIndexes.PipePaths + 2,
                icons: [SelectedRunningWaterIcon]
            }
        case 'selectable':
            return {
                strokeColor: Vars.SelectablePipePathStrokeColor,
                strokeWeight: Vars.SelectablePipePathStrokeWeight,
                zIndex: ZIndexes.PipePaths + 1,
                icons: [SelectableRunningWaterIcon]
            }
        case 'unselectable':
            return {
                strokeColor: Vars.UnselectablePipePathStrokeColor,
                strokeWeight: Number.parseFloat(Vars.UnselectablePipePathStrokeWeight),
                zIndex: ZIndexes.PipePaths,
                icons: [UnselectableRunningWaterIcon]
            }
    }

    return {}
})

const showLengthLabel = computed(() => {
    if (!props.showLength) {
        return false
    }

    const path = props.pipePath.path
    if ((!path) || (path.length < 2)) {
        return false
    }

    const lengthInFeet = GeoUtil.LatLngs.length(path, { units: 'feet' })

    return lengthInFeet > 200
})

const path = computed(() => {
    return props.pipePath?.path || []
})

const pathSegmentPolylines = computed(() => {
    const ret = []

    function buildPathSegmentPolyline(path) {
        const options = {
            key: uuid.v1(),
            path,
            editable: false,
            clickable: props.clickable,
            strokeColor: selectionStyleProps.value.strokeColor,
            strokeWeight: selectionStyleProps.value.strokeWeight,
            icons: selectionStyleProps.value.icons,
            zIndex: selectionStyleProps.value.zIndex
        }

        let bufferOptions = null

        if (props.selectionStyle === 'selectable' || props.selectionStyle === 'selected') {
            bufferOptions = {
                key: uuid.v1(), path, editable: false, clickable: props.clickable,
                strokeWeight: 50, strokeOpacity: 0, zIndex: selectionStyleProps.value.zIndex + 1
            }
        }

        ret.push({ options, bufferOptions })
    }

    PipePathAlg.traversePathSegmentswithJunctions(props.pipePath.path,
        (ll0, ll1) => { buildPathSegmentPolyline([ll0, ll1]) })

    return ret
})

const endPoints = computed(() => {
    const ret = []

    const path = props.pipePath?.path || []
    PipePathAlg.traversePath(path, (latLng, index, branchPath, _isPrimaryBranch, traversalIndexes) => {
        const isLastPoint = index === (branchPath.length - 1)
        if (!isLastPoint) {
            return
        }

        ret.push({
            latLng, traversalIndexes, key: uuid.v1()
        })
    })

    return ret
})

const emit = defineEmits(['click', 'endclick'])

function endPointClicked(endPointWithMetadata) {
    emit('endclick', endPointWithMetadata)
}

function handleClick(e) {
    emit('click', this.pipePath)
}
</script>

<style lang="css" module>
/* .elevation_field {
    font-size: medium;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    background: rgba(0, 0, 0, 0.85);
    border-radius: var(--softer-border-radius);
    padding: 0.5em;
}


.drop_down_arrow :hover {
    -o-transform: rotate(720deg);
    -ms-transform: rotate(720deg);
    -moz-transform: rotate(720deg);
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
}

.elevation_field input {
    padding: 0px;
    margin: 0px;
    background: rgba(0, 0, 0, 0.0);
    color: var(--secondary-light);
    max-width: 2.5em;
    font-size: large;
    border: 1px solid var(--secondary-light);
    border-radius: var(--softer-border-radius);
    border-image: none !important;
    outline: none;

    cursor: pointer;

    transition: none !important;
    -moz-appearance: textfield;
    appearance: textfield;
}

.input_and_arrow_span {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.elevation_field i {
    cursor: pointer;
    margin-left: 0.5em;
    transition: 1s;
}

.elevation_field label {
    min-width: 0px;
    cursor: pointer;
}

.elevation_field input:active {
    background: rgba(0, 0, 0, 1.0);
    border: none !important;
    border-image: none !important;
}

.elevation_field input:focus {
    padding: 0.5em;
    font-size: large;
    border: 1.5px solid var(--secondary-light);
    border-image: none !important;
    cursor: initial;
    border-radius: var(--softer-border-radius);
}

.elevation_field input::-webkit-outer-spin-button,
.elevation_field input::-webkit-inner-spin-button {
    -webkit-appearance: none;
} */
</style>

export function getAllFieldRelatedObjects(fields, farm) {
   const connectedFieldIds = new Set();
   const connectedIrrigationSystemIds = new Set();
   const connectedFields = [];
   const connectedIrrigationSystems = [];

   fields.forEach((field) => {
      connectedFields.push(field);
      connectedFieldIds.add(field.id);

      const irrigationSystems = farm.irrigationSystems.filter(
         is => is.pipePaths.some(pp => pp.fieldId === field.id));
      connectedIrrigationSystems.push(...irrigationSystems);
   });    

   connectedIrrigationSystems.forEach(is => {
      connectedIrrigationSystemIds.add(is.id);

      is.pipePaths.forEach(pp => {
         if(connectedFieldIds.has(pp.fieldId)) {
            return;
         }
         
         const field = farm.fields.find(f => f.id === pp.fieldId);
         if(field) {
            connectedFields.push(field);
            connectedFieldIds.add(field.id);
         }
      });
   });
   
   const ret = {
      farm,
      connectedFields,
      connectedIrrigationSystems
   }

   connectedFields.forEach(f => {
      if (connectedFieldIds.has(f.id)) {
         return;
      }

      connectedFieldIds.add(f.id);
      ret.connectedFields.push(f);

      const other = getAllFieldRelatedObjects(f, farm);

      other.connectedFields.forEach(of => {
         if (!connectedFieldIds.has(of.id)) {
            connectedFieldIds.add(of.id);
            ret.connectedFields.push(of);
         }
      });

      other.connectedIrrigationSystemIds.forEach(ois => {
         if (!connectedFieldIds.has(ois.id)) {
            connectedIrrigationSystemIds.add(ois.id);
            ret.connectedIrrigationSystems.push(ois);
         }
      })
   })

   return ret;
}

import * as Sentry from '@sentry/vue'

function buildConsoleInstance() {
    console.log("Console Telemetry")
    return {
        log: console.log,
        warn: console.warn,
        error: console.error,
        setUser() {
        }
    }
}

function buildSentryInstance(app, { dsn, env = 'dev', tracesSampleRate = 1.0 }) {
    console.log(`Enabling Sentry Telemetry: ${dsn}/${env}/${tracesSampleRate}`)
    Sentry.init({
        app,
        dsn: dsn,
        environment: env,
        integrations: [
            // new Sentry.BrowserTracing({
            //     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            //     // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
            // }),
            // new Sentry.Replay({
            //     maskAllText: false,
            //     blockAllMedia: false,
            // }),
        ],
        // Performance Monitoring
        tracesSampleRate: Number.parseFloat(tracesSampleRate), //  Capture 100% of the transactions
        // Session Replay
        // replaysSessionSampleRate: 0.05, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })

    return {
        log(s) {
            Sentry.addBreadcrumb({
                message: s
            });
        },
        warn: Sentry.warn,
        error: Sentry.captureException,
        setUser(u) {
            console.log("Setting Telemetry User: " + u?.email || null)

            Sentry.setUser(u)
        }
    }
}

function buildInstance(app, params) {
    if (params?.dsn?.length) {
        return buildSentryInstance(app, params)
    }

    return buildConsoleInstance()
}

export const TelemetryPlugin = {
    install(app, params) {
        const instance = buildInstance(app, params)
        app.config.globalProperties.$telemetry = instance
        app.provide('$telemetry', instance)
    }
}

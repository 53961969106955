<template>
    <template>
        <pp4-polyline
            v-for="o of linePathsAndLabels"
            :key="o.linePathKey"
            :path="o.linePath"
            :zIndex="ZIndexes.Fields + 1"
            strokeColor="white"
            :clickable="false"
            strokeWeight="8"/>

        <pp4-label
            v-for="o in linePathsAndLabels"
            :key="o.labelKey"
            :labelText="o.labelText"
            :latLng="o.labelLatLng"
            :clickable="false"
            elementClass="distance_hint_label"/>
    </template>
</template>

<script>
import Pp4MapObject from '@/components/maps/Pp4MapObject'
import { ZIndexes } from '@/maps/ZIndexes'
import { GeoUtil } from '@/geo/GeoUtil'
import * as FormatAlg from '@/util/FormatAlg'

export default {
    extends: Pp4MapObject,
    props: {
        latLng: {
            type: Object,
            required: true,
            validator: function(value) { 
                return ! (value == null || Number.isNaN(value.lat) || Number.isNaN(value.lng))
            }
        },
        segmentPathsToCompare: {
            type: Array,
            required: true
        }
    },
    data: function() {
        return {
            ZIndexes
        }
    },
    computed: {
        linePathsAndLabels: (vm) => {
            const pt = GeoUtil.LatLngs.toGeoJsonPoint(vm.latLng)

            const matching = vm.segmentLineStringsToCompare.filter((ls) => {
                return GeoUtil.GeoJson.booleanPointOnLine(pt, ls)
            })

            const ret = []

            matching.forEach((ls, i) => {
                const latLngs = GeoUtil.GeoJson.toLatLngs(ls)
                const c0 = GeoUtil.LatLngs.toCoord(latLngs[0])
                const c1 = GeoUtil.LatLngs.toCoord(latLngs[1])
                const cm = GeoUtil.LatLngs.toCoord(vm.latLng)

                const d0 = GeoUtil.Coords.distance(c0, cm, {units: 'feet'})
                const d1 = GeoUtil.Coords.distance(c1, cm, {units: 'feet'})

                const mp0 = GeoUtil.Coords.midpoint(c0, cm)
                const mll0 = GeoUtil.GeoJson.toLatLngs(mp0)
                const mp1 = GeoUtil.Coords.midpoint(c1, cm)
                const mll1 = GeoUtil.GeoJson.toLatLngs(mp1)

                const MinDistanceInFeet = 10

                if(d0 > MinDistanceInFeet) {
                    ret.push({
                        linePathKey: 'HintLinePath:' + i + ':0',
                        linePath: [latLngs[0], vm.latLng], 
                        labelKey: 'HintLabel:' + i + ':0',
                        labelText: FormatAlg.leveeDistance(d0),
                        labelLatLng: mll0
                    })
                }

                if(d1 > MinDistanceInFeet) {
                    ret.push({
                        linePathKey: 'HintLinePath:' + i + ':1',
                        linePath: [latLngs[1], vm.latLng], 
                        labelKey: 'HintLabel:' + i + ':1',
                        labelText: FormatAlg.leveeDistance(d1),
                        labelLatLng: mll1
                    })
                }
            })

            return ret
        },
        segmentLineStringsToCompare: (vm) => vm.segmentPathsToCompare.map(GeoUtil.LatLngs.toLineString)
    },
    methods: {
        mapReady() {
        }
    }
}
</script>

<style lang="css">
.distance_hint_label {
    cursor: default;
    font-size: small;
    font-weight: bold;
    color: white;
    background: rgba(0, 0, 0, 0.8);
    padding: 0.5em;
    border-radius: var(--softer-border-radius);
    position: absolute;
    text-align: center;
}
</style>
<template>
    <pp4-polyline :path="indicatorPath" :strokeColor="Vars.UnselectablePipePathFillColor" :strokeOpacity="0"
        :icons="indicatorLineIcons">
    </pp4-polyline>

    <pp4-label v-if="showLabel" :latLng="wateringTimesLabelLocation" :elementClass="wateringTimesLabelClass"
        :labelText="wateringTimesString" />
</template>

<script setup>
import { computed } from 'vue'

import * as WateringTimeAlg from '@/util/WateringTimeAlg'
import { GeoUtil } from '@/geo/GeoUtil'
import Vars from '@/Vars'
import Pp4Label from '@/components/maps/Label'
import Pp4Polyline from '@/components/maps/Polyline'

const props = defineProps({
    path: {
        type: Array,
        required: true
    },
    grossAppliedInches: {
        type: Number,
        required: true
    },
    flowRate: {
        type: Object,
        required: false
    },
    alternatingFurrows: {
        type: Boolean,
        required: true,
    },
    irrigationSystem: {
        type: Object,
        required: true
    },
    desiredWateringTime: {
        type: Number,
        required: false
    },
    showLabel: {
        type: Boolean,
        required: false,
        default: true
    }
})

const pathGeo = computed(() => GeoUtil.LatLngs.toPolygon(props.path))

const indicatorPath = computed(() => {
    const ll0 = props.irrigationSystem.waterSourceLocation

    const point1 = GeoUtil.GeoJson.centerOfMass(pathGeo.value)
    const ll1 = GeoUtil.GeoJson.toLatLngs(point1)

    return [ll0, ll1]
})

const wateringTimesLabelLocation = computed(() => {
    return indicatorPath.value[1]
})

const pathAreaInSquareMeters = computed(() => GeoUtil.GeoJson.area(pathGeo.value))

const wateringTimeWarning = computed(() => {
    const waterableAreaInAcres = pathAreaInSquareMeters.value * 0.0002471052
    let fieldLayoutProps = {
        grossAppliedInches: props.grossAppliedInches,
        alternatingFurrows: props.alternatingFurrows
    }
    let wateringTime = WateringTimeAlg.wateringTimeInHours(
        waterableAreaInAcres, props.flowRate, fieldLayoutProps)
    
    return (props.desiredWateringTime * 1.05) < wateringTime // give it some breathing room
})

const wateringTimesLabelClass = computed(() => wateringTimeWarning.value ?
    'watering_time_label_warning' : 'watering_time_label')

const wateringTimesString = computed(() => {
    let fieldLayoutProps = {
        grossAppliedInches: props.grossAppliedInches,
        alternatingFurrows: props.alternatingFurrows
    }

    const waterableAreaInAcres = pathAreaInSquareMeters.value * 0.0002471052
    let ret = WateringTimeAlg.formattedWateringTimeAndFlowRate(waterableAreaInAcres, props.flowRate, fieldLayoutProps)
    if (wateringTimeWarning.value) {
        ret = '(!) ' + WateringTimeAlg.formattedWateringTimeAndFlowRate(waterableAreaInAcres, props.flowRate, fieldLayoutProps)
    }
    return ret
})

const indicatorLineIcons = computed(() => {
    let ret = []

    const DashedLineIcon = {
        icon: {
            path: 'M 0, -1.5 0,0',
            strokeOpacity: 1.0,
            scale: 4
        },
        offset: '0',
        repeat: '20px'
    }

    ret.push(DashedLineIcon)

    return ret
})
</script>

<style lang="css">
.watering_time_label {
    font-size: small;
    color: var(--selected-pipe-path-fill-color);
    transform: translate(0%, 50%);
    background: rgba(0, 0, 0, 0.9);
    padding: 0.5em;
    border-radius: var(--softer-border-radius);
    cursor: pointer;
}

.watering_time_label_warning {
    font-size: small;
    color: white;
    transform: translate(0%, 50%);
    background-color: var(--warning-orange);
    padding: 0.5em;
    border-radius: var(--softer-border-radius);
    cursor: pointer;
    text-align: center;
}
</style>

<template>
    <div id="transfer_farm">
      <select-farm-header title="Transfer Farm" descriptionText="Select farm you would like to transfer or transfer all." />

      <span class="filter mt-1 bg-0">
        <button @click="transferAll" class="mr-1">Transfer All</button>

        <span class="filter_div">
          <label v-show="search.length > 0" class=" mobile-show"
            v-text="`${this.filteredList.length}/${this.farms.length} farms`"></label>
        </span>
        <label v-show="search.length > 0" class="filter_bar mobile-hide mr-1"
          v-text="`Showing ${this.filteredList.length}/${this.farms.length} farms`"></label>

        <input class="filter_bar" type="text" onfocus="this.placeholder = ' '"
          onblur="this.placeholder='Filter Farms'" placeholder="Filter Farms" v-bind:value="search"
          @input='updateFilteredList($event.target.value)'>
        <button v-if="search.length > 0" @click="clearFilter()" class="ml-1">Clear</button>
      </span>

      <loading v-if="!farmsLoaded" label="Loading Farms..."/>

      <div v-show="filteredList.length > 0" class="display_farms">
        <div id="farms_list">
          <span class="accordion" v-for="(o) in filteredList" :key="o.farm.id" :class="{ active: o.selected }">
            <div class="accordion_header" @click="farmAccordionToggle(o.farm)">
              <label class="farm_label" :title="o.farm.name">{{ o.farm.name }}</label>
              <a v-show="!o.selected" class="icon" href="#"><i class="fa fa-angle-down"></i></a>
              <a v-show="o.selected" class="icon" href="#"><i class="fa fa-angle-up"></i></a>
            </div>

            <span v-if="o.selected" class="farm_menu">
              <div class="accordion_menu">
                <img class="farm_and_field_img" :alt="o.farm.name" :src="buildFarmPreviewImageUrl(o.farm)"
                  :to="{ path: '/FarmMapView/' + o.farm.id }" />
                <div class="farm_menu_buttons">
                  <a class="button widget_margin_top" @click="() => showTransferFarmDialog(o.farm)"><i
                      class="fa fa-share-square" />Transfer Farm</a>
                </div>
              </div>
              <span class="field_tiles">
                <div class="field_tile" v-for="(field, fieldIndex) in o.farm.fields" :key="fieldIndex">
                  <label class="field_label" for="field"> {{ field.name }}</label>
                  <img class="farm_and_field_img" :src="buildFieldPreviewImageUrl(field)">
                </div>
              </span>
            </span>
          </span>
        </div>
      </div>
    </div>
</template>

<script>
import TransferFarmDialog from '../components/TransferFarmDialog.vue'
import SelectFarmHeader from '../components/SelectFarmHeader.vue'

export default {
  components: {
    SelectFarmHeader
  },
  data: function () {
    return {
      search: '',
      showFarm: false,
      icon: false,
      selectedFarm: null,
      firstLoad: true
    }
  },
  computed: {
    farmsLoaded: vm => vm.$store.state.farmsLoaded,
    farms: vm => vm.$store.state.farms,
    puppetUser: (vm) => vm.$store.state.puppetUser,
    report: (vm) => vm.$store.state.reports,
    filteredList: (vm) => {
      const selectedFarm = vm.selectedFarm

      return vm.farms.reduce((accum, farm) => {
        if (!farm.name.toLowerCase().includes(vm.search.toLowerCase())) {
          return accum
        }

        const selected = selectedFarm != null && farm.id === selectedFarm.id

        accum.push({
          farm: selected ? selectedFarm : farm, selected
        })

        return accum
      }, [])
    },
    isAdmin: vm => vm.$auth.user['https://deltaplastics.com/roles'].some((role) => role == 'PP Admin')
  },
  methods: {
    updateFilteredList(value) {
      this.search = value
    },
    logoClicked() {
      let sendToDeltaPlastics = () => {
        window.location.href = "http://www.deltaplastics.com"
      }
      this.$dialog.confirm(sendToDeltaPlastics, {
        title: 'Continue to Delta Plastics home page?',
        confirmLabel: 'Yes',
        cancelLabel: 'No'
      })
    },
    async exitTransferFarms() {
      this.$root.$router.push({ name: 'SelectFarm' })
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      });
    },
    buildFarmPreviewImageUrl(farm) {
      return this.$staticImages.buildFarmUrl(farm)
    },
    buildFieldPreviewImageUrl(field) {
      const layout = field.layouts.find((layout) => layout.id == field.activeLayoutId)
      return this.$staticImages.buildFieldUrl(layout)
    },
    async showTransferFarmDialog(farm) {
      await this.$dialog.push(TransferFarmDialog, { farm })
    },
    async transferAll() {
      await this.$dialog.push(TransferFarmDialog, { })
    },
    async farmAccordionToggle(farm) {
      if ((this.selectedFarm != null) && (farm.id === this.selectedFarm.id)) {
        this.selectedFarm = null
        return
      }

      const fullFarm = await this.$store.getters.getFarm(farm.id)

      this.selectedFarm = fullFarm
    },
    clearFilter() {
      this.search = ''
    },
    isFarmSelected(farm) {
      return this.selectedFarm && this.selectedFarm.id == farm.id
    },
  },
  mounted: async function () {
    if (this.firstLoad) {
      await this.$store.dispatch('getFarmsFromService')
      this.firstLoad = false
    }
  }
}
</script>

<style lang="css" scoped>
@media only screen and (max-width: 690px) {
  .farm_menu {
    flex-wrap: wrap;
    margin: auto;
    overflow: hidden;
  }

  .delta_plastics_logo {
    max-width: 7em !important;
    max-height: 7em !important;
    margin-right: 5px !important;
  }

  .display_farms {
    flex-grow: 1;
    margin: var(--general-padding);
    border-radius: var(--general-border-radius);
    text-align: left;
    border: none;
    outline: none;
    transition: 0.4s;
    align-self: center;
  }

  .farms_list {
    background: none !important;
  }
}

.delta_plastics_logo {
  max-height: 13em;
  max-width: 13em;
  margin-right: 20px;
}

i {
  margin-right: 5px;
}

.filter {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  margin-bottom: var(--general-padding);
}


.filter_bar {
  min-width: 3em;
  text-align: center;
  white-space: nowrap;
  border-radius: var(--softer-border-radius);
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  font-family: var(--font-default);
  font-weight: bold;
  vertical-align: middle;
}


.icon {
  position: absolute;
  right: 0px;
  float: right;
}

.field_tiles {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-content: flex-start;
}

.filter_div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  vertical-align: middle;
  margin-top: var(--general-padding);
  margin-right: var(--general-padding);
  text-align: center;
}

.field_tile {
  background-color: var(--secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  padding: 6px;
  margin: 10px;
  border: 1.5px solid var(--primary-light);
  border-radius: var(--general-border-radius);
}

.farm_menu {
  display: inline-flex;
  flex-direction: row;
  text-decoration: none;
  overflow: hidden;
  min-width: 100%;
  text-align: center;
  background-color: var(--bg1);
  border-radius: var(--general-border-radius);
  align-items: flex-start;
  align-content: flex-start;
  cursor: initial;
}

#login_as {
  margin-top: 0.5em;
  text-decoration: underline;
  font-size: inherit;
  font-weight: bold;
}

.farm_label {
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  overflow: visible;
  float: left;
  line-height: 2em;
  vertical-align: middle;
}

.farm_and_field_img {
  max-width: 80vw;
  max-height: 80vw;
  width: 192px;
  height: 192px;
  border: 2px solid var(--primary-light);
  border-radius: var(--general-border-radius);
  margin: 5px;
  display: flex;
  align-self: center;
  cursor: pointer;
}

.field_label {
  max-width: 70vw;
  text-align: center;
  text-decoration: none;
  margin-top: 3px;
  white-space: nowrap;
  overflow: visible;
  float: left;
  vertical-align: middle;
}

select {
  font-family: 'FontAwesome';
  display: inline-block;
}


#app_menu {
  float: right;
  display: inline-flex
}

#transfer_farm {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  
  background-color: var(--bg0);
}

#farms_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap
}

.farm_menu_buttons {
  display: flex;
  flex-direction: column;
  margin: 5px;
}

.field_menu_button {
  font-size: 12px;
  border: #FFEACA solid 1px;
  border-radius: 3px;
  padding: 3px;
  margin-left: 2px;
  margin-right: 2px;
}

.field_menu_buttons {
  color: var(--secondary-light) !important;
  display: flex;
  flex-direction: row;
  margin: 5px;
  justify-content: space-evenly;
}

.fa {
  font-size: 1.3em;
}</style>

<template>
  <div class="boundary">
    <div v-for="(item, i) in ppFieldInfos" class="boundary-card" @click="openPpField(item.ppFieldId)" :title="item.name"
      :key="item.ppFieldId">
      <div style="text-align: center; background-color: var(--jd-green);">
        <div class="field-name">{{ item.name }}</div>
        <div class="boundary-name">{{ props.boundary.name }}</div>
      </div>

      <pp4-image :width="192" :height="192" :src="item.imageUri" />
    </div>

    <div v-for="(item, i) in boundaryInfos" class="boundary-card has-no-field" @click="openBoundaryInfoAsPpField(item)"
      :title="item.name">
      <div style="text-align: center; background-color: var(--jd-green);">
        <div class="field-name">{{ item.name }}</div>
        <div class="boundary-name">{{ props.boundary.name }}</div>
      </div>

      <pp4-image :width="192" :height="192" :src="item.imageUri" />
    </div>
  </div>

  <loading v-if="data.loadingField" />
</template>

<script setup>
import { reactive, computed, inject, nextTick } from 'vue'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

import { GeoUtil } from '@/geo/GeoUtil'
import PipePathAlg from '@/design/PipePathAlg'

const props = defineProps(['organizationId', 'field', 'boundary', 'ppFarm', 'selectedOrganizationHasJdAccess'])

const data = reactive({
  loadingField: false
})

const $store = useStore()
const $router = useRouter()
const $staticImages = inject('$staticImages')

const ppFieldInfos = computed(() => {
  const boundaryId = props.boundary.id

  let ret = props.ppFarm?.fields?.filter(f =>
    f?.jd?.boundaryId === boundaryId) || []

  ret = ret.map(f => {
    const activeLayout = f.layouts.find(l => l.id === f.activeLayoutId)
    if (!activeLayout) {
      return null
    }

    if (activeLayout?.path?.length < 4) {
      return null
    }

    const geoJson = GeoUtil.LatLngs.toPolygon(activeLayout.path)

    return {
      ppFieldId: f.id,
      field: props.field,
      name: f.name,
      geoJson, area: GeoUtil.GeoJson.area(geoJson),
      checksum: PipePathAlg.checksum(activeLayout.path),
      imageUri: $staticImages.buildFieldPathUrl(activeLayout.path)
    }
  }).filter(f => f ? true : false)

  ret.sort((a, b) => a.area - b.area)

  return ret
})

const ppFieldChecksums = computed(() => {
  const ret = new Set()

  ppFieldInfos.value.forEach(f => {
    ret.add(f.checksum)
  })

  return ret
})

const boundaryInfos = computed(() => {
  let ret = []

  const b = props.boundary
  const mps = props.boundary.multipolygons

  const multi = (mps.length > 1) || mps.some(mp => {
    const exterior = mp.rings.filter(r => r.type !== 'interior')
    return exterior.length > 1
  })

  mps.forEach(mp => {
    const exterior = mp.rings.filter(r => r.type !== 'interior')

    exterior.forEach(ring => {
      if (ring.type === 'interior') {
        return
      }

      const path = ring.points.map(jdll => ({ lat: jdll.lat, lng: jdll.lon }))
      const geoJson = GeoUtil.LatLngs.toPolygon(path)

      {
        let diff = geoJson
        ppFieldInfos.value.forEach(ppField => {
          if(! diff) {
            return
          }

          diff = GeoUtil.GeoJson.difference(diff, ppField.geoJson)
        })

        const remainingAcres = diff ? GeoUtil.GeoJson.areaInAcres(diff) : 0
        if(remainingAcres < 1) {
          return
        }
      }

      ret.push({
        field: props.field, name: "N/A", path, geoJson, imageUri: $staticImages.buildFieldPathUrl(path),
        area: GeoUtil.GeoJson.area(geoJson),
        checksum: PipePathAlg.checksum(path)
      })
    })
  })

  ret.sort((a, b) => a.area - b.area) // JD always sorts boundary polys by area in their UI

  // set the name after sorting...
  ret.forEach((info, index) => {
    info.name = (!multi) ? `${props.field.name} ${b.name}`
      : `${props.field.name} ${b.name} (${index + 1})`
  })

  ret = ret.filter(info => !ppFieldChecksums.value.has(info.checksum))

  return ret
})

function openPpField(ppFieldId) {
  if (!props.selectedOrganizationHasJdAccess) {
    window.alert('Pipe Planner Software Connection Renewal Required to Modify Designs\n\n Click "Activate" to Begin')
    return
  }


  if (props.ppFarm?.id && ppFieldId) {
    const to = { path: `/FarmMapView/${props.ppFarm.id}/${ppFieldId}` };
    $router.push(to);
  }
}

async function openBoundaryInfoAsPpField(item) {
  if (!props.selectedOrganizationHasJdAccess) {
    window.alert('Pipe Planner Software Connection Renewal Required to Modify Designs\n\n Click "Activate" to Begin')
    return
  }
  
  const fieldName = item.name

  try {
    data.loadingField = true

    const ppFarmAndField = await ($store.getters['jd/getPpFarmAndField'](
      props.organizationId, props.field.id, props.boundary.id, fieldName, item.path))

    const { farmId, fieldId } = ppFarmAndField;

    if (farmId && fieldId) {
      await $store.dispatch('refreshSelectedFarm')

      nextTick(() => {
        $router.push({ name: 'FarmMapView', params: { farmId, fieldId } })
      })
    }
  }
  finally {
    data.loadingField = false
  }
}

</script>

<style lang="css" scoped>
.boundary {
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  position: relative;
  padding-left: 3em;
}

.has-no-field {
  filter: brightness(0.71);
  /* filter: initial !important; */
}

.boundary img {
  border: var(--softer-border-width) solid var(--secondary-light);
  border-radius: var(--general-border-radius);
}

.boundary_label {
  position: absolute;
  text-align: center;
  bottom: 0px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  user-select: none;
  text-overflow: ellipsis;
  margin: var(--general-padding);
  left: var(--general-padding);
  right: var(--general-padding);
  bottom: var(--general-padding);
  background: rgba(255, 255, 255, 0.9);
  color: black;
  font-weight: bold;
}

.field_name {
  font-size: x-small;
}

.field-name {
  max-width: 192px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.boundary-name {
  line-height: 1.4em;
  font-size: xx-small;
}

.boundary-card {
  margin-left: -2em;
  transition: 0.2s !important;
  border: solid var(--jd-green);
  background: var(--jd-green);
}

.boundary-card:not(:first-child) {
  position: relative;
  background: orange;
}

/* .boundary-card:not(:last-child) {
  filter: brightness(0.71);
} */

.boundary-card:hover,
.boundary-card:focus-within {
  transform: scale(1.0);
}

.boundary-card:hover~.boundary-card,
.boundary-card:focus-within~.boundary-card {
  filter: brightness(0.71) !important;
}

.boundary-card:hover {
  border: solid var(--jd-yellow) !important;
  z-index: 99;
  scale: 1.05;
}

.boundary-img {
  border-color: var(--jd-green);
}

.boundary-img-loading {
  width: 192px;
  height: 192px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.boundary-img-error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 192px;
  height: 192px;
  color: white;
}
</style>

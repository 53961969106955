<template>
  <template>
    <template v-if="props.furrowSet">
      <furrow-set v-if="props.furrowSet" :fieldLayout="props.fieldLayout" :furrowSet="props.furrowSet"
        selectionStyle="selected" :clickable="false" :showLabel="true" />

      <furrow-set v-for="unselectableFurrowSet in unselectableFurrowSets" :fieldLayout="fieldLayout"
        :key="unselectableFurrowSet.id" :furrowSet="unselectableFurrowSet" selectionStyle="unselectable" />
    </template>

    <template v-else>
      <pp4-field :field="props.field" :fieldLayout="props.fieldLayout" ref="field" :clickable="false"
        selectionStyle="selected" :showLeveePaths="true" :showLabel="false" />
    </template>

    <template v-if="data.editMode === GeometryEditMode.None">
      <water-source v-for="irrigationSystem in irrigationSystems" :key="irrigationSystem.id"
        @click="handleMapOrWaterSourceClick($event, irrigationSystem)" :clickable="!data.selectedIrrigationSystem"
        @mouseover="handleMouseOverForExistingIrrigationSystem($event, irrigationSystem)"
        @mouseout="handleMouseOutForExistingIrrigationSystem($event, irrigationSystem)"
        :location="irrigationSystem.waterSourceLocation" :name="irrigationSystem.name" :selectionStyle="data.hoveredIrrigationSystem == irrigationSystem ||
      data.selectedIrrigationSystem == irrigationSystem
      ? 'selected'
      : 'selectable'
      " />
      <pipe-segment v-if="data.latLngs.length > 0" :path="data.latLngs" :preview="false" />
      <pipe-segment v-if="previewPath !== null" :path="previewPath" :preview="false" />
    </template>

    <template v-else>
      <edit-pipe-path-path v-model:editMode="data.editMode" v-model:path="data.latLngs"
        @endclick="showEndOfPipeSelectDialog" :snapCallback="finalSnapCallback"
        :snapAndFillCallback="finalSnapAndFillCallback" />

      <water-source v-if="data.selectedIrrigationSystem" :location="data.selectedIrrigationSystem.waterSourceLocation"
        :clickable="!data.selectedIrrigationSystem" :name="data.selectedIrrigationSystem.name"
        selectionStyle="selected" />
    </template>

    <context-menu>
      <icon-button v-if="jdAvailable" :disabled="jdDisabled" label="Complete with Guidance Line"
        @click="importJdGuidanceLines" description="Import associated John Deere Guidance lines as levees" class="mb-1"
        :jd="true">
        <img :src="GuidanceLinesIcon" :draggable="false">
      </icon-button>

      <toggle-button v-if="!elevationModeHidden" description="Add Elevation Manually" label="Elevation"
        :disabled="elevationModeDisabled" :active="elevationModeActive" faIcon="fa-area-chart"
        @click="toggleElevationMode" />
      <toggle-button :class="elevationModeHidden ? '' : 'button_margin_top'" description="Toggle Remove Points Mode"
        label="Remove Points" :disabled="removeModeDisabled" :active="removeModeActive" @click="toggleRemoveMode()">
        <img :src="RemoveLinePointsIcon" draggable="false" />
      </toggle-button>
      <toggle-button label="Snap" :disabled="snapDisabled" :active="data.snapActive"
        description="Snap Points to Field Bounds" class="button_margin_top" @click="toggleSnap()">
        <img :src="SnapIcon" draggable="false" />
      </toggle-button>
      <toggle-button label="Add Branch" description="Add Branch -- Requires a Junction" class="button_margin_top"
        :disabled="!addJunctionBranchEnabled" @click="addJunctionBranchEnabled ? addJunctionBranch() : null">
        <img :src="AddIrrigationSystemIcon" draggable="false" />
      </toggle-button>
      <icon-button label="Save" description="Save" class="button_group_margin_top" :useSaveIcon="true"
        v-bind:disabled="!saveActive" @click="save()" />
      <icon-button label="Cancel" description="Cancel" class="button_margin_top" :useCancelIcon="true"
        @click="cancel()" />
    </context-menu>

    <end-of-pipe-select-dialog v-if="data.endPointToEdit" @done="endOfPipeEditDone" @cancel="cancelEndOfPipeEdit"
      v-model="data.endPointToEdit" />

    <elevation-point-overlay v-if="elevationOverlayLatLngs?.length" :clickable="true"
      :latLngsWithElevation="elevationOverlayLatLngs" @click="handleMapOrWaterSourceClick" @dblclick="handleDblClick" />

    <pp4-dialog v-if="data.showJdGuidanceImportDialog">
      <jd-guidance-lines-import :boundsGeo="innerBoundsGeo" :organizationId="props.field.jd.organizationId"
        type="AdaptiveCurve" :single="true" :fieldId="props.field.jd.fieldId" @cancel="cancelJdGuidanceLinesImport"
        @done="completeWithJdGuidancePath" />
    </pp4-dialog>
  </template>
</template>

<script setup>
import Pp4Field from '@/components/maps/Pp4Field'
import FurrowSet from '@/components/maps/FurrowSet'
import ElevationPointOverlay from '@/components/maps/ElevationPointOverlay'
import WaterSource from '@/components/maps/irrigation_systems/WaterSource'
import PipePathAlg from '@/design/PipePathAlg'
import { BuildSnapCallback, BuildSnapAndFillCallback } from '@/geo/PipeSnapAlg'

import { GeoUtil } from '@/geo/GeoUtil'
import { InlineTee, Pp4Uuid, PipePath, LatLng, FlowRate, GeometryEditMode, PipePathEditMode, FieldMode } from '@/store/Dto'

import RemoveLinePointsIcon from '@/assets/remove_line_points_icon.svg'
import SnapIcon from '@/assets/snap_icon.svg'
import AddIrrigationSystemIcon from '@/assets/add_irrigation_system_icon.png'

import { reactive, computed, onMounted, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
import { useMaps } from '../../../composables/useMaps'

import GuidanceLinesIcon from '@/assets/guidance_lines.svg'

const $store = useStore()

const maps = useMaps()

const props = defineProps({
  field: {
    type: Object,
    required: true
  },
  fieldLayout: {
    type: Object,
    required: true
  },
  furrowSet: {
    type: Object,
    required: false,
    default: null
  }
})

const data = reactive({
  editMode: GeometryEditMode.None,
  RemoveLinePointsIcon,
  SnapIcon,
  AddIrrigationSystemIcon,
  GeometryEditMode,
  cursorLocation: null,
  hoveredIrrigationSystem: null,
  selectedIrrigationSystem: null,
  latLngs: [],
  guidanceLineAddedAtIndex: -1,
  snapActive: true,
  elevationOverlay: false,
  endPointToEdit: null,
  showJdGuidanceImportDialog: false
})

const jdAvailable = computed(() => {
  return props.field?.jd ? true : false
})
const jdDisabled = computed(() => {
  return data.latLngs.length === 0
})
const innerBoundsGeo = computed(() => {
  if (props.furrowSet) {
    return GeoUtil.LatLngs.toPolygon(props.furrowSet.path)
  }

  return GeoUtil.LatLngs.toPolygon(props.fieldLayout.path)
})
const elevationOverlayLatLngs = computed(() => {
  return $store.state.mapObjects.debug.latLngsWithElevation
})

function importJdGuidanceLines() {
  data.showJdGuidanceImportDialog = true
}
function completeWithJdGuidancePath(guidancePath) {
  data.showJdGuidanceImportDialog = false

  if (!guidancePath) {
    return
  }

  if (guidancePath.length < 2) {
    return
  }

  const startingLatLng = data.latLngs[data.latLngs.length - 1]
  const startingPoint = GeoUtil.LatLngs.toGeoJsonPoint(startingLatLng)
  const guidanceLs = GeoUtil.LatLngs.toLineString(guidancePath)

  const guidanceLsClosestPoint = GeoUtil.GeoJson.nearestPointOnLineString(guidanceLs, startingPoint)
  const closestPointSplitter = GeoUtil.GeoJson.getSplitLineAlongBearingFromPoint(
    props.fieldLayout.furrowBearing, guidanceLsClosestPoint
  )
  const lineSplit = GeoUtil.GeoJson.lineSplit(guidanceLs, closestPointSplitter)
  const lines = lineSplit.features?.length ?
    lineSplit.features : [guidanceLs]

  // $store.dispatch('mapObjects/debug/setGeoJsonPolys', lines)

  const potentialLines = []
  lines.forEach(line => {
    const coords = line.geometry.coordinates
    const firstLatLng = GeoUtil.Coords.toLatLng(coords[0])
    const lastLatLng = GeoUtil.Coords.toLatLng(coords[coords.length - 1])
    const firstDist = GeoUtil.LatLngs.distance(firstLatLng, startingLatLng)
    const lastDist = GeoUtil.LatLngs.distance(lastLatLng, startingLatLng)

    // reverse the line if necessary to get the closest starting point
    line.geometry.coordinates =
      firstDist < lastDist ? coords : coords.reverse()

    const trimmedLs = GeoUtil.GeoJson.trimTail(line, innerBoundsGeo.value)
    const lengthInMeters = GeoUtil.GeoJson.length(trimmedLs, { units: 'meters' })

    if (lengthInMeters < 10) {
      return
    }

    const intersects = GeoUtil.GeoJson.booleanIntersects(trimmedLs, innerBoundsGeo.value)
    if(! intersects) {
      const within = GeoUtil.GeoJson.booleanWithin(trimmedLs, innerBoundsGeo.value)
      if(! within) {
        return
      }
    }

    potentialLines.push(trimmedLs)
  })

  if (!potentialLines.length) {
    return
  }

  // $store.dispatch('mapObjects/debug/setGeoJsonPolys', potentialLines)

  if (data.guidanceLineAddedAtIndex > 0) {
    data.latLngs.splice(data.guidanceLineAddedAtIndex)
  }

  data.guidanceLineAddedAtIndex = data.latLngs.length

  // It's tee time!
  if (potentialLines.length > 1) {
    const closestLatLng = GeoUtil.GeoJson.toLatLngs(guidanceLsClosestPoint)

    const teePaths = potentialLines.map(teePath => {
      return [closestLatLng, ...GeoUtil.GeoJson.toLatLngs(teePath)]
    })

    const tee = new InlineTee({
      id: Pp4Uuid(),
      latLng: closestLatLng,
      paths: teePaths
    })

    data.latLngs.push(tee)
  }
  else { // just a single possible line
    data.latLngs.push(...GeoUtil.GeoJson.toLatLngs(potentialLines[0]))
  }

  data.editMode = GeometryEditMode.EditPoints
}
function cancelJdGuidanceLinesImport() {
  data.showJdGuidanceImportDialog = false
}

const flowRateId = computed(() => {
  const primaryFlowRateId = props.fieldLayout.primaryFlowRateId

  if (!data.selectedIrrigationSystem) {
    return primaryFlowRateId
  }

  if (data.selectedIrrigationSystem.flowRates.some((fr) => fr.id === primaryFlowRateId)) {
    return primaryFlowRateId
  }

  return data.selectedIrrigationSystem.flowRates[0].id
})

const fieldGeo = computed(() => {
  return GeoUtil.LatLngs.toPolygon(props.fieldLayout.path)
})

const furrowSetGeo = computed(() => {
  return props.furrowSet ? GeoUtil.LatLngs.toPolygon(props.furrowSet.path) : null
})

const latLngsWithElevation = computed(() => {
  return $store.state.mapObjects.debug.latLngsWithElevation
})

const snapThresholdInMeters = computed(() => {
  return $store.state.preferences.pipe.snapThresholdInMeters
})

const offsetFromFieldInterior = computed(() => {
  return $store.state.preferences.pipe.snapOffsetFromFieldInteriorInMeters
})

const snapDisabled = computed(() => {
  if (data.editMode === GeometryEditMode.RemovePoints) {
    return true
  }

  if (data.editMode === GeometryEditMode.EditElevation) {
    return true
  }

  if (data.editMode === GeometryEditMode.None) {
    if (data.latLngs.length < 1) {
      return true
    }
  }

  if (data.editMode === PipePathEditMode.AddJunctionBranch) {
    if (data.latLngs.length < 1) {
      return true
    }
  }

  return false
})

const snapCallback = computed(() => {
  const snapGeos = []

  if (furrowSetGeo.value) {
    snapGeos.push(furrowSetGeo.value)

    const splitPaths = props.fieldLayout.furrowSetDetails.splitPaths || []

    snapGeos.push(...splitPaths.map(GeoUtil.LatLngs.toLineString))
  }

  snapGeos.push(fieldGeo.value)

  return BuildSnapCallback(
    snapGeos,
    latLngsWithElevation.value,
    snapThresholdInMeters.value,
    offsetFromFieldInterior.value
  )
})

const finalSnapCallback = computed(() => {
  return data.snapActive ? snapCallback.value : null
})

const snapAndFillCallback = computed(() => {
  const snapGeos = [fieldGeo.value]

  if (latLngsWithElevation.value?.length >= 2) {
    const ls = GeoUtil.LatLngs.toLineString(latLngsWithElevation.value)
    snapGeos.push(ls)
  }

  // Now that split paths are going away the alg performs better without this
  // if(furrowSetGeo.value) {
  //   snapgeos.push(furrowSetGeo.value)

  //   const splitPaths = props.fieldLayout.furrowSetDetails.splitPaths || []

  //   snapgeos.push(... splitPaths.map(GeoUtil.LatLngs.toLineString))
  // }

  return BuildSnapAndFillCallback(snapGeos, latLngsWithElevation.value, offsetFromFieldInterior.value)
})

const finalSnapAndFillCallback = computed(() => {
  return data.snapActive ? snapAndFillCallback.value : null
})

const elevationModeHidden = computed(() => {
  return props.field.mode === FieldMode.Levees
})

const elevationModeDisabled = computed(() => {
  return (
    data.editMode !== GeometryEditMode.EditPoints &&
    data.editMode !== PipePathEditMode.EditElevation
  )
})

const elevationModeActive = computed(() => {
  return data.editMode === PipePathEditMode.EditElevation
})

const removeModePossible = computed(() => {
  return PipePathAlg.removeModePossible(data.latLngs)
})

const removeModeDisabled = computed(() => {
  if (
    data.editMode !== GeometryEditMode.RemovePoints &&
    data.editMode !== GeometryEditMode.EditPoints
  ) {
    return true
  }

  return !removeModePossible.value
})

const removeModeActive = computed(() => {
  return data.editMode === GeometryEditMode.RemovePoints
})

const addJunctionBranchEnabled = computed(() => {
  if (data.editMode === PipePathEditMode.AddJunctionBranch) {
    return false
  }

  let path = data.latLngs
  if (!path.length) {
    return false
  }

  const lastLatLng = path[path.length - 1]

  return PipePathAlg.isJunction(lastLatLng)
})

function showEndOfPipeSelectDialog(o) {
  const { branchPath, latLng } = PipePathAlg.lookupFromTraversalIndexes(
    data.latLngs, o.traversalIndexes)

  data.endPointToEdit = latLng
}

function endOfPipeEditDone() {
  data.endPointToEdit = null
  data.latLngs = data.latLngs.slice() // need to trigger an update
}

function cancelEndOfPipeEdit() {
  data.endPointToEdit = null
}

const saveActive = computed(() => {
  return data.latLngs.length >= 2
})

const irrigationSystems = computed(() => {
  return $store.state.selectedFarm.irrigationSystems.filter(
    (irrigationSystem) =>
      irrigationSystem.flowRates != null && irrigationSystem.flowRates.length > 0
  )
})

const irrigationSystemsWithoutFlowRates = computed(() => {
  return $store.state.selectedFarm.irrigationSystems.filter(
    (irrigationSystem) =>
      irrigationSystem.flowRates != null && irrigationSystem.flowRates[0] == undefined
  )
})

const unselectableFurrowSets = computed(() => {
  return $store.state.mapObjects.unselectableFurrowSets
})


// const pipePath = computed(() => {
//   if (data.latLngs.length >= 2) {
//     const ret = new PipePath({
//       id: Pp4Uuid(),
//       fieldId: props.field.id,
//       fieldLayoutId: props.fieldLayout.id,
//       path: data.latLngs,
//       flowRateId: flowRateId
//     })

//     if (furrowSet) {
//       ret.furrowSetId = furrowSet.id
//     }

//     return ret
//   }

//   return null
// })

const previewPath = computed(() => {
  if (!data.cursorLocation) {
    return null
  }

  if (data.latLngs.length < 1) {
    return null
  }

  let latLngs = []

  if (data.snapActive && finalSnapAndFillCallback.value) {
    const lastLatLng = data.latLngs[data.latLngs.length - 1]
    const snappedLatLng = snapIfNecessary(data.cursorLocation)
    const snappedAndFilledLatLngs = finalSnapAndFillCallback.value(lastLatLng, snappedLatLng, 0,
      false)

    latLngs.push(...snappedAndFilledLatLngs)
  } else {
    latLngs = [data.latLngs[data.latLngs.length - 1]]
    latLngs.push(data.cursorLocation)
  }

  return latLngs
})

const clearCursorLocation = () => {
  data.cursorLocation = null
}

const updateLatLngsIfNecessary = (latLngs) => {
  if (latLngs.length < 2) {
    return
  }

  data.latLngs = latLngs
}

function snapIfNecessary(latLng) {
  return snapDisabled.value || (!snapCallback.value) ? latLng : snapCallback.value(latLng)
}

const toggleRemoveMode = () => {
  if (data.editMode === GeometryEditMode.RemovePoints) {
    data.editMode = GeometryEditMode.EditPoints
  } else {
    data.editMode = GeometryEditMode.RemovePoints
  }
}

function toggleSnap() {
  data.snapActive = !data.snapActive
}

function addJunctionBranch() {
  data.editMode = PipePathEditMode.AddJunctionBranch
}

const toggleElevationMode = () => {
  if (data.editMode === PipePathEditMode.EditElevation) {
    data.editMode = GeometryEditMode.EditPoints
  } else {
    data.editMode = PipePathEditMode.EditElevation
  }
  data.elevationOverlay = !data.elevationOverlay
}

async function save() {
  const instance = new PipePath({
    id: Pp4Uuid(),
    fieldId: props.field.id,
    fieldLayoutId: props.fieldLayout.id,
    path: data.latLngs,
    flowRateId: flowRateId.value
  })

  if (props.furrowSet) {
    instance.furrowSetId = props.furrowSet.id
  }

  await $store.dispatch('addPipePath', {
    irrigationSystem: toRaw(data.selectedIrrigationSystem),
    pipePath: instance
  })
}

const cancel = () => {
  $store.dispatch('returnToSelectedFieldOrFurrowSet')
}

const handleMouseOverForExistingIrrigationSystem = ($event, irrigationSystem) => {
  data.hoveredIrrigationSystem = irrigationSystem
}

const handleMouseOutForExistingIrrigationSystem = ($event, irrigationSystem) => {
  data.hoveredIrrigationSystem = null
}

const handleMapOrWaterSourceClick = (e, irrigationSystem) => {
  const tapShouldBeIgnoredBecauseNoIrrigationSytemIsSelected =
    data.selectedIrrigationSystem == null && irrigationSystem == null
  if (tapShouldBeIgnoredBecauseNoIrrigationSytemIsSelected) {
    return
  }

  if (data.editMode !== GeometryEditMode.None) {
    return
  }

  const googleLatLng = e.latLng

  let latLng = new LatLng(googleLatLng.lat(), googleLatLng.lng())

  if (Number.isFinite(googleLatLng.elevationInFeet))
    latLng.elevationInFeet = googleLatLng.elevationInFeet

  // user tapped an irrigation system and none is selected
  if (data.latLngs.length === 0 && irrigationSystem != null) {
    data.selectedIrrigationSystem = irrigationSystem
    data.latLngs = [new LatLng(irrigationSystem.waterSourceLocation)]
    data.cursorLocation = null
    return
  }

  if (data.snapActive) {
    const lastLatLng = Object.assign({}, data.latLngs[data.latLngs.length - 1])
    const snappedLatLng = Object.assign({}, snapIfNecessary(latLng))
    const snappedAndFilledLatLngs = snapAndFillCallback.value(lastLatLng, snappedLatLng)

    if (snappedAndFilledLatLngs.length > 1) {
      snappedAndFilledLatLngs.splice(0, 1)
    }

    data.latLngs = [...data.latLngs, ...snappedAndFilledLatLngs]
  } else {
    data.latLngs = [...data.latLngs, latLng]
  }

  updateLatLngsIfNecessary(data.latLngs)
}

const handleDblClick = (e) => {
  if (data.latLngs.length < 2) {
    return
  }

  data.editMode = GeometryEditMode.EditPoints

  const needs = PipePathAlg.buildInterpolationNeeds(data.latLngs)
  if (needs.length > 0) {
    // console.warn(needs) // TODO: maybe add to UI somehow?
    return
  }

  PipePathAlg.interpolateElevationInFeet(data.latLngs)

  data.latLngs = data.latLngs.slice()
}

function updateHelpKey() {
  if (data.editMode === GeometryEditMode.None) {   // a.k.a Add Mode
    if (data.latLngs.length === 0) {
      $store.dispatch('mapObjects/setHelpKey', 'begin_add_pipe')
    } else {
      $store.dispatch('mapObjects/setHelpKey', 'continue_or_complete_add_pipe')
    }

    return
  }

  // The rest is lifted from EditPipePath, make common somehow!

  if (data.editMode === PipePathEditMode.RemovePoints) {
    $store.dispatch("mapObjects/setHelpKey", null);
    return;
  }


  if (data.editMode === PipePathEditMode.EditElevation) {
    // this is handled inside PipePath.vue
    // $store.dispatch("mapObjects/setHelpKey", 'edit_pipe_path_elevation');
    return;
  }

  if (data.editMode !== PipePathEditMode.EditPoints) {
    $store.dispatch("mapObjects/setHelpKey", null);
    return;
  }

  if (data.editMode === PipePathEditMode.AddJunctionBranch) {
    $store.dispatch('mapObjects/setHelpKey', 'add_junction_branch_1')
    return
  }

  if (data.latLngs.length < 1) {
    $store.dispatch("mapObjects/setHelpKey", 'edit_pipe')
    return;
  }

  const lastLatLng = data.latLngs[data.latLngs.length - 1];
  const endsWithJunction = PipePathAlg.isJunction(lastLatLng);

  if (!endsWithJunction) {
    $store.dispatch("mapObjects/setHelpKey", "edit_pipe");
    return;
  }

  const branches = PipePathAlg.junctionBranches(lastLatLng);
  const branchCount = branches.length;

  const addBranchCallback = () => {
    addJunctionBranch();
  };

  if (branchCount === 0) {
    $store.dispatch("mapObjects/setHelpKeyAndParams", {
      key: "edit_pipe_with_junction_and_no_branches",
      params: { branchCount, addBranchCallback },
    });
  } else if (branchCount === 1) {
    $store.dispatch("mapObjects/setHelpKeyAndParams", {
      key: "edit_pipe_with_junction_and_one_branch",
      params: { branchCount, addBranchCallback },
    });
  }

}

watch(() => [data.editMode, data.latLngs], updateHelpKey)

onMounted(() => {
  updateHelpKey()
  if (irrigationSystemsWithoutFlowRates) {
    irrigationSystemsWithoutFlowRates.value.forEach((is) => {
      is.flowRates.push(new FlowRate('Flow Rate 1', 1000))
    })
  }

  maps.map.setOptions({
    draggableCursor: 'default',
    disableDoubleClickZoom: true
  })

  maps.doDragHack()

  maps.addMapDomListener('mousemove', (e) => {
    let googleLatLng = e.latLng
    let latLng = new LatLng(googleLatLng.lat(), googleLatLng.lng())

    data.cursorLocation = latLng
  })

  maps.addMapListener('mouseout', () => {
    clearCursorLocation()
  })

  maps.addMapListener('click', (e) => handleMapOrWaterSourceClick(e))

  maps.addMapListener('dblclick', (e) => {
    handleDblClick(e)
  })
})
</script>

<style lang="css"></style>

<template>
  <template>
    <pp4-field
      :field="field"
      key="selectedFieldForEditing"
      :fieldLayout="fieldLayout"
      selectionStyle="selected"
      :clickable="true"
      :showFurrowDirection="false"
      :showLabel="false"
      :showLabelOnMouseover="false"
    />

    <furrow-set
      v-for="(furrowSet, i) in furrowSets"
      selectionStyle="selected"
      :key="i"
      :furrowSet="furrowSet"
      :fieldLayout="fieldLayout"
      :showLabel="false"
      :flowRate="primaryFlowRateForField"
    />

    <split-point
      v-for="(splitPoint, index) in splitPoints"
      v-on:split-point-dragged="splitPointDragged"
      v-on:split-point-moved="splitPointMoved"
      v-on:split-point-removed="splitPointRemoved"
      :key="index"
      :latLng="splitPoint"
      :index="index"
      :fieldLayout="fieldLayout"
    />

    <watering-times
      v-for="o in wateringTimePathsAndIrrigationSystems"
      :key="'WateringTme:' + o.id"
      :grossAppliedInches="fieldLayout.grossAppliedInches"
      :alternatingFurrows="fieldLayout.alternatingFurrows"
      :desiredWateringTime="fieldLayout.desiredWateringTimeInHours"
      :path="o.path"
      :flowRate="flowRate"
      :irrigationSystem="o.irrigationSystem"
      :showLabel="true"
    />

    <water-source
      v-if="this.irrigationSystem"
      :location="this.irrigationSystem.waterSourceLocation"
      :name="this.irrigationSystem.name"
      :clickable="false"
      :showLabel="true"
      selectionStyle="unselectable"
    />
  </template>
</template>

<script>
import FurrowSet from '@/components/maps/FurrowSet'
import SplitPoint from '@/components/maps/edit_furrow_sets/SplitPoint'
import WaterSource from '@/components/maps/irrigation_systems/WaterSource'
import WateringTimes from '@/components/maps/irrigation_systems/WateringTimes'

import { FurrowSetAlg } from '@/geo/FurrowSetAlg'
import { GeoUtil } from '@/geo/GeoUtil'

export default {
  props: {
    farm: {
      type: Object,
      required: true
    },
    field: {
      type: Object,
      required: true
    },
    fieldLayout: {
      type: Object,
      required: true
    }
  },
  mounted() {
    const flowRateId = this.fieldLayout.primaryFlowRateId
    this.farm.irrigationSystems.find((is) =>
      is.flowRates.find((fr) => {
        if (fr.id == flowRateId) {
          this.irrigationSystem = is
        }
      })
    )
  },
  data: function () {
    return {
      modifiedFurrowSets: null,
      irrigationSystem: null
    }
  },
  components: {
    FurrowSet,
    SplitPoint,
    WaterSource,
    WateringTimes
  },
  computed: {
    flowRate: (vm) => {
      if (!vm.irrigationSystem) {
        return null
      }

      return vm.irrigationSystem.flowRates.find((fr) => fr.id == vm.fieldLayout.primaryFlowRateId)
    },
    primaryFlowRateForField: (vm) => {
      const flowRateId = vm.fieldLayout.primaryFlowRateId

      for (const irrigationSystem of vm.farm.irrigationSystems) {
        for (const flowRate of irrigationSystem.flowRates) {
          if (flowRate.id === flowRateId) {
            return flowRate
          }
        }
      }

      return null
    },
    furrowSets: (vm) => {
      if (vm.modifiedFurrowSets) {
        return vm.modifiedFurrowSets
      }

      return vm.fieldLayout.furrowSetDetails.resultingFurrowSets
    },
    irrigationSystemsCloseEnoughTofield: (vm) => {
      const maxDistanceInFeet = vm.$store.state.preferences.pipe.maxDistanceFromFieldInFeet
      const fieldLineString = GeoUtil.LatLngs.toLineString(vm.fieldLayout.path)

      return vm.farm.irrigationSystems.filter((irrigationSystem) => {
        const pointGeo = GeoUtil.LatLngs.toGeoJsonPoint(irrigationSystem.waterSourceLocation)

        const distanceInFeet = GeoUtil.GeoJson.pointToLineDistance(pointGeo, fieldLineString, {
          units: 'feet'
        })

        return distanceInFeet < maxDistanceInFeet
      })
    },
    wateringTimePathsAndIrrigationSystems: (vm) => {
      const ret = []

      vm.furrowSets.forEach((furrowSet) => {
        const pathLineString = GeoUtil.LatLngs.toLineString(furrowSet.path)

        let closestDistanceInFeet = Number.MAX_VALUE
        let irrigationSystem = vm.irrigationSystem
        if (irrigationSystem == null) {
          irrigationSystem = vm.irrigationSystemsCloseEnoughTofield.reduce((closest, is) => {
            const pointGeo = GeoUtil.LatLngs.toGeoJsonPoint(is.waterSourceLocation)

            const distanceInFeet = GeoUtil.GeoJson.pointToLineDistance(pointGeo, pathLineString, {
              units: 'feet'
            })
            if (closest == null || distanceInFeet < closestDistanceInFeet) {
              closestDistanceInFeet = distanceInFeet
              return is
            }

            return closest
          }, null)
        }
        if (irrigationSystem == null) {
          return
        }

        ret.push({ id: furrowSet.id, path: furrowSet.path, irrigationSystem })
      })

      return ret
    },
    splitPoints: (vm) => {
      return vm.fieldLayout?.furrowSetDetails?.splitPointLatLngs || []
    },
    splitBearing: (vm) => {
      return vm.fieldLayout.perpendicularSets
        ? GeoUtil.bearingPerpendicular(vm.fieldLayout.furrowBearing)
        : vm.fieldLayout.furrowBearing
    },
    primaryWaterSourceLocation: (vm) => {
      const primaryIrrigationSystem = vm.farm.irrigationSystems.find((irrigationSystem) => {
        return irrigationSystem.flowRates.some(
          (flowRate) => flowRate.id === vm.fieldLayout.primaryFlowRateId
        )
      })

      return primaryIrrigationSystem ? primaryIrrigationSystem.waterSourceLocation : null
    }
  },
  emits: ['change'],
  methods: {
    splitPointDragged(index, latLng) {
      let modifiedLatLngs = this.fieldLayout.furrowSetDetails.splitPointLatLngs.slice()

      modifiedLatLngs.splice(index, 1, latLng)

      const newFurrowSetDetails =
        new FurrowSetAlg().buildFurrowSetDetailsFromSplitPointLatLngsAndSplitPaths(
          modifiedLatLngs,
          this.fieldLayout.furrowSetDetails.splitPaths,
          this.splitBearing,
          this.fieldLayout.path,
          this.primaryWaterSourceLocation
        )

      this.modifiedFurrowSets = newFurrowSetDetails.resultingFurrowSets
    },
    splitPointMoved(index, latLng) {
      const newFieldLayout = JSON.parse(JSON.stringify(this.fieldLayout))

      let modifiedLatLngs = this.fieldLayout.furrowSetDetails.splitPointLatLngs.slice()

      modifiedLatLngs.splice(index, 1, latLng)

      const newFurrowSetDetails =
        new FurrowSetAlg().buildFurrowSetDetailsFromSplitPointLatLngsAndSplitPaths(
          modifiedLatLngs,
          this.fieldLayout.furrowSetDetails.splitPaths,
          this.splitBearing,
          this.fieldLayout.path,
          this.primaryWaterSourceLocation
        )

      newFieldLayout.furrowSetDetails = newFurrowSetDetails

      this.$emit('change', newFieldLayout)
    },
    splitPointRemoved(index) {
      const newFieldLayout = JSON.parse(JSON.stringify(this.fieldLayout))

      let modifiedLatLngs = this.fieldLayout.furrowSetDetails.splitPointLatLngs.slice()

      modifiedLatLngs.splice(index, 1)

      const newFurrowSetDetails =
        new FurrowSetAlg().buildFurrowSetDetailsFromSplitPointLatLngsAndSplitPaths(
          modifiedLatLngs,
          this.fieldLayout.furrowSetDetails.splitPaths,
          this.splitBearing,
          this.fieldLayout.path,
          this.primaryWaterSourceLocation
        )

      newFieldLayout.furrowSetDetails = newFurrowSetDetails

      this.$emit('change', newFieldLayout)
    }
  }
}
</script>

<style lang="css"></style>

<template>
    <div class="top" style="{{ width: `${props.width}px`, height: `${props.height}px` }}">
        <img @load="load" loading="lazy" @loading="loading" @error="error" :src="props.src" :alt="props.alt" :width="props.width" :height="props.height" />
        <div v-if="data.isLoading" class="icon">
            <div class="fa-spin fa fa-2x fa-spinner" />
        </div>
        <div v-else-if="data.hasError" class="icon">
            <div class="fa fa-2x fa-chain-broken" />
        </div>
    </div>
</template>

<script setup>
import { reactive } from 'vue'

const props = defineProps({
    src: {
        type: String,
        required: true
    },
    width: {
        type: Number,
        required: true
    },
    height: {
        type: Number,
        required: true
    },
    alt: {
        type: String,
        rqeuired: false
    }
})

const data = reactive({
    hasError: false,
    isLoading: true
})

function load() {
    data.isLoading = false
    data.hasError = false
}

function error() {
    data.isLoading = false
    data.hasError = true
}
</script>

<style lang="css" scoped>
.top {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    justify-self: center;
    align-self: center;
}

img {
    z-index: 0;
}

.icon {
    position: absolute;
    z-index: 1;
    background-color: transparent;
}
</style>
<!--

This component operates on a FlowRate id, not the object itself.

-->

<template>
  <div style="display: flex; flex-direction: row; gap: var(--general-padding);">
    <div v-if="imagePreviewUrl != null" style="display: flex; flex-direction: column; align-items: flex-start">
      <template v-if="imagePreviewUrl != null">
        <img style="width: 100%" :src="imagePreviewUrl" />
      </template>
    </div>

    <div style="display: flex; flex-direction: column;justify-content: flex-start">
      <div style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        ">
        <label>Water Source</label>
        <select ref="irrigationSystem" v-model="data.selectedIrrigationSystemId">
          <option v-for="irrigationSystem in irrigationSystemsWithExtraStuff"
            :selected="irrigationSystem.id === data.selectedIrrigationSystemId" :key="irrigationSystem.id"
            :value="irrigationSystem.id">
            {{ irrigationSystem.nameOrDescription }}
          </option>
        </select>
      </div>


      <div v-if="showApplyFlowRateChanges" class="apply_div">
        <input type="checkbox" class="widget_margin_left" v-model="data.applyFlowRateToPipe"
          id="flowRateSelectApplyFlowRateToPipe'" />
        <label for="flowRateSelectApplyFlowRateToPipe" class="description">Apply Flow Rate Changes to Pipe</label>
      </div>

      <slot></slot>

      <div style="
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        " v-if="data.selectedIrrigationSystemId">
        <label>Flow Rate</label>
        <select ref="flowRate" v-model="model">
          <option v-for="flowRate in flowRates" :key="flowRate.id" :selected="flowRate.id === model.value"
            :value="flowRate.id">
            {{ flowRate.valueInGpm }} @ {{ flowRate.name }}
          </option>
        </select>
      </div>
      <template v-else>
        <hint>Add or Select Water Source</hint>
      </template>
    </div>
  </div>
</template>

<script setup>
import { reactive, inject, computed, onMounted, watch } from 'vue'

import { GeoUtil } from '@/geo/GeoUtil'
import { useStore } from 'vuex';

const $store = useStore()
const $staticImages = inject('$staticImages')
const $emit = defineEmits(['applyFlowRate'])

const model = defineModel()

const props = defineProps([
  'field',
  'fieldLayout',
  'irrigationSystems',
  'lineStringForDistanceCalculation',
  'showApplyFlowRateChanges'
])

const data = reactive({
  filterDistanceInFeet: $store.state.preferences.pipe.maxDistanceFromFieldInFeet,
  selectedIrrigationSystemId: null,
  applyFlowRateToPipe: true
})

// watch: {
//   value: 'updateFieldsFromModelValue',
//     selectedIrrigationSystemId: 'selectFirstFlowRate',
//       selectedFlowRateId: 'selectedFlowRateIdChanged',
//         applyFlowRateToPipe: 'emitFlowRateChanged'
// },

// function selectFirstFlowRate() {
//   const is = this.selectedIrrigationSystemWithExtraStuff
//   if (!is) {
//     return
//   }

//   this.selectedFlowRateId = is.flowRates.length ? is.flowRates[0].id : null
// }

// function selectedFlowRateIdChanged() {
//   this.$emit('input', this.selectedFlowRateId)
//   this.emitFlowRateChanged()
// }

// function emitFlowRateChanged() {
//   let emitObj = {
//     applyFlowRateBoolean: this.applyFlowRateToPipe,
//     irrigationSystemId: this.selectedIrrigationSystemId,
//     flowRateId: this.selectedFlowRateId
//   }
//   this.$emit('applyFlowRate', emitObj)
// }

const irrigationSystemsWithExtraStuff = computed(() => {
  const ret = props.irrigationSystems.reduce((accum, irrigationSystem) => {
    if (!irrigationSystem.waterSourceLocation) {
      return accum
    }

    const point = GeoUtil.LatLngs.toGeoJsonPoint(irrigationSystem.waterSourceLocation)
    const distanceInFeet = GeoUtil.GeoJson.pointToLineDistance(
      point,
      props.lineStringForDistanceCalculation,
      { units: 'feet' }
    )

    accum.push({ ...irrigationSystem, distanceInFeet })

    return accum
  }, [])

  ret.sort((a, b) => a.distanceInFeet - b.distanceInFeet)

  const formatLatLng = (ll) =>
    ll.lng.toFixed(5).toString() + ', ' + ll.lat.toFixed(5).toString()

  ret.forEach((irrigationSystemWithStuff) => {
    const nameValid =
      irrigationSystemWithStuff.name != null && irrigationSystemWithStuff.name.length > 0

    irrigationSystemWithStuff.inputId = 'irrigation_system_' + irrigationSystemWithStuff.id
    irrigationSystemWithStuff.nameOrDescription = nameValid
      ? irrigationSystemWithStuff.name
      : formatLatLng(irrigationSystemWithStuff.waterSourceLocation)
    // irrigationSystemWithStuff.nameOrDescription += ' (' + Math.round(irrigationSystemWithStuff.distanceInFeet) + ' ft)'
    irrigationSystemWithStuff.imageUrl = $staticImages.buildFieldAndWaterSourceUrl(
      props.fieldLayout,
      irrigationSystemWithStuff.waterSourceLocation
    )
  })

  return ret
})

const selectedIrrigationSystemWithExtraStuff = computed(() => {
  return irrigationSystemsWithExtraStuff.value.find((is) => is.id === data.selectedIrrigationSystemId)
})


const flowRates = computed(() => {
  if (!selectedIrrigationSystemWithExtraStuff.value) {
    return []
  }

  return selectedIrrigationSystemWithExtraStuff.value.flowRates
})

const imagePreviewUrl = computed(() => {
  if (!selectedIrrigationSystemWithExtraStuff.value) {
    return null
  }

  return selectedIrrigationSystemWithExtraStuff.value.imageUrl
})

function updateModelValueIfIrrigationSystemChanged() {
  const is = props.irrigationSystems.find(o => o.id === data.selectedIrrigationSystemId)
  if (!is) {
    return
  }

  if (!is.flowRates.length) {
    return
  }

  const flowRate = is.flowRates.find(o => o.id === model.value)
  if (!flowRate) {
    model.value = is.flowRates[0].id
  }
}

watch(data, updateModelValueIfIrrigationSystemChanged)

watch(model, () => {
  $emit('applyFlowRate', {
    applyFlowRateBoolean: props.showApplyFlowRateChanges ? data.applyFlowRateToPipe : false,
    irrigationSystemId: data.selectedIrrigationSystemId,
    flowRateId: model.value
  })
})

onMounted(() => {
  if (model.value) {
    const irrigationSystem = props.irrigationSystems.find((is) =>
      is.flowRates.some((fr) => fr.id === model.value)
    )

    if (irrigationSystem) {
      data.selectedIrrigationSystemId = irrigationSystem.id
    }
  }
})
</script>

<style lang="css" scoped>
.flow_rate_select_wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin: 0px;
}

.irrigation_system_select {
  max-width: 30vw;
}

.flow_rate_select {
  max-width: 30vw;
}

.flow_rate_preview_image {
  height: 5em;
  max-width: 15vw;
  max-height: 15vw;
  border: 2px solid var(--secondary-light);
  border-radius: var(--general-border-radius);
  margin-left: var(--label-margin);
}

.apply_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  vertical-align: middle;
}

.apply_div .description {
  align-items: center;
}
</style>

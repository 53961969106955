<template>
    <span :title="description" :class="computedIconButtonClasses" @pointermove.stop @mousedown.stop @mousemove.stop
        @touchmove.stop.passive @click.stop.capture="click">
        <slot />

        <span v-if="faIconValid" :class="computedFaIconClasses"></span>
        <span v-if="computedImage"><img class="icon_button_img" :src="image" alt="Icon Image"></span>
        <div :class="computedLabelClass" v-if="label && labelsAvailable" v-html="label"></div>
    </span>
</template>

<script>
import hotkeys from 'hotkeys-js';

export default {
    props: ['label', 'description', 'disabled', 'faIcon', 'faColorClass', 'image', 'useSaveIcon', 'useCancelIcon', 'big', 'jd', 'class'],
    data: function () {
        return {
            iconColorClasses: ""
        }
    },
    defineEmits: ['click'],
    methods: {
        click(e) {
            if(! this.disabled) {
                this.$emit('click')
            }
        }
    },
    computed: {
        computedIconButtonClasses: (vm) => {
            let ret = ""
            if (vm.image) {
                ret = 'img_button'
                ret += vm.disabled ? " disabled" : " enabled"
                return ret
            }
            ret = vm.big ? 'icon_button_big' : 'icon_button'

            ret += vm.disabled ? " disabled" : " enabled"

            if(vm.jd) {
                ret += " jd"
            }

            if(vm.class) {
                ret += ` ${vm.class}`
            }

            return ret
        },
        computedLabelClass: (vm) => vm.big ?
            'icon_button_label_big' : 'icon_button_label',
        computedImage: (vm) => {
            if (vm.image) {
                return true
            }
            return false
        },
        computedFaIconClasses: (vm) => {
            let ret = ""

            if (vm.faIcon || vm.useSaveIcon || vm.useCancelIcon) {
                ret += vm.big ? "fa fa-3x" : "fa fa-2x"
            }

            if (vm.useSaveIcon) {
                ret += " fa-check ok_green"
            }
            else if (vm.useCancelIcon) {
                ret += " fa-undo cancel_red"
            }
            else if (vm.faIcon) {
                ret += " " + vm.faIcon

                if (vm.faColorClass != null && vm.faColorClass.length > 0) {
                    ret += " " + vm.faColorClass
                }
            }

            return ret
        },
        faIconValid: (vm) => vm.computedFaIconClasses.length > 0,
        imageValid: (vm) => vm.computedImageClasses.length > 0,
        labelsAvailable: (vm) => vm.$store.state.preferences.ui.showButtonLabels
    },
    created: function () {
        if (!this.title) {
            this.title = this.$data.label
        }
    },
    mounted() {
        if (this.useCancelIcon) {
            hotkeys('escape', 'IconButtonCancel', () => {
                this.$emit('click');
            });

            hotkeys.setScope("IconButtonCancel")
        }
    },
    unmounted() {
        if (this.useCancelIcon) {
            hotkeys.deleteScope("IconButtonCancel")
        }
    }

}
</script>

<style lang="css">
.icon_button_img {
    height: 2.5em;
    width: 2.5em;
    padding: 0px;
}

.fa {
    vertical-align: middle;
    text-align: center;
    padding: 0.25em;
    margin: 0px;
    align-self: center;
}

.disabled {
    color: var(--disabled-dark);
}

.enabled {
    color: black;
}

.icon_button {
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    font-size: medium;
    padding: 0px;
    cursor: pointer;
    width: 2.5em;
    border-style: solid;
    border-color: black;
}

.icon_button.jd {
    color: var(--jd-yellow) !important;
}

.img_button {
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    font-size: medium;
    padding: 0px;
    cursor: pointer;
    width: 2.5em;
    border-style: solid;
    border-color: black;
}

.icon_button_big {
    user-select: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
    background-color: white;
    font-size: medium;
    padding: 0px;
    cursor: pointer;
    width: 4.5em;
    border-style: solid;
    border-color: black;
}

.icon_button_big.jd {
    color: var(--jd-green) !important;
}

@media screen and (min-width: 100px) {
    .icon_button {
        font-size: small;
    }

    .icon_button_big {
        font-size: small;
    }
}

@media screen and (min-width: 800px) {
    .icon_button {
        font-size: initial;
    }

    .icon_button_big {
        font-size: initial;
    }
}

.ok_green {
    color: var(--ok-green);
}

.disabled>.ok_green {
    color: grey;
}

.disabled>.cancel_red {
    color: grey;
}

.enabled:hover {
    background-color: var(--secondary-light);
}

.icon_button>img {
    border: 4px solid transparent;
    width: 1.75em;
    height: 1.75em;
    max-width: 10vw;
    max-height: 10vw;
    padding: 2px;
    align-self: center;
}

.icon_button_big img {
    border: 4px solid transparent;
    width: 3.5em;
    height: 3.5em;
    max-width: 3.5em;
    max-height: 3.5em;
    padding: 2px;
    align-self: center;
}

.icon_button_label {
    display: block;
    font-size: xx-small;
    color: white;
    padding: 0px;
    margin: 0px;
    position: relative;
    left: 0px;
    right: 0px;
    min-height: 1em;
    overflow: hidden;
    text-overflow: initial;
    text-align: center;
    background-color: var(--secondary-dark);
}

.jd > .icon_button_label {
    background-color: var(--jd-green) !important;
    color: var(--jd-yellow) !important;
}
.jd > .icon_button_label_big {
    background-color: var(--jd-green) !important;
    color: var(--jd-yellow) !important;
}

.icon_button_label_big {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: medium;
    color: white;
    padding: 0px;
    margin: 0px;
    position: relative;
    left: 0px;
    right: 0px;
    min-height: 1em;
    overflow: hidden;
    text-overflow: initial;
    text-align: center;
    background-color: var(--secondary-dark);
    align-self: stretch;
    flex-grow: 1;
    justify-self: flex-start;
}

.disabled>.icon_button_label {
    color: black;
    background: var(--primary-light);
}

.disabled {
    background-color: var(--disabled);
    cursor: unset;
}
</style>

<template>
    <div>
        <span>
            <div class="field_layout_select_wrapper">
                <div>
                    <div>
                        <template v-if="selectedFieldLayoutId != null && editSelectedFieldLayoutMode == false">
                            <label>Field Layout</label>
                            <select class="field_layout_select widget_margin_top widget_margin_left" 
                            ref="flowRate"
                            v-model="selectedFieldLayoutId">
                                <option v-for="(layout, i) in field.layouts"
                                    :key="layout.id"
                                    :selected="layout.id === selectedFieldLayoutId"
                                    :value="layout.id"> {{displayLayoutSelect(layout, i)}}</option>
                                    
                            </select>
                        </template>
                        <template v-else-if="selectedFieldLayoutId != null && editSelectedFieldLayoutMode == true">
                            <label for="field_name">Edit Layout Name:</label>
                            <input type="text" id="field_name" class="label_margin_left" v-model="fieldLayoutName" ref="name">
                            <a class="button layout_btns" @click="addNewLayoutName(fieldLayout)">Save</a>
                        </template>
                        <template v-else>
                            <button>Add Field Layout</button>
                            <hint>Add or Select a Field Layout</hint>
                        </template>
                    </div>
                </div>

                <template v-if="imagePreviewUrl != null">
                    <img class="field_layout_preview_image" :src="imagePreviewUrl">
                </template>
            </div>
        </span>
        <div class="edit_layout_btns" >
            <a class="button layout_btns" @click="addFieldLayout()">Add New Field Layout</a>
            <a @click="editFieldLayout" :disabled="editSelectedFieldLayoutMode == true" class="button layout_btns">Edit Field Layout Name</a>
            <a class="button layout_btns" @click="removeFieldLayout()">Remove Field Layout</a>
            
        </div>

    </div>
</template>

<script>
export default {
    props: ['value', 'field', 'fieldLayout'],
    data: function() {
        return {
            selectedFieldLayoutId: null,
            fieldLayoutName: null,
            editSelectedFieldLayoutMode: false
        }
    },
    watch: {
        selectedFieldLayoutId: 'selectedFieldLayoutIdChanged'
    },
    methods: {
        selectedFieldLayoutIdChanged() {
            const fieldLayoutChanged = this.value == this.selectedFieldLayoutId? false:true
            let emitObj = {
                fieldLayoutChangedBoolean: fieldLayoutChanged,
                fieldId: this.field.id,
                layout: this.field.layouts.find((layout) => layout.id == this.selectedFieldLayoutId)
            }
            this.$emit('fieldLayoutChanged', emitObj)
        },
        displayLayoutSelect(layout, i) {
            const fieldMode = layout.mode == 1? 'Furrow': 'Levee'
            if(layout.name) {
                return `${layout.name}, Type ${fieldMode}`
            }
            else {
                return `${fieldMode} Layout #${i + 1}`
            }
        },
        async addNewLayoutName(fieldLayout) {
            this.editSelectedFieldLayoutMode = false
            let patches = []
            patches.push({
                op: fieldLayout.name == undefined ? 'add': 'replace',
                path: '/name',
                value: this.fieldLayoutName
            })

            await this.$store.dispatch("patchFieldLayout", {field: this.field, fieldLayout: this.fieldLayout, patch: patches})

        },
        addFieldLayout(){
            this.$dialog.pop()
            this.$nextTick(() => {
                 this.$store.dispatch('enterAddFieldLayoutMode')
            }) 
        },
        editFieldLayout() {
            this.editSelectedFieldLayoutMode = true
        },
        async removeFieldLayout() {
            if(this.field.layouts === 1) {
                alert('Cannot delete last layout')
                return
            }
            await this.$store.dispatch("removeSelectedFieldLayout", this.selectedFieldLayoutId)
        }
    },
    computed: {
         fieldLayoutsWithExtraStuff: (vm) => {
            const ret = vm.field.layouts

            ret.forEach((fieldLayoutWithStuff) => {
                const nameValid = fieldLayoutWithStuff.name != null && fieldLayoutWithStuff.name.length > 0

                fieldLayoutWithStuff.inputId = 'irrigation_system_' + fieldLayoutWithStuff.id    
                fieldLayoutWithStuff.imageUrl = 
                    vm.$staticImages.buildFieldUrl(fieldLayoutWithStuff)
            })

            return ret
        },
        selectedFieldLayoutWithExtraStuff: (vm) => vm.fieldLayoutsWithExtraStuff.find((layout) => layout.id == vm.selectedFieldLayoutId),
        flowRates: (vm) => {
            if(vm.selectedIrrigationSystemWithExtraStuff == null) {
                return []
            }

            return vm.selectedIrrigationSystemWithExtraStuff.flowRates
        },
        imagePreviewUrl: (vm) => {
            if(vm.selectedFieldLayoutWithExtraStuff == null) {
                return null
            }

            return vm.selectedFieldLayoutWithExtraStuff.imageUrl
        },
        
    },
    mounted() {   
        this.fieldLayoutName = this.fieldLayout.name
        if(this.value != null) {
            this.selectedFieldLayoutId = this.value
        }
        else if(this.fieldLayoutsWithExtraStuff.length > 0) {
            this.selectedFieldLayoutId = this.fieldLayoutsWithExtraStuff[0].id
        }
    }
}
</script>

<style lang="css" scoped>
.field_layout_select_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    margin: 0px;
}

.field_layout_select {
    max-width: 30vw;
}

.field_layout_preview_image {
    height: 5em;
    max-width: 15vw;
    max-height: 15vw;
    border: 2px solid var(--secondary-light);
    border-radius: var(--general-border-radius);
    margin-left: var(--label-margin);
}

.add_edit_remove_layouts {
    display: inline-flex;
    flex-direction: column;
}

.layout_btns {
    margin: 0.2em;
    padding: 0.5em;
    font-size: small;
    max-width: 10rem;
}

.edit_layout_btns {
    display: flex;
    flex-direction: column;
    justify-items: center;
}

</style>
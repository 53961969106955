<template>
  <fullpageform
      title="Add Farm"
      description="Farms are used to group fields."
      submitLabel="Add" cancelLabel="Cancel"
      @submit="submit" @cancel="cancel">
      <label for="farm_name">Name</label><br><input type="text" ref="name" size="32">
  </fullpageform>
</template>

<script>
import { Pp4Uuid, Farm } from '../store/Dto'
export default {
  components: {
  },
  methods: {
    submit: async function() {
      let name = this.$refs.name.value

      let farm = new Farm(Pp4Uuid(), name, [])

      await this.$store.dispatch('addFarm', farm)

      this.$router.push({name: 'FarmMapView', params: {farmId: farm.id}})
    },
    cancel: function() {
      this.$router.go(-1)
    }
  },
  mounted: function() {
    this.$refs.name.focus()
  }
}
</script>

<style scoped lang="css">
</style>

<template>
  <div class="jd-orgs-div">
    <div v-if="jdBoundariesResult.error.value">{{ jdBoundariesResult.error.value?.toString() || 'Internal Error' }}</div>

    <loading v-if="showLoading" label="Loading Org..." />

    <div class="jd-orgs-sticky-top-div">
      <!-- Filters -->
      <div class="jd-filters-div">
        <button class="jd" @click="refresh" :disabled="jdOrganizations.isValidating.value">
          <i class="fa fa-refresh"></i>
        </button>
        <JdFilter :reset="false" label="Organization" :options="finalJdOrganizations"
          v-model="data.selectedOrganizationId" />
        <input type="text" v-model="data.orgFilter" placeholder="Filter..." class="jd-text-input"
          v-if="jdOrganizations.data?.value?.length > ShowOrgsFilterAt"
          style="height: 100%; border: none; background-color: var(--jd-green); color: var(--fg0); font-weight: bold;">

        <JdFilter label="Clients" :options="jdClients" v-model="data.selectedClientId" />

        <JdFilter label="Farms" :options="jdFarms" v-model="data.selectedFarmId" />

        <input type="text" v-model="data.clientFarmFilter" placeholder="Filter..." class="jd-text-input"
          style="height: 100%; border: none; background-color: var(--jd-green); color: var(--fg0); font-weight: bold;">
      </div>

      <!-- Shopping List & Hole Designs-->
      <div v-if="finalFilteredFields?.length" class="jd-field-action-buttons">
        <div style="display: flex; flex-direction: row; align-items: center; gap: var(--general-padding);">
          <button class="small" @click="showHoleDesigns">
            <i class="fa fa-book fa-1x"></i> Hole Designs
          </button>

          <button class="small" @click="showShoppingList">
            <i class="fa fa-cart-arrow-down fa-1x"></i> Shopping List
          </button>

          <button class="small jd" title="Export Design Hole Sizes as Flags to John Deere Operations Center"
            :disabled="disableFlagExports"
            @click="showFlagExport">
            <i class="fa fa-eject fa-1x"></i>Export Designs as Flags
          </button>

          <div v-if="selectedOrganizationHasJdAccess" class="paid-for">
            <i class="fa fa-check-circle-o "></i>Access through {{ CurrentSeason() }} Season
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectedOrganization" class="jd-org-div">
      <!-- Pay bill for current season. -->
      <div v-if="(!notConnected) && (!selectedOrganizationHasJdAccess)" class="jd-org-needs-payment">
        <div class="pitch-text">Activate Pipe Planner Software Connection for {{ CurrentSeason() }}!</div>
        <div class="mt-1">
          <button @click="payForPipePlanner">Activate</button>
        </div>
      </div>

      <!-- Active Pipe Planner Software Connection w/JD -->
      <div v-if="notConnected" class="mt-1"
        style="display: flex; flex-direction: row; justify-content: center;flex-grow: 1;align-self: center;">
        <icon-button :description="`Enable Software Connection Planner for ${selectedOrganization.name}`"
          label="Enable Software Conn." @click="activatePipePlannerForSelectedOrganization" :big="true" faIcon="fa-link"
          :jd="true" />
      </div>

      <JdFields v-else :fields="finalFilteredFields" :selectedOrganizationHasJdAccess="selectedOrganizationHasJdAccess"
        :organizationId="selectedOrganization.id" :ppFarm="matchingPpFarm" />
    </div>
    <div v-else style="flex-grow: 1;">
      <!-- no selected org, just filler -->
    </div>
  </div>

  <pp4-dialog v-if="data.showFlagExport">
    <fullpageform @cancel="hideFlagExport" :showCancel="true" :backgroundClickCancels="false"
      :enableHotkeys="true"
      title="Export Flags to John Deere"
      description="Polytube lines will be exported as flags to John Deere Operations Center under flag categories created by Pipe Planner.">
      <jd-flag-exporter :farm="selectedFarm" :ppFieldFilter="ppFieldFilter" />
    </fullpageform>
  </pp4-dialog>
</template>
 
<script setup>
import { inject, computed, watch, reactive, toRaw } from 'vue'
import useSWRV from 'swrv'
import { useRouter } from 'vue-router'

import { CurrentSeason } from '@/util/SeasonAlg'
import JdFilter from '@/components/jd/JdFilter'
import JdFields from '@/components/jd/JdFields'
import HoleDesignDialog from '@/components/HoleDesignDialog.vue'
import ShoppingListPrinter from '@/print/ShoppingListPrinter'
import PaymentForm from './PaymentForm.vue'

const ShowOrgsFilterAt = 10

const $api = inject('$api')
const $dialog = inject('$dialog')
const $store = inject("$store")
const $staticImages = inject('$staticImages')

const router = useRouter()

const routeParams = router.currentRoute.value?.params || {}
const routeQuery = router.currentRoute.value?.query || {}

const data = reactive({
  selectedOrganizationId: routeParams.selectedOrganizationId || null,
  selectedClientId: routeQuery.client || null,
  selectedFarmId: routeQuery.farm || null,
  orgFilter: routeQuery.orgFilter || null,
  clientFarmFilter: routeQuery.clientFarmFilter || null,
  showFlagExport: false
})

const jdOrganizations = useSWRV(`/api/jd/organizations`, async (...params) => {
  const result = await $api.fetch(params[0], { method: "GET" })
  return await result.json()
}, { defaultValue: null, revalidateOnFocus: false })

const selectedFarm = computed(() => {
  const sf = $store.state.selectedFarm

  return sf?.jd ? sf : null
})

const selectedOrganization = computed(() => {
  if (!data.selectedOrganizationId) {
    return null
  }

  if (!jdOrganizations.data?.value?.length) {
    return null
  }

  return jdOrganizations.data.value.find(o => o.id === data.selectedOrganizationId)
})

const selectedOrganizationHasJdAccess = computed(() => {
  return selectedOrganization.value?.jdAccess ? true : false
})

const notConnected = computed(() => {
  return selectedOrganization.value?.connectionUri ? true : false
})

// Update 'data' from the route
watch(router.currentRoute, () => {
  const r = router.currentRoute.value
  data.selectedOrganizationId = r.params.selectedOrganizationId || null
  data.selectedClientId = r.query.client || null
  data.selectedFarmId = r.query.farm || null
  data.orgFilter = r.query.orgFilter || null
  data.clientFarmFilter = r.query.clientFarmFilter || null
})

// Update the route from 'data'
watch([data], () => {
  router.push({
    name: "JohnDeere", params: {
      selectedOrganizationId: data.selectedOrganizationId || null
    }, query: {
      client: data.selectedClientId || undefined,
      farm: data.selectedFarmId || undefined,
      orgFilter: data.orgFilter?.length ? data.orgFilter : undefined,
      clientFarmFilter: data.clientFarmFilter?.length ? data.clientFarmFilter : undefined
    }
  })
})

const matchingPpFarm = computed(() => {
  return ($store.state?.selectedFarm?.id === jdBoundariesResult?.data?.value?.ppFarmId) ?
    $store.state.selectedFarm : null
})

const jdFarmFieldBoundariesUrl = computed(() => {
  if (!data.selectedOrganizationId) {
    return 'null' // !! swrv does not react to null values, use empties
  }

  if (notConnected.value) {
    return 'null' // !! swrv does not react to null values, use empties
  }

  return `/api/jd/organizations/${data.selectedOrganizationId}/farm-field-boundaries`
})

const jdBoundariesResult = useSWRV(jdFarmFieldBoundariesUrl, async (url) => {
  if ((!url?.length) || (url === "null")) { // !! swrv does not react to null values, use empties
    return []
  }

  const result = await $api.fetch(url, { method: "GET" })

  return await result.json()
}, { defaultValue: null, revalidateOnFocus: false })

const showLoading = computed(() => {
  return (!jdOrganizations.data.value) || jdOrganizations.isValidating.value
    || jdBoundariesResult.isValidating.value
})


watch([jdBoundariesResult.data, jdBoundariesResult.isValidating], async () => {
  if (jdBoundariesResult.isValidating.value) {
    $store.dispatch("setSelectedFarm", null);
    return
  }

  if (jdBoundariesResult.error.value) {
    $store.dispatch("setSelectedFarm", null);
    return
  }

  const ppFarmId = jdBoundariesResult.data.value.ppFarmId
  if (!ppFarmId) {
    return
  }

  if ((ppFarmId === $store.state.selectedFarmId) && $store.state.selectedFarm) {
    return
  }

  const fullFarm = await $store.getters.getFarm(ppFarmId);
  $store.dispatch("setSelectedFarm", fullFarm);
})

watch(() => data.selectedOrganizationId, () => {
  data.selectedClientId = null
  data.selectedFarmId = null
})

const comboBoxFilteredFields = computed(() => {
  let ret = jdBoundariesResult.data?.value?.fields
  if (!ret) {
    return []
  }

  if (!(data.selectedClientId || data.selectedFarmId)) {
    return ret
  }

  if (data.selectedClientId) {
    ret = ret.filter((field) => {
      return field.clients.some((c) => c.id == data.selectedClientId)
    })
  }

  if (data.selectedFarmId) {
    ret = ret.filter((field) => {
      return field.farms.some((f) => f.id == data.selectedFarmId)
    })
  }

  return ret
})

const finalJdOrganizations = computed(() => {
  const toSearch = data.orgFilter?.toLowerCase() || ''

  if (!toSearch.length) {
    return jdOrganizations.data.value
  }

  return jdOrganizations?.data?.value?.filter((o) => {
    return o.name.toLowerCase().match(toSearch)
  }) || []
})

const finalFilteredFields = computed(() => {
  const toSearch = data.clientFarmFilter?.toLowerCase() || ''

  if (!toSearch.length) {
    return comboBoxFilteredFields.value
  }

  return comboBoxFilteredFields.value.filter((f) => {
    const searchables = [f.name]

    f.clients.forEach((c) => {
      searchables.push(c.name)
    })

    f.farms.forEach((f) => {
      searchables.push(f.name)
    })

    const searchable = searchables.join(' ').toLowerCase()

    return searchable.match(toSearch)
  })
})

const jdFarms = computed(() => {
  const fields = jdBoundariesResult.data?.value?.fields || []

  const retIds = new Set()
  const ret = []

  fields.forEach(field => {
    if (data.selectedClientId) {
      if (!field.clients.some(c => c.id === data.selectedClientId)) {
        return
      }
    }

    field.farms.forEach(farm => {
      if (retIds.has(farm.id)) {
        return
      }

      ret.push(farm)
      retIds.add(farm.id)
    })
  })

  ret.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'accent' }))

  return ret
})

const jdClients = computed(() => {
  const fields = jdBoundariesResult.data?.value?.fields || []

  const retIds = new Set()
  const ret = []

  fields.forEach(field => {
    if (data.selectedFarmId) {
      if (!field.farms.some(f => f.id === data.selectedFarmId)) {
        return
      }
    }

    field.clients.forEach(client => {
      if (retIds.has(client.id)) {
        return
      }

      ret.push(client)
      retIds.add(client.id)
    })
  })

  ret.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'accent' }))

  return ret
})

const ppFieldFilter = computed(() => {
  const jdFieldIds = new Set()

  finalFilteredFields.value.forEach(f => {
    jdFieldIds.add(f.id)
  })

  return (ppField) => jdFieldIds.has(ppField?.jd.fieldId)
})

function refresh() {
  jdOrganizations.mutate()
  jdBoundariesResult.mutate()
}

async function payForPipePlanner() {
  const dialog = await $dialog.push(PaymentForm, {
    organizationId: data.selectedOrganizationId
  })

  dialog.on('done', ({ message }) => {
    $dialog.pop()
    $dialog.toast({ message })
    jdOrganizations.mutate()
  })

  dialog.on('cancel', () => {
    $dialog.pop()
    jdOrganizations.mutate()
  })
}

function activatePipePlannerForSelectedOrganization() {
  const uri = selectedOrganization?.value?.connectionUri?.uri || null

  if (!uri) {
    alert(`No Connection URL Found for ${selectedOrganization?.value?.name}`)
    return
  }

  const redirectUri = encodeURIComponent(window.location.href.replace('/#/', '/'))

  const finalUri = uri + "?redirect_uri=" + redirectUri

  window.location = finalUri
}

const disableFlagExports = computed(() => {
  return !selectedFarm.value
})

function showFlagExport() {
  if(! selectedFarm.value) {
    return
  }

  data.showFlagExport = true
}

function hideFlagExport() {
  data.showFlagExport = false
}

async function showShoppingList() {
  if (!selectedFarm.value) {
    return
  }

  if (!selectedOrganization.value) return

  const filter = ppFieldFilter.value

  const jdClient = jdClients.value.find(c => c.id === data.selectedClientId)

  const jdFarm = jdFarms.value.find(f => f.id === data.selectedFarmId)

  let fileName = selectedOrganization.value.name

  if (jdClient) {
    fileName += ` - ${jdClient.name}`
  }

  if (jdFarm) {
    fileName += ` - ${jdFarm.name}`
  }

  const vm = { $staticImages }

  const printer = new ShoppingListPrinter()

  await printer.buildPdfForJdFields(
    fileName,
    toRaw(selectedFarm.value),
    filter,
    'preview',
    vm)
}

function showHoleDesigns() {
  const filter = ppFieldFilter.value

  $dialog.push(HoleDesignDialog, {
    farm: selectedFarm,
    filter,
    field: null
  });
}
</script>

<style lang="css" scoped>
.icon {
  position: absolute;
  right: 0px;
  float: right;
}

.mb-1 {
  margin-bottom: var(--general-padding);
}

.jd-orgs-div {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  row-gap: var(--general-padding);
  width: 100%;
}

.jd-orgs-sticky-top-div {
  position: sticky;
  top: 0px;
  background-color: var(--bg0);
  z-index: 1;
  padding-bottom: var(--general-padding);
}

.jd-filters-div {
  display: flex;
  flex-direction: row;
  gap: var(--general-padding);
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.jd-org-needs-payment {
  margin-top: var(--general-padding);
  margin-bottom: var(--general-padding);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  padding: var(--general-padding);
}

.jd-org-div {
  display: flex;
  border-radius: var(--general-border-radius);
  flex-direction: column;
  max-height: 100%;
  padding-left: 3em;
  padding-right: 3em;
  background-color: var(--bg0);
}

.jd-field-action-buttons {
  display: flex;
  flex-direction: row;
  position: sticky;
  top: 0px;
  margin-top: var(--general-padding);
}

.jd-text-input {
  line-height: 2.5em;
  width: 8em;
  color: var(--fg0);
}

.pitch-text {
  font-weight: bold;
  font-size: medium;
  font-style: italic;
  color: var(--crazy);
}

.paid-for {
  font-weight: bold;
  font-size: small;
  vertical-align: middle;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--crazy);
  font-style: italic;
}
</style>

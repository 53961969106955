<template>
    <template>
        <pp4-dialog>
            <fullpageform submitLabel="OK" cancelLabel="Cancel" @submit="submit" @cancel="cancel">
                <div class="button_fieldsets_div">
                    <fieldset class="button_fieldset_div">
                        <legend>End of Pipe</legend>
                        <toggle-button label="Open End" description="End is Left Open -- A Buildup is Recommended"
                            :active="data.openEndActive" v-on:click="openEndClicked" :big="true"><img
                                :src="SelectedOpenEndIcon" :draggable="false"></toggle-button>

                        <toggle-button label="Tied Off" description="Pipe is Tied Off (Not Recommended)"
                            :active="data.tiedOffEndActive" @click="tiedOffEndClicked" :big="true"><img
                                :src="SelectedClosedEndIcon" :draggable="false"></toggle-button>
                    </fieldset>

                    <fieldset class="junction_fieldset_margin button_fieldset_div">
                        <legend>Junction</legend>
                        <toggle-button label="Inline Tee" description="Split Flow Proportionally to Each Branch" :big="true"
                            :active="data.inlineTeeActive" @click="inlineTeeClicked"><img :src="SelectedInlineTeeIcon"
                                :draggable="false"></toggle-button>

                        <toggle-button label="Surge Valve" description="Alternate Full Flow to Each Branch"
                            :active="data.surgeValveActive" @click="surgeValveClicked" :big="true"><img
                                :src="SelectedSurgeValveIcon" :draggable="false"></toggle-button>
                    </fieldset>
                </div>

                <div class="hint_div">
                    <hint class="button_margin_top" v-if="data.inlineTeeActive">
                        Split Flow Proportionally to Each Branch
                    </hint>
                    <hint class="button_margin_top" v-else-if="data.surgeValveActive">
                        Alternate Full Flow to Each Branch Using a Timer
                    </hint>
                    <hint class="button_margin_top" v-else-if="data.openEndActive">
                        End is Left Open (A Buildup is Recommended)
                        <br><br>
                        This Also Enables Buildups in the Design
                    </hint>
                    <hint class="button_margin_top" v-else>
                        Pipe is Tied Off (Not Recommended)
                        <br><br>
                        This Disables Buildups in the Design
                    </hint>
                </div>
            </fullpageform>
        </pp4-dialog>
    </template>
</template>

<script setup>
import { reactive, onMounted } from 'vue'

import SelectedInlineTeeIcon from '@/assets/selected_inline_tee_icon.svg'
import SelectedSurgeValveIcon from '@/assets/selected_surge_valve_icon.svg'
import SelectedOpenEndIcon from '@/assets/selected_open_end_icon.svg'
import SelectedClosedEndIcon from '@/assets/selected_closed_end_icon.svg'
import PipePathAlg from '@/design/PipePathAlg'
import { ConnectorType, InlineTee, SurgeValve, LatLng, Pp4Uuid } from '@/store/Dto'

const model = defineModel()
const emit = defineEmits(['done', 'cancel'])

const data = reactive({
    inlineTeeActive: false, surgeValveActive: false,
    openEndActive: false, tiedOffEndActive: false,
})

function openEndClicked() {
    data.openEndActive = true
    data.tiedOffEndActive = false
    data.inlineTeeActive = false
    data.surgeValveActive = false
}

function tiedOffEndClicked() {
    data.openEndActive = false
    data.tiedOffEndActive = true
    data.inlineTeeActive = false
    data.surgeValveActive = false
}

function inlineTeeClicked() {
    data.openEndActive = false
    data.tiedOffEndActive = false
    data.inlineTeeActive = true
    data.surgeValveActive = false
}

function surgeValveClicked() {
    data.openEndActive = false
    data.tiedOffEndActive = false
    data.inlineTeeActive = false
    data.surgeValveActive = true
}

function submit() {
    const latLng = model.value
    const replaceLastLatLng = (newLatLng) => {
        const bothJunctions = PipePathAlg.isJunction(newLatLng)
            && PipePathAlg.isJunction(latLng)

        if (bothJunctions) {
            // preserve 'paths'
            newLatLng.paths = latLng.paths
        }

        if (Object.hasOwn(latLng, 'elevationInFeet')) {
            newLatLng.elevationInFeet = latLng.elevationInFeet
        }

        Object.keys(latLng).forEach(key => {
            if(! Object.hasOwn(newLatLng, key)) {
                delete latLng[key]
            }
        })
        
        Object.assign(model.value, newLatLng)
    }

    if (data.openEndActive) {
        replaceLastLatLng(new LatLng({
            lat: latLng.lat, lng: latLng.lng,
            connectorType: ConnectorType.OpenEnd,
            type: null
        }))
    }

    if (data.tiedOffEndActive) {
        replaceLastLatLng(new LatLng({
            lat: latLng.lat, lng: latLng.lng,
            connectorType: ConnectorType.TiedOffEnd,
            type: null
        }))
    }

    if (data.inlineTeeActive) {
        replaceLastLatLng(new InlineTee({
            id: Pp4Uuid(),
            latLng: new LatLng(latLng.lat, latLng.lng),
            paths: []
        }))
    }

    if (data.surgeValveActive) {
        replaceLastLatLng(new SurgeValve({
            id: Pp4Uuid(),
            latLng: new LatLng(latLng.lat, latLng.lng),
            paths: []
        }))
    }

    emit('done')
}

async function cancel() {
    emit('cancel')
}

onMounted(() => {
    const ll = model.value

    if (PipePathAlg.isJunction(ll)) {
        if (ll.type === 'inline_tee') {
            data.inlineTeeActive = true
        }
        else if (ll.type === 'surge_valve') {
            data.surgeValveActive = true
        }
    }
    else {
        if (ll.connectorType === ConnectorType.TiedOffEnd) {
            data.tiedOffEndActive = true
        }
        else {
            data.openEndActive = true
        }
    }
})
</script>

<style lang="css">
.end_of_pipe_options_div {
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.25em;
    background: var(--primary-dark);
    vertical-align: middle;
}

.button_fieldsets_div {
    display: flex;
}

.button_fieldset_div {
    min-width: 15em;
    display: flex;
    justify-content: space-evenly;
}

@media screen and (max-width: 800px) {
    .junction_fieldset_margin {
        margin-top: 10px;
    }
}

@media screen and (orientation:landscape) {
    .button_fieldsets_div {
        flex-direction: row;
    }

    .button_fieldset_div {
        flex-direction: row;
    }

    .junction_fieldset_margin {
        margin-left: 10px;
        margin-top: 0px;
    }
}

@media screen and (orientation:portrait) {
    .button_fieldsets_div {
        flex-direction: column;
    }

    .button_fieldset_div {
        flex-direction: row;
    }
}

.image_class {
    height: 10px;
    width: 10px;
}

.hint_div {
    padding: 1em;
    min-width: calc(min(25em, 90vw));
}
</style>
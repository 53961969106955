<template>
    <fullpageform
            title="Remove Pipe Path?"
            submitLabel="Remove" cancelLabel="Cancel"
            @submit="submit" @cancel="cancel">
  </fullpageform>
</template>

<script>
export default {
    components: {
    },
    data: function() {
        return {
        }
    },
    methods: {
        submit: async function() {
            this.$store.dispatch('removeSelectedIrrigationSystemPipePath')

            this.$dialog.pop()
        },
        cancel: function() {
            this.$dialog.pop()
        }
    },
    computed: {
    },
    mounted: function() {
    }
}
</script>

<style scoped>
</style>
